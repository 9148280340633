export const requestListConfigRKBn = (params) => {
    return {
        type: 'REQUEST_LIST_CONFIG_RKB',
        params: {
            params
        }
    }
}

export const requestHistoryListConfigRKBn = (params) => {
    return {
        type: 'REQUEST_LIST_HISTORY_CONFIG_RKB',
        params: {
            params
        }
    }
}

export const requestUploadFile =  (params) => {
    return {
        type: 'REQUEST_UPLOAD_FILE',
        payload: {
            params
        }
    }
}

export const requestListDataRead =  (idUpload,params) => {
    return {
        type: 'REQUEST_UPLOAD_DATA_READ',
        payload: {
            idUpload,
            params
        }
    }
}

export const requestListDataError =  (idUpload,params) => {
    return {
        type: 'REQUEST_UPLOAD_DATA_ERROR',
        payload: {
            idUpload,
            params
        }
    }
}

export const requestSubmitJadwal = (params,history) => {
    return {
        type: 'REQUEST_SUBMIT_JADWAL',
        payload: {
            params
        },
        history
    }
}

export const requestDeleteJadwal = (params,history) => {
    return {
        type: 'REQUEST_DELETE_JADWAL',
        payload: {
            params
        },
        history
    }
}

export const requestUpdateJadwal = (params, history) => {
    return {
        type: 'REQUEST_UPDATE_JADWAL',
        payload: {
            params
        },
        history
    }
}