export const requestDataDashboard = (params) => {
  
  return dispatch => {
      dispatch({
          type: "REQUEST_SUMMARY_ORDER",
          payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_ABNORMAL_PICKUP",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_ABNORMAL_IN_DELIVERY",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_SLA_DELIVERY_REVIEW",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_CANCELED_DELIVERY",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_NOT_YET_CANCELED",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_HANGING_AWB",
        payload: {
            params
          }
      })
  }
}

export const requestAbnormalPickup = (params) => {
  return dispatch => {
    dispatch({
        type: "REQUEST_ABNORMAL_PICKUP_INTERVAL",
        payload: {
          params
        }
    })
  }
}

export const requestAbnormalInDelivery = (params) => {
  return dispatch => {
    dispatch({
        type: "REQUEST_ABNORMAL_IN_DELIVERY_INTERVAL",
        payload: {
          params
        }
    })
  }
}

export const requestDataDashboardInterval = (params) => {
  return dispatch => {
      dispatch({
          type: "REQUEST_SUMMARY_ORDER_INTERVAL",
          payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_ABNORMAL_PICKUP_INTERVAL",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_ABNORMAL_IN_DELIVERY_INTERVAL",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_SLA_DELIVERY_REVIEW_INTERVAL",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_CANCELED_DELIVERY_INTERVAL",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_NOT_YET_CANCELED_INTERVAL",
        payload: {
            params
          }
      })
      dispatch({
        type: "REQUEST_HANGING_AWB_INTERVAL",
        payload: {
            params
          }
      })
  }
}