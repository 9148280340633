import React, { useState } from 'react'
import { default as Modal } from './ModalFilter'
const useModalFilter = () => {
    const [modal, setModal] = useState(false)
    const ModalFilter = (props) => {
        return <Modal {...props}/>
    }
    return {
        modal,
        setModal,
        ModalFilter
    }
}

export default useModalFilter