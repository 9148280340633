import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    Textarea,
    RadioButton,
    Textfield
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { fetcher, validateForm, validateFormClear } from 'helper';
import Error404 from 'page/errorLayout'
import moment from 'moment';
import { isPointWithinRadius } from 'geolib';
import { MapContainer, Marker, TileLayer, Circle } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Services } from 'service';
import { useDispatch } from 'react-redux';

const IconLocation = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 6.1187V6.17203C13.3433 6.36968 13.3433 6.56771 13.3333 6.76536C13.3465 8.35434 12.6675 9.87038 11.4733 10.9187C10.219 11.9429 9.13844 13.1631 8.27335 14.532C8.22638 14.6169 8.13703 14.6696 8.04002 14.6696C7.943 14.6696 7.85365 14.6169 7.80668 14.532C6.94159 13.1631 5.861 11.9429 4.60668 10.9187C3.39765 9.87782 2.70363 8.36073 2.70668 6.76536C2.69673 6.56771 2.69673 6.36968 2.70668 6.17203V6.1187C3.00094 3.41114 5.2766 1.3533 8.00002 1.33203C10.7387 1.333 13.0374 3.39602 13.3333 6.1187ZM5.82954 7.53046C6.19115 8.41063 7.04846 8.9853 8.00002 8.98536C9.28422 8.98186 10.3285 7.94945 10.3467 6.66536C10.3575 5.71387 9.79265 4.85004 8.91668 4.47841C8.0407 4.10677 7.02701 4.30091 6.35033 4.9699C5.67365 5.63889 5.46794 6.65029 5.82954 7.53046Z" fill="white" />
        </svg>
    );
};

const IconWarning = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.2202 18.14L13.7858 3.5311C13.4133 2.88549 12.7456 2.5 12 2.5C11.2544 2.5 10.5867 2.88549 10.2141 3.5311L1.77975 18.14C1.40675 18.7855 1.40675 19.5566 1.77975 20.2022C2.15233 20.8477 2.82003 21.2333 3.56562 21.2333H20.4348C21.1799 21.2333 21.8476 20.8477 22.2206 20.2022C22.5932 19.5566 22.5932 18.7855 22.2202 18.14ZM12 18.8568C11.2598 18.8568 10.6575 18.2546 10.6575 17.5143C10.6575 16.7742 11.2598 16.1719 12 16.1719C12.7402 16.1719 13.3424 16.7742 13.3424 17.5143C13.3424 18.2546 12.7402 18.8568 12 18.8568ZM12 14.4698C12.7452 14.4698 13.3516 13.8638 13.3516 13.1186V6.91611C13.3516 6.56231 13.0653 6.27594 12.7114 6.27594H11.289C10.9352 6.27594 10.6488 6.56231 10.6488 6.91611V13.1186C10.6488 13.8638 11.2548 14.4698 12 14.4698Z" fill="#EE2B2E" />
        </svg>
    );
};

const mappingPermission = (status) => {
    switch (status) {
        case true:
            return {
                bgColor: "#8FC742",
                label: 'Lokasi Sesuai'
            }
        case false:
            return {
                bgColor: "#EE2B2E",
                label: 'Lokasi Tidak Sesuai'
            }
        case 'not-active':
            return {
                bgColor: "#EE2B2E",
                label: 'Akses Lokasi Tidak Aktif'
            }
        default:
            return {
                bg: "#9C9DA6",
            }
    }
}

const FormKordel = props => {
    const radius = 20
    const IconStore = require("assets/image/store_icon.png");
    const IconUser = require("assets/image/user_icon.png");
    let pathName = props.location.pathname.split('/')
    pathName = pathName[pathName.length - 1]
    document.title = props.title
    const { id } = useParams()
    const dispatch = useDispatch()
    const [permissionLocation, setPermissionLocation] = useState(null)
    const [userLocation, setUserLocation] = useState(null)
    const [inRadius, setInRadius] = useState(false)
    const [notes, setNotes] = useState(null)
    const [dataForm, setDataForm] = useState({
        mobileDeviceCondition: 'ok',
        mobileDeviceConditionDesc: '',
        motorcycleCondition: 'ok',
        motorcycleConditionDes: '',
        delimanCondition: 'ok',
        delimanConditionDesc: ''
    })
    const [disabledAction, setDisabledAction] = useState(true)
    const [loading, setLoading] = useState(false)
    const { data, error } = useSWR(props.access?.update && `/user/api/back-office/rkb-spv/individual/${id}`, fetcher, {
        revalidateOnFocus: false,
        shouldRetryOnError: false
    })

    console.log(props.access?.update)


    const dataDetail = data?.data

    useEffect(() => {

        if (props.access?.update) {

            navigator.geolocation.getCurrentPosition(position => {

                setPermissionLocation(true)
                setUserLocation({
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                })

            }, e => {

                if (e) {
                    setPermissionLocation(false)
                }
            }, {
                enableHighAccuracy: true
            })
        }
    }, [props.access])

    useEffect(() => {
        if (userLocation) {

            if (dataDetail?.storeLongitude && dataDetail?.storeLatitude) {

                const eligible = isPointWithinRadius(
                    { latitude: dataDetail.storeLatitude, longitude: dataDetail.storeLongitude },
                    { latitude: userLocation.lat, longitude: userLocation.long },

                    radius
                );
                setInRadius(eligible);
            }

        }
    }, [userLocation, dataDetail])


    const isValidData = data => ![
        data.mobileDeviceCondition === 'nok' ? Boolean(data.mobileDeviceConditionDesc) : true,
        data.motorcycleCondition === 'nok' ? Boolean(data.motorcycleConditionDes) : true,
        data.delimanCondition === 'nok' ? Boolean(data.delimanConditionDesc) : true
    ].includes(false)
    useEffect(() => {
        if (pathName === 'clockin') {
            if ((!inRadius && notes) || inRadius) {
                setDisabledAction(false)
            } else {
                setDisabledAction(true)
            }

        } else if (pathName === 'clockout') {
            let disabledA = true
            if (notes && isValidData(dataForm)) {
                disabledA = false
            }
            setDisabledAction(disabledA)
        }
    }, [pathName, inRadius, notes, dataForm])

    const dataBreadcrumb = [
        { to: '/rkb-spv', label: 'RKB Kordel' },
        { to: '', label: pathName === 'clockout' ? 'Clock Out' : 'Clock In' }
    ]

    const locationMatch = mappingPermission(!permissionLocation ? 'not-active' : inRadius)

    const handleAction = (pathName) => {
        if (validateForm("myForm")) {
            setLoading('pending')
            Services().post(`/user/api/back-office/rkb-spv/${id}/${pathName === 'clockout' ? 'clock-out' : 'clock-in'}`, {
                id: id,
                latitude: userLocation.lat,
                longitude: userLocation.long,
                note: notes,
                mobileDeviceCondition: pathName === "clockout" ? dataForm.mobileDeviceCondition === "nok" ? false : true : '',
                mobileDeviceConditionDesc: pathName === "clockout" ? dataForm.mobileDeviceConditionDesc : '',
                motorcycleCondition: pathName === "clockout" ? dataForm.motorcycleCondition === "nok" ? false : true : '',
                motorcycleConditionDes: pathName === "clockout" ? dataForm.motorcycleConditionDes : '',
                delimanCondition: pathName === "clockout" ? dataForm.delimanCondition === "nok" ? false : true : '',
                delimanConditionDesc: pathName === "clockout" ? dataForm.delimanConditionDesc : ''
            })
                .then(() => {
                    setLoading('resolved')
                    props.history.push('/rkb-spv')
                    dispatch({
                        type: 'ALERT_TOAST_SUCCESS',
                        payload: {
                            message: 'Berhasil'
                        }
                    })
                })
                .catch(e => {
                    setLoading('rejected')
                    dispatch({
                        type: 'ALERT_TOAST_ERROR',
                        payload: {
                            message: e.message
                        }
                    })
                })
        }

    }

    console.log("error", error)

    if (!error && !dataDetail) return 'Loading...'
    if (error) return <Error404 />
    return dataDetail && (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Clock {pathName === 'clockout' ? 'Out' : 'In'}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <form id="myForm">
                    <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                        <Segment mb={32}>
                            <Segment display="flex" justifyContent="space-between">
                                <Text fontWeight={600} mb={32}>Informasi Clock {pathName === 'clockout' ? 'Out' : 'In'}</Text>
                            </Segment>
                            <Segment mb={16} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Supervisor Delivery Man</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {dataDetail?.supervisorNik} - {dataDetail?.supervisorName}
                                </Segment>
                            </Segment>
                            <Segment mb={16} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Kode Toko</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {dataDetail?.storeCode} - {dataDetail?.storeName}
                                </Segment>
                            </Segment>
                            <Segment mb={16} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Tanggal</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {moment(dataDetail?.date).format('DD/MM/YY')}
                                </Segment>
                            </Segment>
                            <Segment mb={16} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Akses Lokasi</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {permissionLocation ? 'Aktif' : 'Tidak aktif'}
                                </Segment>
                            </Segment>
                            {
                                pathName === "clockout" &&
                                <>
                                    <Segment mb={16} display="flex" className="xs-flex-wrap">
                                        <Segment minWidth={280}>
                                            <Text color={'#70727D'}>Kondisi HP</Text>
                                        </Segment>
                                        <Segment flex={1}>
                                            <Segment display="inline-block">
                                                <RadioButton
                                                    radioProps={{
                                                        onChange: e => {
                                                            setDataForm(prev => {
                                                                return {
                                                                    ...prev,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            })
                                                            e.target.value === "ok" && validateFormClear('myForm', "mobileDeviceConditionDesc")
                                                        },
                                                        className: 'validate[required]',
                                                        name: "mobileDeviceCondition",
                                                        id: "mobileDeviceCondition",
                                                        disabled: false
                                                    }}
                                                    selected={dataForm.mobileDeviceCondition}
                                                    radioItems={[
                                                        { label: "Ok", value: 'ok' },
                                                        { label: "Not Ok", value: 'nok' }
                                                    ]}
                                                />
                                            </Segment>
                                            <Segment mt={12}>

                                                <Textfield
                                                    disabled={dataForm.mobileDeviceCondition === 'ok'}
                                                    inputProps={{
                                                        placeholder: "Alasan",
                                                        onChange: (e) => setDataForm(prev => {
                                                            return {
                                                                ...prev,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        }),
                                                        value: dataForm.mobileDeviceConditionDesc,
                                                        className: dataForm.mobileDeviceCondition === 'nok' ? 'validate[required]' : "",
                                                        name: 'mobileDeviceConditionDesc',
                                                    }}
                                                />


                                            </Segment>
                                        </Segment>
                                    </Segment>
                                    <Segment mb={16} display="flex" className="xs-flex-wrap">
                                        <Segment minWidth={280}>
                                            <Text color={'#70727D'}>Kondisi Motor</Text>
                                        </Segment>
                                        <Segment flex={1}>
                                            <Segment display="inline-block">
                                                <RadioButton
                                                    radioProps={{
                                                        onChange: e => {
                                                            setDataForm(prev => {
                                                                return {
                                                                    ...prev,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            })
                                                            e.target.value === "ok" && validateFormClear('myForm', "motorcycleConditionDes")
                                                        },
                                                        className: 'validate[required]',
                                                        name: "motorcycleCondition",
                                                        id: "motorcycleCondition",
                                                        disabled: false
                                                    }}
                                                    selected={dataForm.motorcycleCondition}
                                                    radioItems={[
                                                        { label: "Ok", value: 'ok' },
                                                        { label: "Not Ok", value: 'nok' }
                                                    ]}
                                                />
                                            </Segment>
                                            <Segment mt={12}>

                                                <Textfield
                                                    disabled={dataForm.motorcycleCondition === 'ok'}
                                                    inputProps={{
                                                        placeholder: "Alasan",
                                                        onChange: (e) => setDataForm(prev => {
                                                            return {
                                                                ...prev,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        }),
                                                        value: dataForm.motorcycleConditionDes,
                                                        className: dataForm.motorcycleCondition === 'nok' ? 'validate[required]' : "",
                                                        name: 'motorcycleConditionDes',
                                                    }}
                                                />


                                            </Segment>
                                        </Segment>
                                    </Segment>
                                    <Segment mb={16} display="flex" className="xs-flex-wrap">
                                        <Segment minWidth={280}>
                                            <Text color={'#70727D'}>Kondisi Delivery Man</Text>
                                        </Segment>
                                        <Segment flex={1}>
                                            <Segment display="inline-block">
                                                <RadioButton
                                                    radioProps={{
                                                        onChange: e => {
                                                            setDataForm(prev => {
                                                                return {
                                                                    ...prev,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            })
                                                            e.target.value === "ok" && validateFormClear('myForm', "delimanConditionDesc")
                                                        },
                                                        className: 'validate[required]',
                                                        name: "delimanCondition",
                                                        id: "delimanCondition",
                                                        disabled: false
                                                    }}
                                                    selected={dataForm.delimanCondition}
                                                    radioItems={[
                                                        { label: "Ok", value: 'ok' },
                                                        { label: "Not Ok", value: 'nok' }
                                                    ]}
                                                />
                                            </Segment>
                                            <Segment mt={12}>

                                                <Textfield
                                                    disabled={dataForm.delimanCondition === 'ok'}
                                                    inputProps={{
                                                        placeholder: "Alasan",
                                                        onChange: (e) => setDataForm(prev => {
                                                            return {
                                                                ...prev,
                                                                [e.target.name]: e.target.value
                                                            }
                                                        }),
                                                        value: dataForm.delimanConditionDesc,
                                                        className: dataForm.delimanCondition === 'nok' ? 'validate[required]' : "",
                                                        name: 'delimanConditionDesc',
                                                    }}
                                                />


                                            </Segment>
                                        </Segment>
                                    </Segment>
                                </>
                            }

                            <Segment display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>{pathName === 'clockout' ? 'Catatan Kunjungan' : 'Alasan Lokasi Tidak Sesuai'}</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Textarea
                                        disabled={pathName === 'clockout' ? false : permissionLocation ? inRadius : true}
                                        inputProps={{
                                            maxLength: 500,
                                            rows: "5",
                                            placeholder: pathName === 'clockout' ? 'Catatan kunjungan' : 'Alasan lokasi tidak sesuai',
                                            className: pathName === 'clockout' ? 'validate[required]' : '',
                                            onChange: e => {
                                                setNotes(e.target.value)
                                            }
                                        }}
                                    />
                                    <Segment mt={8}>
                                        <Text fontSize={12} color="#70727D" fontWeight={400}>{notes ? notes?.length : 0}/500</Text>
                                    </Segment>
                                </Segment>
                            </Segment>

                        </Segment>
                    </Segment>
                </form>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mt={24}>
                    <Segment mb={32}>
                        <Segment justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Map</Text>
                            <Button style={{ backgroundColor: locationMatch.bgColor }}>
                                <IconLocation style={{ marginRight: 8 }} />
                                {locationMatch.label}
                            </Button>
                        </Segment>
                        {
                            (userLocation && data && permissionLocation) &&
                            <Segment mt={24} height={300}>
                                <MapContainer
                                    center={[dataDetail.storeLatitude, dataDetail.storeLongitude]}
                                    zoom={20}
                                    scrollWheelZoom={true}
                                    style={{ width: "100%", height: "300px" }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker
                                        icon={new Icon({ iconUrl: IconStore, iconSize: [41, 41], iconAnchor: [20, 20] })}
                                        position={[dataDetail.storeLatitude, dataDetail.storeLongitude]}
                                    >
                                    </Marker>
                                    <Circle center={[dataDetail.storeLatitude, dataDetail.storeLongitude]} pathOptions={{ fillColor: 'lime' }} radius={radius} />
                                    <Marker
                                        icon={new Icon({ iconUrl: IconUser, iconSize: [41, 41], iconAnchor: [20, 20] })}
                                        position={[userLocation?.lat, userLocation?.long]}
                                    ></Marker>

                                </MapContainer>
                            </Segment>

                        }
                        {!permissionLocation && <Segment bg="#EFF3F6" justifyContent="center" alignItems="center" height={300} mt={24}>
                            <Segment style={{ textAlign: "center" }} maxWidth={350}>
                                <IconWarning />
                                <Text mt={24} style={{ lineHeight: "24px" }} color="#70727D">
                                    Akses lokasi Anda belum aktif. Harap mengaktifkan
                                    akses lokasi Anda di pengaturan.
                                </Text>
                            </Segment>
                        </Segment>}

                    </Segment>
                </Segment>

                <Segment mt={32} justifyContent="flex-end">
                    <Button
                        disabled={disabledAction || loading === 'pending'}
                        variant={"primary"}
                        onClick={() => handleAction(pathName)}
                    >
                        Clock {pathName === 'clockout' ? 'Out' : 'In'}
                    </Button>
                </Segment>
            </Segment>
        </>
    )
}

export default FormKordel