
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:null,
  listDelimanById:[],
  loadingDeliman:false,
  loadingStore:false,
  listStoreById:[],
  statusCheck:{
    clusterCode:''
  },
  loadingCheck:{
    clusterCode:false
  },
  messageError:{
    clusterCode:''
  },
  availableClusterCode:null
}

export const cluster = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_LIST_CLUSTER':
          return {
              ...state,
              loading: true,
              detailData:null,
              statusCheck:{
                clusterCode:''
              },
              messageError:{
                clusterCode:''
              },
              availableClusterCode:null
          }
      case 'REQUEST_CLUSTER_BY_ID':
          return {
              ...state,
              loading: true,
              detailData:null,
              availableClusterCode:null
          }
      case 'GET_CLUSTER':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false,
              
          }
      case 'GET_CLUSTER_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data,
            loading: false,
            statusCheck:{
              clusterCode:'00'
            },
        }
      case 'GET_CLUSTER_FAILED':
        return {
            ...state,
            loading: false
        }
      case 'REQUEST_STORE_BY_CLUSTER':
        return {
            ...state,
            loadingStore: true,
            listStoreById:[]
        }
      case 'GET_STORE_BY_CLUSTER':
        return {
            ...state,
            loadingStore: false,
            listStoreById:action.payload.data
        }
      case 'REQUEST_DELIMAN_BY_CLUSTER':
        return {
            ...state,
            loadingDeliman: true,
            listDelimanById:[]
        }
      case 'GET_DELIMAN_BY_CLUSTER':
        return {
            ...state,
            loadingDeliman: false,
            listDelimanById:action.payload.data
        }
      case 'REQUEST_CHECK_CLUSTER_CODE':
        return{
          ...state,
          loadingCheck:{
            clusterCode:true
          },
        }
      case 'GET_STATUS_CLUSTER_CODE':
          return{
            ...state,
            statusCheck:{
              clusterCode:action.payload.status
            },
            loadingCheck:{
              clusterCode:false
            },
            messageError:{...state.messageError,clusterCode:''}
          }
      case 'RESET_INPUT_STATUS_CLUSTER':
            return {
                ...state,
                statusCheck:{
                  ...state.statusCheck,
                  [action.payload.field]:''
                },
                messageError:{
                  ...state.messageError,
                  [action.payload.field]:''
                },
                availableClusterCode:null
            }
      case 'ERROR_STATUS_CLUSTER_CODE':
        return{
          ...state,
          messageError:action.payload.message,
          statusCheck:{
            clusterCode:action.payload.status
          },
          loadingCheck:{
            clusterCode:false
          },
        }
      case 'REQUEST_AVAILABLE_CLUSTER_CODE':
        return{
          ...state,
          loadingCheck:{
            clusterCode:true
          },
          availableClusterCode:null
        }
      case 'GET_AVAILABLE_CLUSTER_CODE':
        return {
          ...state,
          availableClusterCode:action.payload.availableClusterCode,
          statusCheck:{
            clusterCode:'00'
          },
          loadingCheck:{
            clusterCode:false
          },
          messageError:'',
        }
      case 'ERROR_AVAILABLE_CLUSTER_CODE':
        return {
          ...state,
          availableClusterCode:null,
          messageError:action.payload.message,
          statusCheck:{
            clusterCode:action.payload.status
          },
          loadingCheck:{
            clusterCode:false
          }
        }
      case 'RESET_FORM_CLUSTER':
        return {
          ...state,
          detailData: null
        }
      default:
          return state
  }
}