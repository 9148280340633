import {
    Button, ButtonGroup, FormControl, Icon, Row, Segment, Spinner, Switch, Text, Textfield
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { validateForm, validateInput } from 'helper'
import { useAction, useInput } from 'hooks'
import Error404 from 'page/errorLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { Services } from 'service'
import { requestCheckEmail, requestCheckNik, requestCheckPhone, resetInputStatus, setStatusField, requestUpdateSupervisorDelivery } from 'store/actions/supervisor-delivery'
import useSWR from 'swr'
import { fetcher } from 'helper'




const parseStatus = {
    active:true,
    inactive:false
}

const Form = props => {
    document.title = props.title
    const IconX = require("assets/image/icon-x.svg");
    const history = props.history
    const [isModalCreate, setIsModalCreate] = useState(false)
    
    const { id } = useParams()
    const [disablePreview, setDisablePreview] = useState(true)
    const optionsSwr = {
        revalidateOnFocus:false
    }

    const { data: dataDetail , error } = useSWR((id &&  props.access?.update) &&  `/user/api/supervisor/${id}`,fetcher,optionsSwr)
    
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            detailData: dataDetail?.data,
            previewData: props?.location?.state,
            loading: !error && !dataDetail,
            loadingCheck: state.supervisorDelivery.loadingCheck,
            messageError: state.supervisorDelivery.messageError,
            statusCheck: state.supervisorDelivery.statusCheck,
        }
    })

    const { detailData, loading} = payload
    
    let { value, bindChange,  bindChecked, setValue } = useInput({
        initialObjects: {
          name: payload?.detailData?.name ? payload?.detailData?.name : payload?.previewData?.name ? payload?.previewData?.name : ''  ,
          nik: payload.detailData?.nik ? payload?.detailData?.nik : payload?.previewData?.nik ? payload?.previewData?.nik : '' ,
          email:payload.detailData?.email ? payload?.detailData?.email : payload?.previewData?.email ? payload?.previewData?.email : '' ,
          phone:payload.detailData?.phone ? payload?.detailData?.phone : payload?.previewData?.phone ? payload?.previewData?.phone : '' ,
          status:payload.detailData?.status ? parseStatus[payload?.detailData?.status] : payload?.previewData?.status ? parseStatus[payload?.previewData?.status] : true ,
          listCoodelId: payload?.previewData?.listCoodelId ? payload?.previewData?.listCoodelId : []
        },
        identity: "myForm",
    });

    useEffect(()=>{
        if(id && !loading && detailData){
            hasFetch(setStatusField(true))
            setValue(prev=>{
                return{
                    ...prev,
                    ...detailData,
                    status: parseStatus[detailData.status],
                    listCoodelId: detailData.cordinatorDelivery.map(val => ({ ...val, value: val.nik, label: `${val.nik} - ${val.name}` }))
                }
            })
        }else if(payload.previewData){
            hasFetch(setStatusField(true))
        }
        else{
            hasFetch(setStatusField(false))
        }
    },[id,detailData, loading, setValue, hasFetch,payload.previewData])
    
    const title = id ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = id ? [
        { to: `/supervisor-delivery/${id}`, label: 'Detail Supervisor Delivery' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/supervisor-delivery', label: 'Supervisor Delivery' },
        ...dataBreadcrumbExt
    ]
    const [onSearchNik$] = useState(()=> new Subject())
    const [onSearchEmail$] = useState(()=> new Subject())
    const [onSearchPhone$] = useState(()=> new Subject())

    useEffect(()=>{
        onSearchEmail$.pipe(
            tap(()=>hasFetch(resetInputStatus('email'))),
            debounceTime(500),
            distinctUntilChanged(),            
            tap(value=>{
                
                validateInput('myForm','email') && hasFetch(requestCheckEmail({
                    email:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchEmail$,id])

    useEffect(()=>{
        onSearchPhone$.pipe(
            tap(()=>hasFetch(resetInputStatus('phone'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value=>{
                validateInput('myForm','phone') && hasFetch(requestCheckPhone({
                    phone:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchPhone$,id])

    useEffect(()=>{
        onSearchNik$.pipe(
            tap(()=>hasFetch(resetInputStatus('nik'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value=>{
                validateInput('myForm','nik') && hasFetch(requestCheckNik({
                    nik:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchNik$,id])

    const handleChangeNik = (e) => {
        bindChange.onChange(e)
        onSearchNik$.next(e.target.value)
    }

    const handleChangeEmail = (e) => {
        bindChange.onChange(e)
        onSearchEmail$.next(e.target.value)
    }

    const handleChangePhone = (e) => {
        bindChange.onChange(e)
        onSearchPhone$.next(e.target.value)
    }

    

    const handleShowConfirmation = () => {
        if(validateForm('myForm')){
            setIsModalCreate(!isModalCreate)    
        }
        
    }

    

    const handleChangeCoordel = (data) => {
        let coordel = data ? data : []
        setValue(prev => {
            return {
                ...prev,
                listCoodelId: coordel
            }
        })

        
        setTimeout(() => {
            
            !id && validateInput('myForm', 'listCoodelId')
        }, 0)
    }

    
    
    const handleLoadOptionsCoordel = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/user/api/coordinator/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data

            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }
        if (inputValue.value === 0) {
            const response = await Services().get(`/user/api/coordinator/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data
            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }

    }

    const handleSubmit = () => {
        let addCoorDel = value.listCoodelId.filter(o1=>{
            return detailData.cordinatorDelivery.some(o2=>{
              return o1.nik!==o2.nik
            })
          });
        let deleteCoorDel = detailData.cordinatorDelivery.filter(o1=>{
            return !value.listCoodelId.some(o2=>{
                return o1.nik===o2.nik
            })
        });
        if(detailData.cordinatorDelivery.length === 0){
            addCoorDel = value.listCoodelId
        }
        
        const dataUpdate = {
            email:value.email,
            name:value.name,
            nik:value.nik,
            phone:value.phone,
            status:value.status ? 'active' : 'inactive',
            coordinatorDeliveryNik: addCoorDel.length > 0 ?  addCoorDel.map(val=>val.nik) : null,
            coordinatorDeliveryNikDeleted: deleteCoorDel.length > 0 ?  deleteCoorDel.map(val=>val.nik) : null,
            id:payload.detailData?.id,
        }
        
        hasFetch(requestUpdateSupervisorDelivery(dataUpdate.id,dataUpdate,history))
        
    }

    useEffect(()=>{
        const disabled = Object.keys(value).filter(val=>{
            
            if(Array.isArray(value[val])){
                
                return id ? false : value[val].length === 0
            }else{
                
                return value[val] === ''
            }
        })
        
        if(disabled.length > 0){
            
            setDisablePreview(true)
        }else{
            
            setDisablePreview(false)
        }
        
    },[value,id])

    

    const handlePreview = () => {
        
        if(validateForm('myForm')){
            let dataPreview = {...value}
            dataPreview.status = value.status ? 'active':'inactive'
            history.push({
                pathname:'/supervisor-delivery/preview',
                state:dataPreview
            })
        }
    }

    
    if (!props.access) return <></>
    else if (id === undefined && !props.access?.create) return <Error404 />
    else if (id !== undefined && !props.access?.update) return <Error404 />
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment p={24} boxShadow bg={"white"} borderRadius={4}>
                        <Segment >
                            <Text fontWeight={600} mb={32}>Informasi Supervisor Delivery</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name:'name',
                                                placeholder: "Nama",
                                                value: value.name,
                                                maxLength: 100,
                                                className: 'validate[required,maxLength[100]]',
                                                
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>NIK Supervisor</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.nik ? 'error':'normal'}
                                        helptext={payload.messageError.nik}
                                    >
                                        <Textfield
                                            status={payload.messageError.nik ? 'error':'normal'}
                                            inputProps={{
                                                ...bindChange,
                                                onChange:(e)=>handleChangeNik(e),
                                                placeholder: "NIK",
                                                name:'nik',
                                                value: value.nik,
                                                maxLength:10,
                                                className: 'validate[required,length[10]]',
                                                
                                            }}
                                            disabled={payload.loadingCheck.nik ? true :false}
                                            right={
                                                payload.loadingCheck.nik ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.nik && payload.statusCheck.nik==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Email</Text>
                                </Segment>
                                <Segment flex={1}>
                                    
                                    <FormControl
                                        status={payload.messageError.email ? 'error':'normal'}
                                        helptext={payload.messageError.email}
                                    >
                                        <Textfield
                                            status={payload.messageError.email ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangeEmail(e),
                                                placeholder: "Email",
                                                name:'email',
                                                value: value.email,
                                                maxLength: 100,
                                                className: 'validate[required,email]',
                                                
                                                
                                            }}
                                            right={
                                                payload.loadingCheck.email ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.email && payload.statusCheck.email==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nomor Handphone</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.phone ? 'error':'normal'}
                                        helptext={payload.messageError.phone}
                                    >
                                        <Textfield
                                            status={payload.messageError.phone ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangePhone(e),
                                                placeholder: "Nomor Handphone",
                                                name:'phone',
                                                value: value.phone,
                                                maxLength: 16,
                                                className: 'validate[required,phone]',
                                                
                                            }}
                                            disabled={payload.loadingCheck.phone ? true :false}
                                            right={
                                                payload.loadingCheck.phone ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.phone && payload.statusCheck.phone==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Koordinator Delivery</Text>
                                </Segment>
                                <Segment flex={1} minWidth={204}>
                                    <FormControl>
                                        <AsyncSelect
                                            onChange={handleChangeCoordel}
                                            isClearable
                                            isMulti
                                            value={value.listCoodelId}
                                            loadOptions={handleLoadOptionsCoordel}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => null
                                            }}
                                            placeholder={'Nama Koordinator Delivery'}
                                        />
                                    </FormControl>
                                    {
                                        !id && <input
                                        type="hidden"
                                        name="listCoodelId"
                                        className='validate[required] validateMessage{required{Koordinator harus diisi}}'
                                        value={value.listCoodelId.length > 0 ? value.listCoodelId.length : ''} />
                                    }
                                    
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1}>
                                <Switch name="status" label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.status} />
                                </Segment>
                            </Row>
                        </Segment>
                        <Segment name="divide" height={1} bg={"#DCDEE3"} mt={12} mb={32} />
                        <Segment justifyContent="space-between" mb={24}>
                            <Text fontWeight={600} >Koordinator Delivery</Text>
                            {
                                value.listCoodelId.length > 0 && 
                                <Segment alignItems="flex-end">
                                    
                                    <Text color="#70727D"> {value.listCoodelId.length} Koordinator Delivery</Text>
                                </Segment>
                            }
                            
                        </Segment>
                        <Segment>
                            {
                                value.listCoodelId.length > 0 ? 
                                <DataTable
                                    countingList={true}
                                    //defaultSize={10}
                                    tableConsume={[{
                                        field: 'NIK',
                                        rowField: 'nik',
                                        
                                    }, {
                                        field: 'Nama',
                                        rowField: 'name'
                                    }, {
                                        field: 'Klaster',
                                        isCustomRow: (id, entity) => {
                                            return `${entity.clusterTotal??0} Klaster` 
                                        }
                                    }]}
                                    dataConsume={value.listCoodelId}
                                />
                                :
                                <Segment boxShadow="0px 0px 4px 0px #8080806b" minHeight={300} justifyContent="center" alignItems="center">
                                    <Segment display="inline-block" style={{textAlign:"center"}}>
                                        <img src={IconX} alt="Icon X"/>
                                        <Text fontSize={14} color="#9C9DA6" mt={8}>Koordinator Delivery belum dipilih</Text>
                                    </Segment>
                                </Segment>
                            }
                            
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={id ? `/supervisor-delivery/${id}` : "/supervisor-delivery"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={!(!disablePreview && payload.statusCheck.nik==='00' && payload.statusCheck.email==='00'&&payload.statusCheck.phone==='00')}
                                >
                                    Simpan
                                </Button>
                                :
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handlePreview()}
                                    disabled={!(!disablePreview&&payload.statusCheck.nik==='00' && payload.statusCheck.email==='00'&&payload.statusCheck.phone==='00')}
                                    
                                >
                                    Preview
                                </Button>
                                
                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button onClick={handleSubmit} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Form