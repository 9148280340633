export const requestOrderList = properties => {
  return {
      type: "REQUEST_ORDER_LIST",
      payload: { 
        properties, 
        loading:true
      }
  }
}

export const requestFileUpload = (url) => {
  return {
      type: "REQUEST_FILE_UPLOAD",
      payload: { 
        url
      }
  }
}

export const resetFileUpload = () => {
  return {
      type: "RESET_FILE_UPLOAD",
      
  }
}

export const requestOrderListById = id => {
  return {
      type: "REQUEST_ORDER_LIST_BY_ID",
      payload: { id }
  }
}

export const requestFilterCluster = params => {
  return {
      type: "REQUEST_FILTER_CLUSTER",
      payload: { params }
  }
}

export const requestFilterCancelReason = orderStatus => {
  return {
      type: "REQUEST_FILTER_CANCEL_REASON",
      payload: { orderStatus }
  }
}

export const requestOrderListInterval = properties => {
  return {
      type: "REQUEST_ORDER_LIST_INTERVAL"
  }
}

export const requestSummaryTotalOrderTab = params =>{
  return {
      type: "REQUEST_ORDER_LIST_TOTAL_ORDER_TAB",
      payload:{
        params
      }
  }
}

export const requestFilterChannel = params => {
  return {
      type: "REQUEST_FILTER_CHANNEL",
      
  }
}