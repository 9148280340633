import React, { Fragment, useState, useEffect } from 'react'
import {
    Segment,
    Text
} from "@elevenia/master-ui/components/Atom"
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import Breadcrumb from 'component/Breadcrumb'
import TabRetur from './_component/TabRetur'
import TabDiterima from './_component/TabDiterima'
import TabDibatalkan from './_component/TabDibatalkan'
import TabPembatalan from './_component/TabPembatalan'
import TabFallback from './_component/TabFallback'
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestOrderList, requestFilterCluster, requestSummaryTotalOrderTab, requestFilterChannel } from 'store/actions/orderList'
import { Subject } from 'rxjs'
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import Error404 from 'page/errorLayout'
import moment from 'moment'
import { getLabelTimeZone } from 'helper'
import { listOptionsKategori } from 'helper/Order'
let initlistStatusOrderCount = 'orderStatusList=IN_RETURN&orderStatusList=CANCELING'
const Pesanan = props => {
    document.title = props.title
    const history = props.history
    const urlParams = new URLSearchParams(history.location.search)
    const paramsQueryactiveTab = urlParams.get("activeTab")
    const paramsQueryDate = urlParams.get("date")
    const paramsQueryChannelOrder = urlParams.get("channelOrder")??""
    const paramsQueryOrderTypeGroup = urlParams.get("orderTypeGroup")??""
    const dataBreadcrumb = [{ to: '', label: 'Retur dan Pembatalan' }]
    const [activeTab, setActiveTab] = useState(paramsQueryactiveTab ?? "IN_RETURN");
    const [historyDate, setHistoryDate] = useState(paramsQueryDate)
    const { hasFetch } = useAction();
    const [status, hasStatus] = useState("hasLoad")
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    const [categorySearch, setCategorySearch] = useState(1)
    let initialIsTable = {
        page: 0,
        size: 30,
        searchParam: "",
        orderStatus:activeTab,
        channelOrder: paramsQueryChannelOrder,
        orderTypeGroup: paramsQueryOrderTypeGroup
    }

    let listStatusOrderCount = ''
    if(paramsQueryDate){
        listStatusOrderCount = `${initlistStatusOrderCount}&endDate=${paramsQueryDate}`
        if(activeTab==='FALLBACK'){
            initialIsTable = {
                ...initialIsTable, 
                startDateHistory:moment(paramsQueryDate,'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00.000'),
                endDateHistory:paramsQueryDate
            }
        }
        
    }else{
        listStatusOrderCount = initlistStatusOrderCount
        if(['RETURN_COMPLETED','CANCELED','FALLBACK'].includes(activeTab)){
            initialIsTable = {
                ...initialIsTable,
                startDate: moment().format('YYYY-MM-DDT00:00:00.000'),
                endDate: moment().format('YYYY-MM-DDT23:59:59.999')
            }
        }
    }

    if(paramsQueryChannelOrder){
        listStatusOrderCount = `${initlistStatusOrderCount}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
    }

    
    const [isTable, setTable] = useState(initialIsTable);

    const payload = useSelector(state => {
        return {
            data: state.orderList.data,
            loading: state.orderList.loading,
            totalPages: state.orderList.totalPages,
            parameter: state.orderList.parameter,
            statName: state.setAlertsToast.notify.statName,
            filterCluster : state.orderList.filterCluster,
            loadingCluster : state.orderList.loadingCluster,
            timeStamp:state.orderList.timeStamp,
            dataTotalOrder:state.orderList.dataSummayOrder,
            loadingSummary: state.orderList.loadingSummary,
            loadingChannel: state.orderList.loadingChannel,
            filterChannel: state.orderList.filterChannel,
        }
    })

    useEffect(()=>{
        props.access?.read && hasFetch(requestSummaryTotalOrderTab(listStatusOrderCount))
    },[hasFetch,props.access,listStatusOrderCount])

    useEffect(()=>{
      status=== "hasLoad" && props.access?.read && hasFetch(requestOrderList(isTable))
      status=== "hasPagination" && props.access?.read && hasFetch(requestOrderList(isTable))
    },[hasFetch,isTable,status, props.access])

    useEffect(()=>{
        if(props.access?.read) {
            hasFetch(requestFilterCluster())
            hasFetch(requestFilterChannel())
        }  
    },[hasFetch,props.access])

    useEffect(()=>{
        
        //const tabActive = paramsQueryactiveTab ?? 'ALLOCATING'
        if(paramsQueryDate){
            history.replace({
                search: `?activeTab=${activeTab}&date=${paramsQueryDate}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }else{
            history.replace({
                search: `?activeTab=${activeTab}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }
        
    },[history,activeTab,paramsQueryDate, paramsQueryOrderTypeGroup, paramsQueryChannelOrder])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestOrderList(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            searchParam: value.trim(),
            searchParamType: categorySearch
        });
    }

   

    const handleOnChangeTab = (tab) => {
        setActiveTab(tab)
        let tempIsTable = initialIsTable
        if(initialIsTable.hasOwnProperty('startDateHistory')){
            delete tempIsTable.startDateHistory
            delete tempIsTable.endDateHistory
        }
        
        if(!['RETURN_COMPLETED','CANCELED','FALLBACK','COMPLETED'].includes(tab)){
            delete tempIsTable.startDate
            delete tempIsTable.endDate
        }else{
            tempIsTable = {
                ...tempIsTable,
                startDate: moment().format('YYYY-MM-DDT00:00:00.000'),
                endDate: moment().format('YYYY-MM-DDT23:59:59.999')
            }
        }
        setHistoryDate(null)
        if(paramsQueryDate){
            history.replace({
                search: `?activeTab=${tab}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }
        setTable({
            ...tempIsTable,
            orderStatus:tab
        })
        hasFetch(requestSummaryTotalOrderTab(initlistStatusOrderCount))
        setCategorySearch(1)
    }

    
    
    
    const handlePagination = (page) => {
        
        const query = {
            ...payload.parameter,
            ...page
        }
        hasStatus('hasPagination')
        setTable(query)
    }

    const listOptionsServ = [
        { value: "-", label: "-" },
        { value: "isBulky", label: "Bulky" },
        { value: "isCod", label: "COD" },
        { value: "isBulkyisCod", label: "Bulky & COD" },
        { value: "isBulkyIsReturn", label: "Bulky & Retur" },
        { value: "all", label: "COD, Bulky & Retur" }
    ];
    
    
    const tabs = [{
        key: "IN_RETURN",
        showTotal:true,
        label: "Retur",
        component: <TabRetur 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        listOptionsServ={listOptionsServ}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                    />
    }, {
        key: "RETURN_COMPLETED",
        label: "Diterima Toko",
        component: <TabDiterima 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        listOptionsServ={listOptionsServ}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                        />
    }, {
        key: "CANCELING",
        showTotal:true,
        label: "Pembatalan",
        component: <TabPembatalan 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        listOptionsServ={listOptionsServ}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                    />
    }, {
        key: "CANCELED",
        label: "Selesai Dibatalkan",
        component: <TabDibatalkan 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        defaultDate={historyDate ? new Date(historyDate) : null}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        listOptionsServ={listOptionsServ}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                    />
    }, {
        key: "FALLBACK",
        label: "Fallback & Titip",
        component: <TabFallback 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        defaultDate={historyDate ? new Date(historyDate) : null}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        listOptionsServ={listOptionsServ}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                    />
    }
]
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Retur dan Pembatalan</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active =>
                        
                        active !== activeTab ? handleOnChangeTab(active) : null
                    }>
                        {tabs.map((item) => <Text key={item.key}>{item.label} {(item.showTotal && !payload.loadingSummary) ? `(${payload.dataTotalOrder[item.key] ?? 0})` : ''} </Text>)}
                    </Tabs>
                    <Segment mt={32}>
                        {tabs.map((item, idx) => activeTab === item.key && <Fragment key={idx}>{item.component}</Fragment>)}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default Pesanan