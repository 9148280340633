import {
    Badge, Button, ButtonGroup, FormControl,
    Icon, OptionBox, Segment,
    Text, Textfield
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { fetcher, objectToQueryString, capitalize } from 'helper'
import Error404 from 'page/errorLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import useSWR from 'swr'
import { useAction } from "hooks"
import DownloadExcel from 'helper/DownloadExcel'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Services } from 'service'
import { resetForm } from 'store/actions/cluster'
import FilterChannel from "component/FilterChannel"

const dataBreadcrumb = [
    { to: '', label: 'Klaster' }
]

const Klaster = props => {
    document.title = props.title
    const IconXls = require("assets/image/icon-xls.svg");
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const [statusDownload, setStatusDownload] = useState("idle")
    const [onSearch$] = useState(() => new Subject());
    const paramsSearch = new URLSearchParams(props.history.location.search).get("searchParam")
    const supervisorId = new URLSearchParams(props.history.location.search).get("supervisorId")
    const managerId = new URLSearchParams(props.history.location.search).get("managerId")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        searchParam: paramsSearch??"",
        sort: "clusterName,asc",
        active:true,
        supervisorId:supervisorId??'',
        managerId: managerId??''
    });

    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data:dataCluster, error } = useSWR((isTable && props.access?.read) &&  `/store/api/back-office/clusters?${objectToQueryString(isTable)}`,fetcher,optionsSwr)
    
    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message?error.message : 'Not Found' } })
        }
    },[hasFetch, error])

    useEffect(()=>{
        props.access?.read && hasFetch(resetForm())
    },[hasFetch, props.access])

    const payload = useSelector(state => {
        return {
            data: dataCluster?.data.content,
            loading: !dataCluster && !error ,
            totalPages: dataCluster?.data.totalPages,
            statName: state.setAlertsToast.notify.statName,
            
        }
    })


    useEffect(() => {
        
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                value.searchParam.length > 3  && setTable(value)
                value.searchParam.length === 0 && setTable(value)
            })
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = (e) => {
        const { value } = e.target
        
        const query = {
            ...isTable,
            searchParam: value,
            page: 0
        }
        onSearch$.next(query)            
    }

    const listOptions = [
        { value: true, label: "Aktif" },
        { value: false, label: "Non-Aktif" },
    ];

    const StatusColor = (status) => {
        switch (status) {
            case true:
                return <Badge bg="#8FC742" style={{ textTransform: 'capitalize' }}>Aktif</Badge>
            case false:
                return <Badge bg="#EE2B2E" style={{ textTransform: 'capitalize' }}>Non-Aktif</Badge>
            default:
                break;
        }
    }

    const handleDonwloadExcel = async () => {
       

        setStatusDownload("pending")
        Services().get('/store/api/back-office/cluster-detail-list',{...isTable, unpaged: true})
        .then(async (data)=>{
            const contentRow = data.data.data.content?.map((val,index)=>{
                return {
                    ...val,
                    no: index + 1,
                    stores: val.storeCodes ? val.storeCodes?.join(", ") : 0,
                    delimans: val.driverNiks ? val.driverNiks?.join(", ") : 0,
                    status: val.active ? 'Aktif' : 'Non-Aktif' ,
                    channel: val.channel ? capitalize(val.channel) : '-'
                }
            })
            const columns = [
                { header: "No", key: "no", width: 5 },
                { header: "ID Klaster", key: "clusterCode", width: 19 },
                { header: "Nama Klaster", key: "clusterName", width: 19 },
                { header: "Wilayah", key: "postalCodeDescription", width: 45 },
                { header: "Koordinator", key: "coordinatorName", width: 15 },
                { header: "Toko", key: "stores", width: 15 },
                { header: "Delivery Man", key: "delimans", width: 20 },
                { header: "Channel", key: "channel", width: 20 },
                { header: "Status Klaster", key: "status", width: 20 }
            ]
            
            const creator       = 'Ipp Application'
            const createdDate   = creator
            const modifiedDate  = new Date()
            const sheetName     = "Sheet 1"
            const fileName      = `Master  - Klaster ${moment(new Date()).format("DD MM YYYY")}`;
            const buffer        = await DownloadExcel({creator, createdDate, modifiedDate, sheetName, columns, contentRow});
            
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, fileName + fileExtension);
            setStatusDownload('resolved')
        })
        .catch((e)=>{
            setStatusDownload('rejected')
        })
        
         
    }


    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Klaster</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Button onClick={()=>handleDonwloadExcel()} mr={16} variant="secondary">
                        {
                            statusDownload==="pending" ? 'Loading...' : <>
                            <img src={IconXls} alt="xls" style={{marginRight: 8}}/>
                            Export XLS
                            </>
                        }
                    </Button>
                    <Link to={"/klaster/create"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Nama Klaster / Kode Klaster / Wilayah / Koordinator'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <FilterChannel 
                                handleChange={e=>{
                                    setTable(prev=>({
                                        ...prev,
                                        channel:e
                                    }))
                                }}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={listOptions.filter(val=>val.value===isTable.active)[0]}
                                options={listOptions}
                                placeholder={"Status"}
                                isClearable
                                onChange={(e)=>{
                                    let value = ''
                                    if(e) value=e.value
                                    setTable(prev=>({
                                        ...prev,
                                        active:value
                                    }))
                                }}
                            />
                        </FormControl>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    defaultSize={isTable.size}
                    tableConsume={[{
                        field: 'ID Klaster',
                        sortField:'clusterCode',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={`/klaster/${entity.id}`}>{entity.clusterCode}</Link>
                            )
                        }
                    }, {
                        field: 'Nama Klaster',
                        rowField:'clusterName',
                        sortField:'clusterName'
                    },{
                        field: 'Wilayah',
                        isCustomRow: (id, entity) => {
                            return entity.postalCodeDescription ?? '-'
                        },
                        sortField:'postalCodeDescription'
                    },{
                        field: 'Koordinator',
                        isCustomRow: (id, entity) => {
                            return entity.coordinatorName ?? '-'
                        }
                    }, {
                        field: 'Toko',
                        rowField: 'totalStore'
                    }, {
                        field: 'Delivery Man',
                        rowField: 'totalDriver'
                    }, {
                        field: 'Channel',
                        rowField: 'channel',
                        isCustomRow: (id, entity) => {
                            return (
                                entity.channel ? capitalize(entity.channel) : '-'
                            )
                        },
                    }, {
                        field: 'Status',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{StatusColor(entity.active)}</>
                            )
                        },

                    }]}
                    dataConsume={payload?.data}
                    isLoading={payload?.loading}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload?.totalPages}
                    activePage={isTable?.page}
                    callSorted={(name,direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            sort:`${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            page:0,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...isTable,
                            ...page
                        }
                        
                        setTable(query)
                    }}
                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Klaster?"}
                content={<Segment py={8}>Semua informasi mengenai Klaster ini akan dihapus dari sistem.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Klaster