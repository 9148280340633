import { Segment, Spinner, Table, Text } from "@elevenia/master-ui/components/Atom"
import React, { useRef, useState } from "react"

const TableInfinitiedScroll = (
    {
        countingList=false,
        defaultSize = 10,
        tableConsume = [],
        dataConsume = [],
        isLoading = false,
        addItemCount = 10
    }
) => {
    const tBodyRef = useRef(null)
    const trRef = useRef(null)
    const [size, setSize] = useState(defaultSize)
    const [currentCount, setCurrentCount] = useState(defaultSize)
    const handleScroll = () => {
        
        if(parseInt(tBodyRef.current.scrollHeight - tBodyRef.current.scrollTop) <= tBodyRef.current.clientHeight){
            let addItem = addItemCount
            
            if(currentCount !== dataConsume.length){
                if(currentCount > dataConsume.length){
                    addItem = currentCount - dataConsume.length    
                }
                
                setSize(size+addItem)
                setCurrentCount(size+addItem)
            }
            
        }
    }

    return (
        <Table responsive round>
            <thead className="table-head">
                
                <tr ref={trRef}>
                    {countingList && <th><Text H14 color={"black70"}>No</Text></th>}
                    {
                        tableConsume.map((item, index) => {
                            let size = item.width ? item.width : 0
                            
                            return (
                                <th className={item.headerStyle} width={size} key={index}>
                                    {
                        
                                            <Text H14 >{item.field}</Text>
                                            
                                    }
                                </th>
                            )
                        })
                    }
                </tr>
                
            </thead>
            <tbody ref={tBodyRef} onScroll={()=>{handleScroll()}}>
                    {
                        isLoading ?
                        <tr>
                            <td colSpan={tableConsume.length + 1}>
                                <Segment className="u-tx-center" width="100%" height={30}>
                                    <Spinner />
                                </Segment>
                            </td>
                        </tr>
                        :
                        dataConsume.length === 0 ?
                            <tr>
                                <td colSpan={tableConsume.length + 1}>
                                    <Segment className="u-tx-center" width="100%" height={30}>
                                        No data
                            </Segment>
                                </td>
                            </tr> :
                            dataConsume.slice(0,size).map((item, index) => {
                                
                                return (
                                    <tr key={index}>
                                       {countingList && <td>{((1 - 1) * size) + index + 1}</td>}
                                        {
                                            tableConsume.map((child, indexChild) => {
                                                
                                                return (
                                                    <td key={indexChild} className={child.textStyle}>
                                                        {
                                                            child.isCustomRow ? child.isCustomRow(item[child.entityFilters], item) : item[child.rowField]
                                                        }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                    }
            </tbody>
        </Table>
            
    )
}

export default TableInfinitiedScroll