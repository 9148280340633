import React, { useState, useEffect } from 'react';
import {
    Segment,
    Text,
    Button,
    FormControl,
    Row,
    ButtonGroup,
    Spinner,
    OptionBox,
    Icon,
    RadioButton,
} from '@elevenia/master-ui/components/Atom';
import ModalSmall from 'component/ModalCustom/modalSmall';
import Breadcrumb from 'component/Breadcrumb';
import { Services } from 'service';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useInput, useAction } from 'hooks';
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout';
import {
    requestOrderListByIdDeliman,
    requestSubmitReleaseLocking,
    requestListDuration,
    requestDismissError,
    requestApprovalReleaseLocking,
} from 'store/actions/release-locking';
import { validateInput } from 'helper';

const CreateReleaseForm = (props) => {
    if (!props.access) return <></>;
    else if (!props.access?.create) return <Error404 />;
    return <>{<Form {...props} />}</>;
};

const Form = (props) => {
    document.title = props.title;
    const history = props.history;
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [disabledButton, setDisableButton] = useState(true);
    const dataBreadcrumb = [
        { to: '/release-lock', label: 'Release Lock' },
        { to: '', label: 'Buat Baru' },
    ];

    const { hasFetch } = useAction();

    const payload = useSelector((state) => {
        return {
            orderListById: state.releaseLocking.orderListById?.orderIdList,
            loadingGetOrder: state.releaseLocking.loadingGetOrder,
            listDuration: state.releaseLocking.listDuration,
            loadingDuration: state.releaseLocking.loadingDuration,
            loading: state.releaseLocking.loading,
            error: state.releaseLocking.error,
            listType: state.homesidemenu?.action_group
                ?.find((val) => val.code === 'release-locking')
                ?.actions?.filter((action) =>
                    action.code.includes('locking-type') && action.allowed
                ),
            loadingType: state.homesidemenu?.loading,
        };
    });

    useEffect(() => {
        if (payload?.error?.status === 'RL01' && isModalCreate) {
            setIsModalCreate(!isModalCreate);
        }
    }, [payload, isModalCreate, setIsModalCreate]);

    useEffect(() => {
        props.access?.read && hasFetch(requestListDuration());
    }, [hasFetch, props.access]);

    let { value, setValue, bindSelect, bindChange } = useInput({
        initialObjects: {
            deliman: '',
            orderList: null,
            duration: '',
            type: '',
        },
        identity: 'myForm',
    });

    useEffect(() => {
        payload.listDuration &&
            setValue((prev) => {
                return {
                    ...prev,
                    duration:
                        payload.listDuration.length > 0
                            ? payload.listDuration.find((val) => val.id === 1)
                                  ?.id
                            : null,
                };
            });
    }, [payload.listDuration, setValue]);

    useEffect(() => {
        const disabled = Object.values(value).filter((val) => {
            if (Array.isArray(val)) {
                return val.length === 0;
            } else {
                return val === '' || val === null;
            }
        });
        if (disabled.length) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [value]);

    useEffect(() => {
        setValue((prev) => {
            return {
                ...prev,
                orderList: payload.orderListById ?? null,
            };
        });
    }, [payload.orderListById, setValue]);

    const handleLoadOptions = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(
                `/driver/api/back-office/search/${inputValue}`
            );
            const listData = response.data.data.content;
            callback(
                listData?.map((val) => ({
                    label: `${val.nik} - ${val.name}`,
                    value: val.id,
                    nik: val.nik,
                    name: val.name,
                }))
            );
        }

        if (inputValue.length === 0) {
            const response = await Services().get(
                `/driver/api/back-office/search/${inputValue}`
            );
            const listData = response.data.data.content;

            callback(
                listData?.map((val) => ({
                    label: `${val.nik} - ${val.name}`,
                    value: val.id,
                    nik: val.nik,
                    name: val.name,
                }))
            );
        }
    };

    const handleChangeDeliman = (data) => {
        if (data) {
            hasFetch(
                requestOrderListByIdDeliman({
                    deliverymanId: data.value,
                    type: value.type,
                })
            );
            setValue((prev) => {
                return {
                    ...prev,
                    deliman: data,
                };
            });
        } else {
            setValue((prev) => {
                return {
                    ...prev,
                    deliman: '',
                    orderList: null,
                };
            });
        }

        setTimeout(() => {
            validateInput('myForm', 'deliman');
        }, 0);
    };

    // create release lock
    const handleSubmit = () => {
        const dataSubmit = {
            deliverymanId: value.deliman.value,
            deliverymanName: value.deliman.name,
            deliverymanNik: value.deliman.nik,
            releaseConfigId: value.duration,
            type: value.type,
        };

        hasFetch(requestSubmitReleaseLocking(dataSubmit, history));
    };

    // request approval release lock
    const handleReqRelease = () => {
        const reqApproval = {
            deliverymanId: value.deliman.value,
            deliverymanName: value.deliman.name,
            deliverymanNik: value.deliman.nik,
            releaseConfigId: value.duration,
            type: value.type,
        };

        hasFetch(requestApprovalReleaseLocking(reqApproval, history));
    };

    const DropdownIndicator = (props) => {
        const menuIsOpen = props.selectProps.menuIsOpen;

        return (
            <components.DropdownIndicator {...props}>
                <Icon
                    name={menuIsOpen ? 'chevron-up' : 'chevron-down'}
                    size={'16px'}
                    fillColor={'black50'}
                />
            </components.DropdownIndicator>
        );
    };

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={'1rem'}>
                    Buat Baru
                </Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form
                    id='myForm'
                    autoComplete='false'
                    onSubmit={(e) => e.preventDefault()}
                >
                    <Segment boxShadow bg={'white'} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>
                                Informasi Release
                            </Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Tipe Release</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <RadioButton
                                            radioProps={{
                                                onChange: (e) => {
                                                    bindChange.onChange(e);
                                                    const { value:type } = e.target;
                                                    setValue((prev) => {
                                                        return {
                                                            ...prev,
                                                            type: type,
                                                        };
                                                    });
                                                    if(value?.deliman!==''){
                                                        hasFetch(
                                                            requestOrderListByIdDeliman({
                                                                deliverymanId: value?.deliman?.value,
                                                                type: type,
                                                            })
                                                        );
                                                    }
                                                    console.log({ e });
                                                },
                                                className: 'validate[required]',
                                                name: 'type',
                                                id: 'type',
                                            }}
                                            selected={value.type}
                                            radioItems={payload?.listType}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Delivery Man</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        className={'custom-helptext'}
                                        helptext='Release Lock digunakan untuk membuka penjagaan terkait pesanan COD di halaman retur dan pengiriman'
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeDeliman}
                                            isClearable={true}
                                            defaultInputValue={
                                                value.deliman
                                                    ? value.deliman
                                                    : ''
                                            }
                                            loadOptions={handleLoadOptions}
                                            name='deliman'
                                            styles={{
                                                placeholder: (
                                                    defaultStyles
                                                ) => {
                                                    return {
                                                        ...defaultStyles,
                                                    };
                                                },
                                                control: (defaultStyles) => ({
                                                    ...defaultStyles,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator,
                                            }}
                                            inputClassName={
                                                'validate[required] validateMessage{required{Deliman Harus dipilih}}'
                                            }
                                            placeholder={'Pilih Delivery Man'}
                                        />
                                    </FormControl>
                                    <input
                                        type='hidden'
                                        name='postalCodeDescription'
                                        className='validate[required] validateMessage{required{Deliman Harus dipilih}}'
                                        value={value.deliman ?? ''}
                                    />
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Durasi</Text>
                                </Segment>
                                <Segment flex={1}>
                                    {!payload.loadingDuration && (
                                        <FormControl>
                                            <OptionBox
                                                {...bindSelect}
                                                placeholder={
                                                    'Pilih Durasi Release Lock'
                                                }
                                                name='duration'
                                                className='validate[required]'
                                                isClearable
                                                // defaultValue={payload.listDuration?.map(val => ({ ...val, value: val.id, label: `${val.value} Jam` })).filter(val=>val.id===1)}
                                                isDisabled={
                                                    payload.loadingDuration
                                                }
                                                options={payload.listDuration?.map(
                                                    (val) => ({
                                                        ...val,
                                                        value: val.id,
                                                        label: `${val.value} Jam`,
                                                    })
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Order ID</Text>
                                </Segment>
                                <Text mr={8}>:</Text>
                                <Segment flex={1}>
                                    {payload.loadingGetOrder && (
                                        <Segment
                                            className='u-tx-center'
                                            width={30}
                                            height={30}
                                        >
                                            <Spinner />
                                        </Segment>
                                    )}
                                    {value.orderList && value.orderList?.length
                                        ? value.orderList?.map((val, index) => (
                                              <Text key={index} mb={12}>
                                                  {val}
                                              </Text>
                                          ))
                                        : '-'}
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={'flex-end'}>
                        <Segment>
                            <Button
                                variant='primary'
                                size='medium'
                                type={'button'}
                                disabled={disabledButton}
                                onClick={() => setIsModalCreate(!isModalCreate)}
                            >
                                Buat
                            </Button>
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalSmall
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={'Buat Baru Release Lock?'}
                content={
                    <Segment py={8}>
                        Delivery man ini akan diberikan Release Lock sehingga
                        dapat menjemput pesanan COD baru selama{' '}
                        <strong>
                            {value.duration
                                ? `${
                                      payload.listDuration.find(
                                          (val) => val.id === value.duration
                                      ).value
                                  }`
                                : '-'}
                        </strong>{' '}
                        jam
                    </Segment>
                }
                ButtonFooter={
                    <ButtonGroup>
                        <Button
                            minWidth={'100px'}
                            variant={'secondary'}
                            onClick={() => setIsModalCreate(!isModalCreate)}
                        >
                            Batal
                        </Button>
                        <Button
                            disabled={payload?.loading}
                            onClick={() => {
                                !payload?.loading && handleSubmit();
                            }}
                            minWidth={'100px'}
                        >
                            Release Lock
                        </Button>
                    </ButtonGroup>
                }
            />

            {/* modal req approval release lock */}
            <ModalSmall
                isOpen={payload?.error?.status === 'RL01'}
                onClose={() => {
                    setIsModalCreate(false);
                    hasFetch(requestDismissError());
                }}
                title={'Anda Mencapai Batas Release Lock'}
                content={
                    <>
                        <Segment py={12}>
                            Anda sudah melakukan 5 kali release lock pada hari ini untuk
                            Delivery Man tersebut. Untuk membuat release lock, silakan
                            mengajukan persetujuan kepada atasan Anda.
                        </Segment>
                        <Segment py={12}>
                            Klik tombol “Ajukan Approval Manager” untuk mengajukan persetujuan.
                            Release lock akan dibuat setelah Manajer menyetujui pengajuan.
                        </Segment>
                    </>
                }
                ButtonFooter={
                    <ButtonGroup>
                        <Button
                            minWidth={'100px'}
                            variant={'secondary'}
                            onClick={() => {
                                setIsModalCreate(false);
                                hasFetch(requestDismissError());
                            }}
                            style={{ flexGrow: 0 }}
                        >
                            Batal
                        </Button>
                        <Button
                            disabled={payload?.loading}
                            onClick={() => {
                                !payload?.loading && handleReqRelease();
                            }}
                            minWidth={'100px'}
                        >
                            Ajukan Approval Manajer
                        </Button>
                    </ButtonGroup>
                }
            />
        </>
    );
};

export default CreateReleaseForm;
