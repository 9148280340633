import React, { useState, useRef } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    Spinner,
} from '@elevenia/master-ui/components/Atom';
import Breadcrumb from 'component/Breadcrumb';
import { useAction } from 'hooks';
import ResultImportedData from './_component/ResultImportedData';
import Error404 from 'page/errorLayout';
import Template from 'assets/template/ABSENSI_DELIVERY_MAN_TEMPLATE.xlsx';
import useDataUpload, { DataUploadProvider } from './_utils/DataContex';
import { processFn, uploadFn } from './_utils';
import ActionSection from './_component/ActionSection';
import { useHistory } from 'react-router-dom';
import { ModalConfirm, useModalConfirm } from './_component/ModalConfirm';

const dataBreadcrumb = [
    { to: '/absensi-deliveryman', label: 'Pengaturan Absensi Delivery Man' },
    { to: '', label: 'Upload Jadwal' }
];

const MAX_FILE_SIZE = 2000000; // byte

const ButtonCariFile = ({ uploadResult, handleFile, setInputError }) => {
    const { isUpload, loadingUpload } = useDataUpload();
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];

        if (fileUploaded?.size > MAX_FILE_SIZE) {
            setInputError(true);
            handleFile('');
        } else {
            setInputError(false);
            handleFile(fileUploaded);
        };
    };

    return <>
        <Button
            style={{ minWidth: 86 }}
            ml={12}
            size="medium"
            type={"button"}
            onClick={handleClick}
            disabled={isUpload && uploadResult?.id && loadingUpload !== "rejected"}
        >
            Cari File

            {/* hidden input file */}
            <input
                accept='<input id="fileSelect" type="file" accept=".csv,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </Button>
    </>
};

const ConfigRKBForm = props => {
    if (!props.access) return <></>;
    else if (!props.access?.create) return <Error404 />;

    return (
        <Form {...props} />
    );
};

const Form = ({ title }) => {
    document.title = title;
    const history = useHistory();
    const IconDownload = require("assets/image/download.svg");
    const [getFile, setGetFile] = useState("");
    const [isUpload, setIsUpload] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState("idle");
    const [loadingSubmit, setLoadingSubmit] = useState('idle');
    const { hasFetch } = useAction();
    const [inputError, setInputError] = useState(false);

    const modalConfirm = useModalConfirm();

    const handleConfirmation = () => {
        modalConfirm.show({
            title: 'Buat Jadwal Delivery Man?',
            content: 'Daftar jadwal untuk delivery man akan disimpan.',
            onSubmit: handleProses
        });
    };

    const handleProses = async () => {
        modalConfirm.setIsLoading(true);
        setLoadingSubmit('pending');
        try {
            await processFn(uploadResult);
            hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal baru berhasil dibuat' } });
            modalConfirm.setIsLoading(false);
            modalConfirm.hide();
            history.push('/absensi-deliveryman?activeTab=Deliman');
        } catch (error) {
            modalConfirm.setIsLoading(false);
            modalConfirm.hide();
            setLoadingSubmit("rejected");
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } });
        };
    };

    const [uploadResult, setUploadResult] = useState(null);

    const handleUpload = async () => {
        //setIsUpload(true);
        const form = new FormData();
        form.append('file', getFile);
        setLoadingUpload("pending");
        try {
            setIsUpload(true);
            const doUpload = await uploadFn(form);
            setUploadResult(doUpload);
            setLoadingUpload("resolved");
        } catch (error) {
            setIsUpload(false);
            setLoadingUpload("rejected");
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } });
        };
    };

    const filename = 'ABSENSI DELIVERY MAN TEMPLATE.xlsx';
    const link = useRef(null);

    const handleDownloadFile = () => {
        link.current.click();
    };

    return (
        <DataUploadProvider
            value={{
                isUpload,
                loadingUpload,
                loadingSubmit
            }}
            uploadResult={uploadResult}
        >
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Upload Delivery Man</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Import Data</Text>
                            <Segment>
                                <Row mb={20}>
                                    <Segment width={280} py={8}>
                                        <Text>File</Text>
                                    </Segment>
                                    <Segment flex={1} display="flex" className="xs-flex-wrap">
                                        <FormControl
                                            helptext={`${!inputError ? 'XLS atau CSV file. ' : ''}Ukuran maksimum file 2 MB.`}
                                            style={{ flex: "1" }}
                                            status={inputError ? 'error' : 'normal'}
                                            className="custom-helptext"
                                        >
                                            <Textfield
                                                inputProps={{
                                                    onChange: () => { },
                                                    placeholder: "Pilih File",
                                                    value: getFile?.name ?? ""
                                                }}
                                                disabled={true}
                                            />
                                        </FormControl>
                                        <Segment>
                                            <ButtonCariFile uploadResult={uploadResult} handleFile={setGetFile} setInputError={setInputError} />
                                        </Segment>
                                    </Segment>
                                </Row>
                                {!isUpload && (
                                    <Segment justifyContent="flex-end">
                                        <Button onClick={handleDownloadFile} mr={16} variant="secondary" style={{ color: "#151823" }}>
                                            <a
                                                ref={link}
                                                style={{ display: 'none' }}
                                                download={filename}
                                                target="_blank"
                                                href={Template}
                                                rel="noopener noreferrer"
                                            >download</a>
                                            <img src={IconDownload} alt="Download" style={{ marginRight: 8 }} />
                                            Download Template
                                        </Button>
                                    </Segment>)}
                            </Segment>
                            {isUpload ?
                                loadingUpload === "pending" ?
                                    <Segment className="u-tx-center" width="100%" height={30}>
                                        <Spinner />
                                    </Segment> :
                                    <>
                                        <ResultImportedData />
                                    </> : ''
                            }
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <ActionSection
                            actionUpload={handleUpload}
                            actionProcess={handleConfirmation}
                            getFile={getFile}
                        />
                    </Segment>
                </form>
            </Segment>
            <ModalConfirm config={modalConfirm.config} toggle={modalConfirm.hide} />
        </DataUploadProvider>
    )
}

export default ConfigRKBForm