import React, { useEffect } from 'react'
import {
    Segment,
    Text,
    Row,
    Spinner
} from '@elevenia/master-ui/components/Atom'
import { Link } from 'react-router-dom'
import { useAction } from 'hooks';
import { useSelector } from 'react-redux';
import { requestDataDashboard} from 'store/actions/dashboard'
import Error404 from 'page/errorLayout'
import moment from 'moment'
import 'moment/locale/id';
import { getLabelTimeZone, serviceTypeDashboard, colorOrderId } from 'helper'
import SectionOrderStatus from 'page/dashboard/_components/SectionOrderStatus';
import TableDashboard from 'page/dashboard/_components/TableDashboard';
import SectionSLAReview from 'page/dashboard/_components/SectionSLAReview';
import SectionCancelOrder from 'page/dashboard/_components/SectionCancelOrder';
import { payloadDashboard } from 'page/dashboard/_helper';
const Dashboard = (props) => {
    document.title = props.title
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return payloadDashboard(state)
    })

    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)

    useEffect(() => {
        props.access?.read && hasFetch(requestDataDashboard({
            channelOrder: 'INDOGROSIR'
        }))

    }, [hasFetch, props.access])

    // useEffect(() => {
    //     let intervalId = null
    //     if (props.access?.read) {
    //         intervalId = setInterval(() => {
    //             hasFetch(requestDataDashboardInterval({
    //                 channelOrder: 'INDOGROSIR'
    //             }))
    //         }, 30000)
    //     }

    //     return () => {
    //         clearInterval(intervalId)
    //     }
    // }, [hasFetch, props.access])

    const dataSummaryStatus =  [
        {
            status: 'ALLOCATING',
            color: '#FDC194',
            titleStatus: 'Pesanan Baru',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'PICKING_UP',
            color: '#B7E27D',
            titleStatus: 'penjemputan',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'IN_DELIVERY',
            color: '#F99698',
            titleStatus: 'Pengiriman',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'COMPLETED',
            color: '#6CA5E0',
            titleStatus: 'Selesai',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'FALLBACK',
            color: '#FCB731',
            titleStatus: 'Fallback & Titip',
            path: 'pembatalan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
    ] 
    
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30} justifyContent={"space-between"} className="xs-block">
                <Text H28 mb={"1rem"}>Dashboard Indogrosir</Text>
                <Segment display="inline-block">
                    {
                        payload.loadingOrderSummary
                            ? <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment>
                            : <Segment className="label-dashboard">
                                {payload.dateTime ? `${moment(payload.dateTime).locale('id').format('dddd, DD MMMM YYYY - HH:mm:ss')} ${timezoneLabel}` : '-'}
                            </Segment>
                    }

                </Segment>
            </Segment>
            <Segment>
                <SectionOrderStatus
                    loadingOrderSummary= {payload.loadingOrderSummary}
                    dataOrderSummary= {payload.dataOrderSummary}
                    dataStatus= {dataSummaryStatus}
                    addOnQueryString={'&channelOrder=INDOGROSIR'}
                />
                <Row mt={32} justifyContent="space-between" className="sm-flex-wrap">
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                            title ={<Text H16 mb={8}>
                                Pesanan Belum Dijemput <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 6 Jam
                            </Text>}
                            countData = {{
                                text:payload?.totalOrderAbnormalPickup??'-',
                                bgColor: '#FFA800'
                            }}
                            columns = {[{
                                field: 'AWB ID',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                    )
                                }
                            }, {
                                field: 'Kode Toko',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Segment width={"95%"}>{entity.storeCode}</Segment>
                                    )
                                }
                            }, {
                                field: 'Nama Cabang',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                    )
                                },
                            }, {
                                field: 'Mulai Jemput',
                                isCustomRow: (id, entity) => {
                                    return entity.minDeliveryTime ? `${moment(entity.minDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                    
                                },
                            }, {
                                field: 'Service',
                                isCustomRow: (id, entity) => {
                                    return   serviceTypeDashboard(entity)
                                },
                            }
                            ]}
                            loading={payload?.loadingAbnormalPickup}
                            data={payload?.dataAbnormalPickup ?? []}
                        />
                    </Segment>
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                            title ={<Text H16 mb={8}>Pesanan Belum Dikirim <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 24 Jam</Text>}
                            countData = {{
                                text:payload?.totalOrderAbnormalInDelivery??'-',
                                bgColor: '#FFA800'
                            }}
                            columns = {[{
                                field: 'AWB ID',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                    )
                                }
                            }, {
                                field: 'Kode Toko',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Segment width={"95%"}>{entity.storeCode}</Segment>
                                    )
                                }
                            }, {
                                field: 'Nama Cabang',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                    )
                                },
                            }, {
                                field: 'Batas Kirim',
                                isCustomRow: (id, entity) => {
                                    return entity.maxDeliveryTime ? `${moment(entity.maxDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                    
                                },
                            }, {
                                field: 'Service',
                                isCustomRow: (id, entity) => {
                                    return   serviceTypeDashboard(entity)
                                },
                            }
                            ]}
                            loading={payload?.loadingAbnormalInDelivery}
                            data={payload?.dataAbnormalInDelivery ?? []}
                        />
                    </Segment>
                </Row>
                <Row mt={32} justifyContent="space-between" className="sm-flex-wrap">
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                                title ={<Text H16 mb={8}>
                                    Pesanan Belum Selesai Pembatalan
                                </Text>}
                                countData = {{
                                    text:payload?.totalOrderNotYetCanceled??'-',
                                    bgColor: '#FFA800'
                                }}
                                columns = {[{
                                    field: 'AWB ID',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Segment width={"95%"}>{entity.storeCode}</Segment>
                                        )
                                    }
                                }, {
                                    field: 'Nama Cabang',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                        )
                                    },
                                }, {
                                    field: 'Tanggal Pengajuan',
                                    isCustomRow: (id, entity) => {
                                        return entity.cancelingDate ? `${moment(entity.canceledgDate).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                        
                                    },
                                }, {
                                    field: 'Service',
                                    isCustomRow: (id, entity) => {
                                        return   serviceTypeDashboard(entity)
                                    },
                                }
                                ]}
                                loading={payload?.loadingNotYetCanceled}
                                data={payload?.dataNotYetCanceled ?? []}
                            />
                    </Segment>
                </Row>
                <Segment mt={32}>
                    <Row justifyContent="space-between" className="sm-flex-wrap">
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16} >
                            <SectionSLAReview payload={payload} />
                        </Segment>
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16}>
                            <SectionCancelOrder payload={payload}/>
                        </Segment>
                    </Row>
                </Segment>
            </Segment>
        </>
    )
}

export default Dashboard