import {
    FormControl,
    Icon,
    OptionBox,
    Segment,
    Textfield
} from '@elevenia/master-ui/components/Atom';
import DataTable from 'component/DataTable';
import {
    debounce,
    fetcher,
    getLabelTimeZone,
    objectToQueryString
} from 'helper';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import ModalForm from './ModalForm';
//import { capitalizeMany } from './TabRiwayat';

const searchOptions = [
    {
        label: 'Nama Toko',
        value: 1
    },
    {
        label: 'Nama Klaster',
        value: 2
    }
]

const TakAktif = (props) => {
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60);
    const [isModal, setIsModal] = useState(false);
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: '',
        searchParamType: 1
        // sort: "closingTime,desc"
    });
    const optionsSwr = {
        revalidateOnFocus: false,
        refreshInterval: 0,
    };
    const { data: dataAccess, error, mutate } = useSWR(
        isTable &&
        `/store/api/back-office/store-configs?${objectToQueryString(
            isTable
        )}`,
        fetcher,
        optionsSwr
    );

    const payload = useSelector((state) => {
        return {
            data: dataAccess?.data?.content,
            loading: !dataAccess && !error,
            totalPages: dataAccess?.data?.totalPages,
            statName: state.setAlertsToast.notify.statName,
        };
    });

    const [selectedData, setSelectedData] = useState(null);
    const handleShowDetail = (data) => {
        setSelectedData(data);
        setIsModal(true);
    };

    const handleSearch = debounce((name, value) => {
        setTable((prev) => ({
            ...prev,
            page: 0,
            [name]: value
        }));
    }, 500);

    return (
        <>
            <Segment
                className={"group-filter"}
                boxShadow={"0 1px 4px 0 #70727d33"}
                borderRadius={4}
                bg={"white"}
                p={20}
                borderBottom={"1px solid #DCDEE3"}
                mb={24}
                display="flex"
            >
                <Segment alignItems="center" pr={16} style={{ fontSize: '16px', textWrap: 'nowrap' }}>
                    Cari berdasarkan:
                </Segment>
                <Segment width="100%" maxWidth={190} pr={16}>
                    <FormControl>
                        <OptionBox
                            name='searchParamType'
                            className='validate[required]'
                            // isClearable
                            defaultValue={searchOptions[0]}
                            options={searchOptions}
                            onChange={(e) => handleSearch('searchParamType', e.value)}
                        />
                    </FormControl>
                </Segment>
                <Segment width="100%" maxWidth={350}>
                    <FormControl>
                        <Textfield
                            inputProps={{
                                onChange: (e) => handleSearch('keyword', e.target.value),
                                placeholder: `Cari ${searchOptions.find((type) => type.value === isTable.searchParamType)?.label ?? 'Nama'}`
                            }}
                            left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                        />
                    </FormControl>
                </Segment>
            </Segment>
            <DataTable
                countingList={true}
                defaultSize={isTable.size}
                activePage={isTable.page}
                tableConsume={[
                    {
                        field: 'Kode Toko',
                        isCustomRow: (id, entity) => {
                            return <>{entity.storeCode}</>;
                        },
                    },
                    {
                        field: 'Nama Toko',
                        isCustomRow: (id, entity) => {
                            return <>{entity.storeName}</>;
                        },
                    },
                    {
                        field: 'Klaster',
                        isCustomRow: (id, entity) => {
                            return <>{entity.clusterName}</>;
                        },
                    },
                    {
                        field: 'Default',
                        isCustomRow: (id, entity) => {
                            return <>{entity.useDefault ? 'Ya' : 'Tidak'}</>;
                        },
                    },
                    {
                        field: 'Jumlah Validasi',
                        isCustomRow: (id, entity) => {
                            return <>{entity?.maxCodValidation}</>;
                        },
                    },
                    {
                        field: 'Terakhir Diperbarui',
                        isCustomRow: (id, entity) => {
                            return <>
                                {entity.modifiedDate ? `${moment(entity.modifiedDate).format(
                                    'DD/MM/YY - HH:mm'
                                )} ${timezoneLabel}`
                                    : '-'}
                            </>;
                        },
                    },
                    {
                        field: 'Diperbarui Oleh',
                        isCustomRow: (id, entity) => {
                            return (
                                <>
                                    {entity.modifiedBy}
                                </>
                            );
                        },
                    },
                    {
                        field: 'Opsi',
                        isCustomRow: (_, entity) => {
                            return props.access?.update ? (
                                <Segment style={{
                                    cursor: 'pointer'
                                }}
                                    onClick={() => {
                                        handleShowDetail(entity)
                                    }}
                                >
                                    <Icon
                                        name={'edit'}
                                        size={24}
                                        fillColor={'#70727D'}
                                    />
                                </Segment>
                            ) : '';
                        },
                    },
                ]}
                dataConsume={payload?.data}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={payload?.totalPages}
                createShowPerSize={(size) =>
                    setTable((prev) => {
                        return {
                            ...prev,

                            size: size.value,
                        };
                    })
                }
                createPaginations={(page) => {
                    const query = {
                        ...isTable,
                        ...page,
                    };
                    setTable(query);
                }}
            />

            {/* modal info */}
            {isModal && selectedData && (
                <ModalForm
                    detailId={selectedData}
                    isModal={isModal}
                    setIsModal={setIsModal}
                    refetch={mutate}
                />
            )}
        </>
    );
};

export default TakAktif;
