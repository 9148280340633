import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup
} from "@elevenia/master-ui/components/Atom";
import { requestCreateMenuAccessRight, requestMenuAccessRightById } from 'store/actions/menuaccessright';
import { useAction } from 'hooks';
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import Breadcrumb from 'component/Breadcrumb'
import ModalSmall from 'component/ModalCustom/modalSmall';
import { reverseName, rotateOtorisasi, constructActionGroup } from 'helper/Generics';

const DetailMenuAccess = (props) => {
    document.title = props.title;
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [disabled, setdisabled] = useState(false)
    const getPath = props.match.params.id;
    const { hasFetch } = useAction();
    useEffect(() => {
        props.access?.read && getPath && hasFetch(requestMenuAccessRightById({ id: getPath }))
    }, [hasFetch, getPath, props.access])
    const payload = useSelector(state => {
        return {
            updateData: getPath ? state.menuAccessRight.updateData : props.location.state?.updateData,
            statusOrder: getPath ? state.menuAccessRight.action_group.map(val=>({...val,id:0})) : props.location.state?.updateData.statusOrder,
            statName: state.setAlertsToast.notify.statName
        }
    })
    useEffect(() => {
        payload.statName === "success" && setdisabled(false)
        payload.statName === "error" && setdisabled(false)
    }, [payload.statName])
    const title = getPath ? 'Detail Otoritas Pengguna' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: `/authorization/`, label: 'Otoritas Pengguna' },
        { to: '', label: title }
    ]
    
  
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow bg={"white"} p={24} borderRadius={4} mb={32}>
                    <Segment display="flex" justifyContent="space-between">
                        <Text fontWeight={600} mb={32}>Informasi Otorisasi</Text>
                    </Segment>
                    <Segment mb={24} display="flex" className="xs-flex-wrap">
                        <Segment minWidth={280}>
                            <Text color={'#70727D'}>Nama Otoritas</Text>
                        </Segment>
                        <Segment display="flex">
                            <Text mr={8}>:</Text>
                            {payload.updateData?.name}
                        </Segment>
                    </Segment>
                    <Segment mb={24} display="flex" className="xs-flex-wrap">
                        <Segment minWidth={280}>
                            <Text color={'#70727D'}>Status</Text>
                        </Segment>
                        <Segment display="flex">
                            <Text mr={8}>:</Text>
                            {payload.updateData?.status && payload.updateData?.status}
                        </Segment>
                    </Segment>
                    <Segment>
                        {
                            payload.updateData?.rules.map((raw, index) => {
                                return (
                                    <Segment key={index}>
                                        <Segment height={1} bg={'#DCDEE3'} mb={32} />
                                        <Text fontWeight={600} mb={40}> {raw.name}</Text>
                                        {
                                            raw.menus.map((rawMenus, indexMenus) => {
                                                const x = [
                                                    rawMenus.accessRights.read && "read",
                                                    rawMenus.accessRights.create && "create",
                                                    rawMenus.accessRights.update && "update",
                                                    rawMenus.accessRights.delete && "delete",
                                                ];
                                                const filter = rotateOtorisasi(x).filter(item => item !== false && item !== "delete")
                                                return (
                                                    <Segment key={indexMenus} mb={24} display="flex" className="xs-flex-wrap">
                                                        <Segment minWidth={280}>
                                                            <Text color={'#70727D'}>{rawMenus.label}</Text>
                                                        </Segment>
                                                        <Segment display="flex">
                                                            <Text mr={8}>:</Text>
                                                            {
                                                                filter.length === 0 ? '-' :
                                                                    filter.map((val, key) =>
                                                                        <Segment key={key}
                                                                            mr={12}
                                                                            p={8}
                                                                            borderRadius={16}
                                                                            bg={'#1178D4'}
                                                                            color={'white'}
                                                                            className={"badge-checkbox"}
                                                                            style={{ fontSize: "12px", textTransform: "capitalize" }}
                                                                        >
                                                                            {reverseName(val)}
                                                                        </Segment>
                                                                    )
                                                            }
                                                        </Segment>
                                                    </Segment>
                                                )
                                            })
                                        }
                                    </Segment>
                                )
                            })
                        }
                    </Segment>
                    
                    
                        {
                            payload.statusOrder.map((parentVal,index)=>{
                                return(
                                    <Segment key={index}>
                                        <Text fontWeight={600} mb={40}>{parentVal.name}</Text>
                                        {
                                            parentVal?.actions.map((val,i)=>(
                                                <Segment key={i} mb={24} display="flex" className="xs-flex-wrap">
                                                    <Segment minWidth={280}>
                                                        <Text color={'#70727D'}>{val.label}</Text>
                                                    </Segment>
                                                    <Segment display="flex">
                                                        <Text mr={8}>:</Text>
                                                        <Segment
                                                            mr={12}
                                                            p={8}
                                                            borderRadius={16}
                                                            bg={'#1178D4'}
                                                            color={'white'}
                                                            className={"badge-checkbox"}
                                                            style={{ fontSize: "12px", textTransform: "capitalize" }}
                                                        >
                                                            {val.allowed?'Ijinkan':'Tidak diijinkan'}
                                                        </Segment>
                                                    </Segment>
                                                </Segment>
                                            ))
                                        }
                                    </Segment>
                                )
                            })
                        }
                        <Segment height={1} bg={'#DCDEE3'} mb={32} />
                        
                </Segment>
                <Segment justifyContent="flex-end">
                    {
                        !getPath && (
                            <>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                    onClick={() => props.history.replace({
                                        pathname: `/authorization/create`,
                                        state: props.location.state
                                    })}>Atur Ulang</Button>
                                <Button onClick={() => setIsModalCreate(!isModalCreate)}>Buat</Button>
                            </>
                        )
                    }
                    {
                        props.access?.update && getPath && (<Button onClick={() => props.history.replace(`/authorization/${getPath}/edit`)}>Edit</Button>)
                    }
                </Segment>
            </Segment>
            {/* modal confirm */}
            <ModalSmall
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Buat Otoritas Pengguna Baru ?"}
                content={<Segment py={8}>Semua Otorisasi Pengguna yang anda masukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button disabled={disabled} onClick={() => {
                                setdisabled(true)
                                hasFetch(requestCreateMenuAccessRight({ 
                                    value: props.location.state?.updateData.value, 
                                    id: getPath, history: props.history, 
                                    action_group:constructActionGroup(payload.updateData.statusOrder.map(val=>{
                                        return{
                                            ...val,
                                            actions:val.actions.map(vals=>({...vals,id:0}))
                                        }
                                    })) 
                                }))
                            }} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default DetailMenuAccess