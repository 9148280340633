import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    FormControl,
    Icon,
    Textfield,
    DatePicker,
    Tooltip,
    TooltipTarget,
    TooltipContent,
    Badge
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestPhotoReport } from 'store/actions/photoReport';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { serviceTypeDashboard, getDuration, formatCountDown, getLabelTimeZone } from 'helper'
import StatusColorSLA from 'component/StatusColorSLA'
import moment  from 'moment'
import Error404 from "page/errorLayout";

const dataBreadcrumb = [
    { to: '', label: 'Foto Pesanan' }
]

const CardImg = ({ imgSrc }) => {
    return <Segment width={100} height={100} bg={imgSrc ? "transparent" : ""} borderRadius={4} justifyContent="center" alignItems="center" p={4}>
        {imgSrc ? <img src={imgSrc} alt="bukti" style={{ maxWidth: "100%", maxHeight: '100%' }} /> :
            '-'// <Icon name={"image"} size={24} fillColor="#70727D" />
        }
    </Segment>
}

const orderStatusFotoReport = (status) => {
    const parsingStatus = {
        COMPLETED: {
            text:'Selesai',
            color:'#8FC742'
        },
        IN_RETURN: {
            text:'Retur',
            color:'#FFA800'
        },
        RETURN_COMPLETED: {
            text:'Diterima Toko',
            color:'#1178D4'
        },
        CANCELED: {
            text:'Selesai Dibatalkan',
            color:'#EE2B2E'
        },
        FAILED: {
            text:'Selesai Dibatalkan',
            color:'#EE2B2E',
        },
    }
    return parsingStatus[status]
}

const FotoPesanan = props => {
    document.title = props.title
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    const { hasFetch } = useAction();
       
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        searchParam: ""
    });
    const payload = useSelector(state => {
        return {
            data: state.photoReport.data,
            loading: state.photoReport.loading,
            totalPages: state.photoReport.totalPages,
            parameter: state.photoReport.parameter,
            
        }
    })

    

    useEffect(()=>{
        
        props.access?.read && hasFetch(requestPhotoReport(isTable))
        
      },[hasFetch,isTable, props.access])

    

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                if(value.searchParam){
                    hasFetch(requestPhotoReport(value))
                    if(!value.hasOwnProperty('endDate') && !value.hasOwnProperty('startDate')){
                        
                        const startDate = moment().add(-6,'days').format('YYYY-MM-DDT00:00:00.000')
                        const endDate = moment().format('YYYY-MM-DDT23:59:59.999')
                        setTable(prev=>{
                            return{
                                ...prev,
                                startDate,
                                endDate
                            }
                        })
                    }
                }else{
                    hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Mohon isi data deliman/toko pada search bar' } });
                    hasFetch({
                        type: 'GET_PHOTO_REPORT',
                        payload: {
                            data: [],
                            parameter: value,
                            totalPages: 0,
                            
                        }
                    })
                    
                }
                
            })
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        
        
        onSearch$.next({
            ...isTable,
            searchParam: value.trim()
        });
      
        
    }

    const handleFilterDate = (date,key) => {
        
        const dateFormat = date ? moment(date).format(key==='startDate' ? 'YYYY-MM-DDT00:00:00.000' : 'YYYY-MM-DDT23:59:59.999') : null
        if(!payload.parameter?.searchParam){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Mohon isi data deliman/toko pada search bar' } });
            return
        }
        if(isTable.startDate && key==='endDate'){
            const diffDate  = moment(isTable.startDate).diff(moment(date),'days')
            
            if((Math.abs(diffDate) + 1) > 7){
                
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Range tanggal tidak boleh melebihi 7 hari' } });
                setTable(prev=>{
                    return{
                        ...prev,
                        ...payload.parameter,
                        [key]:''
                    }
                })
                return
            }
            
        }
        if(isTable.endDate && key==='startDate'){
            const diffDate  = moment(isTable.endDate).diff(moment(date),'days')
            if((Math.abs(diffDate) + 1) > 7){
                
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Range tanggal tidak boleh melebihi 7 hari' } });
                setTable(prev=>{
                    return{
                        ...prev,
                        ...payload.parameter,
                        [key]:''
                    }
                })
                return
            }
            
        }
        
        setTable(prev=>{
            return{
                ...prev,
                ...payload.parameter,
                [key]:dateFormat
            }
        })
    }

    if (!props.access) return <></>;
    else if (!props.access?.read) return <Error404 />;

    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Foto Pesanan</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    justifyContent="space-between"
                    className={"group-filter"}
                >
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Delivery Man atau Toko'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    
                    <Segment alignItems="center">
                        <Segment alignItems="center" className={"group-filter"}>
                            <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
                                <FormControl>
                                    <DatePicker
                                        maxDate={isTable.endDate ? moment(isTable.endDate).toDate() : new Date()}
                                        onChange={date => handleFilterDate(date,'startDate')}
                                        selectsStart
                                        startDate={isTable.startDate ? moment(isTable.startDate).toDate() : null}
                                        endDate={isTable.endDate ? moment(isTable.endDate).toDate() : null}
                                        placeholderText='Tanggal Awal'
                                        selected={isTable.startDate ? moment(isTable.startDate).toDate() : null}
                                        isClearable={isTable.startDate ? true : false}
                                        right={<Icon name={"date"} size={24} fillColor="#70727D" />}
                                    />
                                </FormControl>
                            </Segment>
                            {/* separator */}
                            <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                            {/* separator */}
                            <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                                <FormControl>
                                    <DatePicker
                                        maxDate={new Date()}
                                        placeholderText='Tanggal Akhir'
                                        selected={isTable.endDate ? moment(isTable.endDate).toDate() : null}
                                        onChange={date => handleFilterDate(date,'endDate')}
                                        selectsEnd
                                        startDate={isTable.startDate ? moment(isTable.startDate).toDate() : null}
                                        endDate={isTable.endDate ? moment(isTable.endDate).toDate() : null}
                                        minDate={isTable.startDate ? moment(isTable.startDate).toDate() : null}
                                        isClearable={isTable.endDate ? true : false}
                                        right={<Icon name={"date"} size={24} fillColor="#70727D" />}
                                    />
                                </FormControl>
                            </Segment>
                        </Segment>
                        <Segment display="flex" alignItems="center">
                            <Tooltip type="hover" placement="right" color="dark">
                                <TooltipTarget>
                                    <Icon name={"information"} size={18} fillColor={'#70727D'} ml={12} />
                                </TooltipTarget>
                                <TooltipContent style={{ width: 185 }}>
                                    <Text>Hanya untuk menunjukkan data dengan interval maksimal 7 hari</Text>
                                </TooltipContent>
                            </Tooltip>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    defaultSize={isTable.size}
                    tableConsume={[{
                        field: 'Foto Pengiriman',
                        isCustomRow: (id, entity) => {
                            return (
                                <CardImg imgSrc={entity.deliveryImageUrl} />
                            )
                        },
                    }, {
                        field: 'Struk COD',
                        isCustomRow: (id, entity) => {
                            return (
                                <CardImg imgSrc={entity.codImageUrl} />
                            )
                        }
                    }, {
                        field: 'Struk Retur',
                        isCustomRow: (id, entity) => {
                            return (
                                <CardImg imgSrc={entity.bulkyImageUrl} />
                            )
                        }
                    }, {
                        field: 'Foto Pembatalan',
                        isCustomRow: (id, entity) => {
                            return (
                                <CardImg imgSrc={entity.cancelImageUrl} />
                            )
                        }
                    }, {
                        field: 'Order ID',
                        rowField: 'orderId',
                    }, {
                        field: 'Kategori',
                        rowField: 'serviceType',
                    }, {
                        field: 'Sales Order',
                        rowField: 'salesOrderId',
                    }, {
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return (
                                `${entity.driverNIK} - ${entity.driverName}`
                            )
                        },
                        width:100
                    }, {
                        field: 'Klaster',
                        isCustomRow: (id, entity) => {
                            return (
                                entity.clusterCode ? `${entity.clusterCode} - ${entity.clusterName}` : '-'
                            )
                        }
                    }, {
                        field: 'Service',
                        isCustomRow: (id, entity)=>{
                            return serviceTypeDashboard(entity)
                        }
                    }, {
                        field: 'SLA',
                        isCustomRow: (id, entity) => {
                            
                            const duration = getDuration(entity.minDeliveryTime,entity.completedTime)
                            const completedTime = moment(entity.completedTime).unix()
                            const maxDelivTime = moment(entity.maxDeliveryTime).unix()
                            let colorDuration = duration
                            
                                if(completedTime > maxDelivTime ){
                                    colorDuration = 3700
                                }else if(completedTime < maxDelivTime){
                                    colorDuration = 3500
                                }else if(completedTime === maxDelivTime){
                                    colorDuration = 3500
                                }

                            
                            return (
                                <>{(entity.completedTime) ? StatusColorSLA({
                                    duration:colorDuration,
                                    text:formatCountDown(duration,true)
                                }) : '-'}</>
                            )
                        }
                    }, {
                        field: 'Batas Kirim',
                        isCustomRow: (id, entity)=>{
                            return `${moment(entity.minDeliveryTime).format('HH:mm')} - ${moment(entity.maxDeliveryTime).format('HH:mm')} ${timezoneLabel}`
                        }
                    }, {
                        field: 'Tanggal Kirim',
                        isCustomRow: (id,entity) => {
                            return <>{entity.minDeliveryTime ? moment(entity.minDeliveryTime).format('DD/MM/YYYY') : '-'} </>
                        }
                    }, {
                        field: 'Waktu Selesai',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.completedTime ? `${moment(entity.completedTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-' }</>
                            )
                        },
                    }, {
                        field: 'Status',
                        isCustomRow: (id, entity) => {
                            const status = orderStatusFotoReport(entity.orderStatus)
                            return (
                                <Badge bg={status.color} fontSize="12">
                                        {status.text}
                                </Badge>
                            )
                        },
                    }]}
                    dataConsume={payload.data}
                    isLoading={payload.loading}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload.totalPages}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...payload.parameter,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...payload.parameter,
                            ...page
                        }
                        setTable(query)
                    }}
                />
            </Segment>
        </>
    )
}

export default FotoPesanan
