import React, { useState } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Icon,
    Textfield,
    OptionBox,
    ButtonGroup,
    DatePicker
} from "@elevenia/master-ui/components/Atom"
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'

const dataBreadcrumb = [
    { to: '', label: 'Absensi' }
]

const Absensi = props => {
    document.title = props.title
    const [isModal, setIsModal] = useState(false)
    const [data, setData] = useState({});
    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: new Date(value)
        });
    };
    const dataTable = [
        {
            id: 5325723,
            name: 'Absensi 1',
            delivMan: '1 Delivery Man',
            toko: '1 Toko',
            aktivitas: 'logout',
            waktu: '-'
        },
        {
            id: 5325748,
            name: 'Absensi 2',
            delivMan: '2 Delivery Man',
            toko: '1 Toko',
            aktivitas: 'login',
            waktu: '-'
        },
    ]

    const listOptions = [
        { value: "01", label: "Option 1" },
        { value: "02", label: "Option 2" },
    ];

    const StatusColor = (status) => {
        switch (status) {
            case "login":
                return <Text fontWeight="500" color="green20" style={{ textTransform: "capitalize" }}>{status}</Text>
            case "logout":
                return <Text fontWeight="500" color="red20" style={{ textTransform: "capitalize" }}>{status}</Text>
            default:
                break;
        }
    }

    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Absensi</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    {/* <Button mr={16} variant="secondary">
                        <Icon name={"add-document"} size={22} fillColor={"#115488"} mr={8} />
                        Export XLS
                    </Button> */}
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => { },
                                    placeholder: 'Cari Nama Klaster'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <DatePicker
                                placeholderText="Tanggal"
                                selected={data.singleDate}
                                onChange={data => onChange("singleDate", data)}
                                right={<Icon name={"date"} size={24} />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={""}
                                options={listOptions}
                                placeholder={"Aktivitas"}
                                isClearable
                            />
                        </FormControl>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    defaultSize={10}
                    tableConsume={[{
                        field: 'Kode Klaster',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={'/absensi/12345'}>{entity.id}</Link>
                            )
                        }
                    }, {
                        field: 'Nama Klaster',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.name}</>
                            )
                        }
                    }, {
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.delivMan}</>
                            )
                        }
                    }, {
                        field: 'Aktivitas',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{StatusColor(entity.aktivitas)}</>
                            )
                        }
                    }, {
                        field: 'Waktu',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.waktu}</>
                            )
                        },

                    }]}
                    dataConsume={dataTable}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={5}
                    createPaginations={(page) => { }}
                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Absensi?"}
                content={<Segment py={8}>Semua informasi mengenai Absensi ini akan dihapus dari sistem.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Absensi