import { constructObjectAsServices } from "helper/Generics";

export const requestParameterCRUD = () => {
    return dispatch => {
        dispatch({
            type: "REQUEST_PARAMETER_CRUD_MENU_ACCESS_RIGHT"
        })
    }
}

export const requestMenuAccessRightById = ({ id }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_MENU_ACCESS_RIGHT_ID",
            payload: { id }
        })
    }
}

export const requestReadMenuAccessRight = ({ isTable }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_READ_MENU_ACCESS_RIGHT",
            payload: {
                parameter: isTable
            }
        })
    }
}

export const requestCreateMenuAccessRight = ({ value, history, action_group }) => {
    return (dispatch, store) => {
        const { rules } = store().menuAccessRight;
        const ObjectMenuConstructs = Object.assign({}, ...constructObjectAsServices({ value }))
        rules['name'] = value.roleName
        rules['status'] = value.status ? "active" : "inactive"
        const retrievePayload = rules.menu_group.map(setFirst => {
            return {
                ...setFirst,
                menus: setFirst.menus.map(val => {
                    return {
                        ...val,
                        accessRights: {
                            ...val.accessRights,
                            ...ObjectMenuConstructs[val.code]
                        }
                    }
                })
            }
        })
        dispatch({
            type: "REQUEST_CREATE_MENU_ACCESS_RIGHT",
            payload: {
                ...rules,
                menu_group: retrievePayload,
                action_group: action_group
            },
            history
        })
    }
}

export const requestUpdateMenuAccessRight = ({ value, id, history, action_group }) => {
    return (dispatch, store) => {
        const { rules } = store().menuAccessRight;
        const ObjectMenuConstructs = Object.assign({}, ...constructObjectAsServices({ value }))
        rules['name'] = value.roleName
        rules['id'] = parseInt(id)
        rules['status'] = value.status ? "active" : "inactive"
        const retrievePayload = rules.menu_group.map(setFirst => {
            return {
                ...setFirst,
                menus: setFirst.menus.map(val => {
                    return {
                        ...val,
                        accessRights: {
                            ...val.accessRights,
                            ...ObjectMenuConstructs[val.code]
                        }
                    }
                })
            }
        })
        dispatch({
            type: "REQUEST_UPDATE_MENU_ACCESS_RIGHT",
            payload: {
                id,
                data: {
                    ...rules,
                    menu_group: retrievePayload,
                    action_group: action_group
                }
            },
            history
        })
    }
}

export const requestDeleteMenuAccessRight = ({ id }) => {
    return (dispatch) => {
        dispatch({
            type: "REQUEST_DELETE_MENU_ACCESS_RIGHT",
            payload: {
                id,
            }
        })
    }
}