import React, { useEffect } from 'react'
import {
    Segment,
    Text,
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import { useParams } from 'react-router-dom'
import { useAction } from 'hooks'
import { requestDetailActivityLog } from 'store/actions/activity-log'
import { useSelector } from 'react-redux'
import Error404 from 'page/errorLayout'
import { getLabelTimeZone } from "helper"
const MonitoringAplikasiDetail = props => {
    document.title = props.title
    const { id } = useParams()
    const { hasFetch } = useAction()
    const title = 'Detail Aktivitas'
    const dataBreadcrumb = [{ to: '/monitoring-aplikasi', label: 'Monitoring Aplikasi' }, { to: '', label: title }]
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    useEffect(() => {
        props.access?.read && hasFetch(requestDetailActivityLog({ id }))
    }, [hasFetch, id, props.access])
    const StatusColor = (status) => {
        switch (status) {
            case "login":
                return <Text fontWeight="500" color="green20" style={{ textTransform: "capitalize" }}>{status}</Text>
            case "logout":
                return <Text fontWeight="500" color="red20" style={{ textTransform: "capitalize" }}>{status}</Text>
            default:
                break;
        }
    }
    const payload = useSelector(state => ({ ...state.activityLog.detail }))
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={40}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi</Text>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kode Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {payload.clusterCode}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {payload.clusterName}
                            </Segment>
                        </Segment>
                        <Segment display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Tanggal</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {payload.activityDate}
                            </Segment>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    defaultSize={10}
                    tableConsume={[{
                        field: 'NIK Delivery Man',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.deliveryManNik}</>
                            )
                        },
                        width:"25%"
                    }, {
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.deliveryManName}</>
                            )
                        },
                        width:"25%"
                    }, {
                        field: 'Aktivitas',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{StatusColor(entity.activity)}</>
                            )
                        },
                        width:"25%"
                    }, {
                        field: 'Waktu',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.timeLabel !== "-" ? `${entity.timeLabel} ${timezoneLabel}` : "-"}</>
                            )
                        },
                        width:"25%"
                    }
                    ]}
                    dataConsume={payload.activities}
                    showSize={false}
                    showPagination={false}
                    totalPages={0}
                // createPaginations={(page) => { }}
                />
            </Segment>
        </>
    )
}

export default MonitoringAplikasiDetail