
const initialState = {
    data: "",
    totalPages:0,
    parameter:null,
    loading: false,
    detailData:null,
    orderListById:null,
    loadingGetOrder:false,
    listDuration:null,
    loadingDuration:false
  }
  
  export const releaseAccess = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_RELEASE_ACCESS':
            return{
                ...state,
                loading:true,
                orderListById:null
            }
        case 'REQUEST_RELEASE_ACCESS_INTERVAL':
            return{
                ...state,
                loading:false
            }
        case 'GET_RELEASE_ACCESS':
            return{
                ...state,
                data:action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,
                loading: false,
            }
        case 'REQUEST_RELEASE_ACCESS_HISTORY':
            return{
                ...state,
                loading:true,
                orderListById:null
            }
        case 'GET_RELEASE_ACCESS_HISTORY':
            return{
                ...state,
                data:action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,
                loading: false,
            }
        case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_ACCESS':
            return {
                ...state,
                loadingGetOrder: true,
            }
        case 'GET_ORDER_LIST_BY_ID_DELIMAN_ACCESS':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: action.payload.orderList
            }
        case 'RELEASE_ACCESS_SUCCESS':
            return{
                ...state,
                loadingGetOrder: false,
                orderListById: null,
                loading: false
            }
        
        case 'REQUEST_DURATION_RELEASE_ACCESS':
            return {
                ...state,
                loadingDuration:true
            }
        case 'GET_DURATION_RELEASE_ACCESS':
            return {
                ...state,
                loadingDuration: false,
                listDuration: action.payload.data
            }
        case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS_RELEASE_ACCESS':
            return {
                ...state,
                loadingGetOrder: true,
            }
        case 'GET_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: action.payload.orderList
            }
        case 'RESET_ORDER_LIST':
            return {
                ...state,
                loading: false,
                orderListById: null
            }
        case 'REQUEST_SUBMIT_RELEASE_ACCESS':
            return {
                ...state,
                loading: true
            }
        case 'RELEASE_ACCESS_FAILED':
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
  }