export const getInitialParams = (activeTab, paramsQueryChannelOrder, paramsQueryOrderTypeGroup) => {
    return {
        page: 0,
        size: 30,
        searchParam: "",
        orderStatus:activeTab,
        channelOrder: paramsQueryChannelOrder,
        orderTypeGroup: paramsQueryOrderTypeGroup
    }
}

export const listOptionsKategori = [
    {value: "EXPRESS", label: "Express" },
    { value: "EXPRESS-MULTIDROP", label: "Express Multidrop" },
    { value: "NEXTDAY", label: "Nextday" },
    { value: "REGULAR", label: "Regular" },
    { value: "REGULAR-PAKET", label: "Regular Paket" },
    { value: "REGULAR-SLOT", label: "Regular Slot" },
    { value: "SAMEDAY", label: "Sameday" }
];