import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    ButtonGroup,
    Switch,
    RadioButton,
    Spinner,
    Icon
} from '@elevenia/master-ui/components/Atom'
import ModalConfirm from 'component/ModalCustom/modalSmall';
import ModalDelete from 'component/ModalCustom/modalSmall';
import Breadcrumb from 'component/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import { debounce, validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { requestDeliveryManById, requestUpdateDeliveryMan, requestCheckEmail, requestCheckPhone, requestCheckNik, resetInputStatus } from 'store/actions/deliveryman'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import AsyncSelect from 'react-select/async';
import { Services } from 'service'

const SingleValue = (props) => {
    return (
        <div
            style={{
                backgroundColor: '#EFF3F6',
                padding: '4px'
            }}
        >
            {props.data.label}
        </div>
    )
}

const DeliveryManForm = props => {
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;

    useEffect(() => {
        let unmounted = false;
        if (props.access) {
            getPath !== undefined && props.access?.update && !unmounted && hasFetch(requestDeliveryManById(getPath))
        }
        return () => (unmounted = true);
    }, [hasFetch, getPath, props.access])
    const loading = useSelector(state => state.deliveryman.loading)


    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <>{(!loading) && <Form {...props} />}</>
    )
}

const parseStatus = {
    active: true,
    inactive: false
}

const Form = props => {
    document.title = props.title;
    const history = props.history;
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const { id } = useParams();
    const [disablePreview, setDisablePreview] = useState(true);

    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            detailData: state.deliveryman.detailData,
            previewData: props?.location?.state,
            loadingCheck: state.deliveryman.loadingCheck,
            messageError: state.deliveryman.messageError,
            statusCheck: state.deliveryman.statusCheck,
            userType: state.homesidemenu.profile.userType
        }
    })

    const isCoordinator = payload.userType === 'coordinator';
    const isDeleted = payload.detailData?.deleted;

    let { value, bindChange, bindChecked, setValue } = useInput({
        initialObjects: {
            name: payload?.detailData?.name ? payload?.detailData?.name : payload?.previewData?.name ? payload?.previewData?.name : '',
            nik: payload.detailData?.nik ? payload?.detailData?.nik : payload?.previewData?.nik ? payload?.previewData?.nik : '',
            email: payload.detailData?.email ? payload?.detailData?.email : payload?.previewData?.email ? payload?.previewData?.email : '',
            phone: payload.detailData?.phone ? payload?.detailData?.phone : payload?.previewData?.phone ? payload?.previewData?.phone : '',
            status: payload.detailData?.status ? parseStatus[payload?.detailData?.status] : payload?.previewData?.status ? parseStatus[payload?.previewData?.status] : true,
            employmentType: payload.detailData?.employmentType ? payload?.detailData?.employmentType : payload?.previewData?.employmentType ? payload?.previewData?.employmentType : 'Full-Time',
            vehicleType: payload.detailData?.vehicleType ? payload?.detailData?.vehicleType : payload?.previewData?.vehicleType ? payload?.previewData?.vehicleType : '',
            licensePlateSegment1: payload?.detailData?.licensePlateSegment1 ? payload?.detailData?.licensePlateSegment1 : payload?.previewData?.licensePlateSegment1 ? payload?.previewData?.licensePlateSegment1 : '',
            licensePlateSegment2: payload?.detailData?.licensePlateSegment2 ? payload?.detailData?.licensePlateSegment2 : payload?.previewData?.licensePlateSegment2 ? payload?.previewData?.licensePlateSegment2 : '',
            licensePlateSegment3: payload?.detailData?.licensePlateSegment3 ? payload?.detailData?.licensePlateSegment3 : payload?.previewData?.licensePlateSegment3 ? payload?.previewData?.licensePlateSegment3 : '',
            company: payload.detailData?.company ? payload?.detailData?.company : payload?.previewData?.company ? payload?.previewData?.company : '',
            storeBase: payload.detailData?.homeBaseStore ? { label: `${payload?.detailData?.homeBaseStore?.storeCode} - ${payload?.detailData?.homeBaseStore?.storeName} (${payload?.detailData?.homeBaseStore?.channel})` } : payload?.previewData?.storeBase ? { label: `${payload?.previewData?.storeBase?.storeCode} - ${payload?.previewData?.storeBase?.storeName} (${payload?.previewData?.storeBase?.channel})`, value: payload?.previewData?.storeBase?.value, storeCode: payload?.previewData?.storeBase?.storeCode, storeName: payload?.previewData?.storeBase?.storeName, channel: payload?.previewData?.storeBase?.storeName } : '',
        },
        identity: "myForm",
    });

    const title = id ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = id ? [
        { to: `/delivery-man/${id}`, label: 'Detail Delivery Man' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/delivery-man', label: 'Delivery Man' },
        ...dataBreadcrumbExt
    ]
    const [onSearchNik$] = useState(() => new Subject())
    const [onSearchEmail$] = useState(() => new Subject())
    const [onSearchPhone$] = useState(() => new Subject())

    useEffect(() => {
        onSearchEmail$.pipe(
            tap(() => hasFetch(resetInputStatus('email'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {

                validateInput('myForm', 'email') && hasFetch(requestCheckEmail({
                    email: value,
                    id: id ?? ''
                }))

            })
        ).subscribe()
    }, [hasFetch, onSearchEmail$, id])

    useEffect(() => {
        onSearchPhone$.pipe(
            tap(() => hasFetch(resetInputStatus('phone'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                validateInput('myForm', 'phone') && hasFetch(requestCheckPhone({
                    phone: value,
                    id: id ?? ''
                }))

            })
        ).subscribe()
    }, [hasFetch, onSearchPhone$, id])

    useEffect(() => {
        onSearchNik$.pipe(
            tap(() => hasFetch(resetInputStatus('nik'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                validateInput('myForm', 'nik') && hasFetch(requestCheckNik({
                    nik: value,
                    id: id ?? ''
                }))

            })
        ).subscribe()
    }, [hasFetch, onSearchNik$, id])

    const handleChangeNik = (e) => {
        bindChange.onChange(e)
        onSearchNik$.next(e.target.value)
    }

    const handleChangeEmail = (e) => {
        bindChange.onChange(e)
        onSearchEmail$.next(e.target.value)
    }

    const handleChangePhone = (e) => {
        bindChange.onChange(e)
        onSearchPhone$.next(e.target.value)
    }

    const handleShowConfirmation = () => {
        if (validateForm('myForm')) {
            setIsModalCreate(!isModalCreate)
        }
    }

    const handleSubmit = () => {
        const dataUpdate = {
            email: value.email,
            name: value.name,
            nik: value.nik,
            phone: value.phone,
            status: value.status ? 'active' : 'inactive',
            employmentType: value.employmentType,
            company: value.company,
            vehicleType: value.vehicleType,
            licensePlate: (value?.licensePlateSegment1 && value?.licensePlateSegment2 && value?.licensePlateSegment3) ? `${value?.licensePlateSegment1}-${value?.licensePlateSegment2}-${value?.licensePlateSegment3}` : "",
            homeBaseStoreId: value.storeBase.value,
            id: payload.detailData?.id,
        }

        hasFetch(requestUpdateDeliveryMan(payload.detailData?.id, dataUpdate, history))
    }

    useEffect(() => {
        //const disabled = Object.values(value).filter(val => val === "")

        const disabled = Object.keys(value).filter(val => val !== "storeBase").filter(val => value[val] === "")

        if (disabled.length > 0) {

            setDisablePreview(true)
        } else {

            setDisablePreview(false)
        }

    }, [value])



    const handlePreview = () => {

        if (validateForm('myForm')) {
            let dataPreview = { ...value }

            dataPreview.status = value.status ? 'active' : 'inactive'

            history.push({
                pathname: '/delivery-man/preview',
                state: dataPreview
            })
        }
    }


    const handleChangeKendaraan = e => {
        bindChange.onChange(e)
    }

    const handleChangeLicensePlate = (e) => {
        const _e = {
            ...e,
            target: {
                name: e.target?.name,
                value: e.target?.value.toUpperCase()
            }
        }
        bindChange.onChange(_e)
    }

    const handleChangeStoreBase = (e) => {
        setValue(prev => ({
            ...prev,
            storeBase: e
        }))
    }

    const handleLoadOptionStoreBase = debounce(async (inputValue, callback) => {
        const params = inputValue ? {} : {
            size: 60,
            page: 0
        }
        const response = await Services().get(`/store/api/back-office/stores`, {
            unpaged: inputValue ? true : false,
            searchParam: inputValue,
            active: true,
            ...params
        })
        const listData = response.data.data.content

        callback(listData?.map(val => ({
            label: `${val.storeCode} - ${val.storeName} (${val.channel})`,
            value: val.id,
            ...val
        })))
    }, 500);

    const handleDelete = async () => {
        setLoadingDelete(true);
        try {
            await Services().delete(`/driver/api/back-office/delete/${id}`);
            setLoadingDelete(false);
            history.push(`/delivery-man/${id}`);
        } catch (error) {
            const { message } = error;
            setLoadingDelete(false);
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } });
        };
    };

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Delivery Man</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name: 'name',
                                                placeholder: "Nama",
                                                value: value.name,
                                                maxLength: 100,
                                                className: 'validate[required]',

                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>NIK</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.nik ? 'error' : 'normal'}
                                        helptext={payload.messageError.nik}
                                    >
                                        <Textfield
                                            status={payload.messageError.nik ? 'error' : 'normal'}
                                            inputProps={{
                                                ...bindChange,
                                                onChange: (e) => handleChangeNik(e),
                                                placeholder: "NIK",
                                                name: 'nik',
                                                value: value.nik,
                                                maxLength: 10,
                                                className: 'validate[required,length[10]]',

                                            }}
                                            disabled={payload.loadingCheck.nik ? true : false}
                                            right={
                                                payload.loadingCheck.nik ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment>
                                                    : (!payload.loadingCheck.nik && payload.statusCheck.nik === '00') ? <Segment borderRadius={20} style={{ backgroundColor: "#8FC742" }} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Email</Text>
                                </Segment>
                                <Segment flex={1}>

                                    <FormControl
                                        status={payload.messageError.email ? 'error' : 'normal'}
                                        helptext={payload.messageError.email}
                                    >
                                        <Textfield
                                            status={payload.messageError.email ? 'error' : 'normal'}
                                            inputProps={{
                                                onChange: (e) => handleChangeEmail(e),
                                                placeholder: "Email",
                                                name: 'email',
                                                value: value.email,
                                                maxLength: 100,
                                                className: 'validate[required,email]',


                                            }}
                                            right={
                                                payload.loadingCheck.email ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment>
                                                    : (!payload.loadingCheck.email && payload.statusCheck.email === '00') ? <Segment borderRadius={20} style={{ backgroundColor: "#8FC742" }} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nomor Handphone</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.phone ? 'error' : 'normal'}
                                        helptext={payload.messageError.phone}
                                    >
                                        <Textfield
                                            status={payload.messageError.phone ? 'error' : 'normal'}
                                            inputProps={{
                                                onChange: (e) => handleChangePhone(e),
                                                placeholder: "Nomor Handphone",
                                                name: 'phone',
                                                value: value.phone,
                                                maxLength: 16,
                                                className: 'validate[required,phone]',

                                            }}
                                            disabled={payload.loadingCheck.phone ? true : false}
                                            right={
                                                payload.loadingCheck.phone ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment>
                                                    : (!payload.loadingCheck.phone && payload.statusCheck.phone === '00') ? <Segment borderRadius={20} style={{ backgroundColor: "#8FC742" }} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Toko Homebase</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.storeBase ? 'error' : 'normal'}
                                        helptext={payload.messageError.storeBase}
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeStoreBase}
                                            isClearable
                                            value={value.storeBase}
                                            loadOptions={handleLoadOptionStoreBase}
                                            defaultOptions
                                            placeholder={'Toko Homebase'}
                                            components={
                                                { SingleValue }
                                            }
                                        />

                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Tipe Pegawai</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Segment display="inline-block">
                                        <RadioButton
                                            radioProps={{
                                                ...bindChange,
                                                className: 'validate[required]',
                                                name: "employmentType",
                                                id: "employmentType",
                                            }}
                                            selected={value.employmentType}
                                            radioItems={[
                                                { label: "Full-Time", value: "Full-Time" },
                                                { label: "Part-Time", value: "Part-Time" },
                                                { label: "Cadangan", value: "Cadangan" }
                                            ]}
                                        />
                                    </Segment>

                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Perusahaan</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Segment display="inline-block" className="radio-wrapper">
                                        <RadioButton
                                            radioProps={{
                                                onChange: e => bindChange.onChange(e),
                                                className: 'validate[required]',
                                                name: "company",
                                                id: "company",
                                            }}
                                            selected={value.company}
                                            radioItems={[
                                                { label: "Indopaket", value: "INDOPAKET" },
                                                { label: "Indomaret", value: "INDOMARET" }
                                            ]}
                                        />
                                    </Segment>

                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Switch name="status" label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.status} />
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>

                    <Segment boxShadow bg={"white"} borderRadius={4} mt={24}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Kendaraan</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Jenis Kendaraan</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Segment display="inline-block" className="radio-wrapper">
                                        <RadioButton
                                            radioProps={{
                                                onChange: e => handleChangeKendaraan(e),
                                                className: 'validate[required]',
                                                name: "vehicleType",
                                                id: "vehicleType",
                                            }}
                                            selected={value.vehicleType}
                                            radioItems={[
                                                { label: "Motor", value: "MOTOR" },
                                                { label: "Mobil", value: "MOBIL" },
                                                { label: "Truck", value: "TRUCK" }
                                            ]}
                                        />
                                    </Segment>

                                </Segment>
                            </Row>
                            <Row>
                                <Segment width={280} py={8}>
                                    <Text>Plat Nomor Kendaraan</Text>
                                </Segment>
                                <Segment flex={1} alignItems={'flex-start'} className="xs-flex-wrap">
                                    <Segment maxWidth={69} mr={16}>
                                        <FormControl>
                                            <Textfield
                                                inputProps={{
                                                    onChange: (e) => {
                                                        handleChangeLicensePlate(e)
                                                    },
                                                    className: 'validate[required,letter]',
                                                    name: 'licensePlateSegment1',
                                                    placeholder: "Huruf",
                                                    maxLength: 2,

                                                    value: value.licensePlateSegment1

                                                }}
                                            />
                                        </FormControl>
                                    </Segment>
                                    <Segment maxWidth={74} mr={16}>
                                        <FormControl>
                                            <Textfield
                                                inputProps={{
                                                    onChange: (e) => {
                                                        handleChangeLicensePlate(e)
                                                    },
                                                    className: 'validate[required,number]',
                                                    name: 'licensePlateSegment2',
                                                    placeholder: "Angka",
                                                    maxLength: 4,
                                                    value: value.licensePlateSegment2

                                                }}
                                            />
                                        </FormControl>
                                    </Segment>
                                    <Segment maxWidth={69}>
                                        <FormControl>
                                            <Textfield
                                                inputProps={{
                                                    onChange: (e) => {
                                                        handleChangeLicensePlate(e)
                                                    },
                                                    className: 'validate[required,letter]',
                                                    name: 'licensePlateSegment3',
                                                    placeholder: "Huruf",
                                                    maxLength: 3,
                                                    value: value.licensePlateSegment3
                                                }}
                                            />
                                        </FormControl>
                                    </Segment>
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>

                    <Segment mt={32} justifyContent={"space-between"}>
                        <Segment>
                            {!isDeleted && (
                                <Button
                                    variant={"secondary"}
                                    onClick={() => setIsModalDelete(true)}
                                    disabled={isCoordinator}
                                    style={{ border: isCoordinator && 'none' }}
                                >
                                    Hapus Delivery Man
                                </Button>
                            )}
                        </Segment>
                        <Segment>
                            <Link to={id ? `/delivery-man/${id}` : "/delivery-man"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={!(!disablePreview && payload.statusCheck.nik === '00' && payload.statusCheck.email === '00' && payload.statusCheck.phone === '00')}
                                >
                                    Simpan
                                </Button>
                                :
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handlePreview()}
                                    disabled={!(!disablePreview && payload.statusCheck.nik === '00' && payload.statusCheck.email === '00' && payload.statusCheck.phone === '00')}
                                >
                                    Preview
                                </Button>

                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModalDelete}
                onClose={() => setIsModalDelete(false)}
                title="Hapus Delivery Man?"
                content={<Segment py={8}>
                    Informasi ini akan dihapus dari sistem.
                </Segment>}
                ButtonFooter={(
                    <ButtonGroup>
                        <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalDelete(false)}>
                            Batal
                        </Button>
                        <Button
                            onClick={handleDelete}
                            disabled={loadingDelete}
                            minWidth={"100px"} variant={"primary"}
                        >
                            {loadingDelete ? "Loading.." : "Hapus"}
                        </Button>
                    </ButtonGroup>
                )
                }
            />

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button onClick={handleSubmit} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default DeliveryManForm