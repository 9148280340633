const initialState = {
    data: [],
    updateData: {
        id: 0,
        name: "",
        menus: {},
        rules: [],
        status: "active"
    },
    loading: false,
    onloadChecked: false,
    totalPages: 0,
    parameter: null,
    rules: {
        "description": "",
        "id": 0,
        "menu_group": [
            {
                "code": "",
                "icon": "",
                "id": 0,
                "menus": [
                    {
                        "accessRights": {
                            "create": true,
                            "delete": true,
                            "id": 0,
                            "read": true,
                            "update": true
                        },
                        "code": "",
                        "enabled": true,
                        "id": 0,
                        "label": "",
                        "order": 0,
                        "url": ""
                    }
                ],
                "name": "",
                "order": 0
            }
        ],
        "name": "",
        "selected": true,
        "userInRoleCount": 0
    },
    action_group:[]
}
export const menuAccessRight = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_READ_MENU_ACCESS_RIGHT':
            return {
                ...state,
                loading: true,
                updateData: {
                    id: 0,
                    name: "",
                    menus: initialState.updateData.menus,
                    rules: [],
                    status: true
                }
            }
        case 'GET_READ_MENU_ACCESS_RIGHT':
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                totalPages: action.payload.totalPages,
                parameter: action.payload.parameter
            }
        case 'REQUEST_PARAMETER_CRUD_MENU_ACCESS_RIGHT':
            return {
                ...state,
                loading: true
            }
        case "GET_PARAMETER_CRUD_MENU_ACCESS_RIGHT":
            return {
                ...state,
                loading: false,
                rules: action.payload.rules,
                updateData: {
                    ...state.updateData,
                    menus: action.payload.menus,
                },
                action_group: action.payload.action_group
            }
        case 'REQUEST_MENU_ACCESS_RIGHT_ID':
            return {
                ...state,
                loading: true
            }
        case 'GET_MENU_ACCESS_RIGHT_ID':
            return {
                ...state,
                updateData: action.payload,
                rules: {
                    ...state.rules,
                    ...action.payload,
                    menu_group: action.payload.rules
                },
                action_group: action.payload.action_group,
                loading: false
            }
        case 'REQUEST_MENU_ACCESS_RIGHT_FAILED':
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}