import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    CheckBox,
    Switch,
    ButtonGroup
} from "@elevenia/master-ui/components/Atom"
import { useAction, useInput } from 'hooks';
import { useSelector } from 'react-redux';
import { requestUpdateMenuAccessRight, requestMenuAccessRightById, requestParameterCRUD } from 'store/actions/menuaccessright';
import { validateForm, constructActionGroup } from 'helper';
import { constructObjectAsServices, constructValidationCheck, reverseName, rotateOtorisasi } from 'helper/Generics';
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import Error404 from 'page/errorLayout'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { constructStateFromServices } from 'store/epics/epicMenuAccessRight';


const MenuAccessForm = (props) => {
    document.title = props.title;
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;
    useEffect(() => {
        let unmounted = false;
        if (props.access) {
            getPath === undefined && props.access?.create && !unmounted && hasFetch(requestParameterCRUD());
            getPath !== undefined && props.access?.update && !unmounted && hasFetch(requestMenuAccessRightById({ id: getPath }))
        }
        return () => (unmounted = true);
    }, [hasFetch, getPath, props.access]);
    const payload = useSelector(state => {
        return {
            loading: state.menuAccessRight.loading,
        }
    });
    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <Segment>
            {!payload.loading && props.access?.read && <Access {...props} />}
        </Segment>
    )
}
const Access = (props) => {
    const getPath = props.match.params.id;
    const { hasFetch } = useAction();
    const [disabled, setdisabled] = useState(false)
    const [isModalCreate, setIsModalCreate] = useState(false)
    const payload = useSelector(state => ({
        action_group: state.menuAccessRight.action_group,
        getMenus: state.homesidemenu.getMenus,
        updateData: state.menuAccessRight.updateData,
        statName: state.setAlertsToast.notify.statName
    }));
    let rawState = {}
    
    if (props.location.state) {
        const preview = constructStateFromServices(props.location.state?.updateData.rules)
        rawState = Object.keys(preview).map(raws => {
            const filter = preview[raws].filter(tag => tag !== 'id')
            filter.length === 3 && filter.push(raws)
            return {
                [raws]: preview[raws]?.length === 0 ? [] : filter.filter(val=>val!==null && val !== raws)
            }
        });
    } else {
        rawState = Object.keys(payload.updateData.menus).map(raws => {
            const filter = payload.updateData.menus[raws].filter(tag => tag !== 'id' && tag !== 'delete' && tag !== "createdBy" && tag !== "lastModifiedBy")
            filter.length === 3 && filter.push(raws)
            return {
                [raws]: filter.length === 0 ? [] : filter.filter(val=>val!==null && val !== raws)
            }
        });
    }
    let reuseState = Object.assign({}, ...rawState)

    let { setValue, value, bindChange, bindCheckedAllBatch, bindCheckedAll } = useInput({
        initialObjects: {
            roleName: props.location.state === undefined ? "" || payload.updateData.name : props.location.state?.updateData.name,
            status: props.location.state === undefined ? payload.updateData.status === "active" ? true : payload.updateData.status === "inactive" ? false : payload.updateData.status : props.location.state !== undefined && props.location.state?.updateData.status === "active" ? true : false,
            statusOrder: (props.location.state?.updateData)  ? props.location.state?.updateData.statusOrder : payload.action_group,
            ...reuseState
        },
        identity: "getRole"
    });
    
    const submittedRole = async (e) => {
        e.preventDefault();
        const valid = validateForm(e.target.id, true, true);
        const validateChecked = constructValidationCheck(value).includes(true)
        if (getPath === undefined) {
            if (valid && validateChecked) {
                const ObjectMenuConstructs = Object.assign({}, ...constructObjectAsServices({ value }))
                const retrieve = payload.getMenus.map(setFirst => {
                    return {
                        ...setFirst,
                        menus: setFirst.menus.map(val => {
                            return {
                                ...val,
                                accessRights: {
                                    ...val.accessRights,
                                    ...ObjectMenuConstructs[val.code]
                                }
                            }
                        })
                    }
                })
                props.history.push({
                    pathname: '/authorization/preview', state: {
                        updateData: {
                            ...payload.updateData,
                            name: value.roleName,
                            status: value.status ? "active" : "inactive",
                            rules: retrieve,
                            statusOrder: value.statusOrder,
                            value
                        }
                    }
                })
            }
            else {
                valid && hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: "Select one menu & one permission" } })
            }
        } else {
            if (valid && validateChecked) {
                setIsModalCreate(!isModalCreate)
            }
            else {
                valid && hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: "Select one menu & one permission" } })
            }
        }
    }
    const handleChangeSwitch = () => {
        setValue(prev => ({
            ...prev,
            status: !value.status
        }))
    }
    useEffect(() => {
        payload.statName === "success" && setdisabled(false)
        payload.statName === "error" && setdisabled(false)
    }, [payload.statName])
    const title = getPath ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = getPath ? [
        { to: `/authorization/${getPath}`, label: 'Detail Otoritas Pengguna' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/authorization', label: 'Otoritas Pengguna' },
        ...dataBreadcrumbExt
    ]
    
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <form id="getRole" autoComplete="false" onSubmit={(e) => submittedRole(e)}>
                    <Segment>
                        <Segment boxShadow bg={'white'} borderRadius={4} p={30} mb={32}>
                            <Text fontWeight={600} mb={32}>Informasi Otorisasi</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama Otorisasi</Text>
                                </Segment>
                                <Segment flex="1">
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name: "roleName",
                                                className: "validate[required]",
                                                placeholder: "Nama Otorisasi",
                                                autoFocus: true,
                                                value: value.roleName,
                                                autoComplete: "off"
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={24}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1} alignItems="center">
                                    <Segment>
                                        <Switch label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} onChange={() => handleChangeSwitch()} checked={value?.status} />
                                    </Segment>
                                </Segment>
                            </Row>
                            {
                                payload.getMenus.map((item, index) => {
                                    return (
                                        <Segment key={index}>
                                            <Segment height={1} bg={'#DCDEE3'} mb={32} />

                                            <Segment maxWidth={755} mb={24}>
                                                <Text fontWeight={600} mb={40}>{item.name}</Text>
                                                <Segment>
                                                    {
                                                        item.menus.map((children, index) => {
                                                            let setKey = Object.keys(children.accessRights).filter(raw => raw !== "id"  && raw !== "delete");
                                                            let actions = []
                                                            
                                                            actions.push(...setKey)
                                                            
                                                            return (
                                                                <Row key={index} className="xs-flex-wrap">
                                                                    <Segment width={280}>
                                                                        <Text>{children.label}</Text>
                                                                    </Segment>
                                                                    <Segment className="wrapper-checkbox">
                                                                        <Segment
                                                                            mr={16}
                                                                            p={8}
                                                                            borderRadius={16}
                                                                            display="inline-block"
                                                                            bg={
                                                                                value[children.code]?.filter(val=>val).length < actions.length ?
                                                                                    '#EFF3F6'
                                                                                    : '#1178D4'
                                                                            }
                                                                            color={
                                                                                value[children.code]?.filter(val=>val).length < actions.length ?
                                                                                    'inherit'
                                                                                    : 'white'
                                                                            }
                                                                            className={"badge-checkbox"}
                                                                            style={{ fontSize: "12px", textTransform: "capitalize" }}
                                                                        >
                                                                            <CheckBox
                                                                                checkProps={{
                                                                                    onChange: (e) => {
                                                                                        bindCheckedAll.onChange(e, actions)
                                                                                        !e.target.checked  && setValue(prev=>{
                                                                                            return{
                                                                                                ...prev,
                                                                                                statusOrder: value.statusOrder.map(parentVal=>{
                                                                                                    return{
                                                                                                        ...parentVal,
                                                                                                        actions: children.code===parentVal.accessRightTypeCode ?  parentVal.actions.map(val=>({...val,allowed:false})) : parentVal.actions
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    },
                                                                                    name: `${children.code}`,
                                                                                    id: children.code
                                                                                }}
                                                                                checkItems={[{ label: "Select All", value: children.code }]}
                                                                                selected={value[`${children.code}`]?.filter(val=>val && val!==children.code).length === 3 ? [children.code] : ['']}
                                                                            />
                                                                        </Segment>
                                                                        <AccessRights 
                                                                            code={children.code} 
                                                                            data={children.accessRights} 
                                                                            bindChange={bindChange} 
                                                                            bindCheckedAll={bindCheckedAll} 
                                                                            bindCheckedAllBatch={bindCheckedAllBatch} 
                                                                            value={value} getPath={getPath} setValue={setValue} />
                                                                    </Segment>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Segment>
                                            </Segment>
                                        </Segment>
                                    )
                                })
                            }

                            <Segment>
                                <Segment height={1} bg={'#DCDEE3'} mb={32} />
                                {
                                    value?.statusOrder?.map((parentVal,index)=>{
                                        const menu = value[parentVal.accessRightTypeCode]
                                        
                                        return(
                                            <Segment key={`${parentVal.name}${index}`} maxWidth={755} mb={24}>
                                                <Text fontWeight={600} mb={40}>{parentVal.name}</Text>
                                                <Segment>
                                                    {
                                                        parentVal.actions.map((val,index2)=>{
                                                            
                                                            
                                                            return(
                                                                <Row key={`${val.id}${index2}`} mb={16}>
                                                                    <Segment width={280} py={8}>
                                                                        <Text>{val.label}</Text>
                                                                    </Segment>
                                                                    <Segment flex={1} alignItems="center">
                                                                        <Switch 
                                                                        disabled={!(menu?.find(val=>val==='read'))}
                                                                            onChange={(e) => {
                                                                                const selected = val
                                                                                const modify = parentVal.actions.map(val=>{
                                                                                    
                                                                                    return{
                                                                                        ...val,
                                                                                        allowed:val.value===selected.value ? !val.allowed : val.allowed
                                                                                    }
                                                                                    
                                                                                })
                                                                                const newData = [...value.statusOrder.filter(x=>x.value!==parentVal.value),{
                                                                                    ...parentVal,
                                                                                    actions: modify
                                                                                }]
                                                                                let items = [...value.statusOrder]
                                                                                items[index] = newData[0]
                                                                                setValue(prev=>{
                                                                                    return{
                                                                                        ...prev,
                                                                                        statusOrder:items
                                                                                    }
                                                                                })
                                                                                
                                                                            }} 
                                                                            checked={val.allowed} 
                                                                        />
                                                                    </Segment>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                    
                                                    
                                                </Segment>
                                            </Segment>
                                        
                                        )
                                        
                                    })
                                }
                                </Segment>
                            
                        </Segment >
                    </Segment >
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={getPath ? `/authorization/${getPath}` : "/authorization"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {getPath ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"submit"}
                                >
                                    Simpan
                                </Button>
                                : (<Button
                                    variant="primary"
                                    size="medium"
                                    type={"submit"}
                                >
                                    Preview
                                </Button>)

                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment >
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button disabled={disabled} onClick={() => {
                                setdisabled(true)
                                hasFetch(requestUpdateMenuAccessRight({ 
                                    value, id: getPath, 
                                    history: props.history,
                                    action_group:constructActionGroup(value.statusOrder)
                                 }))
                            }} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export const AccessRights = (props) => {
    const { code, data, bindCheckedAll, bindCheckedAllBatch, value, getPath, setValue } = props
    let setKey = Object.keys(data).filter(raw => raw !== "id" && raw !== "delete");
    let actions = []
    actions.push(...setKey)
    const handleOnChange = (e,code) => {
        
        
        
        setTimeout(()=>{
            const newValue = value[code]?.filter(val=>val).filter(set=>set!==code)    
            newValue?.length < rotateOtorisasi(newValue?.length < actions.length).length ?
                                                    bindCheckedAll.onChange(e, actions, code)
                                                    :
                                                    bindCheckedAllBatch.onChange(e, actions, code, getPath && code)
            value?.statusOrder?.map(val=>val.accessRightTypeCode).includes(code) && ["read"].includes(newValue) === false  && setValue(prev=>{
                return{
                    ...prev,
                    statusOrder: value.statusOrder.map(parentVal=>{
                        return{
                            ...parentVal,
                            actions:parentVal.actions.map(val=>({...val,allowed:false}))
                        }
                    })
                }
            })
        
        },0)
        
        
    }
    return (
        <Segment display={"inline-block"}>
            {
                <Segment
                    mb={16}
                    pl={16}
                    borderLeft={'1px solid #DCDEE3'}
                    className={"group-auth-checkbox"}
                >
                    {
                        rotateOtorisasi(actions).filter(tag => tag !== "createdBy" && tag !== "lastModifiedBy").map((val, index) => {
                            return (
                                <Segment
                                    key={index}
                                    bg={
                                        !value[code]?.filter(val=>val).includes(val) ?
                                            '#EFF3F6'
                                            : '#1178D4'
                                    }
                                    color={
                                        !value[code]?.filter(val=>val).includes(val) ?
                                            'inherit'
                                            : 'white'
                                    }
                                    borderRadius={16}
                                    p={8}
                                    mr={16}
                                    className={"badge-checkbox"}
                                    style={{ fontSize: "12px", textTransform: "capitalize" }}
                                >
                                    <CheckBox
                                        checkProps={{
                                            onChange:e=>handleOnChange(e,code),
                                            name: `${code}`,
                                            id: `${val}-${code}`,
                                        }}
                                        checkItems={[{ label: reverseName(val), value: val }]}
                                        selected={value[`${code}`]?.filter(val=>val)}
                                    />
                                </Segment>
                            )
                        })
                    }
                </Segment>
            }
        </Segment>
    )
}
export default MenuAccessForm