import {
    Segment
} from "@elevenia/master-ui/components/Atom"
import DataTable from 'component/DataTable'
import TimeSlot from 'component/TimeSlot'
import { capitalize } from 'helper'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import CountDown from './CountDown'
import { serviceType } from './helper'
import { colorOrderId } from "helper"
import useModalFilter from "hooks/useModalFilterOrder"
import SectionFilterOrder from "component/SectionFilterOrder"
const TabSiapDikirim = (
    {
        data,
        totalPages,
        loading,
        isTable,
        setTable,
        parameter,
        handleSearch,
        handlePagination,
        listOptionsKategori,
        filterCluster,
        loadingCluster,
        listOptionsServ,
        currentTime,
        timezoneLabel,
        orderConfig,
        filterChannel,
        loadingChannel,
        setCategorySearch,
        categorySearch,
    }
) => {
    
    const { modal, setModal, ModalFilter } = useModalFilter()
    
    return (
        <>
            <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} display="flex" justifyContent="space-between" mb={24}>
                <SectionFilterOrder
                    setModal={setModal}
                    handleSearch={handleSearch}
                    setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                />
            </Segment>
            <DataTable
                countingList={true}
                defaultSize={isTable.size}
                tableConsume={[{
                    field: 'Order ID',
                    isCustomRow: (id, entity) => {
                        return (
                            <Link style={{color:colorOrderId(entity.channelOrder) }} to={{pathname:`/pesanan/${entity.id}`}}>{entity.orderId}</Link>
                        )
                    }
                }, {
                    field: 'Kategori',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.serviceType && capitalize(entity.serviceType)
                        )
                    }
                }, {
                    field: 'Klaster',
                    rowField:'clusterCode',
                    isCustomRow:(id, entity)=>{
                        return(
                            entity.clusterCode??'-'
                        )
                    },
                    width: "10%"
                }, {
                    field: 'Toko Asal',
                    rowField:'storeCode',
                    isCustomRow:(id, entity)=>{
                        return(
                            entity.storeCode??'-'
                        )
                    }
                }, {
                    field: 'Channel',
                    rowField:'storeCode',
                    isCustomRow:(id, entity)=>{
                        return(
                            entity.channelOrder ? capitalize(entity.channelOrder) : '-'
                        )
                    }
                    
                }, {
                    field: 'Sales Order',
                    rowField:'salesOrderId',
                }, {
                    field: 'Delivery Man',
                    isCustomRow: (id, entity) => {
                        return entity.driverName ? entity.driverName : '-'
                        
                        
                    }
                }, {
                    field: 'Service',
                    isCustomRow: (id, entity) => {
                        return serviceType(entity)
                        
                        
                    }
                }, {
                    field: 'Time Out',
                    isCustomRow: (id, entity) => {
                        return <CountDown
                                category={entity.serviceType}
                                type={entity.orderStatus}
                                startDuration={currentTime}
                                endDuration={entity.expiredDeliveryTime}
                                orderConfig={orderConfig}
                                channelOrder={entity.channelOrder}
                                orderType={entity.orderType}
                                />
                    },
                }, {
                    field: 'Slot',
                    isCustomRow: (id, entity) => {
                        return (
                            <TimeSlot data={entity} timezoneLabel={timezoneLabel} />
                        )
                    }
                }, {
                    field: 'Waktu Pilih Pesanan',
                    isCustomRow: (id, entity) => {
                        return (
                            <>{moment(entity.processTime).format('DD/MM/YYYY HH:mm')} {timezoneLabel}</>
                        )
                    }
                }
                ]}
                dataConsume={data}
                isLoading={loading}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={totalPages}
                createShowPerSize={(size) => setTable(prev => {
                    return {
                        ...prev,
                        ...parameter,
                        size: size.value
                    }
                })}
                createPaginations={(page) => handlePagination(page)}
            />
            {
                modal && 
                <ModalFilter
                    isOpen={modal}
                    toggle={()=> setModal(false)}
                    filterChannel={{
                        show: true,
                        options: filterChannel,
                        loading: loadingChannel
                    }}
                    filterCluster={{
                        show: true,
                        options: filterCluster,
                        loading: loadingCluster
                    }}
                    filterCategory={{
                        show: true,
                        options: listOptionsKategori
                    }}
                    doFilter={setTable}
                    paramsFilter={isTable}
                    orderStatus="ALLOCATING"
                    filterServices={{
                        show: true,
                        options: listOptionsServ
                    }}
                    filterStatus={{
                        show: false
                    }}
                />
            }
        </>
    )
}

export default TabSiapDikirim