import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestConfigRKB = action$ =>
    action$.pipe(
        ofType( 'REQUEST_LIST_CONFIG_RKB',
                'REQUEST_LIST_HISTORY_CONFIG_RKB',
                'REQUEST_UPLOAD_FILE',
                'REQUEST_SUBMIT_JADWAL',
                'REQUEST_UPLOAD_DATA_READ',
                'REQUEST_UPLOAD_DATA_ERROR',
                'REQUEST_DELETE_JADWAL',
                'REQUEST_UPDATE_JADWAL'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_LIST_CONFIG_RKB':
                        const rawResponse = await Services().get('/user/api/coordinator',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_LIST_CONFIG_RKB',
                                payload: {
                                    data: rawResponse.data.data.result.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.result.totalPages,
                                    filterStatus: rawResponse.data.data.status,
                                }
                            })
                        }
                    case 'REQUEST_LIST_HISTORY_CONFIG_RKB':
                        const rawResponseDetail = await Services().get(`/user/api/coordinator/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_COORDINATOR_DELIMAN_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                
                                }
                            })
                        }

                    case 'REQUEST_UPLOAD_FILE':
                        try {
                            const respUpload = await Services().post(`/user/api/back-office/rkb/upload`,action.payload.params)
                            const idUpload  = respUpload.data.data.id
                            
                            return dispatch => {
                                
                                dispatch({ 
                                    type: 'GET_DATA_UPLOAD_FILE', 
                                    payload: { data: respUpload.data.data } 
                                })
                                dispatch({ 
                                    type: 'REQUEST_UPLOAD_DATA_ERROR', 
                                    payload: { 
                                        idUpload,
                                        params:{
                                            page: 0,
                                            size: 10
                                        } 
                                    } 
                                })
                            }
                        } catch (error) {
                            return dispatch => {
                                dispatch({ 
                                    type: 'ERROR_UPLOAD_FILE', 
                                })    
                                
                            }
                        }
                        
                    case 'REQUEST_UPLOAD_DATA_READ':
                        const respListRead = await Services().get(`/user/api/back-office/rkb/upload/${action.payload.idUpload}/review/read-list`,action.payload.params)
                        
                        return dispatch => {
                                                    
                            dispatch({ 
                                type: 'GET_UPLOAD_DATA_READ', 
                                payload: {
                                    data: respListRead.data.data.content,
                                    parameter: action.payload.params,
                                    totalPages: respListRead.data.data.totalPages,
                                    totalData: respListRead.data.data.totalElements,
                                }
                            })
                        }
                    case 'REQUEST_UPLOAD_DATA_ERROR':
                        const respListError = await Services().get(`/user/api/back-office/rkb/upload/${action.payload.idUpload}/review/error-list`,action.payload.params)
                        
                        return dispatch => {
                            
                            dispatch({ 
                                type: 'GET_UPLOAD_DATA_ERROR', 
                                payload: {
                                    data: respListError.data.data.content,
                                    parameter: action.payload.params,
                                    totalPages: respListError.data.data.totalPages,
                                    totalData: respListError.data.data.totalElements,
                                }
                            })
                        }
                    case 'REQUEST_SUBMIT_JADWAL':
                        await Services().post(`/user/api/back-office/rkb/create`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/pengaturan-rkb`)
                            dispatch({
                                type: 'SUCCESS_SUBMIT_JADWAL'
                            })
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal baru berhasil dibuat' } })
                        }
                        
                    case 'REQUEST_DELETE_JADWAL':
                        await Services().delete(`/user/api/back-office/rkb/${action.payload.params}`)
                        
                        return dispatch => {
                            action.history.push(`/pengaturan-rkb`)
                            
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal berhasil dihapus' } })
                        }
                    case 'REQUEST_UPDATE_JADWAL':
                        await Services().put(`/user/api/back-office/rkb/${action.payload.params.id}`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/pengaturan-rkb`)
                            
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal berhasil diubah' } })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_LIST_CONFIG_RKB_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
