import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    ButtonGroup,
    Switch,
    Spinner,
    Icon,
    RadioButton
} from '@elevenia/master-ui/components/Atom'
import { Tabs } from "@elevenia/master-ui/components/Molecules";
import ModalConfirm from 'component/ModalCustom/modalSmall'
import Breadcrumb from 'component/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import { validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { requestStoreById, requestUpdateStore, requestCheckStoreCode, resetInputStatus, setStatusField } from 'store/actions/store'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import moment from 'moment'
import { Services } from 'service'
import AsyncSelect from 'react-select/async';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import DataTable from 'component/DataTable'
const mappingDeliman = {
    true:'Ya',
    false:'Tidak'
}
const TokoForm = props => {
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;
    
    useEffect(()=>{
        let unmounted = false;
        if (props.access) {
            getPath !== undefined  && props.access?.update && !unmounted && hasFetch(requestStoreById(getPath))
            getPath === undefined && props.access?.create && !unmounted && hasFetch(setStatusField(false))
            getPath === undefined && props?.location?.state && props.access?.create && !unmounted && hasFetch(setStatusField(true))
        }
        return () => (unmounted = true);
    },[hasFetch,getPath,props.access,props.location.state])
    const loading       = useSelector(state => state.store.loading)
    
    
    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <>{(!loading) && <Form {...props} />}</>
    )
}
const Form = props => {
    document.title = props.title
    const history = props.history
    const [isModalCreate, setIsModalCreate] = useState(false)
    
    const { id } = useParams()
    
    const title = id ? 'Edit' : 'Buat Baru'
    const [disablePreview, setDisablePreview] = useState(true)
    const dataBreadcrumbExt = id ? [
        { to: `/toko/${id}`, label: 'Detail Toko' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/toko', label: 'Toko' },
        ...dataBreadcrumbExt
    ]

    

    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            detailData: state.store.detailData,
            previewData: props?.location?.state,
            loadingCheck: state.store.loadingCheck,
            messageError: state.store.messageError,
            statusCheck: state.store.statusCheck,
            listDriverByIdStore: state.store.listDriverByIdStore,
        }
    })
    
    const handleLoadOptionsWilayah = async (inputValue, callback) => {
        if(inputValue.length >= 3){
            const response = await Services().get(`/store/api/back-office/postal-codes?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content
            
            callback(listData?.map(val=>({
                        label:`${val.description}`,
                        value:val.postalCodeId,
                        zipCode:val.postal_code
            })))
        }
        if(inputValue.value===0){
            const response = await Services().get(`/store/api/back-office/postal-codes?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content
            
            callback(listData?.map(val=>({
                        label:`${val.description}`,
                        value:val.postalCodeId,
                        zipCode:val.postal_code
            })))
        }

    }

    const handleLoadOptionsDriver = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/driver/api/back-office/search/${inputValue}`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }
        if (inputValue.value === 0) {
            const response = await Services().get(`/driver/api/back-office/search/${inputValue}`)
            const listData = response.data.data.content
            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }

    }

    const handleChangeDriver = (data) => {
        let driver = data ? data : []
        setValue(prev => {
            return {
                ...prev,
                listDriverId: driver
            }
        })

        setTimeout(() => {
            value.hasDriver && validateInput('myForm', 'listDriverId')
        }, 0)
    }

    
    
    let { value, bindChange,  bindChecked, setValue } = useInput({
        initialObjects: {
          storeName: payload?.detailData?.storeName ? payload?.detailData?.storeName : payload?.previewData?.storeName ? payload?.previewData?.storeName : ''  ,
          storeCode: payload.detailData?.storeCode ? payload?.detailData?.storeCode : payload?.previewData?.storeCode ? payload?.previewData?.storeCode : '' ,
          address:payload.detailData?.address ? payload?.detailData?.address : payload?.previewData?.address ? payload?.previewData?.address : '' ,
          postalCodeDescription:payload.detailData?.postalCodeDescription ? payload?.detailData?.postalCodeDescription : payload?.previewData?.postalCodeDescription ? payload?.previewData?.postalCodeDescription : '' ,
          postalCodeId:payload.detailData?.postalCodeId ? payload?.detailData?.postalCodeId : payload?.previewData?.postalCodeId ? payload?.previewData?.postalCodeId : '' ,
          active:payload.detailData?.active ? payload?.detailData?.active : payload?.previewData?.active ? payload?.previewData?.active : id ?  payload.detailData?.active : true ,
          latitude:payload.detailData?.latitude ? payload?.detailData?.latitude : payload?.previewData?.latitude ? payload?.previewData?.latitude : '' ,
          longitude:payload.detailData?.longitude ? payload?.detailData?.longitude : payload?.previewData?.longitude ? payload?.previewData?.longitude : '' ,
          contact:payload.detailData?.contact ? payload?.detailData?.contact : payload?.previewData?.contact ? payload?.previewData?.contact : '' ,
          openingHour:payload.detailData?.openingHour ? moment(payload?.detailData?.openingHour,'HH:mm Z').format('HH:mm') : payload?.previewData?.openingHour ? moment(payload?.previewData?.openingHour,'HH:mm Z').format('HH:mm') : '' ,
          closingHour:payload.detailData?.closingHour ? moment(payload?.detailData?.closingHour,'HH:mm Z').format('HH:mm') : payload?.previewData?.closingHour ? moment(payload?.previewData?.closingHour,'HH:mm Z').format('HH:mm') : '' ,
          dcName: payload.detailData?.dcName ? payload?.detailData?.dcName : payload?.previewData?.dcName ? payload?.previewData?.dcName : 'AAA' ,
          dcCode: payload.detailData?.dcCode ? payload?.detailData?.dcCode : payload?.previewData?.dcCode ? payload?.previewData?.dcCode : 'BBB' ,
          hasDriver: payload.detailData?.hasDriver ? mappingDeliman[payload?.detailData?.hasDriver] : payload?.previewData?.hasDriver ? payload?.previewData?.hasDriver : 'Tidak' ,
          listDriverId:payload?.listDriverByIdStore?.length > 0 ? payload?.listDriverByIdStore.map(val => ({ ...val, value: val.nik, label: `${val.nik} - ${val.name}` })) : payload?.previewData?.listDriverId ? payload?.previewData?.listDriverId : [],
          channel:payload.detailData?.channel ? payload?.detailData?.channel : payload?.previewData?.channel ? payload?.previewData?.channel : 'INDOMARET' 
        },
        identity: "myForm",
      });


    const handleCheckDeliman = (e) => {
        
        if(e.target.value==='Tidak') {
            setValue(prev=>{
                return {
                        ...prev,
                        listDriverId:[]
                    }
                }
            )
        }
        setValue(prev=>{
            return {
                ...prev,
                hasDriver: e.target.value 
            }
        })
    }
    const handleChangeTimer = (e) => {
        const { name, value } = e.target
        
        const replaceD = value.replace(/^(\d{2})(\d{2}).*/, '$1:$2')
        if(replaceD.length <=5){
            setValue(prev => {
                return {
                    ...prev,
                    [name]: replaceD
                }
            })
            setTimeout(()=>{
                validateInput('myForm', name)
            })
           
        }
        
    }

    const [onSearchStoreCode$] = useState(()=>new Subject())

    useEffect(()=>{
        onSearchStoreCode$.pipe(
            tap(()=>hasFetch(resetInputStatus('storeCode'))),
            debounceTime(1000),
            distinctUntilChanged(),
            tap(value=>{
                value.length <= 50 && value.length >= 4 && hasFetch(requestCheckStoreCode({
                    storeCode:value,
                    storeId:id??''
                }))
            })
        ).subscribe()
    },[hasFetch,onSearchStoreCode$,id])

    const handleChangeCode = (e) => {
        bindChange.onChange(e)
        onSearchStoreCode$.next(e.target.value)
    }

    const handleChangeWilayah = (data) => {
        
        if(data){
            setValue(prev=>{
                return {
                    ...prev,
                    postalCodeDescription:data.label,
                    postalCodeId:data.value
                }
            })
        }else{
            setValue(prev=>{
                return {
                    ...prev,
                    postalCodeDescription:'',
                    postalCodeId:null
                }
            })
        }
        setTimeout(()=>{
            validateInput('myForm', 'postalCodeDescription')
        },0)
        
    }

    

    const handlePreview = () => {
        
        if(validateForm('myForm')){
            const state = {...value,totalDriver:value.listDriverId.length}
            history.push({
                pathname:'/toko/preview',
                state:state
            })
        }
    }

    const handleShowConfirmation = () => {
        if(validateForm('myForm')){
            setIsModalCreate(!isModalCreate)    
        }
        
    }

    const handleSubmit = () => {
        
        const dataUpdate = {
            id:payload.detailData.id,
            storeCode:value.storeCode,
            storeName:value.storeName,
            address:value.address,
            contact:value.contact,
            latitude:value.latitude,
            longitude:value.longitude,
            postalCodeId:value.postalCodeId,
            openingHour:moment(value.openingHour,'HH:mm').format('HH:mm:00+07:00'),
            closingHour:moment(value.closingHour,'HH:mm').format('HH:mm:00+07:00'),
            active:value.active,
            dcName:value.dcName,
            dcCode:value.dcCode,
            hasDriver: value.hasDriver === "Ya" ? true : false,
            driverList: value.listDriverId.map(val=>({id:val.id,nik:val.nik})),
            channel: value.channel
        }
        
        
        hasFetch(requestUpdateStore(payload.detailData?.id,dataUpdate,history))
        
    }

    useEffect(() => {
        
        const disabled = Object.keys(value).filter(val => {
            
            if(Array.isArray(value[val])){
                if(value.hasDriver==="Tidak"){
                    return false
                }else{
                    return value[val].length === 0
                }
                
            }else{
                return val!=='contact' && value[val] === ""
            }
            
        })
        if (disabled.length) {
            
            setDisablePreview(true)
        } else {
            
            setDisablePreview(false)
        }

    }, [value])

    const handleChangeChannel = (e) => {
        bindChange.onChange(e)
        const { value } = e.target
        if(value==='INDOGROSIR'){
            setValue(prev=>{
                return {
                    ...prev,
                    hasDriver:'Tidak',
                    listDriverId:[]
                }
            })
        }
    }
    

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Toko</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama Toko</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name:'storeName',
                                                placeholder: "Nama Toko",
                                                value: value.storeName,
                                                maxLength: 100,
                                                className: 'validate[required] validateMessage{required{Nama Toko harus diisi}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kode Toko</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.storeCode ? 'error':'normal'}
                                        helptext={payload.messageError.storeCode}
                                    >
                                        <Textfield
                                            status={payload.messageError.storeCode ? 'error':'normal'}
                                            inputProps={{
                                                placeholder: "Kode Toko",
                                                onChange:(e)=>handleChangeCode(e),
                                                // onKeyUp: () => handleKeyUp(),
                                                // onKeyDown: () => handleKeyDown(),
                                                name:'storeCode',
                                                value: value.storeCode,
                                                maxLength: 50,
                                                className: 'validate[required,maxLength[50]] validateMessage{required{Kode Toko harus diisi}}',
                                            }}
                                            disabled={payload.loadingCheck.storeCode}
                                            right={
                                                payload.loadingCheck.storeCode 
                                                ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.storeCode && payload.statusCheck.storeCode==='00')
                                                    ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment>
                                                    : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Alamat</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                placeholder: "Alamat",
                                                ...bindChange,
                                                name:'address',
                                                value: value.address,
                                                className: 'validate[required] validateMessage{required{Alamat harus diisi}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Wilayah/ Kode Pos</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <AsyncSelect
                                            onChange={handleChangeWilayah}
                                            isClearable
                                            defaultInputValue={value.postalCodeDescription ? value.postalCodeDescription :''}
                                            defaultValue={value.postalCodeId ? value.postalCodeId : '' }
                                            loadOptions={handleLoadOptionsWilayah}
                                            name='postalCodeId'
                                            inputClassName={'validate[required] validateMessage{required{Wilayah harus diisi}}'}
                                            placeholder={'Wilayah / Kode Pos'}
                                        />
                                        
                                    </FormControl>
                                    <input 
                                            type="hidden"
                                            name="postalCodeDescription"
                                            className='validate[required] validateMessage{required{Wilayah harus diisi}}' 
                                            value={value.postalCodeId} />
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Koordinat Latitude</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                placeholder: "Koordinat Latitude",
                                                ...bindChange,
                                                name:'latitude',
                                                value: value.latitude,
                                                maxLength: 100,
                                                className: 'validate[required] validateMessage{required{Latitude harus diisi}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Koordinat Longitude</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                placeholder: "Koordinat Longitude",
                                                ...bindChange,
                                                name:'longitude',
                                                value: value.longitude,
                                                maxLength: 100,
                                                className: 'validate[required] validateMessage{required{Longitude harus diisi}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kontak</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                placeholder: "Kontak",
                                                ...bindChange,
                                                name:'contact',
                                                value: value.contact,
                                                maxLength:12,
                                                className: 'validate[homePhone] validateMessage{phone{Format nomer telepon salah}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Jam Operasional</Text>
                                </Segment>
                                <Segment flex={1} alignItems={'center'} className="xs-flex-wrap">
                                    <Segment maxWidth={130}>
                                        <FormControl>
                                            <Textfield
                                                inputProps={{
                                                    placeholder: "Jam Buka",
                                                    onChange:(e)=>handleChangeTimer(e),
                                                    name:'openingHour',
                                                    value: value.openingHour,
                                                    className: 'validate[required,numberSemiColon,hourMinute] validateMessage{numberSemiColon{Format jam salah}}',

                                                }}
                                            />
                                            
                                        </FormControl>
                                    </Segment>
                                    {/* separator */}
                                    <Segment width={12} height={2} bg={'#9C9DA6'} mx={10} className="xs-hidden"/>
                                    {/* separator */}
                                    <Segment maxWidth={130}>
                                        <FormControl>
                                            <Textfield
                                                inputProps={{
                                                    placeholder: "Jam Tutup",
                                                    onChange:(e)=>handleChangeTimer(e),
                                                    name:'closingHour',
                                                    value: value.closingHour,
                                                    className: 'validate[required,numberSemiColon,hourMinute] validateMessage{numberSemiColon{Format jam salah}}',
                                                }}
                                            />
                                            
                                        </FormControl>
                                    </Segment>
                                    
                                    <Text color={'#9C9DA6'} fontSize={16} ml={16}>WIB</Text>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama Cabang</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            disabled
                                            inputProps={{
                                                placeholder: "Nama Cabang",
                                                ...bindChange,
                                                name:'dcName',
                                                value: value.dcName,
                                            
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kode Cabang</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            disabled
                                            inputProps={{
                                                placeholder: "Kode Cabang",
                                                ...bindChange,
                                                name:'dcCode',
                                                value: value.dcCode,
                                                
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Channel</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Segment display="inline-block">
                                        <RadioButton
                                            radioProps={{
                                                onChange:(e)=>handleChangeChannel(e),
                                                className: 'validate[required]',
                                                name: "channel",
                                                id: "channel",
                                                disabled: id ? true : false
                                                
                                            }}
                                            selected={value.channel}
                                            radioItems={[
                                                { label: "Indomaret", value: "INDOMARET" },
                                                { label: "Indogrosir", value: "INDOGROSIR" },
                                                { label: "Other", value: "OTHER" },
                                            ]}
                                            
                                        />
                                    </Segment>
                                    
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Delivery Man</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Segment display="inline-block">
                                        <RadioButton
                                            radioProps={{
                                                onChange:e=>handleCheckDeliman(e),
                                                className: 'validate[required]',
                                                name: "hasDriver",
                                                id: "hasDriver",
                                                //disabled: value.channel === "OTHER"
                                            }}
                                            selected={value.hasDriver}
                                            radioItems={[
                                                { label: "Ya", value: 'Ya' },
                                                { label: "Tidak", value: 'Tidak' }
                                            ]}
                                        />
                                    </Segment>
                                    {
                                    value.hasDriver==='Ya' ? 
                                        <Segment mt={12}>
                                            <AsyncSelect
                                                onChange={handleChangeDriver}
                                                isClearable
                                                isMulti
                                                loadOptions={handleLoadOptionsDriver}
                                                value={value.listDriverId}
                                                name='listDriverId'
                                                className="asyncDropdown"
                                                placeholder={'Delivery Man'}
                                            />
                                            <input
                                            type="hidden"
                                            name="listDriverId"
                                            className='validate[required] validateMessage{required{Delivery Man harus diisi}}'
                                            value={value.listDriverId.length > 0 ? value.listDriverId.length : ''} />
                                            
                                        </Segment> : ''
                                    }
                                </Segment>
                                            
                                
                                
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Switch name="active" label={value.active ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.active} />
                                    
                                </Segment>
                            </Row>
                            
                        </Segment>
                    </Segment>
                    
                    <Segment mt={32}>
                        <Tabs active={'delivMan'} onChange={active => console.log(active)}>
                            <Text key="delivMan">Delivery Man</Text>
                            
                        </Tabs>
                        <Segment mt={24}>
                                <DataTable
                                        countingList={true}
                                        defaultSize={10}
                                        tableConsume={[{
                                            field: 'NIK Delivery Man',
                                            rowField: 'nik',
                                            isCustomRow: (id, entity) => {
                                                return (
                                                    <Link target="_blank" to={`/delivery-man/${entity.id}`}>{entity.nik}</Link>
                                                )
                                            }
                                        }, {
                                            field: 'Nama',
                                            rowField: 'name'
                                        }, {
                                            field: 'Kontak Delivery Man',
                                            rowField: 'phone'
                                        }, {
                                            field: 'Tipe Pegawai',
                                            rowField: 'employmentType'
                                        }]}
                                        dataConsume={value.listDriverId}
                                    />
                        </Segment>
                    </Segment>
                    
                    
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={id ? `/toko/${id}` : "/toko"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={disablePreview ? true : payload.statusCheck.storeCode === '00' ? false :true}
                                >
                                    Simpan
                                </Button>
                                :
                                
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    disabled={disablePreview ? true : payload.statusCheck.storeCode === '00' ? false :true}
                                    onClick={handlePreview}
                                >
                                    Preview
                                </Button>
                                
                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button type="button" onClick={handleSubmit} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default TokoForm