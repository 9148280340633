/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useRef, useEffect } from "react";

const SingleOTPInputComponent = (props) => {
    const usePrevious = (value) => {
        // The ref object is a generic container whose current property is mutable ...
        // ... and can hold any value, similar to an instance property on a class
        const ref = useRef();
        // Store current value in ref
        useEffect(() => {
            ref.current = value;
        }, [value]); // Only re-run if value changes
        // Return previous value (happens before update in useEffect above)
        return ref.current;
    }
    const { focus, autoFocus, ...rest } = props;
    const inputRef = useRef(null);
    const prevFocus = usePrevious(!!focus);
    useEffect(() => {
        if (inputRef.current) {
            if (focus && autoFocus) {
                inputRef.current.focus();
            }
            if (focus && autoFocus && focus !== prevFocus) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }
    }, [autoFocus, focus, prevFocus]);

    return <input autoComplete="off" ref={inputRef} {...rest} />;
}
const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput
