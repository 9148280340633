export const requestStore = properties => {
  return {
      type: "REQUEST_LIST_STORE",
      payload: { properties }
  }
}


export const requestStoreById = id => {
  return {
      type: "REQUEST_STORE_BY_ID",
      payload: id
  }
}

export const requestAddStore = (params,history) => {
  return {
      type: "REQUEST_CREATE_STORE",
      payload:{params},
      history
  }
}

export const requestUpdateStore = (id,params, history)=> {
  return {
      type: "REQUEST_UPDATE_STORE",
      payload:{
        params,
        id
      },
      history
  }
}


export const requestCheckStoreCode = (params) => {
  return {
    type: "REQUEST_CHECK_STORE_CODE",
    payload:{
      params
    }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS_STORE",
      payload:{
        field:name
      }
  }
}

export const requestListAddress = (value) => {
  return {
      type: "REQUEST_LIST_ADDRESS",
      payload:{
        params:value
      }
  }
}

export const requestFilterCluster = () => {
  return {
      type: "REQUEST_LIST_FILTER_CLUSTER",
  }
}

export const setStatusField = (status) => {
  return {
      type: "SET_STATUS_FIELD_STORE",
      payload:{
        status:status?'00':''
      }
  }
}

export const resetFrom = (status) => {
  return {
      type: "RESET_FORM_STORE",
      
  }
}