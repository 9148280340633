import {
    Icon, Segment, Text, Tooltip, TooltipContent, TooltipTarget
} from "@elevenia/master-ui/components/Atom"
import DataTable from 'component/DataTable'
import TimeSlot from 'component/TimeSlot'
import { capitalize, colorOrderId } from 'helper'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatCountDown, getDuration, serviceType } from './helper'
import StatusColorSLA from './StatusColorSLA'
import SectionFilterOrder from "component/SectionFilterOrder"
import useModalFilter from "hooks/useModalFilterOrder"
const TabSelesai = ({
    data,
    totalPages,
    loading,
    isTable,
    setTable,
    handleSearch,
    handlePagination,
    filterCluster,
    loadingCluster,
    listOptionsKategori,
    timezoneLabel,
    filterChannel,
    loadingChannel,
    listOptionsServ,
    setCategorySearch,
    categorySearch,
}) => {
    const { modal, setModal, ModalFilter } = useModalFilter()
    
    return (
        <>
            <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} display="flex" justifyContent="space-between" mb={24}>
                <SectionFilterOrder
                    setModal={setModal}
                    handleSearch={handleSearch}
                    setTable={setTable}
                    setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                    customOptionList={[{value: 4, label: 'Delivery Man'}]}
                />
            </Segment>
            <DataTable
                countingList={true}
                defaultSize={isTable.size}
                tableConsume={[{
                    field: 'Order ID',
                    isCustomRow: (id, entity) => {
                        return (
                            <Link style={{color:colorOrderId(entity.channelOrder) }} to={{pathname:`/pesanan/${entity.id}/selesai`}}>{entity.orderId}</Link>
                        )
                    }
                }, {
                    field: 'Kategori',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.serviceType && capitalize(entity.serviceType)
                        )
                    }
                }, {
                    field: 'Klaster',
                    rowField:'clusterCode',
                    isCustomRow:(id, entity)=>{
                        return(
                            entity.clusterCode??'-'
                        )
                    },
                    width: "10%"
                },{
                    field: 'Channel',
                    rowField:'storeCode',
                    isCustomRow:(id, entity)=>{
                        return(
                            entity.channelOrder ? capitalize(entity.channelOrder) : '-'
                        )
                    }
                    
                }, {
                    field: 'Sales Order',
                    rowField:'salesOrderId',
                }, {
                    field: 'Delivery Man',
                    isCustomRow: (id, entity) => {
                        return entity.driverName ? entity.driverName : '-'
                        
                        
                    }
                }, {
                    field: 'Service',
                    isCustomRow: (id, entity) => {
                        return serviceType(entity)
                        
                        
                    }
                }, {
                    field: 'Slot',
                    isCustomRow: (id, entity) => {
                        return (
                            <TimeSlot data={entity} timezoneLabel={timezoneLabel} />
                        )
                    }
                }, {
                    field: 'Tanggal Kirim',
                    isCustomRow: (id, entity) => {
                        return (
                            <>{entity.minDeliveryTime ? `${moment(entity.minDeliveryTime).format('DD/MM/YYYY')}` : '-' }</>
                        )
                    }
                }, {
                    field: 'SLA',
                    isAddOnHeader:()=>{
                        return <>
                            <Tooltip type="hover" placement="right" color="dark">
                                <TooltipTarget>
                                    <Icon name="information" size="20px" />
                                </TooltipTarget>
                                <TooltipContent>
                                <Text>SLA menunjukan lama durasi pengiriman. Maksimal durasi pengiriman adalah 1 jam. </Text>
                                <Text>SLA Normal = menit : detik</Text>
                                <Text>SLA Over = jam : menit : detik</Text>
                                    
                                    
                                    </TooltipContent>
                            </Tooltip>
                        </>
                    },
                    isCustomRow: (id, entity) => {
                        const duration = getDuration(entity.minDeliveryTime,entity.processTime)
                        const completedTime = moment(entity.processTime).unix()
                        const maxDelivTime = moment(entity.maxDeliveryTime).unix()
                        let colorDuration = duration
                        //if(entity.serviceType==='SAMEDAY'){
                            if(completedTime > maxDelivTime ){
                                colorDuration = 3700
                            }else if(completedTime < maxDelivTime){
                                colorDuration = 3500
                            }else if(completedTime === maxDelivTime){
                                colorDuration = 3500
                            }

                        //}
                        
                        return (
                            <>{StatusColorSLA({
                                duration:colorDuration,
                                text:formatCountDown(duration,true)
                              })}</>
                        )
                    },
                }, {
                    field: 'Waktu Selesai',
                    isCustomRow: (id, entity) => {
                        return (
                            <>{entity.processTime ? `${moment(entity.processTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-' }</>
                        )
                    },
                }
                ]}
                dataConsume={data}
                isLoading={loading}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={totalPages}
                createShowPerSize={(size) => setTable(prev => {
                    return {
                        ...prev,
                        orderStatus:'COMPLETED',
                        size: size.value
                    }
                })}
                createPaginations={(page) => handlePagination(page)}
            />
            {
                modal && 
                <ModalFilter
                    isOpen={modal}
                    toggle={()=> setModal(false)}
                    filterChannel={{
                        show: true,
                        options: filterChannel,
                        loading: loadingChannel
                    }}
                    filterCluster={{
                        show: true,
                        options: filterCluster,
                        loading: loadingCluster
                    }}
                    filterCategory={{
                        show: true,
                        options: listOptionsKategori
                    }}
                    doFilter={setTable}
                    paramsFilter={isTable}
                    orderStatus="COMPLETED"
                    filterServices={{
                        show: true,
                        options: listOptionsServ
                    }}
                    filterStatus={{
                        show: false
                    }}
                />
            }
        </>
    )
}

export default TabSelesai