import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'
import React from 'react'
import { capitalize } from 'helper'
export const RequestOrderList = (action$,state$) =>
    action$.pipe(
        ofType( 'REQUEST_ORDER_LIST',
                'REQUEST_FILTER_CLUSTER',
                'REQUEST_FILTER_CANCEL_REASON',
                'REQUEST_ORDER_LIST_BY_ID',
                'REQUEST_FILE_UPLOAD',
                'REQUEST_ORDER_LIST_INTERVAL',
                'REQUEST_ORDER_LIST_TOTAL_ORDER_TAB',
                'REQUEST_FILTER_CHANNEL'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_ORDER_LIST':
                        const rawResponse = await Services().get('/order/api/back-office/orders',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST',
                                payload: {
                                    data: rawResponse.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.totalPages,
                                    timeStamp: rawResponse.data.timestamp
    
                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_INTERVAL':
                        
                        const rawResponseInterval = await Services().get('/order/api/back-office/orders',state$.value.orderList.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST',
                                payload: {
                                    data: rawResponseInterval.data.data.content,
                                    parameter: state$.value.orderList.parameter,
                                    totalPages: rawResponseInterval.data.data.totalPages,
                                    timeStamp: rawResponseInterval.data.timestamp,
                                    totalOrder: rawResponseInterval.data.data.totalElements
                                }
                            })
                        }
                    case 'REQUEST_FILTER_CLUSTER':
                        
                        const rawResponseCluster = await Services().get('/store/api/back-office/clusters/dropdown',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_FILTER_CLUSTER',
                                payload: {
                                    data: rawResponseCluster.data.data.content.map(val=>({value:val.id,label:val.clusterCode})),
                          
                                }
                            })
                        }
                    case 'REQUEST_FILTER_CANCEL_REASON':
                        const rawResponseReason = await Services().get(`/order/api/back-office/cancel-reasons/${action.payload.orderStatus}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_FILTER_CANCEL_REASON',
                                payload: {
                                    data: rawResponseReason.data.data.content.map(val=>({value:val.id,label:val.description,...val})),
                                    
                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_BY_ID':
                      const rawResponseDetail = await Services().get(`/order/api/back-office/orders/${action.payload.id}`,action.payload.properties)
                      
                      return dispatch => {
                          dispatch({
                              type: 'GET_ORDER_LIST_BY_ID',
                              payload: {
                                  data: rawResponseDetail.data.data,
                                  timeStamp: rawResponseDetail.data.timestamp
                                  
                              }
                          })
                      }
                    case 'REQUEST_FILE_UPLOAD':
                    const rawResponseImage = await Services().get(action.payload.url,null,{ responseType: "arraybuffer" })
                    const metaData = JSON.parse(rawResponseImage.headers.metadata)
                        

                    const blob = new Blob([rawResponseImage.data], { type: "image/png" });
                    const uris = URL.createObjectURL(blob);
                    return dispatch => {
                        dispatch({
                            type: 'GET_FILE_UPLOAD',
                            payload: {
                                data: <img src={uris} alt={'File Upload'} />,
                                properties: {
                                    lat: metaData.latitude,
                                    long: metaData.longitude,
                                    dateTime: metaData.timestamp
                                }
                            }
                        })
                    }
                    case 'REQUEST_ORDER_LIST_TOTAL_ORDER_TAB':
                        
                        const rawResponseTotal = await Services().get(`/order/api/back-office/dashboard/total-order?${action.payload.params}`)
                    
                        return dispatch => {
                            dispatch({
                                type: 'GET_SUMMARY_ORDER',
                                payload: {
                                    dataTotalOrder: rawResponseTotal.data.data
                                }
                            })
                        }
                    case 'REQUEST_FILTER_CHANNEL' :
                        const rawFilterChannel = await Services().get(`order/api/back-office/orders/dropdown/channel`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_FILTER_CHANNEL',
                                payload: {
                                    data: rawFilterChannel?.data?.data?.channelType.map(val=>({
                                        value: val,
                                        label: capitalize(val)
                                    }))
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_ORDER_LIST_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
