import React, { useEffect } from 'react'
import {
  Segment,
  Text,
  FormControl,
  Button,
  OptionBox,
  ButtonGroup,
} from "@elevenia/master-ui/components/Atom";
import ModalDeleteSection from 'component/ModalCustom/modalSmall';
import { useAction, useInput } from "hooks";
import { useSelector } from "react-redux";
import { validateForm } from 'helper';

const ModalDelete = ({showModal, setShowModal, getListReason, cancelOrder, orderStatus, orderId, categoryOrderId, searchValue}) =>{
  const { hasFetch } = useAction();
  

  const payload = useSelector((state) => ({
    
    listReasonCancel: state.trackOrder.listReasonCancel?.map(val=>({value:val.id,label:val.description})),
    loadingListReasonCancel: state.trackOrder.loadingListReasonCancel,
    loadingCancelOrder: state.trackOrder.loadingCancelOrder,
    statusActionCancel: state.trackOrder.statusActionCancel,
    orderId: orderId
    
  }))

  let { value, bindSelect } = useInput({
    initialObjects: {
      selectedReason: null
      
    },
    identity: "myForm",
  });
  useEffect(()=>{
    hasFetch(getListReason({type:'CANCEL_FROM_BO',orderStatus}))
    
  },[hasFetch,getListReason,orderStatus])

  useEffect(()=>{
    if(!payload.loadingCancelOrder && payload.statusActionCancel){
      setShowModal(false)
    }
  },[payload.statusActionCancel,payload.loadingCancelOrder,setShowModal])
  
  const handleSubitReason = () => {
    
    if (validateForm('myForm')) {
      hasFetch(cancelOrder({
        orderId:payload.orderId,
        cancelReasonId:value.selectedReason,
        categoryOrderId:categoryOrderId,
        searchValue:searchValue
      }))
    }
  }
  return (
    <>
      <ModalDeleteSection
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        title={"Batalkan Pesanan"}
        content={<Segment py={8}>
          <p>Pesanan ini akan dibatalkan sehingga semua proses pengiriman pesanan akan dihentikan.</p>
          <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
            <Text fontWeight={500} mb={8} mt={20}>Alasan</Text>
            <FormControl>
              <OptionBox
                name={'selectedReason'}
                placeholder={"Alasan Pembatalan"}
                defaultValue={""}
                isLoading={payload.loadingReasonCancel}
                isDisabled={payload.loadingReasonCancel}
                noOptionsMessage={() => 'Tidak Ada Data'}
                options={payload.listReasonCancel}
                value={payload.listReasonCancel?.find(val=>val.value===value.selectedReason) }
                inputClassName='validate[required] validateMessage{required{Alasan harus diisi}}'
                {...bindSelect}
              />
            </FormControl>
          </form>
        </Segment>
        }
        ButtonFooter={
          (
            <ButtonGroup>
              <Button minWidth={"100px"} variant={"secondary"} onClick={() => setShowModal(!showModal)}>
                Batal
              </Button>
              <Button 
                minWidth={"100px"}
                onClick={()=>handleSubitReason()}
                disabled={!value.selectedReason ? true : payload.loadingCancelOrder ? true : false }
              >
                Simpan
              </Button>
            </ButtonGroup>
          )
        }
      />
    </>
  )
}

export default ModalDelete