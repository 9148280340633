import React, { useEffect } from 'react';

const TooltipContentCustom = ({
    name,
    containerStyle = {},
    arrowStyle = {},
    children
}) => {
    useEffect(() => {
        const tooltipContainer = document.querySelector(`div:has(.${name})`);
        const tooltipArrow = document.querySelector(`div:has(.${name}) > div:last-child`);

        // apply styles
        if (tooltipContainer && tooltipArrow) {
            Object.entries(containerStyle).forEach(([key, value]) => {
                tooltipContainer.style[key] = value;
            });

            Object.entries(arrowStyle).forEach(([key, value]) => {
                tooltipArrow.style[key] = value;
            });
        };
    }, [name, containerStyle, arrowStyle]);

    return (
        <>
            {children}
        </>
    );
};

export default TooltipContentCustom;
