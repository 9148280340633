import React from 'react'
import {
    Segment,
    Text,
    Row
} from '@elevenia/master-ui/components/Atom'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/id'
import { getLabelTimeZone, serviceTypeDashboard, colorOrderId } from 'helper'
import SectionOrderStatus from 'page/dashboard/_components/SectionOrderStatus'
import TableDashboard from 'page/dashboard/_components/TableDashboard'
import SectionSLAReview from 'page/dashboard/_components/SectionSLAReview'
import SectionCancelOrder from 'page/dashboard/_components/SectionCancelOrder'
import { payloadDashboard } from 'page/dashboard/_helper'

const IndomaretPaket = () => {
    const payload = useSelector(state => {
        return payloadDashboard(state);   
    })

    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    

    const dataSummaryStatus =  [
        {
            status: 'ALLOCATING',
            color: '#FDC194',
            titleStatus: 'Pesanan Baru',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'PICKING_UP',
            color: '#B7E27D',
            titleStatus: 'penjemputan',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'IN_DELIVERY',
            color: '#F99698',
            titleStatus: 'Pengiriman',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'COMPLETED',
            color: '#6CA5E0',
            titleStatus: 'Selesai',
            path: 'pesanan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        {
            status: 'FALLBACK',
            color: '#FCB731',
            titleStatus: 'Fallback & Titip',
            path: 'pembatalan',
            dateTime: moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
    ] 

    return (
        <>
            <Segment>
                <SectionOrderStatus
                    loadingOrderSummary= {payload.loadingOrderSummary}
                    dataOrderSummary= {payload.dataOrderSummary}
                    dataStatus= {dataSummaryStatus}
                    addOnQueryString={'&channelOrder=INDOMARET&orderTypeGroup=group-paket'}
                />
                
                <Row mt={32} justifyContent="space-between" className="sm-flex-wrap">
                    
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                            title ={<Text H16 mb={8}>
                                Pesanan Belum Dijemput <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 2 Jam
                            </Text>}
                            countData = {{
                                text:payload?.totalOrderAbnormalPickup??'-',
                                bgColor: '#FFA800'
                            }}
                            columns = {[{
                                field: 'AWB ID',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                    )
                                }
                            }, {
                                field: 'Kode Toko',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Segment width={"95%"}>{entity.storeCode}</Segment>
                                    )
                                }
                            }, {
                                field: 'Nama Cabang',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                    )
                                },
                            }, {
                                field: 'Mulai Jemput',
                                isCustomRow: (id, entity) => {
                                    return entity.minDeliveryTime ? `${moment(entity.minDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                    
                                },
                            }, {
                                field: 'Service',
                                isCustomRow: (id, entity) => {
                                    return   serviceTypeDashboard(entity)
                                },
                            }
                            ]}
                            loading={payload?.loadingAbnormalPickup}
                            data={payload?.dataAbnormalPickup ?? []}
                        />
                    </Segment>
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                            title ={<Text H16 mb={8}>Pesanan Belum Dikirim <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 4 Jam</Text>}
                            countData = {{
                                text:payload?.totalOrderAbnormalInDelivery??'-',
                                bgColor: '#FFA800'
                            }}
                            columns = {[{
                                field: 'AWB ID',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                    )
                                }
                            }, {
                                field: 'Kode Toko',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <Segment width={"95%"}>{entity.storeCode}</Segment>
                                    )
                                }
                            }, {
                                field: 'Nama Cabang',
                                isCustomRow: (id, entity) => {
                                    return (
                                        <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                    )
                                },
                            }, {
                                field: 'Batas Kirim',
                                isCustomRow: (id, entity) => {
                                    return entity.maxDeliveryTime ? `${moment(entity.maxDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                    
                                },
                            }, {
                                field: 'Service',
                                isCustomRow: (id, entity) => {
                                    return   serviceTypeDashboard(entity)
                                },
                            }
                            ]}
                            loading={payload?.loadingAbnormalInDelivery}
                            data={payload?.dataAbnormalInDelivery ?? []}
                        />
                    </Segment>
                </Row>
                <Row mt={32} justifyContent="space-between" className="sm-flex-wrap">
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                                title ={<Text H16 mb={8}>
                                    Pesanan Belum Selesai Pembatalan
                                </Text>}
                                countData = {{
                                    text:payload?.totalOrderNotYetCanceled??'-',
                                    bgColor: '#FFA800'
                                }}
                                columns = {[{
                                    field: 'AWB ID',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Segment width={"95%"}>{entity.storeCode}</Segment>
                                        )
                                    }
                                }, {
                                    field: 'Nama Cabang',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                        )
                                    },
                                }, {
                                    field: 'Tanggal Pengajuan',
                                    isCustomRow: (id, entity) => {
                                        return entity.cancelingDate ? `${moment(entity.cancelingDate).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                        
                                    },
                                }, {
                                    field: 'Service',
                                    isCustomRow: (id, entity) => {
                                        return   serviceTypeDashboard(entity)
                                    },
                                }
                                ]}
                                loading={payload?.loadingNotYetCanceled}
                                data={payload?.dataNotYetCanceled ?? []}
                            />
                    </Segment>
                    <Segment width={"49%"} className="custom-table">
                        <TableDashboard
                                title ={<Text H16 mb={8}>
                                    AWB Pembatalan Gantung
                                </Text>}
                                countData = {{
                                    text:payload?.totalOrderHangingAwb??'-',
                                    bgColor: '#FFA800'
                                }}
                                columns = {[{
                                    field: 'AWB ID',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Segment width={"95%"}>{entity.storeCode}</Segment>
                                        )
                                    }
                                }, {
                                    field: 'Nama Cabang',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                        )
                                    },
                                }, {
                                    field: 'Tanggal Pembatalan',
                                    isCustomRow: (id, entity) => {
                                        return entity.canceledDate ? `${moment(entity.canceledDate).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                        
                                    },
                                }, {
                                    field: 'Service',
                                    isCustomRow: (id, entity) => {
                                        return   serviceTypeDashboard(entity)
                                    },
                                }
                                ]}
                                loading={payload?.loadingHangingAwb}
                                data={payload?.dataHangingAwb ?? []}
                            />
                    </Segment>
                </Row>

                <Segment mt={32}>
                    <Row justifyContent="space-between" className="sm-flex-wrap">
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16} >
                            <SectionSLAReview
                                payload={payload}
                            />
                        </Segment>
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16}>
                            <SectionCancelOrder
                                payload={payload}
                            />
                        </Segment>
                    </Row>
                </Segment>
            </Segment>
        </>
    )
}

export default IndomaretPaket