
const initialState = {
    data: "",
    totalPages:0,
    parameter:null,
    loading: false,
    detailData:null,
    previewData:null,
    roles:[],
    statuses:[],
    loadingRoles:false,
    statusCheck:{
        nik:'',
        email:''
    },
    loadingCheck:{
      nik:false,
      email:false
    },
    messageError:{
      nik:'',
      email:''
    },
    dataFoundNik:null,
    field:''
}

export const users = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_USERS':
            return {
                ...state,
                loading: true,
                detailData:null,
                statusCheck:{
                    nik:'',
                    email:''
                  },
                messageError:{
                    nik:'',
                    email:''
                },
                dataFoundNik:null,
                field:'',
            }
        case 'GET_USERS':
            
            return {
                ...state,
                data:action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,
                roles:action.payload.filterRoles,
                statuses:action.payload.filterStatus,
                loading: false
            }
        case 'REQUEST_USER_BY_ID':
            return {
                ...state,
                loading:true,
                
            }
        case 'GET_USER_BY_ID':
        
            return {
                ...state,
                detailData:action.payload.data,
                loading: false,
                field:'',
                statusCheck:{
                    nik:'00',
                    email:'00'
                  },
                  
            }
        case 'REQUEST_USER_ROLES':
            return {
                ...state,
                loadingRoles:true
            }
        case 'GET_USER_ROLES':
            return {
                ...state,
                roles : action.payload.data,
                loadingRoles:false
            }
        case 'GET_USERS_FAILED':
            return {
                ...state,
                loading: false
            }
        case 'SET_USER_PREVIEW':
            return {
                ...state,
                previewData:action.payload.params
            }
        case 'REQUEST_CHECK_NIK_USER':
            return{
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    nik:true,
                },
                messageError:{
                    ...state.messageError,
                    nik:''
                },
                dataFoundNik:null
                
            }
            case 'RESET_INPUT_STATUS':
                return {
                    ...state,
                    statusCheck:{
                        ...state.statusCheck,
                        [action.payload.field]:''
                    },
                    messageError:{
                        ...state.messageError,
                        [action.payload.field]:''
                    }
                }
            case 'REQUEST_CHECK_EMAIL_USER':
                return{
                    ...state,
                    loadingCheck:{
                        ...state.loadingCheck,
                        email:true,
                    },
                    messageError:{
                        ...state.messageError,
                        email:''
                    }
                    
                }
            case 'GET_STATUS_FIELD_USER':
                return {
                    ...state,
                    loadingCheck:{
                        ...state.loadingCheck,
                        [action.payload.field]:false,
                    },
                    statusCheck:{
                        ...state.statusCheck,
                        [action.payload.field]:action.payload.status
                    },
                    messageError:{
                        ...state.messageError,
                        [action.payload.field]:action.payload.message
                    },
                    dataFoundNik:action.payload.field==='nik' ? action.payload.data : state.dataFoundNik,
                    field:action.payload.field
                }
            case 'ERROR_STATUS_FIELD_USER':
                return {
                    ...state,
                    loadingCheck:{
                        ...state.loadingCheck,
                        [action.payload.field]:false,
                    },
                    messageError:{
                        ...state.messageError,
                        [action.payload.field]:action.payload.message
                    },
                    statusCheck:{
                        ...state.statusCheck,
                        [action.payload.field]:action.payload.status
                    },
                    field:action.payload.field
                }
        default:
            return state
    }
}