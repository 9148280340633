import React from "react"
import DataTable from 'component/DataTable'
import useSWR from "swr";
import { fetcher } from "helper";
import { Link } from "react-router-dom";

const TabKlaster = (props) => {

    const optionsSwr = {
        revalidateOnFocus: false
    }

    const { data: dataCluster, error, isValidating } = useSWR((props.idDeliman) && `/store/api/back-office/clusters/driver/${props.idDeliman}`, fetcher, optionsSwr);

    const payload = {
        data: dataCluster?.data.content,
        loading: props.idDeliman && !dataCluster && !error,
    }

    return <>
        <DataTable
            countingList={true}
            defaultSize={payload.data?.length}
            isLoading={payload.loading || isValidating}
            tableConsume={[{
                field: 'Kode Klaster',
                isCustomRow: (id, entity) => {
                    return (
                        <Link target="_blank" to={`/klaster?searchParam=${entity.clusterCode}`}>{entity.clusterCode}</Link>
                    )
                }
            }, {
                field: 'Nama Klaster',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.clusterName}</>
                    )
                }
            }, {
                field: 'Wilayah',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.postalCodeDescription}</>
                    )
                }
            }]}
            dataConsume={payload.data}
        />
    </>
}

export default TabKlaster