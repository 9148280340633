import React from "react"
import { Segment, Text, Spinner } from "@elevenia/master-ui/components/Atom"
import Chart from "react-apexcharts";

const ChartLabelBlock = ({ labelColor, labelName, labelCount, ...props }) => {
    return (
        <>
            <Segment {...props} justifyContent="space-between">
                <Segment display="flex">
                    <Segment mr={8} bg={labelColor} width="16px" height="16px" borderRadius={4} />
                    <Text color="#151823" fontWeight={500}>
                        {labelName}
                    </Text>
                </Segment>
                <Text color="primary" fontSize={16} fontWeight={500}>
                    {labelCount}
                </Text>
            </Segment>
        </>
    )
}

const SectionSLAReview = ({
    payload

}) => {
    const dataDoughnut = {
        labels: ['Selesai Dalam SLA', 'Over SLA'],
        data: [
            payload?.dataSLADeliveryReview?.orderFinishedInTime,
            payload?.dataSLADeliveryReview?.orderFinishedOverTime
        ],
        backgroundColor: [
            '#FFA800',
            '#F9C662'
        ]

    }

    const dataDoughnutPercentage = !payload?.loadingSLADeliveryReview && (payload?.dataSLADeliveryReview?.orderFinishedInTime + payload?.dataSLADeliveryReview?.orderFinishedOverTime) > 0 ? payload?.dataSLADeliveryReview?.orderFinishedInTime / (payload?.dataSLADeliveryReview?.orderFinishedInTime + payload?.dataSLADeliveryReview?.orderFinishedOverTime) * 100 : 0
    return (
        <>
            <Text H16 mb={24} mt={12}>SLA Delivery Review</Text>
            <Segment>
                {
                    !payload?.loadingSLADeliveryReview ?
                        <>
                            <Segment>
                                <Segment position="relative">
                                    <Segment className="chart-wrapper" position="relative" zIndex="2" mx="auto" mb={16}>
                                        <Chart
                                            options={
                                                {
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    chart: {
                                                        width: '100%'
                                                    },
                                                    legend: {
                                                        show: false
                                                    },
                                                    colors: dataDoughnut.backgroundColor,
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: false,
                                                            donut: {
                                                                size: '80%'
                                                            }
                                                        }
                                                    },
                                                    labels: dataDoughnut.labels,
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const valueHover = series[seriesIndex]
                                                            const labelHover = dataDoughnut.labels[seriesIndex]
                                                            const total = series.reduce((a, b) => a + b, 0)
                                                            const percentace = total > 0 ? (valueHover / total) * 100 : 0
                                                            return `<div style="padding:10px">${labelHover} : ${percentace.toFixed(0)}% - ${valueHover} Order</div>`
                                                        }
                                                    }
                                                }}
                                            series={dataDoughnut.data}
                                            type="donut"
                                        />
                                    </Segment>

                                    <Segment justifyContent={'center'} alignItems={'center'} zIndex="1" position="absolute" top={0} left={0} right={0} bottom={0}>
                                        <Segment style={{ textAlign: 'center' }} className="group-label-donut">
                                            <Text className="percent" color={"#151823"} fontWeight={500} >
                                                {`${dataDoughnutPercentage.toFixed(0)}%`}
                                            </Text>
                                            <Text className="text-label" color={"#9C9DA6"} fontWeight={400} mt={8}>
                                                Selesai dalam SLA
                                            </Text>
                                        </Segment>
                                    </Segment>
                                </Segment>
                            </Segment>
                            <Segment>
                                <Segment boxShadow borderRadius={4} p={12} border={'1px solid #eff1f9'}>
                                    <ChartLabelBlock labelColor="#FFA800" labelName="Selesai Dalam SLA" labelCount={`${payload?.dataSLADeliveryReview?.orderFinishedInTime} Order`} />
                                    <ChartLabelBlock labelColor="#F9C662" labelName="Over SLA" mt={20} labelCount={`${payload?.dataSLADeliveryReview?.orderFinishedOverTime} Order`} />
                                </Segment>

                                <Segment boxShadow borderRadius={4} p={12} mt={16} border={'1px solid #eff1f9'}>
                                    <Segment justifyContent="space-between" mb={20}>
                                        <Text color="#9C9DA6">
                                            Total
                                        </Text>
                                        <Text color="primary" fontWeight="500">
                                            {`${payload?.dataSLADeliveryReview?.totalOrder} Order`}
                                        </Text>
                                    </Segment>
                                    <Segment justifyContent="space-between">
                                        <Text color="#9C9DA6">
                                            Klaster
                                    </Text>
                                        <Text color="primary" fontWeight="500">
                                            {`${payload?.dataSLADeliveryReview?.totalCluster} Klaster`}
                                        </Text>
                                    </Segment>
                                </Segment>
                            </Segment>
                        </>
                        : <Segment className="u-tx-center" width="100%" height={30}>
                            <Spinner />
                        </Segment>
                }
            </Segment>
        </>
    )
}

export default SectionSLAReview;
