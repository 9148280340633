const initialState = {
    email: {
        loading: false,
        code: "",
        error: false,
    },
    otp: {
        code: "",
        error: false,
    }
}

export const verificationSetup = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_SETUP':
            return {
                ...state,
                email: {
                    ...state.email,
                    loading: true,
                }
            }
        case 'OTP_FAILED':
            return {
                ...state,
                otp: {
                    ...action.payload
                }
            }
        case 'SETUP_FAILED':
            return {
                ...state,
                email: {
                    loading: false,
                    ...action.payload
                }
            }
        case "SETUP_RESET":
            return initialState
        default:
            return state
    }
}