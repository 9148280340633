import { useEffect } from 'react'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const LazyLoad = () => {
    useEffect(() => {
        NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });
        NProgress.start();
        return () => {
            NProgress.done();
        };
    }, []);
    return null;
};
export default LazyLoad