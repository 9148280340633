import { swrFetcher } from "helper";
import useSWR, { mutate } from "swr";


const useFetchSWR = ({
    key,
    options,
    mappingData,
}) => {
    
    const {data, error, isValidate, mutate: refetch} = useSWR(key, swrFetcher, options)
    
    return {
        data: mappingData(data),
        isValidate,
        loading: !data && !error,
        error,
        refetch,
        mutateData: mutate,
        realData: data,
        key
    }
}

export default useFetchSWR