const initialState = {
    data: '',
    totalPages: 0,
    parameter: null,
    loading: false,
    detailData: null,
    orderListById: null,
    loadingGetOrder: false,
    listDuration: null,
    loadingDuration: false,
};

export const releaseValidation = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_RELEASE_VALIDATION':
            return {
                ...state,
                loading: true,
                orderListById: null,
            };
        case 'REQUEST_RELEASE_VALIDATION_INTERVAL':
            return {
                ...state,
                loading: false,
            };
        case 'GET_RELEASE_VALIDATION':
            return {
                ...state,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case 'REQUEST_RELEASE_VALIDATION_HISTORY':
            return {
                ...state,
                loading: true,
                orderListById: null,
            };
        case 'GET_RELEASE_VALIDATION_HISTORY':
            return {
                ...state,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS':
            return {
                ...state,
                loadingGetOrder: true,
            };
        case 'GET_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: action.payload.orderList,
            };
        case 'RELEASE_VALIDATION_SUCCESS':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: null,
            };

        case 'REQUEST_DURATION_RELEASE_VALIDATION':
            return {
                ...state,
                loadingDuration: true,
            };
        case 'GET_DURATION_RELEASE_VALIDATION':
            return {
                ...state,
                loadingDuration: false,
                listDuration: action.payload.data,
            };
        case 'REQUEST_SUBMIT_RELEASE_VALIDATION':
            return {
                ...state,
                loading: true,
            };
        case 'RELEASE_VALIDATION_FAILED':
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
