
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:null,
  listDriverByIdStore:[],
  loadingCheck:{
    storeCode:false
  },
  messageError:{
      storeCode:''
  },
  statusCheck:{
      storeCode:''
  },
  listAddress:null,
  dropDownCluster:[]
}

export const store = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_LIST_STORE':
          return {
              ...state,
              loading: true,
              detailData:null,
              listDriverByIdStore:[],
              messageError:{
                  storeCode:''
              },
              statusCheck:{
                storeCode:''
            }
          }
      case 'REQUEST_STORE_BY_ID':
          return {
              ...state,
              loading: true,
              detailData:null,
              listDriverByIdStore:[]
          }
      case 'GET_STORE':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false
          }
      case 'GET_STORE_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data.detail,
            listDriverByIdStore:action.payload.data.listDriver,
            loading: false,
            statusCheck:{
                storeCode:'00'
            }
        }
      case 'GET_STORE_FAILED':
        return {
            ...state,
            loading: false
        }
        case 'REQUEST_CHECK_STORE_CODE':
            return {
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    storeCode:true,
                }
            }
        case 'GET_STATUS_STORE_CODE':
            return {
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    storeCode:false,
                },
                statusCheck:{
                    ...state.statusCheck,
                    storeCode:action.payload.status
                },
                messageError:{
                    ...state.messageError,
                    storeCode:action.payload.message
                }
            }
        case 'ERROR_STATUS_STORE_CODE':
            return {
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    storeCode:false,
                },
                messageError:{
                    ...state.messageError,
                    storeCode:action.payload.message
                },
                statusCheck:{
                    ...state.statusCheck,
                    storeCode:action.payload.status
                }
            }
            case 'RESET_INPUT_STATUS_STORE':
                return {
                    ...state,
                    statusCheck:{
                      ...state.statusCheck,
                      [action.payload.field]:''
                    },
                    messageError:{
                      ...state.messageError,
                      [action.payload.field]:''
                    }
                }
            case 'GET_LIST_ADDRESS':
                return {
                    ...state,
                    listAddress:action
                }
            case 'GET_DROPDOWN_CLUSTER_STORE':
                return {
                    ...state,
                    dropDownCluster:action.payload.data
                }
            case 'SET_STATUS_FIELD_STORE':
                return {
                    ...state,
                    statusCheck:{
                        storeCode:action.payload.status
                    },
                    loadingCheck:{
                        storeCode:false,
                        
                    },
                    messageError:{
                        storeCode:'',
                        
                    }
                }
            case 'RESET_FORM_STORE':
                return {
                    ...state,
                    detailData:null,
                    listDriverByIdStore:[],
                    messageError:{
                        storeCode:''
                    },
                    statusCheck:{
                        storeCode:''
                    }
                } 
      default:
          return state
  }
}