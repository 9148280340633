import {
    Button, ButtonGroup, FormControl, Row, Segment, Spinner, Switch, Text, Textfield
} from '@elevenia/master-ui/components/Atom';
import { Tabs } from "@elevenia/master-ui/components/Molecules";
import Breadcrumb from 'component/Breadcrumb';
import DataTable from 'component/DataTable';
import ModalConfirm from 'component/ModalCustom/modalSmall';
import { validateForm, validateInput, validateInputClear } from 'helper';
import { useAction, useInput } from 'hooks';
import Error404 from 'page/errorLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Services } from 'service';
import { requestAvailableClusterCode, requestCheckClusterCode, requestClusterById, requestUpdateCluster, resetInputStatus } from 'store/actions/cluster';


const KlasterForm = props => {
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;


    useEffect(() => {
        let unmounted = false;
        if (props.access) {
            getPath !== undefined && props.access?.update && !unmounted && hasFetch(requestClusterById(getPath))
        }
        return () => (unmounted = true);
    }, [hasFetch, getPath, props.access])
    const loading = useSelector(state => state.cluster.loading)


    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <>{(!loading) && <Form {...props} />}</>
    )
}

const Form = props => {

    document.title = props.title
    const history = props.history
    const [isModalCreate, setIsModalCreate] = useState(false)
    const id = props.match.params.id;

    const [activeTab, setActiveTab] = useState("daftarToko");
    const [disablePreview, setDisablePreview] = useState(true)
    const [flagChannel, setFlagChannel] = useState('')
    const [errorStore, setErrorStore] = useState(false)
    const title = id ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = id ? [
        { to: `/klaster/${id}`, label: 'Detail Klaster' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/klaster', label: 'Klaster' },
        ...dataBreadcrumbExt
    ]



    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            detailData: state.cluster.detailData,
            availableClusterCode: state.cluster.availableClusterCode ?? '',
            previewData: props?.location?.state,
            listStoreById: id ? state.cluster.listStoreById : props?.location?.state ? props?.location?.state.listStoreById : [],
            loadingStore: state.cluster.loadingStore,
            listDelimanById: id ? state.cluster.listDelimanById : props?.location?.state ? props?.location?.state.listDelimanById : [],
            loadingDeliman: state.cluster.loadingDeliman,
            statusCheck: state.cluster.statusCheck,
            loadingCheck: state.cluster.loadingCheck,
            messageError: state.cluster.messageError
        }
    })
    

    let { value, bindChange, setValue } = useInput({
        initialObjects: {
            postalCodeDescription: payload?.detailData?.postalCodeDescription ? payload?.detailData?.postalCodeDescription : payload?.previewData?.postalCodeDescription ? payload?.previewData?.postalCodeDescription : '',
            postalCodeId: payload?.detailData?.postalCodeId ? payload?.detailData?.postalCodeId : payload?.previewData?.postalCodeId ? payload?.previewData?.postalCodeId : '',
            clusterName: payload?.detailData?.clusterName ? payload?.detailData?.clusterName : payload?.previewData?.clusterName ? payload?.previewData?.clusterName : '',
            clusterCode: payload?.detailData?.clusterCode ? payload?.detailData?.clusterCode : payload?.previewData?.clusterCode ? payload?.previewData?.clusterCode : '',
            listStoreId: payload?.listStoreById?.length > 0 ? payload?.listStoreById.map(val => ({ ...val, value: val.storeCode, label: `${val.storeCode} - ${val.storeName}` })) : payload?.previewData?.listStoreId ? payload?.previewData?.listStoreId : [],
            listDriverId: payload?.listDelimanById?.length > 0 ? payload?.listDelimanById.map(val => ({ ...val, value: val.nik, label: `${val.nik} - ${val.name}` })) : payload?.previewData?.listDriverId ? payload?.previewData?.listDriverId : [],
            active: payload.detailData?.active ? payload?.detailData?.active : payload?.previewData?.active ? payload?.previewData?.active : id ? payload?.detailData?.active : true,
            coordinatorId: payload?.detailData?.coordinatorId ? payload?.detailData?.coordinatorId : payload?.previewData?.coordinatorId ? payload?.previewData?.coordinatorId : '',
            coordinatorDescription: payload?.detailData?.coordinatorId > 0 ? `${payload?.detailData?.coordinatorNik} - ${payload?.detailData?.coordinatorName}` : payload?.previewData?.coordinatorDescription ? payload?.previewData?.coordinatorDescription : ''
        },
        identity: "myForm",
    });
    

    useEffect(() => {
        const disabled = Object.keys(value).filter(val => {
            
            if (Array.isArray(value[val])) {
                if(val==='listDriverId' || val==='listStoreId'){
                    if(value.active){
                        return value[val].length === 0    
                    }else{
                        return false
                    }
                }else{
                    return value[val].length === 0    
                }
            } else {
                let valid = false
                if(val==='active'){
                    if (!value[val]) {
                        valid= id ? false : true
                    }
                }else{
                    valid = value[val] === ''
                }
                return valid
            }
        })


        if (disabled.length > 0) {

            setDisablePreview(true)
        } else {

            setDisablePreview(false)
        }

    }, [value,id])

    useEffect(() => {

        setValue(prev => {
            return {
                ...prev,
                clusterCode: payload.availableClusterCode ? payload.availableClusterCode : payload.messageError.clusterCode ? '' : prev.clusterCode
            }
        })

    }, [payload.availableClusterCode, payload.messageError.clusterCode, setValue])



    useEffect(() => {
        if (id && payload.listStoreById) {

            setValue(prev => {
                return {
                    ...prev,
                    listStoreId: payload.listStoreById.map(val => ({ ...val, value: val.storeCode, label: `${val.storeCode} - ${val.storeName}` }))
                }
            })
            let channel = payload.listStoreById.length > 0 ? payload.listStoreById[0].channel : ''
            setFlagChannel(channel) 
        }

    }, [id, payload.listStoreById, setValue])

    useEffect(() => {
        if (id && payload.listDelimanById) {
            setValue(prev => {
                return {
                    ...prev,
                    listDriverId: payload.listDelimanById.map(val => ({ ...val, value: val.nik, label: `${val.nik} - ${val.name}` }))
                }
            })
        }

    }, [id, payload.listDelimanById, setValue])

    const handleLoadOptionStore = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/store/api/back-office/stores/available?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.storeCode} - ${val.storeName}`,
                value: val.storeCode,
                ...val
            })))
        }
        if (inputValue.value === 0) {
            const response = await Services().get(`/store/api/back-office/stores/available?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.storeCode} - ${val.storeName}`,
                value: val.storeCode,
                ...val
            })))
        }

    }

    const handleLoadOptionDriver = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/driver/api/back-office/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }
        if (inputValue.value === 0) {
            const response = await Services().get(`/driver/api/back-office/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data.content
            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.nik,
                ...val
            })))
        }

    }
    

    const handleChangeStore = (data) => {
        let store = data ? data : []
        if(store.length > 0){
            if(value.listStoreId.length === 0) {
                setFlagChannel(store[0].channel)
                setValue(prev => {
                    return {
                        ...prev,
                        listStoreId: store
                    }
                })
                
            }else{
                
                if(store[data.length - 1].channel === flagChannel){
                    setValue(prev => {
                        return {
                            ...prev,
                            listStoreId: store
                        }
                    })
                    setErrorStore(false)
                }else{
                    
                    setErrorStore(true)
                }
            }
        }else{
            setFlagChannel('')
            setValue(prev => {
                return {
                    ...prev,
                    listStoreId: store
                }
            })
            setErrorStore(false)
        }
        

        setActiveTab('daftarToko')
        setTimeout(() => {
            value.active && validateInput('myForm', 'listStoreId')
            validateInput('myForm', 'channelValidator')
            
        }, 0)
        
    }

    const handleChangeDriver = (data) => {
        let driver = data ? data : []
        setValue(prev => {
            return {
                ...prev,
                listDriverId: driver
            }
        })

        setActiveTab('delivMan')
        setTimeout(() => {
            value.active && validateInput('myForm', 'listDriverId')
        }, 0)
    }

    const handleShowConfirmation = () => {
        if (validateForm('myForm')) {
            setIsModalCreate(!isModalCreate)
        }

    }

    const handlePreview = () => {

        if (validateForm('myForm')) {

            history.push({
                pathname: '/klaster/preview',
                state: value
            })
        }
    }

    const handleSubmit = () => {
        let idDrivers = []
        let idStores = []
        value.listStoreId.forEach(element => {
            idStores.push(element.id)
        });
        value.listDriverId.forEach(element => {
            idDrivers.push(element.id)
        });

        const dataUpdate = {
            active: value.active,
            clusterCode: value.clusterCode,
            clusterName: value.clusterName,
            id: payload.detailData?.id,
            listDriverId: idDrivers,
            listStoreId: idStores,
            postalCodeId: value.postalCodeId,
            coordinatorId: value.coordinatorId
        }

        hasFetch(requestUpdateCluster(dataUpdate, history))

    }

    const [onSearchClasterCode$] = useState(() => new Subject());

    useEffect(() => {

        onSearchClasterCode$.pipe(
            tap(() => hasFetch(resetInputStatus('clusterCode'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                validateInput('myForm', 'clusterCode') && hasFetch(requestCheckClusterCode({
                    clusterCode: value,
                    clusterId: id ?? ''
                }))

            })
        ).subscribe();

        return () => {
            onSearchClasterCode$.unsubscribe();
        }

    }, [hasFetch, onSearchClasterCode$, id])

    const handleChangeCode = (e) => {

        bindChange.onChange(e)
        onSearchClasterCode$.next(e.target.value)
    }

    const handleChangeWilayah = (data) => {
        if (data) {
            hasFetch(requestAvailableClusterCode({
                clusterId: id ?? null,
                postalCodeId: data.value
            }))
            setValue(prev => {
                return {
                    ...prev,
                    postalCodeDescription: data.label,
                    postalCodeId: data.value
                }
            })
        } else {

            hasFetch(resetInputStatus('clusterCode'))
            setValue(prev => {
                return {
                    ...prev,
                    postalCodeDescription: '',
                    postalCodeId: 0,
                    clusterCode: ''
                }
            })

        }
        setTimeout(() => {

            validateInput('myForm', 'postalCodeDescription')
        }, 0)

    }



    const handleLoadOptions = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/store/api/back-office/postal-codes?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.description}`,
                value: val.postalCodeId,
                zipCode: val.postal_code
            })))
        }

        if (inputValue.length === 0) {
            const response = await Services().get(`/store/api/back-office/postal-codes?searchParam=${inputValue}&unpaged=true`)
            const listData = response.data.data.content

            callback(listData?.map(val => ({
                label: `${val.description}`,
                value: val.postalCodeId,
                zipCode: val.postal_code
            })))
        }

    }

    const handleLoadOptionsCoordel = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(`/user/api/coordinator/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data

            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.id,

            })))
        }

        if (inputValue.length === 0) {
            const response = await Services().get(`/user/api/coordinator/search-by-name-or-nik/${inputValue}`)
            const listData = response.data.data

            callback(listData?.map(val => ({
                label: `${val.nik} - ${val.name}`,
                value: val.id,

            })))
        }

    }

    const handleChangeCoordel = (data) => {
        if (data) {
            setValue(prev => {
                return {
                    ...prev,
                    coordinatorDescription: data.label,
                    coordinatorId: data.value
                }
            })
        } else {

            setValue(prev => {
                return {
                    ...prev,
                    coordinatorDescription: '',
                    coordinatorId: 0
                }
            })

        }
        setTimeout(() => {

            validateInput('myForm', 'coordinatorDescription')
        }, 0)
    }

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setValue(prev => {
            return {
                ...prev,
                [name]: checked
            }
        });
        validateInput('myForm', name)
        if(!checked){
            validateInputClear('myForm','listDriverId')
            validateInputClear('myForm','listStoreId')
            
        }
    }

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Klaster</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Wilayah</Text>
                                </Segment>
                                <Segment flex={1} minWidth={204}>
                                    <FormControl
                                        status={payload.messageError.clusterCode ? 'error' : 'normal'}
                                        helptext={payload.messageError.clusterCode}
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeWilayah}
                                            isClearable={value.postalCodeId ? true : false}
                                            value={value.postalCodeId ? {label:value.postalCodeDescription, value: value.postalCodeId} : ''}
                                            loadOptions={handleLoadOptions}
                                            name='postalCodeId'
                                            inputClassName={'validate[required] validateMessage{required{Wilayah harus diisi}}'}
                                            placeholder={'Wilayah / Kode Pos'}
                                        />

                                    </FormControl>
                                    <input
                                        type="hidden"
                                        name="postalCodeDescription"
                                        className='validate[required] validateMessage{required{Wilayah harus diisi}}'
                                        value={value.postalCodeDescription ?? ''} />
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kode Klaster</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl

                                    >
                                        <Textfield
                                            status={payload.messageError.clusterCode ? 'error' : 'normal'}
                                            inputProps={{

                                                onChange: (e) => handleChangeCode(e),
                                                name: 'clusterCode',
                                                placeholder: "Kode Klaster",
                                                maxLength: 7,
                                                className: 'validate[required,maxLength[7]]',
                                                value: value.clusterCode

                                            }}
                                            disabled={true}
                                            right={
                                                payload.loadingCheck.clusterCode ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment>
                                                    : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama Klaster</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name: 'clusterName',
                                                maxLength: 100,
                                                placeholder: "Nama Klaster",
                                                className: 'validate[required,maxLength[100]]',
                                                value: value.clusterName
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Koordinator Delivery</Text>
                                </Segment>
                                <Segment flex={1} minWidth={204}>
                                    <FormControl
                                        status={payload.messageError.coordinatorId ? 'error' : 'normal'}
                                        helptext={payload.messageError.coordinatorId}
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeCoordel}
                                            isClearable={value.coordinatorId ? true : false}
                                            value={value.coordinatorId ? {label:value.coordinatorDescription, value: value.coordinatorId} : ''}
                                            loadOptions={handleLoadOptionsCoordel}
                                            name='coordinatorId'
                                            inputClassName={'validate[required] validateMessage{required{Koordinator Delivery harus diisi}}'}
                                            placeholder={'Koordinator Delivery'}
                                        />

                                    </FormControl>
                                    <input
                                        type="hidden"
                                        name="coordinatorDescription"
                                        className='validate[required] validateMessage{required{Koordinator Delivery harus diisi}}'
                                        value={value.coordinatorDescription ?? ''} />
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Toko</Text>
                                </Segment>
                                <Segment flex={1} minWidth={204}>
                                    <FormControl
                                        
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeStore}
                                            isClearable
                                            isMulti
                                            value={value.listStoreId}
                                            loadOptions={handleLoadOptionStore}

                                            placeholder={'Toko'}
                                        />
                                    </FormControl>
                                    <input
                                        type="hidden"
                                        name="listStoreId"
                                        className={value.active ? 'validate[required] validateMessage{required{Toko harus diisi}}' : ''}
                                        value={value.listStoreId.length > 0 ? value.listStoreId.length : ''} />
                                    <input
                                        type="hidden"
                                        name="channelValidator"
                                        className={'validate[required] validateMessage{required{Tidak dapat menginput toko dengan channel berbeda}}'}
                                        value={errorStore ? '' : 1} />
                                    
                                    
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Delivery Man</Text>
                                </Segment>
                                <Segment flex={1} minWidth={204}>
                                    <FormControl>
                                        <AsyncSelect
                                            onChange={handleChangeDriver}
                                            isClearable
                                            isMulti
                                            value={value.listDriverId}
                                            loadOptions={handleLoadOptionDriver}

                                            placeholder={'Delivery Man'}
                                        />
                                    </FormControl>
                                    {
                                        value.active && <input
                                        type="hidden"
                                        name="listDriverId"
                                        className='validate[required] validateMessage{required{Delivery Man harus diisi}}'
                                        value={value.listDriverId.length > 0 ? value.listDriverId.length : ''} />
                                    }
                                    
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1} width={280}>
                                    <Switch 
                                            name="active" 
                                            label={value.active ? "Aktif" : "Non-Aktif"} 
                                            labelPosition={"right"}
                                            onChange={(e)=>handleCheck(e)} 
                                            checked={value.active} />
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32}>
                        <Tabs active={activeTab} onChange={active => setActiveTab(active)}>
                            <Text key="daftarToko">Daftar Toko</Text>
                            <Text key="delivMan">Delivery Man</Text>
                        </Tabs>
                        <Segment mt={24}>
                            {activeTab === "daftarToko"
                                && <DataTable
                                    countingList={true}
                                    //defaultSize={10}
                                    tableConsume={[{
                                        field: 'Kode Toko',
                                        rowField: 'storeCode',
                                        // isCustomRow: (id, entity) => {
                                        //     return (
                                        //         <Link to={`/toko/${entity.id}`}>{entity.storeCode}</Link>
                                        //     )
                                        // }
                                    }, {
                                        field: 'Nama Toko',
                                        rowField: 'storeName'
                                    }, {
                                        field: 'Alamat Toko',
                                        rowField: 'address'
                                    }]}
                                    dataConsume={value.listStoreId}
                                />}

                            {activeTab === "delivMan"
                                && <DataTable
                                    countingList={true}
                                    defaultSize={10}
                                    tableConsume={[{
                                        field: 'NIK Delivery Man',
                                        rowField: 'nik',
                                        // isCustomRow: (id, entity) => {
                                        //     return (
                                        //         <Link to={`/delivery-man/${entity.id}`}>{entity.nik}</Link>
                                        //     )
                                        // }
                                    }, {
                                        field: 'Nama',
                                        rowField: 'name'
                                    }, {
                                        field: 'Kontak Delivery Man',
                                        rowField: 'phone'
                                    }, {
                                        field: 'Tipe Pegawai',
                                        rowField: 'employmentType'
                                    }]}
                                    dataConsume={value.listDriverId}
                                />}
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={id ? `/klaster/${id}` : "/klaster"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={disablePreview ? true : false}
                                >
                                    Simpan
                                </Button>
                                :
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    disabled={disablePreview ? true : false}
                                    onClick={handlePreview}
                                >
                                    Preview
                                </Button>

                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button onClick={handleSubmit} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default KlasterForm