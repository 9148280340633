import React from 'react'
import {
    Segment,
    Icon,
    Text
} from "@elevenia/master-ui/components/Atom"

const StatusColor = (children) => {
    if (children.duration <= 900 && 301 > children.duration) {
        return <Segment alignItems={'center'}>
            <Icon name={"waiting-full"} size={14} fillColor="#EE2B2E" />
            <Text fontWeight="500" color="red20" ml={4} style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>{children.text}</Text>
        </Segment>
    }
    else if (301 <= children.duration) {
        return <Segment alignItems={'center'}>
            <Icon name={"waiting-full"} size={14} fillColor="#1178D4" />
            <Text fontWeight="500" color="blue20" ml={4} style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>{children.text}</Text>
        </Segment>
    } else {
        return children.text
    }

}

export default StatusColor