import React, { useState, useEffect } from "react"
import {
    Segment,
    FormControl,
    Icon,
    Textfield,
    Button,
    ButtonGroup,
    Text
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import ModalNotes from 'component/ModalCustom/modalSmall'
import { Link } from "react-router-dom"
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { useAction } from "hooks"
import useFetchSWR from "hooks/useFetchSWR"
import { parseValue } from "helper"
import moment from "moment"

const basicStyle = { minWidth: 44, height: 32, padding: "8px 16px", fontSize: '12px' };

const styleBtnUbah = {
    ...basicStyle,
    background: "#1178D4"
};

const TabDeliman = (props) => {
    const { hasFetch } = useAction();
    const [isModalNotes, setIsModalNotes] = useState(({ show: false, data: null }))
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        sort: "",
        status: 'active',
        startDate: '',
        endDate: ''
    });

    const optionsSwr = {
        revalidateOnFocus: true,
        shouldRetryOnError: false,
        revalidateOnMount: true
    };

    const { data: dataActive, loading, error, key } = useFetchSWR({
        key: (isTable && props.access?.read) && [`/driver/api/back-office/absensi`, isTable],
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data.content,
            totalPages: data?.data.totalPages,
            totalElements: data?.data.totalElements
        })
    });

    const payload = {
        // data: dataActive?.data.content,
        data: dataActive?.data,
        loading: loading,
        totalPages: dataActive?.totalPages
    };

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        };
    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            keyword: value.trim()
        });
    }

    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
        }
    }, [hasFetch, error]);

    return (
        <>
            <Segment mb={32} pb={16}>
                <Segment
                    className="group-filter"
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Segment maxWidth={280} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => handleSearch(e),
                                    placeholder: "Cari NIK atau Nama Delivery Man",
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment alignItems={"center"} justifyContent={"space-between"} className="group-filter">
                        <Link to={"/absensi-deliveryman/form"}>
                            <Button>
                                Upload Jadwal Delivery Man
                            </Button>
                        </Link>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loading}
                    defaultSize={isTable.size}
                    totalPages={payload.totalPages}
                    tableConsume={[
                        {
                            field: "NIK Delivery Man",
                            entityFilters: 'deliveryManNik',
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Delivery Man",
                            entityFilters: 'deliveryManName',
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Terakhir Diperbarui",
                            width: "30%",
                            entityFilters: 'modifiedDate',
                            isCustomRow: (value) => parseValue(value, (val) => moment(val).format('DD/MM/yyyy'))
                        }, {
                            field: "Action",
                            isCustomRow: (_, entity) => {
                                return (
                                    <Link to={{
                                        pathname: `/absensi-deliveryman/${entity.id}/ubah-jadwal`, state: {
                                            mutate: key
                                        }
                                    }}>
                                        <Button style={styleBtnUbah}>
                                            Ubah Jadwal
                                        </Button>
                                    </Link>
                                );
                            },
                        },
                    ]}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {

                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>

            {/* modal show notes */}
            {isModalNotes.show && <ModalNotes
                isOpen={isModalNotes.show}
                onClose={() => setIsModalNotes({ show: !isModalNotes.show, data: null })}
                title={"Informasi Jadwal"}
                content={<Segment py={8} style={{ lineHeight: "21px" }}>
                    <Segment>
                        <Text color={'#151823'} fontWeight="500">
                            Alasan Lokasi Tidak Sesuai
                        </Text>
                        {isModalNotes.data?.checkinNote}
                    </Segment>
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModalNotes({ show: !isModalNotes, data: null })}>
                                Tutup
                            </Button>
                        </ButtonGroup>
                    )
                }
            />}
        </>
    );
};

export default TabDeliman;
