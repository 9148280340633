import { useCallback, useState } from 'react';
import { validateInput, validateFormClear, validateInputClear } from 'helper';
import { getBase64ImageFile } from 'helper/Generics';

export const useInput = ({ initialObjects, identity }) => {
    const [hasValue, setValue] = useState(initialObjects);
    // const [hasData, setData] = useState([]);
    const selectItems = (items, value) => {
        const idx = items.indexOf(value);
        if (idx >= 0) {
            items.splice(idx, 1);
        }
        if (idx === -1) {
            items.splice(idx, 0, value);
        }
        return items;
    }

    const onChange = async (e) => {
        const { name, value } = e.target
        setValue(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        try {
            await validateInput(identity, name)
        }
        catch (e) {

        }
    }

    const onSelect = async (selectPropertiesValue, AttrTextName) => {
        const { name } = AttrTextName;
        const dataValue = selectPropertiesValue ? selectPropertiesValue.value : '';
        setValue(prev => {
            return {
                ...prev,
                [name]: dataValue
            }
        });
        try {
            await validateInputClear(identity, name);
        }
        catch (e) {

        }
    }

    const onChecked = async (e) => {
        const { name, checked } = e.target;
        setValue(prev => {
            return {
                ...prev,
                [name]: checked
            }
        });
        try {
            await validateInput(identity, name)
        }
        catch (e) {

        }
    }

    const onCheckedAll = (e, actions, code) => {
        const { name, value, checked } = e.target
        let result = selectItems(hasValue[name], value)
        if (checked) {
            result = selectItems(actions, value);
            if (value === undefined) {
                result = selectItems(actions, value);
                result.push(code)
            }
            setValue(prev => {
                return {
                    ...prev,
                    [name]: result
                }
            });
        }
        else {
            setValue(prev => {
                return {
                    ...prev,
                    [name]: []
                }
            });
        }
    }

    const onCheckedAllBatch = useCallback((e, actions, tag, flex) => {
        const { name, value } = e.target
        let result = selectItems(hasValue[name], value);
        const actionUpdate = actions.filter(tag => tag !== "delete")
        let resultUpdate = flex ? result.filter(tag => tag !== "delete") : result
        if (resultUpdate.length === actionUpdate.length) {
            resultUpdate = selectItems(resultUpdate, tag);
        }
        setValue(prev => {
            return {
                ...prev,
                [name]: resultUpdate
            }
        });
    }, [hasValue])

    const onCheckedBatch = (e) => {
        const { name, value } = e.target
        const result = selectItems(hasValue[name], value);
        setValue(prev => {
            return {
                ...prev,
                [name]: result
            }
        });
    }

    const onImagesChanges = async (e) => {
        const { name } = e.target
        const file = e.target.files[0]
        if (file !== undefined) {
            const fileContentType = file.type
            getBase64ImageFile(file, base64 => {
                setValue(prev => ({
                    ...prev,
                    [name]: base64.split(',')[1],
                    imagePreview: URL.createObjectURL(file),
                    fileContentType,
                    fileName: file.name
                }))
            })
        }
        else {
            setValue(prev => ({
                ...prev,
                fileContentType: "",
                fileName: "",
                image: "",
                imagePreview: ""
            }))
        }
        try {
            await validateInput(identity, name)
        }
        catch (e) {

        }
    }

    const onGetDate = async (name, value) => {
        setValue(prev => {
            return {
                ...prev,
                [name]: new Date(value)
            }
        });
        setTimeout(async () => {
            try {
                await validateInput(identity, name)
            }
            catch (e) {

            }
        }, 0);

    }

    const validateSingleInput = (name) => {
        validateInput(identity, name)
    }

    const validateMultipleInput = (properties) => {
        validateInput(identity, properties)
    }

    const resetByName = async (name) => {
        setValue({
            ...hasValue,
            [name]: ""
        });
        try {
            await validateInputClear(identity, name);
        }
        catch (e) {

        }
    }

    const resetMultipleName = (properties) => {
        validateInputClear(identity, properties);
    }

    const resetByForm = () => {
        setValue(initialObjects);
        validateFormClear(identity);
    }

    return {
        selectItems,
        setValue,
        value: hasValue,
        resetByForm,
        resetByName,
        resetMultipleName,
        validateSingleInput,
        validateMultipleInput,
        bindChange: {
            onChange: onChange
        },
        bindSelect: {
            onChange: onSelect
        },
        bindChecked: {
            onChange: onChecked
        },
        bindCheckedBatch: {
            onChange: onCheckedBatch
        },
        bindCheckedAll: {
            onChange: onCheckedAll
        },
        bindCheckedAllBatch: {
            onChange: onCheckedAllBatch
        },
        bindImageChange: {
            onChange: onImagesChanges
        },
        bindSelectDate: {
            onChange: onGetDate
        }
    };
}