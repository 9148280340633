export const requestVerificationReset = () => {
    return dispatch => {
        dispatch({ type: "SETUP_RESET" })
    }
}
export const requestVercationSetup = (email, history, flex) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_SETUP",
            payload: {
                email
            },
            history,
            flex
        })
        dispatch(requestVerificationReset())
    }
}
export const requestToSendOtp = ({ otp, history }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_OTP",
            payload: {
                code: otp
            },
            history
        })
    }
}
export const requestResetpassword = ({ nik, newPassword, history }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_SETUP_PASSWORD",
            payload: {
                "nik": nik,
                "secret": newPassword,
            },
            history
        })
    }
}