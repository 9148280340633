import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'


export const RequestDashboard = action$ =>
    action$.pipe(
        ofType( 'REQUEST_SUMMARY_ORDER',
                'REQUEST_SUMMARY_ORDER_INTERVAL',
                'REQUEST_ABNORMAL_PICKUP',
                'REQUEST_ABNORMAL_PICKUP_INTERVAL',
                'REQUEST_ABNORMAL_IN_DELIVERY',
                'REQUEST_ABNORMAL_IN_DELIVERY_INTERVAL',
                'REQUEST_SLA_DELIVERY_REVIEW',
                'REQUEST_CANCELED_DELIVERY',
                'REQUEST_SLA_DELIVERY_REVIEW_INTERVAL',
                'REQUEST_CANCELED_DELIVERY_INTERVAL',
                'REQUEST_NOT_YET_CANCELED',
                'REQUEST_NOT_YET_CANCELED_INTERVAL',
                'REQUEST_HANGING_AWB',
                'REQUEST_HANGING_AWB_INTERVAL'
                ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_SUMMARY_ORDER':
                        const rawResponse = await Services().get('/order/api/back-office/dashboard/order-summary',action.payload.params)
                        let dataFormat = rawResponse.data.data.orderStatusProjectionSet.reduce((data,arr)=>{
                            data[arr.orderStatus] = arr.orderStatusCount
                            return data
                        },{})
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ORDER_SUMMARY',
                                payload: {
                                    data: dataFormat,
                                    dateTime:rawResponse.data.data.dateTime
                                    
                                }
                            })
                        }
                    case 'REQUEST_SUMMARY_ORDER_INTERVAL':
                        const rawResponseInterval = await Services().get('/order/api/back-office/dashboard/order-summary',action.payload.params)
                        let dataFormatInt = rawResponseInterval.data.data.orderStatusProjectionSet.reduce((data,arr)=>{
                            data[arr.orderStatus] = arr.orderStatusCount
                            return data
                        },{})
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ORDER_SUMMARY',
                                payload: {
                                    data: dataFormatInt,
                                    dateTime:rawResponseInterval.data.data.dateTime
                                    
                                }
                            })
                        }
                    case 'REQUEST_ABNORMAL_PICKUP':
                        const rawResponseAbnormalPickup = await Services().get('/order/api/back-office/dashboard/abnormal/pick-up',action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ABNORMAL_PICKUP',
                                payload: {
                                    data: rawResponseAbnormalPickup.data.data.abnormalOrderList,
                                    totalOrder: rawResponseAbnormalPickup.data.data.totalOrder,
                                    timeStamp: rawResponseAbnormalPickup.data.timestamp
                                }
                            })
                        }
                    case 'REQUEST_ABNORMAL_PICKUP_INTERVAL':
                        const rawResponseAbnormalPickupInt = await Services().get('/order/api/back-office/dashboard/abnormal/pick-up',action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ABNORMAL_PICKUP',
                                payload: {
                                    data: rawResponseAbnormalPickupInt.data.data.abnormalOrderList,
                                    totalOrder: rawResponseAbnormalPickupInt.data.data.totalOrder,
                                    timeStamp: rawResponseAbnormalPickupInt.data.timestamp
                                }
                            })
                        }
                    case 'REQUEST_ABNORMAL_IN_DELIVERY':
                        const rawResponseAbnormalInDelivery = await Services().get('/order/api/back-office/dashboard/abnormal/in-delivery',action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ABNORMAL_IN_DELIVERY',
                                payload: {
                                    data: rawResponseAbnormalInDelivery.data.data.abnormalOrderList,
                                    totalOrder: rawResponseAbnormalInDelivery.data.data.totalOrder,
                                    timeStamp: rawResponseAbnormalInDelivery.data.timestamp
                                    
                                }
                            })
                        }
                    case 'REQUEST_ABNORMAL_IN_DELIVERY_INTERVAL':
                        const rawResponseAbnormalInDeliveryInt = await Services().get('/order/api/back-office/dashboard/abnormal/in-delivery',action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_ABNORMAL_IN_DELIVERY',
                                payload: {
                                    data: rawResponseAbnormalInDeliveryInt.data.data.abnormalOrderList,
                                    totalOrder: rawResponseAbnormalInDeliveryInt.data.data.totalOrder,
                                    timeStamp: rawResponseAbnormalInDeliveryInt.data.timestamp
                                    
                                }
                            })
                        }
                    case 'REQUEST_SLA_DELIVERY_REVIEW':
                        const rawResponseSLADeliveryReview = await Services().get('/order/api/back-office/dashboard/sla-review',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_SLA_DELIVERY_REVIEW',
                                payload: {
                                    data: rawResponseSLADeliveryReview?.data.data,
                                    
                                }
                            })
                        }
                    case 'REQUEST_SLA_DELIVERY_REVIEW_INTERVAL':
                        const rawResponseSLADeliveryReviewInt = await Services().get('/order/api/back-office/dashboard/sla-review',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_SLA_DELIVERY_REVIEW',
                                payload: {
                                    data: rawResponseSLADeliveryReviewInt?.data.data,
                                    
                                }
                            })
                        }
                    case 'REQUEST_CANCELED_DELIVERY': 
                        const rawResponseCanceledDelivery = await Services().get('/order/api/back-office/dashboard/canceled-order-summary',action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_CANCELED_DELIVERY',
                                payload: {
                                    data: rawResponseCanceledDelivery?.data.data.content,
                                    
                                }
                            })
                        }
                    case 'REQUEST_CANCELED_DELIVERY_INTERVAL': 
                        const rawResponseCanceledDeliveryInt = await Services().get('/order/api/back-office/dashboard/canceled-order-summary',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_CANCELED_DELIVERY',
                                payload: {
                                    data: rawResponseCanceledDeliveryInt?.data.data.content,
                                    
                                }
                            })
                        }
                    case 'REQUEST_NOT_YET_CANCELED': 
                        const rawResponseNotYetCanceled = await Services().get('/order/api/back-office/dashboard/abnormal/canceling',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_NOT_YET_CANCELED',
                                payload: {
                                    data: rawResponseNotYetCanceled.data.data.abnormalOrderList,
                                    totalOrder: rawResponseNotYetCanceled.data.data.totalOrder,
                                    
                                }
                            })
                        } 
                    case 'REQUEST_NOT_YET_CANCELED_INTERVAL': 
                        const rawResponseNotYetCanceledIntv = await Services().get('/order/api/back-office/dashboard/abnormal/canceling',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_NOT_YET_CANCELED',
                                payload: {
                                    data: rawResponseNotYetCanceledIntv.data.data.abnormalOrderList,
                                    totalOrder: rawResponseNotYetCanceledIntv.data.data.totalOrder,
                                    
                                }
                            })
                        }
                    case 'REQUEST_HANGING_AWB': 
                        const rawResponseHangingAwb = await Services().get('/order/api/back-office/dashboard/abnormal/canceled',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_HANGING_AWB',
                                payload: {
                                    data: rawResponseHangingAwb.data.data.abnormalOrderList,
                                    totalOrder: rawResponseHangingAwb.data.data.totalOrder,
                                    
                                }
                            })
                        } 
                    case 'REQUEST_HANGING_AWB_INTERVAL': 
                        const rawResponseHangingAwbIntv = await Services().get('/order/api/back-office/dashboard/abnormal/canceled',action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_DATA_HANGING_AWB',
                                payload: {
                                    data: rawResponseHangingAwbIntv.data.data.abnormalOrderList,
                                    totalOrder: rawResponseHangingAwbIntv.data.data.totalOrder,
                                    
                                }
                            })
                        }     
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "DATA_DASHBOARD_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
