import React, { Fragment, useState, useEffect } from 'react'
import {
    Segment,
    Text
} from "@elevenia/master-ui/components/Atom"
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import Breadcrumb from 'component/Breadcrumb'
import TabPesanan from './_component/TabPesanan'
import TabPenjemputan from './_component/TabPenjemputan'
import TabPengiriman from './_component/TabPengiriman'
import TabSelesai from './_component/TabSelesai'
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestOrderList, requestFilterCluster, requestSummaryTotalOrderTab, requestFilterChannel } from 'store/actions/orderList'
import { Subject } from 'rxjs'
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import Error404 from 'page/errorLayout'
import moment from 'moment'
import { getLabelTimeZone } from 'helper'
import TabSiapDikirim from './_component/TabSiapDikirim'
import { getInitialParams, listOptionsKategori } from 'helper/Order'
let initlistStatusOrderCount = 'orderStatusList=ALLOCATING&orderStatusList=PICKING_UP&orderStatusList=IN_DELIVERY&orderStatusList=READY_TO_DELIVER'

const Pesanan = props => {
    document.title = props.title
    const history = props.history
    let urlParams = new URLSearchParams(history.location.search)
    const paramsQueryactiveTab = urlParams.get("activeTab")
    const paramsQueryDate = urlParams.get("date")
    const paramsQueryChannelOrder = urlParams.get("channelOrder")??""
    const paramsQueryOrderTypeGroup = urlParams.get("orderTypeGroup")??""
    const dataBreadcrumb = [{ to: '', label: 'Pesanan Berlangsung' }]
    const [activeTab, setActiveTab] = useState(paramsQueryactiveTab ?? "ALLOCATING");
    const [historyDate, setHistoryDate] = useState(paramsQueryDate)
    const { hasFetch } = useAction();
    const [status, hasStatus] = useState("hasLoad")
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    const [categorySearch, setCategorySearch] = useState(1)
    // let initialIsTable = {
    //     page: 0,
    //     size: 30,
    //     searchParam: "",
    //     orderStatus:activeTab,
    //     channelOrder: paramsQueryChannelOrder,
    //     orderTypeGroup: paramsQueryOrderTypeGroup
    // }

    let initialIsTable = getInitialParams(activeTab, paramsQueryChannelOrder, paramsQueryOrderTypeGroup)
    let listStatusOrderCount = ''
    if(paramsQueryDate){
        let keyDateStartDate = 'startDate'
        let keyDateEndDate = 'endDate'
        if(activeTab==='COMPLETED'){
            keyDateStartDate = 'startDateHistory'
            keyDateEndDate = 'endDateHistory'
            initialIsTable = {
                ...initialIsTable, 
                [keyDateStartDate]: activeTab === 'COMPLETED' ?  moment(paramsQueryDate,'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00.000') : '1970-01-01T00:00:00.000',
                [keyDateEndDate]:paramsQueryDate
            }
        }
        
        listStatusOrderCount = `${initlistStatusOrderCount}&endDate=${paramsQueryDate}`
    }else{
        listStatusOrderCount = initlistStatusOrderCount
        if(activeTab==="COMPLETED"){
            initialIsTable = {
                ...initialIsTable,
                startDateHistory: moment().format('YYYY-MM-DDT00:00:00.000'),
                endDateHistory: moment().format('YYYY-MM-DDT23:59:59.999')
            }
        }
    }
    if(paramsQueryChannelOrder){
        listStatusOrderCount = `${initlistStatusOrderCount}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
    }
    
    const [isTable, setTable] = useState(initialIsTable);
    
    const payload = useSelector(state => {
        return {
            data: state.orderList.data,
            loading: state.orderList.loading,
            totalPages: state.orderList.totalPages,
            parameter: state.orderList.parameter,
            statName: state.setAlertsToast.notify.statName,
            filterCluster : state.orderList.filterCluster,
            loadingCluster : state.orderList.loadingCluster,
            timeStamp:state.orderList.timeStamp,
            dataTotalOrder:state.orderList.dataSummayOrder,
            loadingSummary: state.orderList.loadingSummary,
            orderConfig: state.homesidemenu.orderConfig,
            loadingChannel: state.orderList.loadingChannel,
            filterChannel: state.orderList.filterChannel,
        }
    })
    
    useEffect(()=>{
        props.access?.read && hasFetch(requestSummaryTotalOrderTab(listStatusOrderCount))
    },[hasFetch,props.access,listStatusOrderCount])
    
    useEffect(()=>{
        
      status=== "hasLoad" && props.access?.read && hasFetch(requestOrderList(isTable))
      status=== "hasPagination" && props.access?.read && hasFetch(requestOrderList(isTable))
    },[hasFetch,isTable,status, props.access])

    useEffect(()=>{
        if(props.access?.read)  {
            hasFetch(requestFilterCluster())  
            hasFetch(requestFilterChannel())
        }
    },[hasFetch,props.access])

    useEffect(()=>{
        
        //const tabActive = paramsQueryactiveTab ?? 'ALLOCATING'
        if(paramsQueryDate){
            history.replace({
                search: `?activeTab=${activeTab}&date=${paramsQueryDate}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }else{
            history.replace({
                search: `?activeTab=${activeTab}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }
    },[history,activeTab,paramsQueryDate,paramsQueryChannelOrder,paramsQueryOrderTypeGroup])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestOrderList(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            searchParam: value.trim(),
            searchParamType: categorySearch
        });
    }

    //const [intervalId$,setIntervalId] = useState()
    // useEffect(()=>{
    //     //let intervalId = null
    //     if(props.access?.read){
    //         //intervalId = setInterval(()=>{
    //             hasFetch(requestOrderListInterval())
    //             hasFetch(requestSummaryTotalOrderTab(listStatusOrderCount))
    //         //},60000)
    //         //setIntervalId(intervalId)
    //     }
        
    //     return ()=>{
    //         //clearInterval(intervalId)
    //     }
    // },[hasFetch,activeTab,props.access,listStatusOrderCount])

    const handleOnChangeTab = (tab) => {
        setActiveTab(tab)
        let tempIsTable = initialIsTable
        if(initialIsTable.hasOwnProperty('startDateHistory') && tab !== 'COMPLETED'){
            delete tempIsTable.startDateHistory
            delete tempIsTable.endDateHistory
        }
        
        if(!['RETURN_COMPLETED','CANCELED','FALLBACK','COMPLETED'].includes(tab)){
            
            delete tempIsTable.startDate
            delete tempIsTable.endDate
        }else{
            tempIsTable = {
                ...tempIsTable,
                startDateHistory: moment().format('YYYY-MM-DDT00:00:00.000'),
                endDateHistory: moment().format('YYYY-MM-DDT23:59:59.999')
            }
        }
        
        if(paramsQueryDate){
            history.replace({
                search: `?activeTab=${tab}&channelOrder=${paramsQueryChannelOrder}&orderTypeGroup=${paramsQueryOrderTypeGroup}`
            })
        }
        
        setHistoryDate(null)
        setTable({
            ...tempIsTable,
            orderStatus:tab
        })
        hasFetch(requestSummaryTotalOrderTab(initlistStatusOrderCount))
        setCategorySearch(1)
        //clearInterval(intervalId$)
    }

    
    
    
    const handlePagination = (page) => {
        const query = {
            ...payload.parameter,
            ...page
        }
        hasStatus('hasPagination')
        setTable(query)
    }

    

    const listOptionsServ = [
        { value: "-", label: "-" },
        { value: "isBulky", label: "Bulky" },
        { value: "isCod", label: "COD" },
        { value: "isBulkyisCod", label: "Bulky & COD" },
        { value: "isBulkyIsReturn", label: "Bulky & Retur" },
        { value: "all", label: "COD, Bulky & Retur" }
    ];
    
    const tabs = [{
        key: "ALLOCATING",
        showTotal:true,
        label: "Pesanan Baru",
        component: <TabPesanan 
                    
                    data={payload?.data} 
                    loading={payload?.loading} 
                    parameter={payload?.parameter}
                    isTable={isTable}
                    setTable={setTable}
                    totalPages={payload?.totalPages}
                    handleSearch={handleSearch}
                    handlePagination={handlePagination}
                    listOptionsKategori={listOptionsKategori}
                    filterCluster={payload?.filterCluster}
                    loadingCluster={payload?.loadingCluster}
                    currentTime={payload.timeStamp}
                    defaultDate={historyDate ? new Date(historyDate) : null}
                    timezoneLabel={timezoneLabel}
                    orderConfig={payload?.orderConfig}
                    filterChannel={payload?.filterChannel}
                    loadingChannel={payload?.loadingChannel}
                    listOptionsServ={listOptionsServ}
                    setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                    {...props} />
    }, {
        key: "PICKING_UP",
        showTotal:true,
        label: "Penjemputan",
        component: <TabPenjemputan 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        listOptionsServ={listOptionsServ}
                        currentTime={payload.timeStamp}
                        timezoneLabel={timezoneLabel}
                        orderConfig={payload?.orderConfig}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        setCategorySearch={setCategorySearch}
                        categorySearch={categorySearch}
                        {...props} 
                    />
    }, {
        key: "READY_TO_DELIVER",
        label: "Siap Dikirim",
        showTotal:true,
        component: <TabSiapDikirim 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        listOptionsServ={listOptionsServ}
                        currentTime={payload.timeStamp}
                        timezoneLabel={timezoneLabel}
                        orderConfig={payload?.orderConfig}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                        {...props} 
                    />
    },{
        key: "IN_DELIVERY",
        showTotal:true,
        label: "Pengiriman",
        component: <TabPengiriman 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        currentTime={payload.timeStamp}
                        listOptionsServ={listOptionsServ}
                        timezoneLabel={timezoneLabel}
                        orderConfig={payload?.orderConfig}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                        {...props} 
                    />
    }, {
        key: "COMPLETED",
        label: "Selesai",
        component: <TabSelesai 
                        data={payload?.data} 
                        loading={payload?.loading} 
                        parameter={payload?.parameter}
                        isTable={isTable}
                        setTable={setTable}
                        totalPages={payload?.totalPages}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        listOptionsKategori={listOptionsKategori}
                        filterCluster={payload?.filterCluster}
                        loadingCluster={payload?.loadingCluster}
                        defaultDate={paramsQueryDate ? new Date(paramsQueryDate) : null}
                        timezoneLabel={timezoneLabel}
                        filterChannel={payload?.filterChannel}
                        loadingChannel={payload?.loadingChannel}
                        listOptionsServ={listOptionsServ}
                        setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                        {...props} 
                    />
    }
    
]
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Pesanan Berlangsung</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active =>
                        
                        active !== activeTab ? handleOnChangeTab(active) : null
                    }>
                        {tabs.map((item) => <Text key={item.key}>{item.label} {(item.showTotal && !payload.loadingSummary) ? `(${payload.dataTotalOrder[item.key] ?? 0})` : ''} </Text>)}
                    </Tabs>
                    <Segment mt={32}>
                        {tabs.map((item, idx) => activeTab === item.key && <Fragment key={idx}>{item.component}</Fragment>)}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default Pesanan