
const initialState = {
    data: "",
    totalPages:0,
    parameter:null,
    loading: false,
    loadingUpload: false,
    dataUpload: null,
    loadingRead: false,
    listDataRead: "",
    totalPagesDataRead:0,
    parameterDataRead:null,
    totalDataRead:0,
    loadingError: false,
    listDataError: "",
    totalPagesDataError:0,
    parameterDataError:null,
    totalDataError:0,
    isUploadError: false
  }
  
  export const configRKB = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_LIST_CONFIG_RKB':
            return {
                ...state,
                loading: true,
                
            }
        case 'GET_LIST_CONFIG_RKB':
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,

            }
        case 'REQUEST_LIST_HISTORY_CONFIG_RKB':
            return {
                ...state,
                loading: true,
                
            }
        case 'GET_LIST_HISTORY_CONFIG_RKB':
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,

            }            
        case 'REQUEST_UPLOAD_FILE':
            
            return {
                ...state,
                loadingUpload:true,
            }
        case 'GET_DATA_UPLOAD_FILE':
      
          return {
              ...state,
              dataUpload: action.payload.data,
              loadingUpload: false,
              isUploadError: false
          }
        case 'REQUEST_UPLOAD_DATA_READ':
            
            return {
                ...state,
                loadingRead: true
            }
        case 'GET_UPLOAD_DATA_READ':
      
          return {
              ...state,
              listDataRead: action.payload.data,
              totalPagesDataRead:action.payload.totalPages,
              parameterDataRead:action.payload.parameter,
              totalDataRead: action.payload.totalData,
              loadingRead: false
          }
        case 'REQUEST_UPLOAD_DATA_ERROR':
            
            return {
                ...state,
                loadingError: true
            }
        case 'GET_UPLOAD_DATA_ERROR':
      
          return {
              ...state,
              listDataError: action.payload.data,
              totalPagesDataError:action.payload.totalPages,
              parameterDataError:action.payload.parameter,
              totalDataError: action.payload.totalData,
              loadingError: false
          }
        case 'REQUEST_SUBMIT_JADWAL':
            return {
                ...state,
                loadingSubmit: true
            }
        case 'SUCCESS_SUBMIT_JADWAL':
            return {
                ...state,
                loadingSubmit: false
            }
        case 'ERROR_UPLOAD_FILE':
            return{
                ...state,
                isUploadError:true,
                loadingSubmit: false
            }
        
        default:
            return state
    }
  }