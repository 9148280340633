import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestReleaseLocking = (action$, state$) =>
    action$.pipe(
        ofType( 'REQUEST_ORDER_LIST_BY_ID_DELIMAN',
                'REQUEST_SUBMIT_RELEASE_LOCKING',
                'REQUEST_RELEASE_LOCKING',
                'REQUEST_RELEASE_LOCKING_INTERVAL',
                'REQUEST_RELEASE_LOCKING_HISTORY',
                'REQUEST_APPROVAL_RELEASE_LOCKING',
                'REQUEST_DURATION_RELEASE_LOCKING',
                'REQUEST_DISMISS_ERROR_RELEASE_LOCKING',
                'REQUEST_RELEASE_LOCKING_APPROVAL',
                'REQUEST_RELEASE_LOCKING_APPROVAL_INTERVAL',
                'REQUEST_RELEASE_LOCKING_APPROVAL_HISTORY',
                'REQUEST_SUBMIT_RELEASE_LOCKING_APPROVAL'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_RELEASE_LOCKING':
                        const rawResponseReleaseLocking = await Services().get(`/order/api/back-office/release-locking/`, action.payload.parameter)

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING',
                                payload: {
                                    data: rawResponseReleaseLocking.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseLocking.data.data.totalPages,

                                }
                            })
                        }
                    case 'REQUEST_RELEASE_LOCKING_INTERVAL':
                        const rawResponseReleaseLockingInt = await Services().get(`/order/api/back-office/release-locking/`, state$.value.releaseLocking.parameter)

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING',
                                payload: {
                                    data: rawResponseReleaseLockingInt.data.data.content,
                                    parameter: state$.value.releaseLocking.parameter,
                                    totalPages: rawResponseReleaseLockingInt.data.data.totalPages,

                                }
                            })
                        }
                    case 'REQUEST_RELEASE_LOCKING_HISTORY':
                        const rawResponseReleaseLockingHis = await Services().get(`/order/api/back-office/release-locking/history`, action.payload.parameter)

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING_HISTORY',
                                payload: {
                                    data: rawResponseReleaseLockingHis.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseLockingHis.data.data.totalPages,

                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN':
                        const rawResponse = await Services().get(`/order/api/back-office/release-locking/information/${action.payload.data?.deliverymanId}`,{
                            type: action.payload.data?.type
                        })
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST_BY_ID_DELIMAN',
                                payload: {
                                    orderList: rawResponse.data.data,

                                }
                            })
                        }
                    case 'REQUEST_SUBMIT_RELEASE_LOCKING':
                        await Services().post(`/orderprocessor/api/back-office/release-locking/add/`, action.payload.data)
                        return dispatch => {
                            action.history.push(`/release-lock`)
                            dispatch({ type: 'RELEASE_LOCKING_SUCCESS' })
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Release Lock berhasil dibuat' } })
                        }
                    case 'REQUEST_APPROVAL_RELEASE_LOCKING':
                        await Services().post(`/orderprocessor/api/back-office/release-locking/request/`, action.payload.data)
                        return dispatch => {
                            action.history.push(`/release-lock`)
                            dispatch({ type: 'RELEASE_LOCKING_SUCCESS' })
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Release Lock berhasil dibuat' } })
                        }
                    case 'REQUEST_DURATION_RELEASE_LOCKING':
                        const rawDurationReleaseLocking = await Services().get('/orderprocessor/api/back-office/release-locking/config')
                        return dispatch => {
                            dispatch({
                                type: 'GET_DURATION_RELEASE_LOCKING',
                                payload: {
                                    data: rawDurationReleaseLocking.data.data.content
                                }
                            })
                        }
                    case 'REQUEST_RELEASE_LOCKING_APPROVAL':
                        const rawResponseReleaseLockingApproval = await Services().get(`/order/api/back-office/release-locking/request`, action.payload.parameter);

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING',
                                payload: {
                                    data: rawResponseReleaseLockingApproval.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseLockingApproval.data.data.totalPages,
                                }
                            });
                        }
                    case 'REQUEST_RELEASE_LOCKING_APPROVAL_INTERVAL':
                        const rawResponseReleaseLockingApprovalInt = await Services().get(`/order/api/back-office/release-locking/request`, state$.value.releaseLocking.parameter);

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING',
                                payload: {
                                    data: rawResponseReleaseLockingApprovalInt.data.data.content,
                                    parameter: state$.value.releaseLocking.parameter,
                                    totalPages: rawResponseReleaseLockingApprovalInt.data.data.totalPages
                                }
                            });
                        }
                    case 'REQUEST_RELEASE_LOCKING_APPROVAL_HISTORY':
                        const rawResponseReleaseLockingApprovalHis = await Services().get(`/order/api/back-office/release-locking/request/history`, action.payload.parameter);

                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_LOCKING_HISTORY',
                                payload: {
                                    data: rawResponseReleaseLockingApprovalHis.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseLockingApprovalHis.data.data.totalPages,

                                }
                            });
                        }
                    case 'REQUEST_SUBMIT_RELEASE_LOCKING_APPROVAL':
                        const approvalType = action.payload.setujui ? 'approve' : 'reject';
                        const toastMessage = action.payload.setujui ? 'Release Lock Disetujui' : 'Release Lock Ditolak';
                        const toastType = action.payload.setujui ? 'ALERT_TOAST_SUCCESS' : 'ALERT_TOAST_WARNING';

                        await Services().post(`/orderprocessor/api/back-office/release-locking/request/${approvalType}/${action.payload.requestId}`)

                        return dispatch => {
                            action.payload.refetchList();
                            dispatch({ type: 'RELEASE_LOCKING_SUCCESS' });
                            dispatch({ type: toastType, payload: { message: toastMessage } });
                        }
                    case 'REQUEST_DISMISS_ERROR_RELEASE_LOCKING':
                        return dispatch => {
                            dispatch({
                                type: 'RELEASE_LOCKING_DISMISS_ERROR'
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "RELEASE_LOCKING_FAILED", payload: { error : e } })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
