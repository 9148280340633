import React, { useState, useEffect, useMemo } from "react";
import {
    Button, OptionBox, Segment,
    Text
} from "@elevenia/master-ui/components/Atom";
import Breadcrumb from 'component/Breadcrumb';
import DataTable from "component/DataTable";
import useFetchSWR from 'hooks/useFetchSWR'
import moment from "moment";
import { useAction } from "hooks";
import { generateOptionDate } from "helper";
import DownloadExcel from 'helper/DownloadExcel'
import { Services } from "service";
import { saveAs } from "file-saver";
const IconXls = require("assets/image/icon-xls.svg");

const dataBreadcrumb = [
    { to: '', label: 'Insentif Delivery Man IDM' }
]
const getListMonth = (numberOfCurrentMonth) => {
    const countListMonth = 12 + numberOfCurrentMonth
    const listOptions = generateOptionDate(moment().subtract(countListMonth, 'M'), countListMonth, 'YYYY-MM-DD').map(val => ({ value: val, label: moment(val, 'YYYY-MM-DD').format('MMMM YYYY') })).filter(val => {
        const [year] = val.value.split('-')
        return parseInt(year) > 2022
    })
    return listOptions
}
const Report = (props) => {
    document.title = props.title
    const { hasFetch } = useAction()
    const instanceCurrentMonth = moment().startOf('month')
    const numberOfCurrentMonth = parseInt(instanceCurrentMonth.format("M"))
    const listOptionsMonth = useMemo(() => {
        return getListMonth(numberOfCurrentMonth)
    }, [numberOfCurrentMonth])

    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        sort: "scoreTotal,desc",
        filteredDate: instanceCurrentMonth.format('YYYY-MM-DD'),
        unpaged: false
    });

    const optionsSwr = {
        revalidateOnFocus: true,
        shouldRetryOnError: false,
        revalidateOnMount: true
    }
    const { data: dataInsentif, loading, error } = useFetchSWR({
        key: (isTable && props.access?.read) && [`/driverperformance/api/back-office/driver-insentif`, isTable],
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data.driverInsentifPage.content,
            totalPages: data?.data.driverInsentifPage.totalPages,
            totalElements: data?.data.driverInsentifPage.totalElements,
            configuration: data?.data.configuration
        })
    })

    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message } })
        }
    }, [hasFetch, error])

    const [statusDownload, setStatusDownload] = useState('idle')
    const handleDownloadExcel = async () => {
        
        setStatusDownload('pending')
        Services().get('/driverperformance/api/back-office/driver-insentif',{...isTable,unpaged:true})
        .then( async (data)=>{
            setStatusDownload('resolved')
            const contentRow = data?.data?.data.driverInsentifPage.content?.map((value,key)=>{
                return {
                    ...value,
                    no: key + 1,
                    avgOrderPerShift: value.avgOrderPerShift.toFixed(2),
                    totalOrderCompletedWithinSlaPct: value.totalOrderCompletedWithinSlaPct.toFixed(1),
                    avgOrderPerShiftLastMonth: value.avgOrderPerShiftLastMonth.toFixed(2),
                    scoreOrderCompletedGrowth: value.scoreOrderCompletedGrowth.toFixed(2),
                    delimanNameNik: `${value.deliveryManNik??'-'} - ${value.deliveryManName??'-'}`,
                    storeHomeBase: (value.storeCode && value.storeName) ? `${value.storeCode} - ${value.storeName}` : '-'
                }
            });

            const columns = [
                { header: "No", key: "no", width: 5 },
                { header: "Delivery Man", key: "delimanNameNik", width: 12.5 },
                { header: "Toko Homebase", key: "storeHomeBase", width: 12.5 },
                { header: "Total Hari Login", key: "totalDayLogin", width: 12.5 },
                { header: "Total Order", key: "totalOrderCompleted", width: 12.5 },
                { header: "Total Order SLA", key: "totalOrderCompletedWithinSla", width: 12.5 },
                { header: "Total Shift", key: "totalShift", width: 12.5 },
                { header: "Rata-Rata Order per Shift (Bulan Observasi)", key: "avgOrderPerShift", width: 30 },
                { header: "% Complete SLA", key: "totalOrderCompletedWithinSlaPct", width: 15 },
                { header: "Rata-Rata Order per Shift (Bulan Sebelumnya)", key: "avgOrderPerShiftLastMonth", width: 20 },
                { header: "Growth Order per Shift", key: "scoreOrderCompletedGrowth", width: 12.5 },
                { header: "Skor Presensi", key: "scoreLogin", width: 12.5 },
                { header: "Skor Antaran", key: "scoreOrderCompleted", width: 12.5 },
                { header: "Skor Growth", key: "scoreOrderCompletedGrowth", width: 12.5 },
                { header: "Skor SLA", key: "scoreOrderCompletedWithinSla", width: 12.5 },
                { header: "Total Skor", key: "scoreTotal", width: 12.5 }

            ]
            const creator       = 'Ipp Application'
            const createdDate   = creator
            const modifiedDate  = new Date()
            const sheetName     = `Scoring Delivery Man ${moment(isTable.filteredDate, 'YYYY-MM-DD').format("MMMM YYYY")}`
            const fileName      = `Insentif Delivery Man ${moment(isTable.filteredDate, 'YYYY-MM-DD').format("MMMM YYYY")}`;
            const buffer        = await DownloadExcel({creator, createdDate, modifiedDate, sheetName, columns, contentRow});
            
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, fileName + fileExtension);
            
        })
        .catch(e=>{
            setStatusDownload('rejected')
        })
        
         
    }
    
    return <>
        <Segment justifyContent={"space-between"} className="xs-flex-wrap">
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Insentif Delivery Man IDM</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Button 
                    disabled={statusDownload==="pending" ? true : false} 
                    onClick={()=>handleDownloadExcel()} 
                    variant="secondary"
                >
                    <img src={IconXls} alt="xls" style={{ marginRight: 8 }} />
                    Export XLS
                </Button>
            </Segment>
        </Segment>
        <Segment boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={8} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
            <Segment flex={3}>
                <Segment mb={12} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={280}>
                        <Text color={'#70727D'}>Tanggal Exclude</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {dataInsentif?.configuration?.excludedDay?.length > 0 ? dataInsentif?.configuration.excludedDay.join(', ') : '-'}
                    </Segment>
                </Segment>
                <Segment display="flex" className="xs-flex-wrap">
                    <Segment minWidth={280}>
                        <Text color={'#70727D'}>Delivery Man Exclude</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {
                            dataInsentif?.configuration?.excludedDeliveryMan?.length > 0 ? dataInsentif?.configuration?.excludedDeliveryMan?.map(({ nik, name }) => `${nik} - ${name}`).join(', ') : '-'
                        }

                    </Segment>
                </Segment>
            </Segment>
            <Segment flex={'0 1 180px'}>
                <OptionBox
                    defaultValue={""}
                    options={listOptionsMonth}
                    placeholder={"Pilih Bulan"}

                    value={listOptionsMonth.find(val => val.value === isTable.filteredDate)}
                    onChange={(e) => {
                        setTable(prev => ({
                            ...prev,
                            filteredDate: e.value
                        }))

                    }}

                />
            </Segment>
        </Segment>
        
        <DataTable
            defaultSortActive={isTable.sort}
            countingList={true}
            isLoading={loading}
            defaultSize={isTable.size}
            totalPages={dataInsentif?.totalPages}
            tableConsume={[
                {
                    field: "Delivery Man",
                    isCustomRow: (id, { deliveryManNik, deliveryManName }) => {
                        return `${deliveryManNik ?? '-'} - ${deliveryManName ?? '-'}`
                    }
                },
                {
                    field: "Toko Homebase",
                    sortField: "storeCode",
                    isCustomRow: (id, { storeCode, storeName }) => {
                        return (storeCode && storeName) ? `${storeCode} - ${storeName}` : '-'
                    },
                },
                {
                    field: "Total Hari Login",
                    rowField: "totalDayLogin",
                    sortField: "totalDayLogin",
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Total Order",
                    rowField: "totalOrderCompleted",
                    sortField: "totalOrderCompleted",
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Total Order Sesuai SLA",
                    rowField: "totalOrderCompletedWithinSla",
                    sortField: "totalOrderCompletedWithinSla",
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Total Shift",
                    rowField: "totalShift",
                    sortField: "totalShift",
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Rata-rata Order per Shift (BO)",
                    rowField: "avgOrderPerShift",
                    sortField: "avgOrderPerShift",
                    isCustomRow: (id, { avgOrderPerShift }) => avgOrderPerShift.toFixed(2), 
                    headerStyle: {
                        minWidth: 200
                    },
                },
                {
                    field: "(%) Complete SLA",
                    rowField: "totalOrderCompletedWithinSlaPct",
                    sortField: "totalOrderCompletedWithinSlaPct",
                    isCustomRow: (id, { totalOrderCompletedWithinSlaPct }) => `${totalOrderCompletedWithinSlaPct.toFixed(1)} %`,
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Rata-rata Order per Shift (BS)",
                    width: 500,
                    rowField: "avgOrderPerShiftLastMonth",
                    sortField: "avgOrderPerShiftLastMonth",
                    isCustomRow: (id, { avgOrderPerShiftLastMonth }) => avgOrderPerShiftLastMonth.toFixed(2),
                    headerStyle: {
                        minWidth: 200
                    },
                },
                {
                    field: "(%) Growth Order/Shift",
                    rowField: "scoreOrderCompletedGrowth",
                    sortField: "scoreOrderCompletedGrowth",
                    isCustomRow: (id, { scoreOrderCompletedGrowth }) => scoreOrderCompletedGrowth.toFixed(2),
                    headerStyle: {
                        minWidth: 170
                    },
                },
                {
                    field: "Skor Presensi",
                    rowField: "scoreLogin",
                    sortField: "scoreLogin",
                },
                {
                    field: "Skor Antaran",
                    rowField: "scoreOrderCompleted",
                    sortField: "scoreOrderCompleted",
                },
                {
                    field: "Skor Growth",
                    rowField: "scoreOrderCompletedGrowth",
                    sortField: "scoreOrderCompletedGrowth",
                },
                {
                    field: "Skor SLA",
                    rowField: "scoreOrderCompletedWithinSla",
                    sortField: "scoreOrderCompletedWithinSla",
                },
                {
                    field: "Total Skor",
                    rowField: "scoreTotal",
                    sortField: "scoreTotal",
                },
            ]}
            dataConsume={dataInsentif?.data}
            showSize={true}
            showPagination={true}
            showSearch={false}
            activePage={isTable.page}
            callSorted={(name, direction) => setTable(prev => {
                return {
                    ...prev,
                    sort: `${name},${direction}`
                }
            })}
            createShowPerSize={(size) => setTable(prev => {
                return {
                    ...prev,
                    size: size.value
                }
            })}
            createPaginations={(page) => setTable(prev => {

                return {
                    ...prev,
                    page: page.page
                }
            })}
        />

        
    </>
}

export default Report;