
const initialState = {
  loading:false,
  status:false
}

export const changePassword = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_CHANGE_PASSWORD':
          return {
              ...state,
              loading: true
          }
      case 'CHANGE_PASSWORD_SUCCESS':
        return {
          ...state,
          loading:false,
          status:true
        }
      case 'REQUEST_CHANGE_PASSWORD_FAILED':
          return {
              ...state,
              loading: false
          }
      
      default:
          return state
  }
}