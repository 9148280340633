

import React,{useState,useEffect} from 'react'
import {getDuration, formatCountDown} from './helper'
import StatusColor from './StatusColor'
import { useAction } from 'hooks';
import { requestOrderListInterval } from 'store/actions/orderList'
import { Segment } from '@elevenia/master-ui/components/Atom'

const CountDown = ({
  type='GENERAL',
  startDuration,
  endDuration,
  serviceType='-',
  page='list-data'
}) => {
  
  const durations = getDuration(startDuration,endDuration)
  const [timer, setTimes] =  useState(durations)
  const { hasFetch } = useAction();
  useEffect(()=>{
    let isMounted = true
    setInterval(()=>{
      
      isMounted && setTimes(prev=>{
        
        return prev-1
          
      })
    },[1000])
    return ()=> {isMounted=false}
  },[])
  
  useEffect(()=>{
    if(page==='list-data'){
      if(type!=='IN_DELIVERY'){
      
        (serviceType==='-' && timer===0) && hasFetch(requestOrderListInterval())
      }
    }
    
  },[hasFetch,timer,type,serviceType,page])
  
  
  return (
    <Segment width={92}>
    {
      (type==='IN_DELIVERY' || type==='PICKING_UP') ?
        StatusColor({
          duration:timer,
          text:formatCountDown(timer)
        })
        : timer <= 900 ? StatusColor({
          duration:timer,
          text:formatCountDown(timer)
        }) : '-'
    }
    
    </Segment>
  )
}

export default CountDown;