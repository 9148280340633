import React, { useState } from 'react'
import {
    Segment,
    Text,
    Button,
    Icon,
} from "@elevenia/master-ui/components/Atom"
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import TabSedangAktif from './_component/TabSedangAktif'
import TabRiwayat from './_component/TabRiwayat'
import Error404 from 'page/errorLayout'
const dataBreadcrumb = [
    { to: '', label: 'Release Location Access' }
]

const ReleaseLock = props => {
    document.title = `${props.title}`
    const [activeTab, setActiveTab] = useState("SedangAktif");
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Release Location Access</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Link to={"/release-location/form"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="SedangAktif">Sedang Aktif</Text>
                        <Text key="Riwayat">Riwayat</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "SedangAktif" && <TabSedangAktif {...props}/> }
                        {activeTab === "Riwayat" && <TabRiwayat {...props}/>}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default ReleaseLock