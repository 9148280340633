import React from "react"
import DataTable from 'component/DataTable'
import useSWR from "swr";
import { fetcher } from "helper";
import { Link } from "react-router-dom";
const TabToko = (props) => {
    const optionsSwr = {
        revalidateOnFocus: false
    }

    const { data: dataStore, error, isValidating } = useSWR((props.idDeliman) && `/store/api/back-office/stores/driver/${props.idDeliman}`, fetcher, optionsSwr)

    const payload = {
        data: dataStore?.data.content,
        loading: !dataStore && !error,
    }

    return <>
        <DataTable
            countingList={true}
            defaultSize={payload.data?.length}
            isLoading={payload.loading || isValidating}
            tableConsume={[{
                field: 'Kode Toko',
                isCustomRow: (id, entity) => {
                    return (
                        <Link target="_blank" to={`/toko?searchParam=${entity.storeCode}`}>{entity.storeCode}</Link>
                    )
                }
            }, {
                field: 'Nama Toko',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.storeName}</>
                    )
                }
            }, {
                field: 'Alamat Toko',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.address}</>
                    )
                }
            }]}
            dataConsume={payload?.data}
        />
    </>
}

export default TabToko