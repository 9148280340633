import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    FormControl,
    Segment,
    Spinner,
    Textfield,
    Text
} from '@elevenia/master-ui/components/Atom';
import { validateForm } from 'helper';
import { useInput, useAction } from 'hooks';
import { useSelector } from 'react-redux';
import { requestVercationSetup } from 'store/actions/verifikasi-setup';

const Verifikasi = props => {
    document.title = props.title;
    const logo = require('assets/image/indopaket-logo.png')
    const { hasFetch } = useAction();
    const [messageError, setMessageError] = useState({
        message: "",
        isError: "normal"
    })
    const payload = useSelector(state => {
        return {
            notification: state.setAlerVerification.componentMessage,
            status: state.verificationSetup.email,
            loading: state.authentication.loading
        }
    });
    const [errors] = useState(false)
    const { bindChange, value } = useInput({
        initialObjects: {
            email: payload.status.code
        },
        identity: "myForm",
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const valid = validateForm(e.target.id, true, false);
        valid && hasFetch(requestVercationSetup(value.email, props.history, 'verif-pass'))
    }
    useEffect(() => {
        if (payload.status.error) {
            setMessageError({
                message: "Email belum didaftarkan, Mohon menghubungi admin.",
                isError: payload.status.error && "error"
            })
        }
    }, [payload.status.error, hasFetch])
    return (
        <>
            {payload.notification?.Modal}
            <Segment
                boxShadow
                position={'relative'}
                height={'100vh'}
                alignItems={'center'}
                justifyContent={'center'}
                background="#F2F4FC"
                className="font-nunito"
            >
                <Segment position="absolute" top={40} left={48}>
                    <img src={logo} alt={'Main Logo'} />
                </Segment>
                <Segment
                    boxShadow
                    width={522}
                    bg={'white'}
                    borderRadius={8}
                    px={80}
                    py={60}
                    mx={'auto'}
                >
                    <Segment flexDirection={'column'} alignItems={'center'} mb={48} mx={-24}>
                        <Text H28 fontWeight={'800'} textAlign={'center'} fontSize={28} mt={8}>Verifikasi Akun</Text>
                        <Text color={'#70727D'} mt={32} textAlign={'center'} fontSize={14}>
                            Masukkan alamat email Anda yang didaftarkan oleh admin.
                            <br />Kami akan mengirimkan kode melalui email
                            <br />untuk menyelesaikan proses verifikasi.
                        </Text>
                    </Segment>
                    <form id="myForm" onSubmit={handleSubmit}>
                        <FormControl mb={20} helptext={<Text color="red">{messageError.message}</Text>}>
                            <Textfield
                                inputProps={{
                                    ...bindChange,
                                    type: "text",
                                    name: 'email',
                                    className: `validate[required,email,emailNotExist[${errors}]]`,
                                    placeholder: 'Email',
                                    value: value.email
                                }}
                                state="normal"
                                model="default"
                                status={messageError.isError}
                            />
                        </FormControl>
                        <Segment mt={32}>
                            <ButtonGroup responsive>
                                <Button disabled={payload.loading || value.email.length === 0} type="submit" variant="primary">
                                    {payload.loading ? <Spinner color="#2a93d6" /> : "Kirim"}
                                </Button>
                            </ButtonGroup>
                        </Segment>
                    </form>
                </Segment>
            </Segment >
        </>
    )
}

export default Verifikasi