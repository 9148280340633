import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Icon,
    Textfield,
    OptionBox,
    ButtonGroup,
    Badge
} from "@elevenia/master-ui/components/Atom"
import { useAction } from 'hooks';
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import { requestUsers } from 'store/actions/users'
import { useSelector } from 'react-redux';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Error404 from 'page/errorLayout'
const dataBreadcrumb = [
    { to: '', label: 'Pengguna' }
]

const statusColor = status => {
    switch (status) {

        case 'inactive':
            return { bg: '#EE2B2E', color: 'white', border: '#EE2B2E', text: 'Non-Aktif' }
        case 'active':
            return { bg: '#8FC742', color: 'white', border: '#8FC742', text: 'Aktif' }

        default:
            return { bg: 'white', color: '#151823', border: '#DCDEE3', text: status }
    }
}


const Users = props => {
    const { hasFetch } = useAction();
    document.title = props.title
    const [isModal, setIsModal] = useState(false)
    const [status, hasStatus] = useState("hasLoad")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        sort: "fullname,asc",
        status:"active"
    });

    const payload = useSelector(state => {
        return {
            data: state.users.data,
            loading: state.users.loading,
            totalPages: state.users.totalPages,
            parameter: state.users.parameter,
            statName: state.setAlertsToast.notify.statName,
            roles: state.users.roles.map(val => ({ label: val.name, value: val.id })),
            statuses: state.users.statuses.map(val => ({ label: val === 'active' ? 'Aktif' : 'Tidak Aktif', value: val })),

        }
    })

    useEffect(() => {
        status === "hasLoad" && hasFetch(requestUsers(isTable))
        status === "hasPagination" && hasFetch(requestUsers(isTable))
    }, [hasFetch, isTable, status])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestUsers(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            page:0,
            keyword: value.trim()
        });
    }


    const listVerifikasi = [
        { value: "2", label: "Terverifikasi" },
        { value: "1", label: "Belum Verifikasi" },
    ];

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Pengguna</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    {/* <Button mr={16} variant="secondary">
                        <Icon name={"add-document"} size={22} fillColor={"#115488"} mr={8} />
                        Export XLS
                    </Button> */}
                    <Link to={"/user/create"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Nama'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={""}
                                options={listVerifikasi}
                                placeholder={"Verifikasi"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value === '1') value = false
                                    if (e && e.value === '2') value = true
                                    setTable(prev => ({
                                        ...prev,
                                        ...payload.parameter,
                                        verified: value
                                    }))

                                }}
                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={""}

                                options={payload.roles}
                                placeholder={"User Authority"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value) value = e.value

                                    setTable(prev => ({
                                        ...prev,
                                        ...payload.parameter,
                                        roleId: value
                                    }))

                                }}

                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                    {
                        !payload.loading && 
                        <FormControl>
                                <OptionBox
                                    defaultValue={payload.statuses.filter(val=>val.value===isTable.status)[0]}

                                    options={payload.statuses}
                                    placeholder={"Status"}
                                    isClearable
                                    onChange={(e) => {
                                        let value = ''
                                        if (e && e.value) value = e.value
                                        setTable(prev => ({
                                            ...prev,
                                            ...payload.parameter,
                                            status: value
                                        }))

                                    }}
                                    isDisabled={payload.loading}
                                />
                        </FormControl>
                    }
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    defaultSortActive={isTable.sort}
                    defaultSize={isTable.size}

                    tableConsume={[{
                        field: 'NIK',
                        sortField: 'nik',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={`/user/${entity.id}`}>{entity.nik}</Link>
                            )
                        },

                    }, {
                        field: 'Nama',
                        sortField: 'fullname',
                        rowField: 'fullname',
                    }, {
                        field: 'Email',
                        rowField: 'email',
                        sortField: 'email',
                    }, {
                        field: 'User Authority',
                        rowField: 'roleName',
                        //sortField: 'roleName',
                    }, {
                        field: 'Verifikasi',
                        sortField: 'verified',
                        isCustomRow: (id, entity) => {
                            return entity.verified ? 'Terverifikasi' : 'Belum Verifikasi'
                        }
                    }, {
                        field: 'Status',
                        sortField: 'status',
                        isCustomRow: (id, entity) => {
                            const pallet = statusColor(entity.status)
                            return (
                                <Badge
                                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                    bg={pallet.bg}
                                    color={pallet.color}
                                >
                                    {pallet.text}
                                </Badge>
                            )
                        }
                    }]}
                    isLoading={payload.loading}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload.totalPages}
                    callSorted={(name, direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...payload.parameter,
                            sort: `${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...payload.parameter,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...payload.parameter,
                            ...page
                        }
                        hasStatus('hasPagination')
                        setTable(query)
                    }}

                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Koordinator Delivery?"}
                content={<Segment py={8}>Semua informasi mengenai Koordinator Delivery ini akan dihapus dari sistem</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Users