import React from 'react'
export const requestVerificationAccount = ({ open, url }) => {
    const emailChecked = require('assets/image/email-checked.svg')
    return dispatch => {
        dispatch({
            type: "ALERT_VERIFICATION_ACCOUNT",
            payload: {
                isOpen: open,
                onClose: () => dispatch({
                    type: "ALERT_VERIFICATION_ACCOUNT",
                    payload: { isOpen: !open }
                }),
                headerTitle: "Email Terkirim",
                image: <img src={emailChecked} alt={'Error Icon'} />,
                text: <span>Kami mengirimkan kode verifikasi<br /> di Email Anda.</span>,
                buttonTitle: "Lanjut",
                link: url
            }
        })
    }
}

export const requestVerificationLogin = ({ open, url }) => {
    const emailChecked = require('assets/image/email-x.svg')
    return dispatch => {
        dispatch({
            type: "ALERT_VERIFICATION_ACCOUNT",
            payload: {
                isOpen: open,
                onClose: () => dispatch({
                    type: "ALERT_VERIFICATION_ACCOUNT",
                    payload: { isOpen: !open }
                }),
                headerTitle: "Akun Belum Diverifikasi",
                image: <img src={emailChecked} alt={'Error Icon'} />,
                text: <span>Akun yang and masukan belum terverifikasi.<br /> Harap melakukan verifikasi akun anda terlebih dahulu..</span>,
                buttonTitle: "Verifikasi Akun",
                link: url
            }
        })
    }
}

export const requestVerificationFailed = ({ open, url }) => {
    return dispatch => {
        dispatch({
            type: "ALERT_VERIFICATION_ACCOUNT",
            payload: {
                isOpen: open,
                onClose: () => dispatch({
                    type: "ALERT_VERIFICATION_ACCOUNT",
                    payload: { isOpen: !open }
                }),
                headerTitle: "Kode Verifikasi Salah",
                text: <span>Anda sudah berulang kali memasukan<br /> kode verifikasi yang salah, akun telah dinonaktifkan.<br /> Mohon hubungi admin.</span>,
                buttonTitle: "Tutup",
                link: url
            }
        })
    }
}

export const requestVerificationSuccessFullyForgotPassword = ({ open, url }) => {
    const IconSuccess = require('assets/image/icon-success.svg')
    return dispatch => {
        dispatch({
            type: "ALERT_VERIFICATION_SUCCESSFULL",
            payload: {
                isOpen: open,
                onClose: () => dispatch({
                    type: "ALERT_VERIFICATION_SUCCESSFULL",
                    payload: { isOpen: !open }
                }),
                image: <img src={IconSuccess} alt={'Error Icon'} />,
                headerTitle: "Atur Ulang Kata Sandi Berhasil",
                text: <span>Kata Sandi Anda berhasil disimpan.
                <br />Silahkan masuk kembali ke akun Anda
                <br />menggunakan kata sandi yang baru.</span>,
                buttonTitle: "Masuk",
                link: url
            }
        })
    }
}

export const requestVerificationSuccessFullyResetPassword = ({ open, url }) => {
    const IconSuccess = require('assets/image/icon-success.svg')
    return dispatch => {
        dispatch({
            type: "ALERT_VERIFICATION_SUCCESSFULL",
            payload: {
                isOpen: open,
                onClose: () => dispatch({
                    type: "ALERT_VERIFICATION_SUCCESSFULL",
                    payload: { isOpen: !open }
                }),
                image: <img src={IconSuccess} alt={'Error Icon'} />,
                headerTitle: "Atur Ulang Kata Sandi Berhasil",
                text: <span>Proses verifikasi akun berhasil,
                <br />Silahkan masuk.</span>,
                buttonTitle: "Masuk",
                link: url
            }
        })
    }
}