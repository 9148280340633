import React, { useState } from 'react'
import {
    Segment,
    Text,
} from "@elevenia/master-ui/components/Atom"
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import Breadcrumb from 'component/Breadcrumb'
import TabDaftarPermintaan from './_component/TabDaftarPermintaan'
import TabRiwayat from './_component/TabRiwayat'
import Error404 from 'page/errorLayout'
const dataBreadcrumb = [
    { to: '', label: 'Approval Release Lock' }
]

const ReleaseLockApproval = props => {
    document.title = props.title
    const [activeTab, setActiveTab] = useState("DaftarPermintaan");
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Approval Release Lock</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="DaftarPermintaan">Daftar Permintaan Persetujuan</Text>
                        <Text key="Riwayat">Riwayat</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "DaftarPermintaan" && <TabDaftarPermintaan {...props}/> }
                        {activeTab === "Riwayat" && <TabRiwayat {...props}/>}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default ReleaseLockApproval