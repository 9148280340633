import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestCoordinatorDeliman = action$ =>
    action$.pipe(
        ofType( 'REQUEST_COORDINATOR_DELIMAN',
                'REQUEST_COORDINATOR_DELIMAN_BY_ID',
                'REQUEST_CREATE_COORDINATOR_DELIMAN',
                'REQUEST_UPDATE_COORDINATOR_DELIMAN',
                'REQUEST_CHECK_NIK_DELIMAN',
                'REQUEST_CHECK_EMAIL_DELIMAN',
                'REQUEST_CHECK_PHONE_DELIMAN'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_COORDINATOR_DELIMAN':
                        const rawResponse = await Services().get('/user/api/coordinator',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_COORDINATOR_DELIMAN',
                                payload: {
                                    data: rawResponse.data.data.result.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.result.totalPages,
                                    filterStatus: rawResponse.data.data.status,
                                }
                            })
                        }
                    case 'REQUEST_COORDINATOR_DELIMAN_BY_ID':
                        const rawResponseDetail = await Services().get(`/user/api/coordinator/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_COORDINATOR_DELIMAN_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                
                                }
                            })
                        }

                    case 'REQUEST_CREATE_COORDINATOR_DELIMAN':
                        await Services().post(`/user/api/coordinator/new`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push('/koordinator-delivery')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Koordinator Delivery baru' } })
                        }
                    case 'REQUEST_UPDATE_COORDINATOR_DELIMAN':
                        await Services().put(`/user/api/coordinator/${action.payload.id}`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/koordinator-delivery/${action.payload.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Koordinator Delivery berhasil' } })
                        }
                        case 'REQUEST_CHECK_NIK_DELIMAN':
                            try {
                                const rawCheckNik = await Services().get(`/user/api/coordinator/check-nik/${action.payload.params.nik}`,{id:action.payload.params.id})
                                if(rawCheckNik.data.data.available){
                                    return dispatch => {
                                        dispatch({
                                            type: 'GET_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'nik',
                                                status: '00',
                                                message:''
                                            }
                                        })
                                    }
                                }else{
                                    
                                    return dispatch => {
                                        dispatch({
                                            type: 'ERROR_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'nik',
                                                message: 'NIK sudah digunakan',
                                                status:'03'
                                                
                                            }
                                        })
                                    }
                                }
                                    
                            } catch (error) {
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_DELIMAN',
                                        payload: {
                                            field:'nik',
                                            message: error.message,
                                            status:error.status
                                            
                                        }
                                    })
                                }
                            }
                        case 'REQUEST_CHECK_EMAIL_DELIMAN':
                            try {
                                const rawCheckEmail = await Services().get(`/user/api/coordinator/check-email/${action.payload.params.email}`,{id:action.payload.params.id})
                                
                                if(rawCheckEmail.data.data.available){
                                    return dispatch => {
                                        dispatch({
                                            type: 'GET_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'email',
                                                status: '00',
                                                message:''
                                            }
                                        })
                                    }
                                }else{
                                    return dispatch => {
                                        dispatch({
                                            type: 'ERROR_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'email',
                                                message: 'Email sudah digunakan',
                                                status:'03'
                                                
                                            }
                                        })
                                    }
                                }  
                            } catch (error) {
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_DELIMAN',
                                        payload: {
                                            field:'email',
                                            message: error.message,
                                            status:error.status
                                            
                                        }
                                    })
                                }
                            }
                        case 'REQUEST_CHECK_PHONE_DELIMAN':
                            try {
                                const rawCheckPhone = await Services().get(`/user/api/coordinator/check-phone/${action.payload.params.phone}`,{id:action.payload.params.id})
                                if(rawCheckPhone.data.data.available){
                                    return dispatch => {
                                        dispatch({
                                            type: 'GET_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'phone',
                                                status: '00',
                                                message:''
                                            }
                                        })
                                    }
                                }else{
                                    return dispatch => {
                                        dispatch({
                                            type: 'ERROR_STATUS_FIELD_DELIMAN',
                                            payload: {
                                                field:'phone',
                                                message: 'No HP sudah digunakan',
                                                status:'03'
                                                
                                            }
                                        })
                                    }
                                }     
                            } catch (error) {
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_DELIMAN',
                                        payload: {
                                            field:'phone',
                                            message: error.message,
                                            status:error.status
                                            
                                        }
                                    })
                                }
                            }
                    
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_COORDINATOR_DELIMAN_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
