import React from 'react'
import {
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom'
import TimeSlot from 'component/TimeSlot'
import moment from 'moment'
import { timeSlot } from 'helper'
const ScheduleInfo = ({
    data,
    timezoneLabel
}) => {
  return (
    <>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Slot</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>
                <TimeSlot data={data} timezoneLabel={timezoneLabel} />
                {/* {timeSlot(data?.startSlotHour, data?.endSlotHour)} {timezoneLabel} */}
            </Segment>
        </Segment>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Batas Kirim</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>
                {timeSlot(data?.minDeliveryTime, data?.maxDeliveryTime)} {timezoneLabel}
            </Segment>
        </Segment>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Tanggal Kirim Awal</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>
                {data?.minDeliveryTime ? moment(data?.minDeliveryTime).format('DD/MM/YYYY') : '-'}
            </Segment>
        </Segment>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Tanggal Kirim Akhir</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>
                {data?.maxDeliveryTime ? moment(data?.maxDeliveryTime).format('DD/MM/YYYY') : '-'}
            </Segment>
        </Segment>
    </>
  )
}

export default ScheduleInfo