import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestHomeSideMenu = action$ =>
    action$.pipe(
        ofType('REQUEST_HOME_SIDE_MENU'),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_HOME_SIDE_MENU':
                        const rawResponse = await Services().get('/user/api/home')
                        const getMenus = rawResponse.data.data.menu_group.map(cp => cp.menus)
                        const initialValue = {}
                        const pola = [].concat(...Object.values(getMenus)).reduce((obj, item) => {
                            delete item.accessRights.createdBy
                            delete item.accessRights.lastModifiedBy            
                            return {
                                ...obj,
                                [item['code']]: item.accessRights,
                            };
                        }, initialValue);
                        const transposeData = rawResponse.data.data.orderConfig.configurations?.reduce((data,arr)=>{
                            data[arr.keyId] = parseInt(arr.value) * 60
                            return data
                        },{})
                        return dispatch => {
                            dispatch({
                                type: 'GET_HOME_SIDE_MENU',
                                payload: {
                                    menus: rawResponse.data.data.menu_group,
                                    profile: rawResponse.data.data.profile,
                                    orderConfig: transposeData,
                                    pola,
                                    action_group: rawResponse.data.data.action_group,
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_HOME_SIDE_MENU_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
