import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    FormControl,
    Segment,
    // Spinner,
    Text
} from '@elevenia/master-ui/components/Atom';
import { validateForm } from 'helper';
import { useAction, useInput } from 'hooks';
import OtpField from 'component/OtpField'
import ModalInfo from 'component/ModalCustom/modalMedium'
import { requestToSendOtp, requestVercationSetup } from 'store/actions/verifikasi-setup';
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'

const OTP = props => {
    document.title = props.title;
    const logo = require('assets/image/indopaket-logo.png')
    const emailChecked = require('assets/image/email-checked.svg')
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const [otpValidate, setOtpValidate] = useState("otpInput")
    const [errorName] = useState({
        text: "",
        color: "red"
    })
    const payload = useSelector(state => {
        return {
            notification: state.setAlerVerification.componentMessage,
            status: state.verificationSetup.otp
        }
    });
    const { value, setValue } = useInput({
        initialObjects: {
            otp: payload.status.code
        },
        identity: "myForm"
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        const valid = validateForm(e.target.id);
        valid && hasFetch(requestToSendOtp({ otp: value.otp, history: props.history }));
    }
    useEffect(() => {
        if (payload.status.error) {
            setOtpValidate("otpInput otpValidate");
            errorName['text'] = 'Kode verifikasi yang anda masukan salah.'
        }
    }, [payload.status.error, errorName])
    if (props.location.state === undefined) return <Error404 {...props} />
    return (
        <>
            {payload.notification?.Modal}
            <Segment
                boxShadow
                position={'relative'}
                height={'100vh'}
                alignItems={'center'}
                justifyContent={'center'}
                background="#F2F4FC"
                className="font-nunito"
            >
                <Segment position="absolute" top={40} left={48}>
                    <img src={logo} alt={'Main Logo'} />
                </Segment>
                <Segment
                    boxShadow
                    width={522}
                    bg={'white'}
                    borderRadius={8}
                    px={80}
                    py={60}
                    mx={'auto'}
                >
                    <Segment flexDirection={'column'} alignItems={'center'} mb={48} mx={-24}>
                        <Text H28 fontWeight={'800'} textAlign={'center'} fontSize={28} mt={8}>Verifikasi</Text>
                        <Text color={'#70727D'} mt={32} textAlign={'center'} fontSize={14}>
                            {/* Kode verifikasi telah dikirim melalui email ke */}
                            {/* <br />{props.location.state?.email}. */}
                            <br />Silahkan masukkan 4 digit kode verifikasi.
                            <br />Anda memiliki batas kesempatan 3 kali salah,
                            <br />jika lebih akun Anda akan dinonaktifkan.
                        </Text>
                    </Segment>
                    <form id="myForm" autoComplete="false">
                        <FormControl mb={20}>
                            <>
                                <OtpField
                                    autoFocus
                                    isNumberInput
                                    name="otp"
                                    length={4}
                                    className="otpContainer"
                                    inputClassName={otpValidate}
                                    onChangeOTP={(otp) => setValue(prev => ({ ...prev, otp }))}
                                    value={value.otp}
                                />
                                <Text style={{ textAlign: "center" }} color="red">{errorName.text}</Text>
                            </>
                        </FormControl>
                        <Segment mt={24}>
                            <Text color={'#70727D'} textAlign="center" fontSize={14}>Tidak menerima Kode? <a href="/" onClick={e => {
                                e.preventDefault()
                                hasFetch(requestVercationSetup(props.location.state?.email, props.history, props.location.state?.flex))
                            }}><b>Kirim Ulang Kode</b></a></Text>
                        </Segment>
                        <Segment mt={32}>
                            <ButtonGroup responsive>
                                <Button onClick={handleSubmit} disabled={value.otp.length !== 4} type="button" variant="primary">
                                    Kirim
                                </Button>
                            </ButtonGroup>
                        </Segment>
                    </form>
                </Segment>
            </Segment >

            {/* modal */}
            <ModalInfo
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                content={
                    <Segment style={{ textAlign: 'center' }} className="font-nunito" mb={48}>
                        <img src={emailChecked} alt={'Error Icon'} />
                        <Text color="#1178D4" fontWeight={'800'} fontSize={28} mb={24} mt={53}>Email Terkirim</Text>
                        <Text color="#70727D" fontSize={16}>
                            Kami mengirimkan kode verifikasi <br />
                            di Email Anda.
						</Text>
                    </Segment>
                }
                ButtonFooter={
                    (
                        <Segment justifyContent="center" width="100%" className="font-nunito" mb={9}>
                            <Button width={240}>
                                Lanjut
                            </Button>
                        </Segment>
                    )
                }
            />
        </>
    )
}

export default OTP