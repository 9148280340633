import { Segment, Text } from "@elevenia/master-ui/components/Atom"
import React from 'react'
import { Link } from "react-router-dom"
import { useAction } from 'hooks';
import { requestFileUpload } from 'store/actions/orderList'

const CardDetailPesanan =({
    dataConsume=[],
    orderStatus='',
    })=>{

    const { hasFetch } = useAction();

    const handleGetImage = (url) => {
        hasFetch(requestFileUpload(url))
    }
    return (
        <>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mt={24}>
                    <Segment>
                        <Text fontWeight={600} mb={32}>Pengirim Pesanan</Text>

                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama Pengirim</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.pickupName ?? '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nomor Telepon</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.pickupPhoneNumber ?? '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Alamat</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.pickupAddress ?? '-'}
                            </Segment>
                        </Segment>
                    </Segment>
                </Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mt={24}>
                    <Segment>
                        <Text fontWeight={600} mb={32}>Tujuan Pesanan</Text>

                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama Tujuan</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.customerName ?? '-'}
                            </Segment>
                        </Segment>
                        {
                            (["COMPLETED", "IN_RETURN", "RETURN_COMPLETED", "CANCELED","FAILED","CANCELING","FALLBACK"].includes(orderStatus)
                            && dataConsume?.orderType!=="PAKET-JEMPUT") && 
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Nama Penerima</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {dataConsume?.receiverName ?? dataConsume?.customerName}
                                </Segment>
                            </Segment>
                        }
                        
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nomor Telepon</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.customerPhoneNumber ?? '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Alamat</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataConsume?.deliveryAddress ?? '-'}
                            </Segment>
                        </Segment>
                        {
                            (["COMPLETED", "IN_RETURN", "RETURN_COMPLETED", "CANCELED","FAILED","CANCELING","FALLBACK"].includes(orderStatus)
                            && dataConsume?.orderType!=="PAKET-JEMPUT") &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Foto Bukti Penerima</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {
                                        dataConsume?.deliveryImageUrl
                                            ?
                                            <Link
                                                to={'#'}
                                                onClick={() => handleGetImage(dataConsume?.deliveryImageUrl)}
                                            >
                                                Lihat Gambar
                                        </Link> : '-'
                                    }
                                </Segment>
                            </Segment>
                        }
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default CardDetailPesanan