import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestManager = action$ =>
    action$.pipe(
        ofType( 'REQUEST_MANAGER',
                'REQUEST_MANAGER_BY_ID',
                'REQUEST_CREATE_MANAGER',
                'REQUEST_UPDATE_MANAGER',
                'REQUEST_CHECK_NIK_MANAGER',
                'REQUEST_CHECK_EMAIL_MANAGER',
                'REQUEST_CHECK_PHONE_MANAGER'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_MANAGER':
                        const rawResponse = await Services().get('/driver/api/back-office/list',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_MANAGER',
                                payload: {
                                    data: rawResponse.data.data.result.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.result.totalPages,
                                    filterStatus: rawResponse.data.data.statusList,
                                    filterCluster: rawResponse.data.data.clusters,
                                }
                            })
                        }
                    case 'REQUEST_MANAGER_BY_ID':
                        const rawResponseDetail = await Services().get(`/driver/api/back-office/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_MANAGER_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                
                                }
                            })
                        }

                    case 'REQUEST_CREATE_MANAGER':
                        await Services().post(`/user/api/manager`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push('/manager')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Manager baru.' } })
                        }
                    case 'REQUEST_UPDATE_MANAGER':
                        await Services().put(`/user/api/manager`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/manager`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Manager berhasil' } })
                        }
                    case 'REQUEST_CHECK_NIK_MANAGER':
                        try {
                            const rawCheckNik = await Services().get(`/user/api/manager/check-nik/${action.payload.params.nik}`,{id:action.payload.params.id})
                            if(rawCheckNik.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'nik',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'nik',
                                            message: 'NIK sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }
                                
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD_MANAGER',
                                    payload: {
                                        field:'nik',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_CHECK_EMAIL_MANAGER':
                        try {
                            const rawCheckEmail = await Services().get(`/user/api/manager/check-email/${action.payload.params.email}`,{id:action.payload.params.id})
                            
                            if(rawCheckEmail.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'email',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'email',
                                            message: 'Email sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }  
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD_MANAGER',
                                    payload: {
                                        field:'email',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_CHECK_PHONE_MANAGER':
                        try {
                            const rawCheckPhone = await Services().get(`/user/api/manager/check-phone/${action.payload.params.phone}`,{id:action.payload.params.id})
                            if(rawCheckPhone.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'phone',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_MANAGER',
                                        payload: {
                                            field:'phone',
                                            message: 'No HP sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }     
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD_MANAGER',
                                    payload: {
                                        field:'phone',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_MANAGER_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
