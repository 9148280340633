import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestReleaseAccess = (action$,state$) =>
    action$.pipe(
        ofType( 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_ACCESS',
                'REQUEST_SUBMIT_RELEASE_ACCESS',
                'REQUEST_RELEASE_ACCESS',
                'REQUEST_RELEASE_ACCESS_INTERVAL',
                'REQUEST_RELEASE_ACCESS_HISTORY',
                'REQUEST_DURATION_RELEASE_ACCESS',
                'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS_RELEASE_ACCESS'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_RELEASE_ACCESS':
                        const rawResponseReleaseAccess = await Services().get(`/order/api/back-office/release-location`,action.payload.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_ACCESS',
                                payload: {
                                    data: rawResponseReleaseAccess.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseAccess.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_RELEASE_ACCESS_INTERVAL':
                        const rawResponseReleaseAccessInt = await Services().get(`/order/api/back-office/release-location/`,state$.value.releaseAccess.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_ACCESS',
                                payload: {
                                    data: rawResponseReleaseAccessInt.data.data.content,
                                    parameter: state$.value.releaseAccess.parameter,
                                    totalPages: rawResponseReleaseAccessInt.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_RELEASE_ACCESS_HISTORY':
                        const rawResponseReleaseAccessHis = await Services().get(`/order/api/back-office/release-location/history`,action.payload.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_ACCESS_HISTORY',
                                payload: {
                                    data: rawResponseReleaseAccessHis.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseAccessHis.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS_RELEASE_ACCESS':
                        const rawResponseDelimanStatus = await Services().get(`/order/api/back-office/release-location/information?${action.payload.params}`)

                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS',
                                payload: {
                                    orderList: rawResponseDelimanStatus.data.data,

                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_ACCESS':
                        const rawResponse = await Services().get(`/order/api/back-office/release-location/orders`,{
                            deliverymanId:action.payload.id
                        })
                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST_BY_ID_DELIMAN_ACCESS',
                                payload: {
                                    orderList: rawResponse.data.data?.content,
                                    
                                }
                            })
                        }
                    case 'REQUEST_SUBMIT_RELEASE_ACCESS':
                        await Services().post(`/orderprocessor/api/back-office/release-location/add`,action.payload.data)
                        return dispatch => {
                            action.history.push(`/release-location`)
                            dispatch({ type: 'RELEASE_ACCESS_SUCCESS' })
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Release Lock berhasil dibuat' } })
                        }
                    case 'REQUEST_DURATION_RELEASE_ACCESS':
                        const rawDurationReleaseAccess = await Services().get('/orderprocessor/api/back-office/release-location/config')
                        
                        return dispatch=> {
                            dispatch({ 
                                type: 'GET_DURATION_RELEASE_ACCESS',
                                payload:{
                                    data: rawDurationReleaseAccess.data.data.content
                                }
                             })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "RELEASE_ACCESS_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
