import { Segment } from '@elevenia/master-ui/components/Atom'
import Spinner from '@elevenia/master-ui/components/Atom/Spinner'
import React from 'react'
import { Services } from 'service'
import 'moment/locale/id';

export const requestTrackOrder = ({ trackingOrderId }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_TRACK_ORDER",
            payload: { searchParam: trackingOrderId }
        })
    }
}
export const requestFileTrackRecord = ({ open, imageURL }) => {
    const moment = require("moment");
    return async dispatch => {
        const parameter = { isOpen: open, onClose: () => { dispatch({ type: "RESET_FILE_TRACK_RECORD" }) }, buttonTitle: "Tutup" }
        dispatch({
            type: "GET_FILE_TRACK_RECORD", payload: { ...parameter, image: <Segment className="u-tx-center" width="100%" height={40}><Spinner /></Segment> }
        })
        try {
            const response = await Services().get(imageURL, null, { responseType: "arraybuffer" })
            const blob = new Blob([response.data], { type: "image/png" });
            const uris = URL.createObjectURL(blob);
            const raw = JSON.parse(response.headers.metadata)
            
            dispatch({
                type: "GET_FILE_TRACK_RECORD", payload: {
                    ...parameter, image: <img src={uris} alt={'file pendukung'} />,
                    meta: {
                        ...raw,
                        timestamp: moment(new Date(raw.timestamp), 'dddd, DD MMM YYYY - HH:mm').format("dddd, DD MMM YYYY - HH:mm")
                    }
                }
            })
        } catch (e) {
            dispatch({ type: "ALERT_TOAST_ERROR", payload: { message: "File Pendukung Tidak Tersedia" } })
        }
    }
}

export const requestListReasonCancelOrder = (params) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_LIST_REASON_CANCEL_ORDER",
            payload: { params }
        })
    }
}

export const requestCancelOrder = (params) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_CANCEL_ORDER",
            payload: { params }
        })
    }
}

export const requestMultiTrackOrder = (searchParams, category) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_MULTI_TRACK_ORDER",
            payload: { searchParams, category }
        })
    }
}

export const requestOptionSearchCategory = () => {
    return dispatch => {
        dispatch({
            type: "REQUEST_OPTION_SEARCH_CATEGORY",
        })
    }
}