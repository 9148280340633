import React from 'react'
import moment from 'moment'
import { Segment, Text } from '@elevenia/master-ui/components/Atom'
const ScheduleOrderInfo = ({
    data,
    textOrderStatus,
    timezoneLabel,
    allowedStatus
}) => {
  return (
    <>
        {
            (textOrderStatus !== 'ALLOCATING')
            &&
            <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Waktu Pilih Pesanan</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>

                    {data?.confirmOrderTime ? `${moment(data?.confirmOrderTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-'}
                </Segment>
            </Segment>
        }

        {
            allowedStatus.includes(textOrderStatus)
            &&
            <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Waktu Jemput</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>

                    {data?.pickupTime ? `${moment(data?.pickupTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-'}
                </Segment>
            </Segment>
        }
    </>
  )
}

export default ScheduleOrderInfo