import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    ButtonGroup,
    Icon,
    ButtonLink,
} from '@elevenia/master-ui/components/Atom'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { useSingleToggle } from 'hooks';
import Breadcrumb from 'component/Breadcrumb'
import { validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { requestChangePassword } from 'store/actions/changePassword'
import { useSelector } from 'react-redux';
import { logout } from 'helper'

const ChangePassword = props => {
    document.title = props.title
    const [openOld, setOpenOld] = useSingleToggle(false);
    const [openNew, setOpenNew] = useSingleToggle(false);
    const [openConfirm, setOpenConfirm] = useSingleToggle(false);
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [isModalSuccess, setIsModalSuccess] = useState(false)
    const [disablePreview, setDisablePreview] = useState(true)
    const dataBreadcrumb = [
        { to: '', label: 'Ubah Kata Sandi' }
    ]

    const { hasFetch } = useAction()
    const payload = useSelector(state => {
        return {
            email:state.homesidemenu.profile.email,
            status: state.changePassword.status,
            loading: state.changePassword.loading
        }
    })
    
    let { value, bindChange } = useInput({
        initialObjects: {
            newPassword: '',
            konfirmPassword: '',
            oldPassword:''

        },
        identity: "myForm",
    });

    useEffect(()=>{
        const disabled = Object.values(value).filter(val=>val==="")
        if(disabled.length){
            
            setDisablePreview(true)
        }else{
            
            setDisablePreview(false)
        }
        
    },[value])

    

    useEffect(()=>{
        if(payload.status){
            
            setIsModalSuccess(true)
            setIsModalCreate(false)
        }
        
    },[payload.status])

    const handlePreview = () => {
        if(validateForm('myForm')){
            setIsModalCreate(!isModalCreate)
        }
    }

    const handleSubmit = () => {
        const dataSubmit = {
            email:payload.email,
            currentSecret:value.oldPassword,
            newSecret:value.newPassword
        }
        hasFetch(requestChangePassword(dataSubmit))
    }

    const handleLogin = () => {
        logout()
    }

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Ubah Kata Sandi</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Kata Sandi</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kata Sandi Lama</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                type: openOld ? 'text' : 'password',
                                                name: 'oldPassword',
                                                className: 'validate[required]',
                                                placeholder: 'Kata Sandi Lama',
                                                value:value.oldPassword
                                            }}
                                            right={
                                                <ButtonLink type="button" onClick={setOpenOld}>
                                                    <Icon
                                                        name={openOld ? 'visible' : 'invisible'}
                                                        size={16}
                                                        fillColor="#70727D"
                                                    />
                                                </ButtonLink>
                                            }
                                            state="normal"
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Kata Sandi Baru</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                onChange:(e)=>{
                                                    validateInput('myForm','konfirmPassword')
                                                    bindChange.onChange(e)
                                                },
                                                type: openNew ? 'text' : 'password',
                                                name: 'newPassword',
                                                className: 'validate[required,minLength[6]] validateMessage{minLength{Kata sandi yang diinputkan harus lebih dari 6 digit}',
                                                placeholder: 'Kata Sandi Baru',
                                                value:value.newPassword
                                            }}
                                            right={
                                                <ButtonLink type="button" onClick={setOpenNew}>
                                                    <Icon
                                                        name={openNew ? 'visible' : 'invisible'}
                                                        size={16}
                                                        fillColor="#70727D"
                                                    />
                                                </ButtonLink>
                                            }
                                            state="normal"
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Konfirmasi Kata Sandi</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                type: openConfirm ? 'text' : 'password',
                                                name: 'konfirmPassword',
                                                className: `validate[required,equals[newPassword]] validateMessage{equals{Kata Sandi yang Anda masukan tidak sama}}`,
                                                placeholder: 'Konfirmasi Kata Sandi',
                                                value:value.konfirmPassword
                                            }}
                                            right={
                                                <ButtonLink type="button" onClick={setOpenConfirm}>
                                                    <Icon
                                                        name={openConfirm ? 'visible' : 'invisible'}
                                                        size={16}
                                                        fillColor="#70727D"
                                                    />
                                                </ButtonLink>
                                            }
                                            state="normal"
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Button
                                variant="secondary"
                                size="medium"
                                type={"button"}
                                mr={16}
                                onClick={()=>props.history.goBack()}
                            >
                                Batal
                            </Button>
                            <Button
                                variant="primary"
                                size="medium"
                                type={"button"}
                                disabled={disablePreview}
                                onClick={() => handlePreview()}
                            >
                                Simpan
                            </Button>
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Ubah Kata Sandi?"}
                content={<Segment py={8}>Perubahan Kata Sandi Anda akan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button disabled={payload.loading} onClick={handleSubmit} minWidth={"100px"}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalSuccess}
                //onClose={() => setIsModalSuccess(!isModalSuccess)}
                title={"Kata Sandi Berhasil Diubah"}
                content={<Segment py={8}>Kata Sandi Anda berhasil diubah. Silahkan masuk kembali menggunakan kata sandi Anda yang baru.</Segment>}
                backdrop="static"
                ButtonFooter={
                    (
                        <ButtonGroup>
                            
                            <Button disabled={payload.status ? false : true} onClick={handleLogin} minWidth={"100px"}>
                                Masuk
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default ChangePassword