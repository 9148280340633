import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    ButtonGroup,
    Switch,
    Spinner,
    Icon
} from '@elevenia/master-ui/components/Atom'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import Breadcrumb from 'component/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import { validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { setStatusField, resetInputStatus, requestCoordinatorDelimanById, requestUpdateCoordinatorDeliman, requestCheckNik, requestCheckEmail, requestCheckPhone } from 'store/actions/coodinatorDeliman'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'

import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'


const KoordinatorDelivForm = props => {
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;

    useEffect(() => {
        let unmounted = false;
        if (props.access) {
            getPath !== undefined && props.access?.update && !unmounted && hasFetch(requestCoordinatorDelimanById(getPath))
            getPath === undefined && props.access?.create && !unmounted && hasFetch(setStatusField(false))
            getPath === undefined && props?.location?.state && props.access?.create && !unmounted && hasFetch(setStatusField(true))
        }
        return () => (unmounted = true);
    }, [hasFetch, getPath, props.access,props.location.state])
    const loading = useSelector(state => state.coordinatorDeliman.loading)


    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <>{(!loading) && <Form {...props} />}</>
    )
}

const parseStatus = {
    active: true,
    inactive: false
}

const Form = (props) => {
    document.title = props.title
    const history = props.history
    const [isModalCreate, setIsModalCreate] = useState(false)
    
    const { id } = useParams()
    const [disablePreview, setDisablePreview] = useState(true)
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            detailData: state.coordinatorDeliman.detailData,
            previewData: props?.location?.state,
            loadingCheck: state.coordinatorDeliman.loadingCheck,
            messageError: state.coordinatorDeliman.messageError,
            statusCheck: state.coordinatorDeliman.statusCheck,
        }
    })


    let { value, bindChange, bindChecked } = useInput({
        initialObjects: {
            name: payload?.detailData?.name ? payload?.detailData?.name : payload?.previewData?.name ? payload?.previewData?.name : '',
            nik: payload.detailData?.nik ? payload?.detailData?.nik : payload?.previewData?.nik ? payload?.previewData?.nik : '',
            email: payload.detailData?.email ? payload?.detailData?.email : payload?.previewData?.email ? payload?.previewData?.email : '',
            phone: payload.detailData?.phone ? payload?.detailData?.phone : payload?.previewData?.phone ? payload?.previewData?.phone : '',
            status: payload.detailData?.status ? parseStatus[payload?.detailData?.status] : payload?.previewData?.status ? parseStatus[payload?.previewData?.status] : true,
        },
        identity: "myForm",
    });

    const title = id ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = id ? [
        { to: `/koordinator-delivery/${id}`, label: 'Detail Koordinator Delivery' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/koordinator-delivery', label: 'Koordinator Delivery' },
        ...dataBreadcrumbExt
    ]



    const handleShowConfirmation = () => {
        if (validateForm('myForm')) {
            setIsModalCreate(!isModalCreate)
        }

    }

    const [onSearchNik$] = useState(()=> new Subject())
    const [onSearchEmail$] = useState(()=> new Subject())
    const [onSearchPhone$] = useState(()=> new Subject())

    useEffect(()=>{
        onSearchEmail$.pipe(
            tap(()=>hasFetch(resetInputStatus('email'))),
            debounceTime(500),
            distinctUntilChanged(),            
            tap(value=>{
                
                validateInput('myForm','email') && hasFetch(requestCheckEmail({
                    email:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchEmail$,id])

    useEffect(()=>{
        onSearchPhone$.pipe(
            tap(()=>hasFetch(resetInputStatus('phone'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value=>{
                validateInput('myForm','phone') && hasFetch(requestCheckPhone({
                    phone:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchPhone$,id])

    useEffect(()=>{
        onSearchNik$.pipe(
            tap(()=>hasFetch(resetInputStatus('nik'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value=>{
                validateInput('myForm','nik') && hasFetch(requestCheckNik({
                    nik:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchNik$,id])

    const handleChangeNik = (e) => {
        bindChange.onChange(e)
        onSearchNik$.next(e.target.value)
    }

    const handleChangeEmail = (e) => {
        bindChange.onChange(e)
        onSearchEmail$.next(e.target.value)
    }

    const handleChangePhone = (e) => {
        bindChange.onChange(e)
        onSearchPhone$.next(e.target.value)
    }

    const handleSubmit = () => {
        const dataUpdate = {
            email: value.email,
            name: value.name,
            nik: value.nik,
            phone: value.phone,
            status: value.status ? 'active' : 'inactive',
            id: payload.detailData?.id,
        }
        hasFetch(requestUpdateCoordinatorDeliman(payload.detailData?.id, dataUpdate, history))

    }

    useEffect(() => {
        const disabled = Object.values(value).filter(val => val === "")
        if (disabled.length) {

            setDisablePreview(true)
        } else {

            setDisablePreview(false)
        }

    }, [value])

    const handlePreview = () => {
        if (validateForm('myForm')) {
            let dataPreview = { ...value }
            dataPreview.status = value.status ? 'active' : 'inactive'
            history.push({
                pathname: '/koordinator-delivery/preview',
                state: dataPreview
            })
        }
    }

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Koordinator Delivery</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <Textfield
                                            inputProps={{
                                                ...bindChange,
                                                name: 'name',
                                                placeholder: "Nama",
                                                value: value.name,
                                                maxLength: 100,
                                                className: 'validate[required]',
                                                
                                            }}

                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>NIK</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.nik ? 'error':'normal'}
                                        helptext={payload.messageError.nik}
                                    >
                                        <Textfield
                                            status={payload.messageError.nik ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangeNik(e),
                                                placeholder: "NIK",
                                                name: 'nik',
                                                value: value.nik,
                                                maxLength: 10,
                                                className: 'validate[required,length[10]]',
                                                
                                            }}
                                            disabled={payload.loadingCheck.nik ? true :false}
                                            right={
                                                payload.loadingCheck.nik ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.nik && payload.statusCheck.nik==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Email</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.email ? 'error':'normal'}
                                        helptext={payload.messageError.email}
                                    >
                                        <Textfield
                                            status={payload.messageError.email ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangeEmail(e),
                                                placeholder: "Email",
                                                name: 'email',
                                                value: value.email,
                                                maxLength: 100,
                                                className: 'validate[required,email]',
                                                
                                            }}
                                            disabled={payload.loadingCheck.email ? true :false}
                                            right={
                                                payload.loadingCheck.email ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.email && payload.statusCheck.email==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nomor Handphone</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.phone ? 'error':'normal'}
                                        helptext={payload.messageError.phone}
                                    >
                                        <Textfield
                                            status={payload.messageError.phone ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangePhone(e),
                                                placeholder: "Nomor Handphone",
                                                name: 'phone',
                                                value: value.phone,
                                                maxLength: 16,
                                                className: 'validate[required,phone]',

                                            }}
                                            disabled={payload.loadingCheck.phone ? true :false}
                                            right={
                                                payload.loadingCheck.phone ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.phone && payload.statusCheck.phone==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Switch name="status" label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.status} />
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={id ? `/koordinator-delivery/${id}` : "/koordinator-delivery"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={!(!disablePreview&&payload.statusCheck.nik==='00' && payload.statusCheck.email==='00'&&payload.statusCheck.phone==='00')}
                                >
                                    Simpan
                                </Button>
                                :

                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handlePreview()}
                                    disabled={!(!disablePreview&&payload.statusCheck.nik==='00' && payload.statusCheck.email==='00'&&payload.statusCheck.phone==='00')}
                                >
                                    Preview
                                </Button>
                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button type="button" minWidth={"100px"} onClick={handleSubmit}>
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default KoordinatorDelivForm