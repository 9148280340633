import React, { useState, useRef } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    Spinner
} from '@elevenia/master-ui/components/Atom'

import Breadcrumb from 'component/Breadcrumb'
import { Link } from 'react-router-dom'

import { requestUploadFile, requestSubmitJadwal } from 'store/actions/configRKB'
import { useAction } from 'hooks';
import { useSelector } from 'react-redux'
import ResultImportedData from './_component/ResultImportedData'
import Error404 from 'page/errorLayout'
import Template from 'assets/template/RKB_TEMPLATE.xlsx'
const dataBreadcrumb = [
    { to: '/pengaturan-rkb', label: 'Pengaturan RKB' },
    { to: '', label: 'Upload Jadwal' }
]



const ButtonCariFile = ({ disabled, handleFile }) => {

    const hiddenFileInput = useRef(null);
    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded){
            handleFile(fileUploaded);
        }
        
        
    };

    return <>
        <Button
            style={{ minWidth: 86 }}
            ml={12}
            size="medium"
            type={"button"}
            onClick={handleClick}
            disabled={disabled}
        >
            Cari File

            {/* hidden input file */}
            <input
                accept='<input id="fileSelect" type="file" accept=".csv,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </Button>
    </>
}
const ConfigRKBForm = props => {


    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />

    return (
        <Form {...props} />
    )
}
const Form = ({title, history}) => {

    document.title = title
    const IconDownload = require("assets/image/download.svg");
    const [getFile, setGetFile] = useState("");
    const [isUpload, setIsUpload] = useState(false)
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            dataUpload: state.configRKB.dataUpload,
            loadingUpload: state.configRKB.loadingUpload,
            loadingRead: state.configRKB.loadingRead,
            loadingError: state.configRKB.loadingError,
            totalDataError: state.configRKB.totalDataError,
            totalDataRead: state.configRKB.totalDataRead,
            loadingSubmit: state.configRKB.loadingSubmit,
            isUploadError: state.configRKB.isUploadError
        }
    })
    const handleProses = () => {
        
        hasFetch(requestSubmitJadwal({
            rkbUploadId: payload.dataUpload.id
        },history))
    }

    const handleUpload = () => {
        setIsUpload(true)
        const form = new FormData()
        form.append('file', getFile)
        hasFetch(requestUploadFile(form))
    }
    const filename = 'RKB KOORDINATOR DELIVERY TEMPLATE.xlsx'
    const link = useRef(null)
    const handleDownloadFile = () => {
        link.current.click();
    }
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Upload Jadwal</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Import Data</Text>
                            <Segment>
                                <Row mb={20}>
                                    <Segment width={280} py={8}>
                                        <Text>File</Text>
                                    </Segment>
                                    <Segment flex={1} display="flex" className="xs-flex-wrap">
                                        <FormControl helptext="XLS atau CSV file" style={{ flex: "1" }} className={"custom-helptext"}>
                                            <Textfield
                                                inputProps={{
                                                    onChange: () => { },
                                                    placeholder: "Pilih File",
                                                    value: getFile?.name ?? ""
                                                }}
                                                disabled={true}
                                            />
                                        </FormControl>
                                        <Segment>
                                            <ButtonCariFile disabled={isUpload && payload.dataUpload?.id && !payload.isUploadError} handleFile={setGetFile} />
                                        </Segment>
                                    </Segment>
                                </Row>
                                <Segment justifyContent="flex-end">
                                    <Button onClick={handleDownloadFile} mr={16} variant="secondary" style={{ color: "#151823" }}>
                                    <a
                                        ref={link}
                                        style={{ display: 'none' }}
                                        download={filename}
                                        target="_blank"
                                        href={Template}
                                        rel="noopener noreferrer"
                                    >download</a>
                                        <img src={IconDownload} alt="Download" style={{ marginRight: 8 }} />
                                        Download Template
                                    </Button>
                                </Segment>
                            </Segment>
                            {isUpload ?
                                payload?.loadingUpload ?
                                    <Segment className="u-tx-center" width="100%" height={30}>
                                        <Spinner />
                                    </Segment> :
                                    <>
                                        <Segment>
                                            <Segment borderTop={"1px solid #DCDEE3"} display="flex" className="xs-flex-wrap" pt={24} mt={20} mb={24}>
                                                {
                                                    payload.loadingRead ?
                                                        <Segment className="u-tx-center" width="100%" height={30}>
                                                            <Spinner />
                                                        </Segment> :
                                                        <>
                                                            <Segment minWidth={280}>
                                                                <Text color={'#70727D'}>Data Terbaca</Text>
                                                            </Segment>
                                                            <Segment display="flex">
                                                                <Text mr={8}>:</Text>
                                                                {payload.totalDataRead} data terbaca
                                                            </Segment>
                                                        </>
                                                }

                                            </Segment>
                                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                                {
                                                    payload.loadingError ?
                                                        <Segment className="u-tx-center" width="100%" height={30}>
                                                            <Spinner />
                                                        </Segment> :
                                                        <>
                                                            <Segment minWidth={280}>
                                                                <Text color={'#70727D'}>Data Tidak Terbaca</Text>
                                                            </Segment>
                                                            <Segment display="flex">
                                                                <Text mr={8}>:</Text>
                                                                {payload.totalDataError} data tidak terbaca
                                                            </Segment>
                                                        </>
                                                }
                                            </Segment>
                                        </Segment>
                                        <ResultImportedData />
                                    </> : ''
                            }

                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={"/pengaturan-rkb"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                    
                                >
                                    Batal
                                </Button>
                            </Link>
                            {
                                (isUpload && !payload.isUploadError) ?
                                    <Button
                                        variant="primary"
                                        size="medium"
                                        type={"button"}
                                        onClick={handleProses}
                                        disabled={ payload.loadingSubmit || payload.listDataRead?.length === 0 || payload.loadingUpload || payload.loadingError || payload.loadingRead || payload.totalDataRead===0}
                                    >
                                        Proses
                                    </Button> :
                                    <Button
                                        variant="primary"
                                        size="medium"
                                        type={"button"}
                                        onClick={handleUpload}
                                        disabled={getFile === ""}
                                    >
                                        Upload
                                    </Button>
                            }

                        </Segment>
                    </Segment>
                </form>
            </Segment>
        </>
    )
}

export default ConfigRKBForm