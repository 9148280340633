import React from "react"
import ModalSmall from 'component/ModalCustom/modalSmall'
import {
    Segment,
    Icon,
    ButtonLink,
    Text,
    TooltipTarget,
    Tooltip,
    TooltipContent,
    Spinner
} from "@elevenia/master-ui/components/Atom"
import { useSelector } from 'react-redux';
import useSWR from "swr";
import { fetcher, objectToQueryString } from "helper";
const ModalOderInfo = ({isModal, setIsModal, detailId}) => {
    
    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data:dataDetail, error } = useSWR((detailId && isModal) &&  `/order/api/back-office/release-location/orders?${objectToQueryString({releaseLocationId: detailId.id})}`,fetcher,optionsSwr)
    
    const payload = useSelector(() => {
        return {
            orderListById: dataDetail?.data?.content,
            loadingGetOrder: !dataDetail && !error
        }
    })
    
    return(
        <>
            <ModalSmall
            isOpen={isModal}
            onClose={() => setIsModal(!isModal)}
            title={<>
                <Segment justifyContent="space-between">
                    <Text>
                        Daftar Order ID
                    </Text>
                    <ButtonLink onClick={() => setIsModal(!isModal)}>
                        <Icon name={"cancel"} size={14} fillColor={"#70727D"} />
                    </ButtonLink>
                </Segment>
            </>}
            content={<Segment py={8} mt={24}>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={100}>
                        <Text color={'#70727D'}>Delivery Man</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {`${detailId?.deliverymanNik} - ${detailId?.deliverymanName}`}
                    </Segment>
                </Segment>
                <Segment display="flex" className="xs-flex-wrap">
                    <Segment minWidth={100} display="flex">
                        <Text color={'#70727D'}>Order ID</Text>
                        <Tooltip type="hover" placement="bottom" color="dark">
                            <TooltipTarget>
                                <Icon name="information" size={16} fillColor="#434755" ml={8}/>
                            </TooltipTarget>
                            <TooltipContent>
                                Order akan ter-update otomatis.
                            </TooltipContent>
                        </Tooltip>
                    </Segment>
                    <Segment display="flex" style={{ width: "100%" }}>
                        <Text mr={8}>:</Text>
                        <Segment 
                            style={{
                                overflowY: "scroll",
                                maxHeight: "min(72vh, 695px)",
                                width: "100%"
                            }}
                        >
                        {payload.loadingGetOrder && <Segment className="u-tx-center" width={30} height={30}>
                                    <Spinner />
                                </Segment>}
                        {(payload.orderListById && payload.orderListById?.length ) 
                        ? payload.orderListById?.map((val,index)=>(<Text key={index} mb={12}>
                            {val.orderId}
                        </Text>))
                        : '-'
                        }
                        </Segment>
                    </Segment>
                </Segment>
            </Segment>}
        />
        </>
    )
}

export default ModalOderInfo