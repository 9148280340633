import React, { createContext, useContext, useState } from "react";
import useFetchSWR from "hooks/useFetchSWR";

const initData = {
    dataRead: null,
    errorRead: null,
    dataUnRead: null,
    errorUnRead: null,
    isTableRead: null,
    setTableRead: () => null,
    isTableUnRead: null,
    setTableUnRead: () => null
};

const dataUploadContent = createContext(initData);

export const DataUploadProvider = ({ uploadResult, children, value }) => {
    const [isTableRead, setTableRead] = useState({
        page: 0,
        size: 10,
    });
    const [isTableUnRead, setTableUnRead] = useState({
        page: 0,
        size: 10,
    });
    const { data: dataRead, error: errorRead } = useFetchSWR({
        key: uploadResult?.id && isTableRead && [`/driver/api/back-office/absensi/upload/${uploadResult.id}/review/read-list`, isTableRead],
        mappingData: (data) => ({
            data: data?.data?.content,
            totalPages: data?.data?.totalPages,
            totalElements: data?.data?.totalElements
        }),
        options: {
            revalidateOnFocus: false
        }
    });
    const { data: dataUnRead, error: errorUnRead } = useFetchSWR({
        key: uploadResult?.id && isTableUnRead && [`/driver/api/back-office/absensi/upload/${uploadResult.id}/review/error-list`, isTableUnRead],
        mappingData: (data) => ({
            data: data?.data?.content,
            totalPages: data?.data?.totalPages,
            totalElements: data?.data?.totalElements
        }),
        options: {
            revalidateOnFocus: false
        }
    });

    return (
        <dataUploadContent.Provider
            value={{
                uploadResult,
                dataRead,
                errorRead,
                dataUnRead,
                errorUnRead,
                isTableRead,
                setTableRead,
                isTableUnRead,
                setTableUnRead,
                loadingSubmit: value.loadingSubmit,
                isUpload: value.isUpload,
                loadingUpload: value.loadingUpload
            }}
        >
            {children}
        </dataUploadContent.Provider>
    );
};

const useDataUpload = () => {
    const {
        uploadResult,
        dataRead,
        errorRead,
        dataUnRead,
        errorUnRead,
        isTableRead,
        setTableRead,
        isTableUnRead,
        setTableUnRead,
        loadingSubmit,
        isUpload,
        loadingUpload
    } = useContext(dataUploadContent)

    return {
        uploadResult,
        dataRead,
        errorRead,
        dataUnRead,
        errorUnRead,
        isTableRead,
        setTableRead,
        isTableUnRead,
        setTableUnRead,
        loadingSubmit,
        isUpload,
        loadingUpload
    };
};

export default useDataUpload;
