import React from "react";
import { Button, Segment } from "@elevenia/master-ui/components/Atom";
import { Link } from "react-router-dom";
import useDataUpload from "../_utils/DataContex";

const ActionSection = ({
    actionUpload,
    actionProcess,
    getFile
}) => {
    const { dataRead, loadingUpload, loadingSubmit, isUpload, errorRead, errorUnRead } = useDataUpload();
    return (
        <>
            <Segment>
                <Link to={"/absensi-deliveryman?activeTab=Deliman"}>
                    <Button
                        variant="secondary"
                        size="medium"
                        type={"button"}
                        mr={16}
                    >
                        Batal
                    </Button>
                </Link>
                {
                    (isUpload && loadingUpload !== "rejected") ?
                        <Button
                            variant="primary"
                            size="medium"
                            type={"button"}
                            onClick={actionProcess}
                            disabled={loadingSubmit === "pending" || dataRead?.totalElements === 0 || loadingUpload !== "resolved" || errorRead || errorUnRead}
                        >
                            Proses
                        </Button> :
                        <Button
                            variant="primary"
                            size="medium"
                            type={"button"}
                            onClick={actionUpload}
                            disabled={getFile === ""}
                        >
                            Upload
                        </Button>
                }

            </Segment>
        </>
    )
}

export default ActionSection