import React, { useEffect } from "react"
import { Segment, Spinner, Text } from "@elevenia/master-ui/components/Atom"
import { fetcher } from "helper"
import useSWR from "swr"
const FormListOrder = ({deliman, setValue}) => {
    
    const optionsSwr = {
        revalidateOnFocus: false
    }

    const {data, error} = useSWR(deliman.value && `/order/api/back-office/release-location/orders?deliverymanId=${deliman.value}`, fetcher, optionsSwr )
    useEffect(()=>{
        if(data){
            if(data.data.content.length > 0){
                setValue(prev=>({
                    ...prev,
                    orderList: data.data.content
                }))
            }else{
                setValue(prev=>({
                    ...prev,
                    orderList: []
                }))
            }
        }
    },[data, setValue])
    if(!data && !error) return <Segment className="u-tx-center" width={30} height={30}>
                                    <Spinner />
                                </Segment>
    else if (!data || data?.data.content.length === 0 || !deliman) return <>-</>
    return data?.data.content.length > 0 && data?.data.content.map((val,index)=>(<Text key={index} mb={12}>
        {val.orderId}
    </Text>))
}

export default FormListOrder