import { Icon, Segment, Tooltip, TooltipContent, TooltipTarget } from '@elevenia/master-ui/components/Atom';
import TooltipContentCustom from 'component/Tooltip';
import useFetchSWR from 'hooks/useFetchSWR';
import React from 'react';

const optionsSwr = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnMount: true
};

const ShiftTooltip = () => {
    const { data: shift } = useFetchSWR({
        key: 'driver/api/back-office/absensi/shift',
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data?.content?.map((shift) => ({
                label: shift.value,
                value: shift.value,
                description: shift.description
            }))
        })
    });
    const shiftDetail = shift?.data;

    return (
        <Tooltip
            placement="right"
            color="dark"
            type="hover"
        >
            <TooltipTarget>
                <Icon name="information" size="16px" color="#9C9DA6" />
            </TooltipTarget>
            <TooltipContent
                className="tooltip-absensi-deliman"
                style={{ width: '100%' }}
            >
                <TooltipContentCustom
                    name="tooltip-absensi-deliman"
                    containerStyle={{
                        marginLeft: '8px',
                        marginTop: '24px'
                    }}
                    arrowStyle={{
                        marginTop: '-26px'
                    }}
                >
                    {shiftDetail?.map((shift, index) => (
                        <Segment key={index} display="flex" style={{ marginBottom: '4px' }}>
                            <span style={{ width: '12px' }}>{shift.label}</span>
                            <span style={{ marginRight: '4px' }}>:</span>
                            <span>{shift.description}</span>
                        </Segment>
                    ))}
                </TooltipContentCustom>
            </TooltipContent>
        </Tooltip>
    )
};

export default ShiftTooltip;
