import React, { Fragment } from 'react'
import { Segment, Text } from '@elevenia/master-ui/components/Atom'
import logo from 'assets/image/indopaket-logo.png'

const Layout = props => {
    document.title = props.title
    return (
        <Fragment>
            <Segment
                boxShadow
                bg='white'
                borderRadius={4}
                justifyContent="center"
                alignItems="center"
                maxWidth={480}
                minHeight={230}
                mx={"auto"}
                className="fadeInUp"
                style={{position: 'relative', overflow: 'hidden' }}
                p={40}
            >
                <Segment style={{ textAlign: 'center' }} width={"100%"}>
                    <img alt="text" style={{ width: 185, margin: 'auto', maxWidth: '100%' }} src={logo} />
                    <Segment name="just separator" mt={32} mb={32} height={1} bg="#DCDEE3"/>
                    <Text className="font-nunito" fontSize={20} fontWeight="bold">Hello, Welcome To Backoffice!</Text>
                </Segment>
            </Segment>
        </Fragment>
    )
}

export default Layout