import {
    Button,
    ButtonGroup, Segment,
    Text,
    Badge
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { useAction } from 'hooks'
import moment from 'moment'
import Error404 from 'page/errorLayout'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { requestAddSupervisorDelivery } from 'store/actions/supervisor-delivery'
import useSWR from 'swr'
import { fetcher, statusColor } from 'helper'
import { Services } from 'service'

const Detail = props => {
    document.title = props.title
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const history = props.history
    
    const title = id ? 'Detail Supervisor Delivery' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/supervisor-delivery', label: 'Supervisor Delivery' },
        { to: '', label: title }
    ]
    const optionsSwr = {
        revalidateOnFocus:false
    }

    const { data: dataDetail , error, mutate } = useSWR((id && id !=='preview' && props.access?.read) &&  `/user/api/supervisor/${id}`,fetcher,optionsSwr)
    
    const payload = useSelector(() => {
        return {
            detailData: dataDetail?.data,
            listCoodelId: id ? dataDetail?.data.cordinatorDelivery : props.location?.state?.listCoodelId,
            loading: !dataDetail && !error,
            previewData: props.location?.state
        }
    })

    const [loadingDelete, setLoadingDelete] = useState('idle')
    const handleActionYes = async () => {
        if (id === undefined) {
            let idCoorDel = []
            
            
            payload.previewData.listCoodelId.forEach(element => {
                idCoorDel.push(element.nik)
            });

            const dataInsert = {
                status: payload.previewData.status,
                coordinatorDeliveryNik: idCoorDel,
                name: payload.previewData.name,
                phone: payload.previewData.phone,
                email: payload.previewData.email,
                nik: payload.previewData.nik
                
                
                
            }

            //console.log(dataInsert)
            
            hasFetch(requestAddSupervisorDelivery(dataInsert, history))
        }else{
            setLoadingDelete('pending')
            try {
                await Services().delete(`/user/api/supervisor/delete/${id}`)    
                setLoadingDelete('resolved')
                mutate()
            } catch (error) {
                const { message } = error
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                setLoadingDelete('rejected')
            }
            
        }
        setIsModal(!isModal)
    }
    const pallet = payload.detailData?.deleted ? statusColor('deleted') : null
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between"  alignItems="start">
                            <Text fontWeight={600} mb={32}>Informasi Supervisor Delivery</Text>
                            {payload.detailData?.deleted && pallet !== null && <Badge
                                style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                bg={pallet.bg}
                                color={pallet.color}
                                fontSize={12}
                                p={8}
                            >
                                {pallet.text}
                            </Badge>}
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? 
                                payload.detailData?.name ? payload.detailData?.name : '-' 
                                : 
                                payload.previewData?.name ? payload.previewData?.name : '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>NIK Supervisor</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.nik : payload.previewData?.nik}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Email</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.email : payload.previewData?.email}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nomor Handphone</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.phone : payload.previewData?.phone}
                            </Segment>
                        </Segment>
                        
                        
                        <Segment display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.status==='active' ? 'Aktif' : 'Non-Aktif' 
                                    : payload.previewData?.status==='active' ? 'Aktif' : 'Non-Aktif'}
                            </Segment>
                        </Segment>
                    </Segment>
                    {id &&
                        <Segment>
                            <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                                </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.lastModifiedBy} | {payload.detailData?.lastModifiedDate ? moment(payload.detailData?.lastModifiedDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                    <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                    <Segment justifyContent="space-between" mb={24}>
                        <Text fontWeight={600} >Koordinator Delivery</Text>
                        {
                            payload?.listCoodelId?.length > 0 && 
                            <Segment alignItems="flex-end">
                                
                                <Text color="#70727D"> {payload.listCoodelId.length} Koordinator Delivery</Text>
                            </Segment>
                        }
                        
                    </Segment>
                    <Segment>
                        <DataTable
                            countingList={true}
                            //defaultSize={10}
                            tableConsume={[{
                                field: 'NIK',
                                rowField: 'nik',
                                
                            }, {
                                field: 'Nama',
                                rowField: 'name'
                            }, {
                                field: 'Klaster',
                                
                                isCustomRow: (id, entity) => {
                                    return `${entity.clusterTotal} Klaster` 
                                }
                            }]}
                            dataConsume={payload.listCoodelId}
                        />
                    </Segment>
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            !payload.detailData?.deleted && <>
                                <Button variant={"secondary"} onClick={() => setIsModal(!isModal)}>Hapus</Button>
                                <Link to={`/supervisor-delivery/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={{ pathname: "/supervisor-delivery/create", state: props.history?.location?.state }}>
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>
                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Supervisor Delivery?" : "Buat Supervisor Delivery Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Informasi ini akan dihapus dari sistem."
                        :
                        "Semua informasi Supervisor Delivery yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button onClick={handleActionYes} disabled={loadingDelete==="pending"} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                {id ? loadingDelete==="pending" ? "Loading.." : "Hapus" : "Buat"}
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Detail