import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
} from '@elevenia/master-ui/components/Atom'
import { Tabs } from '@elevenia/master-ui/components/Molecules'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import { useAction } from 'hooks';
import { requestClusterById, requestAddCluster } from 'store/actions/cluster'
import { useSelector } from 'react-redux';
import moment from 'moment'
import Error404 from 'page/errorLayout'

const KlasterDetail = props => {
    document.title = props.title
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const history = props.history
    const [activeTab, setActiveTab] = useState("daftarToko");

    const title = id ? 'Detail Klaster' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/klaster', label: 'Klaster' },
        { to: '', label: title }
    ]
    const payload = useSelector(state => {
        return {
            detailData: state.cluster.detailData,
            listStoreById: id ? state.cluster.listStoreById : props.location?.state?.listStoreId,
            loadingStore: state.cluster.loadingStore,
            listDelimanById: id ? state.cluster.listDelimanById : props.location?.state?.listDriverId,
            loadingDeliman: state.cluster.loadingDeliman,
            previewData: props.location?.state
        }
    })
    
    useEffect(() => {
        (id !== undefined && id !== 'preview') && props.access?.read &&  hasFetch(requestClusterById(id))
    }, [hasFetch, id, props.access])

    const handleActionYes = () => {
        if (id === undefined) {
            let idDrivers = []
            let idStores = []
            payload.previewData.listStoreId.forEach(element => {
                idStores.push(element.id)
            });
            payload.previewData.listDriverId.forEach(element => {
                idDrivers.push(element.id)
            });

            const dataInsert = {
                active: payload.previewData.active,
                clusterCode: payload.previewData.clusterCode,
                clusterName: payload.previewData.clusterName,
                listDriverId: idDrivers,
                listStoreId: idStores,
                postalCodeId:payload.previewData.postalCodeId,
                coordinatorId:payload.previewData.coordinatorId
            }
            
            hasFetch(requestAddCluster(dataInsert, history))
        }
        setIsModal(!isModal)
    }

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi Klaster</Text>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Wilayah</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? 
                                payload.detailData?.postalCodeDescription ? payload.detailData?.postalCodeDescription : '-' 
                                : 
                                payload.previewData?.postalCodeDescription ? payload.previewData?.postalCodeDescription : '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kode Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.clusterCode : payload.previewData?.clusterCode}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.clusterName : payload.previewData?.clusterName}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Koordinator Delivery</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ?  payload.detailData?.coordinatorId > 0 ? `${payload.detailData?.coordinatorNik} - ${payload.detailData?.coordinatorName}` : '-' : payload.previewData?.coordinatorDescription}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Toko</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.totalStore : payload.previewData?.listStoreId.length} Toko
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Delivery Man</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.totalDriver : payload.previewData?.listDriverId.length} Delivery Man
                            </Segment>
                        </Segment>
                        <Segment display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.active ? 'Aktif' : 'Non-Aktif' 
                                    : payload.previewData?.active ? 'Aktif' : 'Non-Aktif'}
                            </Segment>
                        </Segment>
                    </Segment>
                    {id &&
                        <Segment>
                            <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.modifiedBy} | {payload.detailData?.modifiedDate ? moment(payload.detailData?.modifiedDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                </Segment>
                <Segment mt={32}>
                    <Tabs active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="daftarToko">Daftar Toko</Text>
                        <Text key="delivMan">Delivery Man</Text>
                    </Tabs>
                    <Segment mt={24}>
                        {activeTab === "daftarToko"
                            && <DataTable
                                countingList={true}
                                //defaultSize={10}
                                tableConsume={[{
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link to={`/toko/${entity.id}`}>{entity.storeCode}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Nama Toko',
                                    rowField: 'storeName'
                                }, {
                                    field: 'Alamat Toko',
                                    rowField: 'address'
                                }]}
                                dataConsume={payload.listStoreById}
                                isLoading={payload.loadingStore}
                            />}

                        {activeTab === "delivMan"
                            && <DataTable
                                countingList={true}
                                // defaultSize={10}
                                tableConsume={[{
                                    field: 'NIK Delivery Man',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link to={`/delivery-man/${entity.id}`}>{entity.nik}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Nama',
                                    rowField: 'name'
                                }, {
                                    field: 'Kontak Delivery Man',
                                    rowField: 'phone'
                                }, {
                                    field: 'Tipe Pegawai',
                                    rowField: 'employmentType'
                                }]}
                                dataConsume={payload.listDelimanById}
                                isLoading={payload.loadingDeliman}
                            />}
                    </Segment>
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            <>
                                {/* <Button variant={"secondary"} onClick={() => setIsModal(!isModal)}>Hapus</Button> */}
                                <Link to={`/klaster/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={{ pathname: "/klaster/create", state: props.history?.location?.state }}>
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>
                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Klaster?" : "Buat Klaster Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Semua informasi mengenai Klaster ini akan dihapus dari sistem."
                        :
                        "Semua informasi Klaster yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button onClick={handleActionYes} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                Buat
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default KlasterDetail