import { Button, ButtonLink, FormControl, Icon, OptionBox, Row, Segment, Text, Textfield } from '@elevenia/master-ui/components/Atom';
import React, { useState, useEffect } from 'react';
import Breadcrumb from 'component/Breadcrumb';
import { useParams } from 'react-router-dom';
import Error404 from 'page/errorLayout';
import useFetchSWR from 'hooks/useFetchSWR';
import { useAction, useInput } from 'hooks';
import { parseValue, validateForm } from 'helper';
import { deleteFn, updateAbsensiFn, updateIzinAbsensiFn } from './_utils';
import moment from 'moment';
import ModalMedium from 'component/ModalCustom/modalMedium';
import DataTable from 'component/DataTable';
import ShiftTooltip from './_component/ShiftTooltip';
import { ModalConfirm, useModalConfirm } from './_component/ModalConfirm';
import { Link } from 'react-router-dom';

const dataBreadcrumb = [
    { to: '/absensi-deliveryman', label: 'Pengaturan Absensi Delivery Man' },
    { to: '', label: 'Ubah Jadwal' }
];

moment.locale('id');
const monthOption = moment.months().map((month, index) => ({
    label: month,
    value: index + 1
}));

const optionsSwr = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnMount: true
};

const SHIFT_LIBUR = 0;
const isLibur = (shift) => shift === SHIFT_LIBUR;

const UbahJadwal = ({ access: permissionAccess }) => {
    const { hasFetch } = useAction();
    const today = new Date();
    const currentYear = today.getFullYear();
    const [month, setMonth] = useState(today.getMonth() + 1);   // + 1 because getMonth() start at 0
    const [year, setYear] = useState(currentYear);
    const [modalUbahJadwal, setModalUbahJadwal] = useState(null);
    const [modalUbahIzin, setModalUbahIzin] = useState(null);
    const { id } = useParams();

    const yearList = [currentYear - 1, currentYear, currentYear + 1];
    const yearOption = yearList.map((year) => ({
        label: year,
        value: year
    }));

    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        sort: 'absensiDate,asc',
        month,
        year
    });

    const { data: driver } = useFetchSWR({
        key: `/driver/api/back-office/absensi/${id}/name`,
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data
        })
    });

    const { data: shift } = useFetchSWR({
        key: 'driver/api/back-office/absensi/shift',
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data?.content?.map((shift) => ({
                label: shift.value,
                value: shift.value
            }))
        })
    });
    const shiftOption = shift?.data ?? [];

    const { data: izin } = useFetchSWR({
        key: 'driver/api/back-office/absensi/izin',
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data?.content?.map((izin) => ({
                label: izin.name,
                value: izin.id
            }))
        })
    });
    const izinOption = izin?.data ?? [];

    const { data, loading, refetch: refetchList, error } = useFetchSWR({
        key: (isTable && permissionAccess?.read) && [`/driver/api/back-office/absensi/${id}`, isTable],
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data.content,
            totalPages: data?.data.totalPages,
            totalElements: data?.data.totalElements
        })
    });

    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Terjadi kesalahan' } })
        }
    }, [hasFetch, error])

    const payload = {
        data: data?.data,
        loading: loading,
        totalPages: data?.totalPages
    };

    const modalDelete = useModalConfirm();

    const handleDelete = async (idJadwal) => {
        modalDelete.setIsLoading(true);
        try {
            await deleteFn({ id: idJadwal });
            modalDelete.setIsLoading(false);
            modalDelete.hide();
            refetchList();
            hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal berhasil dihapus' } });
        } catch (e) {
            modalDelete.setIsLoading(false);
            modalDelete.hide();
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message ? e.message : 'Gagal menghapus' } })
        }
    };

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Ubah Jadwal</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={24}>
                <Text fontWeight={600} mb={32}>Informasi Delivery Man</Text>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={280}>
                        <Text color={'#70727D'}>Delivery Man</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {driver?.data ? `${driver?.data?.deliveryManNik} - ${driver?.data?.deliveryManName}` : '-'}
                    </Segment>
                </Segment>
            </Segment>
            <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={24}>
                <Text fontWeight={600} mb={32}>Informasi Jadwal</Text>
                <Segment alignItems={"start"} mb={20}>
                    <Segment maxWidth={160} width={"100%"}>
                        <FormControl>
                            <OptionBox
                                options={monthOption}
                                placeholder={'Pilih Bulan'}
                                value={monthOption?.find(
                                    (val) => val.value === month
                                )}
                                onChange={(e) => {
                                    let value = '';
                                    if (e) value = e.value;
                                    setMonth(value);
                                    setTable((prev) => ({
                                        ...prev,
                                        month: value
                                    }));
                                }}
                            />
                        </FormControl>
                    </Segment>
                    <Segment maxWidth={160} width={"100%"} pl={20}>
                        <FormControl>
                            <OptionBox
                                options={yearOption}
                                value={yearOption?.find(
                                    (val) => val.value === year
                                )}
                                defaultValue={yearOption?.find(
                                    (val) => val.value === currentYear
                                )}
                                onChange={(e) => {
                                    let value = '';
                                    if (e) value = e.value;
                                    setYear(value);
                                    // refsInput.current.value = '';
                                    setTable((prev) => ({
                                        ...prev,
                                        year: value,
                                    }));
                                }}
                            />
                        </FormControl>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loading}
                    defaultSize={isTable.size}
                    totalPages={payload.totalPages}
                    defaultSortActive={isTable.sort}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            entityFilters: 'absensiDate',
                            sortField: 'absensiDate',
                            isCustomRow: (value) => parseValue(value, (val) => moment(val).format('DD/MM/yyyy'))
                        }, {
                            field: "Shift",
                            entityFilters: 'shift',
                            isAddOnHeader: () => <ShiftTooltip />,
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Kode Toko Clock In",
                            entityFilters: 'clockInStoreCode',
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Kode Toko Clock Out",
                            entityFilters: 'clockOutStoreCode',
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Jenis Izin",
                            entityFilters: 'izinName',
                            isCustomRow: (value, entity) => {
                                const beforeToday = new Date(entity.absensiDate) < today.setHours(0, 0, 0, 0);
                                const isDisabled = beforeToday || isLibur(entity.shift);

                                const disabledState = isDisabled && {
                                    onClick: null,
                                    style: { cursor: 'unset' }
                                };

                                return (
                                    <Segment alignItems="center">
                                        <span>{value ?? '-'}</span>
                                        <ButtonLink
                                            ml={12}
                                            onClick={() => setModalUbahIzin(entity)}
                                            {...disabledState}
                                        >
                                            <Icon
                                                name={"edit"}
                                                size={20}
                                                fillColor={isDisabled ? "#aaaaaa" : "#1178D4"}
                                            />
                                        </ButtonLink>
                                    </Segment>
                                )
                            }
                        }, {
                            field: "Terakhir Diperbarui",
                            entityFilters: 'modifiedDate',
                            isCustomRow: (value) => parseValue(value, (val) => moment(val).format('DD/MM/yyyy'))
                        }, {
                            field: "Diperbarui Oleh",
                            entityFilters: 'modifiedBy',
                            isCustomRow: (value) => parseValue(value)
                        }, {
                            field: "Action",
                            isCustomRow: (_, entity) => {
                                const isDisabled = new Date(entity.absensiDate) < today.setHours(0, 0, 0, 0) || !entity.allowModified;

                                const content = isLibur(entity.shift)
                                    ? `Jadwal untuk tanggal ${moment(entity.absensiDate).format("DD/MM/YYYY")}, Shift ${entity.shift} akan dihapus.`
                                    : `Jadwal untuk tanggal ${moment(entity.absensiDate).format("DD/MM/YYYY")}, Shift ${entity.shift}, dengan kode toko clock in (${entity.clockInStoreCode}) dan kode toko clock out (${entity.clockOutStoreCode}) akan dihapus.`

                                const disabledState = isDisabled && {
                                    onClick: null,
                                    style: { cursor: 'unset' }
                                };

                                return (
                                    <>
                                        <ButtonLink
                                            mr={8}
                                            onClick={() => setModalUbahJadwal({
                                                ...entity,
                                                deliveryManNik: driver?.data?.deliveryManNik,
                                                deliveryManName: driver?.data?.deliveryManName
                                            })}
                                            {...disabledState}
                                        >
                                            <Icon name={"edit"} size={20} fillColor={isDisabled ? "#aaaaaa" : "#1178D4"} />
                                        </ButtonLink>
                                        <ButtonLink
                                            onClick={() => modalDelete.show({
                                                title: 'Hapus Jadwal?',
                                                content: content,
                                                confirmText: 'Hapus',
                                                onSubmit: () => handleDelete(entity.id)
                                            })}
                                            {...disabledState}
                                        >
                                            <Icon name={"delete"} size={20} fillColor={isDisabled ? "#aaaaaa" : "#70727D"} />
                                        </ButtonLink >
                                    </>
                                );
                            }
                        }
                    ]}
                    dataConsume={payload.data}
                    emptyStateStyle={{ height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    showSize={true}
                    showPagination={true}
                    callSorted={(name, direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            sort: `${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => ({
                        ...prev,
                        size: size.value
                    }))}
                    createPaginations={(page) => setTable(prev => ({
                        ...prev,
                        page: page.page
                    }))}
                />
            </Segment >
            <Segment display="flex" justifyContent="end">
                <Link to="/absensi-deliveryman">
                    <Button variant="secondary">
                        Kembali
                    </Button>
                </Link>
            </Segment>
            <ModalConfirm
                config={modalDelete.config}
                toggle={modalDelete.hide}
            />
            <ModalUbahJadwal
                permissionAccess={permissionAccess}
                isOpen={modalUbahJadwal}
                toggle={() => setModalUbahJadwal(null)}
                refetchList={refetchList}
                shiftOption={shiftOption}
            />
            <ModalUbahIzin
                permissionAccess={permissionAccess}
                isOpen={modalUbahIzin}
                toggle={() => setModalUbahIzin(null)}
                refetchList={refetchList}
                izinOption={izinOption}
            />
        </>
    );
};

const ModalUbahJadwal = ({ permissionAccess, isOpen, toggle, refetchList, shiftOption }) => {
    const { hasFetch } = useAction();
    const [isValid, setIsValid] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const data = isOpen;

    const { value, bindChange, bindSelect, setValue } = useInput({
        initialObjects: {
            clockOutStoreCode: data?.clockOutStoreCode ?? '',
            clockInStoreCode: data?.clockInStoreCode ?? '',
            shift: data?.shift ?? ''
        }
    });

    // set form's default value
    useEffect(() => {
        if (data) {
            const [clockOutStoreCode] = data.clockOutStoreCode?.split(" - ") ?? [''];
            const [clockInStoreCode] = data.clockInStoreCode?.split(" - ") ?? [''];
            const shift = {
                label: data.shift,
                value: data.shift
            };
            setValue(prev => ({ ...prev, clockOutStoreCode, clockInStoreCode, shift }));
        };
    }, [data, setValue]);

    // validate form on change
    useEffect(() => {
        if (validateForm('formUbahJadwal')) {
            setIsValid(true);
        } else {
            setIsValid(false);
        };
    }, [value]);

    const handleSubmit = async () => {
        if (validateForm('formUbahJadwal')) {
            setLoadingSubmit(true);
            const payload = {
                id: data?.id,
                clockInStoreCode: value.clockInStoreCode,
                clockOutStoreCode: value.clockOutStoreCode,
                shift: value.shift.value
            };
            try {
                await updateAbsensiFn(payload);
                hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jadwal berhasil diubah' } });
                setLoadingSubmit(false);
                toggle();
                refetchList();
            } catch (error) {
                setLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } });
            };
        };
    };

    const delimanLibur = isLibur(value.shift.value);

    // clear store codes on choose shift libur
    useEffect(() => {
        if (delimanLibur) {
            setValue((prev) => ({
                ...prev,
                clockOutStoreCode: '',
                clockInStoreCode: ''
            }));
        };
    }, [delimanLibur, setValue]);

    if (permissionAccess?.update === false || permissionAccess?.create === false) return <Error404 />;
    return (
        <ModalMedium
            isOpen={Boolean(isOpen?.id)}
            content={
                <Segment>
                    <Segment>
                        <Text fontWeight={600} mb={32}>Jadwal Saat Ini</Text>
                        <ModalDetailRow
                            name="Delivery Man"
                            value={(data?.deliveryManNik && data?.deliveryManName) && `${data?.deliveryManNik} - ${data?.deliveryManName}`}
                        />
                        <ModalDetailRow
                            name="Tanggal"
                            value={data?.absensiDate}
                            format={(val) => `${moment(val).format("DD/MM/YYYY")}`}
                        />
                        <ModalDetailRow
                            name="Kode Toko Clock In"
                            value={data?.clockInStoreCode}
                        />
                        <ModalDetailRow
                            name="Kode Toko Clock Out"
                            value={data?.clockOutStoreCode}
                        />
                        <ModalDetailRow
                            name="Shift"
                            value={data?.shift}
                        />
                    </Segment>
                    <hr color="#DCDEE3" style={{ height: '1px', marginBottom: '24px' }} />
                    <form id="formUbahJadwal">
                        <Segment>
                            <Text fontWeight={600} mb={32}>Ubah Jadwal</Text>
                            <ModalInputRow
                                bind={bindChange}
                                label="Kode Toko Clock In"
                                name="clockInStoreCode"
                                value={value.clockInStoreCode}
                                disabled={delimanLibur}
                            />
                            <ModalInputRow
                                bind={bindChange}
                                label="Kode Toko Clock Out"
                                name="clockOutStoreCode"
                                value={value.clockOutStoreCode}
                                disabled={delimanLibur}
                            />
                            <ModalInputRow
                                type='option'
                                bind={bindSelect}
                                label="Shift"
                                name="shift"
                                value={value.shift}
                                options={shiftOption}
                                loading={shiftOption.length === 0}
                                setValue={setValue}
                            />
                        </Segment>
                        <Segment mt={32} justifyContent={"flex-end"}>
                            <Segment>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                    onClick={toggle}

                                >
                                    Batal
                                </Button>
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={handleSubmit}
                                    disabled={loadingSubmit || !isValid}
                                >
                                    Simpan Perubahan
                                </Button>
                            </Segment>
                        </Segment>
                    </form>
                </Segment>
            }
        />
    );
};

const ModalUbahIzin = ({ permissionAccess, isOpen, toggle, refetchList, izinOption }) => {
    const [isValid, setIsValid] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { hasFetch } = useAction();

    const { value, setValue, bindSelect } = useInput({
        initialObjects: {
            izinName: ''
        }
    });

    // set form's default value
    useEffect(() => {
        if (isOpen && izinOption) {
            const izinName = {
                label: isOpen.izinName ? isOpen.izinName : izinOption[0].label,
                value: isOpen.izinName ? isOpen.izinId : izinOption[0].value
            };
            setValue(prev => ({ ...prev, izinName }));
        };
    }, [isOpen, izinOption, setValue]);

    // validate form on change
    useEffect(() => {
        if (validateForm('formUbahIzin')) {
            setIsValid(true);
        } else {
            setIsValid(false);
        };
    }, [value]);

    const handleSubmit = async () => {
        if (validateForm('formUbahIzin')) {
            setLoadingSubmit(true);
            const payload = {
                id: isOpen?.id,
                izinId: value.izinName.value
            };
            try {
                await updateIzinAbsensiFn(payload);
                hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Jenis izin berhasil diubah' } });
                setLoadingSubmit(false);
                toggle();
                refetchList();
            } catch (error) {
                setLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Terjadi kesalahan' } });
            };
        };
    };

    if (permissionAccess?.update === false || permissionAccess?.create === false) return <Error404 />;
    return (
        <ModalMedium
            isOpen={Boolean(isOpen)}
            content={
                <Segment>
                    <Segment>
                        <Text fontWeight={600} mb={32}>Jenis Izin Saat Ini</Text>
                        <ModalDetailRow
                            name="Jenis Izin"
                            value={isOpen?.izinName}
                        />
                        <ModalDetailRow
                            name="Tanggal"
                            value={isOpen?.absensiDate}
                            format={(val) => `${moment(val).format("DD/MM/YYYY")}`}
                        />
                    </Segment>
                    <hr color="#DCDEE3" style={{ height: '1px', marginBottom: '24px' }} />
                    <form id="formUbahIzin">
                        <Segment>
                            <Text fontWeight={600} mb={32}>Ubah Jenis Izin</Text>
                            <ModalInputRow
                                type='option'
                                bind={bindSelect}
                                label="Jenis Izin"
                                name="izinName"
                                value={value.izinName}
                                options={izinOption}
                                loading={izinOption.length === 0}
                                setValue={setValue}
                            />
                        </Segment>
                        <Segment mt={32} justifyContent={"flex-end"}>
                            <Segment>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                    onClick={toggle}

                                >
                                    Batal
                                </Button>
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={handleSubmit}
                                    disabled={loadingSubmit || !isValid}
                                >
                                    Simpan Perubahan
                                </Button>
                            </Segment>
                        </Segment>
                    </form>
                </Segment>
            }
        />
    );
};

const ModalDetailRow = ({ name, value, format }) => (
    <Segment mb={24} display="flex" className="xs-flex-wrap">
        <Segment minWidth={280}>
            <Text color={'#70727D'}>{name}</Text>
        </Segment>
        <Segment display="flex">
            <Text mr={8}>:</Text>
            {parseValue(value, format)}
        </Segment>
    </Segment>
);

const ModalInputRow = ({ type = 'text', label, name, value, bind, options, loading, setValue, disabled = false, ...props }) => (
    <Row mb={16}>
        <Segment width={280} py={8}>
            <Text>{label}</Text>
        </Segment>
        <Segment flex={1}>
            {type === 'option' && (
                <FormControl>
                    <OptionBox
                        {...bind}
                        options={options}
                        name={name}
                        disabled={loading}
                        placeholder={`Pilih ${label}`}
                        inputClassName={
                            'validate[required]'
                        }
                        value={value}
                        onChange={(e) => setValue((prev) => ({
                            ...prev,
                            [name]: e
                        }))}
                        {...props}
                    />
                </FormControl>)}
            {type === 'text' && (
                <FormControl>
                    <Textfield
                        inputProps={{
                            ...bind,
                            placeholder: label,
                            name,
                            value,
                            ...props
                        }}
                        disabled={disabled}
                    />
                </FormControl>)}
        </Segment>
    </Row>
);

export default UbahJadwal;
