export const requestDeliverymanCadangan = properties => {
  return {
      type: "REQUEST_DELIVERY_MAN_CADANGAN",
      payload: { properties }
  }
}


export const requestDeliverymanCadanganById = id => {
  return {
      type: "REQUEST_DELIVERY_MAN_CADANGAN_BY_ID",
      payload: id
  }
}

export const requestValidateDelimanCadangan = (params) => {
  const dataSubmit = params.map(val=>{
    return{
      assignmentEndDate: val.assignmentEndDate,
      assignmentStartDate: val.assignmentStartDate,
      clusterId: val.clusterId,
      deliveryManId: val.deliveryManId,
      referenceId: val.referenceId
    }
  })
  return {
    type: "REQUEST_VALIDATE_DELIVERY_MAN_CADANGAN",
    payload:{
      params,
      dataSubmit
    }
}
}

export const requestAddDeliverymanCadangan = (params,history) => {
  
  const dataSubmit = params.map(val=>{
    return{
      assignmentEndDate: val.assignmentEndDate,
      assignmentStartDate: val.assignmentStartDate,
      clusterId: val.clusterId,
      deliveryManId: val.deliveryManId,
      referenceId: val.referenceId
    }
  })
  return {
      type: "REQUEST_CREATE_DELIVERY_MAN_CADANGAN",
      payload:{
        params,
        dataSubmit
      },
      history
  }
}

export const requestUpdateDeliverymanCadangan = (id,params, history)=> {
 
  return {
      type: "REQUEST_UPDATE_DELIVERY_MAN_CADANGAN",
      payload:{
        params,
        id
      },
      history
  }
}

export const requestCheckNik = (params)=> {
  return {
      type: "REQUEST_CHECK_NIK",
      payload:{
        params
      }
  }
}

export const requestCheckEmail = (params)=> {
  return {
      type: "REQUEST_CHECK_EMAIL",
      payload:{
        params
      }
  }
}

export const requestCheckPhone = (params)=> {
  return {
      type: "REQUEST_CHECK_PHONE",
      payload:{
        params
      }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS",
      payload:{
        field:name
      }
  }
}