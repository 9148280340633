import { Button, FormControl, OptionBox, Row, Segment, Text, Textfield, ButtonGroup } from '@elevenia/master-ui/components/Atom'
import Breadcumb from 'component/Breadcrumb'
import { useAction, useInput } from 'hooks'
import React, { useEffect, useState } from 'react'
import { fetcher, validateForm } from "helper";
import useSWR from "swr";
import { Services } from 'service';
import ModalConfirm from 'component/ModalCustom/modalSmall'
import Error404 from 'page/errorLayout'

const App = props => {
    document.title = props.title

    const {hasFetch} = useAction()
    const [isLoadingSubmit, setLoadingSubmit] = useState(false)
    const [isModalConfirm, setIsModalConfirm] = useState(false)

    const dataBreadcrumb = [
        { to: '', label: 'Konfigurasi Fallback' }
    ]

    const { value, bindChange, setValue } = useInput({
        initialObjects:{
            value: '',
            updateValue: '',
            parameter: ''
        }
    })

    const optionsSwr = {
        revalidateOnFocus: false,
    }

    const { data: listConfigFallback, mutate, error } = useSWR(`/order/api/back-office/order-fallback-configs`,fetcher,optionsSwr)

    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message?error.message : 'Not Found' } })
        }
    },[hasFetch, error])
    
    const onResetForm = () => {
        setValue(() => { 
            return {
                value: '',
                updateValue: '',
                parameter: ''
            }
        })
    }

    const handleUpdateValue = e => {
        const value = e?.target?.value
        const pattern = /^[0-9]+$/

        if (pattern.test(e.target.value) || value === ''){
            setValue(prev => {
                return{
                    ...prev,
                    updateValue: value
                }
            })
        }
    }

    const handleSubmit = async () => {
        if (validateForm('formKonfigurasiFallback')){
            setLoadingSubmit(true)
            const requestBody = {
                keyId: value?.parameter?.value,
                value: value?.updateValue
            }
            try {
                await Services().put(`/orderprocessor/api/back-office/order-fallback-configs`, requestBody)
                hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil memperbaharui data.' } })
                onResetForm()
                setLoadingSubmit(false)
                setIsModalConfirm(false)
                mutate()
            } catch (error) {
                setLoadingSubmit(false)
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Cannot update' } })
            }
        }
    }
    
    if(props?.access?.update===false) return <Error404/>
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>Konfigurasi Fallback</Text>
                <Breadcumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <form id="formKonfigurasiFallback" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                        <Segment mb={32}>
                            <Text fontWeight={600} mb={32}>Informasi Parameter</Text>
                        </Segment>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Parameter</Text>
                            </Segment>
                            <Segment flex={1} minWidth={204}>
                                <FormControl>
                                    <OptionBox
                                        name="parameter"
                                        defaultValue={""}
                                        value={value?.parameter}
                                        isClearable
                                        options={listConfigFallback && listConfigFallback?.data?.content.map(val=>({ value: val?.keyId, label: val?.description, currentValue: val.value }))}
                                        placeholder={'Pilih Parameter'}
                                        noOptionsMessage={() => 'Tidak Ada Data'}
                                        className='validate[required] validateMessage{required{Value harus diisi}}'
                                        onChange={(data)=>{
                                            setValue(prev=>({
                                                ...prev,
                                                parameter: data ? data : '',
                                                value: data ? data.currentValue : ''
                                            }))
                                        }}
                                    />
                                </FormControl>
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Value</Text>
                            </Segment>
                            <Segment flex={1}>
                                <FormControl>
                                    <Textfield
                                        inputProps={{
                                            ...bindChange,
                                            value: value.value
                                        }}     
                                        disabled={true}                               
                                    />
                                </FormControl>
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Update Value</Text>
                            </Segment>
                            <Segment flex={1}>
                                <FormControl>
                                    <Textfield
                                        inputProps={{
                                            onChange:(e)=>handleUpdateValue(e),
                                            name:'updateValue',
                                            placeholder: "Update Value",
                                            value: value.updateValue,
                                            maxLength: 100,
                                            className: 'validate[required]',
                                        }}                                    
                                    />
                                </FormControl>
                            </Segment>
                        </Row>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Button
                                variant="secondary"
                                size="medium"
                                type={"button"}
                                mr={16}
                                onClick={onResetForm}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="primary"
                                size="medium"
                                type={"button"}
                                onClick={() => setIsModalConfirm(true)}
                                disabled={isLoadingSubmit || Object.values(value).filter(val => val === "").length > 0}
                            >
                                Simpan
                            </Button>
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalConfirm}
                onClose={() => setIsModalConfirm(!isModalConfirm)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalConfirm(!isModalConfirm)}>
                                Batal
                            </Button>
                            <Button 
                                type="button"
                                onClick={handleSubmit}
                                minWidth={"100px"}
                            >
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default App