import React from 'react'
import {
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom'
import TableInfinitiedScroll from 'component/TableInfinitiedScroll'
const TableDashboard = ({
    title = "",
    countData = {},
    columns = [],
    loading,
    data
}) => {
    const getCountRow = (row) => {
        let rowCount = row ? row.length : 0;

        if (rowCount > 10) {
            return true
        } else {
            return false
        }
    }
    return (
        <>
            <Segment justifyContent="space-between" alignItems="flex-start" mb={16} className="xs-block">
                {title}
                <Segment py={5} px={8} bg={countData?.bgColor} borderRadius={4} display="inline-block">
                    <Text color="white">{`Total : ${countData?.text}`}</Text>
                </Segment>
            </Segment>
            
            <Segment className={getCountRow(data) ? 'scroll-table' : ''}>
            <TableInfinitiedScroll
                isLoading={loading}
                dataConsume={data}
                tableConsume={columns}
            />
            </Segment>
        </>
    )
}

export default TableDashboard;