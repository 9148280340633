import {
    Badge, Button, ButtonGroup, FormControl,
    Icon, OptionBox, Segment,
    Text, Textfield
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { fetcher, objectToQueryString, capitalize } from 'helper'
import Error404 from 'page/errorLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import useSWR from 'swr'
import { useAction } from "hooks"
import { saveAs } from "file-saver";
import moment from 'moment'
import DownloadExcel from 'helper/DownloadExcel'
import { Services } from 'service'
import { resetFrom } from 'store/actions/store'
import FilterChannel from "component/FilterChannel"
const dataBreadcrumb = [
    { to: '', label: 'Toko' }
]

const Toko = props => {
    document.title = props.title
    const IconXls = require("assets/image/icon-xls.svg");
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const [statusDownload, setStatusDownload] = useState("idle")
    const paramsSearch = new URLSearchParams(props.history.location.search).get("searchParam")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        searchParam: paramsSearch??"",
        sort: "storeName,asc",
        active:true
    });

    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data:dataStore, error } = useSWR((isTable && props.access?.read) &&  `/store/api/back-office/stores?${objectToQueryString(isTable)}`,fetcher,optionsSwr)

    const { data:dataFilterCluster, error: errorFilterCluster } = useSWR((props.access?.read) &&  `/store/api/back-office/clusters/dropdown`,fetcher,optionsSwr)

    useEffect(()=>{
        props.access?.read && hasFetch(resetFrom())
    },[hasFetch, props.access])

    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message?error.message : 'Not Found' } })
        }
    },[hasFetch, error])

    const payload = useSelector(state => {
        return {
            data: dataStore?.data.content,
            loading: !dataStore && !error , 
            totalPages: dataStore?.data.totalPages,
            statName: state.setAlertsToast.notify.statName,
            dropDownCluster: dataFilterCluster?.data.content.map(val=>({
                label:`${val.clusterCode}`,
                value:val.id,
            }))

        }
    })

    

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            page: 0,
            searchParam: value.trim()
        });
    }

    const listStatus = [
        { value: true, label: "Aktif" },
        { value: false, label: "Non-Aktif" },
    ];

    const StatusColor = (status) => {

        switch (status) {
            case true:
                return <Badge bg="#8FC742" style={{ textTransform: 'capitalize' }}>Aktif</Badge>
            case false:
                return <Badge bg="#EE2B2E" style={{ textTransform: 'capitalize' }}>Non-Aktif</Badge>
            default:
                break;
        }
    }

    const handleDonwloadExcel = async () => {

        setStatusDownload("pending")
        Services().get("/store/api/back-office/stores",{...isTable,unpaged: true})
        .then( async (data)=>{
            setStatusDownload("resolved")
            const contentRow = data?.data?.data.content?.map((value,key)=>{
                return {
                    ...value,
                    no: key + 1,
                    clusterCode: value.clusterCode??'-',
                    postalCodeDescription: value.postalCodeDescription??'-',
                    contact: value.contact??'-',
                    operationalHour: value.operationalHour??'-',
                    channel: value.channel? capitalize(value.channel) : '-',
                    latitude: value.latitude??'-',
                    longitude: value.longitude??'-',
                    deliveryMan: value.driverNikList?.length > 0 ? value?.driverNikList.join(",") : '-',
                    status: value.active ? 'Aktif' : 'Non-Aktif',

                }
            })
            
            const columns = [
                { header: "No", key: "no", width: 5 },
                { header: "Kode Toko", key: "storeCode", width: 19 },
                { header: "Nama Toko", key: "storeName", width: 19 },
                { header: "Wilayah", key: "postalCodeDescription", width: 45 },
                { header: "Alamat Toko", key: "address", width: 15 },
                { header: "Klaster", key: "clusterCode", width: 15 },
                { header: "Nama Cabang", key: "dcName", width: 15 },
                { header: "Kode Cabang", key: "dcCode", width: 15 },
                { header: "Kontak", key: "contact", width: 15 },
                { header: "Jam Operasional", key: "operationalHour", width: 15 },
                { header: "Channel", key: "channel", width: 15 },
                { header: "Koordinat Latitude", key: "latitude", width: 15 },
                { header: "Koordinat Longitude", key: "longitude", width: 15 },
                { header: "Delivery Man", key: "deliveryMan", width: 15 },              
                { header: "Status Toko", key: "status", width: 20 }
            ]
            const creator       = 'Ipp Application'
            const createdDate   = creator
            const modifiedDate  = new Date()
            const sheetName     = 'sheet 1'
            const fileName      = `Master Store ${moment(new Date()).format("YYYY-MM-DD")}`;
            const buffer        = await DownloadExcel({creator, createdDate, modifiedDate, sheetName, columns, contentRow});
            
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, fileName + fileExtension); 
        })
        .catch(e=>{
            setStatusDownload("rejected")
        })
        
        
        
    }

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    else if (errorFilterCluster || error ) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Toko</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Button disabled={statusDownload==="pending" ? true : false } onClick={()=>handleDonwloadExcel()} mr={16} variant="secondary">
                        <img src={IconXls} alt="xls" style={{marginRight: 8}}/>
                        Export XLS
                    </Button> 
                    <Link to={"/toko/create"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Nama Toko / Kode Toko/ Wilayah'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <FilterChannel 
                                handleChange={e=>{
                                    setTable(prev=>({
                                        ...prev,
                                        channel:e
                                    }))
                                }}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={""}
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value !== undefined) value = e.value
                                    setTable(prev => ({
                                        ...prev,
                                        ...isTable,
                                        page:0,
                                        clusterId: value
                                    }))

                                }}
                                options={payload.dropDownCluster}
                                placeholder={"Klaster"}
                                isClearable
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={listStatus.filter(val=>val.value===isTable.active)}
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value !== undefined) value = e.value
                                    setTable(prev => ({
                                        ...prev,
                                        ...isTable,
                                        active: value
                                    }))

                                }}
                                options={listStatus}
                                placeholder={"Status"}
                                isClearable
                            />
                        </FormControl>
                    </Segment>
                </Segment>
                <DataTable
                    defaultSortActive={isTable.sort}
                    countingList={true}
                    defaultSize={isTable.size}
                    tableConsume={[{
                        field: 'Kode Toko',
                        sortField: 'storeCode',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={`/toko/${entity.id}`}>{entity.storeCode}</Link>
                            )
                        }
                    }, {
                        field: 'Nama Toko',
                        rowField: 'storeName',
                        sortField: 'storeName',
                    }, {
                        field: 'Wilayah',
                        isCustomRow: (id, entity) => {
                            return entity.postalCodeDescription ?? '-'
                        },
                        sortField: 'postalCodeDescription',
                    }, {
                        field: 'Alamat Toko',
                        rowField: 'address',
                    }, {
                        field: 'Channel',
                        rowField: 'channel',
                        isCustomRow: (id, entity) => {
                            return (
                                entity.channel ? capitalize(entity.channel) : '-'
                            )
                        },
                    }, {
                        field: 'Klaster',
                        isCustomRow: (id, entity) => {
                            return entity.clusterCode ? <Link to={`/klaster?searchParam=${entity.clusterCode}`}>{entity.clusterCode}</Link> : '-'
                        },
                    }, {
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return entity.totalDriver > 0 ? entity.totalDriver : '-'
                        },
                    }, {
                        field: 'Status',
                        sortField: 'active',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{StatusColor(entity.active)}</>
                            )
                        },

                    }]}
                    dataConsume={payload?.data}
                    isLoading={payload?.loading}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload?.totalPages}
                    activePage={isTable?.page}
                    callSorted={(name, direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            sort: `${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            page:0,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...isTable,
                            ...page
                        }
                        setTable(query)
                    }}
                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Toko?"}
                content={<Segment py={8}>Semua informasi mengenai Toko ini akan dihapus dari sistem.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Toko