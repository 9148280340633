import React from 'react'
import {
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom'
const SourceOrder = ({
    data,
    textOrderStatus
}) => {
  return (
    <>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Toko Asal Pesanan</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>

                {
                    (data?.storeCode || data?.storeName)
                        ? `${data?.storeCode} - ${data?.storeName}`
                        : '-'
                }
            </Segment>

        </Segment>
        {
            ["ALLOCATING", "ALLOCATED"].includes(textOrderStatus) && 
            <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Toko Asal Delivery Man</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>
                    {
                        (data?.assignedStoreCode || data?.assignedStoreName) 
                        ? `${data?.assignedStoreCode} - ${data?.assignedStoreName}`
                        : '-'
                    }
                </Segment>
            </Segment>
        }
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Cabang</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>
                {
                    (data?.dcCode && data?.dcName) ?
                        `${data?.dcCode} - ${data?.dcName}`
                        : '-'
                }

            </Segment>
        </Segment>
        <Segment mb={24} display="flex" className="xs-flex-wrap">
            <Segment minWidth={280}>
                <Text color={'#70727D'}>Klaster</Text>
            </Segment>
            <Segment display="flex">
                <Text mr={8}>:</Text>

                {data?.clusterName ?? '-'}
            </Segment>
        </Segment>
        {
            textOrderStatus !== 'ALLOCATING'
            &&
            <>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={280}>
                        <Text color={'#70727D'}>Delivery Man</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>

                        {data?.driverName ?? '-'}
                    </Segment>
                </Segment>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={280}>
                        <Text color={'#70727D'}>Toko Asal Delivery Man</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {
                            (data?.assignedStoreCode || data?.assignedStoreName) 
                            ? `${data?.assignedStoreCode} - ${data?.assignedStoreName}`
                            : '-'
                        }

                    </Segment>
                </Segment>
            </>
        }
    </>

  )
}

export default SourceOrder