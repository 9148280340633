export const requestCoordinatorDeliman = properties => {
  return {
      type: "REQUEST_COORDINATOR_DELIMAN",
      payload: { properties }
  }
}


export const requestCoordinatorDelimanById = id => {
  return {
      type: "REQUEST_COORDINATOR_DELIMAN_BY_ID",
      payload: id
  }
}

export const requestAddCoordinatorDeliman = (params,history) => {
  return {
      type: "REQUEST_CREATE_COORDINATOR_DELIMAN",
      payload:{params},
      history
  }
}

export const requestUpdateCoordinatorDeliman = (id,params, history)=> {
  return {
      type: "REQUEST_UPDATE_COORDINATOR_DELIMAN",
      payload:{
        params,
        id
      },
      history
  }
}

export const requestCheckNik = (params)=> {
  return {
      type: "REQUEST_CHECK_NIK_DELIMAN",
      payload:{
        params
      }
  }
}

export const requestCheckEmail = (params)=> {
  return {
      type: "REQUEST_CHECK_EMAIL_DELIMAN",
      payload:{
        params
      }
  }
}

export const requestCheckPhone = (params)=> {
  return {
      type: "REQUEST_CHECK_PHONE_DELIMAN",
      payload:{
        params
      }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS_DELIMAN",
      payload:{
        field:name
      }
  }
}

export const setStatusField = (status) => {
  return {
      type: "SET_STATUS_FIELD_DELIMAN",
      payload:{
        status:status?'00':''
      }
  }
}