import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
} from '@elevenia/master-ui/components/Atom'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import { useAction } from 'hooks';
import { requestUserById, addNewUser, requestDeleteUser } from 'store/actions/users'
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/id';
import Error404 from 'page/errorLayout'
const UserDetail = props => {
    document.title = props.title
    const history = props.history
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const title = id ? 'Detail Pengguna' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/user', label: 'Pengguna' },
        { to: '', label: title }
    ]

    const payload = useSelector(state => {
        return {
            detailData: state.users.detailData,
            previewData: props.history?.location?.state
        }
    })


    useEffect(() => {
        (id) && hasFetch(requestUserById(id))
    }, [hasFetch, id])

    const handleActionYes = () => {
        if (!id) {
            const dataAddUser = {
                email: payload.previewData.email,
                fullname: payload.previewData.name,
                nik: payload.previewData.nik,
                roleId: payload.previewData.otorisasi,
                status: payload.previewData.status,
                coordinatorId: payload.previewData.coordinatorId
            }
            hasFetch(addNewUser(dataAddUser, history))
        } else {
            hasFetch(requestDeleteUser(id, history))
        }
        setIsModal(!isModal)
    }
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment mb={32}>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi Pengguna</Text>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>NIK</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.nik : payload.previewData?.nik}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.fullname : payload.previewData?.name}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Email</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.email : payload.previewData?.email}
                            </Segment>
                        </Segment>
                        {/* <Segment mb={24} display="flex">
                            <Segment minWidth={280}>
                                <Text>Kata Sandi</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id!=='preview' ? '********' : payload.previewData?.password}
                            </Segment>
                        </Segment> */}

                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>User Authority</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.roleName : payload.previewData?.textRole}
                            </Segment>
                        </Segment>
                        {
                            id &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Verifikasi</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.verified ? 'Terverifikasi' : 'Belum Verifikasi'}
                                </Segment>
                            </Segment>
                        }
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.status === 'active' ? 'Aktif' : 'Non-Aktif'
                                    : payload.previewData?.status === 'active' ? 'Aktif' : 'Non-Aktif'}
                            </Segment>
                        </Segment>
                    </Segment>
                    {id &&
                        <Segment borderTop={"1px solid #DCDEE3"} pt={32}>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.lastModifiedBy} | {payload.detailData?.lastModifiedDate ? moment(payload.detailData?.lastModifiedDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            <>
                                {/* <Button variant={"secondary"} onClick={() => setIsModal(!isModal)}>Hapus</Button> */}
                                <Link to={`/user/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={{ pathname: "/user/create", state: props.history?.location?.state }} >
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>
                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Pengguna?" : "Buat Pengguna Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Semua informasi mengenai Pengguna ini akan dihapus dari sistem."
                        :
                        "Semua informasi Pengguna yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button onClick={() => handleActionYes()} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                Buat
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default UserDetail