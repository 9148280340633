import {
    Badge, Button, ButtonGroup, FormControl,
    Icon, OptionBox, Segment,
    Text, Textfield
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { fetcher, objectToQueryString, statusColor } from 'helper'
import { useAction } from 'hooks'
import Error404 from 'page/errorLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import useSWR from 'swr'
import DownloadExcel from 'helper/DownloadExcel'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Services } from 'service'

const dataBreadcrumb = [
    { to: '', label: 'Manager' }
]

const Manager = props => {
    document.title = props.title
    const IconXls = require("assets/image/icon-xls.svg");
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const [statusDownload, setStatusDownload] = useState("idle")

    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        sort: "name,asc",
        status:"active",
    });

    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data:dataManager, error } = useSWR((isTable && props.access?.read) &&  `/user/api/manager?${objectToQueryString(isTable)}`,fetcher,optionsSwr)
    
    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message?error.message : 'Not Found' } })
        }
    },[hasFetch, error])
    
    const payload = useSelector(state => {
        return {
            data: dataManager?.data.result.content,
            loading: !dataManager && !error,
            totalPages: dataManager?.data.result.totalPages,
            statName: state.setAlertsToast.notify.statName,
            statuses:dataManager?.data?.status?.map(val=>({label:val==='active'?'Aktif':'Non-Aktif',value:val})),
        }
    })

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            page:0,
            keyword: value.trim()
        });
    }

    
    
    const listVerifikasi = [
        
        { value: "2", label: "Terverifikasi" },
        { value: "1", label: "Belum Terverifikasi" },
    ];


    const handleDownloadExcel = async () => {
        setStatusDownload("pending")
        Services().get('/user/api/manager',{...isTable, unpaged: true})
        .then( async (data)=>{ 
            setStatusDownload("resolved")
            const contentRow = data.data.data.result?.content?.map((value,key)=>{
                return {
                    ...value,
                    no: key + 1,
                    verified: value.verified ? 'Terverifikasi' : 'Belum Verifikasi',
                    status: value.status==='active' ? 'Aktif' : 'Non-Aktif',
                    supervisorTotal: value.supervisorTotal ? value.supervisorTotal : "-",
                    clusterTotal: value.clusterTotal ? value.clusterTotal : "-",
                    email: value.email ? value.email : "-",
                    phone: value.phone ? value.phone : "-",
                }
            })
            
            const columns = [
                { header: "No", key: "no", width: 5 },
                { header: "NIK Namager", key: "nik", width: 19 },
                { header: "Nama", key: "name", width: 19 },
                { header: "Email", key: "email", width: 19 },
                { header: "Nomor Handphone", key: "phone", width: 19 },
                { header: "Spv. Delivery", key: "supervisorTotal", width: 19 },
                { header: "Klaster", key: "clusterTotal", width: 10 },
                { header: "Verifikasi", key: "verified", width: 15 },
                { header: "Status", key: "status", width: 20 }
            ]
            // console.log({columns})
            const creator       = 'Ipp Application'
            const createdDate   = creator
            const modifiedDate  = new Date()
            const sheetName     = 'sheet 1'
            const fileName      = `Master Manager Delivery ${moment(new Date()).format("YYYY-MM-DD")}`;
            const buffer        = await DownloadExcel({creator, createdDate, modifiedDate, sheetName, columns, contentRow});
            
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, fileName + fileExtension); 
        })
        .catch((e)=>{
            setStatusDownload("rejected")
        })
        
        
        
    }

    const listStatus = payload.statuses;
    
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Manager</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Button disabled={statusDownload==="pending" ? true : false} onClick={()=>handleDownloadExcel()} mr={16} variant="secondary">
                        <img src={IconXls} alt="xls" style={{marginRight: 8}}/>
                        Export XLS
                    </Button>
                    <Link to={"/manager/create"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Nama atau NIK'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <OptionBox
                                defaultValue={""}
                                options={listVerifikasi}
                                placeholder={"Verifikasi"}
                                isClearable
                                onChange={(e)=>{
                                    let value = ''
                                    if(e && e.value==='1') value=false
                                    if(e && e.value==='2') value=true
                                    setTable(prev=>({
                                        ...prev,
                                        ...isTable,
                                        verified:value
                                    }))
            
                                }}
                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                    {
                        !payload.loading && 
                        <FormControl>
                                <OptionBox
                                    defaultValue={listStatus?.find(val=>val.value===isTable.status)}

                                    options={listStatus}
                                    placeholder={"Status"}
                                    isClearable
                                    onChange={(e) => {
                                        let value = ''
                                        if (e && e.value) value = e.value
                                        setTable(prev => ({
                                            ...prev,
                                            ...isTable,
                                            status: value
                                        }))

                                    }}
                                    isDisabled={payload.loading}
                                />
                        </FormControl>
                    }
                    </Segment>
                </Segment>
                <DataTable
                    defaultSortActive={isTable.sort}
                    countingList={true}
                    defaultSize={isTable.size}
                    tableConsume={[{
                        field: 'NIK Manager',
                        rowField:'nik',
                        sortField: 'nik',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={`/manager/${entity.id}`}>{entity.nik}</Link>
                            )
                        }
                    }, {
                        field: 'Nama',
                        rowField:'name',
                        sortField: 'name',
                    },{
                        field: 'Spv. Delivery',
                        isCustomRow: (id, entity) => {
                            return entity.supervisorTotal ? <Link to={`/supervisor-delivery?managerId=${entity.id}`}>{entity.supervisorTotal} Supervisor</Link> : '-'
                        }
                    }, {
                        field: 'Klaster',
                        isCustomRow: (id, entity) => {
                            return entity.clusterTotal ? <Link to={`/klaster?managerId=${entity.id}`}>{entity.clusterTotal} Klaster</Link> : '-'
                        }
                    }, {
                        field: 'Verifikasi',
                        sortField: 'verified',
                        isCustomRow: (id, entity) => {
                            return entity.verified ? 'Terverifikasi' : 'Belum Verifikasi'
                        }
                    }, {
                        field: 'Status',
                        rowField: 'status',
                        sortField: 'status',
                        isCustomRow: (id, entity) => {
                            const pallet = statusColor(entity.status)
                            
                            return (
                                <Badge
                                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                    bg={pallet.bg}
                                    color={pallet.color}
                                >
                                    {pallet.text}
                                </Badge>
                            )
                        },
                        width: "25%"
                    },{
                        field: 'Dihapus',
                        rowField: 'deleted',
                        sortField: 'deleted',
                        isCustomRow: (id, entity) => {
                            const pallet = entity.deleted ? statusColor("deleted") : null
                            
                            return (
                                pallet!==null ?  <Badge
                                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                    bg={pallet.bg}
                                    color={pallet.color}
                                >
                                    {pallet.text}
                                </Badge> : '-'
                            )
                        },
                        width: "25%"
                    }]}
                    isLoading={payload.loading}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload.totalPages}
                    activePage={isTable.page}
                    callSorted={(name,direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            sort:`${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...isTable,
                            ...page
                        }
                        
                        setTable(query)
                    }}
                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Supervisor Delivery?"}
                content={<Segment py={8}>Semua informasi mengenai Supervisor Delivery ini akan dihapus dari sistem.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default Manager