const initialState = {
    profile: {
        email: "",
        name: ""
    },
    menus: [],
    getMenus: [],
    loading: false,
    pola: {},
    orderConfig:[],
    action_group:[]
}
export const homesidemenu = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_HOME_SIDE_MENU':
            return {
                ...state,
                loading: true
            }
        case 'GET_HOME_SIDE_MENU':
            const { profile, menus, orderConfig } = action.payload
            let listMenu = [{
                index: 0,
                key: "home",
                icon: "home",
                label: "Home",
                link: "/",
                children: []
            }];
            const declareMenus = menus.map((item, index) => {
                return {
                    index: index + 1,
                    key: item.code,
                    icon: item.icon,
                    label: item.name,
                    link: item.url === undefined ? '' : item.url,
                    children: item.menus.filter(setEnabled => setEnabled.enabled).map(valueOfChildren => {
                        return {
                            key: valueOfChildren.code,
                            accessRights: valueOfChildren.accessRights,
                            icon: "",
                            label: valueOfChildren.label,
                            link: valueOfChildren.url === undefined ? '/' : valueOfChildren.url,
                            parent: item.code,
                            children: []
                        }
                    })
                }
            });
            listMenu.push(...declareMenus);
            return {
                ...state,
                profile,
                menus: listMenu,
                getMenus: menus,
                pola: action.payload.pola,
                orderConfig,
                loading: false,
                action_group: action.payload.action_group
            }
        case 'REQUEST_HOME_SIDE_MENU_FAILED':
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}