import React from "react"
import { Segment, Text, Spinner } from "@elevenia/master-ui/components/Atom"
import Chart from "react-apexcharts";

const ChartLabel = ({ labelColor, labelName, labelCount }) => {
    return (
        <>
            <Segment className="chartlabel" mb={12}>
                <Segment bg={labelColor} width="16px" height="16px" borderRadius={4} />
                <Segment ml={8}>
                    <Text color="#151823" fontWeight={500}>
                        {labelName}
                    </Text>
                    <Text color="primary" fontSize={16} fontWeight={500} mt={8}>
                        {labelCount}
                    </Text>
                </Segment>
            </Segment>
        </>
    )
}

const SectionCancelOrder = ({
    payload
}) => {
    const dataPie = {
        labels: payload.dataCanceledDelivery.map(val=>val.label),
        data: payload.dataCanceledDelivery.map(val=>val.value),
        backgroundColor: payload.dataCanceledDelivery.map(val=>val.color??'')

    };

    return(
        <>
            <Segment justifyContent="space-between" mb={24} mt={12}>
                <Text H16>Canceled Delivery</Text>
            </Segment>
            <Segment>
                {
                    !payload?.loadingCanceledDelivery
                        ? <>
                            <Segment className="chart-wrapper" mx="auto" mb={16}>
                                <Segment>
                                    <Chart
                                        options={
                                            {
                                                dataLabels: {
                                                    enabled: true,
                                                    position: 'bottom',
                                                    formatter: function (val) {
                                                        return val.toFixed(1) + '%'
                                                    },
                                                    style: {
                                                        fontSize: '10px',
                                                        colors: ['white']
                                                    },
                                                    backgroundColor:{
                                                        enabled: false
                                                    },
                                                    dropShadow: {
                                                        enabled: false
                                                    }
                                                },
                                                legend: {
                                                    show: false
                                                },
                                                colors: dataPie.backgroundColor,
                                                plotOptions: {
                                                    pie: {
                                                        expandOnClick: false,
                                                        dataLabels: {
                                                            offset: -20,                                                                           
                                                        },
                                                        size: "100%"
                                                    }
                                                },
                                                labels: dataPie.labels,
                                                tooltip: {
                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                        const valueHover = series[seriesIndex]
                                                        const labelHover = dataPie.labels[seriesIndex]
                                                        const total = series.reduce((a, b) => a + b, 0)
                                                        const percentace = total > 0 ? (valueHover / total) * 100 : 0
                                                        return `<div style="padding:10px">${labelHover} : ${percentace.toFixed(1)}% - ${valueHover} Order</div>`
                                                    }
                                                }
                                            }}
                                        series={dataPie.data}
                                        type="pie"
                                    />
                                </Segment>
                            </Segment>
                            <Segment>
                                <Segment boxShadow className="label-cancalation-wrap" borderRadius={4} p={12} border={'1px solid #eff1f9'}>
                                    {
                                        payload?.dataCanceledDelivery.map((val,index)=>{
                                            return <ChartLabel key={index} labelColor={val.color} labelName={val.label} labelCount={`${val.value} Order`} />
                                        })
                                    }
                                </Segment>
                            </Segment>
                        </>
                        : <Segment className="u-tx-center" width="100%" height={30}>
                            <Spinner />
                        </Segment>
                }

            </Segment>
        </>
    )
}

export default SectionCancelOrder;