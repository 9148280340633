import React, { useEffect } from 'react'
import {
    Segment,
    Text,
    Row,
    Spinner
} from '@elevenia/master-ui/components/Atom'
import { Link } from 'react-router-dom'
import { useAction } from 'hooks';
import { useSelector } from 'react-redux';
import { requestDataDashboard } from 'store/actions/dashboard'
import Error404 from 'page/errorLayout'
import moment from 'moment'
import 'moment/locale/id';
import Chart from "react-apexcharts";
import { getLabelTimeZone, serviceTypeDashboard, colorOrderId } from 'helper'
import TabelInfinitiedScroll from 'component/TableInfinitiedScroll';

const CardCountTracking = ({ statusName, path, status, count, color, date }) => {
    const params = `?activeTab=${statusName}&date=${date}`
    return (
        <Segment className="card-counting-tracking" bg="white" p={16} display="inline-block" width="100%" borderRight={`4px solid ${color ? color : 'black'}`} borderRadius={"8px 0 0 8px"}>
            <Text H16 mb={24}>
                {status}
            </Text>
            <Link target="_blank" to={{ pathname: `/${path}`, search: params }}>
                <Text H28 color="primary">
                    {count}
                </Text>
            </Link>
        </Segment>
    )
}

const Dashboard = (props) => {
    document.title = props.title
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            dataOrderSummary: state.dashboard.dataOrderSummary,
            loadingOrderSummary: state.dashboard.loadingOrderSummary,
            dataAbnormalPickup: state.dashboard.dataAbnormalPickup,
            loadingAbnormalPickup: state.dashboard.loadingAbnormalPickup,
            dataAbnormalInDelivery: state.dashboard.dataAbnormalInDelivery,
            loadingAbnormalInDelivery: state.dashboard.loadingAbnormalInDelivery,
            loadingSLADeliveryReview: state.dashboard.loadingSLADeliveryReview,
            dataSLADeliveryReview: state.dashboard.dataSLADeliveryReview,
            loadingCanceledDelivery: state.dashboard.loadingCanceledDelivery,
            dataCanceledDelivery: state.dashboard.dataCanceledDelivery,
            dateTime: state.dashboard.dateTime,
            timeStampAbnormalPickup: state.dashboard.timeStampAbnormalPickup,
            totalOrderAbnormalPickup: state.dashboard.totalOrderAbnormalPickup,
            timeStampAbnormalInDelivery: state.dashboard.timeStampAbnormalInDelivery,
            totalOrderAbnormalInDelivery: state.dashboard.totalOrderAbnormalInDelivery
        }
    })

    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)

    useEffect(() => {
        props.access?.read && hasFetch(requestDataDashboard())

    }, [hasFetch, props.access])

    // useEffect(() => {
    //     //let intervalId = null
    //     if (props.access?.read) {
    //         //intervalId = setInterval(() => {
    //             hasFetch(requestDataDashboardInterval())
    //         //}, 1000)
    //     }

    //     return () => {
    //         //clearInterval(intervalId)
    //     }
    // }, [hasFetch, props.access])

    const getCountRow = (row) => {
        let rowCount = row ? row.length : 0;

        if (rowCount > 10) {
            return true
        } else {
            return false
        }
    }

    const ChartLabel = ({ labelColor, labelName, labelCount }) => {
        return (
            <>
                <Segment className="chartlabel" mb={12}>
                    <Segment bg={labelColor} width="16px" height="16px" borderRadius={4} />
                    <Segment ml={8}>
                        <Text color="#151823" fontWeight={500}>
                            {labelName}
                        </Text>
                        <Text color="primary" fontSize={16} fontWeight={500} mt={8}>
                            {labelCount}
                        </Text>
                    </Segment>
                </Segment>
            </>
        )
    }

    const ChartLabelBlock = ({ labelColor, labelName, labelCount, ...props }) => {
        return (
            <>
                <Segment {...props} justifyContent="space-between">
                    <Segment display="flex">
                        <Segment mr={8} bg={labelColor} width="16px" height="16px" borderRadius={4} />
                        <Text color="#151823" fontWeight={500}>
                            {labelName}
                        </Text>
                    </Segment>
                    <Text color="primary" fontSize={16} fontWeight={500}>
                        {labelCount}
                    </Text>
                </Segment>
            </>
        )
    }

    const dataDoughnut = {
        labels: ['Selesai Dalam SLA', 'Over SLA'],
        data: [
            payload?.dataSLADeliveryReview?.orderFinishedInTime,
            payload?.dataSLADeliveryReview?.orderFinishedOverTime
        ],
        backgroundColor: [
            '#FFA800',
            '#F9C662'
        ]

    }

    const dataDoughnutPercentage = !payload.loadingSLADeliveryReview && (payload?.dataSLADeliveryReview?.orderFinishedInTime + payload?.dataSLADeliveryReview?.orderFinishedOverTime) > 0 ? payload?.dataSLADeliveryReview?.orderFinishedInTime / (payload?.dataSLADeliveryReview?.orderFinishedInTime + payload?.dataSLADeliveryReview?.orderFinishedOverTime) * 100 : 0

    const dataPie = {
        labels: payload.dataCanceledDelivery.map(val=>val.label),
        data: payload.dataCanceledDelivery.map(val=>val.value),
        backgroundColor: payload.dataCanceledDelivery.map(val=>val.color??'')

    };
    
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30} justifyContent={"space-between"} className="xs-block">
                <Text H28 mb={"1rem"}>Dashboard Delivery</Text>
                <Segment display="inline-block">
                    {
                        payload.loadingOrderSummary
                            ? <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment>
                            : <Segment className="label-dashboard">
                                {payload.dateTime ? `${moment(payload.dateTime).locale('id').format('dddd, DD MMMM YYYY - HH:mm:ss')} ${timezoneLabel}` : '-'}
                            </Segment>
                    }

                </Segment>
            </Segment>
            <Segment>
                {
                    payload.loadingOrderSummary
                        ? <Segment className="u-tx-center" width="100%" height={30}>
                            <Spinner />
                        </Segment>
                        : <Segment>
                            <Text H16>Status Pesanan</Text>
                            <Segment className="group-card-tracking" display="flex" justifyContent="space-between" mt={24}>
                                <CardCountTracking date={moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')} path="pesanan" statusName="ALLOCATING" status="Pesanan Baru" count={payload.dataOrderSummary?.ALLOCATING} color="#FDC194" />
                                <CardCountTracking date={moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')} path="pesanan" statusName="PICKING_UP" status="Penjemputan" count={payload.dataOrderSummary?.PICKING_UP} color="#B7E27D" />
                                <CardCountTracking date={moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')} path="pesanan" statusName="IN_DELIVERY" status="Pengiriman" count={payload.dataOrderSummary?.IN_DELIVERY} color="#F99698" />
                                <CardCountTracking date={moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')} path="pesanan" statusName="COMPLETED" status="Selesai" count={payload.dataOrderSummary?.COMPLETED} color="#6CA5E0" />
                                <CardCountTracking date={moment(payload.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')} path="pembatalan" statusName="FALLBACK" status="Fallback & Titip" count={payload.dataOrderSummary?.FALLBACK} color="#FCB731" />
                            </Segment>
                        </Segment>
                }
                <Row mt={32} justifyContent="space-between" className="sm-flex-wrap">
                    <Segment width={"49%"} className="custom-table">
                        <Segment justifyContent="space-between" alignItems="flex-start" mb={16} className="xs-block">
                            <Text H16 mb={8}>
                                Pesanan Belum Dijemput <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 10 Menit
                            </Text>
                            <Segment py={5} px={8} bg="#FFA800" borderRadius={4} display="inline-block">
                                <Text color="white">{`Total : ${payload?.totalOrderAbnormalPickup??'-'}`}</Text>
                            </Segment>
                        </Segment>
                        <Segment className={getCountRow(payload?.dataAbnormalPickup) ? `scroll-table` : ''}>
                            <TabelInfinitiedScroll
                                isLoading={payload?.loadingAbnormalPickup}
                                dataConsume={payload?.dataAbnormalPickup ?? []}
                                tableConsume={[{
                                    field: 'AWB ID',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Segment width={"95%"}>{entity.storeCode}</Segment>
                                        )
                                    }
                                }, {
                                    field: 'Nama Cabang',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                        )
                                    },
                                }, {
                                    field: 'Mulai Jemput',
                                    isCustomRow: (id, entity) => {
                                        return entity.minDeliveryTime ? `${moment(entity.minDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                        
                                    },
                                }, {
                                    field: 'Service',
                                    isCustomRow: (id, entity) => {
                                        return   serviceTypeDashboard(entity)
                                    },
                                }
                                ]}
                            />
                        </Segment>
                    </Segment>
                    <Segment width={"49%"} className="custom-table">
                        <Segment justifyContent="space-between" alignItems="flex-start" mb={16} className="xs-block">
                            <Text H16 mb={8}>Pesanan Belum Dikirim <span style={{ fontSize: "20px", margin: "0 5px", verticalAlign: "text-top" }} >&#8250;</span> 1 Jam</Text>
                            <Segment py={5} px={8} bg="#FFA800" borderRadius={4} display="inline-block">
                                <Text color="white">{`Total : ${payload.totalOrderAbnormalInDelivery??'-'}`}</Text>
                            </Segment>
                        </Segment>
                        
                        <Segment className="scroll-table">
                        <TabelInfinitiedScroll
                                isLoading={payload?.loadingAbnormalPickup}
                                dataConsume={payload?.dataAbnormalInDelivery ?? []}
                                tableConsume={[{
                                    field: 'AWB ID',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Link style={{color:colorOrderId(entity.channelOrder) }} target="_blank" to={{ pathname: `/pesanan/${entity.id}` }}>{entity.awbId}</Link>
                                        )
                                    }
                                }, {
                                    field: 'Kode Toko',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <Segment width={"95%"}>{entity.storeCode}</Segment>
                                        )
                                    }
                                }, {
                                    field: 'Nama Cabang',
                                    isCustomRow: (id, entity) => {
                                        return (
                                            <>{entity.dcName ? `${entity.dcName}` : '-'}</>
                                        )
                                    },
                                }, {
                                    field: 'Batas Kirim',
                                    isCustomRow: (id, entity) => {
                                        return entity.maxDeliveryTime ? `${moment(entity.maxDeliveryTime).format('DD/MM/YYYY - HH:mm')} ${timezoneLabel}` : '-'
                                        
                                    },
                                }, {
                                    field: 'Service',
                                    isCustomRow: (id, entity) => {
                                        return   serviceTypeDashboard(entity)
                                    },
                                }
                                ]}
                            />
                        </Segment>
                    </Segment>
                </Row>

                <Segment mt={32}>
                    <Row justifyContent="space-between" className="sm-flex-wrap">
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16} >
                            <Text H16 mb={24} mt={12}>SLA Delivery Review</Text>
                            <Segment>
                                {
                                    !payload?.loadingSLADeliveryReview ?
                                        <>
                                            <Segment>
                                                <Segment position="relative">
                                                    <Segment className="chart-wrapper" position="relative" zIndex="2" mx="auto" mb={16}>
                                                        <Chart
                                                            options={
                                                                {
                                                                    dataLabels: {
                                                                        enabled: false
                                                                    },
                                                                    chart: {
                                                                        width: '100%'
                                                                    },
                                                                    legend: {
                                                                        show: false
                                                                    },
                                                                    colors: dataDoughnut.backgroundColor,
                                                                    plotOptions: {
                                                                        pie: {
                                                                            expandOnClick: false,
                                                                            donut: {
                                                                                size: '80%'
                                                                            }
                                                                        }
                                                                    },
                                                                    labels: dataDoughnut.labels,
                                                                    tooltip: {
                                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                            const valueHover = series[seriesIndex]
                                                                            const labelHover = dataDoughnut.labels[seriesIndex]
                                                                            const total = series.reduce((a, b) => a + b, 0)
                                                                            const percentace = total > 0 ? (valueHover / total) * 100 : 0
                                                                            return `<div style="padding:10px">${labelHover} : ${percentace.toFixed(0)}% - ${valueHover} Order</div>`
                                                                        }
                                                                    }
                                                                }}
                                                            series={dataDoughnut.data}
                                                            type="donut"
                                                        />
                                                    </Segment>

                                                    <Segment justifyContent={'center'} alignItems={'center'} zIndex="1" position="absolute" top={0} left={0} right={0} bottom={0}>
                                                        <Segment style={{ textAlign: 'center' }} className="group-label-donut">
                                                            <Text className="percent" color={"#151823"} fontWeight={500} >
                                                                {`${dataDoughnutPercentage.toFixed(0)}%`}
                                                            </Text>
                                                            <Text className="text-label" color={"#9C9DA6"} fontWeight={400} mt={8}>
                                                                Selesai dalam SLA
                                                            </Text>
                                                        </Segment>
                                                    </Segment>
                                                </Segment>
                                            </Segment>
                                            <Segment>
                                                <Segment boxShadow borderRadius={4} p={12} border={'1px solid #eff1f9'}>
                                                    <ChartLabelBlock labelColor="#FFA800" labelName="Selesai Dalam SLA" labelCount={`${payload?.dataSLADeliveryReview?.orderFinishedInTime} Order`} />
                                                    <ChartLabelBlock labelColor="#F9C662" labelName="Over SLA" mt={20} labelCount={`${payload?.dataSLADeliveryReview?.orderFinishedOverTime} Order`} />
                                                </Segment>

                                                <Segment boxShadow borderRadius={4} p={12} mt={16} border={'1px solid #eff1f9'}>
                                                    <Segment justifyContent="space-between" mb={20}>
                                                        <Text color="#9C9DA6">
                                                            Total
                                                        </Text>
                                                        <Text color="primary" fontWeight="500">
                                                            {`${payload?.dataSLADeliveryReview?.totalOrder} Order`}
                                                        </Text>
                                                    </Segment>
                                                    <Segment justifyContent="space-between">
                                                        <Text color="#9C9DA6">
                                                            Klaster
                                                    </Text>
                                                        <Text color="primary" fontWeight="500">
                                                            {`${payload?.dataSLADeliveryReview?.totalCluster} Klaster`}
                                                        </Text>
                                                    </Segment>
                                                </Segment>
                                            </Segment>
                                        </>
                                        : <Segment className="u-tx-center" width="100%" height={30}>
                                            <Spinner />
                                        </Segment>
                                }
                            </Segment>
                        </Segment>
                        <Segment width="49%" boxShadow bg="white" borderRadius={8} p={16}>
                            <Segment justifyContent="space-between" mb={24} mt={12}>
                                <Text H16>Canceled Delivery</Text>
                            </Segment>
                            <Segment>
                                {
                                    !payload?.loadingCanceledDelivery
                                        ? <>
                                            <Segment className="chart-wrapper" mx="auto" mb={16}>
                                                <Segment>
                                                    <Chart
                                                        options={
                                                            {
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    position: 'bottom',
                                                                    formatter: function (val) {
                                                                        return val.toFixed(1) + '%'
                                                                    },
                                                                    style: {
                                                                        fontSize: '10px',
                                                                        colors: ['white']
                                                                    },
                                                                    backgroundColor:{
                                                                        enabled: false
                                                                    },
                                                                    dropShadow: {
                                                                        enabled: false
                                                                    }
                                                                },
                                                                legend: {
                                                                    show: false
                                                                },
                                                                colors: dataPie.backgroundColor,
                                                                plotOptions: {
                                                                    pie: {
                                                                        expandOnClick: false,
                                                                        dataLabels: {
                                                                            offset: -20,                                                                           
                                                                        },
                                                                        size: "100%"
                                                                    }
                                                                },
                                                                labels: dataPie.labels,
                                                                tooltip: {
                                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                        const valueHover = series[seriesIndex]
                                                                        const labelHover = dataPie.labels[seriesIndex]
                                                                        const total = series.reduce((a, b) => a + b, 0)
                                                                        const percentace = total > 0 ? (valueHover / total) * 100 : 0
                                                                        return `<div style="padding:10px">${labelHover} : ${percentace.toFixed(1)}% - ${valueHover} Order</div>`
                                                                    }
                                                                }
                                                            }}
                                                        series={dataPie.data}
                                                        type="pie"
                                                    />
                                                </Segment>
                                            </Segment>
                                            <Segment>
                                                <Segment boxShadow className="label-cancalation-wrap" borderRadius={4} p={12} border={'1px solid #eff1f9'}>
                                                    {
                                                        payload?.dataCanceledDelivery.map((val,index)=>{
                                                            return <ChartLabel key={index} labelColor={val.color} labelName={val.label} labelCount={`${val.value} Order`} />
                                                        })
                                                    }
                                                    
                                                    {/* <ChartLabel labelColor="#F9C662" labelName="Alamat Tidak Ditemukan" labelCount={`${payload?.dataCanceledDelivery?.addressNotFound} Order`} />
                                                    <ChartLabel labelColor="#1178D4" labelName="Customer Tidak Pesan" labelCount={`${payload?.dataCanceledDelivery?.declinedByConsumer} Order`} />
                                                    <ChartLabel labelColor="#6CA5E0" labelName="Delivery Man Sakit/Berhalangan" labelCount={`${payload?.dataCanceledDelivery?.delimanUnavailable} Order`} /> */}
                                                </Segment>
                                            </Segment>
                                        </>
                                        : <Segment className="u-tx-center" width="100%" height={30}>
                                            <Spinner />
                                        </Segment>
                                }

                            </Segment>
                        </Segment>
                    </Row>
                </Segment>
            </Segment>
        </>
    )
}

export default Dashboard