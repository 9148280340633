import { Services } from "service"

export const uploadFn = async(params) => {
    const progressUpload = await Services().post(`/driver/api/back-office/absensi/upload`,params)
    return progressUpload.data.data
}

export const processFn = async (uploadResult) => {
    const processSubmit = await Services().post(`/driver/api/back-office/absensi/process-uploaded-data/${uploadResult.id}`)
    return processSubmit
}

export const deleteFn = async(data) => {
    const processDelete = await Services().delete(`/driver/api/back-office/absensi/delete/${data?.id}`)
    return processDelete
}

export const updateAbsensiFn = async (payload) => {
    const processUpdate = await Services().put(`/driver/api/back-office/absensi/update`,payload)
    return processUpdate
}

export const updateIzinAbsensiFn = async (payload) => {
    const processUpdate = await Services().put(`/driver/api/back-office/absensi/update-izin`,payload)
    return processUpdate
}

export const updateJadwalLiburFn = async (payload) => {
    const processUpdate = await Services().put(`/driver/api/back-office/libur/${payload?.id}`,payload)
    return processUpdate
}

export const uploadJadwalLiburFn = async(params) => {
    const progressUpload = await Services().post(`/driver/api/back-office/libur/upload`,params)
    return progressUpload.data.data
}

export const processJadwalLiburFn = async (uploadResult) => {
    const processSubmit = await Services().post(`/driver/api/back-office/libur/process-uploaded-data/${uploadResult.id}`)
    return processSubmit
}