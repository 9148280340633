import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestReleaseValidation = (action$,state$) =>
    action$.pipe(
        ofType( 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS',
                'REQUEST_SUBMIT_RELEASE_VALIDATION',
                'REQUEST_RELEASE_VALIDATION',
                'REQUEST_RELEASE_VALIDATION_INTERVAL',
                'REQUEST_RELEASE_VALIDATION_HISTORY',
                'REQUEST_DURATION_RELEASE_VALIDATION'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_RELEASE_VALIDATION':
                        const rawResponseReleaseValidation = await Services().get(`/order/api/back-office/release-validation/`,action.payload.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_VALIDATION',
                                payload: {
                                    data: rawResponseReleaseValidation.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseValidation.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_RELEASE_VALIDATION_INTERVAL':
                        const rawResponseReleaseValidationInt = await Services().get(`/order/api/back-office/release-validation/`,state$.value.releaseValidation.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_VALIDATION',
                                payload: {
                                    data: rawResponseReleaseValidationInt.data.data.content,
                                    parameter: state$.value.releaseValidation.parameter,
                                    totalPages: rawResponseReleaseValidationInt.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_RELEASE_VALIDATION_HISTORY':
                        const rawResponseReleaseValidationHis = await Services().get(`/order/api/back-office/release-validation/history`,action.payload.parameter)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_RELEASE_VALIDATION_HISTORY',
                                payload: {
                                    data: rawResponseReleaseValidationHis.data.data.content,
                                    parameter: action.payload.parameter,
                                    totalPages: rawResponseReleaseValidationHis.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS':
                        const rawResponse = await Services().get(`/order/api/back-office/release-validation/information?${action.payload.params}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS',
                                payload: {
                                    orderList: rawResponse.data.data,
                                    
                                }
                            })
                        }
                    case 'REQUEST_SUBMIT_RELEASE_VALIDATION':
                        await Services().post(`/orderprocessor/api/back-office/release-validation/add/`,action.payload.data)
                        return dispatch => {
                            action.history.push(`/release-validation`)
                            dispatch({ type: 'RELEASE_VALIDATION_SUCCESS' })
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Release Validation berhasil dibuat' } })
                        }
                    case 'REQUEST_DURATION_RELEASE_VALIDATION':
                        const rawDurationReleaseValidation = await Services().get('/orderprocessor/api/back-office/release-validation/config')
                        return dispatch=> {
                            dispatch({ 
                                type: 'GET_DURATION_RELEASE_VALIDATION',
                                payload:{
                                    data: rawDurationReleaseValidation.data.data.content
                                }
                             })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "RELEASE_VALIDATION_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
