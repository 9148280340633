export const payloadDashboard = (state) => {
    return {
        dataOrderSummary: state.dashboard.dataOrderSummary,
        loadingOrderSummary: state.dashboard.loadingOrderSummary,
        dataAbnormalPickup: state.dashboard.dataAbnormalPickup,
        loadingAbnormalPickup: state.dashboard.loadingAbnormalPickup,
        dataAbnormalInDelivery: state.dashboard.dataAbnormalInDelivery,
        loadingAbnormalInDelivery: state.dashboard.loadingAbnormalInDelivery,
        loadingSLADeliveryReview: state.dashboard.loadingSLADeliveryReview,
        dataSLADeliveryReview: state.dashboard.dataSLADeliveryReview,
        loadingCanceledDelivery: state.dashboard.loadingCanceledDelivery,
        dataCanceledDelivery: state.dashboard.dataCanceledDelivery,
        dateTime: state.dashboard.dateTime,
        timeStampAbnormalPickup: state.dashboard.timeStampAbnormalPickup,
        totalOrderAbnormalPickup: state.dashboard.totalOrderAbnormalPickup,
        timeStampAbnormalInDelivery: state.dashboard.timeStampAbnormalInDelivery,
        totalOrderAbnormalInDelivery: state.dashboard.totalOrderAbnormalInDelivery,
        dataNotYetCanceled: state.dashboard.dataNotYetCanceled,
        totalOrderNotYetCanceled: state.dashboard.totalOrderNotYetCanceled,
        loadingNotYetCanceled: state.dashboard.loadingNotYetCanceled,
        dataHangingAwb: state.dashboard.dataHangingAwb,
        totalOrderHangingAwb: state.dashboard.totalOrderHangingAwb,
        loadingHangingAwb: state.dashboard.loadingHangingAwb,
    }
}