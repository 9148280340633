export const requestAddSupervisorDelivery = (params,history) =>{
  return {
      type: "REQUEST_CREATE_SUPERVISOR_DELIVERY",
      payload:{
        params
      },
      history
  }
}

export const requestUpdateSupervisorDelivery = (id,params,history) =>{
  return {
      type: "REQUEST_UPDATE_SUPERVISOR_DELIVERY",
      payload:{
        params,
        id
      },
      history
  }
}

export const requestCheckNik = (params)=> {
    return {
        type: "REQUEST_CHECK_NIK_SPV",
        payload:{
          params
        }
    }
  }
  
  export const requestCheckEmail = (params)=> {
    return {
        type: "REQUEST_CHECK_EMAIL_SPV",
        payload:{
          params
        }
    }
  }
  
  export const requestCheckPhone = (params)=> {
    return {
        type: "REQUEST_CHECK_PHONE_SPV",
        payload:{
          params
        }
    }
  }
  
  export const resetInputStatus = (name) => {
    return {
        type: "RESET_INPUT_STATUS_SPV",
        payload:{
          field:name
        }
    }
  }

  export const setStatusField = (status) => {
    return {
        type: "SET_STATUS_FIELD_SPV",
        payload:{
          status:status?'00':''
        }
    }
  }