export const requestCluster = properties => {
  return {
      type: "REQUEST_LIST_CLUSTER",
      payload: { properties }
  }
}


export const requestClusterById = id => {
  return {
      type: "REQUEST_CLUSTER_BY_ID",
      payload: id
  }
}

export const requestAddCluster = (params,history) => {
  return {
      type: "REQUEST_CREATE_CLUSTER",
      payload:{params},
      history
  }
}

export const requestUpdateCluster = (params, history)=> {
  return {
      type: "REQUEST_UPDATE_CLUSTER",
      payload:{
        params
      },
      history
  }
}

export const requestCheckClusterCode = params => {
  return {
      type: "REQUEST_CHECK_CLUSTER_CODE",
      payload: { params }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS_CLUSTER",
      payload:{
        field:name
      }
  }
}

export const requestAvailableClusterCode = (params) => {
  return {
      type: "REQUEST_AVAILABLE_CLUSTER_CODE",
      payload:{
        params
      }
  }
}

export const resetForm = (params) => {
  return {
      type: "RESET_FORM_CLUSTER",
      
  }
}