import {
    Button,
    Segment,
    Spinner,
    Text
} from '@elevenia/master-ui/components/Atom';
import { Tabs } from "@elevenia/master-ui/components/Molecules";
import React, { useEffect, useState } from 'react';
import useDataUpload from '../_utils/DataContex';
import TabDataTerbaca from './TabDataTerbaca';
import TabDataTidakTerbaca from './TabDataTidakTerbaca';
import { useAction } from 'hooks';
import { Services } from 'service';
import { parseValue } from 'helper';
import moment from 'moment';
import DownloadExcel from 'helper/DownloadExcel';
import { saveAs } from 'file-saver';
import IconDownload from 'assets/image/download.svg';

const ResultImportedData = () => {
    const { hasFetch } = useAction();
    const [activeTab, setActiveTab] = useState("Terbaca");
    const { dataRead, errorRead, dataUnRead, errorUnRead } = useDataUpload();

    useEffect(() => {
        if (errorRead) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: errorRead?.message ? errorRead?.message : 'Terjadi kesalahan' } })
        };
    }, [hasFetch, errorRead]);

    useEffect(() => {
        if (errorUnRead) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: errorUnRead?.message ? errorUnRead?.message : 'Terjadi kesalahan' } })
        };
    }, [hasFetch, errorUnRead]);

    return (
        <>
            <Segment>
                <Segment borderTop={"1px solid #DCDEE3"} display="flex" className="xs-flex-wrap" pt={24} mt={20} mb={24}>
                    {
                        !dataRead?.data && !errorRead ?
                            <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment> :
                            <>
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Data Terbaca</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {dataRead.totalElements ?? 0} data terbaca
                                </Segment>
                            </>
                    }
                </Segment>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    {
                        !dataUnRead?.data && !errorUnRead ?
                            <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment> :
                            <>
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Data Tidak Terbaca</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {dataUnRead.totalElements ?? 0} data tidak terbaca
                                </Segment>
                            </>
                    }
                </Segment>
            </Segment>
            <Segment borderTop={"1px solid #DCDEE3"} pt={24} mt={20}>
                <Segment className="container-tab-upload-jadwal" style={{ position: 'relative' }}>
                    {activeTab === "TidakTerbaca" && <ButtonDownloadUnread />}
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="Terbaca">Data Terbaca</Text>
                        <Text key="TidakTerbaca">Data Tidak Terbaca</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "Terbaca" && <TabDataTerbaca />}
                        {activeTab === "TidakTerbaca" && <TabDataTidakTerbaca />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    );
};

const ButtonDownloadUnread = () => {
    const { uploadResult } = useDataUpload();
    const [isLoading, setIsLoading] = useState(false);
    const { hasFetch } = useAction();

    const handleDownloadFile = () => {
        setIsLoading(true);

        Services().get(`/driver/api/back-office/absensi/upload/${uploadResult.id}/review/error-list`, { unpaged: true })
            .then(async ({ data }) => {
                setIsLoading(false);
                const contentRow = data.data.content.map((item) => {
                    return {
                        deliman: parseValue(item.driverNik),
                        date: parseValue(item.absensiDate, (val) => moment(val).format('DD/MM/yyyy')),
                        shift: parseValue(item.shift, (val) => `${val}`),
                        clockInStoreCode: parseValue(item.clockInStoreCode),
                        clockOutStoreCode: parseValue(item.clockOutStoreCode)
                    };
                });

                const columns = [
                    { header: 'Delivery Man', key: 'deliman', width: 14 },
                    { header: 'Tanggal', key: 'date', width: 14, style: { numFmt: '@' } },
                    { header: 'Shift', key: 'shift', style: { numFmt: '@' } },
                    { header: 'Toko Clock In', key: 'clockInStoreCode', width: 14 },
                    { header: 'Toko Clock Out', key: 'clockOutStoreCode', width: 14 }
                ];

                const sheetName = 'sheet 1';
                const fileName = `Data tidak terbaca ${moment(data.data.timestamp).format('DDMMYYYY HHmm')}`;

                const buffer = await DownloadExcel({ sheetName, columns, contentRow });
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                const fileExtension = ".xlsx";
                const blob = new Blob([buffer], { type: fileType });
                saveAs(blob, fileName + fileExtension);
            })
            .catch((e) => {
                setIsLoading(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message ? e.message : 'Terjadi Kesalahan' } });
            });
    };

    return (
        <Button
            variant="secondary"
            onClick={handleDownloadFile}
            style={{ position: 'absolute', top: '0', right: '0', color: "#151823", zIndex: 100 }}
            disabled={isLoading}
            spinner={isLoading}
        >
            {!isLoading && <img src={IconDownload} alt="Download" style={{ marginRight: 8 }} />}
            Download Data Tidak Terbaca
        </Button>
    );
};

export default ResultImportedData;
