export const requestActivityLog = ({ isTable }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_ACTIVITY_LOG",
            payload: {
                parameter: isTable
            }
        })
    }
}

export const requestDetailActivityLog = ({ id }) => {
    return dispatch => {
        dispatch({
            type: "REQUEST_DETAIL_ACTIVITY_LOG",
            payload: { id }
        })
    }
}