
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:{
      status:''
  },
  dataError:null
}

export const deliverymanCadangan = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_DELIVERY_MAN_CADANGAN':
          return {
              ...state,
              loading: true,
              dataError:null
          }
      case 'REQUEST_DELIVERY_MAN_CADANGAN_BY_ID':
          return {
              ...state,
              loading: true
          }
    case 'REQUEST_CREATE_DELIVERY_MAN_CADANGAN':
        return {
            ...state,
            loading: true
        }
      case 'GET_DELIVERY_MAN_CADANGAN':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false
          }
      case 'GET_DELIVERY_MAN_CADANGAN_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data,
            loading: false
        }
      case 'GET_DELIVERY_MAN_CADANGAN_FAILED':
        return {
            ...state,
            loading: false
        }
      case 'GET_DATA_CREATE_ERROR':
          return {
              ...state,
              dataError:action.payload.data,
              loading: false
          }
      default:
          return state
  }
}