import React, { useEffect, useState } from "react";
import {
  Segment,
  Text,
  FormControl,
  Icon,
  Textfield,
  OptionBox,
  DatePicker,
} from "@elevenia/master-ui/components/Atom";
import { Link } from "react-router-dom";
import Breadcrumb from "component/Breadcrumb";
import DataTable from "component/DataTable";
import { useAction } from "hooks";
import { requestActivityLog } from "store/actions/activity-log";
import { useSelector } from "react-redux";
import Error404 from "page/errorLayout";
import { debounceTime, tap, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { getLabelTimeZone } from "helper"
const MonitoringAplikasi = (props) => {
  document.title = props.title;
  const moment = require("moment");
  const dataBreadcrumb = [{ to: "", label: "Monitoring Aplikasi" }];
  const { hasFetch } = useAction();
  const [status, setstatus] = useState("hasLoad");
  const [isTable, setTable] = useState({
    activity: "",
    date: new Date(),
    keyword: "",
    page: 0,
    size: 30,
    sort: "recordingDate,asc",
  });
  const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
  const [data, setData] = useState({
    singleDate: isTable.date,
  });
  useEffect(() => {
    status === "hasLoad" &&
      hasFetch(
        requestActivityLog({
          isTable: {
            ...isTable,
            date: moment(isTable.date).format("YYYY-MM-DD"),
          },
        })
      );
    status === "hasPagination" && hasFetch(requestActivityLog({ isTable }));
  }, [hasFetch, isTable, status, moment]);
  const payload = useSelector((state) => ({ ...state.activityLog }));
  const onChange = (name, value) => {
    setData({
      ...data,
      [name]: new Date(value),
    });
    hasFetch(
      requestActivityLog({
        isTable: {
          ...isTable,
          ...payload.parameter,
          date: moment(new Date(value)).format("YYYY-MM-DD"),
        },
      })
    );
  };
  const StatusColor = (status) => {
    switch (status) {
      case "login":
        return (
          <Text
            fontWeight="500"
            color="green20"
            style={{ textTransform: "capitalize" }}
          >
            {status}
          </Text>
        );
      case "logout":
        return (
          <Text
            fontWeight="500"
            color="red20"
            style={{ textTransform: "capitalize" }}
          >
            {status}
          </Text>
        );
      default:
        break;
    }
  };
  const [onSearch$] = useState(() => new Subject());
  useEffect(() => {
    onSearch$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((value) => hasFetch(requestActivityLog(value)))
      )
      .subscribe();

    return () => {
      onSearch$.unsubscribe();
    };
  }, [hasFetch, onSearch$]);
  const handleSearch = (e) => {
    const query = {
      // ...isTable,
      ...payload.parameter,
      keyword: e.target.value.trim(),
    };
    onSearch$.next({ isTable: query });
  };
  if (!props.access) return <></>;
  else if (!props.access?.read) return <Error404 />;
  return (
    <>
      <Segment justifyContent={"space-between"}>
        <Segment mb={30}>
          <Text H28 mb={"1rem"}>
            Monitoring Aplikasi
          </Text>
          <Breadcrumb data={dataBreadcrumb} />
        </Segment>
        <Segment>
          {/* <Button mr={16} variant="secondary">
                        <Icon name={"add-document"} size={22} fillColor={"#115488"} mr={8} />
                        Export XLS
                    </Button> */}
        </Segment>
      </Segment>
      <Segment mb={32} pb={16}>
        <Segment
          className={"group-filter"}
          boxShadow={"0 1px 4px 0 #70727d33"}
          borderRadius={4}
          bg={"white"}
          p={20}
          borderBottom={"1px solid #DCDEE3"}
          mb={24}
          display="flex"
        >
          <Segment maxWidth={468} width={"100%"} className={"fw500"}>
            <FormControl>
              <Textfield
                inputProps={{
                  onChange: handleSearch,
                  placeholder: "Cari Kode atau Nama Klaster ",
                }}
                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
              />
            </FormControl>
          </Segment>
          <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
            <FormControl>
              <DatePicker
                placeholderText="Tanggal"
                selected={data.singleDate}
                onChange={(data) => onChange("singleDate", data)}
                maxDate={new Date()}
                right={<Icon name={"date"} size={24} />}
              />
            </FormControl>
          </Segment>
          <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
            <FormControl>
              <OptionBox
                onChange={(get) =>
                  hasFetch(
                    requestActivityLog({
                      isTable: {
                        ...isTable,
                        ...payload.parameter,
                        activity: get?.value,
                      },
                    })
                  )
                }
                defaultValue={""}
                options={payload.activities}
                placeholder={"Aktivitas"}
                isClearable
              />
            </FormControl>
          </Segment>
        </Segment>
        <DataTable
          countingList={true}
          defaultSortActive={isTable.sort}
          defaultSize={isTable.size}
          tableConsume={[
            {
              field: "Kode Klaster",
              isCustomRow: (id, entity) => {
                return (
                  <Link to={`/monitoring-aplikasi/${entity.referenceId}`}>
                    {entity.clusterCode}
                  </Link>
                );
              },
              sortField: "clusterCode",
            },
            {
              field: "Nama Klaster",
              isCustomRow: (id, entity) => {
                return <>{entity.clusterName}</>;
              },
              sortField: "clusterName",
            },
            {
              field: "Delivery Man",
              isCustomRow: (id, entity) => {
                return <>{entity.deliveryManName}</>;
              },
              sortField: "deliveryManName",
            },
            {
              field: "Aktivitas",
              isCustomRow: (id, entity) => {
                return (
                  <>
                    {entity.activity === "-"
                      ? "-"
                      : StatusColor(entity.activity)}
                  </>
                );
              },
              sortField: "activity",
            },
            {
              field: "Waktu",
              isCustomRow: (id, entity) => {
                return <>{entity.timeLabel!=='-' ? `${entity.timeLabel} ${timezoneLabel}` : "-"}</>;
              },
              sortField: "recordingDate",
            },
          ]}
          dataConsume={payload.data}
          isLoading={payload.loading}
          showSize={true}
          showPagination={true}
          totalPages={payload.totalPages}
          createPaginations={(page) => {
            const query = {
              ...payload.parameter,
              ...page,
            };
            setstatus("hasPagination");
            setTable(query);
          }}
          callSorted={(val, sort) => {
            const query = {
              ...payload.parameter,
              sort: `${val},${sort}`,
            };
            hasFetch(requestActivityLog({ isTable: query }));
          }}
          createShowPerSize={(size) =>
            setTable((prev) => {
              return {
                ...prev,
                ...payload.parameter,
                size: size.value,
              };
            })
          }
        />
      </Segment>
    </>
  );
};

export default MonitoringAplikasi;
