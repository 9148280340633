import React from 'react'
import { Segment, Text } from '@elevenia/master-ui/components/Atom'
import { formatCountDown } from 'helper'
import moment from 'moment'
import StatusColorSLA from './StatusColorSLA'
const OrderDoneInfo = ({
    data,
    allowedStatus,
    timezoneLabel,
    colorDuration,
    duration,
    textOrderStatus
}) => {
  return (
    <>
        {
            allowedStatus.includes(textOrderStatus)
            &&
            <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Waktu Selesai</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>

                    {data?.completedTime ? `${moment(data?.completedTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-'}
                </Segment>
            </Segment>
        }
        {
            textOrderStatus === 'COMPLETED' &&
            <Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>SLA</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>

                    {(data?.completedTime && data?.minDeliveryTime) ? StatusColorSLA({
                        duration: colorDuration,
                        text: formatCountDown(duration, true)
                    }) : '-'}
                </Segment>
            </Segment>
        }
    </>
  )
}

export default OrderDoneInfo