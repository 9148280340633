import React from "react"
import {
    OptionBox
} from "@elevenia/master-ui/components/Atom";
import { capitalize, fetcher } from "helper";
import useSWR from "swr";
const FilterChannel = ({handleChange}) => {
    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data, error } = useSWR(`/order/api/back-office/orders/dropdown/channel`,fetcher,optionsSwr)
    
    return <OptionBox
                defaultValue={""}
                options={data && data.data.channelType.map(val=>({value:val, label: capitalize(val)}))}
                placeholder={"Channel"}
                isClearable
                isDisabled={!data && !error}
                onChange={(e) => {

                    let value = ''
                    if (e) value = e.value
                    handleChange(value)

                }}
            />
}

export default FilterChannel