import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    FormControl,
    Textfield,
    Row,
    ButtonGroup,
    Switch,
    OptionBox,
    Spinner,
    Icon
} from '@elevenia/master-ui/components/Atom'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import Breadcrumb from 'component/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import { validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { requestUserById, requestListRoles, updateUser, requestCheckEmail, requestCheckNik, resetInputStatus } from 'store/actions/users'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'

const UserForm = props => {
    const { hasFetch } = useAction();
    const getPath = props.match.params.id;
    
    useEffect(()=>{
        let unmounted = false;
        !unmounted && hasFetch(requestListRoles({unpaged:true}))
        getPath !== undefined && props.access?.update && !unmounted &&  hasFetch(requestUserById(getPath))
        return () => (unmounted = true);
    },[hasFetch,getPath,props.access])
    const loading       = useSelector(state => state.users.loading)
    const loadingRoles  = useSelector(state => state.users.loadingRoles)
    
    if (!props.access) return <></>
    else if (getPath === undefined && !props.access?.create) return <Error404 />
    else if (getPath !== undefined && !props.access?.update) return <Error404 />
    return (
        <>{(!loading && !loadingRoles) && <Form {...props} />}</>
    )
}

const parseStatus = {
    active:true,
    inactive:false
}

const Form = (props) => {
    document.title = props.title
    const history = props.history
    const { hasFetch } = useAction();
    const [isModalCreate, setIsModalCreate] = useState(false)
    const { id } = useParams()
    const title = id ? 'Edit' : 'Buat Baru'
    const dataBreadcrumbExt = id ? [
        { to: `/user/${id}`, label: 'Detail Pengguna' },
        { to: '', label: 'Edit' }
    ] : [{ to: '', label: 'Buat Baru' }]
    const dataBreadcrumb = [
        { to: '/user', label: 'Pengguna' },
        ...dataBreadcrumbExt
    ]
    
    
    
    
    
    const payload = useSelector(state => {
        return {
            detailData: state.users.detailData,
            previewData: history?.location?.state,
            roles:state.users.roles.map(val=>({label:val.name,value:val.id})),
            loadingCheck: state.users.loadingCheck,
            messageError: state.users.messageError,
            statusCheck: state.users.statusCheck,
            dataFoundNik: state.users.dataFoundNik,
            field: state.users.field
        }
    })

    const [disablePreview, setDisablePreview] = useState(true)

    const [disableInput, setDisableInput ]  = useState(payload.detailData ? false:true)
    const [coordinatorId, setCoordinatorId] = useState(payload?.detailData?.coordinatorId ? payload?.detailData?.coordinatorId : payload?.previewData?.coordinatorId ? payload?.previewData?.coordinatorId : 0  )
    let { value, bindChange, bindSelect, bindChecked, setValue } = useInput({
        initialObjects: {
          name: payload?.detailData?.fullname ? payload?.detailData?.fullname : payload?.previewData?.name ? payload?.previewData?.name : ''  ,
          nik: payload.detailData?.nik ? payload?.detailData?.nik : payload?.previewData?.nik ? payload?.previewData?.nik : '' ,
          
          email:payload.detailData?.email ? payload?.detailData?.email : payload?.previewData?.email ? payload?.previewData?.email : '' ,
          otorisasi:payload.detailData?.roleId ? payload?.detailData?.roleId : payload?.previewData?.otorisasi ? payload?.previewData?.otorisasi : '' ,
          
          status:payload.detailData?.status ? parseStatus[payload?.detailData?.status] : payload?.previewData?.status ? parseStatus[payload?.previewData?.status] : true ,
        },
        identity: "myForm",
      });
    
    useEffect(()=>{
        const disabled = Object.values(value).filter(val=>val==="")
        if(disabled.length){
            
            setDisablePreview(true)
        }else{
            
            setDisablePreview(false)
        }
        
    },[value])

    const handleSubmit = () => {
        const dataUpdate = {
            email:value.email,
            fullname:value.name,
            nik:value.nik,
            roleId:value.otorisasi,
            status:value.status ? 'active' : 'inactive',
            id:payload.detailData.id,
            coordinatorId:coordinatorId
        }
        hasFetch(updateUser(payload.detailData?.id,dataUpdate,history))
        
    }

    const [onSearchNik$] = useState(()=> new Subject())
    const [onSearchEmail$] = useState(()=> new Subject())

    useEffect(()=>{
        onSearchEmail$.pipe(
            tap(()=>hasFetch(resetInputStatus('email'))),
            debounceTime(500),
            distinctUntilChanged(),            
            tap(value=>{
                
                validateInput('myForm','email') && hasFetch(requestCheckEmail({
                    email:value,
                    id:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchEmail$,id])

    useEffect(()=>{
        onSearchNik$.pipe(
            tap(()=>hasFetch(resetInputStatus('nik'))),
            debounceTime(500),
            distinctUntilChanged(),
            tap(value=>{
                validateInput('myForm','nik') && hasFetch(requestCheckNik({
                    nik:value,
                    userId:id??''
                }))
                
            })
        ).subscribe()
    },[hasFetch,onSearchNik$,id])

    useEffect(()=>{
        if(payload.dataFoundNik){
            setValue(prev=>{
                return{
                    ...prev,
                    name:payload.dataFoundNik.name,
                    email:payload.dataFoundNik.email
                }
            })
            setCoordinatorId(payload.dataFoundNik.id)
            setDisableInput(true)
        }else{
            if(payload.field==='nik'){
                setValue(prev=>{
                    return{
                        ...prev,
                        name:'',
                        email:''
                    }
                })
                setCoordinatorId(0)
                hasFetch(resetInputStatus('email'))
            }
            setDisableInput(false)
        }
    },[hasFetch,payload.dataFoundNik,payload.field,setValue])

    const handleChangeNik = (e) => {
        bindChange.onChange(e)
        onSearchNik$.next(e.target.value)
    }

    const handleChangeEmail = (e) => {
        bindChange.onChange(e)
        onSearchEmail$.next(e.target.value)
    }
    

    const handleShowConfirmation = () => {
        if(validateForm('myForm')){
            setIsModalCreate(!isModalCreate)    
        }
        
    }
    
    const handlePreview = () => {
        
        if(validateForm('myForm')){
            const role = payload.roles.find(val=>val.value===value.otorisasi)
            
            const dataPreview = {
                ...value,
                textRole:role.label,
                coordinatorId:coordinatorId,
                status:value.status?'active':'inactive'
            }
            //hasFetch(setDataPreview(dataPreview))
            history.push({
                pathname:'/user/preview',
                state:dataPreview
            })
        }
    }

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form id="myForm" autoComplete="false" >
                    <Segment boxShadow bg={"white"} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>Informasi Pengguna</Text>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>NIK</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.nik ? 'error':'normal'}
                                        helptext={payload.messageError.nik}
                                    >
                                        <Textfield
                                            status={payload.messageError.nik ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangeNik(e),
                                                name:'nik',
                                                placeholder: "NIK",
                                                value: value.nik,
                                                maxLength: 10,
                                                className: 'validate[required,length[10]]',
                                            
                                            }}
                                            right={
                                                payload.loadingCheck.nik ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.nik && payload.statusCheck.nik==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                            
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Nama</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        
                                    >
                                        <Textfield
                                            
                                            inputProps={{
                                                ...bindChange,
                                                name:'name',
                                                placeholder: "Nama",
                                                value: value.name,
                                                maxLength: 100,
                                                className: 'validate[required]',
                                                
                                            }}
                                            disabled={disableInput}
                                            
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Email</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        status={payload.messageError.email ? 'error':'normal'}
                                        helptext={payload.messageError.email}
                                    >
                                        <Textfield
                                            status={payload.messageError.email ? 'error':'normal'}
                                            inputProps={{
                                                onChange:(e)=>handleChangeEmail(e),
                                                placeholder: "Email",
                                                name:'email',
                                                value: value.email,
                                                maxLength: 100,
                                                className: 'validate[required,email]',
                                                
                                            }}
                                            disabled={disableInput}
                                            right={
                                                payload.loadingCheck.email ? <Segment width={24} height={24}>
                                                    <Spinner color="#000000" />
                                                </Segment> 
                                                : (!payload.loadingCheck.email && payload.statusCheck.email==='00') ? <Segment borderRadius={20} style={{backgroundColor: "#8FC742"}} justifyContent={'center'} width={20} height={20}><Icon name='check' size={16} fillColor='white' /></Segment> : ''
                                            }
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>User Authority</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl>
                                        <OptionBox
                                            name="otorisasi"
                                            options={payload.roles ? payload.roles : '' }
                                            placeholder={'User Authority'}
                                            value={payload.roles.find(val=>val.value===value.otorisasi) }
                                            noOptionsMessage={() => 'Tidak Ada Data'}
                                            className='validate[required] validateMessage{required{Otorisasi harus diisi}}'
                                            {...bindSelect}
                                            
                                        />
                                    </FormControl>
                                </Segment>
                            </Row>
                            
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text>Status</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <Switch name="status" label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.status} />
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={"flex-end"}>
                        <Segment>
                            <Link to={id ? `/user/${id}` : "/user"}>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    type={"button"}
                                    mr={16}
                                >
                                    Batal
                                </Button>
                            </Link>
                            {id ?
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handleShowConfirmation()}
                                    disabled={!(!disablePreview && payload.statusCheck.nik==='00' && (payload.statusCheck.email==='00' ? true : payload.dataFoundNik?.email?true : false ))}
                                >
                                    Simpan
                                </Button>
                                :
                                <Button
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    onClick={() => handlePreview()}
                                    disabled={!(!disablePreview && payload.statusCheck.nik==='00' && (payload.statusCheck.email==='00' ? true : payload.dataFoundNik?.email?true : false ))}
                                >
                                    Preview
                                </Button>
                                
                            }
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={"Simpan Perubahan?"}
                content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                                Batal
                            </Button>
                            <Button 
                                type="button"
                                onClick={handleSubmit}
                                minWidth={"100px"}
                            >
                                Simpan
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default UserForm