import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonLink,
  FormControl,
  Icon,
  Segment,
  Spinner,
  Textfield,
  Text,
} from "@elevenia/master-ui/components/Atom";
import { validateForm } from "helper";
import { useInput, useSingleToggle, useAction } from "hooks";
import { useSelector } from "react-redux";
import { requestAuthentication } from "store/actions/authentication";
import { Link } from "react-router-dom";
import { requestVerificationReset } from "store/actions/verifikasi-setup";

const Login = (props) => {
  document.title = props.title;
  const logo = require("assets/image/indopaket-logo.png");
  const { hasFetch } = useAction();
  const [open, setOpen] = useSingleToggle(false);
  const { value, bindChange } = useInput({
    initialObjects: {
      username: "",
      password: "",
    },
    identity: "myForm",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const account = {
      username: value.username,
      password: value.password,
    };
    const valid = validateForm(e.target.id);
    valid && hasFetch(requestAuthentication(account, props.history));
  };
  const loading = useSelector((state) => state.authentication.loading);
  const notification = useSelector(
    (state) => state.setAlerVerification.componentMessage
  );
  return (
    <>
      {notification?.Modal}
      <Segment className="font-nunito wrapper-login">
        <Segment className="wrapper-logo">
          <img src={logo} alt={"Main Logo"} />
        </Segment>
        <Segment>
          <Segment
            className={"login-card"}
            boxShadow
            bg={"white"}
            borderRadius={8}
          >
            <Segment flexDirection={"column"} alignItems={"center"} mb={48}>
              <Text H28 fontWeight={"800"} textAlign={"center"} fontSize={28}>
                Masuk Akun
              </Text>
            </Segment>
            <form id="myForm" onSubmit={handleSubmit} autoComplete="false">
              <FormControl mb={20}>
                <Textfield
                  inputProps={{
                    ...bindChange,
                    type: "text",
                    name: "username",
                    className: "validate[required]",
                    placeholder: "NIK",
                  }}
                  state="normal"
                  model="default"
                />
              </FormControl>
              <FormControl mb={20}>
                <Textfield
                  inputProps={{
                    ...bindChange,
                    type: open ? "text" : "password",
                    name: "password",
                    className: "validate[required]",
                    placeholder: "Kata Sandi",
                    autoComplete: "off"
                  }}
                  right={
                    <ButtonLink type="button" onClick={setOpen}>
                      <Icon
                        name={open ? "visible" : "invisible"}
                        size={16}
                        fillColor="#70727D"
                      />
                    </ButtonLink>
                  }
                  state="normal"
                />
              </FormControl>
              <Segment justifyContent="flex-end">
                <Text fontWeight={"700"} textAlign="right">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/forgot-password");
                      hasFetch(requestVerificationReset());
                    }}
                    to="/"
                  >
                    Lupa Kata Sandi?
                  </Link>
                </Text>
              </Segment>
              <Segment mt={32}>
                <ButtonGroup responsive>
                  <Button
                    disabled={
                      loading ||
                      (value.username.length === 0 &&
                        value.password.length === 0)
                    }
                    type="submit"
                    variant="primary"
                  >
                    {loading ? <Spinner color="#2a93d6" /> : "Masuk"}
                  </Button>
                </ButtonGroup>
              </Segment>
              <Segment mt={24}>
                <Text textAlign="center" fontSize={16}>
                  Belum verifikasi akun? Silahkan verifikasi{" "}
                  <b>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/verifikasi");
                        hasFetch(requestVerificationReset());
                      }}
                      to="/"
                    >
                      disini
                    </Link>
                  </b>
                </Text>
              </Segment>
            </form>
          </Segment>
          <Segment
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={24}
          >
            <Text fontSize={12} fontWeight={700}>
            v{['uat'].includes(process.env.REACT_APP_ENVIRONMENT) ? " " : ''}{process.env.REACT_APP_VERSION}-{['on prem','gcp edts'].includes(process.env.REACT_APP_ENVIRONMENT) ? `${process.env.REACT_APP_BUILD_NUMBER}` : ''} ({process.env.REACT_APP_ENVIRONMENT})
            </Text>
          </Segment>
        </Segment>
      </Segment>
    </>
  );
};

export default Login;
