import React from "react";
import DataTable from 'component/DataTable';
import useSWR from "swr";
import { fetcher, parseValue } from "helper";
import moment from "moment";

const TabRiwayatNik = (props) => {
    const optionsSwr = {
        revalidateOnFocus: false
    };

    const { data: dataStore, error, isValidating } = useSWR((props.idDeliman) && `/driver/api/back-office/nik-history/${props.idDeliman}`, fetcher, optionsSwr);

    const payload = {
        data: dataStore?.data.content,
        loading: !dataStore && !error,
    };

    return <>
        <DataTable
            countingList={true}
            defaultSize={payload.data?.length}
            isLoading={payload.loading || isValidating}
            tableConsume={[{
                field: 'NIK Lama',
                entityFilters: "oldNik",
                isCustomRow: (value) => parseValue(value)
            }, {
                field: 'Tanggal Perubahan',
                entityFilters: "changesDate",
                isCustomRow: (value) => parseValue(value, val => moment(val).format('DD/MM/YY'))
            }, {
                field: 'Tanggal Realisasi NIK Berubah',
                entityFilters: "realisationDate",
                isCustomRow: (value) => parseValue(value, val => moment(val).format('DD/MM/YY'))
            }, {
                field: 'Diubah Oleh',
                entityFilters: "createdBy",
                isCustomRow: (value) => parseValue(value)
            }]}
            dataConsume={payload?.data}
        />
    </>
}

export default TabRiwayatNik