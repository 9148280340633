import React, { useState, useEffect, useRef } from 'react';
import { Services } from 'service';
import { useAction } from 'hooks';
import Error404 from 'page/errorLayout';
import {
  Segment,
  Spinner
} from "@elevenia/master-ui/components/Atom";

const ReportFallback = (props) => {
  document.title = props.title;
  const { hasFetch } = useAction();
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState('idle');
  const iframeElement = useRef(null);

  useEffect(() => {
    Services()
      .get('/order/api//back-office/looker/laporan-absensi')
      .then(({ data }) => {
        setUrl(data?.data.src);
        setStatus('resolved');
      })
      .catch((e) => {
        hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message } });
        setStatus('rejected');
      })
  }, [hasFetch]);

  if (!props.access) return <></>;
  else if (!props.access?.read) return <Error404 />;
  return (
    <>
      {(status === 'idle' || status === 'pending') &&
        <Segment className="u-tx-center" width="100%" height={30}>
          <Spinner />
        </Segment>
      }
      {status === 'resolved' &&
        <iframe
          ref={iframeElement}
          style={{ border: 'none', marginBottom: 24 }}
          id="dashboard"
          title="Dashboard"
          width="100%"
          height="2000px"
          allowFullScreen
          src={url}
        />}
    </>
  );
};

export default ReportFallback;
