import {
    Segment,
    DatePicker,
    FormControl,
    Icon,
    ButtonLink,
    Text,
    ButtonGroup,
    Button,
    OptionBox
} from '@elevenia/master-ui/components/Atom';
import { ModalBody, ModalFooter, ModalHeader, ModalLite, ModalTitle } from "@elevenia/master-ui/components/Molecules";
import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getInitialParams } from 'helper/Order';
import { objectToQueryString, queryStringToObject } from 'helper';
import Select, {components} from 'react-select';
import chevronDown from '../../assets/image/chevron-down.svg'
import cancel from '../../assets/image/cancel.svg'

const mappingService = ({isBulky, isCod, isOrderReturn}) => {
    if(isCod===null && isBulky===null && isOrderReturn===null) return  ''
    if(isCod===undefined && isBulky===undefined && isOrderReturn===undefined) return  ''
    if(!isCod && isBulky && isOrderReturn) return  'isBulkyIsReturn'
    if(isCod && isBulky && isOrderReturn) return  'all'
    if(isCod && isBulky && !isOrderReturn) return  'isBulkyisCod'
    if(!isCod && isBulky && !isOrderReturn) return  'isBulky'
    if(isCod && !isBulky && !isOrderReturn) return  'isCod'
    if(!isCod && !isBulky && !isOrderReturn)  return  '-'
}
const ModalFilter = ({
    isOpen,
    toggle,
    filterChannel,
    filterCategory,
    filterCluster,
    paramsFilter,
    orderStatus,
    doFilter,
    filterServices,
    filterStatus,
    filterReason
}) => {
    const history = useHistory()
    const [isReset, setIsReset] = useState(false)
    const optionsStatus = [
        { value: 'new', label: 'New' },
        { value: 'reassign', label: 'Reassign' }
    ]
    const paramsDate = orderStatus==='COMPLETED' ? 'History' : ''
    const initParamFilter = {
        [`startDate${paramsDate}`]: ['COMPLETED', 'CANCELED', 'RETURN_COMPLETED', 'FALLBACK'].includes(orderStatus) ? moment().format('YYYY-MM-DDT00:00:00.000') : '',
        [`endDate${paramsDate}`]: ['COMPLETED', 'CANCELED', 'RETURN_COMPLETED', 'FALLBACK'].includes(orderStatus) ? moment().format('YYYY-MM-DDT23:59:59.999') : '',
        orderStatus: orderStatus,
        channelOrder: '',
        orderTypeGroup: '',
        serviceType: '',
        clusterId: '',
        orderStatusType: '',
        isCod: null,
        isBulky: null,
        isOrderReturn: null,
        cancelId: null
    }

    const [paramFilter, setParamFilter] = useState(initParamFilter)
    useEffect(()=>{
        if(paramsFilter){
            setParamFilter({    
                [`startDate${paramsDate}`]: paramsFilter[`startDate${paramsDate}`] ? new Date(paramsFilter[`startDate${paramsDate}`]) : "",
                [`endDate${paramsDate}`]: paramsFilter[`endDate${paramsDate}`] ? new Date(paramsFilter[`endDate${paramsDate}`]) : "",
                ...paramsFilter
            })
        }
    },[paramsFilter, paramsDate])

    const handleFilter = () => {
        
        doFilter({
            ...paramFilter,
            page: 0
        })
        if(isReset){
            history.replace({
                search: `?activeTab=${orderStatus}&channelOrder=&orderTypeGroup=`
            })
        }else{
            const queryString = queryStringToObject(history.location.search)
            if(paramFilter.channelOrder){
                
                history.replace({
                    search: objectToQueryString({...queryString, channelOrder: paramFilter.channelOrder})
                })
            }else{
                
                history.replace({
                    search: objectToQueryString({...queryString})
                })
            }
        }
        toggle()
        
    }
    return <ModalLite 
            isOpen={isOpen} 
            toggle={toggle}
            backdrop={'static'}
            style={{ 
                width: 'min(100%, 468px)',
                margin: '10px',
                padding: '24px 24px'
            }}
            >
        <ModalHeader>
            <ModalTitle>
                
                <Text mb={32} H16 >Filter Informasi</Text>
            </ModalTitle>
        </ModalHeader>
        <ModalBody>
            
            <Segment mb={24} display="flex">
                <Segment alignSelf={"center"} flex={"1 1 0"}>
                    <Text color="#70727D">Tanggal</Text>
                </Segment>
                <Segment alignItems={"center"} flex={"1 1 0"}>
                    <FormControl>
                        <DatePicker
                            placeholderText="Tanggal"
                            selected={paramFilter[`startDate${paramsDate}`] ? new Date(paramFilter[`startDate${paramsDate}`]) : ''}
                            onChange={data => {
                                const startDate = moment(data).format('YYYY-MM-DDT00:00:00.000')
                                const endDate = moment(data).format('YYYY-MM-DDT23:59:59.999')
                                setParamFilter(prev => {
                                    return {
                                        ...prev,
                                        [`startDate${paramsDate}`]: data ? startDate : '',
                                        [`endDate${paramsDate}`]: data ? endDate : ''
                                    }
                                })
                            }}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            right={<Icon name={"date"} size={24} />}
                        />
                    </FormControl>
                    
                </Segment>
            </Segment>
            {
                filterReason?.show && 
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Alasan</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                            <OptionBox
                                defaultValue={""}
                                options={filterReason.options}
                                placeholder={"Alasan"}
                                isClearable
                                isDisabled={filterReason.loading}
                                value={paramFilter?.cancelId ? filterReason.options.find(val=>val.value===paramFilter?.cancelId) : ""}
                                onChange={(e)=>{
                                    let value = ''
                                    if(e) value = e.value
                                    setParamFilter(prev=>({
                                        ...prev,
                                        cancelId:value
                                    }))
            
                                }}
                            />
                        </FormControl>
                        
                    </Segment>
                </Segment>
            }
            {
                filterStatus.show && 
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Status</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                            <OptionBox
                                defaultValue={""}
                                options={optionsStatus}
                                placeholder={"Status"}
                                value={paramFilter?.orderStatusType ? optionsStatus.find(val=>val.value===paramFilter?.orderStatusType) : ''}
                                onChange={(e)=>{
                                    let value = ''
                                    if (e) value = e.value
                                    setParamFilter(prev => ({
                                        ...prev,
                                        orderStatusType:value
                                    }))

                                }}
                            /> 
                        </FormControl>
                        
                    </Segment>
                </Segment>
            }
            
            {
                filterChannel.show && 
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Channel</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                            <OptionBox
                                defaultValue={""}
                                options={filterChannel.options}
                                placeholder={"Channel"}
                                isDisabled={filterChannel.loading}
                                value={paramFilter?.channelOrder ? filterChannel.options.find(val=>val.value===paramFilter?.channelOrder) : ""}
                                onChange={(e)=>{
                                    let value = ''
                                    if (e) value = e.value
                                    setParamFilter(prev => ({
                                        ...prev,
                                        channelOrder: value
                                    }))

                                }}
                            /> 
                        </FormControl>
                        
                    </Segment>
                </Segment>
            }
            {
                filterCluster.show &&
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Klaster</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                            <OptionBox
                                defaultValue={""}
                                options={filterCluster.options}
                                placeholder={"Klaster"}
                                isClearable
                                isDisabled={filterCluster.loading}
                                value={paramFilter?.clusterId ? filterCluster.options.find(val=>val.value===paramFilter?.clusterId) : ""}
                                onChange={(e)=>{
                                    let value = ''
                                    if (e) value = e.value
                                    setParamFilter(prev => ({
                                        ...prev,
                                        clusterId: value
                                    }))

                                }}
                            /> 
                        </FormControl>
                        
                    </Segment>
                </Segment>
            }
            {
                filterCategory.show && 
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Kategori</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                            <MultiOptionBox filterCategory={filterCategory} paramFilter={paramFilter} setParamFilter={setParamFilter}/>
                        </FormControl>
                    </Segment>
                </Segment>
            }
            {
                filterServices.show &&
                <Segment mb={24} display="flex">
                    <Segment alignSelf={"center"} flex={"1 1 0"}>
                        <Text color="#70727D">Service</Text>
                    </Segment>
                    <Segment alignItems={"center"} flex={"1 1 0"}>
                        <FormControl style={{width: '100%'}}>
                        <OptionBox
                            defaultValue={""}
                            options={filterServices.options}
                            placeholder={"Service"}
                            isClearable
                            value={filterServices.options.find(val=>val.value===mappingService({
                                isCod: paramFilter?.isCod,
                                isBulky: paramFilter?.isBulky,
                                isOrderReturn: paramFilter?.isOrderReturn
                            }))}    
                            onChange={(e)=>{
                                let value = ''
                                if(e) value = e.value
                                if(value==='isCod'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:true,
                                        isBulky:false,
                                        isOrderReturn:false
                                    }))
                                }
                                else if(value==='isBulky'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:false,
                                        isBulky:true,
                                        isOrderReturn:false
                                    }))
                                }
                                else if(value==='isBulkyisCod'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:true,
                                        isBulky:true,
                                        isOrderReturn:false
                                    }))
                                }
                                else if(value==='isBulkyIsReturn'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:false,
                                        isBulky:true,
                                        isOrderReturn:true
                                    }))
                                }
                                else if(value==='all'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:true,
                                        isBulky:true,
                                        isOrderReturn:true
                                    }))
                                }
                                else if(value==='-'){
                                    setParamFilter(prev=>({
                                        ...prev,
                                        isCod:false,
                                        isBulky:false,
                                        isOrderReturn:false
                                    }))
                                }
                                else{
                                    delete paramFilter.isBulky
                                    delete paramFilter.isCod
                                    delete paramFilter.isOrderReturn
                                    setParamFilter({
                                        ...paramFilter
                                    })
                                }
                                
        
                            }}
                        />
                        </FormControl>
                        
                    </Segment>
                </Segment>
            }
            
        </ModalBody>
        <ModalFooter>
            <Segment width={"100%"} justifyContent="space-between" borderTop="1px solid #DCDEE3" pt={24} >
                <Segment style={{alignSelf: 'center'}}>
                    <ButtonLink onClick={()=>{
                        let initParams = getInitialParams(orderStatus,'','')
                        if(['COMPLETED', 'CANCELED', 'RETURN_COMPLETED', 'FALLBACK'].includes(orderStatus)){
                            initParams = {
                                ...getInitialParams(orderStatus,'',''),
                                [`startDate${paramsDate}`]: ['COMPLETED', 'CANCELED', 'RETURN_COMPLETED', 'FALLBACK'].includes(orderStatus) ? moment().format('YYYY-MM-DDT00:00:00.000') : '',
                                [`endDate${paramsDate}`]: ['COMPLETED', 'CANCELED', 'RETURN_COMPLETED', 'FALLBACK'].includes(orderStatus) ? moment().format('YYYY-MM-DDT23:59:59.999') : '',
                            }
                        }
                        
                        setParamFilter(initParams)
                        setIsReset(true)
                    }
                    }>Reset Filter</ButtonLink>
                </Segment>
                <Segment>
                    <ButtonGroup>
                        <Button
                            variant={"secondary"}
                            onClick={toggle}
                            style={{ borderRadius: 4, minWidth: "67px" }}
                        >
                            Batal
                        </Button>
                        <Button
                            onClick={handleFilter}
                            style={{ borderRadius: 4, minWidth: "92px" }}
                        >
                            Terapkan
                        </Button>
                    </ButtonGroup>
                </Segment>
            </Segment>
            
        </ModalFooter>
    </ModalLite>
}

const MultiOptionBox = ({filterCategory, paramFilter, setParamFilter}) => {
    const selected = paramFilter?.serviceType ? paramFilter?.serviceType.split(',') : null;

    return (
        <Select
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '40px',
                    borderBottom: state.menuIsOpen ? 'none' : `1px solid`,
                    borderColor: state.isFocused ? '#1178D4!important' : '#DCDEE3!important',
                    borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
                    boxShadow: 'none'
                  }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingLeft: '16px'
                  }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingRight: '8px',
                    color: '#70727D'
                }),
                indicatorSeparator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: state.hasValue ? 'visible' : 'none'
                }),
                clearIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingRight: '6px',
                    cursor: 'pointer'
                }),
                dropdownIndicator: (baseStyles, state) => {
                    return ({
                    ...baseStyles,
                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)!important' : 'rotate(0deg)'
                })}
            }}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValue={""}
            options={filterCategory.options}
            isClearable
            isMulti
            isDisabled={filterCategory.loading}
            placeholder={"Kategori"}
            value={selected 
                ? filterCategory.options.filter((category) => selected.includes(category.value))
                : []}
            onChange={(e)=>{
                let value = ''
                if (e) value = e.map((item) => item.value).toString()
                setParamFilter(prev => ({
                    ...prev,
                    serviceType: value
                }))
            }}
            
            components={{
                Option,
                ValueContainer,
                DropdownIndicator,
                ClearIndicator
              }}
        /> 
    )
}

const DropdownIndicator = ({children, ...props}) => {
    return (
        <components.DropdownIndicator {...props}>
            <div style={{ width: '16px', height: '16px' }}>
                <img src={chevronDown} alt='chevron down' style={{maxWidth: '100%'}}/>
            </div>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = ({children, ...props}) => {
    return (
        <components.ClearIndicator {...props}>
            <div style={{width: '20px', height: '20px'}}>
                <img src={cancel} alt='cancel' style={{maxWidth: '100%'}}/>
            </div>
        </components.ClearIndicator>
    )
}

const ValueContainer = ({children, ...props}) => {
    const [categoryLength, setCategoryLength] = useState(0)
    useEffect(() => {
        setCategoryLength(props.getValue().length)
    }, [props]);
    
    return (
        <components.ValueContainer {...props}>
            {categoryLength 
            ? 
                <>
                    <span style={{color: '#333333'}}>{categoryLength} Kategori</span>
                    {children[1]}
                </>
            : 
                [...children]
            }
        </components.ValueContainer>
    )
}

const Option = ({
    children,
    innerProps,
    ...props
  }) => {
  
    // styles
    const style = {
      alignItems: "center",
      backgroundColor: 'transparent',
      color: "inherit",
      display: "flex ",
      padding: '0px 8px'
    };
  
    // prop assignment
    const customInnerProps = {
      ...innerProps,
      style
    };
  
    return (
      <components.Option
        {...props}
        innerProps={customInnerProps}
      >
        <div style={{backgroundColor: props.isFocused && '#eff3f6', width: '100%', padding: '8px 12px', borderRadius: '4px', display: 'flex', alignItems:'center'}}>
            <input onChange={() => {}} type="checkbox" checked={props.isSelected} style={{marginRight: '12px'}}/>
            {children}
        </div>
      </components.Option>
    );
  };

export default ModalFilter;