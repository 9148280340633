
const initialState = {
    data: [],
    totalPages:0,
    parameter:null,
    loading: false,
  }
  
  export const photoReport = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_PHOTO_REPORT':
            return{
                ...state,
                loading:true
            }
        case 'GET_PHOTO_REPORT':
            return{
                ...state,
                loading:false,
                data:action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,
            }
        case 'RESET_DATA_LIST':
            return{
                ...state,
                data:[]
            }
        
        default:
            return state
    }
  }