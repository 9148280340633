export const requestDeliveryMan = properties => {
  return {
      type: "REQUEST_DELIVERY_MAN",
      payload: { properties }
  }
}


export const requestDeliveryManById = id => {
  return {
      type: "REQUEST_DELIVERY_MAN_BY_ID",
      payload: id
  }
}

export const requestAddDeliveryMan = (params,history) => {
  return {
      type: "REQUEST_CREATE_DELIVERY_MAN",
      payload:{params},
      history
  }
}

export const requestUpdateDeliveryMan = (id,params, history)=> {
  return {
      type: "REQUEST_UPDATE_DELIVERY_MAN",
      payload:{
        params,
        id
      },
      history
  }
}

export const requestCheckNik = (params)=> {
  return {
      type: "REQUEST_CHECK_NIK",
      payload:{
        params
      }
  }
}

export const requestCheckEmail = (params)=> {
  return {
      type: "REQUEST_CHECK_EMAIL",
      payload:{
        params
      }
  }
}

export const requestCheckPhone = (params)=> {
  return {
      type: "REQUEST_CHECK_PHONE",
      payload:{
        params
      }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS",
      payload:{
        field:name
      }
  }
}

export const setStatusField = (status) => {
  return {
      type: "SET_STATUS_FIELD_DELIVERY_MAN",
      payload:{
        status:status?'00':''
      }
  }
}

export const requestPhotoProfile = (url) => {
  return {
      type: "REQUEST_PHOTO_PROFILE",
      payload:{
        url
      }
  }
}

export const resetPhotoProfile = () => {
  return {
      type: "RESET_FILE_UPLOAD",
      
  }
}

