import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'


export const RequestCluster = action$ =>
    action$.pipe(
        ofType( 'REQUEST_LIST_CLUSTER',
                'REQUEST_CLUSTER_BY_ID',
                'REQUEST_CREATE_CLUSTER',
                'REQUEST_UPDATE_CLUSTER',
                'REQUEST_STORE_BY_CLUSTER',
                'REQUEST_DELIMAN_BY_CLUSTER',
                'REQUEST_CHECK_CLUSTER_CODE',
                'REQUEST_AVAILABLE_CLUSTER_CODE'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_LIST_CLUSTER':
                        const rawResponse = await Services().get('/store/api/back-office/clusters',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_CLUSTER',
                                payload: {
                                    data: rawResponse.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_CLUSTER_BY_ID':
                        const rawResponseDetail = await Services().get(`/store/api/back-office/clusters/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_CLUSTER_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                
                                }
                            })
                            dispatch({
                                type: 'REQUEST_STORE_BY_CLUSTER',
                                payload: {
                                    properties: {
                                        unpaged:true
                                    },
                                    id:action.payload
                                }
                            })
                            dispatch({
                                type: 'REQUEST_DELIMAN_BY_CLUSTER',
                                payload: {
                                    properties: {
                                        unpaged:true
                                    },
                                    id:action.payload
                                }
                            })
                        }

                    case 'REQUEST_CREATE_CLUSTER':
                        await Services().post(`/store/api/back-office/clusters`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push('/klaster')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Klaster baru' } })
                        }
                    case 'REQUEST_UPDATE_CLUSTER':
                        await Services().put(`/store/api/back-office/clusters`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/klaster/${action.payload.params.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Klaster berhasil' } })
                        }
                    case 'REQUEST_STORE_BY_CLUSTER':
                        const rawStoreByCluster = await Services().get(`/store/api/back-office/clusters/${action.payload.id}/stores`,action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_STORE_BY_CLUSTER',
                                payload: {
                                    data: rawStoreByCluster.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawStoreByCluster.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_DELIMAN_BY_CLUSTER':
                        const rawDelimanByCluster = await Services().get(`/driver/api/back-office/search-by-cluster/${action.payload.id}`,action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DELIMAN_BY_CLUSTER',
                                payload: {
                                    data: rawDelimanByCluster.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawDelimanByCluster.data.data.totalPages,
                                    
                                }
                            })
                        }
                    case 'REQUEST_CHECK_CLUSTER_CODE':
                        try {
                            const rawCheckClusterCode = await Services().get(`/store/api/back-office/clusters/validate`,action.payload.params)
                            return dispatch => {
                                dispatch({
                                    type: 'GET_STATUS_CLUSTER_CODE',
                                    payload: {
                                        status: rawCheckClusterCode.data.status,
                                        
                                    }
                                })
                            }    
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_CLUSTER_CODE',
                                    payload: {
                                        message: {clusterCode:error.message},
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_AVAILABLE_CLUSTER_CODE':
                        try {
                            const rawCheckAvailableClusterCode = await Services().get(`/store/api/back-office/clusters/available-code-by-postal`,action.payload.params)
                            return dispatch => {
                                dispatch({
                                    type: 'GET_AVAILABLE_CLUSTER_CODE',
                                    payload: {
                                        availableClusterCode: rawCheckAvailableClusterCode.data.data.code,
                                        //availableClusterCode: 'ABCDEF1',
                                    }
                                })
                            }    
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_AVAILABLE_CLUSTER_CODE',
                                    payload: {
                                        message: {clusterCode:error.message},
                                        status:error.status
                                        
                                    }
                                })
                            } 
                        }
                        
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_CLUSTER_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
