import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    Icon,
    FormControl,
    Row,
    Textfield,
    DatePicker
} from '@elevenia/master-ui/components/Atom'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import Error404 from 'page/errorLayout'
import { fetcher, validateForm, validateInput } from 'helper'
import useSWR from 'swr'
import Spinner from '@elevenia/master-ui/components/Atom/Spinner'
import moment from 'moment'
import { requestDeleteJadwal, requestUpdateJadwal } from 'store/actions/configRKBSpv'
import { useAction, useInput } from 'hooks'

const FormUbahJadwal = props => {
    const { hasFetch } = useAction()
    document.title = props.title
    const id = props.match.params.id;
    const [isModal, setIsModal] = useState(false)
    const [disabledSimpan, setDisabledSimpan] = useState(true)
    const optionsSwr = {
        revalidateOnFocus:false,
        shouldRetryOnError: false
    }
    const { data:respDataDetail, error } = useSWR(props.access?.read &&  `/user/api/back-office/rkb-spv/${id}`,fetcher,optionsSwr)
    const dataDetail = respDataDetail?.data
    
    const title = 'Ubah Jadwal'
    const dataBreadcrumb = [
        { to: '/pengaturan-rkb-spv', label: 'Pengaturan RKB Spv' },
        { to: '', label: title }
    ]

    const handleDelete = () => {
        hasFetch(requestDeleteJadwal(id,props.history))
    }
    let { value, bindChange, setValue } = useInput({
        initialObjects: {
            storeCode: dataDetail? dataDetail.storeCode : '',
            date: dataDetail? new Date(dataDetail.date) : null,
            
        },
        identity: "myForm",
    });

    useEffect(()=>{
        if(dataDetail){
            setValue({
                storeCode: dataDetail? dataDetail.storeCode : '',
                date: dataDetail? new Date(dataDetail.date) : null,
            })
        }
    },[dataDetail, setValue])

    useEffect(()=>{
        const disabled = Object.keys(value).filter(val => value[val]==="" || value[val]===null)
        
        if (disabled.length > 0) {
            
            setDisabledSimpan(true)
        } else {
            
            setDisabledSimpan(false)
        }
    },[value])

    const handleSimpan = () => {
        if(validateForm('myForm')){
            const dataUpdate = {
                date:moment(value.date).format('DD/MM/YYYY'),
                id:id,
                storeCode: value.storeCode
            }
            
            hasFetch(requestUpdateJadwal(dataUpdate,props.history))
        }
        
    }

    
    
    
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    else if (error) return <Error404 />
    else if (!dataDetail && !error) return <Segment width={'100%'} height="40px" ><Spinner/></Segment>
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between" className="xs-flex-wrap" mb={26}>
                            <Segment>
                                <Text fontWeight={600}>Informasi Clock In</Text>
                            </Segment>
                            <Segment>
                                <Button
                                    variant={"secondary"}
                                    style={{ color: "#151823", borderColor: "#DCDEE3", fontSize: 12 }}
                                    onClick={() => setIsModal(!isModal)}
                                >
                                    <Icon name={'delete'} size={16} fillColor="#70727D" mr={6} />
                                    Hapus jadwal
                                </Button>
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Supervisor Delivery Man</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataDetail.supervisorNik} - {dataDetail.supervisorName}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kode Toko</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataDetail.storeCode}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Tanggal</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {dataDetail.date ? moment(dataDetail.date).format('DD/MM/YY') : '-'}
                            </Segment>
                        </Segment>
                    </Segment>
                </Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mt={24}>
                    <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Perubahan Informasi</Text>
                        </Segment>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Kode Toko</Text>
                            </Segment>
                            <Segment flex={1}>
                                <FormControl>
                                    <Textfield
                                        inputProps={{
                                            ...bindChange,
                                            name: 'storeCode',
                                            placeholder: "Kode Toko",
                                            value: value.storeCode,
                                            maxLength: 50,
                                            className: 'validate[required,maxLength[50]] validateMessage{required{Kode Toko harus diisi}}',
                                            
                                        }}
                                    />
                                </FormControl>
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Tanggal</Text>
                            </Segment>
                            <Segment width={260}>
                                <FormControl>
                                    <DatePicker
                                        onChange={e=>{
                                            setValue(prev=>{
                                                return{
                                                    ...prev,
                                                    date: e
                                                }
                                            })
                                            setTimeout(()=>{
                                                validateInput('myForm', 'date')
                                            })
                                            
                                        }}
                                        placeholderText={"Pilih Tanggal"}
                                        selected={value.date}
                                        startDate={value.date}
                                        minDate={new Date()}
                                        name="date"
                                        className='validate[required]'
                                        right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                    />
                                </FormControl>
                            </Segment>
                        </Row>
                    </Segment>
                    </form>
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        <Link to={`/pengaturan-rkb-spv`}>
                            <Button variant={"secondary"}>Batal</Button>
                        </Link>
                        <Button onClick={handleSimpan} disabled={disabledSimpan} variant={"primary"}>Simpan</Button>
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Jadwal?"}
                content={<Segment py={8}>
                    Jadwal akan dihilangkan dari sistem
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant="secondary">
                                Batal
                            </Button>
                            <Button onClick={handleDelete} minWidth={"100px"} variant="primary">
                                Hapus
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default FormUbahJadwal