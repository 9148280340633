import React, { useState, useEffect } from "react"
import {
    Segment,
    Icon,
    FormControl,
    Textfield,
    OptionBox,
    Button,
    ButtonGroup
} from "@elevenia/master-ui/components/Atom"
import ModalConfirm from 'component/ModalCustom/modalSmall';

import DataTable from 'component/DataTable'

import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestReleaseLockingApproval, requestReleaseLockingApprovalInterval, requestSubmitReleaseLockingApproval } from 'store/actions/release-locking';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from "moment"


const TabDaftarPermintaan = (props) => {
    const { hasFetch } = useAction();
    const [isModalConfirm, setIsModalConfirm] = useState({ isOpen: false, setujui: null, id: null });
    const [status, hasStatus] = useState("hasLoad");
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        type: ""
        // sort: "closingTime,asc",

    });
    const payload = useSelector(state => {
        return {
            data: state.releaseLocking.data,
            loading: state.releaseLocking.loading,
            totalPages: state.releaseLocking.totalPages,
            parameter: state.releaseLocking.parameter,
            statName: state.setAlertsToast.notify.statName,
            listType: state.homesidemenu?.action_group
                ?.find((val) => val.code === 'release-locking')
                ?.actions?.filter((action) =>
                    action.code.includes('locking-type')
                )
        }
    })

    useEffect(() => {
        status === "hasLoad" && props.access?.read && hasFetch(requestReleaseLockingApproval(isTable))
        status === "hasPagination" && props.access?.read && hasFetch(requestReleaseLockingApproval(isTable))
    }, [hasFetch, isTable, status, props.access])


    useEffect(() => {
        let intervalId = null
        if (props.access?.read) {
            intervalId = setInterval(() => {
                hasFetch(requestReleaseLockingApprovalInterval())
            }, 30000)

        }

        return () => {
            clearInterval(intervalId)
        }
    }, [hasFetch, props.access])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestReleaseLockingApproval(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = (e) => {
        let { name, value } = e.target;
        setTable((prev) => ({
            ...prev,
            [name]: value?.trim(),
            page: 0
        }))
        onSearch$.next({
            ...isTable,
            [name]: value.trim(),
            page: 0
        });
    }

    const refetchList = () => {
        setIsModalConfirm({ isOpen: false });
        hasFetch(requestReleaseLockingApproval(isTable));
    };

    const handleSubmitApproval = (setujui, requestId) => {
        hasFetch(requestSubmitReleaseLockingApproval(setujui, requestId, refetchList));
    }

    const tableConfiguration = [{
        field: 'ID Release',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.releaseCode}</>
            )
        }
    }, {
        field: 'NIK Delivery Man',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.deliverymanNik}</>
            )
        }
    }, {
        field: 'Delivery Man',
        headerStyle: { minWidth: '150px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.deliverymanName}</>
            )
        }
    }, {
        field: 'Tipe Release',
        headerStyle: { minWidth: '150px' },
        isCustomRow: (id, entity) => {
            return (
                <>{payload.listType?.find((type) => type.value === entity.type)?.label}</>
            )
        }
    }, {
        field: 'Order',
        headerStyle: { minWidth: '120px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.totalOrder} Order</>
            )
        }
    }, {
        field: 'Periode Berlaku',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.openingTime ? `${moment(entity.openingTime).format('DD/MM/YY')}` : '-'}</>
            )
        },

    }, {
        field: 'Pembuat Release',
        headerStyle: { minWidth: '210px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.username ? `${entity.userNik} - ${entity.username}` : '-'}</>
            )
        }
    }, {
        field: 'Action',
        headerStyle: { minWidth: '200px' },
        isCustomRow: (_, entity) => {
            return (
                <Segment display="flex" flexGap="5">
                    <Button
                        variant="primary"
                        style={{ minWidth: 'unset', padding: '6px 16px' }}
                        onClick={() => setIsModalConfirm({ isOpen: true, setujui: true, id: entity.id })}
                    >
                        <span className="u-tx-b2">
                            Setujui
                        </span>
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ marginLeft: '16px', minWidth: 'unset', padding: '6px 16px' }}
                        onClick={() => setIsModalConfirm({ isOpen: true, setujui: false, id: entity.id })}
                    >
                        <span className="u-tx-b2">
                            Tolak
                        </span>
                    </Button>
                </Segment>
            )
        }
    }];

    return <>
        <Segment boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex" justifyContent="space-between">
            <Segment width={"30%"} className={"fw500"} style={{ overflowX: 'hidden' }}>
                <FormControl>
                    <Textfield
                        inputProps={{
                            name: "keyword",
                            onChange: e => handleSearch(e),
                            placeholder: 'Cari NIK atau Nama Delivery Man'
                        }}
                        left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                    />
                </FormControl>
            </Segment>
            <Segment width={"20%"}>
                <FormControl>
                    <OptionBox
                        placeholder={'Tipe Release'}
                        name='type'
                        className='validate[required]'
                        isClearable
                        options={payload?.listType}
                        onChange={(e) => handleSearch({
                            target: {
                                ...e,
                                value: e?.value ?? '',
                                name: 'type'
                            }
                        })}
                    />
                </FormControl>
            </Segment>
        </Segment>
        <DataTable
            countingList={true}
            defaultSize={isTable.size}
            tableConsume={tableConfiguration}
            dataConsume={payload?.data}
            showSize={true}
            showPagination={true}
            showSearch={false}
            totalPages={payload?.totalPages}
            activePage={isTable.page}
            createShowPerSize={(size) => setTable(prev => {
                return {
                    ...prev,
                    ...payload.parameter,
                    size: size.value
                }
            })}
            createPaginations={(page) => {
                const query = {
                    ...payload.parameter,
                    ...page
                }
                hasStatus('hasPagination')
                setTable(query)
            }}
        />

        {/* modal confirm */}
        <ModalConfirm
            isOpen={isModalConfirm.isOpen}
            onClose={() => setIsModalConfirm({ isOpen: false })}
            title={`${isModalConfirm.setujui ? 'Setujui' : 'Tolak'} Permintaan Release Lock?`}
            content={
                <Segment py={8}>
                    {isModalConfirm.setujui
                        ? 'Jika menyetujui, release lock akan diberikan kepada deliveryman terkait.'
                        : 'Jika menolak, release lock tidak akan diberikan kepada deliveryman terkait.'}
                </Segment>
            }
            ButtonFooter={
                <ButtonGroup>
                    <Button
                        minWidth={'100px'}
                        variant={'secondary'}
                        onClick={() => setIsModalConfirm(!isModalConfirm)}
                    >
                        Batal
                    </Button>
                    <Button
                        disabled={payload?.loading}
                        onClick={() => {
                            !payload?.loading && handleSubmitApproval(isModalConfirm.setujui, isModalConfirm.id);
                        }}
                        minWidth={'100px'}
                    >
                        {isModalConfirm.setujui ? 'Setujui' : 'Tolak'}
                    </Button>
                </ButtonGroup>
            }
        />
    </>
}

export default TabDaftarPermintaan