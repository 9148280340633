import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestUsers = action$ =>
    action$.pipe(
        ofType( 'REQUEST_USERS',
                'REQUEST_USER_BY_ID',
                'REQUEST_USER_ROLES',
                'CREATE_NEW_USER',
                'UPDATE_USER',
                'REQUEST_DELETE_USER',
                'REQUEST_CHECK_NIK_USER',
                'REQUEST_CHECK_EMAIL_USER',
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_USERS':
                        const rawResponse = await Services().get('/user/api/user',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_USERS',
                                payload: {
                                    data: rawResponse.data.data.result.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.result.totalPages,
                                    filterRoles: rawResponse.data.data.roles,
                                    filterStatus: rawResponse.data.data.status,
                                }
                            })
                        }
                    case 'REQUEST_USER_BY_ID':
                        const rawResponseDetail = await Services().get(`/user/api/user/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_USER_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                                    
                                }
                            })
                            // dispatch({
                            //     type: 'REQUEST_CHECK_NIK_USER',
                            //     payload: {
                            //         params: {
                            //             id:action.payload,
                            //             nik:rawResponseDetail.data.data.nik
                            //         },
                
                            //     }
                            // })
                        }
                        
                    case 'REQUEST_USER_ROLES':
                        const rawResponseRoles = await Services().get(`/user/api/role`,action.payload.params)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_USER_ROLES',
                                payload: {
                                    data: rawResponseRoles.data.data.content,
                
                                }
                            })
                        }
                    case 'CREATE_NEW_USER':
                        await Services().post(`/user/api/user/new`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push('/user')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Pengguna baru' } })
                        }
                    case 'UPDATE_USER':
                        await Services().put(`/user/api/user/${action.payload.id}`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/user/${action.payload.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Pengguna berhasil' } })
                        }
                    case 'REQUEST_DELETE_USER':
                        await Services().delete(`/user/api/user/${action.payload.id}`)
                        
                        return dispatch => {
                            action.history.push('/user')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Hapus Pengguna berhasil' } })
                        }
                    case 'REQUEST_CHECK_NIK_USER':
                        try {
                            const rawCheckNik = await Services().get(`/user/api/user/find-by-nik/${action.payload.params.nik}?userId=${action.payload.params.userId}`)
                            
                            if(rawCheckNik.data.status==="00"){
                                const dataUser = rawCheckNik.data.data ? rawCheckNik.data.data[0] : null
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD_USER',
                                        payload: {
                                            field:'nik',
                                            status: '00',
                                            message:'',
                                            data:dataUser
                                        }
                                    })
                                    // if(dataUser){
                                    //     dispatch({
                                    //         type: 'REQUEST_CHECK_EMAIL_USER',
                                    //         payload: {
                                    //             params:{
                                    //                 email:dataUser.email,
                                    //                 id:dataUser.id
                                    //             }
                                                
                                    //         }
                                    //     }) 
                                    // }
                                }
                            }else{
                                
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_USER',
                                        payload: {
                                            field:'nik',
                                            message: 'NIK sudah digunakan',
                                            status:'03',
                                            data:null
                                        }
                                    })
                                }
                            }
                                
                        } catch (error) {
                            
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD_USER',
                                    payload: {
                                        field:'nik',
                                        message: error.message,
                                        status:error.status,
                                        data:null
                                    }
                                })
                            }
                        }
                    case 'REQUEST_CHECK_EMAIL_USER':
                        try {
                            const rawCheckEmail = await Services().get(`/user/api/user/check-email/${action.payload.params.email}`,{id:action.payload.params.id})
                            
                            if(rawCheckEmail.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD_USER',
                                        payload: {
                                            field:'email',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD_USER',
                                        payload: {
                                            field:'email',
                                            message: 'Email sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }  
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD_USER',
                                    payload: {
                                        field:'email',
                                        message: error.message ?  error.message:'Server Error Not Found',
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_USERS_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
