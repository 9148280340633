import React from 'react'
import {
    Segment,
} from "@elevenia/master-ui/components/Atom"
import { timeSlot } from 'helper'
const TimeSlot = ({data, timezoneLabel}) => {
    const startSlot = data.serviceType==="EXPRESS" ? data.minDeliveryTime : data.startSlotHour
    const endSlot = data.serviceType==="EXPRESS" ? data.maxDeliveryTime : data.endSlotHour
    return(
        <Segment style={{ whiteSpace: "nowrap" }}>{timeSlot(startSlot, endSlot)} {timezoneLabel}</Segment>
    )
}

export default TimeSlot