import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    FormControl,
    Row,
    Icon,
    DatePicker,
    OptionBox,
    Spinner,
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import { Services } from 'service'
import AsyncSelect from 'react-select/async';
import { useInput, useAction } from 'hooks';
import { validateForm, debounce, generateOptionDate } from 'helper';
import moment from 'moment'



const InsentifDeliman = (props) => {
    if (!props.access) return <></>
    else if (!props.access?.create) return <Error404 />

    return (
        <App {...props} />
    )
}
const App = (props) => {
    document.title = props.title
    const [loadingSubmit, setLoadingSubmit] = useState('idle')
    const [loadingGetData, setLoadingGetData] = useState('idle')
    const [tempDate, setTempDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState([])
    const dataBreadcrumb = [
        { to: '', label: 'Konfigurasi Insentif Delivery Man' }
    ]

    const listOptions = generateOptionDate(moment().subtract(3,'M'),3,'YYYY-MM-DD').map(val=>({value:val, label: moment(val,'YYYY-MM-DD').format('MMMM YYYY')}))

    const { hasFetch } = useAction();
    const [isModalCreate, setIsModalCreate] = useState(false)
    const payload = useSelector(state => {
        return {
            dataError: state.deliverymanCadangan.dataError,
            loading: state.deliverymanCadangan.loading,
        }
    })

    const initialValue = {
        excludedDate: '',
        excludedDay: [],
        excludedDeliveryMan: []
    }

    let { value, setValue } = useInput({
        initialObjects: initialValue,
        identity: "myForm",
    });

    
    
    const handleChangeDate = (value) => {
        setTempDate(value)
    }

    const handleConfirmation = () => {
        if(validateForm('myForm')){
            setIsModalCreate(!isModalCreate)    
        }
    }
    
    const handleSubmit = () => {
        setLoadingSubmit('pending')
        const dataSubmit = {
            excludedDate: value.excludedDate,
            excludedDay: value.excludedDay.map(val=>parseInt(moment(val,'DD MMMM').format('D'))),
            excludedDeliveryMan: value.excludedDeliveryMan.map(val=>({
                nik: val.nik,
                name: val.name
            }))
        }
        Services().post('/driverperformance/api/back-office/insentif-configuration/',dataSubmit)
        .then(()=>{
            setIsModalCreate(false)
            setLoadingSubmit('resolved')
            hasFetch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil memperbaharui data.' } })
            
        })
        .catch((error)=>{
            setIsModalCreate(false)
            setLoadingSubmit("rejected")
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
            
        })

    }

    useEffect(() => {
        if (payload.loading === false) setIsModalCreate(false)
    }, [payload.loading])

    const optionBoxStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            padding: "2px 8px"
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        })
    }
    const Chip = ({ data = [], setData }) => {
        

        const deleteChip = (val) => {
            let index = data?.indexOf(val);
            let findIndex = data?.filter((_, i) => i !== index)
            setData(prev=>({
                ...prev,
                excludedDay: findIndex
            }))
            setSelectedDate(findIndex)
        };

        let generateChips = data?.map((value, index) => {
            return <Segment className="component-chip" key={index}>
                {value}
                <button
                    className="u-cr-pointer u-ds-inline-flex"
                    onClick={() => deleteChip(value)}
                >
                    <Icon name={"cancel"} fillColor="#70727D" size={16} />
                </button>
            </Segment>
        })

        if (data) return generateChips;
    }
    
    const handleChangeMonth = async (e) => {
        if(e){
            setLoadingGetData('pending')
            setValue(prev=>({
                ...prev,
                excludedDate: e.value
            }))
            setTempDate(null)
            try {
                
                const getExistingData = await Services().get(`/driverperformance/api/back-office/insentif-configuration/${e.value}`)
                const data = getExistingData.data.data
                
                setLoadingGetData('resolved')
                if(data){
                    const excludedDay = data.excludedDay ? data.excludedDay.map(val=>{
                        const dataMonth = moment(data.excludedDate,'YYYY-MM-DD').format('MMMM')
                        const day = moment(val,'D').format('DD')
                        return `${day} ${dataMonth}`
                    }) : []
                    setValue({
                        excludedDate: data.excludedDate,
                        excludedDay,
                        excludedDeliveryMan: data.excludedDeliveryMan?.length > 0 ? data.excludedDeliveryMan.map(val=>({
                            value: val.nik,
                            label: `${val.nik} - ${val.name}`,
                            nik: val.nik,
                            name: val.name
                        })) : []
                    })
                    setSelectedDate(excludedDay)
                }else{
                    setValue({
                        ...initialValue,
                        excludedDate: e.value
                    })
                    setSelectedDate([])
                }
                
            } catch (error) {
                setLoadingGetData('rejected')
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
            }
        }
        
    }
    
    const handleAddDate = () => {
        const formatedDate = moment(tempDate).format('DD MMMM')
        if(!value.excludedDay.includes(formatedDate)){
            setValue(prev=>({
                ...prev,
                excludedDay:[...selectedDate, formatedDate] 
            }))
            setSelectedDate([...selectedDate, formatedDate])
            
            
        }
        setTempDate(null)
    }

    const handleLoadOptionDriver = debounce(async (inputValue, callback) => {
        
        const response = await Services().get(`/driver/api/back-office/search-by-name-or-nik/${inputValue}`)
        const listData = response.data.data.content

        callback(listData?.map(val => ({
            label: `${val.nik} - ${val.name}`,
            value: val.nik,
            ...val
        })))
       
    },500)

    

    const handleChangeDeliveryMan = (data) => {
        setValue(prev=>({
            ...prev,
            excludedDeliveryMan: data
        }))
    }

    const handleReset = () => {
        setValue(initialValue)
        setTempDate('')
    }
    return <>
        <Segment mb={30}>
            <Text H28 mb={"1rem"}>Insentif Delivery Man</Text>
            <Breadcrumb data={dataBreadcrumb} />
        </Segment>

        <Segment>
            <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                <Segment boxShadow bg={"white"} borderRadius={4} p={24}>
                    <Segment>
                        <Segment justifyContent="space-between" mb={32}>
                            <Text fontWeight={600} >Informasi Parameter</Text>
                        </Segment>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Bulan Exclude</Text>
                            </Segment>
                            <Segment flex={1} minWidth={204}>
                                <OptionBox
                                    options={listOptions}
                                    placeholder={"Pilih Bulan"}
                                    onChange={handleChangeMonth}
                                    value={value?.excludedDate ? listOptions.find(val=>value.excludedDate===val.value) : ''}
                                />
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Tanggal Exclude</Text>
                            </Segment>
                            <Segment flex={1} justifyContent={"space-between"} className="group-date">
                                <Segment alignItems={"center"} minWidth={204}>

                                    <Segment display="flex" width={"100%"} className={"fw500"}>
                                        <Segment maxWidth={150} width={"100%"} className={"fw500"} mr="12px">
                                            <FormControl>
                                                <DatePicker
                                                    disabled={value?.excludedDate?false:true}
                                                    placeholderText={"Pilih Tanggal"}
                                                    onChange={(date) => handleChangeDate(date)}
                                                    minDate={new Date(moment(value.excludedDate,'YYYY-MM-DD').startOf('M'))}
                                                    maxDate={new Date(moment(value.excludedDate,'YYYY-MM-DD').endOf('M'))}
                                                    left={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                                    selected={tempDate}
                                                    dateFormat="dd MMMM yyyy"
                                                />
                                            </FormControl>
                                        </Segment>

                                        <Button
                                            onClick={handleAddDate}
                                            variant="primary"
                                            size="medium"
                                            type={"button"}
                                            disabled={(value?.excludedDate && tempDate ) ? false : true}

                                        >
                                            Tambahkan
                                        </Button>

                                    </Segment>
                                </Segment>
                            </Segment>
                        </Row>
                        <Row mb={16} alignItems="center">
                            <Segment width={280} py={8}>
                                <Text>Tanggal Terpilih</Text>
                            </Segment>
                            <Segment flex={1} display="flex" minWidth={204}>
                                <Text mr={8}>:</Text>
                                {
                                    loadingGetData==="pending" ? 
                                    <Segment className="u-tx-center" width="100%" height={30}>
                                        <Spinner/>
                                    </Segment> : <Chip
                                                        data={value.excludedDay}
                                                        setData={setValue}
                                                    />
                                }
                                
                                
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Nama Delivery Man Exclude</Text>
                            </Segment>
                            <Segment flex={1} minWidth={204}>
                                <FormControl>
                                    <AsyncSelect
                                        onChange={handleChangeDeliveryMan}
                                        isClearable
                                        loadOptions={handleLoadOptionDriver}
                                        defaultInputValue={value.excludedDeliveryMan.length > 0 ? value.excludedDeliveryMan : ""}
                                        value={value.excludedDeliveryMan.length > 0 ? value.excludedDeliveryMan : ""}
                                        placeholder={'Update Value'}
                                        styles={optionBoxStyles}
                                        isMulti
                                        isDisabled={loadingGetData==="pending"}
                                        isLoading={loadingGetData==="pending"}
                                    />
                                </FormControl>
                                
                            </Segment>
                        </Row>
                    </Segment>
                </Segment>
                <Segment mt={32} justifyContent={"flex-end"}>
                    <Segment>
                        <Button
                            variant="secondary"
                            size="medium"
                            type={"button"}
                            mr={16}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="primary"
                            size="medium"
                            type={"button"}
                            onClick={() => handleConfirmation()}
                            disabled={!((value.excludedDeliveryMan.length!==0 || value.excludedDay.length!==0) && value.excludedDate!=="" )}
                        >
                            Simpan
                        </Button>
                    </Segment>
                </Segment>
            </form>
        </Segment>
        <ModalConfirm
            isOpen={isModalCreate}
            onClose={() => setIsModalCreate(!isModalCreate)}
            title={"Simpan Perubahan?"}
            content={<Segment py={8}>Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan.</Segment>}
            ButtonFooter={
                (
                    <ButtonGroup>
                        <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                            Batal
                        </Button>
                        <Button disabled={loadingSubmit==="pending"} onClick={handleSubmit} minWidth={"100px"}>
                            {loadingSubmit==="pending" ? 'Loading ...' : 'Simpan'}
                        </Button>
                    </ButtonGroup>
                )
            }
        />
    </>
}

export default InsentifDeliman