
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:null,
  filterCluster:"",
  loadingCluster:false,
  filterReason:[],
  loadingReason:false,
  loadingGetImage:false,
  dataImage:null,
  openModal:false,
  timeStamp:'',
  loadingSummary:false,
  dataSummayOrder:{
    ALLOCATING: 0,
    PICKING_UP: 0,
    IN_DELIVERY: 0,
    IN_RETURN: 0,
    CANCELING: 0
  },
  filterChannel:null,
  loadingChannel:false,
  propertiesImage: {
      lat: "",
      long: "",
      dateTime: ""
  }
}

export const orderList = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_ORDER_LIST':
          return {
              ...state,
              loading: true,
              detailData:null
          }
      case 'REQUEST_ORDER_LIST_INTERVAL':
        return {
            ...state,
            loading: false,
            detailData:null
        }
      case 'REQUEST_ORDER_LIST_BY_ID':
          return {
              ...state,
              loading: true,
              detailData:null,
              timeStamp:''
          }
      case 'GET_ORDER_LIST':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false,
              timeStamp:action.payload.timeStamp
              
          }
      case 'GET_ORDER_LIST_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data,
            loading: false,
            timeStamp:action.payload.timeStamp
        }
      case 'GET_ORDER_LIST_FAILED':
        return {
            ...state,
            loading: false
        }
      case 'REQUEST_FILTER_CLUSTER':
        return {
            ...state,
            loadingCluster: true,
            
        }
      case 'REQUEST_FILTER_CANCEL_REASON':
        return {
            ...state,
            loadingReason: true,
            
        }
    case 'GET_FILTER_CLUSTER':
        return {
            ...state,
            loadingCluster: false,
            filterCluster:action.payload.data
            
        }
    case 'GET_FILTER_CANCEL_REASON':
        return {
            ...state,
            loadingReason: false,
            filterReason:action.payload.data
        }
    case 'REQUEST_FILE_UPLOAD':
        return{
            ...state,
            loadingGetImage:true,
            dataImage:null,
            openModal:true
        }
    case 'GET_FILE_UPLOAD':
        return{
            ...state,
            loadingGetImage:false,
            dataImage:action.payload.data,
            openModal:true,
            propertiesImage:{
                lat: action.payload.properties.lat,
                long: action.payload.properties.long,
                dateTime: action.payload.properties.dateTime
            }
        }
    case 'RESET_FILE_UPLOAD':
            return{
                ...state,
                loadingGetImage:false,
                dataImage:null,
                openModal:false
            }
    case 'REQUEST_ORDER_LIST_TOTAL_ORDER_TAB':
        return{
            ...state,
            loadingSummary: true,
        }
    case 'GET_SUMMARY_ORDER':
        return{
            ...state,
            loadingSummary:false,
            dataSummayOrder:action.payload.dataTotalOrder
        }
    case 'REQUEST_FILTER_CHANNEL':
        return{
            ...state,
            loadingChannel:true
        }
    case 'GET_FILTER_CHANNEL':
        return{
            ...state,
            loadingChannel:false,
            filterChannel:action.payload.data
        }
      default:
          return state
  }
}