import React, { useState, useCallback, useEffect } from "react";
import { Text, Textfield, Icon, OptionBox, Spinner, ButtonLink, ButtonGroup, Button, FormControl } from "@elevenia/master-ui/components/Atom";
import Table from "@elevenia/master-ui/components/Atom/Table";
import Segment from "@elevenia/master-ui/components/Atom/Segment";
import { Pagination } from "@elevenia/master-ui/components/Molecules";

const DataTable = ({
    defaultSortActive = "",
    countingList = false,
    defaultSize = 10,
    tableConsume = [],
    dataConsume = [],
    isLoading = false,
    createShowPerSize,
    callSorted,
    createSearchAbles,
    createPaginations,
    createCustomEdit,
    createCustomDelete,
    totalPages = 0,
    showSize = false,
    showPagination = false,
    showSearch = false,
    customTotal,
    customFilter,
    activePage = null,
    emptyStateStyle = {},
    ...props
}) => {
    const hasOptions = [
        { value: 10, label: "10", color: "#666666" },
        { value: 30, label: "30", color: "#253858" },
        { value: 50, label: "50", color: "#253858" },
        { value: 100, label: "100", color: "#666666" },
        { value: 200, label: "200", color: "#666666" }
    ];
    const isOrder = defaultSortActive.split(',')[1] === 'asc' ? true : false;
    const [isSort, setSort] = useState({
        [defaultSortActive.split(',')[0]]: isOrder,
        color: '#1171d4',
    });
    const [initPage, setInitpage] = useState(activePage ? (activePage + 1) : 1);
    useEffect(() => {

        activePage !== null && setInitpage(activePage + 1)
    }, [activePage])
    const [defaultSizer, setDefaultSizer] = useState(defaultSize)
    const handleSorted = useCallback((value, color) => {
        setSort(prev => {
            return {
                [value]: !isSort[value],
                color
            }
        });
        callSorted(value, isSort[value] ? "desc" : 'asc');
    }, [isSort, callSorted])
    const handleEdit = (id) => {
        createCustomEdit(id);
    }
    const handleDelete = (id, item) => {
        createCustomDelete(id, item);
    }
    const isSearch = (e) => {
        createSearchAbles(e);
        setInitpage(1);
    }
    const hasChangePage = (page) => {
        const paramSetup = {
            page: page - 1,
            size: defaultSizer
        }
        setInitpage(page);
        createPaginations(paramSetup)
    }
    const createSize = (e) => {
        createShowPerSize(e)
        setDefaultSizer(e.value)
        setInitpage(1)
    }

    useEffect(() => {
        if (initPage !== totalPages && dataConsume.length === 0 && totalPages !== 0) {
            setInitpage(totalPages + 1 - 1)
        }
    }, [totalPages, initPage, dataConsume.length])


    return (
        <>
            <Segment boxShadow={props.isWrapperShadow} bg={'white'} borderRadius={4}>
                <Segment
                    justifyContent={'flex-start'}
                    p={(customFilter || showSearch) ? 20 : 0}
                    borderBottom={(customFilter || showSearch) ? '1px solid #DCDEE3' : 'none'}
                    className={"fw500"}>
                    {
                        showSearch && (<Segment width={260}>
                            <FormControl label={'Search'} mr={20}>
                                <>
                                    <Textfield
                                        inputProps={{
                                            onChange: isSearch,
                                            placeholder: props.customPlaceholder
                                        }}
                                        left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                                    />
                                </>
                            </FormControl>
                        </Segment>)
                    }
                    {
                        customFilter
                    }
                </Segment>
                <Segment pt={8} pb={8}>
                    <Table responsive round>
                        <thead className="table-head">
                            <tr>
                                {countingList && <th><Text H14 color={"black70"}>No</Text></th>}
                                {
                                    tableConsume.map((item, index) => {
                                        let size = item.width ? item.width : 0

                                        return (
                                            <th className={item?.headerClass} width={size} key={index} style={item?.headerStyle}>
                                                {
                                                    item.isEdit || item.isDelete ?
                                                        <Text H14 >{item.field}</Text> :
                                                        <Segment>
                                                            {
                                                                item.sortField ?
                                                                    <ButtonLink style={{ textAlign: "left", display: "flex" }} onClick={() => handleSorted(item.sortField, '#1171d4')}>
                                                                        <Text H14 mr={8} color={isSort[item.sortField] !== undefined ? isSort.color : "black70"}>{item.field}</Text>
                                                                        <Icon size={16} fillColor={isSort[item.sortField] !== undefined ? isSort.color : "black70"} name={
                                                                            isSort[item.sortField] ? "chevron-up" : "chevron-down"
                                                                        } />
                                                                        {
                                                                            item.isAddOnHeader ? item.isAddOnHeader() : ''
                                                                        }
                                                                    </ButtonLink>
                                                                    :
                                                                    <div style={{ display: "flex" }}>
                                                                        <Text H14 mr={8} color={isSort[item.sortField] !== undefined ? isSort.color : "black70"}>{item.field}</Text>
                                                                        {
                                                                            item.isAddOnHeader ? item.isAddOnHeader() : ''
                                                                        }
                                                                    </div>

                                                            }
                                                        </Segment>
                                                }
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={tableConsume.length + 1}>
                                            <Segment className="u-tx-center" width="100%" height={30}>
                                                <Spinner />
                                            </Segment>
                                        </td>
                                    </tr>
                                    :
                                    dataConsume.length === 0 ?
                                        <tr>
                                            <td colSpan={tableConsume.length + 1}>
                                                <Segment className="u-tx-center" width="100%" height={30} style={emptyStateStyle}>
                                                    No data
                                                </Segment>
                                            </td>
                                        </tr> :
                                        dataConsume.map((item, index) => {
                                            const x = initPage

                                            return (
                                                <tr key={index}>
                                                    {
                                                        countingList && <td>{((x - 1) * defaultSize) + index + 1}</td>
                                                    }
                                                    {
                                                        tableConsume.map((child, indexChild) => {

                                                            return (
                                                                <td key={indexChild} className={child.textStyle}>
                                                                    {
                                                                        child.isEdit || child.isDelete ?
                                                                            <Segment className="u-tx-center">
                                                                                <ButtonGroup>
                                                                                    {
                                                                                        child.isEdit &&
                                                                                        <Button onClick={() => handleEdit(item[child.entityFilters], item)} size="small" variant="primary" style={{ minWidth: 'auto' }}>
                                                                                            <Icon name={'edit'} size={16} fillColor={'white'} />
                                                                                        </Button>
                                                                                    }
                                                                                    {
                                                                                        child.isDelete &&
                                                                                        <Button onClick={() => handleDelete(item[child.entityFilters], item)} size="small" style={{ backgroundColor: "#ED1651", minWidth: 'auto' }}>
                                                                                            <Icon name={'delete'} size={16} fillColor={'white'} />
                                                                                        </Button>
                                                                                    }
                                                                                </ButtonGroup>
                                                                            </Segment>
                                                                            :
                                                                            child.isCustomRow ? child.isCustomRow(item[child.entityFilters], item) : item[child.rowField]
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                            }
                        </tbody>
                    </Table>
                </Segment>
            </Segment>
            <Segment justifyContent={customTotal ? 'space-between' : 'flex-end'} alignItems={'center'}>
                {customTotal}
                < Segment justifyContent={'flex-end'} className="group-pagination">
                    {
                        showSize && <Segment alignItems={"center"}>
                            <Segment mr={16}>Item per page</Segment>
                            <Segment width={90}>
                                <OptionBox
                                    onChange={createSize}
                                    inputClassName={"sample-class-in-input"}
                                    options={hasOptions}
                                    placeholder="show"
                                    value={hasOptions.filter(isSize => isSize.value === defaultSizer)}
                                    menuPortalTarget={document.body}
                                />
                            </Segment>
                        </Segment>
                    }
                    {
                        showPagination &&
                        <Segment mt={32} pb={32} ml={32}>
                            <Pagination
                                innerRange={2}
                                outerRange={1}
                                total={totalPages}
                                initialPage={initPage}
                                onChange={page => {
                                    hasChangePage(page)
                                }}
                            />
                        </Segment>
                    }
                </Segment>
            </Segment>
        </>
    )
}
DataTable.defaultProps = {
    isShadow: true,
    isWrapperShadow: true,
    customPlaceholder: 'Search...'
}
export default DataTable