import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge, FormControl, Icon, Segment, Textfield
} from "@elevenia/master-ui/components/Atom"
import DataTable from 'component/DataTable';
import { useAction } from 'hooks';
import { requestReadMenuAccessRight } from 'store/actions/menuaccessright';
import { useSelector } from 'react-redux';
import OptionBox from '@elevenia/master-ui/components/Atom/OptionBox';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

const Table = (props) => {
    const { hasFetch } = useAction();
    const [status, hasStatus] = useState("hasLoad")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        name: "",
        sort: "name,asc",
        status: ""
    });
    const payload = useSelector(state => {
        return {
            data: state.menuAccessRight.data,
            loading: state.menuAccessRight.loading,
            totalPages: state.menuAccessRight.totalPages,
            parameter: state.menuAccessRight.parameter,
            statName: state.setAlertsToast.notify.statName
        }
    })
    useEffect(() => {
        status === "hasLoad" && hasFetch(requestReadMenuAccessRight({ isTable }))
        status === "hasPagination" && hasFetch(requestReadMenuAccessRight({ isTable }))
        status === "hasFilter" && hasFetch(requestReadMenuAccessRight({ isTable }))
    }, [hasFetch, isTable, status])
    const statusColor = status => {
        switch (status) {
            case 'inactive':
                return { bg: '#EE2B2E', color: 'white', border: '#EE2B2E', text: 'Non-Aktif' }
            case 'active':
                return { bg: '#8FC742', color: 'white', border: '#8FC742', text: 'Aktif' }

            default:
                return { bg: 'white', color: '#151823', border: '#DCDEE3', text: status }
        }
    }
    const listOptions = [
        { value: "active", label: "Aktif" },
        { value: "inactive", label: "Non-Aktif" },
    ];

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => {
                hasFetch(requestReadMenuAccessRight(value))
            }
            )
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        const query = {
            ...isTable,
            name: e.target.value.trim()
        }
        onSearch$.next({ isTable: query });
    }

    return (
        <>
            <Segment className="group-filter"    boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <Textfield
                            inputProps={{
                                onChange: e => handleSearch(e),
                                placeholder: 'Cari Nama'
                            }}
                            left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                        />
                    </FormControl>
                </Segment>
                <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <OptionBox
                            onChange={(text) => {
                                const query = {
                                    ...isTable,
                                    ...payload.parameter,
                                    status: text === null ? "" : text.value
                                }
                                setTable(query)
                                hasStatus("hasFilter")
                            }}
                            defaultValue={""}
                            options={listOptions}
                            placeholder={"Status"}
                            isClearable
                        />
                    </FormControl>
                </Segment>
            </Segment>
            <DataTable
                countingList={true}
                defaultSortActive={isTable.sort}
                callSorted={(name, direction) => setTable(prev => {
                    return {
                        ...prev,
                        ...payload.parameter,
                        sort: `${name},${direction}`
                    }
                })}
                defaultSize={isTable.size}
                tableConsume={[{
                    field: 'Nama',
                    isCustomRow: (id, entity) => {
                        return (
                            <Link to={`/authorization/${entity.id}`}>{entity.name}</Link>
                        )
                    },
                    sortField: 'name',
                }, {
                    field: 'Status',
                    isCustomRow: (id, entity) => {
                        const pallet = statusColor(entity.status)
                        return (
                            <Badge
                                style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                bg={pallet.bg}
                                color={pallet.color}
                            >
                                {pallet.text}
                            </Badge>
                        )
                    },
                    // sortField: 'status',
                    width: "60%",
                }
                ]}
                dataConsume={payload.data}
                isLoading={payload.loading}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={payload.totalPages}
                createPaginations={(page) => {
                    const query = {
                        ...payload.parameter,
                        ...page
                    }
                    hasStatus('hasPagination')
                    setTable(query)
                }}
                createShowPerSize={(size) => setTable(prev => {
                    return {
                        ...prev,
                        ...payload.parameter,
                        size: size.value
                    }
                })}
            />
        </>

    )
}

export default Table