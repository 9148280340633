export const requestReleaseAccess = (parameter) => {
    return {
        type: "REQUEST_RELEASE_ACCESS",
        payload: { parameter }
    }
}
export const requestReleaseAccessInterval = () => {
    return {
        type: "REQUEST_RELEASE_ACCESS_INTERVAL",
        
    }
}
export const requestReleaseAccessHistory = (parameter) => {
    return {
        type: "REQUEST_RELEASE_ACCESS_HISTORY",
        payload: { parameter }
    }
}
export const requestOrderListByIdDeliman = id => {
    return {
        type: "REQUEST_ORDER_LIST_BY_ID_DELIMAN_ACCESS",
        payload: { id }
    }
}
  
export const requestSubmitReleaseAccess = (data,history) => {
    return {
        type: "REQUEST_SUBMIT_RELEASE_ACCESS",
        payload: { data },
        history
    }
} 

export const requestListDuration = () => {
    return {
        type: "REQUEST_DURATION_RELEASE_ACCESS",
        
    }
} 

export const requestOrderListByIdDelimanAndStatus = params => {
    return {
        type: "REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS_RELEASE_ACCESS",
        payload: { params }
    }
}

export const resetOrderList = () => {
    return {
        type: 'RESET_ORDER_LIST'
    }
}

