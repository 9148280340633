import React, { lazy, Suspense } from 'react';
import {
    Button,
    ButtonGroup,
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom';
import Spinner from '@elevenia/master-ui/components/Atom/Spinner';
const ModalInfo = lazy(() => import('component/ModalCustom/modalMedium'))
const InitialStateAlerts = {
    status: false,
    componentMessage: {
        Modal: ""
    }
}
export const setAlerVerification = (state = InitialStateAlerts, action) => {
    switch (action.type) {
        case 'ALERT_VERIFICATION_ACCOUNT':
            return {
                ...state,
                componentMessage: {
                    Modal: (
                        <Suspense fallback={<Segment width={24} height={24}>
                            <Spinner color="#000000" />
                        </Segment>}>
                            <ModalInfo
                                isOpen={action.payload.isOpen}
                                onClose={() => false}
                                content={
                                    <Segment style={{ textAlign: 'center' }} className="font-nunito" mb={48}>
                                        {action.payload.image}
                                        <Text color="#1178D4" fontWeight={'800'} fontSize={28} mb={24} mt={53}>{action.payload.headerTitle}</Text>
                                        <Text color="#70727D" fontSize={16}>{action.payload.text}</Text>
                                    </Segment>
                                }
                                ButtonFooter={
                                    (
                                        <Segment justifyContent="center" width="100%" className="font-nunito" mb={9}>
                                            <Button onClick={() => {
                                                action.payload.onClose()
                                                action.payload.link()
                                            }} width={240}>
                                                {action.payload.buttonTitle}
                                            </Button>
                                        </Segment>
                                    )
                                }
                            />
                        </Suspense>
                    )
                }
            }
        case 'ALERT_VERIFICATION_SUCCESSFULL':
            return {
                ...state,
                componentMessage: {
                    Modal: (
                        <ModalInfo
                            isOpen={action.payload.isOpen}
                            onClose={() => false}
                            content={
                                <Segment py={8} className="font-nunito" style={{ textAlign: 'center' }} mb={32}>
                                    {action.payload.image}
                                    <Text fontSize={28} fontWeight={800} mt={48}>{action.payload.headerTitle}</Text>
                                    <Text color="#70727D" fontSize={16} mt={32}>{action.payload.text} </Text>
                                </Segment>
                            }
                            ButtonFooter={
                                (
                                    <Segment width={'65%'} mx={'auto'} mb={24}>
                                        <ButtonGroup responsive>
                                            <Button onClick={() => {
                                                action.payload.onClose()
                                                action.payload.link()
                                            }}>
                                                {action.payload.buttonTitle}
                                            </Button>
                                        </ButtonGroup>
                                    </Segment>
                                )
                            }
                        />
                    )
                }
            }       
        default:
            return state
    }
}
