import React from 'react'
import {
    Segment,
    Text,
    Button,
    Icon
} from "@elevenia/master-ui/components/Atom"
import Table from './table'
import Breadcrumb from 'component/Breadcrumb'
import Error404 from 'page/errorLayout'
const Index = (props) => {
    document.title = props.title;
    const onMove = () => {
        props.history.push("/authorization/create")
    }
    const dataBreadcrumb = [
        { to: '', label: 'Otoritas Pengguna' }
    ]
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Otoritas Pengguna</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                {
                    <Segment>
                        <Button onClick={onMove}>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Segment>
                }
            </Segment>
            <Segment>
                <Table {...props} />
            </Segment>
        </>
    )
}

export default Index