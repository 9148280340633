export const requestUsers = properties => {
  return {
      type: "REQUEST_USERS",
      payload: { properties }
  }
}


export const requestUserById = id => {
  return {
      type: "REQUEST_USER_BY_ID",
      payload: id
  }
}

export const requestListRoles = (params) => {
  return {
      type: "REQUEST_USER_ROLES",
      payload:{params}
  }
}

export const setDataPreview = (params) => {
  return {
      type: "SET_USER_PREVIEW",
      payload:{params}
  }
}

export const addNewUser = (params,history) => {
  return {
      type: "CREATE_NEW_USER",
      payload:{params},
      history
  }
}

export const updateUser = (id,params,history) => {
  return {
      type: "UPDATE_USER",
      payload:{
        params,
        id
      },
      history
  }
}

export const requestDeleteUser = (id,history) => {
  return {
      type: "REQUEST_DELETE_USER",
      payload:{
        id
      },
      history
  }
}

export const requestCheckNik = (params)=> {
  return {
      type: "REQUEST_CHECK_NIK_USER",
      payload:{
        params
      }
  }
}

export const requestCheckEmail = (params)=> {
  return {
      type: "REQUEST_CHECK_EMAIL_USER",
      payload:{
        params
      }
  }
}

export const resetInputStatus = (name) => {
  return {
      type: "RESET_INPUT_STATUS",
      payload:{
        field:name
      }
  }
}