import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestActivityLog = action$ =>
    action$.pipe(
        ofType('REQUEST_ACTIVITY_LOG', 'REQUEST_DETAIL_ACTIVITY_LOG'),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_ACTIVITY_LOG':
                        const rawResponse = await Services().get('/driver/api/back-office/activity-log', action.payload.parameter)
                        return dispatch => {
                            dispatch({
                                type: 'GET_ACTIVITY_LOG',
                                payload: {
                                    parameter: action.payload.parameter,
                                    data: rawResponse.data.data.activities.content,
                                    totalPages: rawResponse.data.data.activities.totalPages,
                                    activities: rawResponse.data.data.activityOptions
                                }
                            })
                        }
                    case 'REQUEST_DETAIL_ACTIVITY_LOG':
                        const rawDetailLog = await Services().get(`/driver/api/back-office/activity-log-detail/${action.payload.id}`)
                        return dispatch => {
                            dispatch({
                                type: "GET_DETAIL_ACTIVITY_LOG",
                                payload: rawDetailLog.data.data
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: 'ACTIVITY_LOG_FAILED' })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
