import React, { useState } from 'react';
import {
    Segment,
    Text,
    Button,
    Icon,
} from '@elevenia/master-ui/components/Atom';
import { Tabs } from '@elevenia/master-ui/components/Molecules';
import { Link } from 'react-router-dom';
import Breadcrumb from 'component/Breadcrumb';
import TabSedangAktif from './_component/TabSedangAktif';
import TabRiwayat from './_component/TabRiwayat';
import Error404 from 'page/errorLayout';
import { useSelector } from 'react-redux';
const dataBreadcrumb = [{ to: '', label: 'Release Lock' }];

const ReleaseLock = (props) => {
    document.title = props.title;
    const [activeTab, setActiveTab] = useState('SedangAktif');
    const payload = useSelector((state) => {
        const actionGroupReleaseLock = state.homesidemenu.action_group.filter(
            (group) => group?.code === 'release-locking' 
        );
        let actionGroup =
            actionGroupReleaseLock.length > 0
                ? actionGroupReleaseLock[0].actions.filter((action) =>
                      action?.code.includes('locking-type') && action?.allowed
                  )
                : [];

        return {
            dataActionGroup: actionGroup,
        };
    });
    if (!props.access) return <></>;
    else if (!props.access?.read) return <Error404 />;
    return (
        <>
            <Segment justifyContent={'space-between'}>
                <Segment mb={30}>
                    <Text H28 mb={'1rem'}>
                        Release Lock
                    </Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    {payload?.dataActionGroup?.length > 0 && (
                        <Link to={'/release-lock/form'}>
                            <Button>
                                <Icon
                                    name={'plus'}
                                    size={22}
                                    fillColor={'white'}
                                    mr={8}
                                />
                                Buat Baru
                            </Button>
                        </Link>
                    )}
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <Segment>
                    <Tabs
                        className='group-tab'
                        active={activeTab}
                        onChange={(active) => setActiveTab(active)}
                    >
                        <Text key='SedangAktif'>Sedang Aktif</Text>
                        <Text key='Riwayat'>Riwayat</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === 'SedangAktif' && (
                            <TabSedangAktif {...props} />
                        )}
                        {activeTab === 'Riwayat' && <TabRiwayat {...props} />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    );
};

export default ReleaseLock;
