import { formatCountDown } from 'helper'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { Services } from 'service'

export const RequestTrackOrder = (action$, store) =>
    action$.pipe(
        ofType('REQUEST_TRACK_ORDER',
                'REQUEST_LIST_REASON_CANCEL_ORDER',
                'REQUEST_CANCEL_ORDER',
                'REQUEST_MULTI_TRACK_ORDER',
                'REQUEST_TRACK_ORDER_AFTER_DELETE',
                'REQUEST_OPTION_SEARCH_CATEGORY',
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_MULTI_TRACK_ORDER':
                        const rawMultiRecord = await Services().get('/order/api/back-office/orders/tracks', action.payload)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_MULTI_TRACK_ORDER',
                                payload: {
                                    multiTracking: rawMultiRecord.data.data.content.map(val=>{
                                        const { order } = val
                                        let dataOrder = order
                                        if(order){
                                            const completedTime = moment(order?.completedTime);
                                            const minDeliveryTime = moment(order?.minDeliveryTime);
                                            const maxDeliveryTime = moment(order?.maxDeliveryTime);
                                            const duration = moment.duration(completedTime.diff(minDeliveryTime));
                                            const display = formatCountDown(Math.ceil(duration.as("seconds")), true);
                                            let textOrderStatus = order.orderStatus
                                            if(order?.orderStatus==='FAILED' || order?.orderStatus==='CANCELED' ){
                                                if(order?.cancelReasonPinType==='FALLBACK' || order?.cancelReasonPinType==='KEEP'){
                                                  textOrderStatus = 'FALLBACK'
                                                }
                                            }
                                            dataOrder = {
                                                ...order,
                                                dataCompletedTime: completedTime,
                                                dataMinDeliveryTime: minDeliveryTime,
                                                dataMaxDeliveryTime: maxDeliveryTime,
                                                duration: duration,
                                                display: display,
                                                textOrderStatus:  textOrderStatus,
                                                orderStatus: order?.orderStatus==='READY_TO_DELIVER' ? 'PICKING_UP' : order?.orderStatus
                                        
                                              }
                                        }
                                        
                                        
                                        return {
                                            ...val,
                                            order:dataOrder,
                                            errorMessage: !val.order ? 'Pesanan tidak ditemukan, pastikan Anda memasukkan Order ID, SO ID atau AWB yang benar.' : null
                                        }
                                    }),
                                    
                                }
                            })
                            dispatch({
                                type:'ALERT_CLEARS'
                            })
                        }
                    case 'REQUEST_TRACK_ORDER':
                        const rawRecord = await Services().get('/order/api/back-office/orders/track', action.payload)
                        const rawHistory = await Services().get(`/order/api/back-office/orders/history/${rawRecord.data.data.id}`)
                        return dispatch => {
                            dispatch({
                                type: 'GET_TRACK_ORDER',
                                payload: {
                                    tracking: rawRecord.data.data,
                                    history: rawHistory.data.data.content
                                }
                            })
                        }
                    case 'REQUEST_LIST_REASON_CANCEL_ORDER':
                        
                        const rawList = await Services().get('/order/api/back-office/cancel-reasons', action.payload.params)
                        return dispatch => {
                            dispatch({
                                type: 'GET_LIST_REASON_CANCEL_ORDER',
                                payload:{
                                    data:rawList.data.data.content
                                }
                            })
                        }
                    case 'REQUEST_CANCEL_ORDER':
                        await Services().post('/orderprocessor/api/back-office/orders/cancel',{
                            orderId:action.payload.params.orderId,
                            cancelReasonId:action.payload.params.cancelReasonId
                          })
                        return dispatch => {
                            dispatch({
                                type: 'FINISH_CANCEL_ORDER',
                                payload:{
                                    status:true
                                }
                            })
                            dispatch({
                                type: "REQUEST_TRACK_ORDER_AFTER_DELETE",
                                payload: { searchParams: action.payload.params.searchValue,category:action.payload.params.categoryOrderId }
                            })
                            dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Pesanan berhasil dibatalkan' } })
                            
                        }
                    case 'REQUEST_TRACK_ORDER_AFTER_DELETE':
                        const rawRecordM = await Services().get('/order/api/back-office/orders/tracks', action.payload)
                        
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_MULTI_TRACK_ORDER',
                                payload: {
                                    multiTracking: rawRecordM.data.data.content.map(val=>{
                                        const { order } = val
                                        let dataOrder = order
                                        if(order){
                                            const completedTime = moment(order?.completedTime);
                                            const minDeliveryTime = moment(order?.minDeliveryTime);
                                            const maxDeliveryTime = moment(order?.maxDeliveryTime);
                                            const duration = moment.duration(completedTime.diff(minDeliveryTime));
                                            const display = formatCountDown(Math.ceil(duration.as("seconds")), true);
                                            let textOrderStatus = order.orderStatus
                                            if(order?.orderStatus==='FAILED' || order?.orderStatus==='CANCELED' ){
                                                if(order?.cancelReasonPinType==='FALLBACK' || order?.cancelReasonPinType==='KEEP'){
                                                  textOrderStatus = 'FALLBACK'
                                                }
                                            }
                                            dataOrder = {
                                                ...order,
                                                dataCompletedTime: completedTime,
                                                dataMinDeliveryTime: minDeliveryTime,
                                                dataMaxDeliveryTime: maxDeliveryTime,
                                                duration: duration,
                                                display: display,
                                                textOrderStatus:  textOrderStatus
                                        
                                              }
                                        }
                                        
                                        
                                        return {
                                            ...val,
                                            order:dataOrder,
                                            errorMessage: !val.order ? 'Pesanan tidak ditemukan, pastikan Anda memasukkan Order ID, SO ID atau AWB ID yang benar.' : null
                                        }
                                    }),
                                    
                                }
                            })
                            
                        }
                    case 'REQUEST_OPTION_SEARCH_CATEGORY':
                    
                        const rawOptions = await Services().get('/order/api/back-office/orders/tracks/category')
                        return dispatch => {
                            dispatch({
                                type: 'GET_OPTION_SEARCH_CATEGORY',
                                payload:{
                                    data:rawOptions.data.data.content
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "TRACK_ORDER_FAILED" })
                    if (e.status === "O101") {
                        const isError = {
                            isOpen: true,
                            message: "Pesanan tidak ditemukan, pastikan Anda memasukkan Order ID, SO ID atau AWB yang benar."
                        }
                        dispatch({
                            type: 'ALERT_ERROR',
                            payload: {
                                ...isError,
                                onClose: () => dispatch({
                                    type: "ALERT_ERROR",
                                    payload: {
                                        ...isError,
                                        isOpen: false
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch({
                            type: 'FINISH_CANCEL_ORDER',
                            payload: {
                                status:false
                            }
                        })
                        if(e.status==="OP002"){
                            dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message } })
                        }else{
                            dispatch({ type: 'ALERT_ERROR', payload: { message } })
                        }
                        
                    }
                }
            }
        }),
    )
