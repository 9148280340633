import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    ButtonLink,
    FormControl,
    Row,
    Icon,
    DatePicker,
    Tooltip,
    TooltipTarget,
    TooltipContent
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout'
import { Services } from 'service'
import AsyncSelect from 'react-select/async';
import { useInput, useAction } from 'hooks';
import { requestAddDeliverymanCadangan, requestValidateDelimanCadangan } from 'store/actions/deliverymanCadangan'
import { validateForm, validateInput } from 'helper';
import moment from 'moment'
import _ from 'lodash';

const DelivManCadanganForm = (props) => {
    if (!props.access) return <></>
    else if (!props.access?.create) return <Error404 />

    return (
        <Form {...props} />
    )
}
const Form = (props) => {
    const history = props.history
    const [dateInput, setDateInput] = useState({
        startDate: null,
        endDate: null
    })
    const title = 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/deliveryman-cadangan', label: 'Delivery Man Cadangan' },
        { to: '', label: title }
    ]
    const { hasFetch } = useAction();
    const [listTask, setListTask] = useState([])
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [isDisabledCatat, setIsDisabledCatat] = useState(true)
    const payload = useSelector(state => {
        return {
            dataError: state.deliverymanCadangan.dataError,
            loading: state.deliverymanCadangan.loading,
        }
    })

    let { value, setValue } = useInput({
        initialObjects: {
            deliveryManDescription: '',
            deliveryManId: '',
            deliveryManNIK: '',
            deliveryManName: '',
            clusterDescription: '',
            clusterId: '',
        },
        identity: "myForm",
    });

    const handleChangeDeliman = (data) => {
        if (data) {
            setValue(prev => {
                return {
                    ...prev,
                    deliveryManDescription: data.label,
                    deliveryManNIK: data.nik,
                    deliveryManName: data.name,
                    deliveryManId: data.value
                }
            })
        } else {

            setValue(prev => {
                return {
                    ...prev,
                    deliveryManDescription: '',
                    deliveryManNIK: '',
                    deliveryManName: '',
                    deliveryManId: 0
                }
            })

        }
        setTimeout(() => {

            validateInput('myForm', 'deliveryManDescription')
        }, 0)
    }

    const handleLoadOptionDeliman = (inputValue, callback) => {
        if (inputValue.length >= 3 || inputValue.length === 0) {
            Services().get(`/driver/api/back-office/list?unpaged=true&keyword=${inputValue}&sort=name,asc&status=active&assignmentStartDate=${moment(dateInput.startDate).format('DD-MM-YYYY')}&assignmentEndDate=${moment(dateInput.endDate).format('DD-MM-YYYY')}`)
                .then(response => {
                    const listData = response.data.data.result.content
                    callback(listData?.map(val => ({
                        label: `${val.nik} - ${val.name}`,
                        value: val.id,
                        ...val
                    })))
                })
        }

    }
    const _handleLoadOptionDeliman = _.debounce(handleLoadOptionDeliman, 500)

    const handleChangeCluster = (data) => {
        if (data) {
            setValue(prev => {
                return {
                    ...prev,
                    clusterDescription: data.label,
                    clusterId: data.value
                }
            })
        } else {

            setValue(prev => {
                return {
                    ...prev,
                    clusterDescription: '',
                    clusterId: 0
                }
            })

        }
        setTimeout(() => {

            validateInput('myForm', 'clusterDescription')
        }, 0)
    }

    const handleLoadOptionCluster = (inputValue, callback) => {
        if (inputValue.length >= 3 || inputValue.length === 0) {
            Services().get(`/store/api/back-office/clusters?clusterNotAssigned=true&unpaged=true&searchParam=${inputValue}`)
                .then(response => {
                    const listData = response.data.data.content
                    callback(listData?.map(val => ({
                        label: `${val.clusterCode} - ${val.clusterName}`,
                        value: val.id,
                        ...val
                    })))
                })
        }
    }

    const _handleLoadOptionCluster = _.debounce(handleLoadOptionCluster, 500)


    const handleChangeDate = (name, value) => {
        setDateInput({
            ...dateInput,
            [name]: value
        })
        setTimeout(() => {

            validateInput('myForm', name)
        }, 0)

    }

    const checkIsAvailable = (data) => {

        let notAvailable = 0;
        const listData = listTask;
        const ifExist = listData.filter(val => val.deliveryManId === data.deliveryManId)

        if (ifExist.length > 0) {
            ifExist.forEach(val => {
                if (moment(data.startDate).isBetween(val.startDate, val.endDate, null, '[]') || moment(data.startDate).isBetween(val.startDate, val.endDate, null, '[]')) {
                    notAvailable += 1
                }
            })
            if (notAvailable) {
                return false
            } else {
                // const ifClusterExist = ifExist.filter(val=>val.clusterId===data.clusterId)
                // if(ifClusterExist.length > 0){
                //     return false
                // }else{
                return true
                //}

            }
        } else {
            return true
        }
    }
    const handleCatat = () => {
        if (validateForm('myForm')) {

            const array = [...listTask]
            const idEl = `${value.deliveryManId}${value.clusterId}${moment(dateInput.startDate).format('DDMMYYYY')}${moment(dateInput.endDate).format('DDMMYYYY')}`
            const dataTask = {
                ...value,
                ...dateInput,
                status: 1,//true
                referenceId: idEl
            }

            console.log(dataTask)
            if (checkIsAvailable(dataTask)) {
                array.push(dataTask)
                setListTask(array)
            } else {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: 'Delivery Man Cadangan sudah di assign di Cluster lain' } })
            }


        }
    }

    const handleDelete = id => {

        const array = [...listTask]
        const index = array.findIndex(x => x.idEl === id);
        array.splice(index, 1)
        setListTask(array)
    }

    const handleConfirmation = () => {
        const dataSubmit = listTask.map(val => {
            return {
                assignmentEndDate: moment(val.endDate).format('DD-MM-YYYY'),
                assignmentStartDate: moment(val.startDate).format('DD-MM-YYYY'),
                startDate: val.startDate,
                endDate: val.endDate,
                clusterId: val.clusterId,
                clusterDescription: val.clusterDescription,
                deliveryManId: val.deliveryManId,
                deliveryManNIK: val.deliveryManNIK,
                deliveryManName: val.deliveryManName,
                referenceId: val.referenceId
            }
        })
        hasFetch(requestValidateDelimanCadangan(dataSubmit))
        //setIsModalCreate(!isModalCreate)
    }

    const handleSubmit = () => {
        const dataSubmit = listTask.filter(val => val.status === 1).map(val => {
            return {
                assignmentEndDate: moment(val.endDate).format('DD-MM-YYYY'),
                assignmentStartDate: moment(val.startDate).format('DD-MM-YYYY'),
                startDate: val.startDate,
                endDate: val.endDate,
                clusterId: val.clusterId,
                clusterDescription: val.clusterDescription,
                deliveryManId: val.deliveryManId,
                deliveryManNIK: val.deliveryManNIK,
                deliveryManName: val.deliveryManName,
                referenceId: val.referenceId
            }
        })
        hasFetch(requestAddDeliverymanCadangan(dataSubmit, history))

    }

    useEffect(() => {
        if (payload.dataError) {

            setListTask(payload.dataError)
            if (payload.dataError.filter(val => val.status === 0).length === 0) {

                setIsModalCreate(true)
            }
        }

    }, [payload.dataError])

    useEffect(() => {
        if (payload.loading === false) setIsModalCreate(false)
    }, [payload.loading])

    useEffect(() => {
        const disabled = Object.values(value).filter(val => val === "")

        if (disabled.length > 0 || dateInput.startDate === null || dateInput.endDate === null) {

            setIsDisabledCatat(true)
        } else {

            setIsDisabledCatat(false)
        }

    }, [value, dateInput])

    const hasFailedData = listTask.filter(a => a.status === 0).length;

    return <>
        <Segment mb={30}>
            <Text H28 mb={"1rem"}>{title}</Text>
            <Breadcrumb data={dataBreadcrumb} />
        </Segment>

        <Segment>
            <form id="myForm" autoComplete="false" onSubmit={(e) => e.preventDefault()}>
                <Segment boxShadow bg={"white"} borderRadius={4} p={24}>
                    <Segment>
                        <Segment className="group-title-penugasan" justifyContent="space-between">
                            <Text fontWeight={600} >Informasi Penugasan</Text>
                            <Button
                                disabled={isDisabledCatat}
                                onClick={() => handleCatat()}
                                variant="primary"
                                size="medium"
                                type={"button"}
                                style={{ minWidth: 80 }}
                                className="hidden md-block"
                            >
                                Catat
                            </Button>
                        </Segment>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Tanggal Penugasan</Text>
                            </Segment>
                            <Segment flex={1} justifyContent={"space-between"} className="group-date">
                                <Segment alignItems={"center"} minWidth={204}>
                                    <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                                        <FormControl>
                                            <DatePicker
                                                placeholderText={"Tanggal Awal"}
                                                selected={dateInput.startDate}
                                                onChange={(date) => handleChangeDate('startDate', date)}
                                                selectsStart
                                                minDate={new Date()}
                                                startDate={dateInput.startDate}
                                                endDate={dateInput.endDate}
                                                right={<Icon name={"date"} size={24} />}
                                            />
                                        </FormControl>
                                        <input
                                            type="hidden"
                                            name="startDate"
                                            className='validate[required] validateMessage{required{Tanggal Awal harus diisi}}'
                                            value={dateInput.startDate ?? ''} />
                                    </Segment>
                                    {/* separator */}
                                    <Segment width={8} height={2} bg={'#9C9DA6'} mx={10} />
                                    {/* separator */}
                                    <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                                        <FormControl>
                                            <DatePicker
                                                placeholderText={"Tanggal Akhir"}
                                                selected={dateInput.endDate}
                                                onChange={(date) => handleChangeDate('endDate', date)}
                                                selectsEnd
                                                startDate={dateInput.startDate}
                                                endDate={dateInput.endDate}
                                                minDate={dateInput.startDate}
                                                className={'validate[required] validateMessage{required{Tanggal Akhir harus diisi}}'}
                                                right={<Icon name={"date"} size={24} />}
                                            />
                                        </FormControl>
                                        <input
                                            type="hidden"
                                            name="endDate"
                                            className='validate[required,dateMustMoreThan[startDate]] validateMessage{required{Tanggal Akhir harus diisi}}'
                                            value={dateInput.endDate ?? ''} />
                                    </Segment>
                                </Segment>

                                <Button
                                    disabled={isDisabledCatat}
                                    onClick={() => handleCatat()}
                                    variant="primary"
                                    size="medium"
                                    type={"button"}
                                    style={{ minWidth: 80 }}
                                    className="md-hidden"
                                >
                                    Catat
                                </Button>
                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Delivery Man</Text>
                            </Segment>
                            <Segment flex={1} minWidth={204}>
                                <FormControl>
                                    <AsyncSelect
                                        onChange={handleChangeDeliman}
                                        isClearable
                                        isDisabled={(dateInput.startDate && dateInput.endDate) ? false : true}
                                        loadOptions={_handleLoadOptionDeliman}
                                        defaultInputValue={value.deliveryManDescription ? value.deliveryManDescription : ''}
                                        placeholder={'Pilih Delivery Man'}
                                    />
                                </FormControl>
                                <input
                                    type="hidden"
                                    name="deliveryManDescription"
                                    className='validate[required] validateMessage{required{Delivery Man harus diisi}}'
                                    value={value.deliveryManDescription ?? ''} />

                            </Segment>
                        </Row>
                        <Row mb={16}>
                            <Segment width={280} py={8}>
                                <Text>Klaster</Text>
                            </Segment>
                            <Segment flex={1} minWidth={204}>
                                <FormControl>
                                    <AsyncSelect
                                        onChange={handleChangeCluster}
                                        isClearable
                                        loadOptions={_handleLoadOptionCluster}
                                        defaultInputValue={value.clusterDescription ? value.clusterDescription : ''}
                                        placeholder={'Pilih Klaster'}
                                    />
                                </FormControl>
                                <input
                                    type="hidden"
                                    name="clusterDescription"
                                    className='validate[required] validateMessage{required{Klaster harus diisi}}'
                                    value={value.clusterDescription ?? ''} />
                            </Segment>
                        </Row>
                    </Segment>
                    <Segment width={"100%"} height={1} bg={'#DCDEE3'} mt={24} mb={24} />
                    <Segment>
                        {listTask.length > 0 ?
                            <Segment className="custom-table">
                                <Segment justifyContent="space-between" mb={32}>
                                    <Text fontWeight={600}>Daftar Penugasan Baru</Text>
                                    {
                                        hasFailedData > 0 &&
                                        <Segment alignItems="flex-end">
                                            <Icon name={"attention"} size={18} fillColor={'#EE2B2E'} mr={8} />
                                                <Text color="#EE2B2E">{hasFailedData} data gagal</Text>
                                        </Segment>
                                    }
                                </Segment>
                                <Segment className={listTask.length > 10 ? 'scroll-table responsive' : ''}>
                                    <DataTable
                                        countingList={true}
                                        tableConsume={[
                                            {
                                                field: 'Delivery Man',
                                                isCustomRow: (id, entity) => {
                                                    return <Segment display="flex">
                                                        {`${entity.deliveryManNIK} - ${entity.deliveryManName}`}
                                                        {entity.status === 0 ? <Tooltip type="hover" placement="right" color="dark">
                                                            <TooltipTarget>
                                                                <Icon name={"attention"} size={18} fillColor={'#ee2b2e'} ml={12} />
                                                            </TooltipTarget>
                                                            <TooltipContent>
                                                                <Text>{entity.message}</Text>
                                                            </TooltipContent>
                                                        </Tooltip> : ''}
                                                    </Segment>
                                                }
                                            },
                                            {
                                                field: "Klaster",
                                                isCustomRow: (id, entity) => {
                                                    return <>{entity.clusterDescription}</>;
                                                },
                                            },
                                            {
                                                field: "Tanggal",
                                                isCustomRow: (id, entity) => {
                                                    return <>{moment(entity.startDate).format('DD/MM/YY')} - {moment(entity.endDate).format('DD/MM/YY')}</>;
                                                },
                                            },
                                            {
                                                field: "Hapus",
                                                isCustomRow: (id, entity) => {
                                                    return <>
                                                        <ButtonLink
                                                            variant="primary"
                                                            size="medium"
                                                            type={"button"}
                                                            style={{ minWidth: 50 }}
                                                            onClick={() => handleDelete(entity.idEl)}
                                                        >
                                                            <Icon name={"delete"} size={20} fillColor={'#70727D'} />
                                                        </ButtonLink></>;
                                                },
                                            },
                                        ]}
                                        dataConsume={listTask}
                                        showSize={false}
                                        showPagination={false}
                                        showSearch={false}
                                        totalPages={5}
                                        createPaginations={(page) => { }}
                                    />
                                </Segment>
                            </Segment>
                            :
                            <Segment boxShadow="0px 0px 4px 0px #8080806b" minHeight="200px" justifyContent="center" alignItems="center">
                                <Segment alignItems="center">
                                    <Icon name={"attention"} size={18} fillColor={'#9C9DA6'} />
                                    <Text fontSize={12} color="#9C9DA6" ml={8}>Belum ada data penugasan</Text>
                                </Segment>
                            </Segment>
                        }

                    </Segment>
                </Segment>
                <Segment mt={32} justifyContent={"flex-end"}>
                    <Segment>
                        <Button
                            variant="secondary"
                            size="medium"
                            type={"button"}
                            mr={16}
                            onClick={() => {
                                history.push('/deliveryman-cadangan')
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            disabled={(listTask.length > 0 && hasFailedData === 0) ? false : true}
                            variant="primary"
                            size="medium"
                            type={"button"}
                            onClick={() => handleConfirmation()}
                        >
                            Buat
                        </Button>
                    </Segment>
                </Segment>
            </form>
        </Segment>
        <ModalConfirm
            isOpen={isModalCreate}
            onClose={() => setIsModalCreate(!isModalCreate)}
            title={"Buat Delivery Man Cadangan Baru?"}
            content={<Segment py={8}>Semua informasi Penugasan yang Anda masukkan akan tercatat dan tersimpan.</Segment>}
            ButtonFooter={
                (
                    <ButtonGroup>
                        <Button minWidth={"100px"} variant={"secondary"} onClick={() => setIsModalCreate(!isModalCreate)}>
                            Batal
                        </Button>
                        <Button disabled={payload.loading} onClick={handleSubmit} minWidth={"100px"}>
                            {payload.loading ? 'Loading ...' : 'Simpan'}
                        </Button>
                    </ButtonGroup>
                )
            }
        />
    </>
}

export default DelivManCadanganForm