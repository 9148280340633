import React, { useState, useEffect } from "react"
import {
    Segment,
    Icon,
    FormControl,
    Textfield,
    OptionBox,
    Badge
} from "@elevenia/master-ui/components/Atom"
import DataTable from 'component/DataTable'
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestReleaseLockingApprovalHistory } from 'store/actions/release-locking';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from "moment"
import { statusColor } from "helper";

const TabRiwayat = (props) => {
    const { hasFetch } = useAction();
    const [status, hasStatus] = useState("hasLoad")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        type: "",
        openingTime: moment().startOf('month').format('YYYY-MM-DD 00:00')
        // sort: "closingTime,desc"
    });
    const payload = useSelector(state => {
        return {
            data: state.releaseLocking.data,
            loading: state.releaseLocking.loading,
            totalPages: state.releaseLocking.totalPages,
            parameter: state.releaseLocking.parameter,
            statName: state.setAlertsToast.notify.statName,
            listType: state.homesidemenu?.action_group
                ?.find((val) => val.code === 'release-locking')
                ?.actions?.filter((action) =>
                    action.code.includes('locking-type')
                )
        }
    })

    useEffect(() => {

        status === "hasLoad" && props.access?.read && hasFetch(requestReleaseLockingApprovalHistory(isTable))
        status === "hasPagination" && props.access?.read && hasFetch(requestReleaseLockingApprovalHistory(isTable))
    }, [hasFetch, isTable, status, props.access])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestReleaseLockingApprovalHistory(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = (e) => {
        let { name, value } = e.target;
        setTable((prev) => ({
            ...prev,
            [name]: value?.trim(),
            page: 0
        }))
        onSearch$.next({
            ...isTable,
            [name]: value.trim(),
            page: 0
        });
    }

    const tableCOnfiguration = [{
        field: 'ID Release',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.releaseCode}</>
            )
        }
    }, {
        field: 'NIK Delivery Man',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.deliverymanNik}</>
            )
        }
    }, {
        field: 'Delivery Man',
        headerStyle: { minWidth: '150px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.deliverymanName}</>
            )
        }
    }, {
        field: 'Tipe Release',
        headerStyle: { minWidth: '150px' },
        isCustomRow: (id, entity) => {
            return (
                <>{payload.listType?.find((type) => type.value === entity.type)?.label}</>
            )
        }
    }, {
        field: 'Order',
        headerStyle: { minWidth: '120px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.totalOrder} Order</>
            )
        }
    }, {
        field: 'Periode Berlaku',
        headerStyle: { minWidth: '170px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.openingTime ? `${moment(entity.openingTime).format('DD/MM/YY')}` : '-'}</>
            )
        },

    }, {
        field: 'Pembuat Release',
        headerStyle: { minWidth: '210px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.username ? `${entity.userNik} - ${entity.username}` : '-'}</>
            )
        }
    }, {
    }, {
        field: 'Pemberi Approval',
        headerStyle: { minWidth: '210px' },
        isCustomRow: (id, entity) => {
            return (
                <>{entity.approverDisplay ? `${entity.approverDisplay}` : '-'}</>
            )
        }
    }, {
        field: '',
        // headerStyle: { minWidth: '200px' },
        isCustomRow: (id, entity) => {
            let pallet;
            let text;
            switch (entity.requestStatus) {
                case 'APPROVED':
                    pallet = statusColor('active');
                    text = 'Disetujui';
                    break;
                case 'REJECTED':
                    pallet = statusColor('inactive');
                    text = 'Ditolak';
                    break;
                case 'TIME_OUT':
                    pallet = statusColor('expired');
                    text = 'Expired';
                    break;
                default:
                    pallet = statusColor();
                    text = entity.requestStatus;
                    break;
            }

            return (
                <Badge
                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                    bg={pallet.bg}
                    color={pallet.color}
                >
                    {text}
                </Badge>
            )
        }
    }];

    return <>
        <Segment boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex" justifyContent="space-between">
            <Segment width={"30%"} className={"fw500"} style={{ overflowX: 'hidden' }}>
                <FormControl>
                    <Textfield
                        inputProps={{
                            name: "keyword",
                            onChange: e => handleSearch(e),
                            placeholder: 'Cari NIK atau Nama Delivery Man'
                        }}
                        left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                    />
                </FormControl>
            </Segment>
            <Segment width={"20%"}>
                <FormControl>
                    <OptionBox
                        placeholder={'Tipe Release'}
                        name='type'
                        className='validate[required]'
                        isClearable
                        options={payload?.listType}
                        onChange={(e) => handleSearch({
                            target: {
                                ...e,
                                value: e?.value ?? '',
                                name: 'type'
                            }
                        })}
                    />
                </FormControl>
            </Segment>
        </Segment>
        <DataTable
            countingList={true}
            defaultSize={isTable.size}
            tableConsume={tableCOnfiguration}
            dataConsume={payload?.data}
            showSize={true}
            showPagination={true}
            showSearch={false}
            totalPages={payload?.totalPages}
            activePage={isTable.page}
            createShowPerSize={(size) => setTable(prev => {
                return {
                    ...prev,
                    ...payload.parameter,
                    size: size.value
                }
            })}
            createPaginations={(page) => {
                const query = {
                    ...payload.parameter,
                    ...page
                }
                hasStatus('hasPagination')
                setTable(query)
            }}
        />

    </>
}

export default TabRiwayat