import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'
import {
    requestVerificationAccount,
    requestVerificationFailed,
    requestVerificationSuccessFullyForgotPassword,
    requestVerificationSuccessFullyResetPassword
} from 'store/actions/alertVerification'
import { requestVerificationReset } from 'store/actions/verifikasi-setup'
const locationPushAccepted = ({ history, pathname, state }) => {
    return history.replace({ pathname, state })
}
const locationPushReplaced = ({ history, pathname, state }) => {
    return history.replace({ pathname, state })
}
export const RequestVerificationSetup = action$ =>
    action$.pipe(
        ofType('REQUEST_SETUP', 'REQUEST_OTP', 'REQUEST_SETUP_PASSWORD'),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_SETUP':
                        action.flex === "reset-pass" && await Services().post('/user/api/user/reset-password-check', action.payload)
                        action.flex === "verif-pass" && await Services().post('/user/api/user/verification-check', action.payload)
                        return dispatch => {
                            dispatch(requestVerificationAccount({
                                open: true,
                                url: () => locationPushAccepted({
                                    history: action.history,
                                    pathname: '/otp',
                                    state: {
                                        email: action.payload.email,
                                        flex: action.flex
                                    }
                                })
                            }))
                        }
                    case 'REQUEST_OTP':
                        let getOTP = action.history.location.state;
                        let raw;
                        if (getOTP.flex === "reset-pass") raw = await Services().post('/user/api/user/reset-password-verify', { ...action.payload, email: getOTP.email })
                        if (getOTP.flex === "verif-pass") raw = await Services().post('/user/api/user/verify', { ...action.payload, email: getOTP.email })
                        return () => {
                            locationPushReplaced({ history: action.history, pathname: "/otp", state: undefined })
                            locationPushAccepted({
                                history: action.history,
                                pathname: '/new-password',
                                state: { nik: raw.data.data.nik, flex: getOTP.flex }
                            });
                        }
                    case 'REQUEST_SETUP_PASSWORD':
                        let getResetPass = action.history.location.state;
                        getResetPass.flex === "reset-pass" && await Services().post('/user/api/user/reset-password', action.payload)
                        getResetPass.flex === "verif-pass" && await Services().post('/user/api/user/set-password', action.payload)
                        return dispatch => {
                            getResetPass.flex === "reset-pass" && dispatch(requestVerificationSuccessFullyForgotPassword({
                                open: true,
                                url: () => {
                                    locationPushReplaced({ history: action.history, pathname: "/new-password", state: undefined })
                                    locationPushAccepted({
                                        history: action.history,
                                        pathname: '/login',
                                        state: undefined
                                    });
                                }
                            }))
                            getResetPass.flex === "verif-pass" && dispatch(requestVerificationSuccessFullyResetPassword({
                                open: true,
                                url: () => {
                                    locationPushReplaced({ history: action.history, pathname: "/new-password", state: undefined })
                                    locationPushAccepted({
                                        history: action.history,
                                        pathname: '/login',
                                        state: undefined
                                    })
                                }
                            }))
                        }
                    default:
                        break
                }
            } catch (e) {
                return dispatch => {
                    const { history, payload } = action
                    e.status === "03" && dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message } })
                    e.status === "U001" && dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message } })
                    e.status === "U002" && dispatch({
                        type: "SETUP_FAILED", payload: {
                            code: payload.email,
                            error: true
                        }
                    })
                    e.status === "U003" && dispatch(requestVerificationFailed({
                        open: true, url: () => {
                            locationPushAccepted({
                                history,
                                pathname: '/verifikasi',
                                state: undefined
                            });
                            dispatch(requestVerificationReset())
                        }
                    }))
                    e.status === "U004" && dispatch({
                        type: "OTP_FAILED", payload: {
                            code: payload.code,
                            error: true
                        }
                    })
                    e.status === "internal error" && dispatch({
                        type: "OTP_FAILED", payload: {
                            code: payload.code,
                            error: true
                        }
                    })
                    e.status === "U005" && dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message } })
                    e.status === "U006" && dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: e.message } })
                }
            }
        }),
    )
