import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestDeliveryman = action$ =>
    action$.pipe(
        ofType( 'REQUEST_DELIVERY_MAN',
                'REQUEST_DELIVERY_MAN_BY_ID',
                'REQUEST_CREATE_DELIVERY_MAN',
                'REQUEST_UPDATE_DELIVERY_MAN',
                'REQUEST_CHECK_NIK',
                'REQUEST_CHECK_EMAIL',
                'REQUEST_CHECK_PHONE',
                'REQUEST_PHOTO_PROFILE'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_DELIVERY_MAN':
                        const rawResponse = await Services().get('/driver/api/back-office/list',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DELIVERY_MAN',
                                payload: {
                                    data: rawResponse.data.data.result.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.result.totalPages,
                                    filterStatus: rawResponse.data.data.statusList,
                                    filterCluster: rawResponse.data.data.clusters,
                                }
                            })
                        }
                    case 'REQUEST_DELIVERY_MAN_BY_ID':
                        const rawResponseDetail = await Services().get(`/driver/api/back-office/${action.payload}`)
                        const splitLisencePlate = rawResponseDetail.data.data.licensePlate ? rawResponseDetail.data.data.licensePlate.split("-") : []
                        const licensePlateSegment1= splitLisencePlate.length === 3 ? splitLisencePlate[0] : ""
                        const licensePlateSegment2= splitLisencePlate.length === 3 ? splitLisencePlate[1] : ""
                        const licensePlateSegment3= splitLisencePlate.length === 3 ? splitLisencePlate[2] : ""
                        return dispatch => {
                            dispatch({
                                type: 'GET_DELIVERY_MAN_BY_ID',
                                payload: {
                                    data: {
                                        ...rawResponseDetail.data.data,
                                        licensePlateSegment1,
                                        licensePlateSegment2,
                                        licensePlateSegment3
                                    }
                
                                }
                            })
                        }

                    case 'REQUEST_CREATE_DELIVERY_MAN':
                        await Services().post(`/driver/api/back-office/add`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push('/delivery-man')
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Delivery Man baru' } })
                        }
                    case 'REQUEST_UPDATE_DELIVERY_MAN':
                        await Services().put(`/driver/api/back-office/${action.payload.id}`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/delivery-man/${action.payload.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Delivery Man berhasil' } })
                        }
                    case 'REQUEST_CHECK_NIK':
                        try {
                            const rawCheckNik = await Services().get(`/driver/api/back-office/check-nik/${action.payload.params.nik}`,{id:action.payload.params.id})
                            if(rawCheckNik.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD',
                                        payload: {
                                            field:'nik',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD',
                                        payload: {
                                            field:'nik',
                                            message: 'NIK sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }
                                
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD',
                                    payload: {
                                        field:'nik',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_CHECK_EMAIL':
                        try {
                            const rawCheckEmail = await Services().get(`/driver/api/back-office/check-email/${action.payload.params.email}`,{id:action.payload.params.id})
                            
                            if(rawCheckEmail.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD',
                                        payload: {
                                            field:'email',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD',
                                        payload: {
                                            field:'email',
                                            message: 'Email sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }  
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD',
                                    payload: {
                                        field:'email',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_CHECK_PHONE':
                        try {
                            const rawCheckPhone = await Services().get(`/driver/api/back-office/check-phone/${action.payload.params.phone}`,{id:action.payload.params.id})
                            if(rawCheckPhone.data.data.available){
                                return dispatch => {
                                    dispatch({
                                        type: 'GET_STATUS_FIELD',
                                        payload: {
                                            field:'phone',
                                            status: '00',
                                            message:''
                                        }
                                    })
                                }
                            }else{
                                return dispatch => {
                                    dispatch({
                                        type: 'ERROR_STATUS_FIELD',
                                        payload: {
                                            field:'phone',
                                            message: 'No HP sudah digunakan',
                                            status:'03'
                                            
                                        }
                                    })
                                }
                            }     
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_FIELD',
                                    payload: {
                                        field:'phone',
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_PHOTO_PROFILE':
                        const rawResponseImage = await Services().get(action.payload.url,null,{ responseType: "arraybuffer" })
                    
                        const blob = new Blob([rawResponseImage.data], { type: "image/png" });
                        const uris = URL.createObjectURL(blob);
                        return dispatch => {
                            dispatch({
                                type: 'GET_PHOTO_PROFILE',
                                payload: {
                                    data: uris
                                    
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_DELIVERY_MAN_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
