import React from 'react'
import {
    Segment
} from "@elevenia/master-ui/components/Atom"
import { Link } from 'react-router-dom'
import DataTable from 'component/DataTable'

import { capitalize } from 'helper'
import moment from 'moment'
import { serviceType } from './helper'
import CountDown from './CountDown'
import TimeSlot from 'component/TimeSlot'
import { colorOrderId } from 'helper'
import useModalFilter from 'hooks/useModalFilterOrder'
import SectionFilterOrder from 'component/SectionFilterOrder'
const TabPesanan = (
    {
        data,
        totalPages,
        loading,
        isTable,
        setTable,
        handleSearch,
        handlePagination,
        filterCluster,
        loadingCluster,
        listOptionsKategori,
        currentTime,
        timezoneLabel,
        orderConfig,
        filterChannel,
        loadingChannel,
        listOptionsServ,
        setCategorySearch,
        categorySearch,
        parameter
    }
) => {
    const { modal, setModal, ModalFilter } = useModalFilter()
    
    return (
        <>
            <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} display="flex" justifyContent="space-between" mb={24}>
                <SectionFilterOrder
                    setModal={setModal}
                    handleSearch={handleSearch}
                    setCategorySearch={setCategorySearch}
                    categorySearch={categorySearch}
                    setTable={setTable}
                    isTable={parameter}
                />
            </Segment>
            <DataTable
                countingList={true}
                defaultSize={isTable.size}
                tableConsume={[{
                    field: 'Order ID',
                    isCustomRow: (id, entity) => {
                        return (
                            <Link style={{ color: colorOrderId(entity.channelOrder) }} to={{ pathname: `/pesanan/${entity.id}` }}>{entity.orderId}</Link>
                        )
                    }
                }, {
                    field: 'Kategori',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.serviceType && capitalize(entity.serviceType)
                        )
                    }
                }, {
                    field: 'Klaster',
                    rowField: 'clusterCode',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.clusterCode ?? '-'
                        )
                    },
                    width: "10%"

                }, {
                    field: 'Toko Asal',
                    rowField: 'storeCode',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.storeCode ??  '-'
                        )
                    },
                    width: "10%"
                    
                }, {
                    field: 'Toko Deliman',
                    rowField: 'assignedStoreCode',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.assignedStoreCode ?? '-'
                        )
                    },
                    width: "10%"

                }, {
                    field: 'Status',
                    rowField: 'orderStatusType',
                    isCustomRow: (id, entity) => {
                        return entity?.orderStatusType ?? "-"
                    }
                }, {
                    field: 'Channel',
                    rowField: 'channelOrder',
                    isCustomRow: (id, entity) => {
                        return (
                            entity.channelOrder ? capitalize(entity.channelOrder) : '-'
                        )
                    },
                    width: "10%"

                }, {
                    field: 'Sales Order',
                    rowField: 'salesOrderId',
                }, {
                    field: 'Service',

                    isCustomRow: (id, entity) => {
                        return serviceType(entity)


                    }
                } ,{
                    field: 'Time Out',
                    isCustomRow: (id, entity) => {

                        return <CountDown
                            category={entity.serviceType}
                            serviceType={serviceType(entity)}
                            startDuration={currentTime}
                            endDuration={entity.expiredDeliveryTime}
                            orderConfig={orderConfig}
                            channelOrder={entity.channelOrder}
                            orderType={entity.orderType}
                        />


                    },
                }, {
                    field: 'Slot',
                    isCustomRow: (id, entity) => {

                        return (
                            <TimeSlot data={entity} timezoneLabel={timezoneLabel} />
                        )
                    }
                }, {
                    field: 'Tanggal Kirim',
                    isCustomRow: (id, entity) => {
                        return (
                            <>{entity.minDeliveryTime ? moment(entity.minDeliveryTime).format('DD/MM/YYYY') : '-'} </>
                        )
                    },
                    width: "10%"
                }
                ]}
                dataConsume={data}
                isLoading={loading}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={totalPages}
                createShowPerSize={(size) => setTable(prev => {
                    return {
                        ...prev,
                        orderStatus:'ALLOCATING',
                        size: size.value
                    }
                })}
                createPaginations={(page) => handlePagination(page)}
            />
            {
                modal && 
                <ModalFilter
                    isOpen={modal}
                    toggle={()=> setModal(false)}
                    filterChannel={{
                        show: true,
                        options: filterChannel,
                        loading: loadingChannel
                    }}
                    filterCluster={{
                        show: true,
                        options: filterCluster,
                        loading: loadingCluster
                    }}
                    filterCategory={{
                        show: true,
                        options: listOptionsKategori
                    }}
                    doFilter={setTable}
                    paramsFilter={isTable}
                    orderStatus="ALLOCATING"
                    filterServices={{
                        show: true,
                        options: listOptionsServ
                    }}
                    filterStatus={{
                        show: true
                    }}
                />
            }
            
        </>
    )
}

export default TabPesanan