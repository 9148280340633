
const initialState = {
    dateTime:null,
    dataOrderSummary: null,
    loadingOrderSummary: false,
    dataAbnormalPickup:null,
    loadingAbnormalPickup:false,
    dataAbnormalInDelivery:null,
    loadingAbnormalInDelivery:false,
    loadingSLADeliveryReview:true,
    dataSLADeliveryReview:{
        orderFinishedInTime:0,
        orderFinishedOverTime:0
    },
    loadingCanceledDelivery: true,
    dataCanceledDelivery:[],
    totalOrderAbnormalPickup:null,
    timeStampAbnormalPickup:null,
    totalOrderAbnormalInDelivery:null,
    timeStampAbnormalInDelivery:null,
    loadingNotYetCanceled: true,
    dataNotYetCanceled:null,
    totalOrderNotYetCanceled: null,
    loadingHangingAwb: true,
    dataHangingAwb:null,
    totalOrderHangingAwb: 0
}

export const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_ORDER_SUMMARY':
            return {
                ...state,
                loadingOrderSummary: true
            }
        case 'REQUEST_ORDER_SUMMARY_INTERVAL':
          return {
              ...state,
              loadingOrderSummary: false
          }
        case 'REQUEST_ABNORMAL_PICKUP':
            return {
                ...state,
                loadingAbnormalPickup: true
            }
        case 'REQUEST_SLA_DELIVERY_REVIEW':
            return {
                ...state,
                loadingSLADeliveryReview: true
            }
        case 'REQUEST_CANCELED_DELIVERY':
            return {
                ...state,
                loadingCanceledDelivery: true
            }
        case 'REQUEST_SLA_DELIVERY_REVIEW_INTERVAL':
            return {
                ...state,
                loadingSLADeliveryReview: false
            }
        case 'REQUEST_CANCELED_DELIVERY_INTERVAL':
            return {
                ...state,
                loadingCanceledDelivery: false
            }
        case 'REQUEST_ABNORMAL_PICKUP_INTERVAL':
          return {
              ...state,
              loadingAbnormalPickup: false
          }
        case 'REQUEST_ABNORMAL_IN_DELIVERY':
            return {
                ...state,
                loadingAbnormalInDelivery: true
            }
        case 'REQUEST_ABNORMAL_IN_DELIVERY_INTERVAL':
          return {
              ...state,
              loadingAbnormalInDelivery: false
          }
        case 'GET_DATA_ORDER_SUMMARY':
            return {
                ...state,
                dataOrderSummary:action.payload.data,
                dateTime:action.payload.dateTime,
                loadingOrderSummary: false
            }
        case 'GET_DATA_ABNORMAL_PICKUP':
            return {
                ...state,
                dataAbnormalPickup:action.payload.data,
                totalOrderAbnormalPickup:action.payload.totalOrder,
                timeStampAbnormalPickup:action.payload.timeStamp,
                loadingAbnormalPickup: false
            }
        case 'GET_DATA_ABNORMAL_IN_DELIVERY':
            return {
                ...state,
                dataAbnormalInDelivery:action.payload.data,
                totalOrderAbnormalInDelivery:action.payload.totalOrder,
                timeStampAbnormalInDelivery:action.payload.timeStamp,
                loadingAbnormalInDelivery: false
            }
        case 'GET_DATA_SLA_DELIVERY_REVIEW':
            return {
                ...state,
                dataSLADeliveryReview:action.payload.data,
                loadingSLADeliveryReview:false
            }
        case 'GET_DATA_CANCELED_DELIVERY':
            return {
                ...state,
                dataCanceledDelivery:action.payload.data,
                loadingCanceledDelivery:false
            }
        case 'DATA_DASHBOARD_FAILED':
            return {
                ...state,
                loadingOrderSummary: false,
                loadingAbnormalInDelivery: false,
                loadingAbnormalPickup: false,
                loadingCanceledDelivery: false,
                loadingSLADeliveryReview: false
            }
        case 'REQUEST_NOT_YET_CANCELED':
            return {
                ...state,
                loadingNotYetCanceled: true
            }
        case 'REQUEST_NOT_YET_CANCELED_INTERVAL':
            return {
                ...state,
                loadingNotYetCanceled: false
            }
        case 'REQUEST_HANGING_AWB':
            return {
                ...state,
                loadingHangingAwb: true
            }
        case 'REQUEST_HANGING_AWB_INTERVAL':
            return {
                ...state,
                loadingHangingAwb: false
            }
        case 'GET_DATA_NOT_YET_CANCELED':
            return {
                ...state,
                dataNotYetCanceled:action.payload.data,
                totalOrderNotYetCanceled:action.payload.totalOrder,
                loadingNotYetCanceled: false
            }
        case 'GET_DATA_HANGING_AWB':
            return {
                ...state,
                dataHangingAwb:action.payload.data,
                totalOrderHangingAwb:action.payload.totalOrder,
                loadingHangingAwb: false
            }
        default:
            return state
    }
}