import {
  AlertBox, Badge, Button, FormControl,
  Icon, Segment, Spinner, Text, Textfield, OptionBox
} from "@elevenia/master-ui/components/Atom";
import Breadcrumb from "component/Breadcrumb";
import { getLabelTimeZone, trackerStatus, capitalize, formatRupiahCommas, orderStatus } from 'helper';
import { useAction } from "hooks";
import Error404 from "page/errorLayout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  requestCancelOrder, requestFileTrackRecord,
  requestListReasonCancelOrder, requestMultiTrackOrder,
  requestOptionSearchCategory
} from "store/actions/trackOrder";
import ModalDelete from './_component/ModalDelete';
import TimeSlot from 'component/TimeSlot';
const iconSuccess = require('assets/image/icon-checked.svg')
const iconWarning = require('assets/image/icon-warning.svg')

const LacakPesanan = (props) => {
  document.title = props.title;
  const dataBreadcrumb = [{ to: "", label: "Lacak Pesanan" }];
  const { hasFetch } = useAction();
  const moment = require("moment");

  const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60)
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    hasFetch({ type: "TRACK_ORDER_RESET" });
    return () => {
      hasFetch({ type: "ALERT_CLEARS" });
    };
  }, [hasFetch]);


  const CustomBadge = ({ children, ...props }) => (
    <Segment
      {...props}
      display={"inline-block"}
      mr={8}
      mb={6}
      color={"white"}
      borderRadius={12}
      py={6}
      px={8}
      fontSize={12}
    >
      {children}
    </Segment>
  );

  const payload = useSelector((state) => ({
    notification: state.trackOrder.componentMessage,
    loading: state.trackOrder.loading,
    data: state.trackOrder.data,
    trackHistory: state.trackOrder.trackHistory,
    allowedStatus: state.homesidemenu?.action_group.find(val => val.code === "order-cancel") ? state.homesidemenu?.action_group.find(val => val.code === "order-cancel")['actions'].filter(val => val.allowed).map(val => val.value) : [],
    dataMulti: state.trackOrder.dataMulti,
    listTab: state.trackOrder.listTab,
    responError: state.setAlerts.notify,
    loadingOptions: state.trackOrder.loadingOptions,
    dataOptions: state.trackOrder.dataOptions?.map(val => ({ value: val.id, label: val.label })),
    propertiesImage: state.trackOrder.propertiesImage
  }));
  const informasiDriver = ["Nama", "No. Handphone", "NIK", "Klaster", "Toko Asal Delivery Man"];
  const informasiCustomer = [
    "Nama Customer",
    "Nama Penerima",
    "No. Handphone",
    "Alamat",
  ];
  const informasiPengiriman = [
    "Order ID",
    "AWB ID",
    "TrackNum (awbippid)",
    "Sales Order",
    "Channel",
    "Tipe Order",
    "Diangkut Dengan",
    "Kategori/Service",
    "Slot",
    "Batas Kirim",
    "Tanggal Kirim Awal",
    "Tanggal Kirim Akhir",
    "Toko",
    "Cabang",
    "Nama Biller",
  ];
  const fileRecord = [
    "Bukti Penjemputan",
    "Bukti Pembayaran",
    "Bukti Pengiriman",
    "Bukti Pembatalan",
    "Struk Pengiriman",
    "Struk Setor Tunai",
    "Struk COD",
    "Struk Retur",
    "Struk Pembatalan",
  ];
  const { notification, loading, dataMulti, listTab, responError, dataOptions, loadingOptions } = payload;

  const [searchValue, setSearchValue] = useState("")
  const [categorySearch, setCategorySearch] = useState(null)
  const [activeData, setActiveData] = useState(null)
  const [activeTab, setActiveTab] = useState(null)

  useEffect(() => {
    if (!loading) {


      setActiveData(dataMulti ? activeTab ? dataMulti.find((val, idx) => `${val.searchParam}_${idx}` === activeTab) : dataMulti[0] : null)
      setActiveTab(listTab ? activeTab ? listTab.find(item => item === activeTab) : listTab[0] : null)
    }

  }, [loading, dataMulti, listTab, activeTab])

  useEffect(() => {
    props.access?.read && hasFetch(requestOptionSearchCategory())
  }, [hasFetch, props.access])

  useEffect(() => {
    if (loadingOptions === false && dataOptions.length > 0 & categorySearch === null) {

      const setDefaultValue = dataOptions?.find(val => val.value === 1)
      setCategorySearch(setDefaultValue.value)
    }
  }, [loadingOptions, dataOptions, categorySearch])

  const handleSearchOrder = () => {
    hasFetch(requestMultiTrackOrder(searchValue, categorySearch))

  }

  const handleShowData = (param) => {
    setActiveTab(param)
    const dataActive = dataMulti.find((val, idx) => `${val.searchParam}_${idx}` === param)
    setActiveData(dataActive)
  }

  const handleDelete = (param) => {
    const newData = dataMulti.filter((val, idx) => `${val.searchParam}_${idx}` !== param)
    hasFetch({
      type: 'GET_MULTI_TRACK_ORDER',
      payload: {
        multiTracking: newData,

      }
    })
  }

  const ColorBadgeInactive = {
    height: "auto",
    padding: "10px 12px",
    minWidth: 0,
    borderRadius: "50px",
    border: "1px solid #DCDEE3",
    background: "#EFF3F6",
    color: "black",
    fontWeight: 400,
    marginRight: 16,
    marginBottom: 4
  }

  const ColorBadgeActive = {
    height: "auto",
    padding: 2,
    minWidth: 0,
    background: "white",
    borderRadius: "50px",
    fontWeight: 400
  }




  if (!props.access) return <></>;
  else if (!props.access?.read) return <Error404 />;
  return (
    <>
      {notification?.Modal}
      <Segment justifyContent={"space-between"} className="xs-flex-wrap">
        <Segment mb={30} maxWidth={468} width={"100%"}>
          <Text H28 mb={"1rem"}>
            Lacak Pesanan
          </Text>
          <Breadcrumb data={dataBreadcrumb} />
        </Segment>
        <Segment justifyContent="center" className="xs-flex-wrap">
          <Segment mr={16} width={160} className={"fw500"}>

            {
              !loadingOptions &&
              <FormControl>
                <OptionBox
                  defaultValue={dataOptions?.find(val => val.value === 1)}
                  options={dataOptions}
                  placeholder={"Order ID"}
                  isLoading={loadingOptions}
                  isDisabled={loadingOptions}
                  onChange={(e) => {
                    setCategorySearch(e.value)
                  }}

                />
              </FormControl>
            }

          </Segment>
          <Segment>
            <Segment display="flex">
              <Segment width={300} mr={16}>
                <FormControl status={'normal'}>
                  <Textfield
                    status={responError.message ? 'error' : 'normal'}
                    inputProps={{
                      maxLength: 100,
                      onChange: (e) => {
                        setSearchValue(e.target.value)
                      },
                      value: searchValue,
                      placeholder: "Cari Order ID, SO ID, atau AWB",
                    }}
                    left={<Icon name={"search"} size={16} fillColor="#9c9da5" />}
                  />
                </FormControl>
              </Segment>
              <Segment>
                <Button
                  style={{ minWidth: 58 }}
                  disabled={searchValue ? false : true}
                  onClick={() => handleSearchOrder()}>
                  Cari
                </Button>
              </Segment>
            </Segment>
            <Text H10 color={responError.message ? "#EE2B2E" : "#979797"} mt={12} style={{ maxWidth: 350 }} textAlign="right" ml="auto">
              {responError.message ? responError.message : `Pisahkan dengan tanda “,” untuk pencarian multiple (maksimum 5 order).
Contoh: “DMS-12345, DMS-4567, DMS-7892, dst”`}
            </Text>
          </Segment>
        </Segment>
      </Segment>

      {loading && dataMulti === null && (
        <Segment className="u-tx-center" width="100%" height={40}>
          <Spinner />
        </Segment>
      )}
      {(activeData && !loading) ?
        <>
          <Segment mb={24}>
            <Segment
              boxShadow={"0 1px 4px 0 #70727d33"}
              borderRadius={4}
              bg={"white"}
              p={20}
              borderBottom={"1px solid #DCDEE3"}
              mb={24}
              display="flex"
              className="md-flex-wrap"
            >
              {
                listTab?.map((val, idx) => {
                  const explodeTab = val.split("_")
                  const labelTab = explodeTab[0]
                  return val === activeTab ? (
                    <Segment
                      key={idx}
                      py={10}
                      px={12}
                      display="flex"
                      bg={"#1178D4"}
                      color={"white"}
                      borderRadius={40}
                      alignItems="center"
                      mb={4}
                      mr={16}
                    >
                      {labelTab}
                      <Segment borderRadius={"50%"} bg={"white"} ml={12}>
                        <Button
                          style={ColorBadgeActive}
                          onClick={() => handleDelete(val)}
                        >
                          <Icon name="cancel" size={12} fillColor="#1178D4" />
                        </Button>
                      </Segment>
                    </Segment>
                  )
                    :
                    <Button
                      key={idx}
                      onClick={() => handleShowData(val)}
                      style={ColorBadgeInactive}
                    >
                      {labelTab}
                    </Button>
                })
              }

            </Segment>
          </Segment>
          {
            activeData.errorMessage &&
            <AlertBox type={"error"} isOpen={true} responsive={true}>
              {activeData.errorMessage}
            </AlertBox>
          }
          {
            !activeData.errorMessage &&
            <>
              <Segment display="flex" justifyContent="space-between" className="wrapper-info-pesanan">
                <Segment width={"49%"}>
                  <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={24}>
                    <Segment display="flex" justifyContent="space-between">
                      <Text fontWeight={600} mb={32}>
                        Informasi Delivery Man
                      </Text>
                    </Segment>
                    {informasiDriver.map((item, index) => {
                      if (item === 'Toko Asal Delivery Man') {
                        if (activeData?.order?.orderStatus !== "ALLOCATING") {

                          return (
                            <Segment key={index} mb={24} display="flex">
                              <Segment className="label-info">
                                <Text color={"#70727D"}>{item}</Text>
                              </Segment>
                              <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {activeData?.order?.driverNIK && activeData?.order?.assignedStoreCode ? `${activeData?.order?.assignedStoreCode} - ${activeData?.order?.assignedStoreName} ` : '-'}

                              </Segment>
                            </Segment>
                          )
                        } else {
                          return ''
                        }
                      } else {
                        return (
                          <Segment key={index} mb={24} display="flex">
                            <Segment className="label-info">
                              <Text color={"#70727D"}>{item}</Text>
                            </Segment>
                            <Segment display="flex">
                              <Text mr={8}>:</Text>
                              {item === "Nama" && activeData?.order?.driverName}
                              {item === "No. Handphone" && <Segment className="word-all-break">{activeData?.order?.driverPhoneNumber}</Segment>}
                              {item === "NIK" && activeData?.order?.driverNIK}
                              {item === "Klaster" && activeData?.order?.clusterName}

                            </Segment>
                          </Segment>
                        )
                      }

                    })}
                  </Segment>
                  <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={24}>
                    <Segment display="flex" justifyContent="space-between">
                      <Text fontWeight={600} mb={32}>
                        Informasi Customer
                      </Text>
                    </Segment>
                    {informasiCustomer.map((item, index) => (
                      <Segment key={index} mb={24} display="flex">
                        <Segment className="label-info">
                          <Text color={"#70727D"}>{item}</Text>
                        </Segment>
                        <Segment display="flex">
                          <Text mr={8}>:</Text>
                          {item === "Nama Customer" && activeData?.order?.customerName}
                          {item === "Nama Penerima" && activeData?.order?.receiverName}
                          {item === "No. Handphone" && <Segment className="word-all-break">{activeData?.order?.customerPhoneNumber}</Segment>}
                          {item === "Alamat" && activeData?.order?.deliveryAddress}
                        </Segment>
                      </Segment>
                    ))}
                  </Segment>
                  <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment display="flex" justifyContent="space-between">
                      <Text fontWeight={600} mb={32}>
                        File Pendukung
                      </Text>
                    </Segment>
                    {fileRecord
                      .map((item, index) => (
                        <Segment key={index} mb={24} display="flex">
                          <Segment className="label-info">
                            <Text color={"#70727D"}>{item}</Text>
                          </Segment>
                          <Segment display="flex">
                            <Text mr={8}>:</Text>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                activeData?.order?.pickupImageUrl &&
                                  item === "Bukti Penjemputan" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.pickupImageUrl,
                                    })
                                  );
                                activeData?.order?.deliveryImageUrl && activeData?.order?.orderType !== "PAKET-JEMPUT" &&
                                  item === "Bukti Pengiriman" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.deliveryImageUrl,
                                    })
                                  );
                                activeData?.order?.codImageUrl &&
                                  item === "Struk COD" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.codImageUrl,
                                    })
                                  );
                                activeData?.order?.bulkyImageUrl &&
                                  item === "Struk Retur" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.bulkyImageUrl,
                                    })
                                  );
                                activeData?.order?.cancelImageUrl &&
                                  item === "Struk Pembatalan" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.cancelImageUrl,
                                    })
                                  );
                                activeData?.order?.canceledImageUrl &&
                                  item === "Bukti Pembatalan" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.canceledImageUrl,
                                    })
                                  );
                                activeData?.order?.pickupPaymentImageUrl &&
                                  item === "Bukti Pembayaran" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.pickupPaymentImageUrl,
                                    })
                                  );
                                activeData?.order?.deliveryImageUrl && activeData?.order?.orderType === "PAKET-JEMPUT" &&
                                  item === "Struk Pengiriman" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.deliveryImageUrl,
                                    })
                                  );
                                activeData?.order?.deliveryPaymentImageUrl &&
                                  item === "Struk Setor Tunai" &&
                                  hasFetch(
                                    requestFileTrackRecord({
                                      open: true,
                                      imageURL: activeData?.order?.deliveryPaymentImageUrl,
                                    })
                                  );
                              }}
                              to={"/"}
                            >
                              <Text fontWeight={500}>
                                {item === "Bukti Penjemputan" &&
                                  activeData?.order?.pickupImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Bukti Pengiriman" && activeData?.order?.orderType !== "PAKET-JEMPUT" &&
                                  activeData?.order?.deliveryImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Struk COD" &&
                                  activeData?.order?.codImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Struk Retur" &&
                                  activeData?.order?.bulkyImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Struk Pembatalan" &&
                                  activeData?.order?.cancelImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Bukti Pembatalan" &&
                                  activeData?.order?.canceledImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Bukti Pembayaran" &&
                                  activeData?.order?.pickupPaymentImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Struk Pengiriman" && activeData?.order?.orderType === "PAKET-JEMPUT" &&
                                  activeData?.order?.deliveryImageUrl &&
                                  "Lihat Gambar"}
                                {item === "Struk Setor Tunai" &&
                                  activeData?.order?.deliveryPaymentImageUrl &&
                                  "Lihat Gambar"}
                              </Text>
                            </Link>
                            <Text>
                              {item === "Bukti Penjemputan" &&
                                !activeData?.order?.pickupImageUrl &&
                                "-"}
                              {item === "Bukti Pengiriman" && (activeData?.order?.orderType === "PAKET-JEMPUT" ||
                                !activeData?.order?.deliveryImageUrl) &&
                                "-"}
                              {item === "Bukti Pembayaran" && !activeData?.order?.pickupPaymentImageUrl && "-"}
                              {item === "Struk Pengiriman" && (activeData?.order?.orderType !== "PAKET-JEMPUT" || !activeData?.order?.deliveryImageUrl) && "-"}
                              {item === "Struk Setor Tunai" && !activeData?.order?.deliveryPaymentImageUrl && "-"}
                              {item === "Struk COD" && !activeData?.order?.codImageUrl && "-"}
                              {item === "Struk Retur" && !activeData?.order?.bulkyImageUrl && "-"}
                              {item === "Struk Pembatalan" &&
                                !activeData?.order?.cancelImageUrl &&
                                "-"}
                              {item === "Bukti Pembatalan" &&
                                !activeData?.order?.canceledImageUrl &&
                                "-"}
                            </Text>
                          </Segment>
                        </Segment>
                      ))}
                  </Segment>
                </Segment>
                <Segment
                  boxShadow
                  borderRadius={4}
                  bg={"white"}
                  p={24}
                  width={"49%"}
                >
                  <Segment>
                    <Segment
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Text fontWeight={600} mb={32}>
                        Informasi Pengiriman
                      </Text>
                      <Segment alignItems={"center"}>
                        <Text fontWeight={500} color={"#1178D4"}></Text>
                        <Badge bg="#FF7D1D" fontSize="12">
                          {orderStatus(activeData?.order?.textOrderStatus)}
                        </Badge>
                      </Segment>
                    </Segment>
                    {informasiPengiriman
                      .filter((item) =>
                        activeData?.order?.cod && activeData?.order?.serviceType === "REGULAR"
                          ? item
                          : item !== "Nama Biller"
                      )
                      .map((item, index) => {
                        const showTrackNum = item === "TrackNum (awbippid)" && ['SAMEDAY', 'REGULAR-PAKET'].includes(activeData?.order?.serviceType)
                          ? ['PAKET-EXPRESS', 'PAKET', 'PAKET-JEMPUT', 'PJB'].includes(activeData?.order?.orderType)
                            ? true
                            : false
                          : false;

                        if (item === "TrackNum (awbippid)" && !showTrackNum) {
                          return null;
                        };

                        return (
                          <Segment key={index} mb={24} display="flex">
                            <Segment className="label-info">
                              <Text color={"#70727D"}>{item}</Text>
                            </Segment>
                            <Segment display="flex">
                              <Text mr={8}>:</Text>
                              {item === "Order ID" && <Segment className="word-all-break">{activeData?.order?.orderId}</Segment>}
                              {item === "AWB ID" && <Segment className="word-all-break">{activeData?.order?.awbId}</Segment>}
                              {item === "TrackNum (awbippid)" && <Segment className="word-all-break">{activeData?.order?.awbIppId}</Segment>}
                              {item === "Sales Order" && <Segment className="word-all-break">{activeData?.order?.salesOrderId}</Segment>}
                              {item === "Channel" && <Segment className="word-all-break">{activeData?.order?.channelOrder ? capitalize(activeData?.order?.channelOrder) : '-'}</Segment>}
                              {item === "Tipe Order" && <Segment className="word-all-break">{activeData?.order?.orderType ?? '-'}</Segment>}
                              {item === "Diangkut Dengan" ? activeData?.order?.vehicleType ? <Segment className="word-all-break">{capitalize(activeData?.order?.vehicleType)}</Segment> : '-' : ''}
                              {item === "Kategori/Service" && (
                                <Segment>
                                  <Segment>
                                    {activeData?.order?.serviceType && (
                                      <CustomBadge bg={"green20"}>
                                        {activeData?.order?.serviceType}
                                      </CustomBadge>
                                    )}
                                    {activeData?.order?.cod && (
                                      <CustomBadge bg={"#1178D4"}>COD</CustomBadge>
                                    )}
                                    {activeData?.order?.bulky && (
                                      <CustomBadge bg={"#FFA800"}>Bulky</CustomBadge>
                                    )}
                                    {activeData?.order?.orderReturn && (<CustomBadge bg={"#FD474A"}>Retur</CustomBadge>)}
                                  </Segment>
                                  <Segment mt={16}>
                                    <ul>
                                      {activeData?.order?.bulky &&
                                        activeData?.order?.item.map((itemChild, indexChild) => (
                                          <li key={indexChild}>
                                            - {itemChild.quantity}{" "}
                                            {itemChild.itemName}
                                          </li>
                                        ))}
                                      <li>
                                        {activeData?.order?.codAmount !== 0 &&
                                          activeData?.order?.codAmount &&
                                          activeData?.order?.cod &&
                                          `- Uang Tunai ${formatRupiahCommas(
                                            activeData?.order?.codAmount,
                                            "Rp"
                                          )}`}
                                      </li>
                                    </ul>
                                  </Segment>
                                </Segment>
                              )}
                              {item === "Slot" && <TimeSlot data={activeData?.order} timezoneLabel={timezoneLabel} />}
                              {item === "Batas Kirim" &&
                                `${moment(activeData?.order?.minDeliveryTime).format(
                                  "HH.mm"
                                )} - ${moment(activeData?.order?.maxDeliveryTime).format("HH.mm")} ${timezoneLabel}`}
                              {item === "Tanggal Kirim Awal" &&
                                `${moment(activeData?.order?.minDeliveryTime).format(
                                  "DD/MM/YYYY"
                                )}`}
                              {item === "Tanggal Kirim Akhir" &&
                                `${moment(activeData?.order?.maxDeliveryTime).format("DD/MM/YYYY")}`}
                              {item === "Toko" ? (activeData?.order?.storeCode || activeData?.order?.storeName) ? `${activeData?.order?.storeCode} - ${activeData?.order?.storeName}` :
                                '-' : ''}
                              {item === "Cabang" ? activeData?.order?.dcCode && activeData?.order?.dcName
                                ? `${activeData?.order?.dcCode} - ${activeData?.order?.dcName}`
                                : "-" : ''}
                              {item === "Nama Biller" && activeData?.order?.codPaymentBiller}
                            </Segment>
                          </Segment>
                        );
                      })}
                    {activeData?.order?.orderStatus === "COMPLETED" &&
                      activeData?.order?.dataCompletedTime.valueOf() > activeData?.order?.dataMaxDeliveryTime.valueOf() && (
                        <Segment mb={24} display="flex">
                          <Segment className="label-info">
                            <Text color={"#70727D"}>Catatan Pengiriman</Text>
                          </Segment>
                          <Segment display="flex">
                            <Text mr={8}>:</Text>
                            <Text>{activeData?.order?.driverDeliveryNotes}</Text>
                          </Segment>
                        </Segment>
                      )}
                    {activeData?.order?.orderStatus === "COMPLETED" &&
                      (
                        <Segment mb={24} display="flex">
                          <Segment className="label-info">
                            <Text color={"#70727D"}>SLA</Text>
                          </Segment>
                          <Segment display="flex">
                            <Text mr={8}>:</Text>
                            {
                              <Text
                                color={
                                  activeData?.order?.dataCompletedTime.valueOf() > activeData?.order?.dataMaxDeliveryTime.valueOf()
                                    ? "red"
                                    : "black"
                                }
                              >
                                {activeData?.order?.display}
                              </Text>
                            }
                          </Segment>
                        </Segment>
                      )}
                    {
                      activeData?.order?.orderType === "PAKET-JEMPUT" &&
                      (
                        <Segment mb={24} display="flex">
                          <Segment className="label-info">
                            <Text color={"#70727D"}>Informasi Pembayaran</Text>
                          </Segment>
                          <Segment display="flex">
                            <Text mr={8}>:</Text>
                            {
                              activeData?.order?.paymentInformation ?? "-"
                            }
                          </Segment>
                        </Segment>
                      )
                    }
                  </Segment>
                  <Segment>
                    <Segment
                      name="divide"
                      height={1}
                      bg={"#DCDEE3"}
                      mt={32}
                      mb={32}
                    />
                    <Text fontWeight={600} mb={32}>
                      Proses Pengiriman
                    </Text>
                    <Segment>{
                      activeData?.orderHistory?.map((item, index) => {
                        let status = item.orderStatus
                        if (item.cancelType === 'CANCEL_FROM_BO' && item.orderStatus === 'CANCELED') {
                          status = 'CANCELED_BO'
                        }
                        return (
                          <Segment key={index} display="flex" className="progress-pengiriman-list" position="relative" mb={16}>
                            <Segment mr={16}>
                              <img width={30} src={item.orderStatus === "FAILED" || item.orderStatus === "CANCELED" || item.orderStatus === "CANCELING" ? iconWarning : iconSuccess} alt={'icon success'} />
                            </Segment>
                            <Segment>
                              <Text fontWeight={500} mb={11} pt={8}>{trackerStatus(status)}</Text>
                              <Text color={'#9C9DA6'} fontSize={12}>{`${moment(item.historyDate).format("D/MM/YYYY - HH:mm")} ${timezoneLabel}`} {(item.orderStatus === "CANCELED" || item.orderStatus === "CANCELING") ? `- ${item.cancelReason} ` : ''}</Text>
                            </Segment>
                          </Segment>
                        )
                      })
                    }
                    </Segment>
                  </Segment>
                </Segment>
              </Segment>

              <Segment mt={32} justifyContent={"flex-end"}>
                <Button
                  disabled={!payload.allowedStatus.includes(activeData?.order?.orderStatus)}
                  className="btn-secondary-red"
                  variant="secondary-alt"
                  size="medium"
                  type={"button"}
                  onClick={() => setShowModal(true)}
                >
                  Batalkan Pesanan
                </Button>
              </Segment>
            </>
          }
        </>

        : ""}
      {showModal && <ModalDelete
        orderStatus={activeData?.order?.orderStatus}
        orderId={activeData?.order?.orderId}
        searchValue={searchValue}
        categoryOrderId={categorySearch}
        showModal={showModal}
        setShowModal={setShowModal}
        getListReason={requestListReasonCancelOrder}
        cancelOrder={requestCancelOrder}
      />}
      {/* Modal Delete */}
    </>
  );
};
export default LacakPesanan;
