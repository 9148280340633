import React, { useEffect, useState } from "react"
import {
    Segment,
    DatePicker,
    FormControl,
    Icon,
    ButtonLink,
    Text,
    RadioButton,
    ButtonGroup,
    Button
} from '@elevenia/master-ui/components/Atom';
import { ModalLite, ModalTitle, ModalHeader, ModalBody, ModalFooter } from '@elevenia/master-ui/components/Molecules';
import moment from "moment";
const FilterModal = ({ isOpen, onClose, actionFilter, paramsFilter, loading }) => {
    
    
    const initParamFilter = {
        startDate: moment().startOf('month').toDate(),
        endDate:'',
        clockinStatus:'',
        clockoutStatus:'',
        delimanCondition: '',
        mobileDeviceCondition: '',
        motorcycleCondition: '',

    }
    
    const [paramFilter, setParamFilter] = useState(initParamFilter)
    
    const parsingCondition = (value) => {
        const condition = {
            true: 'ok',
            false: 'nok'
        }
        return condition[value] ?? ''
    }
    const reverseParsingCondition = (value) => {
        const condition = {
            ok: true,
            nok: false
        }
        return condition[value] ?? ''
    }
    useEffect(()=>{
        if(paramsFilter){
            setParamFilter({    
                startDate: paramsFilter?.startDate ? moment(paramsFilter?.startDate,'DD/MM/YYYY').toDate() : moment().startOf('month').toDate(),
                endDate: paramsFilter?.endDate ? moment(paramsFilter?.endDate,'DD/MM/YYYY').toDate() : '',
                clockinStatus: paramsFilter?.clockinStatus ?? '', 
                clockoutStatus: paramsFilter?.clockoutStatus ?? '', 
                delimanCondition: parsingCondition(paramsFilter?.delimanCondition),
                mobileDeviceCondition: parsingCondition(paramsFilter?.mobileDeviceCondition) ,
                motorcycleCondition: parsingCondition(paramsFilter?.motorcycleCondition),
            })
        }
    },[paramsFilter])

    const handleFilter = () => {
        
        actionFilter(prev=>{
            return {
                ...prev,
                ...paramFilter,
                page:0,
                startDate: paramFilter?.startDate ? moment(paramFilter?.startDate).format('DD/MM/YYYY') : '',
                endDate: paramFilter?.endDate ? moment(paramFilter?.endDate).format('DD/MM/YYYY') : '', 
                delimanCondition: reverseParsingCondition(paramFilter?.delimanCondition),
                mobileDeviceCondition: reverseParsingCondition(paramFilter?.mobileDeviceCondition) ,
                motorcycleCondition: reverseParsingCondition(paramFilter?.motorcycleCondition),
                
            }
        })
        onClose()
    }

    const handleResetFilter = () => {
        setParamFilter(initParamFilter)
    }
    const CardSegment = ({ children, ...props }) => {
        return <Segment
            {...props}
            boxShadow={"0 1px 4px 1px #70727d33"}
            borderRadius={8}
            bg={"white"}
            p={16}
            mt={10}
            borderBottom={"1px solid #DCDEE3"}
        >
            {children}
        </Segment>
    }


    return (<>
        <ModalLite
            backdrop
            isOpen={isOpen}
            toggle={onClose}
            style={{ 
                width: 'min(100%, 606px)',
                margin: '10px',
                padding: '32px 24px'
            }}
        >
            <ModalHeader>
                <Segment justifyContent="space-between">
                    <ModalTitle style={{ fontSize: 24 }}>Filter</ModalTitle>
                    <ButtonLink onClick={handleResetFilter}>Reset Filter</ButtonLink>
                </Segment>
            </ModalHeader>
            <ModalBody
                className="scrollbar-visible"
                style={{
                    overflowY: "scroll",
                    height: "min(72vh, 695px)",
                    paddingLeft: "2px",
                    paddingRight: "8px",
                }}
            >
                <Segment mb={32}>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Pilih Tanggal Awal & Akhir</Text>
                        </Segment>
                        <Segment alignItems={"center"} mt={8}>
                            <Segment flex={1} className={"fw500"}>
                                <FormControl>
                                    <DatePicker
                                        placeholderText={"Tanggal Awal"}
                                        selected={paramFilter.startDate}
                                        onChange={date => setParamFilter(prev=>({...prev, startDate: date}))}
                                        selectsStart
                                        startDate={paramFilter.startDate}
                                        endDate={paramFilter.endDate}
                                        maxDate={paramFilter.endDate}
                                        right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                    />
                                </FormControl>
                            </Segment>
                            {/* separator */}
                            <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                            {/* separator */}
                            <Segment flex={1} className={"fw500"}>
                                <FormControl>
                                    <DatePicker
                                        placeholderText={"Tanggal Akhir"}
                                        selected={paramFilter.endDate}
                                        onChange={date => setParamFilter(prev=>({...prev, endDate: date}))}
                                        selectsEnd
                                        startDate={paramFilter.startDate}
                                        endDate={paramFilter.endDate}
                                        minDate={paramFilter.startDate}
                                        maxDate={new Date()}
                                        right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                    />
                                </FormControl>
                            </Segment>
                        </Segment>
                    </CardSegment>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Lokasi Clock In</Text>
                        </Segment>
                        <Segment flex={1}>
                            <Segment display="inline-block" className="radio-wrapper">
                                <RadioButton
                                    radioProps={{
                                        onChange: e => setParamFilter(prev=>({...prev, clockinStatus: e.target.value })),
                                        className: 'validate[required]',
                                        name: "clockinStatus",
                                        id: "clockinStatus",
                                    }}
                                    selected={paramFilter.clockinStatus}
                                    radioItems={[
                                        { label: "Semua", value: "" },
                                        { label: "Sesuai", value: "sesuai" },
                                        { label: "Tidak Sesuai", value: "tidak-sesuai" },
                                        { label: "Kosong", value: "kosong" }
                                    ]}
                                />
                            </Segment>
                        </Segment>
                    </CardSegment>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Lokasi Clock Out</Text>
                        </Segment>
                        <Segment flex={1}>
                            <Segment display="inline-block" className="radio-wrapper">
                                <RadioButton
                                    radioProps={{
                                        onChange: e => setParamFilter(prev=>({...prev, clockoutStatus: e.target.value })),
                                        className: 'validate[required]',
                                        name: "clockoutStatus",
                                        id: "clockoutStatus",
                                    }}
                                    selected={paramFilter.clockoutStatus}
                                    radioItems={[
                                        { label: "Semua", value: "" },
                                        { label: "Sesuai", value: "sesuai" },
                                        { label: "Tidak Sesuai", value: "tidak-sesuai" },
                                        { label: "Kosong", value: "kosong" }
                                    ]}
                                />
                            </Segment>
                        </Segment>
                    </CardSegment>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Kondisi HP</Text>
                        </Segment>
                        <Segment flex={1}>
                            <Segment display="inline-block" className="radio-wrapper">
                                <RadioButton
                                    radioProps={{
                                        onChange: e => setParamFilter(prev=>({...prev, mobileDeviceCondition: e.target.value })),
                                        className: 'validate[required]',
                                        name: "mobileDeviceCondition",
                                        id: "mobileDeviceCondition",
                                    }}
                                    selected={paramFilter.mobileDeviceCondition}
                                    radioItems={[
                                        { label: "Semua", value: "" },
                                        { label: "Ok", value: "ok" },
                                        { label: "Not Ok", value: "nok" },
                                    ]}
                                />
                            </Segment>
                        </Segment>
                    </CardSegment>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Kondisi Motor</Text>
                        </Segment>
                        <Segment flex={1}>
                            <Segment display="inline-block" className="radio-wrapper">
                                <RadioButton
                                    radioProps={{
                                        onChange: e => setParamFilter(prev=>({...prev, motorcycleCondition: e.target.value })),
                                        className: 'validate[required]',
                                        name: "motorcycleCondition",
                                        id: "motorcycleCondition",
                                    }}
                                    selected={paramFilter.motorcycleCondition}
                                    radioItems={[
                                        { label: "Semua", value: "" },
                                        { label: "Ok", value: "ok" },
                                        { label: "Not Ok", value: "nok" },
                                    ]}
                                />
                            </Segment>
                        </Segment>
                    </CardSegment>
                    <CardSegment>
                        <Segment mb={8}>
                            <Text color="#70727D">Kondisi Delivery Man</Text>
                        </Segment>
                        <Segment flex={1}>
                            <Segment display="inline-block" className="radio-wrapper">
                                <RadioButton
                                    radioProps={{
                                        onChange: e => setParamFilter(prev=>({...prev, delimanCondition: e.target.value })),
                                        className: 'validate[required]',
                                        name: "delimanCondition",
                                        id: "delimanCondition",
                                    }}
                                    selected={paramFilter.delimanCondition}
                                    radioItems={[
                                        { label: "Semua", value: "" },
                                        { label: "Ok", value: "ok" },
                                        { label: "Not Ok", value: "nok" },
                                    ]}
                                />
                            </Segment>
                        </Segment>
                    </CardSegment>
                </Segment>

            </ModalBody>
            <ModalFooter style={{ justifyContent: "flex-end" }}>
                <ButtonGroup>
                    <Button
                        variant={"secondary"}
                        onClick={onClose}
                        style={{ borderRadius: 16 }}
                    >
                        Batal
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ borderRadius: 16 }}
                    >
                        Gunakan
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </ModalLite>
    </>)
}

export default FilterModal