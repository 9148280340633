import { Services } from "service"
import store from "store"

const TOKEN = process.env.REACT_APP_USER_TOKEN
const REFRESHTOKEN = process.env.REACT_APP_USER_REFRESH_TOKEN
const LAST_ACTIVITY = 'LAST_ACTIVITY'
const IS_LOGIN = 'IS_LOGIN'

const getCurrentTime = () => Math.round(Date.now() / 1000)
export const isTimeUp = () => {
    
  const lastActivity = localStorage.getItem(LAST_ACTIVITY)
  const shouldLogout = (getCurrentTime() - parseInt(lastActivity)) > parseInt(process.env.REACT_APP_IDLE_TIME)
  if (lastActivity && shouldLogout) return logout()

  localStorage.setItem(LAST_ACTIVITY, getCurrentTime())
}

export const setToken = (tokenId, refreshTokenId,isLogin) => {
    localStorage.setItem(TOKEN, tokenId)
    localStorage.setItem(REFRESHTOKEN, refreshTokenId)
    if(isLogin) localStorage.setItem(IS_LOGIN,isLogin)
    //window.location.reload()
}

export const removeToken = () => {
    window.localStorage.clear()
    window.location.reload()
}

export const getToken = () => {
    return localStorage.getItem(TOKEN)
}

export const isLoggedIn = () => !!localStorage.getItem(IS_LOGIN)

export const logout = () => {
    localStorage.clear()
    window.location.reload()
}
export const refreshToken = (token) => {
    return async () => {
        
        try {
            const raws = await Services().post('/user/api/user/refresh', {
                "refresh_token": token
            })
            store.dispatch({
                type: 'AUTH_SUCCESS',
                payload: {
                    token: raws.data.data.access_token,
                    refresh: raws.data.data.refresh_token
                }
            })

        } catch (e) {
            logout()
        }
    }
    // return () => {
    //     const token = localStorage.getItem(REFRESHTOKEN);
    //     Services().post('/user/api/user/refresh', {
    //         "refresh_token": token
    //     }).then(result => {
    //         store.dispatch({
    //             type: 'AUTH_SUCCESS',
    //             payload: {
    //                 token: result.data.data.access_token,
    //                 refresh: result.data.data.refresh_token
    //             }
    //         })
    //     }).catch(e => {
    //         logout()
    //     })
    // }
}