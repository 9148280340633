import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'


export const RequestPhotoReport = (action$,state$) =>
    action$.pipe(
        ofType( 'REQUEST_PHOTO_REPORT',
                
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_PHOTO_REPORT':
                        const rawResponsePhotoReport = await Services().get(`/order/api/back-office/orders/orders-images-history`,action.payload.params)
                        let params = action.payload.params
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_PHOTO_REPORT',
                                payload: {
                                    data: rawResponsePhotoReport.data.data.content,
                                    parameter: params,
                                    totalPages: rawResponsePhotoReport.data.data.totalPages,
                                    
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "RELEASE_LOCKING_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
