import React from 'react'
import {
    Segment,
    Text
} from "@elevenia/master-ui/components/Atom"

const StatusColorSLA = (children) => {
    if (children.duration <= 3600) {
        return <Segment alignItems={'center'}>
            
            <Text fontWeight="400" color="black70" ml={4} style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>{children.text}</Text>
        </Segment>
    }
    else if (children.duration > 3600) {
        return <Segment alignItems={'center'}>
            
            <Text fontWeight="500" color="red20" ml={4} style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>{children.text}</Text>
        </Segment>
    } else {
        return children.text
    }

}

export default StatusColorSLA