import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestDeliverymanCadangan = action$ =>
    action$.pipe(
        ofType( 'REQUEST_DELIVERY_MAN_CADANGAN',
                'REQUEST_DELIVERY_MAN_CADANGAN_BY_ID',
                'REQUEST_CREATE_DELIVERY_MAN_CADANGAN',
                'REQUEST_UPDATE_DELIVERY_MAN_CADANGAN',
                'REQUEST_VALIDATE_DELIVERY_MAN_CADANGAN'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_DELIVERY_MAN_CADANGAN':
                        const rawResponse = await Services().get('/driver/api/back-office/assignment/list',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DELIVERY_MAN_CADANGAN',
                                payload: {
                                    data: rawResponse.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.totalPages
                                    
                                }
                            })
                        }
                    case 'REQUEST_DELIVERY_MAN_CADANGAN_BY_ID':
                        const rawResponseDetail = await Services().get(`/driver/api/back-office/assignment/${action.payload}`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DELIVERY_MAN_CADANGAN_BY_ID',
                                payload: {
                                    data: rawResponseDetail.data.data,
                
                                }
                            })
                        }

                    case 'REQUEST_CREATE_DELIVERY_MAN_CADANGAN':
                        
                        const responseAdd =  await Services().post(`/driver/api/back-office/assignment/add`,action.payload.dataSubmit)
                        
                        if(responseAdd.data.status==='02'){
                            const newListData = action.payload.params.map(val=>{
                                const referenceId = val.referenceId
                                const find = responseAdd.data.data.content.find(val2=>val2.referenceId===referenceId)
                                
                                if(find){
                                    return {
                                        ...val,
                                        status:0,
                                        message:find.errorMessage
                                    }
                                }else{
                                    return {
                                        ...val,
                                        status:1,
                                        message:''
                                    }
                                }
                            }).sort((a,b)=>a.status-b.status)
                            return dispatch=>{
                                dispatch({
                                    type: 'GET_DATA_CREATE_ERROR', 
                                    payload:{
                                        data:newListData
                                    }
                                    
                                })
                                dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: responseAdd.data.message } })
                                
                            }
                        }else{
                            return dispatch => {
                                action.history.push('/deliveryman-cadangan')
                                dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Penugasan Berhasil dibuat' } })
                            }
                        }
                    
                    case 'REQUEST_VALIDATE_DELIVERY_MAN_CADANGAN':
                        const responseValidate =  await Services().post(`/driver/api/back-office/assignment/validate`,action.payload.dataSubmit)
                        
                        //if(responseValidate.data.data.content.length > 0){
                        const newListData = action.payload.params.map(val=>{
                            const referenceId = val.referenceId
                            const find = responseValidate.data.data.content.find(val2=>val2.referenceId===referenceId)
                            
                            if(find){
                                return {
                                    ...val,
                                    status:0,
                                    message:find.errorMessage
                                }
                            }else{
                                return {
                                    ...val,
                                    status:1,
                                    message:''
                                }
                            }
                        }).sort((a,b)=>a.status-b.status)
                        return dispatch=>{
                            dispatch({
                                type: 'GET_DATA_CREATE_ERROR', 
                                payload:{
                                    data:newListData
                                }
                                
                            })
                            //dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: responseValidate.data.message } })
                            
                        }
                        //}
                    case 'REQUEST_UPDATE_DELIVERY_MAN_CADANGAN':
                        await Services().put(`/driver/api/back-office/assignment/${action.payload.id}`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/deliveryman-cadangan/${action.payload.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Penugasan berhasil' } })
                        }
                    
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_DELIVERY_MAN_CADANGAN_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
