import React, { useState, useEffect } from 'react';
import {
    Segment,
    Text,
    Button,
    FormControl,
    Row,
    ButtonGroup,
    OptionBox,
    Icon,
    CheckBox,
    Spinner,
} from '@elevenia/master-ui/components/Atom';
import ModalConfirm from 'component/ModalCustom/modalSmall';
import Breadcrumb from 'component/Breadcrumb';
import { Services } from 'service';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useInput, useAction } from 'hooks';
import { useSelector } from 'react-redux';
import Error404 from 'page/errorLayout';
import {
    requestSubmitReleaseAccess,
    requestListDuration,
    requestOrderListByIdDelimanAndStatus,
    resetOrderList,
} from 'store/actions/release-access';
import { orderStatus, validateInput } from 'helper';
// import FormListOrder from './_component/FormListOrder'

const CreateReleaseForm = (props) => {
    if (!props.access) return <></>;
    else if (!props.access?.create) return <Error404 />;
    return <>{<Form {...props} />}</>;
};

const Form = (props) => {
    document.title = props.title;
    const history = props.history;
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [disabledButton, setDisableButton] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const dataBreadcrumb = [
        { to: '/release-location', label: 'Release Location Access' },
        { to: '', label: 'Buat Baru' },
    ];

    const { hasFetch } = useAction();

    const payload = useSelector((state) => {
        return {
            orderListById: state.releaseAccess.orderListById?.content,
            loadingGetOrder: state.releaseAccess.loadingGetOrder,
            listDuration: state.releaseAccess.listDuration,
            loadingDuration: state.releaseAccess.loadingDuration,
            loading: state.releaseAccess.loading,
        };
    });

    let { value, setValue, bindSelect } = useInput({
        initialObjects: {
            deliman: '',
            duration: '',
            orderList: null,
        },
        identity: 'myForm',
    });

    // Fetch list duration
    useEffect(() => {
        props.access?.read && hasFetch(requestListDuration());
    }, [hasFetch, props.access]);

    // Set list duration
    useEffect(() => {
        payload.listDuration &&
            setValue((prev) => {
                return {
                    ...prev,
                    duration:
                        payload.listDuration.find((val) => val.id === 4)?.id ||
                        '',
                };
            });
    }, [payload.listDuration, setValue]);

    // Submit Button disable behavior
    useEffect(() => {
        const disabled = Object.values(value).filter((val) => {
            if (Array.isArray(val)) {
                return val.length === 0;
            } else {
                return val === '' || val === null;
            }
        });

        if (disabled.length || selectedOrder.length === 0) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        };

    }, [value, selectedOrder.length]);

    // Set value order list
    useEffect(() => {
        setValue((prev) => {
            return {
                ...prev,
                orderList: payload.orderListById ?? null,
            };
        });
    }, [payload.orderListById, setValue]);

    useEffect(() => {
        hasFetch(resetOrderList());
    }, [hasFetch]);

    // remove selected order on uncheck status 
    useEffect(() => {
        const orderList = value.orderList?.map((item) => item.orderId);
        
        setSelectedOrder((prev) => prev.filter((order) => orderList?.includes(order)))
    }, [value, setSelectedOrder]);

    const handleLoadOptions = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const response = await Services().get(
                `/driver/api/back-office/search/${inputValue}`
            );
            const listData = response.data.data.content;
            callback(
                listData?.map((val) => ({
                    label: `${val.nik} - ${val.name}`,
                    value: val.id,
                    nik: val.nik,
                    name: val.name,
                }))
            );
        }

        if (inputValue.length === 0) {
            const response = await Services().get(
                `/driver/api/back-office/search/${inputValue}`
            );
            const listData = response.data.data.content;

            callback(
                listData?.map((val) => ({
                    label: `${val.nik} - ${val.name}`,
                    value: val.id,
                    nik: val.nik,
                    name: val.name,
                }))
            );
        }
    };

    const handleChangeDeliman = (data) => {
        if (data) {
            setValue((prev) => {
                return {
                    ...prev,
                    deliman: data,
                };
            });
        } else {
            setValue((prev) => {
                return {
                    ...prev,
                    deliman: '',
                    orderList: null,
                };
            });
        }

        setSelectedStatus([]);
        setValue((prev) => {
            return {
                ...prev,
                orderList: null,
            };
        });

        setTimeout(() => {
            validateInput('myForm', 'deliman');
        }, 0);
    };

    const selecItems = (items, value) => {
        let arr = items;
        const idx = items.indexOf(value);
        if (idx >= 0) {
            items.splice(idx, 1);
        } else if (idx === -1) {
            items.splice(idx, 0, value);
        }
        return arr;
    };

    const handleSelectStatus = (event) => {
        const checked = event.target.value;
        const result = selecItems(selectedStatus, checked);

        if (value.deliman) {
            const queryParams = `deliverymanId=${value.deliman.value
                }&orderStatus=${result.join('&orderStatus=')}`;

            hasFetch(requestOrderListByIdDelimanAndStatus(queryParams));
        }

        setSelectedStatus([...result]);
    };

    const handleSelectOrder = (event) => {
        const checked = event.target.value;
        const result = selecItems(selectedOrder, checked);

        setSelectedOrder([...result]);
    };

    const handleSubmit = () => {
        const dataSubmit = {
            deliverymanId: value.deliman.value,
            releaseConfigId: value.duration,
            orderStatus: selectedStatus,
            orderIds: selectedOrder
        };
        hasFetch(requestSubmitReleaseAccess(dataSubmit, history));
    };

    const DropdownIndicator = (props) => {
        const menuIsOpen = props.selectProps.menuIsOpen;

        return (
            <components.DropdownIndicator {...props}>
                <Icon
                    name={menuIsOpen ? 'chevron-up' : 'chevron-down'}
                    size={'16px'}
                    fillColor={'black50'}
                />
            </components.DropdownIndicator>
        );
    };

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={'1rem'}>
                    Buat Baru
                </Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>

            <Segment>
                <form
                    id='myForm'
                    autoComplete='false'
                    onSubmit={(e) => e.preventDefault()}
                >
                    <Segment boxShadow bg={'white'} borderRadius={4}>
                        <Segment p={24}>
                            <Text fontWeight={600} mb={32}>
                                Informasi Release
                            </Text>
                            <Row mb={20}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Delivery Man</Text>
                                </Segment>
                                <Segment flex={1}>
                                    <FormControl
                                        helptext='Release location access digunakan untuk tahap jemput, kirim, dan pembatalan pesanan'
                                        className={'custom-helptext'}
                                    >
                                        <AsyncSelect
                                            onChange={handleChangeDeliman}
                                            isClearable={true}
                                            defaultInputValue={
                                                value.deliman
                                                    ? value.deliman
                                                    : ''
                                            }
                                            loadOptions={handleLoadOptions}
                                            name='deliman'
                                            styles={{
                                                placeholder: (
                                                    defaultStyles
                                                ) => {
                                                    return {
                                                        ...defaultStyles,
                                                    };
                                                },
                                                control: (defaultStyles) => ({
                                                    ...defaultStyles,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator,
                                            }}
                                            inputClassName={
                                                'validate[required] validateMessage{required{Deliman Harus dipilih}}'
                                            }
                                            placeholder={'Pilih Delivery Man'}
                                        />
                                    </FormControl>
                                    <input
                                        type='hidden'
                                        name='postalCodeDescription'
                                        className='validate[required] validateMessage{required{Deliman Harus dipilih}}'
                                        value={value.deliman ?? ''}
                                    />
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Durasi</Text>
                                </Segment>
                                <Segment flex={1}>
                                    {!payload.loadingDuration && (
                                        <FormControl>
                                            <OptionBox
                                                {...bindSelect}
                                                placeholder={
                                                    'Pilih Durasi Release Location Access'
                                                }
                                                name='duration'
                                                className='validate[required]'
                                                isClearable
                                                isDisabled={
                                                    payload.loadingDuration
                                                }
                                                options={payload.listDuration?.map(
                                                    (val) => ({
                                                        ...val,
                                                        value: val.id,
                                                        label: `${val.value} Jam`,
                                                    })
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Status</Text>
                                </Segment>
                                <Segment flex={1} display='flex'>
                                    <Segment>
                                        <CheckBox
                                            checkProps={{
                                                onChange: (e) =>
                                                    handleSelectStatus(e),
                                                name: 'status',
                                                id: 'statusPickUp',
                                                //disabled:value.deliman ? false : true
                                            }}
                                            checkItems={[
                                                {
                                                    label: 'Pick-up',
                                                    value: 'PICKING_UP',
                                                },
                                            ]}
                                            selected={selectedStatus}
                                        />
                                    </Segment>
                                    <Segment ml={48}>
                                        <CheckBox
                                            checkProps={{
                                                onChange: (e) =>
                                                    handleSelectStatus(e),
                                                name: 'status',
                                                id: 'statusPengiriman',
                                                //disabled:value.deliman ? false : true
                                            }}
                                            checkItems={[
                                                {
                                                    label: 'Pengiriman',
                                                    value: 'IN_DELIVERY',
                                                },
                                            ]}
                                            selected={selectedStatus}
                                        />
                                    </Segment>
                                    <Segment ml={48}>
                                        <CheckBox
                                            checkProps={{
                                                onChange: (e) =>
                                                    handleSelectStatus(e),
                                                name: 'status',
                                                id: 'statusBatal',
                                                //disabled:value.deliman ? false : true
                                            }}
                                            checkItems={[
                                                {
                                                    label: 'Batal',
                                                    value: 'CANCELING',
                                                },
                                            ]}
                                            selected={selectedStatus}
                                        />
                                    </Segment>
                                </Segment>
                            </Row>
                            <Row mb={16}>
                                <Segment width={280} py={8}>
                                    <Text color='#70727D'>Order ID - SO - AWB</Text>
                                </Segment>
                                <Text mr={8}>:</Text>
                                <Segment flex={1}>
                                    {/* {
                                        value.deliman ? <FormListOrder
                                            deliman={value.deliman}
                                            setValue={setValue}
                                        /> : '-'
                                    } */}

                                    {payload.loadingGetOrder && (
                                        <Segment
                                            className='u-tx-center'
                                            width={30}
                                            height={30}
                                        >
                                            <Spinner />
                                        </Segment>
                                    )}
                                    {value.orderList && value.orderList?.length
                                        ? value.orderList.map((val, index) => (
                                            <CheckBox
                                                key={index}
                                                checkProps={{
                                                    onChange: (e) =>
                                                        handleSelectOrder(e),
                                                    name: 'order',
                                                    id: val.orderId
                                                }}
                                                checkItems={[
                                                    {
                                                        label: `${val.orderId} - ${val.salesOrderId} - ${val.awbId} - ${orderStatus(val.orderStatus)}`,
                                                        value: val.orderId
                                                    }
                                                ]}
                                                selected={selectedOrder}
                                            />
                                        ))
                                        : '-'}
                                </Segment>
                            </Row>
                        </Segment>
                    </Segment>
                    <Segment mt={32} justifyContent={'flex-end'}>
                        <Segment>
                            <Button
                                variant='primary'
                                size='medium'
                                type={'button'}
                                disabled={disabledButton}
                                onClick={() => setIsModalCreate(!isModalCreate)}
                            >
                                Buat
                            </Button>
                        </Segment>
                    </Segment>
                </form>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModalCreate}
                onClose={() => setIsModalCreate(!isModalCreate)}
                title={'Release Location Access?'}
                content={
                    <Segment py={8}>
                        Delivery man ini akan diberikan{' '}
                        <strong>Release Location Access</strong> sehingga dapat
                        menjemput, mengirim, dan membatalkan pesanan di luar
                        lokasi.
                    </Segment>
                }
                ButtonFooter={
                    <ButtonGroup>
                        <Button
                            minWidth={'100px'}
                            variant={'secondary'}
                            onClick={() => setIsModalCreate(!isModalCreate)}
                        >
                            Batal
                        </Button>
                        <Button
                            disabled={payload.loading}
                            onClick={() => {
                                !payload.loading && handleSubmit();
                            }}
                            minWidth={'auto'}
                        >
                            Release Location Access
                        </Button>
                    </ButtonGroup>
                }
            />
        </>
    );
};

export default CreateReleaseForm;
