const initialState = {
    data: '',
    totalPages: 0,
    parameter: null,
    loading: false,
    error: null,
    detailData: null,
    orderListById: null,
    loadingGetOrder: false,
    listDuration: null,
    loadingDuration: false,
};

export const releaseLocking = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_RELEASE_LOCKING':
            return {
                ...state,
                loading: true,
                orderListById: null,
            };
        case 'REQUEST_RELEASE_LOCKING_INTERVAL':
            return {
                ...state,
                loading: false,
            };
        case 'GET_RELEASE_LOCKING':
            return {
                ...state,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case 'REQUEST_RELEASE_LOCKING_HISTORY':
            return {
                ...state,
                loading: true,
                orderListById: null,
            };
        case 'GET_RELEASE_LOCKING_HISTORY':
            return {
                ...state,
                data: action.payload.data,
                parameter: action.payload.parameter,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case 'REQUEST_ORDER_LIST_BY_ID_DELIMAN':
            return {
                ...state,
                loadingGetOrder: true,
            };
        case 'GET_ORDER_LIST_BY_ID_DELIMAN':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: action.payload.orderList,
            };
        case 'RELEASE_LOCKING_SUCCESS':
            return {
                ...state,
                loadingGetOrder: false,
                orderListById: null,
                loading: false,
                error: null
            };

        case 'REQUEST_DURATION_RELEASE_LOCKING':
            return {
                ...state,
                loadingDuration: true,
            };
        case 'GET_DURATION_RELEASE_LOCKING':
            return {
                ...state,
                loadingDuration: false,
                listDuration: action.payload.data,
            };
        case 'REQUEST_SUBMIT_RELEASE_LOCKING':
            return {
                ...state,
                loading: true,
            };
        case 'REQUEST_RELEASE_LOCKING_APPROVAL':
            return {
                ...state,
                loading: true,
            };
        case 'REQUEST_RELEASE_LOCKING_APPROVAL_INTERVAL':
            return {
                ...state,
                loading: true,
            };
        case 'REQUEST_RELEASE_LOCKING_APPROVAL_HISTORY':
            return {
                ...state,
                loading: true,
            };
        case 'REQUEST_SUBMIT_RELEASE_LOCKING_APPROVAL':
            return {
                ...state,
                loading: true,
            };
        case 'REQUEST_APPROVAL_RELEASE_LOCKING':
            return {
                ...state,
                loading: true,
            };
        case 'RELEASE_LOCKING_FAILED':
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        case 'RELEASE_LOCKING_DISMISS_ERROR':
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
