import React, { useEffect, useState } from "react"
import {
    Segment
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import { requestListDataRead } from "store/actions/configRKBSpv";
import moment from "moment";
const TabDataTerbaca = (props) => {
    const {hasFetch} = useAction()
    const payload = useSelector(state => {
        return {
            dataUpload: state.configRKBSpv.dataUpload,
            loadingRead: state.configRKBSpv.loadingRead,
            listDataRead: state.configRKBSpv.listDataRead,
            totalPagesDataRead: state.configRKBSpv.totalPagesDataRead,
            parameterDataRead: state.configRKBSpv.parameterDataRead,
            isUploadError: state.configRKBSpv.isUploadError
        }
    })
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
    });

    useEffect(()=>{
        !payload.isUploadError && hasFetch(requestListDataRead(payload?.dataUpload?.id,isTable))
    },[hasFetch,isTable,payload.dataUpload.id, payload.isUploadError])

    

    return (
        <>
            <Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loadingRead}
                    defaultSize={isTable.size}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            isCustomRow: (id, entity) => {
                                return entity.date ? moment(entity.date,'DD-MM-YYYY').format('DD/MM/YY') : '-'
                            }
                        },
                        {
                            field: "Kode Toko",
                            isCustomRow: (id, entity) => {
                                return (entity.storeCode && entity.storeName) ? `${entity.storeCode} - ${entity.storeName}` : '-'
                            }
                        },
                        {
                            field: "Supervisor Delivery",
                            isCustomRow: (id, entity) => {
                                return (entity.supervisorNik && entity.supervisorName) ? `${entity.supervisorNik} - ${entity.supervisorName}` : '-'
                            }
                        },
                    ]}
                    dataConsume={payload?.listDataRead}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={payload?.totalPagesDataRead}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...payload?.parameterDataRead,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {
                        return {
                            ...prev,
                            ...payload?.parameterDataRead,
                            page: page.page
                        }
                    })}
                />
            </Segment>
        </>
    );
};

export default TabDataTerbaca;
