import React from 'react'
import {
    Segment,
    Text,
} from '@elevenia/master-ui/components/Atom'
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'

const AbsensiDetail = props => {
    document.title = props.title

    const title = 'Detail Aktivitas'
    const dataBreadcrumb = [
        { to: '/absensi', label: 'Absensi' },
        { to: '', label: title }
    ]

    const data = [
        {
            delivMan: '1 Delivery Man',
            aktivitas: 'logout',
            waktu: '-'
        },
        {
            delivMan: '1 Delivery Man',
            aktivitas: 'login',
            waktu: '-'
        }
    ]

    const StatusColor = (status) => {
        switch (status) {
            case "login":
                return <Text fontWeight="500" color="green20" style={{ textTransform: "capitalize" }}>{status}</Text>
            case "logout":
                return <Text fontWeight="500" color="red20" style={{ textTransform: "capitalize" }}>{status}</Text>
            default:
                break;
        }
    }

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24} mb={40}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi</Text>
                        </Segment>
                        <Segment mb={24} display="flex">
                            <Segment minWidth={280}>
                                <Text>Kode Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                Absensi 1
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex">
                            <Segment minWidth={280}>
                                <Text>Klaster</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                Kuningan
                            </Segment>
                        </Segment>
                        <Segment display="flex">
                            <Segment minWidth={280}>
                                <Text>Tanggal</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                17/03/2021
                            </Segment>
                        </Segment>
                    </Segment>
                </Segment>

                <DataTable
                    countingList={true}
                    defaultSize={10}
                    tableConsume={[{
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.delivMan}</>
                            )
                        }
                    }, {
                        field: 'Aktivitas',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{StatusColor(entity.aktivitas)}</>
                            )
                        }
                    }, {
                        field: 'Waktu',
                        isCustomRow: (id, entity) => {
                            return (
                                <>{entity.waktu}</>
                            )
                        },
                        width: '50%'
                    }
                    ]}
                    dataConsume={data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={5}
                    createPaginations={(page) => { }}
                />
            </Segment>
        </>
    )
}

export default AbsensiDetail