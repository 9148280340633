import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestChangePassword = action$ =>
    action$.pipe(
        ofType('REQUEST_CHANGE_PASSWORD'),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_CHANGE_PASSWORD':
                        await Services().post('/user/api/user/change-password', action.payload.data)
                        return dispatch => {
                            dispatch({
                                type: 'CHANGE_PASSWORD_SUCCESS',
                                payload: {
                                    status: true
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_CHANGE_PASSWORD_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
