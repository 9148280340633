import {
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom'
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import React, { useState } from 'react'
import TabDataTerbaca from './TabDataTerbaca'
import TabDataTidakTerbaca from './TabDataTidakTerbaca'
const ResultImportedData = () => {
    const [activeTab, setActiveTab] = useState("Terbaca");
    
    return (
        <>
            <Segment borderTop={"1px solid #DCDEE3"} pt={24} mt={20}>
                <Segment className="container-tab-upload-jadwal">
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="Terbaca">Data Terbaca</Text>
                        <Text key="TidakTerbaca">Data Tidak Terbaca</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "Terbaca" && <TabDataTerbaca />}
                        {activeTab === "TidakTerbaca" && <TabDataTidakTerbaca />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default ResultImportedData