import React, { useEffect, lazy, useState } from 'react'
import {
    Segment,
    Text,
    Badge,
    Button,
    Spinner
} from '@elevenia/master-ui/components/Atom'
import { Link, useParams } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import { useAction } from 'hooks';
import { requestOrderListById, requestFileUpload, resetFileUpload } from 'store/actions/orderList'
import { useSelector } from 'react-redux';
import { getDuration } from './_component/helper';
import moment from 'moment'
import Error404 from 'page/errorLayout'
import { getLabelTimeZone } from 'helper';
import CountDown from './_component/CountDown'
import 'moment/locale/id';
import { orderStatus } from 'helper/Generics';
import CardDetailPesanan from 'component/CardDetailPesanan';
import PindahToko from './_component/PindahToko'
import GeneralInfo from 'component/CardDetailPesanan/GeneralInfo';
import PaymentInfo from 'component/CardDetailPesanan/PaymentInfo';
import ScheduleInfo from 'component/CardDetailPesanan/ScheduleInfo';
import SourceOrder from 'component/CardDetailPesanan/SourceOrder';
import ReturnInfo from 'component/CardDetailPesanan/ReturnInfo';
import ScheduleOrderInfo from 'component/CardDetailPesanan/ScheduleOrderInfo';
import OrderDoneInfo from 'component/CardDetailPesanan/OrderDoneInfo';
const ModalInfo = lazy(() => import('component/ModalCustom/modalMedium'))

const PesananDetail = props => {
    document.title = props.title

    const { status } = useParams()
    const getPath = props.match.params.id;
    const { hasFetch } = useAction();
    const title = getPath ? 'Detail Pesanan' : 'Buat Baru'
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60)
    useEffect(() => {

        getPath && props.access && hasFetch(requestOrderListById(getPath))
    }, [hasFetch, getPath, props.access])
    const dataBreadcrumb = [
        { to: '/pesanan', label: 'Pesanan' },
        { to: '', label: title }
    ]
    const [textOrderStatus, setOrderStatus] = useState('')
    const payload = useSelector(state => {
        return {

            detailData: state.orderList.detailData,
            loading: state.orderList.loading,
            loadingGetImage: state.orderList.loadingGetImage,
            dataImage: state.orderList.dataImage,
            openModal: state.orderList.openModal,
            timeStamp: state.orderList.timeStamp,
            orderConfig: state.homesidemenu.orderConfig,
            propertiesImage: state.orderList.propertiesImage,
            allowedStatus: state.homesidemenu?.action_group.find(val => val.code === "perpindahan-pesanan") ? state.homesidemenu?.action_group.find(val => val.code === "perpindahan-pesanan")['actions'].filter(val => val.allowed).map(val => val.value) : []
        }
    })

    useEffect(() => {
        if (payload.detailData) {
            let statusOrder = payload.detailData.orderStatus
            if (status === 'selesai') {

                if (["IN_RETURN", "RETURN_COMPLETED"].includes(payload?.detailData?.orderStatus)) {
                    statusOrder = 'COMPLETED'
                }
            }
            setOrderStatus(statusOrder)
        }
    }, [payload.detailData, status])





    const handleGetImage = (url) => {
        hasFetch(requestFileUpload(url))
    }

    const handleCloseModal = () => {
        hasFetch(resetFileUpload())
    }

    let duration = 0;
    let colorDuration = 0;
    const completedTime = moment(payload?.detailData?.completedTime).unix()
    const maxDelivTime = moment(payload?.detailData?.maxDeliveryTime).unix()
    if (textOrderStatus === 'COMPLETED') {
        duration = getDuration(payload.detailData.minDeliveryTime, payload.detailData.completedTime)

        colorDuration = duration
        //if(payload?.detailData?.serviceType==='SAMEDAY'){
        if (completedTime > maxDelivTime) {
            colorDuration = 3700
        } else if (completedTime < maxDelivTime) {
            colorDuration = 3500
        } else if (completedTime === maxDelivTime) {
            colorDuration = 3500
        }

        //}
    }

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return payload.detailData && (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between" alignItems="flex-start" className="xs-flex-wrap">
                            <Text fontWeight={600} mb={32}>Informasi Pesanan</Text>

                            <Segment alignItems={'center'}>
                                {
                                    ["ALLOCATING", "PICKING_UP", "IN_DELIVERY", "READY_TO_DELIVER"].includes(textOrderStatus)
                                        ? <CountDown
                                            orderConfig={payload?.orderConfig}
                                            category={payload?.detailData.serviceType}
                                            page={'detail'}
                                            type={textOrderStatus}
                                            startDuration={payload.timeStamp}
                                            endDuration={textOrderStatus === 'IN_DELIVERY'
                                                ? payload?.detailData?.maxDeliveryTime
                                                : payload?.detailData?.expiredDeliveryTime}
                                            channelOrder={payload?.detailData.channelOrder}
                                            orderType={payload?.detailData.orderType}
                                        /> : ''
                                }

                                <Segment name="divide" width={1} height={24} bg={"#DCDEE3"} mx={12} />
                                <Text fontWeight={500} color={'#1178D4'}></Text>
                                <Badge bg="#FF7D1D" fontSize="12">
                                    {orderStatus(textOrderStatus)}
                                </Badge>
                            </Segment>
                        </Segment>
                        {/* General Info */}
                        <GeneralInfo
                            detailData={payload?.detailData}
                        />
                        {/* End General Info */}
                        <ScheduleInfo
                            data={payload?.detailData}
                            timezoneLabel={timezoneLabel}
                        />
                        <SourceOrder
                            data={payload?.detailData}
                            textOrderStatus={textOrderStatus}
                        />
                        <ReturnInfo
                            data={payload?.detailData}
                            textOrderStatus={textOrderStatus}
                        />
                        {
                            textOrderStatus === 'PICKING_UP'
                            &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Pin Jemput</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>

                                    {payload?.detailData?.pickupPin}
                                </Segment>
                            </Segment>
                        }
                        {
                            textOrderStatus === 'IN_DELIVERY'
                            && <>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Pin Batal</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>

                                        {payload?.detailData?.cancelPin}
                                    </Segment>
                                </Segment>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Pin Titip</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>

                                        {payload?.detailData?.keepPin}
                                    </Segment>
                                </Segment>
                            </>
                        }
                        <ScheduleOrderInfo
                            data={payload?.detailData}
                            textOrderStatus={textOrderStatus}
                            allowedStatus={["IN_DELIVERY", "COMPLETED", "IN_RETURN", "RETURN_COMPLETED", "CANCELED", "FAILED", "CANCELING"]}
                            timezoneLabel={timezoneLabel}
                        />
                        <OrderDoneInfo
                            data={payload?.detailData}
                            textOrderStatus={textOrderStatus}
                            allowedStatus={["COMPLETED", "IN_RETURN", "RETURN_COMPLETED", "CANCELED", "FAILED", "CANCELING"]}
                            timezoneLabel={timezoneLabel}
                            duration={duration}
                            colorDuration={colorDuration}
                        />
                        {
                            textOrderStatus === 'COMPLETED' && completedTime > maxDelivTime &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Catatan Pengiriman</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>

                                    {payload?.detailData?.driverDeliveryNotes ? payload?.detailData?.driverDeliveryNotes : '-'}
                                </Segment>
                            </Segment>
                        }
                        {
                            ["IN_RETURN", "RETURN_COMPLETED"].includes(textOrderStatus)
                            &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Waktu Jemput Retur</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>

                                    {payload?.detailData?.inReturnTime ? `${moment(payload?.detailData?.inReturnTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-'}
                                </Segment>
                            </Segment>
                        }
                        {
                            ["IN_DELIVERY", "COMPLETED", "IN_RETURN", "CANCELED", "FAILED", "CANCELING", "FALLBACK"].includes(textOrderStatus) &&
                            <>
                                <Segment mb={24} display="flex">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Foto Penjemputan</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {
                                            payload?.detailData?.pickupImageUrl
                                                ?
                                                <Link
                                                    to={'#'}
                                                    onClick={() => handleGetImage(payload?.detailData?.pickupImageUrl)}
                                                >
                                                    Lihat Gambar
                                                </Link> : '-'
                                        }

                                    </Segment>
                                </Segment>
                                {
                                    payload?.detailData.orderType === "PAKET-JEMPUT" &&
                                    <Segment mb={24} display="flex">
                                        <Segment minWidth={280}>
                                            <Text color={'#70727D'}>Foto Bukti Pembayaran</Text>
                                        </Segment>
                                        <Segment display="flex">
                                            <Text mr={8}>:</Text>
                                            {
                                                payload?.detailData?.pickupPaymentImageUrl
                                                    ?
                                                    <Link
                                                        to={'#'}
                                                        onClick={() => handleGetImage(payload?.detailData?.pickupPaymentImageUrl)}
                                                    >
                                                        Lihat Gambar
                                                    </Link> : '-'
                                            }

                                        </Segment>
                                    </Segment>
                                }

                                {
                                    payload?.detailData.orderType === "PAKET-JEMPUT" && textOrderStatus !== "IN_DELIVERY" &&
                                    <>
                                        <Segment mb={24} display="flex">
                                            <Segment minWidth={280}>
                                                <Text color={'#70727D'}>Foto Struk Pengiriman</Text>
                                            </Segment>
                                            <Segment display="flex">
                                                <Text mr={8}>:</Text>
                                                {
                                                    payload?.detailData?.deliveryImageUrl
                                                        ?
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => handleGetImage(payload?.detailData?.deliveryImageUrl)}
                                                        >
                                                            Lihat Gambar
                                                        </Link> : '-'
                                                }

                                            </Segment>
                                        </Segment>
                                        <Segment mb={24} display="flex">
                                            <Segment minWidth={280}>
                                                <Text color={'#70727D'}>Foto Struk Setor Tunai</Text>
                                            </Segment>
                                            <Segment display="flex">
                                                <Text mr={8}>:</Text>
                                                {
                                                    payload?.detailData?.deliveryPaymentImageUrl
                                                        ?
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => handleGetImage(payload?.detailData?.deliveryPaymentImageUrl)}
                                                        >
                                                            Lihat Gambar
                                                        </Link> : '-'
                                                }

                                            </Segment>
                                        </Segment>
                                    </>
                                }
                            </>


                        }
                        {textOrderStatus === 'RETURN_COMPLETED'
                            &&
                            <>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Waktu Sampai Toko</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>

                                        {payload?.detailData?.returnCompletedTime ? `${moment(payload?.detailData?.returnCompletedTime).format('DD/MM/YYYY HH:mm')} ${{ timezoneLabel }}` : '-'}
                                    </Segment>
                                </Segment>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Foto Penjemputan</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {
                                            payload?.detailData?.pickupImageUrl
                                                ?
                                                <Link
                                                    to={'#'}
                                                    onClick={() => handleGetImage(payload?.detailData?.pickupImageUrl)}
                                                >
                                                    Lihat Gambar
                                                </Link> : '-'
                                        }

                                    </Segment>
                                </Segment>
                                {
                                    payload?.detailData.orderType === "PAKET-JEMPUT" &&
                                    <>
                                        <Segment mb={24} display="flex">
                                            <Segment minWidth={280}>
                                                <Text color={'#70727D'}>Foto Bukti Pembayaran</Text>
                                            </Segment>
                                            <Segment display="flex">
                                                <Text mr={8}>:</Text>
                                                {
                                                    payload?.detailData?.pickupPaymentImageUrl
                                                        ?
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => handleGetImage(payload?.detailData?.pickupPaymentImageUrl)}
                                                        >
                                                            Lihat Gambar
                                                        </Link> : '-'
                                                }

                                            </Segment>
                                            <Segment mb={24} display="flex">
                                                <Segment minWidth={280}>
                                                    <Text color={'#70727D'}>Foto Struk Pengiriman</Text>
                                                </Segment>
                                                <Segment display="flex">
                                                    <Text mr={8}>:</Text>
                                                    {
                                                        payload?.detailData?.deliveryImageUrl
                                                            ?
                                                            <Link
                                                                to={'#'}
                                                                onClick={() => handleGetImage(payload?.detailData?.deliveryImageUrl)}
                                                            >
                                                                Lihat Gambar
                                                            </Link> : '-'
                                                    }

                                                </Segment>
                                            </Segment>
                                        </Segment>
                                        <Segment mb={24} display="flex">
                                            <Segment minWidth={280}>
                                                <Text color={'#70727D'}>Foto Struk Setor Tunai</Text>
                                            </Segment>
                                            <Segment display="flex">
                                                <Text mr={8}>:</Text>
                                                {
                                                    payload?.detailData?.deliveryPaymentImageUrl
                                                        ?
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => handleGetImage(payload?.detailData?.deliveryPaymentImageUrl)}
                                                        >
                                                            Lihat Gambar
                                                        </Link> : '-'
                                                }

                                            </Segment>
                                        </Segment>
                                    </>
                                }
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Foto Struk Bulky</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {
                                            payload?.detailData?.bulkyImageUrl
                                                ?
                                                <Link
                                                    to={'#'}
                                                    onClick={() => handleGetImage(payload?.detailData?.bulkyImageUrl)}
                                                >
                                                    Lihat Gambar
                                                </Link> : '-'
                                        }

                                    </Segment>
                                </Segment>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Foto Struk COD</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {
                                            payload?.detailData?.codImageUrl
                                                ?
                                                <Link
                                                    to={'#'}
                                                    onClick={() => handleGetImage(payload?.detailData?.codImageUrl)}
                                                >
                                                    Lihat Gambar
                                                </Link> : '-'
                                        }
                                    </Segment>
                                </Segment>
                            </>
                        }

                    </Segment>
                    {/* Pesanan Batal */}
                    {
                        ["CANCELED", "FAILED", "CANCELING"].includes(textOrderStatus)
                        &&
                        <Segment>
                            <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Sebab Pembatalan</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload?.detailData?.cancelReasonDescription}
                                </Segment>
                            </Segment>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Waktu Pengajuan</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload?.detailData?.cancelingTime ? `${moment(payload?.detailData?.cancelingTime).format('DD/MM/YYYY HH:mm')} ${timezoneLabel}` : '-'}

                                </Segment>
                            </Segment>
                            {
                                textOrderStatus !== 'CANCELING' &&
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Selesai Dibatalkan</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {payload?.detailData?.canceledTime ? `${moment(payload?.detailData?.canceledTime).format('DD/MM/YYYY HH:mm')}` : '-'}

                                    </Segment>
                                </Segment>

                            }
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Foto Bukti Pembatalan</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {
                                        payload?.detailData?.canceledImageUrl
                                            ?
                                            <Link
                                                to={'#'}
                                                onClick={() => handleGetImage(payload?.detailData?.canceledImageUrl)}
                                            >
                                                Lihat Gambar
                                            </Link> : '-'
                                    }
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                    <PaymentInfo data={payload?.detailData} />



                </Segment>
                <CardDetailPesanan
                    dataConsume={payload?.detailData}
                    orderStatus={textOrderStatus}
                />

                {["INDOGROSIR", "OTHER"].includes(payload?.detailData?.channelOrder) && payload.detailData.orderStatus === "ALLOCATING" && payload?.allowedStatus?.includes(payload?.detailData?.orderStatus) && <PindahToko detailOrder={payload?.detailData} />}

                {
                    (payload.openModal)
                    &&
                    <ModalInfo
                        isOpen={payload.openModal}
                        onClose={() => handleCloseModal()}
                        style={{ width: 'auto' }}
                        content={
                            <>

                                <Segment py={8} className="font-nunito wrap-img-pendukung" alignItems={'center'} justifyContent={'center'} style={{ textAlign: 'center' }} mb={24}>
                                    {payload.loadingGetImage ?
                                        <Segment className="u-tx-center" width="100%" height={40}><Spinner /></Segment>
                                        : payload?.dataImage}
                                </Segment>

                                <Segment pb={16} maxWidth={360}>
                                    <Segment justifyContent="space-between" alignItems="center" mb={16}>
                                        <Text color="#70727D">
                                            Waktu Pengambilan Foto
                                        </Text>
                                        <Text>
                                            {moment(new Date(payload?.propertiesImage.dateTime), "dddd, DD MMM YYYY - HH:mm").format("dddd, DD MMM YYYY - HH:mm")}
                                        </Text>
                                    </Segment>
                                    <Segment justifyContent="space-between" alignItems="center">
                                        <Text color="#70727D" style={{ width: "50%" }}>
                                            Lokasi Pengambilan Foto
                                        </Text>
                                        <Text style={{ width: "50%" }} textAlign="right" lineHeight={"20px"}>
                                            {`(${payload?.propertiesImage.lat}), (${payload?.propertiesImage.long})`}
                                        </Text>
                                    </Segment>
                                </Segment>
                            </>
                        }
                        ButtonFooter={
                            (
                                <Segment style={{ textAlign: "center" }} mx={'auto'}>
                                    <Button onClick={() => handleCloseModal()}>
                                        Tutup
                                    </Button>
                                </Segment>
                            )
                        }
                    />
                }

            </Segment>
        </>
    )
}
// handle Conflict
export default PesananDetail