import React from 'react'
import {
    Segment,
    Text
} from '@elevenia/master-ui/components/Atom'
const PaymentInfo = ({
    data
}) => {
  return data.orderType==="PAKET-JEMPUT" ? 
            (<Segment mb={24} display="flex" className="xs-flex-wrap">
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Informasi Pembayaran</Text>
                </Segment>
                <Segment display="flex">
                    <Text mr={8}>:</Text>
                    {data?.paymentInformation ?? '-'}
                </Segment>
            </Segment>) 
            : ''
  
}

export default PaymentInfo