import { Button, ButtonGroup, Segment } from '@elevenia/master-ui/components/Atom';
import ModalSmall from 'component/ModalCustom/modalSmall';
import React, { useState } from 'react';

export const useModalConfirm = () => {
    const [config, setConfig] = useState({ isOpen: false, isLoading: false });

    const show = (customConfig) => setConfig({
        isOpen: true,
        ...customConfig
    });

    const hide = () => setConfig(false);

    const setIsLoading = (isLoading) => setConfig((prev) => ({
        ...prev,
        isLoading
    }));

    return {
        config,
        show,
        hide,
        setIsLoading
    };
};

export const ModalConfirm = ({ config, toggle }) => {
    return (
        <ModalSmall
            isOpen={config.isOpen}
            onClose={toggle}
            title={config.title}
            content={
                <Segment py={8}>
                    {config.content}
                </Segment>
            }
            ButtonFooter={
                <ButtonGroup>
                    <Button
                        minWidth={'100px'}
                        variant={'secondary'}
                        onClick={toggle}
                    >
                        {config.cancelText ?? 'Batal'}
                    </Button>
                    <Button
                        disabled={config.isLoading}
                        onClick={() => {
                            !config.isLoading && config.onSubmit();
                        }}
                        minWidth={'100px'}
                    >
                        {config.confirmText ?? 'Konfirmasi'}
                    </Button>
                </ButtonGroup>
            }
        />
    );
};
