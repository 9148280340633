import {
    Button,
    ButtonGroup,
    FormControl,
    Icon,
    Segment,
    Text,
    Textfield,
    DatePicker,
    Spinner
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import ModalNotes from 'component/ModalCustom/modalSmall'
import { useAction } from "hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import useFetchSWR from "hooks/useFetchSWR"
import { Services } from "service"
import IconXls from '../../../assets/image/icon-xls-blue.svg'
import { saveAs } from 'file-saver'
import DownloadExcel from 'helper/DownloadExcel'
import { generateFileNameFromDate, parseValue } from "helper"

const TabRiwayat = (props) => {
    const { hasFetch } = useAction()
    const [isModalNotes, setIsModalNotes] = useState({ show: false, data: null, status: '', imageUrl: '' })
    const startOfMonth = new Date(moment().startOf('month').toString())

    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        // status: 'expired',
        startDate: moment(startOfMonth).format('DD-MM-YYYY'),
        endDate: ''
    });

    const optionsSwr = {
        revalidateOnFocus: true,
        shouldRetryOnError: false,
        revalidateOnMount: true
    }

    const { data: dataActive, loading, error } = useFetchSWR({
        key: (isTable && props.access?.read) && [`/driver/api/back-office/absensi/history`, isTable],
        options: optionsSwr,
        mappingData: (data) => ({
            data: data?.data.content,
            totalPages: data?.data.totalPages,
            totalElements: data?.data.totalElements
        })
    })

    const payload = {
        data: dataActive?.data,
        loading: loading,
        totalPages: dataActive?.totalPages
    }

    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
        }
    }, [hasFetch, error])

    const btnActive = (value) => {
        const basicStyle = { minWidth: 44, height: 24, padding: "5px 8px" }

        switch (value) {
            case true:
                return {
                    based: { ...basicStyle },
                    bg: "#1178D4"
                }
            case false:
                return {
                    based: { ...basicStyle, cursor: "auto" },
                    bg: "#9C9DA6"
                }
            default:
                return {
                    bg: "#9C9DA6",
                }
        }
    }

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            keyword: value.trim()
        });
    }
    const [imageUrl, setImageUrl] = useState(null)
    const [loadingImage, setLoadingImage] = useState("idle")
    const handleImage = async (path) => {
        setLoadingImage('pending')
        let url = null
        try {
            url = await Services().get(`/driver/api/back-office/absensi/history/absensi-image`, { imagePath: path }, { responseType: "arraybuffer" })
            const blob = new Blob([url?.data], { type: "image/png" });
            const uris = URL.createObjectURL(blob);
            url = uris
            setImageUrl(url)
            setLoadingImage('resolved')
        } catch (error) {
            setLoadingImage('rejected')
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
        }
        return url
    }

    const [filterDate, setFilterDate] = useState({
        startDate: startOfMonth,
        endDate: null
    })

    const [loadingExport, setLoadingExport] = useState(false)
    const handleDownloadRiwayat = () => {
        setLoadingExport(true)
        Services().get(`/driver/api/back-office/absensi/history`, { ...isTable, unpaged: true })
            .then(async (resp) => {
                setLoadingExport(false)
                const contentRow = resp.data.data.content?.map((value, key) => {
                    const isClockin = value.clockIn !== null;
                    const locationClockin = isClockin ? value.clockInMatch ? 'Sesuai' : 'Tidak Sesuai' : '-'
                    const isClockout = value.clockOut !== null;
                    const locationClockout = isClockout ? value.clockOutMatch ? 'Sesuai' : 'Tidak Sesuai' : '-'
                    return {
                        ...value,
                        no: key + 1,
                        date: value.date ? moment(value.date).format('DD/MM/YY') : '-',
                        deliman: (value.deliveryManName && value.deliveryManNik) ? `${value.deliveryManNik} - ${value.deliveryManName}` : '-',
                        clockInStoreCode: (value.clockInStoreCode && value.clockInStoreName) ? `${value.clockInStoreCode} - ${value.clockInStoreName}` : '-',
                        clockOutStoreCode: (value.clockOutStoreCode && value.clockOutStoreName) ? `${value.clockOutStoreCode} - ${value.clockOutStoreName}` : '-',
                        clockinLocation: locationClockin,
                        clockinTime: value.clockIn ? `${moment(value.clockIn).format('HH:mm')} ${props.timezoneLabel}` : "-",
                        clockinNotes: value.notesClockIn ? value.notesClockIn : '-',
                        clockoutLocation: locationClockout,
                        clockoutTime: value.clockOut ? `${moment(value.clockOut).format('HH:mm')} ${props.timezoneLabel}` : "-",
                        clockuotNotes: value.notesClockOut ? value.notesClockOut : '-',
                        izinName: value.izinName ? value.izinName : '-'
                    }
                });

                const columns = [
                    { header: "No", key: "no", width: 5 },
                    { header: "Tanggal", key: "date", width: 19 },
                    { header: "Delivery Man", key: "deliman", width: 19 },
                    { header: "Kode Toko Clock In", key: "clockInStoreCode", width: 19 },
                    { header: "Lokasi Clock In", key: "clockinLocation", width: 15 },
                    { header: "Waktu Clock In", key: "clockinTime", width: 19 },
                    { header: "Catatan Clock In", key: "clockinNotes", width: 20 },
                    { header: "Kode Toko Clock Out", key: "clockOutStoreCode", width: 19 },
                    { header: "Lokasi Clock Out", key: "clockoutLocation", width: 20 },
                    { header: "Waktu Clock Out", key: "clockoutTime", width: 15 },
                    { header: "Catatan Clock Out", key: "clockuotNotes", width: 20 },
                    { header: "Jenis Izin", key: "izinName", width: 20 }
                ]
                const creator = 'Ipp Application'
                const createdDate = creator
                const modifiedDate = new Date()
                const sheetName = 'sheet 1'

                const {
                    monthOfEndDateFilter,
                    monthOfStartDateFilter,
                    yearOfEndDateFilter,
                    yearOfStartDateFilter
                } = generateFileNameFromDate(filterDate?.startDate, filterDate?.endDate)
                const fileName = `Hasil Absensi Delivery Man ${yearOfStartDateFilter}${yearOfEndDateFilter}-${monthOfStartDateFilter}${monthOfEndDateFilter}_${moment(new Date()).format("YYYY-MM-DD")}`;

                const buffer = await DownloadExcel({ creator, createdDate, modifiedDate, sheetName, columns, contentRow });
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                const fileExtension = ".xlsx";
                const blob = new Blob([buffer], { type: fileType });
                saveAs(blob, fileName + fileExtension);
            })
            .catch(() => {
                setLoadingExport(false)
            })
    }

    return (
        <>
            <Segment mb={32} pb={16}>
                <Segment
                    className="group-filter"
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Segment maxWidth={280} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => handleSearch(e),
                                    placeholder: "Cari NIK atau Nama Delivery Man",
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment alignItems={"center"} justifyContent={"space-between"} className="group-filter">
                        <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                                <DatePicker
                                    placeholderText={"Tanggal Awal"}
                                    selected={filterDate.startDate}
                                    selectsStart
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    maxDate={filterDate.endDate ? filterDate.endDate : new Date()}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                startDate: e ? moment(e).format('DD-MM-YYYY') : ''
                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                startDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                        {/* separator */}
                        <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                        {/* separator */}
                        <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                                <DatePicker
                                    placeholderText={"Tanggal Akhir"}
                                    selected={filterDate.endDate}
                                    selectsEnd
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    minDate={filterDate.startDate ?? null}
                                    maxDate={new Date()}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                endDate: e ? moment(e).format('DD-MM-YYYY') : ''

                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                endDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                        <Segment ml={20}>
                            <FormControl>
                                <Button style={{ borderRadius: "16px" }} variant="secondary-alt" spinner={loadingExport} disabled={loadingExport} onClick={handleDownloadRiwayat}>
                                    Export Xls
                                    <Segment ml={16}>
                                        <img src={IconXls} alt="xls" />
                                    </Segment>
                                </Button>
                            </FormControl>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loading}
                    defaultSize={isTable.size}
                    totalPages={payload?.totalPages}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            rowField: "date",
                            isCustomRow: (id, { date }) => {
                                return moment(date).format('DD/MM/YY')
                            }
                        },
                        {
                            field: "Delivery Man",
                            rowField: "deliveryManNik",
                            isCustomRow: (id, { deliveryManNik, deliveryManName }) => {
                                return (deliveryManName && deliveryManNik) ? `${deliveryManNik} - ${deliveryManName}` : '-'
                            }
                        },
                        {
                            field: "Shift",
                            rowField: "shift",
                            isCustomRow: (id, { shift }) => parseValue(shift)
                        },
                        {
                            field: "Kode Toko Clock In",
                            rowField: "clockInStoreCode",
                            isCustomRow: (id, { clockInStoreCode, clockInStoreName }) => {
                                return (clockInStoreCode && clockInStoreName) ? `${clockInStoreCode} - ${clockInStoreName}` : '-'
                            }
                        },
                        {
                            field: "Lokasi Clock In",
                            isCustomRow: (_, { clockIn, clockInMatch }) => {
                                const isClockin = clockIn !== null;
                                const locationClockin = isClockin ? clockInMatch ? 'Sesuai' : 'Tidak Sesuai' : '-'
                                return <span style={{ textTransform: "capitalize" }}> {locationClockin}</span>
                            }
                        },
                        {
                            field: "Waktu Clock In",
                            isCustomRow: (_, { clockIn }) => {
                                return clockIn ? `${moment(clockIn).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Catatan Clock In",
                            isCustomRow: (_, { clockIn, clockInMatch, clockInImage, ...entity }) => {
                                const isClockin = clockIn !== null;
                                const property = btnActive(isClockin);

                                return (
                                    <Button style={{ backgroundColor: property.bg, ...property.based }}
                                        onClick={(e) => !isClockin ? e.preventDefault : setIsModalNotes({ show: !isModalNotes.show, data: entity, status: 'Clock-In', imageUrl: handleImage(clockInImage && clockInImage) })}
                                    >
                                        Lihat
                                    </Button>
                                );
                            },
                        },
                        //batas
                        {
                            field: "Kode Toko Clock Out",
                            rowField: "clockOutStoreCode",
                            isCustomRow: (id, { clockOutStoreCode, clockOutStoreName }) => {
                                return (clockOutStoreCode && clockOutStoreName) ? `${clockOutStoreCode} - ${clockOutStoreName}` : '-'
                            }
                        },
                        {
                            field: "Lokasi Clock Out",
                            isCustomRow: (_, { clockOut, clockOutMatch }) => {
                                const isClockout = clockOut !== null;
                                const locationClockout = isClockout ? clockOutMatch ? 'Sesuai' : 'Tidak Sesuai' : '-'
                                return <span style={{ textTransform: "capitalize" }}> {locationClockout}</span>
                            }
                        },
                        {
                            field: "Waktu Clock Out",
                            isCustomRow: (_, { clockOut }) => {
                                return clockOut ? `${moment(clockOut).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Catatan Clock Out",
                            isCustomRow: (_, { clockOut, clockOutMatch, clockOutImage, ...entity }) => {
                                const isClockout = clockOut !== null;
                                // const locationClockout = isClockout ? clockOutMatch ? 'match' : 'not-match'  : 'no-action'
                                const property = btnActive(isClockout);

                                return (
                                    <Button style={{ backgroundColor: property.bg, ...property.based }}
                                        onClick={(e) => !isClockout ? e.preventDefault : setIsModalNotes({ show: !isModalNotes.show, data: entity, status: 'Clock-Out', imageUrl: handleImage(clockOutImage && clockOutImage) })}
                                    >
                                        Lihat
                                    </Button>
                                );
                            },
                        },
                        {
                            field: "Jenis Izin",
                            entityFilters: 'izinName',
                            isCustomRow: (value) => parseValue(value)
                        }
                    ]}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {

                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>

            {/* modal show notes */}
            <ModalNotes
                isOpen={isModalNotes.show}
                onClose={() => {
                    setImageUrl(null)
                    setLoadingImage("idle")
                    setIsModalNotes({ show: !isModalNotes, data: null, status: '', imageUrl: null })
                }
                }
                title={`Foto ${isModalNotes.status}`}
                content={<Segment style={{ lineHeight: "21px" }}>
                    <Segment mb={24} alignItems={'center'} justifyContent={'center'}>
                        {
                            loadingImage === "pending" ? <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment>
                                : imageUrl ? <img src={imageUrl} style={{ width: "337px", height: "337px" }} alt="picture1" /> : '-'
                        }


                    </Segment>
                    <Segment mb={16} style={{ maxHeight: "150px", overflow: "auto" }}>
                        <Text mb={8} color={'#151823'} fontWeight="500">
                            Catatan {isModalNotes.status}
                        </Text>
                        {isModalNotes?.status === 'Clock-In' && isModalNotes?.data?.notesClockIn ? isModalNotes?.data?.notesClockIn :
                            isModalNotes?.status === 'Clock-Out' && isModalNotes?.data?.notesClockOut ? isModalNotes?.data?.notesClockOut : '-'}
                    </Segment>

                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => {
                                setImageUrl(null)
                                setLoadingImage("idle")
                                setIsModalNotes({ show: !isModalNotes, data: null })
                            }}>
                                Tutup
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    );
};

export default TabRiwayat;
