import React, { useState } from 'react'
import {
    Segment,
    Text,
    Button
} from "@elevenia/master-ui/components/Atom"
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import TabSedangAktif from './_component/TabSedangAktif'
import TabRiwayat from './_component/TabRiwayat'
import { getLabelTimeZone } from 'helper'
const dataBreadcrumb = [
    { to: '', label: 'Pengaturan RKB' }
]

const RKBsetting = props => {
    document.title = props.title
    const [activeTab, setActiveTab] = useState("SedangAktif");
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    const optionsLocation = [
        {value:'semua',label:'Semua'},
        {value:'sesuai',label:'Sesuai'},
        {value:'tidak-sesuai',label:'Tidak Sesuai'},
        {value:'kosong',label:'-'}
    ]
    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Pengaturan RKB</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Link to={"/pengaturan-rkb/form"}>
                        <Button>
                            Upload Jadwal
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="SedangAktif">Sedang Aktif</Text>
                        <Text key="Riwayat">Riwayat</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "SedangAktif" && <TabSedangAktif optionsLocation={optionsLocation} timezoneLabel={timezoneLabel} {...props} />}
                        {activeTab === "Riwayat" && <TabRiwayat optionsLocation={optionsLocation} timezoneLabel={timezoneLabel} {...props} />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default RKBsetting