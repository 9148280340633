/**
 * @author Muhamad Humam
 * @email muhamad.humam@sg-edts.com
 * @create date 2020-10-20 10:11:08
 * @modify date 2020-10-20 10:11:08
 * @desc Breadcrumb Component
 */

import React from 'react'
import { Text } from "@elevenia/master-ui/components/Atom"
import { Link } from 'react-router-dom'

const Breadcumb = ({ data }) => {
    return (
        <Text >
            {
                data.map(({ to, label }, key) => (
                    <span key={key}>
                        {
                            (to) ? <Link to={to}>{label}</Link> : label
                        }
                        {(key !== data.length - 1) ? <span style={{ padding: "0 8px" }}>&gt;</span> : null}
                    </span>
                ))
            }
        </Text>
    )
}

export default Breadcumb