import { Button, Segment,Text } from '@elevenia/master-ui/components/Atom';
import 'moment/locale/id';
import React, { lazy } from 'react';
const ModalInfo = lazy(() => import('component/ModalCustom/modalMedium'))
const initialState = {
    loading: false,
    data: {
        awbId: null,
        bulky: false,
        bulkyImageUrl: null,
        cancelPin: null,
        canceledTime: null,
        clusterName: null,
        cod: false,
        codImageUrl: null,
        completedTime: null,
        customerName: null,
        customerPhoneNumber: null,
        deliveryAddress: null,
        deliveryImageUrl: null,
        deliveryTime: null,
        driverNIK: null,
        driverName: null,
        driverPhoneNumber: null,
        endSlotHour: null,
        id: 0,
        inReturnTime: null,
        item: [],
        receiverName: null,
        keepPin: null,
        orderId: null,
        orderStatus: null,
        pickupPin: null,
        pickupTime: null,
        processTime: null,
        returnCompletedTime: null,
        returnPin: null,
        salesOrderId: null,
        serviceType: null,
        startSlotHour: null,
        storeName: null,
    },
    trackHistory: "",
    componentMessage: {
        Modal: ""
    },
    listReasonCancel: null,
    loadingListReasonCancel: false,
    loadingCancelOrder: false,
    statusActionCancel: false,
    dataMulti: null,
    listTab: null,
    loadingOptions: true,
    dataOptions: [],
    propertiesImage: {
        lat: "",
        long: "",
        dateTime: ""
    }
}

export const trackOrder = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_TRACK_ORDER':
            return {
                ...state,
                loading: true,
                data: initialState.data,
                statusActionCancel: false
            }
        case 'GET_TRACK_ORDER':
            return {
                ...state,
                loading: false,
                data: action.payload.tracking,
                trackHistory: action.payload.history
            }
        case "TRACK_ORDER_FAILED":
            return {
                ...state,
                loading: false,
                data: initialState.data
            }
        case "TRACK_ORDER_RESET":
            return {
                ...state,
                loading: false,
                data: initialState.data,
                dataMulti: null,
                listTab: null
            }
        case "RESET_FILE_TRACK_RECORD":
            return {
                ...state,
                componentMessage: {
                    Modal: ""
                }
            }
        case 'GET_FILE_TRACK_RECORD':
            return {
                ...state,
                componentMessage: {
                    Modal: (
                        <ModalInfo
                            isOpen={action.payload.isOpen}
                            onClose={() => false}
                            style={{ width: 'auto' }}
                            content={
                                <>
                                    <Segment py={8} className="font-nunito wrap-img-pendukung" alignItems={'center'} justifyContent={'center'} style={{ textAlign: 'center' }} mb={32}>
                                        {action.payload.image}
                                    </Segment>
                                    <Segment pb={16} maxWidth={360}>
                                        <Segment justifyContent="space-between" alignItems="center" mb={16}>
                                            <Text color="#70727D">
                                                Waktu Pengambilan Foto
                                            </Text>
                                            <Text>
                                                {action.payload.meta?.timestamp}
                                            </Text>
                                        </Segment>
                                        <Segment justifyContent="space-between" alignItems="center">
                                            <Text color="#70727D" style={{ width: "50%" }}>
                                                Lokasi Pengambilan Foto
                                            </Text>
                                            <Text style={{ width: "50%" }} textAlign="right" lineHeight={"20px"}>
                                                {`(${action.payload.meta?.latitude}), (${action.payload.meta?.longitude})`}
                                            </Text>
                                        </Segment>
                                    </Segment>
                                </>
                            }
                            ButtonFooter={
                                (
                                    <Segment style={{ textAlign: "center" }} width={'65%'} mx={'auto'}>
                                        <Button onClick={() => action.payload.onClose()}>
                                            {action.payload.buttonTitle}
                                        </Button>
                                    </Segment>
                                )
                            }
                        />
                    )
                },
                propertiesImage: {
                    lat: action.payload.metadata?.latitude,
                    long:action.payload.metadata?.longitude,
                    dateTime: action.payload.metadata?.timestamp
                }
            }
        case 'REQUEST_LIST_REASON_CANCEL_ORDER':
            return {
                ...state,
                loadingListReasonCancel: true,
            }
        case 'GET_LIST_REASON_CANCEL_ORDER':
            return {
                ...state,
                loadingListReasonCancel: false,
                listReasonCancel: action.payload.data
            }
        case 'REQUEST_CANCEL_ORDER':
            return {
                ...state,
                loadingCancelOrder: true
            }
        case 'FINISH_CANCEL_ORDER':
            return {
                ...state,
                loadingCancelOrder: false,
                statusActionCancel: action.payload.status
            }
        case 'REQUEST_MULTI_TRACK_ORDER':
            return {
                ...state,
                loading: true,
                dataMulti: null
            }
        case 'GET_MULTI_TRACK_ORDER':
            return {
                ...state,
                loading: false,
                dataMulti: action.payload.multiTracking,
                listTab: action.payload.multiTracking.map((val, idx) => `${val.searchParam}_${idx}`),
                statusActionCancel: false
            }
        case 'REQUEST_OPTION_SEARCH_CATEGORY':
            return {
                ...state,
                loadingOptions: true,
            }
        case 'GET_OPTION_SEARCH_CATEGORY':
            return {
                ...state,
                loadingOptions: false,
                dataOptions: action.payload.data
            }
        default:
            return state
    }
}