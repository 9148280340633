import axios from 'axios';
import {
    getToken,
    logout,
    isTimeUp
} from 'helper'
import store from 'store';
const Axios = (url = null) => {
    const TOKEN = getToken()
    let baseURL = null
    let instance = axios.create();
    if (url) {
        baseURL = url
        instance.defaults.baseURL = url
    } else {
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development" || process.env.NODE_ENV === "uat" ) {
            instance.defaults.baseURL = process.env.REACT_APP_API_URL
            baseURL = process.env.REACT_APP_API_URL
        }
    }
    instance.defaults.timeout = 1000 * process.env.REACT_APP_API_TIMEOUT;
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    if (TOKEN) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
    }
    instance.interceptors.request.use(config => {
        isTimeUp()
        if (TOKEN) config.headers.common['Authorization'] = `Bearer ${TOKEN}`
        return config
    })
    instance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        let code, status, message, title = '';
        if (error.response) {
            code = error.response.status;
            status = error.response.data.status;
            message = (error.response.data.detail) ? error.response.data.detail : (error.response.data.message) ? error.response.data.message : error.response.statusText;
            title = error.response.data.title
        } else {
            code = status = message = error.message;
        }
        const originialRequest = error.config
        const refreshToken = localStorage.getItem(process.env.REACT_APP_USER_REFRESH_TOKEN);
        
        if (refreshToken && error?.response?.status === 401 && !originialRequest?.retried) {
            originialRequest.retried = true
            return axios.post('/user/api/user/refresh', { "refresh_token": refreshToken }, { baseURL, headers:{
                'Authorization': `Bearer ${TOKEN}`
            } })
              .then(res => {
                store.dispatch({
                    type: 'AUTH_SUCCESS',
                    payload: {
                        token: res.data.data.access_token,
                        refresh: res.data.data.refresh_token
                    }
                })
      
                return instance({
                  ...originialRequest,
                  headers: {
                    ...originialRequest.headers,
                    'Authorization': `Bearer ${res.data?.data?.access_token}`
                  }
                })
              }).catch(e =>{
                e.response?.status === 401 && logout()
                throw (e)
              }
                
                
              )
        }else{
            let errorData = {
                code,
                status,
                message,
                title
            };
            throw (errorData)
        }
        
        
    });
    return instance;
}

export const Services = (url) => ({
    get: (endpointName, params = null, config = null) => {
        let data = {};
        if (params) { data['params'] = params; }
        if (config) { data = { ...data, ...config }; }
        return Axios(url).get(endpointName, data);
    },
    getRequest: (endpointName, params = null, config = null) => {
        return Axios().get(endpointName, params, config)
    },
    post: (endpointName, params = null, config = null) => {
        return Axios(url).post(endpointName, params, config);
    },
    put: (endpointName, params = null, config = null) => {
        return Axios(url).put(endpointName, params, config);
    },
    delete: (endpointName, params = null, config = null) => {
        let data = {};
        if (params) { data['params'] = params; }
        if (config) { data = { ...data, ...config }; }
        return Axios(url).delete(endpointName, data);
    },
    patch: (endpointName, params = null, config = null) => {
        return Axios(url).patch(endpointName, params, config);
    },
})