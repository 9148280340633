
const initialState = {
    data: "",
    totalPages:0,
    parameter:null,
    loading: false,
    detailData:null,
    statuses:[],
    clusters:[],
    statusCheck:{
        nik:'00',
        email:'00',
        phone:'00'
    },
    loadingCheck:{
      nik:false,
      email:false,
      phone:false
    },
    messageError:{
      nik:'',
      email:'',
      phone:''
    }
  }
  
  export const supervisorDelivery = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_SUPERVISOR_DELIVERY':
            return {
                ...state,
                loading: true,
                detailData:null,
                statusCheck:{
                  nik:'',
                  email:'',
                  phone:''
                },
                messageError:{
                  nik:'',
                  email:'',
                  phone:''
                }
            }
        case 'REQUEST_SUPERVISOR_DELIVERY_BY_ID':
            return {
                ...state,
                loading: true,
                detailData:null,
                statusCheck:{
                  nik:'00',
                  email:'00',
                  phone:'00'
                }
            }
        case 'GET_SUPERVISOR_DELIVERY':
            
            return {
                ...state,
                data:action.payload.data,
                parameter: action.payload.parameter,
                totalPages:action.payload.totalPages,
                loading: false,
                statuses:action.payload.filterStatus,
                clusters:action.payload.filterCluster,
            }
        case 'GET_SUPERVISOR_DELIVERY_BY_ID':
      
          return {
              ...state,
              detailData:action.payload.data,
              loading: false
          }
        case 'GET_SUPERVISOR_DELIVERY_FAILED':
          return {
              ...state,
              loading: false
          }
        case 'RESET_INPUT_STATUS_SPV':
            return {
                ...state,
                statusCheck:{
                  ...state.statusCheck,
                  [action.payload.field]:''
                },
                messageError:{
                  ...state.messageError,
                  [action.payload.field]:''
                }
            }
        case 'REQUEST_CHECK_NIK_SPV':
            return{
                ...state,
                loadingCheck:{
                  ...state.loadingCheck,
                  nik:true,
              },
              messageError:{
                  ...state.messageError,
                  nik:''
              }
            }
        case 'REQUEST_CHECK_EMAIL_SPV':
          return{
              ...state,
              loadingCheck:{
                  ...state.loadingCheck,
                  email:true,
              },
              messageError:{
                  ...state.messageError,
                  email:''
              }
              
          }
        case 'REQUEST_CHECK_PHONE_SPV':
          return{
              ...state,
              loadingCheck:{
                  ...state.loadingCheck,
                  phone:true,
              },
              messageError:{
                  ...state.messageError,
                  phone:''
              }
          }
          case 'GET_STATUS_FIELD_SPV':
              return {
                  ...state,
                  loadingCheck:{
                      ...state.loadingCheck,
                      [action.payload.field]:false,
                  },
                  statusCheck:{
                      ...state.statusCheck,
                      [action.payload.field]:action.payload.status
                  },
                  messageError:{
                      ...state.messageError,
                      [action.payload.field]:action.payload.message
                  }
              }
          case 'ERROR_STATUS_FIELD_SPV':
              return {
                  ...state,
                  loadingCheck:{
                      ...state.loadingCheck,
                      [action.payload.field]:false,
                  },
                  messageError:{
                      ...state.messageError,
                      [action.payload.field]:action.payload.message
                  },
                  statusCheck:{
                      ...state.statusCheck,
                      [action.payload.field]:action.payload.status
                  }
              }
            case 'SET_STATUS_FIELD_SPV':
                return {
                    ...state,
                    statusCheck:{
                        nik:action.payload.status,
                        email:action.payload.status,
                        phone:action.payload.status
                    },
                    loadingCheck:{
                      nik:false,
                      email:false,
                      phone:false
                    },
                    messageError:{
                      nik:'',
                      email:'',
                      phone:''
                    }
                } 
        default:
            return state
    }
  }