
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:null,
  statuses:[],
    statusCheck:{
        nik:'00',
        email:'00',
        phone:'00'
    },
    loadingCheck:{
        nik:false,
        email:false,
        phone:false
    },
    messageError:{
        nik:'',
        email:'',
        phone:''
    }
}

export const coordinatorDeliman = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_COORDINATOR_DELIMAN':
          return {
              ...state,
              loading: true,
              detailData:null,
              statusCheck:{
                nik:'',
                email:'',
                phone:''
              },
              messageError:{
                nik:'',
                email:'',
                phone:''
              }
          }
      case 'REQUEST_COORDINATOR_DELIMAN_BY_ID':
          return {
              ...state,
              loading: true,
              detailData:null,
              statusCheck:{
                nik:'00',
                email:'00',
                phone:'00'
              }
          }
      case 'GET_COORDINATOR_DELIMAN':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false,
              statuses:action.payload.filterStatus,
          }
      case 'GET_COORDINATOR_DELIMAN_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data,
            loading: false
        }
      case 'GET_COORDINATOR_DELIMAN_FAILED':
        return {
            ...state,
            loading: false
        }
      case 'REQUEST_CHECK_NIK_DELIMAN':
          return{
              ...state,
              loadingCheck:{
                ...state.loadingCheck,
                nik:true,
            },
            messageError:{
                ...state.messageError,
                nik:''
            }
          }
      case 'REQUEST_CHECK_EMAIL_DELIMAN':
        return{
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                email:true,
            },
            messageError:{
                ...state.messageError,
                email:''
            }
            
        }
      case 'REQUEST_CHECK_PHONE_DELIMAN':
        return{
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                phone:true,
            },
            messageError:{
                ...state.messageError,
                phone:''
            }
        }
    case 'GET_STATUS_FIELD_DELIMAN':
        return {
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                [action.payload.field]:false,
            },
            statusCheck:{
                ...state.statusCheck,
                [action.payload.field]:action.payload.status
            },
            messageError:{
                ...state.messageError,
                [action.payload.field]:action.payload.message
            }
        }
    case 'ERROR_STATUS_FIELD_DELIMAN':
        return {
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                [action.payload.field]:false,
            },
            messageError:{
                ...state.messageError,
                [action.payload.field]:action.payload.message
            },
            statusCheck:{
                ...state.statusCheck,
                [action.payload.field]:action.payload.status
            }
        }
        case 'SET_STATUS_FIELD_DELIMAN':
            return {
                ...state,
                detailData:null,
                statusCheck:{
                    nik:action.payload.status,
                    email:action.payload.status,
                    phone:action.payload.status
                },
                loadingCheck:{
                  nik:false,
                  email:false,
                  phone:false
                },
                messageError:{
                  nik:'',
                  email:'',
                  phone:''
                }
            } 
      
      default:
          return state
  }
}