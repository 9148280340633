import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
} from '@elevenia/master-ui/components/Atom'
import ModalConfirm from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import { useAction } from 'hooks';
import { requestStoreById, requestAddStore } from 'store/actions/store'
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/id';
import Error404 from 'page/errorLayout'
import { Tabs } from "@elevenia/master-ui/components/Molecules";
import DataTable from 'component/DataTable'
import { capitalize } from 'helper'
const TokoDetail = props => {
    document.title = props.title
    const history = props.history
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)

    const title = id ? 'Detail Toko' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/toko', label: 'Toko' },
        { to: '', label: title }
    ]

    useEffect(() => {
        (id !== undefined && id !== 'preview') && hasFetch(requestStoreById(id))
    }, [hasFetch, id])

    const payload = useSelector(state => {
        return {
            detailData: state.store.detailData,
            loading: state.store.loading,
            listDriverByIdStore: state.store.listDriverByIdStore,
            previewData: props.location?.state
        }
    })

    
    

    const handleActionYes = () => {
        if (id === undefined) {
            
            const dataAdd = {
                storeCode: payload.previewData.storeCode,
                storeName: payload.previewData.storeName,
                address: payload.previewData.address,
                contact: payload.previewData.contact,
                latitude: payload.previewData.latitude,
                longitude: payload.previewData.longitude,
                postalCodeId: payload.previewData.postalCodeId,
                openingHour: moment(payload.previewData.openingHour, 'HH:mm').format('HH:mm:00+07:00'),
                closingHour: moment(payload.previewData.closingHour, 'HH:mm').format('HH:mm:00+07:00'),
                active: payload.previewData.active,
                hasDriver: payload.previewData.hasDriver ==='Ya' ? true : false,
                driverList:payload.previewData.listDriverId.map(val=>({id:val.id, nik: val.nik})),
                channel: payload.previewData.channel
            }
            // console.log({dataAdd})
            hasFetch(requestAddStore(dataAdd, history))
        }
        setIsModal(!isModal)
    }
    
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi Toko</Text>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama Toko</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.storeName : payload.previewData?.storeName}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kode Toko</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.storeCode : payload.previewData?.storeCode}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Alamat</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.address : payload.previewData?.address}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Wilayah/Kode Pos</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.postalCodeDescription : payload.previewData?.postalCodeDescription}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Koordinat Latitude</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                ({id ? payload.detailData?.latitude : payload.previewData?.latitude})
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Koordinat Longitude</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                ({id ? payload.detailData?.longitude : payload.previewData?.longitude})
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kontak</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id
                                    ? payload.detailData?.contact ? payload.detailData?.contact : '-'
                                    : payload.previewData?.contact ? payload.previewData?.contact : '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Jam Operasional</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? moment(payload.detailData?.openingHour, 'HH:mm Z').format('HH:mm') : payload.previewData?.openingHour} - {id ? moment(payload.detailData?.closingHour, 'HH:mm Z').format('HH:mm') : payload.previewData?.closingHour}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama Cabang</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id
                                    ? payload.detailData?.dcName ? payload.detailData?.dcName : '-'
                                    : payload.previewData?.dcName ? payload.previewData?.dcName : '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Kode Cabang</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id
                                    ? payload.detailData?.dcCode ? payload.detailData?.dcCode : '-'
                                    : payload.previewData?.dcCode ? payload.previewData?.dcCode : '-'}
                            </Segment>
                        </Segment>
                        <Segment mb={24}  display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Channel</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? capitalize(payload.detailData?.channel??'-')  : capitalize(payload.previewData?.channel) }
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Delivery Man</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.totalDriver > 0 ? `${payload.detailData?.totalDriver} Delivery Man` : ' - ' 
                                    : payload.previewData?.totalDriver > 0 ? `${payload.previewData?.totalDriver} Delivery Man` : ' - ' }
                            </Segment>
                        </Segment>
                        <Segment  display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.active ? 'Aktif' : 'Non-Aktif'
                                    : payload.previewData?.active ? 'Aktif' : 'Non-Aktif'}
                            </Segment>
                        </Segment>
                    </Segment>
                    {id &&
                        <Segment>
                            <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280} className="xs-flex-wrap">
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.modifiedBy} | {payload.detailData?.modifiedDate ? moment(payload.detailData?.modifiedDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                    
                        
                    <Segment mt={32}>
                            <Tabs active={'delivMan'} onChange={active => console.log(active)}>
                                <Text key="delivMan">Delivery Man</Text>
                                
                            </Tabs>
                            <Segment mt={24}>
                                    <DataTable
                                            countingList={true}
                                            defaultSize={10}
                                            tableConsume={[{
                                                field: 'NIK Delivery Man',
                                                rowField: 'nik',
                                                isCustomRow: (id, entity) => {
                                                    return (
                                                        <Link target="_blank" to={`/delivery-man/${entity.id}`}>{entity.nik}</Link>
                                                    )
                                                }
                                            }, {
                                                field: 'Nama',
                                                rowField: 'name'
                                            }, {
                                                field: 'Kontak Delivery Man',
                                                rowField: 'phone'
                                            }, {
                                                field: 'Tipe Pegawai',
                                                rowField: 'employmentType'
                                            }]}
                                            isLoading={payload.loading}
                                            dataConsume={id ? payload.listDriverByIdStore : payload.previewData.listDriverId}
                                        />
                                </Segment>
                            </Segment>
                        
                    </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            <>
                                {/* <Button variant={"secondary"} onClick={() => setIsModal(!isModal)}>Hapus</Button> */}
                                <Link to={`/toko/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={{ pathname: "/toko/create", state: props.history?.location?.state }}>
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>

                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal confirm */}
            <ModalConfirm
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Toko?" : "Buat Toko Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Semua informasi mengenai Toko ini akan dihapus dari sistem."
                        :
                        "Semua informasi Toko yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button type={"button"} onClick={handleActionYes} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                Buat
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default TokoDetail