import React, { useEffect, useState } from 'react';
import {
    Segment,
    Text
} from "@elevenia/master-ui/components/Atom";
import { Tabs } from "@elevenia/master-ui/components/Molecules";
import Breadcrumb from 'component/Breadcrumb';
import TabDeliman from './_component/TabDeliman';
import TabRiwayat from './_component/TabRiwayat';
import { getLabelTimeZone } from 'helper';
import { useHistory, useLocation } from 'react-router-dom';

const dataBreadcrumb = [
    { to: '', label: 'Pengaturan Absensi Delivery Man' }
];

const AbsenDelivery = props => {
    document.title = props.title;
    const { search } = useLocation();
    const history = useHistory();
    const params = search ? new URLSearchParams(search) : null;
    
    const [activeTab, setActiveTab] = useState(params && params.get("activeTab") ? params.get("activeTab") : "Deliman");
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60);

    useEffect(()=>{
        history.replace(`/absensi-deliveryman?activeTab=${activeTab}`);
    },[activeTab, history]);

    return (
        <>
            <Segment justifyContent={"space-between"}>
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Pengaturan Absensi Delivery Man</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <Segment>
                    <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                        <Text key="Deliman">Delivery Man</Text>
                        <Text key="Riwayat">Riwayat</Text>
                    </Tabs>
                    <Segment mt={32}>
                        {activeTab === "Deliman" && <TabDeliman  timezoneLabel={timezoneLabel} {...props} />}
                        {activeTab === "Riwayat" && <TabRiwayat timezoneLabel={timezoneLabel} {...props} />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    );
};

export default AbsenDelivery;
