import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const RequestMasteStore = action$ =>
    action$.pipe(
        ofType( 'REQUEST_LIST_STORE',
                'REQUEST_STORE_BY_ID',
                'REQUEST_CREATE_STORE',
                'REQUEST_UPDATE_STORE',
                'REQUEST_CHECK_STORE_CODE',
                'REQUEST_LIST_ADDRESS',
                'REQUEST_LIST_FILTER_CLUSTER'
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case 'REQUEST_LIST_STORE':
                        const rawResponse = await Services().get('/store/api/back-office/stores',action.payload.properties)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_STORE',
                                payload: {
                                    data: rawResponse.data.data.content,
                                    parameter: action.payload.properties,
                                    totalPages: rawResponse.data.data.totalPages
                                    
                                }
                            })
                        }
                    case 'REQUEST_STORE_BY_ID':
                        const rawResponseDetail = await Services().get(`/store/api/back-office/stores/${action.payload}`)
                        const rawResponseDelimanByStore = await Services().get(`/driver/api/back-office/deliveryman-store/list-driver/${action.payload}`)
                        return dispatch => {
                            dispatch({
                                type: 'GET_STORE_BY_ID',
                                payload: {
                                    data: {
                                        detail: rawResponseDetail.data.data,
                                        listDriver: rawResponseDelimanByStore.data.data.content
                                    }
                
                                }
                            })
                        }

                    case 'REQUEST_CREATE_STORE':
                        await Services().post(`/store/api/back-office/stores`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/toko/`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Berhasil membuat Toko baru' } })
                        }
                    case 'REQUEST_UPDATE_STORE':
                        await Services().put(`/store/api/back-office/stores`,action.payload.params)
                        
                        return dispatch => {
                            action.history.push(`/toko/${action.payload.id}`)
                            dispatch({ type: 'ALERT_TOAST_SUCCESS', payload: { message: 'Edit Informasi Toko berhasil' } })
                        }
                    case 'REQUEST_CHECK_STORE_CODE':
                        try {
                            const rawCheckStoreCode = await Services().get(`/store/api/back-office/stores/validate`,action.payload.params)
                            return dispatch => {
                                dispatch({
                                    type: 'GET_STATUS_STORE_CODE',
                                    payload: {
                                        status: rawCheckStoreCode.data.status,
                                        
                                    }
                                })
                            }    
                        } catch (error) {
                            return dispatch => {
                                dispatch({
                                    type: 'ERROR_STATUS_STORE_CODE',
                                    payload: {
                                        message: error.message,
                                        status:error.status
                                        
                                    }
                                })
                            }
                        }
                    case 'REQUEST_LIST_ADDRESS':
                        const rawResponseListAddress = await Services().get(`/store/api/back-office/postal-codes?searchParam=${action.payload.params}&unpage=true`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_LIST_ADDRESS',
                                payload: {
                                    data: rawResponseListAddress.data.data.content.map(val=>({
                                                label:`${val.description}`,
                                                value:val.postalCodeId,
                                                zipCode:val.postal_code
                                            })),
                                    
                                }
                            })
                        }
                    case 'REQUEST_LIST_FILTER_CLUSTER' :
                        const rawResponseFilterCluster = await Services().get(`/store/api/back-office/clusters/dropdown`)
                        
                        return dispatch => {
                            dispatch({
                                type: 'GET_DROPDOWN_CLUSTER_STORE',
                                payload: {
                                    data: rawResponseFilterCluster.data.data.content.map(val=>({
                                                label:`${val.clusterCode}`,
                                                value:val.id,
                                            })),
                                    
                                }
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    dispatch({ type: "REQUEST_STORE_FAILED" })
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                }
            }
        }),
    )
