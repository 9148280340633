import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    Badge
} from '@elevenia/master-ui/components/Atom'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import { useAction } from 'hooks';
import { requestCoordinatorDelimanById, requestAddCoordinatorDeliman } from 'store/actions/coodinatorDeliman'
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/id';
import Error404 from 'page/errorLayout'
import { statusColor } from 'helper'
import { Services } from 'service'
const KoordinatorDelivDetail = props => {
    document.title = props.title
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const history = props.history
    const title = id ? 'Detail Koordinator Delivery' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/koordinator-delivery', label: 'Koordinator Delivery' },
        { to: '', label: title }
    ]

    const payload = useSelector(state => {
        return {
            detailData: state.coordinatorDeliman.detailData,
            previewData: props.location?.state
        }
    })
    
    const [loadingDelete, setLoadingDelete] = useState('idle')
    const handleActionYes = async () => {
        if (id === undefined) {
            const dataAdd = {
                email: payload.previewData.email,
                name: payload.previewData.name,
                nik: payload.previewData.nik,
                phone: payload.previewData.phone,
                status: payload.previewData.status
            }

            hasFetch(requestAddCoordinatorDeliman(dataAdd, history))
        }else{
            setLoadingDelete('pending')
            try {
                await Services().delete(`/user/api/coordinator/delete/${id}`)    
                setLoadingDelete('resolved')
                hasFetch(requestCoordinatorDelimanById(id))
            } catch (error) {
                const { message } = error
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                setLoadingDelete('rejected')
            }
            
        }
        setIsModal(!isModal)
    }

    useEffect(() => {
        (id !== undefined && id !== 'preview') && props.access?.read && hasFetch(requestCoordinatorDelimanById(id))
    }, [hasFetch, id, props.access])

    const pallet = payload.detailData?.deleted ? statusColor('deleted') : null

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment mb={32}>
                        <Segment display="flex" justifyContent="space-between" alignItems="start">
                            <Text fontWeight={600} mb={32}>Informasi Koordinator Delivery</Text>
                            {payload.detailData?.deleted && pallet !== null && <Badge
                                style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                bg={pallet.bg}
                                color={pallet.color}
                                fontSize={12}
                                p={8}
                            >
                                {pallet.text}
                            </Badge>}
                        </Segment>
                        <Segment mb={16} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.name : payload.previewData?.name}
                            </Segment>
                        </Segment>
                        <Segment mb={16} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>NIK</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.nik : payload.previewData?.nik}
                            </Segment>
                        </Segment>
                        <Segment mb={16} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Email</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.email : payload.previewData?.email}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nomor Handphone</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.phone : payload.previewData?.phone}
                            </Segment>
                        </Segment>
                        {
                            id &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Verifikasi</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.verified ? 'Terverifikasi' : 'Belum Verifikasi'}
                                </Segment>
                            </Segment>
                        }

                        <Segment display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.status === 'active' ? 'Aktif' : 'Non-Aktif'
                                    : payload.previewData?.status === 'active' ? 'Aktif' : 'Non-Aktif'}
                            </Segment>
                        </Segment>
                    </Segment>
                    {id &&
                        <Segment borderTop={"1px solid #DCDEE3"} pt={32}>
                            <Segment mb={24} display="flex"className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.lastModifiedBy} | {payload.detailData?.lastModifiedDate ? moment(payload.detailData?.lastModifiedDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            !payload.detailData?.deleted && <>
                                <Button variant={"secondary"} onClick={() => setIsModal(!isModal)}>Hapus</Button>
                                <Link to={`/koordinator-delivery/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={{ pathname: "/koordinator-delivery/create", state: props.history?.location?.state }}>
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>
                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Koordinator Delivery?" : "Buat Koordinator Delivery Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Informasi ini akan dihapus dari sistem."
                        :
                        "Semua informasi Koordinator Delivery yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button onClick={handleActionYes} disabled={loadingDelete==="pending"} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                {id ? loadingDelete==="pending" ? "Loading.." : "Hapus" : "Buat"}
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default KoordinatorDelivDetail