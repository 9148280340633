import React, { useState, useEffect } from "react"
import {
    Segment,
    Icon,
    FormControl,
    Textfield,
    ButtonLink
} from "@elevenia/master-ui/components/Atom"

import DataTable from 'component/DataTable'

import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestReleaseLocking,requestReleaseLockingInterval } from 'store/actions/release-locking';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {getLabelTimeZone} from 'helper'
import moment  from "moment"
import ModalOderInfo  from "./ModalOrderInfo";


const TabSedangAktif = (props) => {
    const { hasFetch } = useAction();
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset()/60)
    const [isModal, setIsModal] = useState(false)
    const [status, hasStatus] = useState("hasLoad")
    const [detailId, setDetailId] = useState("")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        // sort: "closingTime,asc",
    
    });
    const payload = useSelector(state => {
        return {
            data: state.releaseLocking.data,
            loading: state.releaseLocking.loading,
            totalPages: state.releaseLocking.totalPages,
            parameter: state.releaseLocking.parameter,
            statName: state.setAlertsToast.notify.statName
        }
    })
    useEffect(() => {

        status === "hasLoad" && props.access?.read && hasFetch(requestReleaseLocking(isTable))
        status === "hasPagination" && props.access?.read &&  hasFetch(requestReleaseLocking(isTable))
    }, [hasFetch, isTable, status,  props.access])

    
    useEffect(()=>{
        let intervalId = null
        if(props.access?.read){
            intervalId = setInterval(()=>{
                hasFetch(requestReleaseLockingInterval())
            },30000)
            
        }
        
        return ()=>{
            clearInterval(intervalId)
        }
    },[hasFetch,props.access])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestReleaseLocking(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            keyword: value.trim()
        });
    }
    
    const handleShowDetail = (data) => {
        setIsModal(!isModal)
        setDetailId(data)
    }
    return <>
        <Segment boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
            <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                <FormControl>
                    <Textfield
                        inputProps={{
                            onChange: e => handleSearch(e),
                            placeholder: 'Cari NIK atau Nama Delivery Man'
                        }}
                        left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                    />
                </FormControl>
            </Segment>
        </Segment>
        <DataTable
            countingList={true}
            defaultSize={isTable.size}
            tableConsume={[{
                field: 'ID Release',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.releaseCode}</>
                    )
                }
            }, {
                field: 'NIK Delivery Man',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.deliverymanNik}</>
                    )
                }
            }, {
                field: 'Delivery Man',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.deliverymanName}</>
                    )
                }
            },{
                field: 'Tipe Release',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.type==='in_return' ? 'Max. COD in Return' : 'Max. COD in Delivery'}</>
                    )
                }
            }, {
                field: 'Order',
                isCustomRow: (id, entity) => {
                    return (
                        <ButtonLink onClick={() => handleShowDetail(entity)}>{entity.totalOrder} Order</ButtonLink>
                    )
                }
            }, {
                field: 'Waktu Pembukaan',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.openingTime ? `${moment(entity.openingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-'}</>
                    )
                },

            }, {
                field: 'Waktu Berakhir',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.closingTime ? `${moment(entity.closingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-'}</>
                    )
                },

            },{
                field: 'Pembuat Release',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.username ? `${entity.userNik} - ${entity.username}` : '-'}</>
                    )
                }
            }]}
            dataConsume={payload?.data}
            showSize={true}
            showPagination={true}
            showSearch={false}
            totalPages={payload?.totalPages}
            createShowPerSize={(size) => setTable(prev => {
                return {
                    ...prev,
                    ...payload.parameter,
                    size: size.value
                }
            })}
            createPaginations={(page) => {
                const query = {
                    ...payload.parameter,
                    ...page
                }
                hasStatus('hasPagination')
                setTable(query)
            }}
        />

        {/* modal info */}
        {isModal && <ModalOderInfo
            data={detailId}
            isModal={isModal}
            setIsModal={setIsModal}
        />}
    </>
}

export default TabSedangAktif