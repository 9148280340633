import React, { useEffect, useState } from 'react'
import {
    Segment,
    Text,
    Spinner
} from '@elevenia/master-ui/components/Atom'
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import { useAction } from 'hooks'
import { useSelector } from 'react-redux'
import { 
    requestDataDashboard, 
    //requestDataDashboardInterval 
} from 'store/actions/dashboard'
import Error404 from 'page/errorLayout'
import moment from 'moment'
import 'moment/locale/id'
import { getLabelTimeZone } from 'helper'
import Paket from './paket'
import NonPaket from './non-paket'

const DashboardIndomaret = (props) => {
    document.title = props.title
    const [activeTab, setActiveTab] = useState("non-paket");
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            loadingOrderSummary: state.dashboard.loadingOrderSummary,
            dateTime: state.dashboard.dateTime,
        }
    })

    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60)
    
    useEffect(() => {
        props.access?.read && hasFetch(requestDataDashboard({
            channelOrder: 'INDOMARET',
            orderTypeGroup: activeTab
        }))

    }, [hasFetch, props.access, activeTab])

    // useEffect(() => {
    //     let intervalId = null
    //     if (props.access?.read) {
    //         intervalId = setInterval(() => {
    //             hasFetch(requestDataDashboardInterval({
    //                 channelOrder: 'INDOMARET',
    //                 orderTypeGroup: activeTab
    //             }))
    //         }, 30000)
    //     }

    //     return () => {
    //         clearInterval(intervalId)
    //     }
    // }, [hasFetch, props.access, activeTab])


    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    return (
        <>
            <Segment mb={30} justifyContent={"space-between"} className="xs-block">
                <Text H28 mb={"1rem"}>Dashboard Indomaret</Text>
                <Segment display="inline-block">
                    {
                        payload.loadingOrderSummary
                            ? <Segment className="u-tx-center" width="100%" height={30}>
                                <Spinner />
                            </Segment>
                            : <Segment className="label-dashboard">
                                {payload.dateTime ? `${moment(payload.dateTime).locale('id').format('dddd, DD MMMM YYYY - HH:mm:ss')} ${timezoneLabel}` : '-'}
                            </Segment>
                    }

                </Segment>
            </Segment>

            <Segment mb={24} pb={16}>
                <Segment>
                    <Segment className="box-tab" bg="white" p={16} display="inline-block" width="100%" borderRadius={"8px"}>
                        <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                            <Text key="non-paket">Non-Paket</Text>
                            <Text key="group-paket">Paket</Text>
                        </Tabs>
                    </Segment>
                    <Segment mt={32}>
                        {activeTab === "non-paket" && <NonPaket activeTab={activeTab} {...props} />}
                        {activeTab === "group-paket" && <Paket activeTab={activeTab} {...props} />}
                    </Segment>
                </Segment>
            </Segment>
        </>
    )
}

export default DashboardIndomaret