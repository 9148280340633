import React, { useRef, useState } from "react"
import {
    Segment,
    FormControl,
    Icon,
    Textfield,
    OptionBox,
    Text,
    Button
} from "@elevenia/master-ui/components/Atom"

const IconFilter = require("assets/image/icon-setting-blue.svg");
const SectionFilterOrder = ({
    setModal,
    handleSearch,
    setCategorySearch,
    categorySearch,
    setTable,
    isTable,
    customOptionList = []
}) => {
    const refInput = useRef(null)
    const optionsList = [
        { value: 1, label: 'Order ID' },
        { value: 3, label: 'SO ID' },
        ...customOptionList
    ]

    const [labelSearch, setLabelSearch] = useState('Order ID');
    
    return <>
            <Segment display="flex">
                <Segment width={220} style={{
                    alignSelf: "center"
                }}>
                    <Text  B14 color="info">Cari Berdasarkan:</Text>
                </Segment>
                
                <Segment pl={20} maxWidth={142} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <OptionBox
                            
                            options={optionsList}
                            placeholder={"Kategori"}
                            value={optionsList.find(val=>val.value===categorySearch)}
                            onChange={(e)=>{
                                let value = ''
                                let label = ''
                                if (e) {
                                    value = e.value
                                    label = e.label
                                }
                                refInput.current.value = ""
                                setCategorySearch(value)
                                setLabelSearch(label)
                                setTable(prev=>({
                                    ...prev,
                                    searchParam:''
                                }))
                            }}
                        />  
                    </FormControl>
                </Segment>
                <Segment pl={20} maxWidth={311} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <Textfield
                            inputProps={{
                                ref:refInput,
                                onChange: e => handleSearch(e), 
                                placeholder: `Cari ${labelSearch}`
                            }}
                            left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                        />
                    </FormControl>
                </Segment>
            </Segment>
            <Segment>
                <Button style={{ borderRadius: "4px", minWidth: "40px" }} variant="secondary-alt"  onClick={(e) => setModal(true)}>
                    <img src={IconFilter} alt="filter"/>
                </Button>
            </Segment>
    </>
}

export default SectionFilterOrder