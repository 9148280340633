export const requestReleaseValidation = (parameter) => {
    return {
        type: "REQUEST_RELEASE_VALIDATION",
        payload: { parameter }
    }
}
export const requestReleaseValidationInterval = () => {
    return {
        type: "REQUEST_RELEASE_VALIDATION_INTERVAL",
        
    }
}
export const requestReleaseValidationHistory = (parameter) => {
    return {
        type: "REQUEST_RELEASE_VALIDATION_HISTORY",
        payload: { parameter }
    }
}
export const requestOrderListByIdDelimanAndStatus = params => {
    return {
        type: "REQUEST_ORDER_LIST_BY_ID_DELIMAN_AND_STATUS",
        payload: { params }
    }
}
  
export const requestSubmitReleaseValidation = (data,history) => {
    return {
        type: "REQUEST_SUBMIT_RELEASE_VALIDATION",
        payload: { data },
        history
    }
} 

export const requestListDuration = () => {
    return {
        type: "REQUEST_DURATION_RELEASE_VALIDATION",
        
    }
} 

