import React, { useState, useEffect } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    Switch
} from '@elevenia/master-ui/components/Atom'
import ModalConfirmation from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'

import { useAction, useInput } from 'hooks';
import { requestDeliverymanCadanganById, requestUpdateDeliverymanCadangan } from 'store/actions/deliverymanCadangan'
import { useSelector } from 'react-redux';
import moment from 'moment'
import Error404 from 'page/errorLayout'

const Detail = (props) => {
  const pathname = props.location.pathname
  const id = props.match.params.id;
  const history = props.history
  const isEdit = pathname.includes('edit') ? true : false 
  const title = isEdit ? 'Edit' : 'Detail Penugasan'
  const [isModal, setIsModal] = useState(false)
  const dataBreadcrumb = isEdit ? [
          { to: '/deliveryman-cadangan', label: 'Delivery Man Cadangan' },
          { to: `/deliveryman-cadangan/${id}`, label: 'Detail Penugasan' },
          { to: '', label: title }
    ]: [
          { to: '/deliveryman-cadangan', label: 'Delivery Man Cadangan' },
          { to: '', label: title }
      ]
  const { hasFetch } = useAction();
  const payload = useSelector(state => {
      return {
          detailData: state.deliverymanCadangan.detailData,
          loading: state.deliverymanCadangan.loading
      }
  })

  useEffect(() => {
      (id !== undefined ) && props.access?.read &&  hasFetch(requestDeliverymanCadanganById(id))
      
  }, [hasFetch, id, props.access])

  let { value,  bindChecked, setValue } = useInput({
    initialObjects: {
      status:payload.detailData?.status==='Aktif' ? true : false
    },
    identity: "myForm",
  });

  useEffect(()=>{
    if(payload.loading===false){
      setValue({
        status:payload.detailData?.status==='Aktif' ? true : false
      })
    }
  },[payload.loading,payload.detailData.status,setValue])

  
  const handleSubmit = () => {
    const dataSubmit = {
      id:id,
      status: value.status ? 'Aktif' : 'Non-Aktif'
    }
    hasFetch(requestUpdateDeliverymanCadangan(id,dataSubmit,history))
    setIsModal(!isModal)
  }

  if (!props.access) return <></>
  else if (!props.access?.read && !isEdit) return <Error404 />
  else if (!props.access?.update && isEdit) return <Error404 />
  return (
    <>
      <Segment mb={30}>
          <Text H28 mb={"1rem"}>{title}</Text>
          <Breadcrumb data={dataBreadcrumb} />
      </Segment>
      {
        isEdit && 
        <Segment mb={30} boxShadow borderRadius={4} bg={"white"} p={24}>
          <Segment display="flex" className="xs-flex-wrap">
              <Segment minWidth={280}>
                  <Text color={'#70727D'}>Status</Text>
              </Segment>
              <Segment display="flex">
                  <Switch name="status" label={value.status ? "Aktif" : "Non-Aktif"} labelPosition={"right"} {...bindChecked} checked={value.status} />
              </Segment>
          </Segment>
        </Segment>
      
      }
      <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
          <Segment>
              <Segment display="flex" justifyContent="space-between">
                  <Text fontWeight={600} mb={32}>Informasi Delivery Man</Text>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Nama</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.name ? payload.detailData.name : '-' }
                  </Segment>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>NIK</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.nik ? payload.detailData.nik : '-' }
                  </Segment>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Nomor Handphone</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.phone ? payload.detailData.phone : '-' }
                  </Segment>
              </Segment>
              
          </Segment>
          <Segment>
              <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
              <Segment display="flex" justifyContent="space-between">
                  <Text fontWeight={600} mb={32}>Informasi Penugasan</Text>
              </Segment>
                  <Segment mb={24} display="flex" className="xs-flex-wrap">
                      <Segment minWidth={280}>
                          <Text color={'#70727D'}>ID Penugasan</Text>
                      </Segment>
                      <Segment display="flex">
                          <Text mr={8}>:</Text>
                          {payload.detailData?.taskId ? payload.detailData?.taskId : '-'}
                      </Segment>
                  </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Kode Klaster</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.clusterCode ? payload.detailData?.clusterCode : '-'} 
                  </Segment>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Nama Klaster</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.clusterName ? payload.detailData?.clusterName : '-'} 
                  </Segment>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Tanggal</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.assignmentStartDate && payload.detailData?.assignmentEndDate ? `${moment(payload.detailData?.assignmentStartDate).format('DD/MM/YY')} - ${moment(payload.detailData?.assignmentEndDate).format('DD/MM/YY')}` : '-'} 
                  </Segment>
              </Segment>
              <Segment mb={24} display="flex" className="xs-flex-wrap">
                  <Segment minWidth={280}>
                      <Text color={'#70727D'}>Status</Text>
                  </Segment>
                  <Segment display="flex">
                      <Text mr={8}>:</Text>
                      {payload.detailData?.status ? payload.detailData?.status : '-'} 
                  </Segment>
              </Segment>
          </Segment>
          {!isEdit &&
              <Segment>
                  <Segment name="divide" height={1} bg={"#DCDEE3"} mt={32} mb={32} />
                  <Segment mb={24} display="flex" className="xs-flex-wrap">
                      <Segment minWidth={280}>
                          <Text color={'#70727D'}>Dibuat Oleh</Text>
                      </Segment>
                      <Segment display="flex">
                          <Text mr={8}>:</Text>
                          {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                      </Segment>
                  </Segment>
                  <Segment mb={24} display="flex" className="xs-flex-wrap">
                      <Segment minWidth={280}>
                          <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                      </Segment>
                      <Segment display="flex">
                          <Text mr={8}>:</Text>
                          {payload.detailData?.modifiedBy} | {payload.detailData?.modifiedDate ? moment(payload.detailData?.modifiedDate).locale('id').format('dddd, DD MMMM YYYY HH:mm') : '-'}
                      </Segment>
                  </Segment>
              </Segment>
          }
      </Segment>
      <Segment mt={32} justifyContent="flex-end">
        {
          isEdit 
          ? <ButtonGroup>
            
            <Link to={{ pathname: `/deliveryman-cadangan/${id}` }}>
                <Button variant={"secondary"}>Batal</Button>
            </Link>
            <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Simpan</Button>
             
          </ButtonGroup>
          : payload.detailData?.status !=='Expired' && <Link to={`/deliveryman-cadangan/${id}/edit`}>
              <Button variant={"primary"}>Edit</Button>
            </Link> 
        }
        
      </Segment>
      <ModalConfirmation
          isOpen={isModal}
          onClose={() => setIsModal(!isModal)}
          title={"Simpan Perubahan?"}
          content={<Segment py={8}>
              {"Perbaruan informasi yang Anda lakukan akan tercatat dan tersimpan."}
          </Segment>}
          ButtonFooter={
              (
                  <ButtonGroup>
                      <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={"secondary"}>
                          Batal
                      </Button>
                      <Button onClick={()=>handleSubmit()} minWidth={"100px"} variant={"primary"}>
                          Simpan
                      </Button>
                  </ButtonGroup>
              )
          }
      />
    </>
  )
}

export default Detail;