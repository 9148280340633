
import React from "react";
import { Segment, Text, Spinner } from "@elevenia/master-ui/components/Atom";
import { Link } from "react-router-dom";

const CardCountTracking = ({ statusName, path, status, count, color, date, addOnQueryString }) => {
    const params = `?activeTab=${statusName}&date=${date}${addOnQueryString}`
    
    return (
        <Segment className="card-counting-tracking" bg="white" p={16} display="inline-block" width="100%" borderRight={`4px solid ${color ? color : 'black'}`} borderRadius={"8px 0 0 8px"}>
            <Text H16 mb={24}>
                {status}
            </Text>
            <Link target="_blank" to={{ pathname: `/${path}`, search: params }}>
                <Text H28 color="primary">
                    {count}
                </Text>
            </Link>
        </Segment>
    )
}
const SectionOrderStatus = ({
    loadingOrderSummary,
    dataStatus,
    dataOrderSummary,
    addOnQueryString
}) => {
    
    return (<>
        {
            loadingOrderSummary
                ? <Segment className="u-tx-center" width="100%" height={30}>
                    <Spinner />
                </Segment>
                : <Segment>
                    <Text H16>Status Pesanan</Text>
                    <Segment className="group-card-tracking" display="flex" justifyContent="space-between" mt={24}>
                        {
                            dataStatus.map((val, index)=>(
                                <CardCountTracking key={index} date={val.dateTime} path={val.path} statusName={val.status} status={val.titleStatus} count={dataOrderSummary ? dataOrderSummary[val.status] : 0} color="#FDC194" addOnQueryString={addOnQueryString}/>
                            ))
                        }
                        
                    </Segment>
                </Segment>
        }
    </>)
}

export default SectionOrderStatus;