import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    FormControl,
    Segment,
    Spinner,
    Textfield,
    Text,
    ButtonLink,
    Icon
} from '@elevenia/master-ui/components/Atom';
import { validateForm, validateInput } from 'helper';
import { useInput, useAction } from 'hooks';
import { useSelector } from 'react-redux';
import { requestResetpassword } from 'store/actions/verifikasi-setup';
import Error404 from 'page/errorLayout'

const NewPassword = props => {
    document.title = props.title;
    const logo = require('assets/image/indopaket-logo.png')
    const { hasFetch } = useAction();
    const [pass, setPass] = useState(false)
    const [passEq, setPassEq] = useState(false);
    const [isModal, setIsModal] = useState(false)
    const { value, bindChange } = useInput({
        initialObjects: {
            defaultUser: props.history.location.state?.nik,
            sandi: "",
            equalpass: ""
        },
        identity: "myForm",
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const valid = validateForm(e.target.id);
        valid && hasFetch(requestResetpassword({ nik: value.defaultUser, newPassword: value.sandi, history: props.history }));
    }
    const payload = useSelector(state => {
        return {
            loading: state.authentication.loading,
            notification: state.setAlerVerification.componentMessage
        }
    })
    if (props.location.state === undefined && value.defaultUser === undefined) return <Error404 {...props} />
    return (
        <>
            {payload.notification?.Modal}
            <Segment
                boxShadow
                position={'relative'}
                height={'100vh'}
                alignItems={'center'}
                justifyContent={'center'}
                background="#F2F4FC"
                className="font-nunito"
            >
                <Segment position="absolute" top={40} left={48}>
                    <img src={logo} alt={'Main Logo'} />
                </Segment>
                <Segment
                    boxShadow
                    width={522}
                    bg={'white'}
                    borderRadius={8}
                    px={80}
                    py={60}
                    mx={'auto'}
                >
                    <Segment flexDirection={'column'} alignItems={'center'} mb={48} mx={-24}>
                        <Text H28 fontWeight={'800'} textAlign={'center'} fontSize={28} mt={8}>Atur Ulang Kata Sandi</Text>
                        <Text color={'#70727D'} mt={32} textAlign={'center'} fontSize={14}>
                            Masukkan kata sandi Anda yang baru.
                        </Text>
                    </Segment>
                    <form id="myForm" onSubmit={handleSubmit} autoComplete="false">
                        {
                            props.location.state.flex !== "reset-pass" && <FormControl mb={20}>
                                <Textfield
                                    disabled={true}
                                    inputProps={{
                                        ...bindChange,
                                        type: "text",
                                        name: 'defaultUser',
                                        className: 'validate[required]',
                                        placeholder: 'defaultUser',
                                        autoComplete: "off",
                                        value: value.defaultUser
                                    }}
                                />
                            </FormControl>
                        }
                        <FormControl mb={20}>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => {
                                        validateInput('myForm', 'equalpass')
                                        bindChange.onChange(e)
                                    },
                                    type: pass ? 'text' : 'password',
                                    name: 'sandi',
                                    maxLength: 100,
                                    className: 'validate[required,minLength[6]]',
                                    placeholder: 'Kata Sandi Baru',
                                    autoComplete: "off"
                                }}
                                right={
                                    <ButtonLink type="button" onClick={() => setPass(!pass)}>
                                        <Icon
                                            name={pass ? 'visible' : 'invisible'}
                                            size={16}
                                            fillColor="#70727D"
                                        />
                                    </ButtonLink>
                                }
                                state="normal"
                            />
                        </FormControl>
                        <FormControl mb={20}>
                            <Textfield
                                inputProps={{
                                    ...bindChange,
                                    type: passEq ? 'text' : 'password',
                                    name: 'equalpass',
                                    maxLength: 100,
                                    className: 'validate[required,equals[sandi],minLength[6]]',
                                    placeholder: 'Konfirmasi Kata Sandi',
                                    autoComplete: "off"
                                }}
                                autoComplete="off"
                                right={
                                    <ButtonLink type="button" onClick={() => setPassEq(!passEq)}>
                                        <Icon
                                            name={passEq ? 'visible' : 'invisible'}
                                            size={16}
                                            fillColor="#70727D"
                                        />
                                    </ButtonLink>
                                }
                                state="normal"
                            />
                        </FormControl>
                        <Segment mt={32}>
                            <ButtonGroup responsive>
                                <Button disabled={payload.loading || (value.defaultUser.length === 0 || value.sandi.length === 0 || value.equalpass.length === 0)} type="submit" variant="primary" onClick={() => setIsModal(!isModal)}>
                                    {payload.loading ? <Spinner color="#2a93d6" /> : "Simpan"}
                                </Button>
                            </ButtonGroup>
                        </Segment>
                    </form>
                </Segment>
            </Segment >
        </>
    )
}

export default NewPassword