import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from 'service'

export const constructStateFromServices = (value = []) => {
    const shrekData = value.map(item => {
        return item.menus
    })
    const mutationRawMenus = [].concat(...Object.values(shrekData))
    const finalMutationMenus = mutationRawMenus.map(getNewitem => {
        const allowedStatus = [];
        const rotateKeyOfAccessRighhts = Object.keys(getNewitem.accessRights).reduce((obj, key) => {
            obj[key] = getNewitem.accessRights[key];
            return obj;
        }, {});
        const filterKeyOfAccessRights = Object.keys(rotateKeyOfAccessRighhts).map((key) => {
            return rotateKeyOfAccessRighhts[key] && key
        })
        const getDefineKey = filterKeyOfAccessRights.filter(setCrudStatus => setCrudStatus !== false);
        const setFinalCodeAccessRights = (getDefineKey.length === allowedStatus.length) ? [...getDefineKey, getNewitem.code] : getDefineKey
        return {
            [getNewitem.code]: setFinalCodeAccessRights.filter(nol => nol !== 0)
        }
    })
    let getMutationMenusToObj = Object.assign({}, ...finalMutationMenus);
    return getMutationMenusToObj
}
export const RequestMenuAccessRight = (action$, store) =>
    action$.pipe(
        ofType(
            'REQUEST_READ_MENU_ACCESS_RIGHT',
            'REQUEST_CREATE_MENU_ACCESS_RIGHT',
            'REQUEST_UPDATE_MENU_ACCESS_RIGHT',
            'REQUEST_DELETE_MENU_ACCESS_RIGHT',
            'REQUEST_MENU_ACCESS_RIGHT_ID',
            "REQUEST_PARAMETER_CRUD_MENU_ACCESS_RIGHT"
        ),
        mergeMap(async action => {
            try {
                switch (action.type) {
                    case "REQUEST_PARAMETER_CRUD_MENU_ACCESS_RIGHT":
                        const rawResponseAdd = await Services().get('/user/api/role/add')
                        const statesAdd = constructStateFromServices(rawResponseAdd.data.data.menu_group);
                        return dispatch => {
                            dispatch({
                                type: "GET_PARAMETER_CRUD_MENU_ACCESS_RIGHT",
                                payload: {
                                    rules: rawResponseAdd.data.data,
                                    menus: statesAdd,
                                    action_group: rawResponseAdd.data.data.action_group
                                }
                            })
                        }
                    case 'REQUEST_MENU_ACCESS_RIGHT_ID':
                        const rawResponseId = await Services().get(`/user/api/role/${action.payload.id}`)
                        const statesUpdate = constructStateFromServices(rawResponseId.data.data.menu_group);
                        return dispatch => {
                            dispatch({
                                type: 'GET_MENU_ACCESS_RIGHT_ID',
                                payload: {
                                    id: rawResponseId.data.data.id,
                                    userInRoleCount: rawResponseId.data.data.userInRoleCount,
                                    name: rawResponseId.data.data.name,
                                    menus: statesUpdate,
                                    rules: rawResponseId.data.data.menu_group,
                                    selected: rawResponseId.data.data.selected,
                                    status: rawResponseId.data.data.status,
                                    action_group: rawResponseId.data.data.action_group
                                },
                            })
                        }
                    case 'REQUEST_READ_MENU_ACCESS_RIGHT':
                        const rawResponse = await Services().get('/user/api/role', action.payload.parameter)
                        return dispatch => {
                            dispatch({
                                type: 'GET_READ_MENU_ACCESS_RIGHT',
                                payload: {
                                    parameter: action.payload.parameter,
                                    data: rawResponse.data.data.content,
                                    totalPages: rawResponse.data.data.totalPages
                                },
                            })
                        }
                    case 'REQUEST_CREATE_MENU_ACCESS_RIGHT':
                        await Services().post('/user/api/role/create', action.payload);
                        return dispatch => {
                            action.history.replace('/authorization')
                            dispatch({
                                type: "REQUEST_HOME_SIDE_MENU"
                            })
                            dispatch({
                                type: 'ALERT_TOAST_SUCCESS',
                                payload: { message: "User Authorize Successfully Create" },
                            })
                        }
                    case 'REQUEST_UPDATE_MENU_ACCESS_RIGHT':
                        await Services().put(`/user/api/role/${action.payload.id}`, action.payload.data)
                        return dispatch => {
                            action.history.replace(`/authorization/${action.payload.id}`)
                            dispatch({
                                type: "REQUEST_HOME_SIDE_MENU"
                            })
                            dispatch({
                                type: 'ALERT_TOAST_SUCCESS',
                                payload: { message: "User Authorize Successfully Update" },
                            })
                            dispatch({
                                type: "REQUEST_MENU_ACCESS_RIGHT_ID",
                                payload: { id: action.payload.id }
                            })
                        }
                    case 'REQUEST_DELETE_MENU_ACCESS_RIGHT':
                        await Services().delete(`/user/api/role/${action.payload.id}`)
                        const { totalPages, parameter, data } = store.value.menuAccessRight;
                        const remove = data.filter(resId => resId.id !== action.payload.id)
                        return dispatch => {
                            dispatch({
                                type: parameter.page === 0 ? 'REQUEST_READ_MENU_ACCESS_RIGHT' : 'GET_READ_MENU_ACCESS_RIGHT',
                                payload: {
                                    data: remove,
                                    parameter: {
                                        ...parameter,
                                        page: remove.length !== 0 ? parameter.page : parameter.page - 1,
                                    },
                                    totalPages: remove.length !== 0 ? totalPages : totalPages - 1
                                },
                            })
                            dispatch({
                                type: 'ALERT_TOAST_SUCCESS',
                                payload: { message: "User Authorize Successfully Delete" },
                            })
                        }
                    default:
                        break
                }
            } catch (e) {
                const { message } = e
                return dispatch => {
                    //window.location.replace('/menu-access-right/');
                    dispatch({ type: "REQUEST_MENU_ACCESS_RIGHT_FAILED" });
                    dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } });
                }
            }
        }),
    )
