import React from 'react';
import { Segment, Text } from '@elevenia/master-ui/components/Atom';
import Breadcrumb from 'component/Breadcrumb';
import TabAktif from './_components/TabAktif';
import Error404 from 'page/errorLayout';
const dataBreadcrumb = [{ to: '', label: 'Konfig Validasi' }];

const KonfigValidasi = (props) => {
    document.title = `${props.title}`;
    if (!props.access) return <></>;
    else if (!props.access?.read) return <Error404 />;
    return (
        <>
            <Segment justifyContent={'space-between'}>
                <Segment mb={30}>
                    <Text H28 mb={'1rem'}>
                        {props.title}
                    </Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
            </Segment>
            <Segment mb={24} pb={16}>
                <TabAktif {...props} />
            </Segment>
        </>
    );
};

export default KonfigValidasi;
