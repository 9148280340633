import React, { useState, useEffect } from 'react';
import {
    Segment,
    Icon,
    FormControl,
    Textfield,
    ButtonLink,
} from '@elevenia/master-ui/components/Atom';
import DataTable from 'component/DataTable';
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
    getLabelTimeZone,
    objectToQueryString,
    fetcher,
    mappingStatusReleaseValidation,
} from 'helper';
import moment from 'moment';
import ModalOderInfo from './ModalOrderInfo';
import useSWR from 'swr';
import { capitalizeMany } from './TabRiwayat';

const TableAktif = (props) => {
    const { hasFetch } = useAction();
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60);
    const [isModal, setIsModal] = useState(false);
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: '',
        // sort: "closingTime,desc"
    });
    const optionsSwr = {
        revalidateOnFocus: false,
        refreshInterval: 30 * 1000, // 30 detik
    };
    const { data: dataAccess, error } = useSWR(
        isTable &&
            `/order/api/back-office/release-location?${objectToQueryString(
                isTable
            )}`,
        fetcher,
        optionsSwr
    );

    const payload = useSelector((state) => {
        return {
            data: dataAccess?.data?.content,
            loading: !dataAccess && !error,
            totalPages: dataAccess?.data?.totalPages,
            statName: state.setAlertsToast.notify.statName,
        };
    });

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap((value) => setTable(value))
            )
            .subscribe();

        return () => {
            onSearch$.unsubscribe();
        };
    }, [hasFetch, onSearch$]);

    const handleSearch = (e) => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            page: 0,
            keyword: value.trim(),
        });
    };
    const [selectedData, setSelectedData] = useState(null);
    const handleShowDetail = (data) => {
        setSelectedData(data);
        setIsModal(true);
    };

    return (
        <>
            <Segment
                className={'group-filter'}
                boxShadow={'0 1px 4px 0 #70727d33'}
                borderRadius={4}
                bg={'white'}
                p={20}
                borderBottom={'1px solid #DCDEE3'}
                mb={24}
                justifyContent={'space-between'}
            >
                <Segment maxWidth={468} width={'100%'} className={'fw500'}>
                    <FormControl>
                        <Textfield
                            inputProps={{
                                onChange: (e) => handleSearch(e),
                                placeholder: 'Cari NIK atau Nama Delivery Man',
                            }}
                            left={
                                <Icon
                                    name={'search'}
                                    size={24}
                                    fillColor='#9c9da5'
                                />
                            }
                        />
                    </FormControl>
                </Segment>
            </Segment>
            <DataTable
                countingList={true}
                defaultSize={isTable.size}
                tableConsume={[
                    {
                        field: 'ID Release',
                        isCustomRow: (id, entity) => {
                            return <>{entity.releaseCode}</>;
                        },
                    },
                    {
                        field: 'NIK Delivery Man',
                        isCustomRow: (id, entity) => {
                            return <>{entity.deliverymanNik}</>;
                        },
                    },
                    {
                        field: 'Delivery Man',
                        isCustomRow: (id, entity) => {
                            return <>{entity.deliverymanName}</>;
                        },
                    },
                    {
                        field: 'Channel',
                        isCustomRow: (id, entity) => {
                            return <>{entity.channels ? capitalizeMany(entity.channels) : '-'}</>;
                        },
                    },
                    {
                        field: 'Status',
                        isCustomRow: (id, entity) => {
                            return (
                                <>
                                    {entity?.orderStatus
                                        .map((val) =>
                                            mappingStatusReleaseValidation(val)
                                        )
                                        .filter(
                                            (value, index, self) =>
                                                self.indexOf(value) === index
                                        )
                                        .sort((a, b) => (a > b ? -1 : 1))
                                        .join(', ')}
                                </>
                            );
                        },
                    },
                    {
                        field: 'Order',
                        isCustomRow: (id, entity) => {
                            return (
                                <ButtonLink
                                    onClick={() => handleShowDetail(entity)}
                                >
                                    {entity.totalOrder} Order
                                </ButtonLink>
                            );
                        },
                    },
                    {
                        field: 'Waktu Pembukaan',
                        isCustomRow: (id, entity) => {
                            return (
                                <>
                                    {entity.openingTime
                                        ? `${moment(entity.openingTime).format(
                                              'DD/MM/YY - HH:mm'
                                          )} ${timezoneLabel}`
                                        : '-'}
                                </>
                            );
                        },
                    },
                    {
                        field: 'Waktu Berakhir',
                        isCustomRow: (id, entity) => {
                            return (
                                <>
                                    {entity.closingTime
                                        ? `${moment(entity.closingTime).format(
                                              'DD/MM/YY - HH:mm'
                                          )} ${timezoneLabel}`
                                        : '-'}
                                </>
                            );
                        },
                    },
                    {
                        field: 'Pembuat Release',
                        isCustomRow: (id, entity) => {
                            return (
                                <>
                                    {entity.createdName
                                        ? `${entity.createdBy} - ${entity.createdName}`
                                        : '-'}
                                </>
                            );
                        },
                    },
                ]}
                dataConsume={payload?.data}
                showSize={true}
                showPagination={true}
                showSearch={false}
                totalPages={payload?.totalPages}
                createShowPerSize={(size) =>
                    setTable((prev) => {
                        return {
                            ...prev,

                            size: size.value,
                        };
                    })
                }
                createPaginations={(page) => {
                    const query = {
                        ...isTable,
                        ...page,
                    };
                    setTable(query);
                }}
            />

            {/* modal info */}
            {isModal && selectedData && (
                <ModalOderInfo
                    detailId={selectedData}
                    isModal={isModal}
                    setIsModal={setIsModal}
                />
            )}
        </>
    );
};

export default TableAktif;
