import { combineReducers } from 'redux';
import { setAlerts } from './alertBlock';
import { setAlertsToast } from './alertToast';
import { setAlerVerification } from './alertVerification';
import { authentication } from './auth';
import { homesidemenu } from './homesidemenu';
import { menuAccessRight } from './menuaccessright';
import { users } from './users';
import { coordinatorDeliman } from './coordinatorDeliman'
import { deliveryman } from './deliveryman'
import { verificationSetup } from './verification-setup';
import { cluster } from './cluster';
import { store } from './store';
import { changePassword } from './changePassword';
import { trackOrder } from './trackOrder';
import { orderList } from './orderList';
import { activityLog } from './activity-log';
import { dashboard } from './dashboard';
import { deliverymanCadangan } from './deliverymanCadangan';
import { releaseLocking } from './release-locking';
import { photoReport } from './photoReport';
import { releaseValidation } from './releaseValidation';
import { supervisorDelivery } from './supervisorDelivery';
import { configRKB } from './configRKB';
import { configRKBSpv } from './configRKBSpv';
import { manager } from './manager'
import { releaseAccess } from './release-access';

const rootReducers = combineReducers({
    setAlerts,
    setAlertsToast,
    setAlerVerification,
    authentication,
    homesidemenu,
    menuAccessRight,
    users,
    coordinatorDeliman,
    deliveryman,
    verificationSetup,
    cluster,
    changePassword,
    trackOrder,
    store,
    orderList,
    activityLog,
    dashboard,
    deliverymanCadangan,
    releaseLocking,
    photoReport,
    releaseValidation,
    supervisorDelivery,
    configRKB,
    configRKBSpv,
    manager,
    releaseAccess
})

export default rootReducers
