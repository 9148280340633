import React, { useEffect, useState } from 'react';
import ModalLarge from 'component/ModalCustom/modalLarge';
import {
    Segment,
    Icon,
    ButtonLink,
    Text,
    Textfield,
    FormControl,
    Button,
    CheckBox,
    DatePicker,
} from '@elevenia/master-ui/components/Atom';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { fetcher, validateForm } from 'helper';
import { useInput, useAction } from 'hooks';
import { Services } from 'service';
import moment from 'moment';
const ModalForm = ({ isModal, setIsModal, detailId, refetch }) => {
    const today = new Date();
    const { hasFetch } = useAction();
    const [disabledMaxCod, setDisabledMaxCod] = useState(false);
    const optionsSwr = {
        revalidateOnFocus: false,
        refreshInterval: 0,
    };
    const { data: dataDetail, error } = useSWR(
        detailId &&
            isModal &&
            `/store/api/back-office/store-configs/${detailId?.id}`,
        fetcher,
        optionsSwr
    );

    const payload = useSelector(() => {
        return {
            detailData: dataDetail?.data,
            loadingGetDetail: !dataDetail && !error,
        };
    });
    let { value, bindChange, setValue, bindChecked } = useInput({
        initialObjects: {
            maxCodValidation: '',
            useDefault: false,
            startPeriod: null,
            endPeriod: null,
        },
        identity: 'myForm',
    });

    useEffect(() => {
        if (dataDetail) {
            setValue((prev) => {
                return {
                    ...prev,
                    useDefault: dataDetail?.data?.useDefault,
                    maxCodValidation: dataDetail?.data?.useDefault ? dataDetail?.data?.maxCodDefault : '',
                    startPeriod: dataDetail?.data?.startPeriod ? new Date(dataDetail?.data?.startPeriod) : null,
                    endPeriod: dataDetail?.data?.startPeriod ? new Date(dataDetail?.data?.endPeriod) : null,
                };
            });
            setDisabledMaxCod(dataDetail?.data?.useDefault)
        }
    }, [dataDetail, setValue]);
    const [loadingSubmit, setLoadingSubmit] = useState('idle');
    const handleSubmit = () => {
        if (validateForm('formConfig')) {
            setLoadingSubmit('pending');
            Services()
                .patch(
                    `/store/api/back-office/store-configs/${detailId?.id}
            `,
                    {
                        useDefault: value?.useDefault,
                        maxCodValidation: parseInt(value.maxCodValidation),
                        startPeriod: value?.useDefault ? null : value?.startPeriod ? moment(value?.startPeriod).format('YYYY-MM-DD') : null,
                        endPeriod: value?.useDefault ? null : value?.endPeriod ? moment(value?.endPeriod).format('YYYY-MM-DD') : null, 
                    }
                )
                .then(() => {
                    setLoadingSubmit('resolved');
                    hasFetch({
                        type: 'ALERT_TOAST_SUCCESS',
                        payload: { message: 'Berhasil memperbaharui data.' },
                    });
                    refetch();
                    setIsModal(false);
                })
                .catch((e) => {
                    setLoadingSubmit('rejected');
                    hasFetch({
                        type: 'ALERT_TOAST_ERROR',
                        payload: { message: e?.message },
                    });
                });
        }
    };

    return (
        <>
            <ModalLarge
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={
                    <>
                        <Segment justifyContent='space-between'>
                            <Text>Informasi Parameter</Text>
                            <ButtonLink onClick={() => setIsModal(!isModal)}>
                                <Icon
                                    name={'cancel'}
                                    size={14}
                                    fillColor={'#70727D'}
                                />
                            </ButtonLink>
                        </Segment>
                    </>
                }
                content={
                    <form
                        id='formConfig'
                        autoComplete='false'
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Segment py={8} mt={32}>
                            <Segment
                                mb={24}
                                display='flex'
                                className='xs-flex-wrap'
                            >
                                <Segment
                                    minWidth={100}
                                    style={{
                                        flex:4
                                    }}
                                >
                                    <Text color={'#70727D'}>Kode Toko</Text>
                                </Segment>
                                <Segment display='flex' style={{
                                        flex:8
                                    }}>
                                    <Text mr={8}>:</Text>
                                    {`${payload?.detailData?.storeCode}`}
                                </Segment>
                            </Segment>
                            <Segment
                                mb={24}
                                display='flex'
                                className='xs-flex-wrap'
                            >
                                <Segment
                                    minWidth={100}
                                    style={{
                                        flex:4
                                    }}
                                >
                                    <Text color={'#70727D'}>Nama Toko</Text>
                                </Segment>
                                <Segment display='flex' style={{
                                        flex:8
                                    }}>
                                    <Text mr={8}>:</Text>
                                    {`${payload?.detailData?.storeName}`}
                                </Segment>
                            </Segment>
                            <Segment
                                mb={24}
                                display='flex'
                                className='xs-flex-wrap'
                            >
                                <Segment
                                    minWidth={100}
                                    style={{
                                        flex:4
                                    }}
                                >
                                    <Text color={'#70727D'}>
                                        Max Order Saat Ini
                                    </Text>
                                </Segment>
                                <Segment display='flex' style={{
                                        flex:8
                                    }}>
                                    <Text mr={8}>:</Text>
                                    {`${payload?.detailData?.maxCodValidation}`}
                                </Segment>
                            </Segment>
                            <Segment
                                mb={24}
                                display='flex'
                                className='xs-flex-wrap'
                            >
                                <Segment
                                    minWidth={100}
                                    style={{
                                        flex:4
                                    }}
                                >
                                    <Text color={'#70727D'}>
                                        Perubahan Jumlah Max Order
                                    </Text>
                                </Segment>
                                <Segment display='flex' style={{
                                        flex:8
                                    }}>
                                    <FormControl mr={32}>
                                        <CheckBox
                                            checkProps={{
                                                onChange: (e) => {
                                                    bindChecked.onChange(e);
                                                    if (e.target.checked) {
                                                        setValue((prev) => ({
                                                            ...prev,
                                                            maxCodValidation: dataDetail?.data?.maxCodDefault,
                                                            startPeriod: null,
                                                            endPeriod: null
                                                        }));
                                                    }else{
                                                        setValue((prev) => ({
                                                            ...prev,
                                                            maxCodValidation: '',
                                                        }));
                                                    }
                                                    setDisabledMaxCod(e.target.checked);
                                                },
                                                name: 'useDefault',
                                            }}
                                            checkItems={[
                                                {
                                                    label: 'Default Value',
                                                    value: true,
                                                },
                                            ]}
                                            selected={[value?.useDefault]}
                                        />
                                    </FormControl>
                                    <FormControl
                                        helptext='Ketik jumlah hanya dengan menggunakan angka'
                                        className={'custom-helptext'}
                                        style={{ flex: '1' }}
                                    >
                                        <Textfield
                                            disabled={disabledMaxCod}
                                            inputProps={{
                                                onChange: (e) => {
                                                    bindChange.onChange(e);
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setValue((prev) => ({
                                                            ...prev,
                                                            useDefault:
                                                                parseInt(
                                                                    value
                                                                ) === dataDetail?.data?.maxCodDefault,
                                                        }));
                                                    }
                                                },
                                                name: 'maxCodValidation',
                                                placeholder:
                                                    'Ketik Jumlah Max Order Validasi',
                                                value: value.maxCodValidation,
                                                maxLength: 100,
                                                autoComplete: 'off',
                                                className:
                                                    'validate[required,number] validateMessage{required{Harus diisi}|number{Hanya gunakan angka. Hindari penggunaan huruf dan simbol.}}',
                                            }}
                                        />
                                    </FormControl>
                                </Segment>
                            </Segment>
                            <Segment
                                mb={24}
                                display='flex'
                                className='xs-flex-wrap'
                            >
                                <Segment
                                    minWidth={100}
                                    style={{
                                        flex:4
                                    }}
                                >
                                    <Text color={'#70727D'}>
                                        Periode Berlaku Konfig
                                    </Text>
                                </Segment>
                                <Segment display='flex' style={{
                                        flex:8,
                                        gap: '8px'
                                    }}>
                                    <Segment>
                                        <Segment mb={10} B2>Tanggal Mulai</Segment>
                                        <FormControl>
                                            <DatePicker
                                                minDate={today}
                                                maxDate={value.endPeriod}
                                                endDate={value.endPeriod}
                                                selectsStart
                                                placeholderText='Tanggal'
                                                selected={value.startPeriod}
                                                onChange={(data) =>
                                                    setValue(prev=>({
                                                        ...prev,
                                                        startPeriod: data
                                                    }))
                                                }
                                                right={
                                                    <Icon
                                                        name={'date'}
                                                        size={24}
                                                    />
                                                }
                                                disabled={disabledMaxCod}
                                            />
                                        </FormControl>
                                    </Segment>
                                    <Segment display='flex' justifyContent='center' alignItems='center'><Segment mt={25}><Icon name={'minus'} size={16} color='#434755'/></Segment></Segment>
                                    <Segment>
                                        <Segment mb={10} B2>Tanggal Selesai</Segment>
                                        <FormControl>
                                            <DatePicker
                                                minDate={value.startPeriod ?? today}
                                                startDate={value.startPeriod}
                                                selectsEnd
                                                placeholderText='Tanggal'
                                                selected={value.endPeriod}
                                                onChange={(data) =>
                                                    setValue(prev=>({
                                                        ...prev,
                                                        endPeriod: data
                                                    }))
                                                }
                                                right={
                                                    <Icon
                                                        name={'date'}
                                                        size={24}
                                                    />
                                                }
                                                disabled={disabledMaxCod}
                                            />
                                        </FormControl>
                                    </Segment>
                                </Segment>
                            </Segment>
                        </Segment>
                        <Segment mt={32} justifyContent={'flex-end'}>
                            <Segment>
                                <Button
                                    variant='secondary'
                                    size='medium'
                                    type={'button'}
                                    mr={16}
                                    onClick={() => setIsModal(false)}
                                >
                                    Batal
                                </Button>

                                <Button
                                    variant='primary'
                                    size='medium'
                                    type={'button'}
                                    disabled={loadingSubmit === 'pending'}
                                    onClick={handleSubmit}
                                >
                                    Simpan
                                </Button>
                            </Segment>
                        </Segment>
                    </form>
                }
            />
        </>
    );
};

export default ModalForm;
