import moment from 'moment'
import { Services } from 'service'

export const formatRupiah = (angka, prefix) => {
    if (angka !== undefined) {
        let number = angka.toString(),
            number_string = number.replace(/[^,\d]/g, "").toString(),
            split = number_string.split(","),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
            let separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    } else {
        return "";
    }
};

export const formatRupiahCommas = (angka, prefix) => {
    if (angka !== undefined) {
        let number = angka.toFixed(2),
            number_string = number.split(".")[0].replace(/[^,\d]/g, "").toString(),
            split = number_string.split(","),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
            let separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah + "," + number.split(".")[1] : "";
    } else {
        return "";
    }
};

export const unique = (array, key = null, object = null) => {
    const { takeLast, checkLast, checkFirst } = object === null ? { takeLast: false, checkLast: null, checkFirst: null } : object
    let a = array.concat();
    let init = checkLast && checkLast < a.length ? a.length - checkLast : 0
    let length = checkFirst && checkFirst < a.length ? checkFirst : a.length
    for (let i = init; i < length; ++i) {
        for (let j = i + 1; j < length; ++j) {
            if (key === null) {
                if (a[i] === a[j]) a.splice(j--, 1);
            } else {
                if (a[i][key] === a[j][key]) {
                    if (object && takeLast === true) a[i] = a[j];
                    a.splice(j--, 1);
                    length--
                }
            }
        }
    }
    return a;
};

export const copyText = text => {
    // Setup element
    var element = null;
    element = document.createElement("input");
    element.setAttribute("id", "copyText");
    element.setAttribute("type", "text");
    element.setAttribute("value", text);
    element.setAttribute("style", "position:fixed");
    document.body.appendChild(element);
    element.select();

    try {
        // Copy element value
        var copied = document.execCommand("copy");

        // Remove element
        if (element) element.remove();

        if (copied) {
            console.log("Copied!");
            return true;
        } else {
            console.log("Unable to copy!");
            return false;
        }
    } catch (err) {
        console.log("Unsupported Browser!");
        return false;
    }
};

export const capitalize = (str) => {
    const split = str.split('-')
    let newStr = ''
    if(split.length > 1){
        newStr = split.map(val=>val.toLowerCase().trim().replace(/^\w/, (c) => c.toUpperCase())).join(' ')
    }else{
        newStr = str.toLowerCase().trim().replace(/^\w/, (c) => c.toUpperCase());
    }
    
    return newStr;
}

export const camelCaseToDash = (str) => {
    return str
        .replace(/[^a-zA-Z0-9]+/g, '-')
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/([0-9])([^0-9])/g, '$1-$2')
        .replace(/([^0-9])([0-9])/g, '$1-$2')
        .replace(/-+/g, '-')
        .toLowerCase();
}

export const getBase64ImageFile = (file, callback = (result, error) => console.log(result, error)) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => callback(reader.result, null)
    reader.onerror = error => {
        console.error(error)
        callback(null, error)
    }
}


export function constructObjectAsServices({ value }) {
    let keys = Object.keys(value);
    let arrayKey = keys.filter(setKey => setKey !== "roleName" && setKey !== "status" && setKey !== "statusOrder");
    let construct = []
    let define = ["create", "read", "update", "delete"]
    arrayKey.forEach(element => {
        const findValue = value[element].filter(tag => tag !== "createdBy" && tag !== "lastModifiedBy")
        let setObj = findValue.filter(header => header !== element);
        let getObject = [...setObj, ...define].reduce(function (obj, v) {
            obj[v] = setObj.includes(v);
            return obj;
        }, {})
        construct.push({
            [element]: {
                ...getObject
            }
        })
    });
    return construct
}

export function constructActionGroup(param) {
    const key = Object.keys(param)
    
    const newData = key.map((val)=>{
        return {
            ...param[val],
            
        }
    })
    return newData
    
}

export function constructValidationCheck(value) {
    let keys = Object.keys(value);
    let arrayKeyMutationName = keys.filter(setKey => setKey !== "roleName" && setKey !== "status" && setKey !== "statusOrder");
    let newArrayMutationName = arrayKeyMutationName.map(item => value[item].length !== 0)
    return newArrayMutationName.filter(resultsOfMutation => resultsOfMutation !== false)
}

export function reverseName(item) {
    let str = ""
    str = item === "create" ? "Buat baru" : item === "update" ? "Edit" : item === "read" && "Detail"
    return str
}
export function rotateOtorisasi(array) {
    let rot = []
    for (let i = 0; i < array.length; i++) {
        rot[0] = array[0]
        rot[1] = array[2]
        rot[2] = array[1]
    }
    return rot
}

export const orderStatus = (status) => {
    const parsingStatus = {
        ALLOCATING: 'Pesanan Baru',
        PICKING_UP: 'Penjemputan',
        IN_DELIVERY: 'Pengiriman',
        COMPLETED: 'Selesai',
        IN_RETURN: 'Retur',
        RETURN_COMPLETED: 'Kirim ke Toko',
        CANCELING: 'Pembatalan',
        CANCELED: 'Selesai Dibatalkan',
        FAILED: 'Selesai Dibatalkan',
        FALLBACK: 'Fallback & Titip',
        READY_TO_DELIVER: 'Siap Dikirim'
    }
    return parsingStatus[status]
}



export const trackerStatus = (status) => {
    const parsingStatus = {
        ALLOCATING: 'Pesanan Baru diterima oleh Delivery Man',
        PICKING_UP: 'Pesanan dijemput di Toko',
        IN_DELIVERY: 'Pesanan dalam perjalanan ke Destinasi Customer',
        COMPLETED: 'Pesanan sampai ke Destinasi Customer',
        IN_RETURN: 'Pesanan sampai ke Destinasi Customer & Pengiriman Retur',
        RETURN_COMPLETED: 'Selesai Retur',
        CANCELING: 'Delivery Man mengajukan Pembatalan',
        CANCELED: 'Pesanan dibatalkan oleh Delivery Man',
        CANCELED_BO: 'Pesanan dibatalkan oleh Admin/Koordinator Delivery Man',
        FAILED: 'Pesanan sudah melewati masa tunggu ',
        READY_TO_DELIVER: 'Delivery Man telah sampai di Toko'
    }
    return parsingStatus[status]
}

export const serviceTypeDashboard = (data) => {
    if(!data.cod && data.bulky && data.orderReturn) return  'Bulky & Retur'
    if(data.cod && data.bulky && data.orderReturn) return  'COD, Bulky & Retur'
    if(data.cod && data.bulky && !data.orderReturn) return  'COD & Bulky'
    if(!data.cod && data.bulky && !data.orderReturn) return  'Bulky'
    if(data.cod && !data.bulky && !data.orderReturn) return  'COD'
    if(!data.cod && !data.bulky && !data.orderReturn)  return  '-'
}

export const formatCountDown = (duration,is1Hour=false) => {
    const positifValue = Math.abs(duration)
    
    const days = Math.floor(positifValue / (24 * 60 * 60 ))
    const hours = Math.floor(positifValue / (60 * 60 ) % 24)
    const minutes = Math.floor((positifValue / (60)) % 60)
    const seconds = Math.floor((positifValue % 60)).toLocaleString('en-US',{minimumIntegerDigits:2})
    
    const hourString =  hours > 0 ? `${hours}:` : ''
    const dayString = days > 0 ? `${days}:` : ''
    let result = ''
    if(duration < 0){
      result = `- ${dayString}${hourString}${minutes}:${seconds}`
      
    }else if(duration===0){
      result = '00:00'
    }else if(is1Hour && duration===3600){
      result = '01:00:00'
    }else{
      result = `${dayString}${hourString}${minutes}:${seconds}`
    }
    
    return result
  }
  
  export const getDuration = (start,end) => {
    const startDate = new moment(start);
    const endData = new moment(end);
  
    
    const duration = moment.duration(endData.diff(startDate)).as('seconds')
    
    return Math.ceil(duration)
    
  }
export const getLabelTimeZone = (time) => {
    const diffTime = Math.abs(time)
    const timezone = {
        '7':'WIB',
        '8':'WITA',
        '9':'WIT',
    }

    return timezone[diffTime]
}

export const truncateString = (str,start,end,elipsis=true)=>{
    return elipsis ? `${str.substr(start,end)}...` : str.substr(start,end) 
}

export const mappingStatusReleaseValidation = (status) => {
    const mapStatus = {
        CANCELING: 'Batal',
        PICKING_UP: 'Pick-up',
        READY_TO_DELIVER: 'Pick-up',
        IN_DELIVERY: 'Pengiriman',
    }
    return mapStatus[status]
}
export const objectToQueryString = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const  fetcher = url => Services().get(url).then(res => res.data)
export const timeSlot = (start,end) => {
    return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
  }

export const colorOrderId = (channelOrder = 'INDOMARET') => {
    const color = {
        INDOMARET:'#1178D4',
        INDOGROSIR:'#FFA800'
    }

    return color[channelOrder] ?? '#1178D4' 
}

export const swrFetcher = async (url,params) => {
    
    const response = await Services().get(url,params)
    const data = response?.data

    return data
}

export const generateFileNameFromDate = (startDate, endDate) => {
    const monthOfStartDateFilter = startDate ? moment(startDate).format("MM") : ''
    const monthOfEndDateFilter = endDate && (monthOfStartDateFilter !== moment(endDate).format("MM")) ? moment(endDate).format("MM") : ''
    const yearOfStartDateFilter = startDate ? moment(startDate).format("YYYY") : ''
    const yearOfEndDateFilter = endDate && (yearOfStartDateFilter !== moment(endDate).format("YYYY")) ? moment(endDate).format("YYYY") : ''

    return {
        monthOfEndDateFilter,
        monthOfStartDateFilter,
        yearOfEndDateFilter,
        yearOfStartDateFilter
    }
}

export const queryStringToObject = (query) => {
    const search = query?.substring(1);
    return search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}
}

export const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
};

export const generateOptionDate = (startDate, count, format='DD-MM-YYYY') => {
    let arrayDate = []
    const start = moment(startDate).startOf('month')
    for (let i = 1; i <= count; i++) {
        const date = moment(start).add(i,'M').format(format)
        arrayDate.push(date)   
    }
    return arrayDate
    
}

export const statusColor = status => {
    switch (status) {
        
        case 'inactive':
            return { bg: '#EE2B2E', color: 'white', border: '#EE2B2E', text: 'Non-Aktif' }
        case 'active':
            return { bg: '#8FC742', color: 'white', border: '#8FC742', text: 'Aktif' }
        case 'deleted':
                return { bg: '#EE2B2E', color: 'white', border: '#EE2B2E', text: 'Dihapus' }
        
        default:
            return { bg: 'white', color: '#151823', border: '#DCDEE3', text: status }
    }
}

export const parseValue = (value, formatValue = (val) => val) => (value === 0 || (value && value !== '') ? formatValue(value) : '-');
