import Excel from "exceljs";
const DownloadExcel = (
    {creator,
    createdDate,
    modifiedDate,
    sheetName,
    contentRow,
    columns}
) => {
    const workbook = new Excel.Workbook();
    workbook.creator = "Ipp Application";
    workbook.lastModifiedBy = "Ipp Application";
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    worksheet.columns = columns;

    worksheet.addRows(contentRow);
    
    
    const buffer = workbook.xlsx.writeBuffer();
    return buffer
}

export default DownloadExcel
