import React, { useState, useEffect } from "react"
import {
    Segment,
    Icon,
    FormControl,
    Textfield,
    DatePicker,
    Button
} from "@elevenia/master-ui/components/Atom"
import DataTable from 'component/DataTable'
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestReleaseValidationHistory } from 'store/actions/release-validation';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getLabelTimeZone, mappingStatusReleaseValidation } from 'helper'
import moment from "moment"
import IconXls from '../../../assets/image/icon-xls-blue.svg'
import { Services } from "service";
import DownloadExcel from "helper/DownloadExcel";
import { saveAs } from "file-saver";

const TabRiwayat = (props) => {
    const { hasFetch } = useAction();
    const timezoneLabel = getLabelTimeZone(new Date().getTimezoneOffset() / 60)
    const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
    const [endDate, setEndDate] = useState(null);
    const [status, hasStatus] = useState("hasLoad")
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        openingTime: moment().startOf('month').format('YYYY-MM-DD 00:00')
        // sort: "closingTime,desc"

    });
    const payload = useSelector(state => {
        return {
            data: state.releaseValidation.data,
            loading: state.releaseValidation.loading,
            totalPages: state.releaseValidation.totalPages,
            parameter: state.releaseValidation.parameter,
            statName: state.setAlertsToast.notify.statName,
        }
    })

    useEffect(() => {

        status === "hasLoad" && props.access?.read && hasFetch(requestReleaseValidationHistory(isTable))
        status === "hasPagination" && props.access?.read && hasFetch(requestReleaseValidationHistory(isTable))
    }, [hasFetch, isTable, status, props.access])

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestReleaseValidationHistory(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            keyword: value.trim()
        });
    }

    const [loadingExport, setLoadingExport] = useState(false)
    const handleDownloadRiwayat = () => {
        setLoadingExport(true)
        Services().get(`/order/api/back-office/release-validation/history`, { ...isTable, unpaged: true })
            .then(async (resp) => {
                setLoadingExport(false)
                const contentRow = resp.data.data.content?.map((value, key) => {
                    return {
                        ...value,
                        no: key + 1,
                        releaseCode: value?.releaseCode ? value?.releaseCode : '-',
                        deliverymanNik: value?.deliverymanNik ? value?.deliverymanNik : '-',
                        deliverymanName: value?.deliverymanName ? value?.deliverymanName : '-',
                        status: value?.orderStatus.map(val=>mappingStatusReleaseValidation(val)).filter((value,index, self) => self.indexOf(value) === index).sort((a, b) => (a > b ? -1 : 1)).join(" & "),
                        openingTime: value?.openingTime ? `${moment(value?.openingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-',
                        closingTime : value?.closingTime ? `${moment(value?.closingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-',
                        username: value?.username ? `${value?.userNik} - ${value?.username}` : '-'
                    }
                });
                
                const columns = [
                    { header: "No", key: "no", width: 5 },
                    { header: "ID Release", key: "releaseCode", width: 19 },
                    { header: "NIK Delivery Man", key: "deliverymanNik", width: 19 },
                    { header: "Delivery Man", key: "deliverymanName", width: 19 },
                    { header: "Status", key: "status", width: 19 },
                    { header: "Waktu Pembukaan", key: "openingTime", width: 22 },
                    { header: "Waktu Berakhir", key: "closingTime", width: 22 },
                    { header: "Pembuat Release", key: "username", width: 19 }
                ]
                const creator = 'Ipp Application'
                const createdDate = creator
                const modifiedDate = new Date()
                const sheetName = 'sheet 1'
                const fileName = `Riwayat Release Validation ${moment(new Date()).format("YYYY-MM-DD")}`;

                const buffer = await DownloadExcel({ creator, createdDate, modifiedDate, sheetName, columns, contentRow });
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                const fileExtension = ".xlsx";
                const blob = new Blob([buffer], { type: fileType });
                saveAs(blob, fileName + fileExtension);
            })
            .catch(() => {
                setLoadingExport(false)
            })
    }

    return <>
        <Segment 
            className={"group-filter"}
            boxShadow={"0 1px 4px 0 #70727d33"}
            borderRadius={4}
            bg={"white"}
            p={20}
            borderBottom={"1px solid #DCDEE3"}
            mb={24}
            justifyContent={"space-between"}
        >
            <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                <FormControl>
                    <Textfield
                        inputProps={{
                            onChange: e => handleSearch(e),
                            placeholder: 'Cari NIK atau Nama Delivery Man'
                        }}
                        left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                    />
                </FormControl>
            </Segment>

            <Segment alignItems="center" className={"group-filter"}>
                <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <DatePicker
                            placeholderText={"Tanggal Awal"}
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                setTable(prev => {
                                    return {
                                        ...prev,
                                        ...payload.parameter,
                                        openingTime: date && moment(date).format('YYYY-MM-DD 00:00')
                                    }
                                })
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                            right={<Icon name={"date"} size={24} />}
                        />
                    </FormControl>
                </Segment>
                {/* separator */}
                <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                {/* separator */}
                <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                    <FormControl>
                        <DatePicker
                            placeholderText={"Tanggal Akhir"}
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setTable(prev => {
                                    return {
                                        ...prev,
                                        ...payload.parameter,
                                        closingTime: date && moment(date).format('YYYY-MM-DD 23:59')
                                    }
                                })
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            right={<Icon name={"date"} size={24} />}
                        />
                    </FormControl>
                </Segment>
                <Segment ml={20}>
                    <FormControl>
                        <Button style={{ borderRadius: "16px" }} variant="secondary-alt" spinner={loadingExport} disabled={loadingExport} onClick={handleDownloadRiwayat}>
                            Export Xls
                            <Segment ml={16}>
                                <img src={IconXls} alt="xls"/>
                            </Segment>
                        </Button>
                    </FormControl>
                </Segment>
            </Segment>
        </Segment>
        <DataTable
            countingList={true}
            defaultSize={isTable.size}
            tableConsume={[{
                field: 'ID Release',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.releaseCode}</>
                    )
                }
            }, {
                field: 'NIK Delivery Man',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.deliverymanNik}</>
                    )
                }
            }, {
                field: 'Delivery Man',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.deliverymanName}</>
                    )
                }
            },{
                field: 'Status',
                isCustomRow: (id, entity) => {
                    return (
                        <> 
                            {entity.orderStatus.map(val=>mappingStatusReleaseValidation(val))
                            .filter((value,index, self) => self.indexOf(value) === index)
                            .sort((a, b) => (a > b ? -1 : 1)).join(" & ")}
                        </>
                    )
                }
            }, {
                field: 'Waktu Pembukaan',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.openingTime ? `${moment(entity.openingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-'}</>
                    )
                },

            }, {
                field: 'Waktu Berakhir',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.closingTime ? `${moment(entity.closingTime).format('DD/MM/YY - HH:mm')} ${timezoneLabel}` : '-'}</>
                    )
                },

            },{
                field: 'Pembuat Release',
                isCustomRow: (id, entity) => {
                    return (
                        <>{entity.username ? `${entity.userNik} - ${entity.username}` : '-'}</>
                    )
                }
            }]}
            dataConsume={payload?.data}
            showSize={true}
            showPagination={true}
            showSearch={false}
            totalPages={payload?.totalPages}
            createShowPerSize={(size) => setTable(prev => {
                return {
                    ...prev,
                    ...payload.parameter,
                    size: size.value
                }
            })}
            createPaginations={(page) => {
                const query = {
                    ...payload.parameter,
                    ...page
                }
                hasStatus('hasPagination')
                setTable(query)
            }}
        />

    </>
}

export default TabRiwayat