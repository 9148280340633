import React, { useState, useEffect } from "react"
import {
    Segment,
    FormControl,
    Icon,
    Textfield,
    Button,
    ButtonGroup,
    Text
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import DatePicker from "@elevenia/master-ui/components/Atom/DatePicker"
import ModalNotes from 'component/ModalCustom/modalSmall'
import useSWR from 'swr'
import { fetcher, objectToQueryString } from 'helper'
import moment from "moment"
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { useAction } from "hooks"

const TabSedangAktif = (props) => {
    const hasFetch = useAction()
    const [isModalNotes, setIsModalNotes] = useState(false)
    const [isTable, setTable] = useState({
        page:0,
        size:10,
        status:'active',
        startDate:'',
        endDate:''
    })
    
    const { data: dataRKB, error } = useSWR((isTable && props.access?.read) && `/user/api/back-office/rkb/individual?${objectToQueryString(isTable)}`, fetcher,{
        revalidateOnFocus: false,
        shouldRetryOnError: false
    })    

    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message } })
        }
    },[hasFetch, error])
    const [filterDate, setFilterDate] = useState({
        startDate: null,
        endDate: null
    })
    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            searchParam: value.trim()
        });
    }
    
    const btnActive = (value) => {
        const basicStyle = { minWidth: 44, height: 24, padding: "5px 8px" }

        switch (value) {
            case true:
                return {
                    based: { ...basicStyle },
                    bg: "#FFA800",
                    action: (path) => props.history.push(path)
                }
            case false:
                return {
                    based: { ...basicStyle, cursor: "auto" },
                    bg: "#9C9DA6",
                    action: () => {}
                }
            default:
                return {
                    bg: "#9C9DA6",
                }
        }
    }

    

    return (
        <>
            <Segment mb={32} pb={16}>
                <Segment
                    className="group-filter"
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Segment maxWidth={280} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => handleSearch(e),
                                    placeholder: "Cari Kode Toko",
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment alignItems={"center"} justifyContent={"space-between"} className="group-filter">
                        <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                            <DatePicker
                                    placeholderText={"Tanggal Awal"}
                                    selected={filterDate.startDate}
                                    selectsStart
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    minDate={new Date()}
                                    maxDate={filterDate.endDate ? filterDate.endDate : null}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                startDate: e ? moment(e).format('DD/MM/YYYY') : ''
                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                startDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                        {/* separator */}
                        <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                        {/* separator */}
                        <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                            <DatePicker
                                    placeholderText={"Tanggal Akhir"}
                                    selected={filterDate.endDate}
                                    selectsEnd
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    minDate={filterDate.startDate ?? new Date()}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                endDate: e ? moment(e).format('DD/MM/YYYY') : ''
                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                endDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={!dataRKB && !error}
                    defaultSize={isTable.size}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            rowField: "date",
                            isCustomRow: (id, { date }) => {
                                return moment(date).format('DD/MM/YY')
                            }
                        },
                        {
                            field: "Koordinator Delivery",
                            rowField: "coordinatorNik",
                            isCustomRow: (id, { coordinatorNik, coordinatorName }) => {
                                return (coordinatorName && coordinatorNik) ? `${coordinatorNik} - ${coordinatorName}` : '-'
                            }
                        },
                        {
                            field: "Kode Toko",
                            rowField: "storeCode",
                            isCustomRow: (id, { storeCode, storeName }) => {
                                return (storeCode && storeName) ? `${storeCode} - ${storeName}` : '-'
                            }
                        },
                        {
                            field: "Lokasi",
                            isCustomRow: (_, { checkinStatusInfo }) => {
                                const getLabel = props.optionsLocation.find(val=>val.value===checkinStatusInfo)
                                
                                return <span style={{ textTransform: "capitalize" }}> {getLabel ? getLabel.label : '-'}</span>
                            }
                        },
                        {
                            field: "Waktu Clock In",
                            isCustomRow: (_, { checkinTime }) => {
                                return checkinTime ? `${moment(checkinTime).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Aktivitas",
                            isCustomRow: (_,{date,checkinStatusInfo, id}) => {
                                const activeClockin = btnActive(date === moment().format('YYYY-MM-DD') && checkinStatusInfo==='kosong') ;
                                const activeClockout = btnActive(checkinStatusInfo!=='kosong');
                                

                                return (
                                    <Segment display='flex'>
                                        <Button mr={32} style={{ backgroundColor: activeClockin.bg, ...activeClockin.based }} onClick={()=>activeClockin?.action(`/rkb-kordel/${id}/clockin`)}>
                                            Clockin
                                        </Button>
                                        <Button style={{ backgroundColor: activeClockout.bg, ...activeClockout.based }} onClick={()=>activeClockout?.action(`/rkb-kordel/${id}/clockout`)}>
                                            Clockout
                                        </Button>
                                    </Segment>
                                );
                            },
                        },
                    ]}
                    dataConsume={dataRKB?.data.content}
                    totalPages={dataRKB?.data.totalPages}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {
                        
                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>

            {/* modal show notes */}
            <ModalNotes
                isOpen={isModalNotes}
                onClose={() => setIsModalNotes(!isModalNotes)}
                title={"Informasi Jadwal"}
                content={<Segment py={8} style={{ lineHeight: "21px" }}>
                    <Segment>
                        <Text color={'#151823'} fontWeight="500">
                            Alasan Lokasi Tidak Sesuai
                        </Text>
                        Susah signal sehingga GPS terganggu keakuratannya.
                    </Segment>
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModalNotes(!isModalNotes)}>
                                Tutup
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    );
};

export default TabSedangAktif;
