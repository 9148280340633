import {
    Button, ButtonGroup, FormControl,
    Icon, Segment, Text, Textfield
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import ModalNotes from 'component/ModalCustom/modalSmall'
import { saveAs } from 'file-saver'
import { fetcher, generateFileNameFromDate, objectToQueryString } from 'helper'
import DownloadExcel from 'helper/DownloadExcel'
import { useAction } from "hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { Services } from "service"
import useSWR from 'swr'
import FilterModal from "./FilterModal"

const TabRiwayat = (props) => {
    const { hasFetch } = useAction()
    const [isModalNotes, setIsModalNotes] = useState({ show: false, data: null })
    const [modal, setModal] = useState(null)
    const IconXls = require("assets/image/icon-xls-blue.svg");
    const IconFilter = require("assets/image/icon-setting-blue.svg");

    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        searchParam: "",
        status: 'expired',
        startDate: moment().startOf('month').format('DD/MM/YYYY'),
        endDate: ''
    });

    const optionsSwr = {
        revalidateOnFocus: false,
        shouldRetryOnError: false
    }
    const { data: dataExpired, error } = useSWR((isTable && props.access?.read) && `/user/api/back-office/rkb-spv/all?${objectToQueryString(isTable)}`, fetcher, optionsSwr)

    const payload = {
        data: dataExpired?.data.content,
        loading: !dataExpired && !error,
        totalPages: dataExpired?.data.totalPages
    }


    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
        }
    }, [hasFetch, error])


    const btnActive = (value) => {
        const basicStyle = { minWidth: 44, height: 24, padding: "5px 8px" }

        switch (value) {
            case true:
                return {
                    based: { ...basicStyle },
                    bg: "#FFA800",
                    action: (data) => setIsModalNotes({ show: isModalNotes, data: data })
                }
            case false:
                return {
                    based: { ...basicStyle, cursor: "auto" },
                    bg: "#9C9DA6",
                    action: e => e.preventDefault
                }
            default:
                return {
                    bg: "#9C9DA6",
                }
        }
    }

    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            searchParam: value.trim()
        });
    }


    const [loadingExport, setLoadingExport] = useState('idle')
    const handleDownloadRiwayat = () => {
        setLoadingExport('pending')
        Services().get(`/user/api/back-office/rkb-spv/all`, { ...isTable })
            .then(async (resp) => {
                setLoadingExport('resolved')
                const contentRow = resp.data.data.content?.map((value, key) => {
                    const getLabelLocationClockin = props.optionsLocation.find(val => val.value === value.checkinStatusInfo)

                    const getLabelLocationClockOut = props.optionsLocation.find(val => val.value === value.checkoutStatusInfo)
                    return {
                        ...value,
                        no: key + 1,
                        date: value.date ? moment(value.date).format('DD/MM/YY') : '-',
                        supervisorDeliman: (value.supervisorName && value.supervisorNik) ? `${value.supervisorNik} - ${value.supervisorName}` : '-',
                        store: (value.storeCode && value.storeName) ? `${value.storeCode} - ${value.storeName}` : '-',
                        storeOriginal: (value.storeCodeOriginal && value.storeNameOriginal) ? `${value.storeCodeOriginal} - ${value.storeNameOriginal}` : '-',
                        clockinLocation: getLabelLocationClockin ? getLabelLocationClockin.label : '-',
                        clockinTime: value.checkinTime ? `${moment(value.checkinTime).format('HH:mm')} ${props.timezoneLabel}` : "-",
                        clockoutLocation: getLabelLocationClockOut ? getLabelLocationClockOut.label : '-',
                        clockoutTime: value.checkoutTime ? `${moment(value.checkoutTime).format('HH:mm')} ${props.timezoneLabel}` : "-",
                        mobileDeviceCondition: value.mobileDeviceCondition ? 'Ok' : "Not Ok",
                        mobileDeviceConditionDesc: !value.mobileDeviceCondition ? value.mobileDeviceConditionDesc : '-',
                        motorcycleCondition: value.motorcycleCondition ? 'Ok' : "Not Ok",
                        motorcycleConditionDes: !value.motorcycleCondition ? value.motorcycleConditionDes : '-',
                        delimanCondition: value.delimanCondition ? 'Ok' : "Not Ok",
                        delimanConditionDesc: !value.delimanCondition ? value.delimanConditionDesc : '-',
                        checkoutNote: value.checkoutNote ? value.checkoutNote : '-',
                        checkinNote: value.checkinNote ? value.checkinNote : '-'
                    }
                });
                
                const columns = [
                    { header: "No", key: "no", width: 5 },
                    { header: "Tanggal", key: "date", width: 19 },
                    { header: "Supervisor Delivery", key: "supervisorDeliman", width: 19 },
                    { header: "Kode Toko (Planned)", key: "storeOriginal", width: 19 },
                    { header: "Kode Toko (Actual)", key: "store", width: 19 },
                    { header: "Waktu Clock In", key: "clockinTime", width: 19 },
                    { header: "Lokasi Clock In", key: "clockinLocation", width: 15 },
                    { header: "Waktu Clock Out", key: "clockoutTime", width: 15 },
                    { header: "Lokasi Clock Out", key: "clockoutLocation", width: 20 },
                    { header: "Kondisi HP", key: "mobileDeviceCondition", width: 20 },
                    { header: "Catatan Kondisi HP", key: "mobileDeviceConditionDesc", width: 20 },
                    { header: "Kondisi Motor", key: "motorcycleCondition", width: 20 },
                    { header: "Catatan Kondisi Motor", key: "motorcycleConditionDes", width: 20 },
                    { header: "Kondisi Delivery Man", key: "delimanCondition", width: 20 },
                    { header: "Catatan Kondisi Delivery Man", key: "delimanConditionDesc", width: 20 },
                    { header: "Alasan Lokasi Tidak Sesuai", key: "checkinNote", width: 20 },
                    { header: "Catatan Kunjungan", key: "checkoutNote", width: 20 }
                ]
                const creator = 'Ipp Application'
                const createdDate = creator
                const modifiedDate = new Date()
                const sheetName = 'sheet 1'
                const {
                    monthOfEndDateFilter,
                    monthOfStartDateFilter,
                    yearOfEndDateFilter,
                    yearOfStartDateFilter
                } = generateFileNameFromDate(isTable.startDate, isTable.endDate)
                const fileName = `Hasil Rencana Kerja Bulanan SPV ${yearOfStartDateFilter}${yearOfEndDateFilter}-${monthOfStartDateFilter}${monthOfEndDateFilter}_${moment(new Date()).format("YYYY-MM-DD")}`;
                const buffer = await DownloadExcel({ creator, createdDate, modifiedDate, sheetName, columns, contentRow });

                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                const fileExtension = ".xlsx";
                const blob = new Blob([buffer], { type: fileType });
                saveAs(blob, fileName + fileExtension);
            })
            .catch(() => {
                setLoadingExport('rejected')
            })
    }
    const [isFilterActive, setIsFilterActive] = useState(false)

    const checkFilterValue = (value) => {
        const result = [true, false].includes(value)
        return result
    }
    useEffect (()=>{
        if(isTable.endDate || isTable.clockinStatus || isTable.clockoutStatus || checkFilterValue(isTable.mobileDeviceCondition) || checkFilterValue(isTable.motorcycleCondition) || checkFilterValue(isTable.delimanCondition)){
            setIsFilterActive(true)
        } else {
            setIsFilterActive(false)
        }
        
    },[isTable])

    return (
        <>
            <Segment mb={32} pb={16}>
                <Segment
                    className="group-filter"
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Segment flex="1" width={"50%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => handleSearch(e),
                                    placeholder: "Cari Supervisor Delivery",
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment display="flex" justifyContent={"flex-end"} width={"50%"} className={"fw500"}>
                        <Segment maxWidth={200} mr={20}>
                            <FormControl>
                                <Button spinner={loadingExport === "pending"} style={{ borderRadius: "16px" }} variant="secondary-alt" disabled={loadingExport === 'pending'} onClick={handleDownloadRiwayat}>
                                    Export Xls
                                    <Segment ml={16}>
                                        <img src={IconXls} alt="xls"/>
                                    </Segment>
                                </Button>
                            </FormControl>
                        </Segment>
                        <Segment maxWidth={200} className={"fw500"}>
                            <FormControl>
                                <Button className={isFilterActive ? "filter-active" : ""}spinner={loadingExport === "pending"} style={{ borderRadius: "16px" }} borderRadius={16} variant="secondary-alt" disabled={loadingExport === 'pending'} onClick={(e) => setModal({ name: 'filter-modal' })}>
                                    Filter
                                    <Segment ml={16}>
                                        <img src={IconFilter} alt="filter"/>
                                    </Segment>
                                    {isFilterActive && <span className="icon-rounded"></span>}
                                </Button>
                            </FormControl>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loading}
                    defaultSize={isTable.size}
                    totalPages={payload?.totalPages}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            rowField: "date",
                            isCustomRow: (id, { date }) => {
                                return moment(date).format('DD/MM/YY')
                            }
                        },
                        {
                            field: "Supervisor Delivery",
                            rowField: "supervisorNik",
                            isCustomRow: (id, { supervisorNik, supervisorName }) => {
                                return (supervisorName && supervisorNik) ? `${supervisorNik} - ${supervisorName}` : '-'
                            }
                        },
                        {
                            field: "Kode Toko",
                            rowField: "storeCode",
                            isCustomRow: (id, { storeCode, storeName }) => {
                                return (storeCode && storeName) ? `${storeCode} - ${storeName}` : '-'
                            }
                        },
                        {
                            field: "Lokasi Clockin",
                            isCustomRow: (_, { checkinStatusInfo }) => {
                                const getLabel = props.optionsLocation.find(val => val.value === checkinStatusInfo)

                                return <span style={{ textTransform: "capitalize" }}> {getLabel ? getLabel.label : '-'}</span>
                            }
                        },
                        {
                            field: "Waktu Clock In",
                            isCustomRow: (_, { checkinTime }) => {
                                return checkinTime ? `${moment(checkinTime).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Lokasi Clock Out",
                            isCustomRow: (_, { checkoutStatusInfo }) => {
                                const getLabel = props.optionsLocation.find(val => val.value === checkoutStatusInfo)

                                return <span style={{ textTransform: "capitalize" }}> {getLabel ? getLabel.label : '-'}</span>
                            }
                        },
                        {
                            field: "Waktu Clock Out",
                            isCustomRow: (_, { checkoutTime }) => {
                                return checkoutTime ? `${moment(checkoutTime).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Kondisi HP",
                            isCustomRow: (_, { mobileDeviceCondition }) => {
                                return mobileDeviceCondition ? 'Ok' : "Not Ok"
                            }
                        },
                        {
                            field: "Kondisi Motor",
                            isCustomRow: (_, { motorcycleCondition }) => {
                                return motorcycleCondition ? 'Ok' : "Not Ok"
                            }
                        },
                        {
                            field: "Kondisi Delivery Man",
                            isCustomRow: (_, { delimanCondition }) => {
                                return delimanCondition ? 'Ok' : "Not Ok"
                            }
                        },
                        {
                            field: "Catatan",
                            isCustomRow: (_, { checkinStatusInfo, checkoutStatusInfo, ...entity }) => {
                                const statusCheckIn = checkinStatusInfo === 'tidak-sesuai' ? true : false
                                const statusCheckOut = checkoutStatusInfo === 'tidak-sesuai' ? true : false
                                const lihat = statusCheckIn || statusCheckOut
                                const property = btnActive(lihat);

                                return (
                                    <Button style={{ backgroundColor: property.bg, ...property.based }}
                                        onClick={(e) => !lihat ? e.preventDefault : setIsModalNotes({ show: !isModalNotes.show, data: entity })}
                                    >
                                        Lihat
                                    </Button>
                                );
                            },
                        },
                    ]}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {

                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>

            {/* modal show notes */}
            <ModalNotes
                isOpen={isModalNotes.show}
                onClose={() => setIsModalNotes({ show: !isModalNotes, data: null })}
                title={"Informasi Jadwal"}
                content={<Segment py={8} style={{ lineHeight: "21px" }}>
                    <Segment mb={16}>
                        <Text color={'#151823'} fontWeight="500">
                            Alasan Lokasi Tidak Sesuai
                        </Text>
                        {isModalNotes.data?.checkinNote ?? '-'}
                    </Segment>
                    <Segment mb={16}>
                        <Text color={'#151823'} fontWeight="500">
                            Catatan Kunjungan
                        </Text>
                        {isModalNotes.data?.checkoutNote ?? '-'}
                    </Segment>
                    <Segment mb={16}>
                        <Text color={'#151823'} fontWeight="500">
                            Kondisi HP
                        </Text>
                        {isModalNotes.data?.mobileDeviceCondition ? '-' : isModalNotes.data?.mobileDeviceConditionDesc}
                    </Segment>
                    <Segment mb={16}>
                        <Text color={'#151823'} fontWeight="500">
                            Kondisi Motor
                        </Text>
                        {isModalNotes.data?.motorcycleCondition ? '-' : isModalNotes.data?.motorcycleConditionDes}
                    </Segment>
                    <Segment mb={16}>
                        <Text color={'#151823'} fontWeight="500">
                            Kondisi Delivery Man
                        </Text>
                        {isModalNotes.data?.delimanCondition ? '-' : isModalNotes.data?.delimanConditionDesc}
                    </Segment>
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModalNotes({ show: !isModalNotes, data: null })}>
                                Tutup
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
            {modal?.name === 'filter-modal' &&
                <FilterModal
                    actionFilter={setTable}
                    loading={payload.loading}
                    paramsFilter={isTable}
                    isOpen={modal?.name === 'filter-modal'}
                    onClose={() => setModal(null)}
                />
            }
        </>
    );
};

export default TabRiwayat;
