export const requestReleaseLocking = (parameter) => {
    return {
        type: "REQUEST_RELEASE_LOCKING",
        payload: { parameter }
    }
}
export const requestReleaseLockingInterval = () => {
    return {
        type: "REQUEST_RELEASE_LOCKING_INTERVAL",
        
    }
}
export const requestReleaseLockingHistory = (parameter) => {
    return {
        type: "REQUEST_RELEASE_LOCKING_HISTORY",
        payload: { parameter }
    }
}
export const requestOrderListByIdDeliman = data => {
    return {
        type: "REQUEST_ORDER_LIST_BY_ID_DELIMAN",
        payload: { data }
    }
}
  
export const requestSubmitReleaseLocking = (data,history) => {
    return {
        type: "REQUEST_SUBMIT_RELEASE_LOCKING",
        payload: { data },
        history
    }
} 

export const requestApprovalReleaseLocking = (data, history) => {
    return {
        type: "REQUEST_APPROVAL_RELEASE_LOCKING",
        payload: { data },
        history
    }
} 

export const requestListDuration = () => {
    return {
        type: "REQUEST_DURATION_RELEASE_LOCKING",
        
    }
} 

export const requestDismissError = () => {
    return {
        type: "REQUEST_DISMISS_ERROR_RELEASE_LOCKING",
        
    }
} 

export const requestReleaseLockingApproval = (parameter) => {
    return {
        type: "REQUEST_RELEASE_LOCKING_APPROVAL",
        payload: { parameter }
    }
}

export const requestReleaseLockingApprovalInterval = (parameter) => {
    return {
        type: "REQUEST_RELEASE_LOCKING_APPROVAL_INTERVAL",
        payload: { parameter }
    }
}

export const requestReleaseLockingApprovalHistory = (parameter) => {
    return {
        type: "REQUEST_RELEASE_LOCKING_APPROVAL_HISTORY",
        payload: { parameter }
    }
}

export const requestSubmitReleaseLockingApproval = (setujui, requestId, refetchList) => {
    return {
        type: "REQUEST_SUBMIT_RELEASE_LOCKING_APPROVAL",
        payload: { setujui, requestId, refetchList }
    }
}
