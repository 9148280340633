import React, { useState, useEffect } from "react"
import {
  Segment,
  Text,
  FormControl,
  Icon,
  Textfield,
  OptionBox,
  Badge,
  Button
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from "component/Breadcrumb"
import DataTable from "component/DataTable"
import { Link } from 'react-router-dom'
import DatePicker from "@elevenia/master-ui/components/Atom/DatePicker"
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestDeliverymanCadangan } from 'store/actions/deliverymanCadangan';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Error404 from 'page/errorLayout'
import moment from 'moment'
const DelivManCadangan = (props) => {
  document.title = props.title;

  const [filterDate, setFilterDate] = useState({
    startDate: moment().startOf('month').toDate(),
    endDate:null
  })
  const dataBreadcrumb = [{ to: "", label: "Delivery Man Cadangan" }];
  const { hasFetch } = useAction();
  const [status, hasStatus] = useState("hasLoad")
  const [isTable, setTable] = useState({
      page: 0,
      size: 10,
      keyword: "",
      status:'Aktif',
      startDate: moment().startOf('month').format('DD-MM-YYYY')
  });
  const payload = useSelector(state => {
      return {
          data: state.deliverymanCadangan.data,
          loading: state.deliverymanCadangan.loading,
          totalPages: state.deliverymanCadangan.totalPages,
          parameter: state.deliverymanCadangan.parameter,
          statName: state.setAlertsToast.notify.statName,
      }
  })
  
  useEffect(() => {

      status === "hasLoad" && props.access?.read && hasFetch(requestDeliverymanCadangan(isTable))
      status === "hasPagination" && props.access?.read &&  hasFetch(requestDeliverymanCadangan(isTable))
  }, [hasFetch, isTable, status,  props.access])

  const handleFilterDate = (name, value) => {
    
    setTable(prev=>{
        return{
            ...prev,
            ...payload.parameter,
            [name]:value?moment(value).format('DD-MM-YYYY'):'',
        }
    })
    setFilterDate({
      ...filterDate,
      [name]:value??null
    })
  }

  const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => hasFetch(requestDeliverymanCadangan(value)))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [hasFetch, onSearch$])

  const handleSearch = e => {
    let value = e.target.value;
    onSearch$.next({
        ...isTable,
        keyword: value.trim()
    });
}
  

  const statusColor = (status) => {
    switch (status) {
      case "Non-Aktif":
        return {
          bg: "#EE2B2E",
          color: "white",
          border: "#EE2B2E",
          text: "Non-Aktif",
        };
      case "Aktif":
        return {
          bg: "#8FC742",
          color: "white",
          border: "#8FC742",
          text: "Aktif",
        };
      case "Expired":
        return {
          bg: "#70727D",
          color: "white",
          border: "#70727D",
          text: "Expired",
        };
      default:
        return {
          bg: "white",
          color: "#151823",
          border: "#DCDEE3",
          text: status,
        };
    }
  };

  if (!props.access) return <></>
  else if (!props.access?.read) return <Error404 />

  return (
    <>
      <Segment justifyContent={"space-between"} className="xs-flex-wrap">
        <Segment mb={30}>
          <Text H28 mb={"1rem"}>
            Delivery Man Cadangan
          </Text>
          <Breadcrumb data={dataBreadcrumb} />
        </Segment>
        <Segment>
          <Link to={"/deliveryman-cadangan/create"}>
            <Button>
              <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
              Buat Baru
            </Button>
          </Link>
        </Segment>
      </Segment>
      <Segment mb={32} pb={16}>
        <Segment
          className="group-filter"
          boxShadow={"0 1px 4px 0 #70727d33"}
          borderRadius={4}
          bg={"white"}
          p={20}
          borderBottom={"1px solid #DCDEE3"}
          mb={24}
          display="flex"
          alignItems={"center"}
        >
          <Segment maxWidth={468} width={"100%"} className={"fw500"}>
            <FormControl>
              <Textfield
                inputProps={{
                  onChange: e => handleSearch(e),
                  placeholder: "Cari NIK, Nama atau Kode Klaster",
                }}
                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
              />
            </FormControl>
          </Segment>
          <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
            <FormControl>
              <DatePicker
                placeholderText={"Tanggal Awal"}
                selected={filterDate.startDate}
                onChange={(date) => handleFilterDate('startDate',date)}
                selectsStart
                startDate={filterDate.startDate}
                endDate={filterDate.endDate}
                right={<Icon name={"date"} size={24} />}
              />
            </FormControl>
          </Segment>
          {/* separator */}
          <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
          {/* separator */}
          <Segment maxWidth={200} width={"100%"} className={"fw500"}>
            <FormControl>
              <DatePicker
                placeholderText={"Tanggal Akhir"}
                selected={filterDate.endDate}
                onChange={(date) => handleFilterDate('endDate',date)}
                selectsEnd
                startDate={filterDate.startDate}
                endDate={filterDate.endDate}
                minDate={filterDate.startDate}
                right={<Icon name={"date"} size={24} />}
              />
            </FormControl>
          </Segment>
          <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
            <FormControl>
              <OptionBox
                placeholder={"Status"}
                isClearable
                onChange={(e) => {
                    let value = ''
                    if(e){
                      value = e.value
                    }
                    
                    setTable(prev => ({
                        ...prev,
                        ...payload.parameter,
                        status: value
                    }))

                }}
                defaultValue={{value:isTable.status,label:isTable.status}}
                isDisabled={payload.loading}
                options={[
                  { value: "Aktif", label: "Aktif" },
                  { value: "Non-Aktif", label: "Non-Aktif" },
                  { value: "Expired", label: "Expired" }
                ]}
              />
            </FormControl>
          </Segment>
        </Segment>
        <DataTable
          countingList={true}
          isLoading={payload.loading}
          defaultSize={isTable.size}
          tableConsume={[
            {
              field: "ID Penugasan",
              isCustomRow: (id, entity) => {
                return <><Link to={`/deliveryman-cadangan/${entity.id}`}>{entity.taskId}</Link></>;
              },
            },
            {
              field: "NIK Delivery Man",
              rowField: "nik",
            },
            {
              field: "Nama",
              rowField: "name"
            },
            {
              field: "Kode Klaster",
              rowField:"clusterCode"
            },
            {
              field: "Nama Klaster",
              rowField: "clusterName"
            },
            {
              field: "Tanggal",
              isCustomRow: (id, entity) => {
                return <>{moment(entity.assignmentStartDate).format('DD/MM/YY')} - {moment(entity.assignmentEndDate).format('DD/MM/YY')}</>;
              },
            },
            {
              field: "Status",
              isCustomRow: (id, entity) => {
                const pallet = statusColor(entity.status);

                return (
                  <Badge
                    style={{
                      border: `1px solid ${pallet.border}`,
                      borderRadius: 4,
                    }}
                    bg={pallet.bg}
                    color={pallet.color}
                  >
                    {pallet.text}
                  </Badge>
                );
              },
              width: "12%",
            },
          ]}
          dataConsume={payload.data}
          showSize={true}
          showPagination={true}
          showSearch={false}
          totalPages={payload.totalPages}
          createShowPerSize={(size) => setTable(prev => {
              return {
                  ...prev,
                  ...payload.parameter,
                  size: size.value
              }
          })}
          createPaginations={(page) => {
              const query = {
                  ...payload.parameter,
                  ...page
              }
              hasStatus('hasPagination')
              setTable(query)
          }}
        />
      </Segment>
    </>
  );
};

export default DelivManCadangan;
