export const requestAddManager = (params,history) =>{
    return {
        type: "REQUEST_CREATE_MANAGER",
        payload:{
          params
        },
        history
    }
  }
  
  export const requestUpdateManager = (id,params,history) =>{
    return {
        type: "REQUEST_UPDATE_MANAGER",
        payload:{
          params,
          id
        },
        history
    }
  }
  
  export const requestCheckNik = (params)=> {
      return {
          type: "REQUEST_CHECK_NIK_MANAGER",
          payload:{
            params
          }
      }
    }
    
    export const requestCheckEmail = (params)=> {
      return {
          type: "REQUEST_CHECK_EMAIL_MANAGER",
          payload:{
            params
          }
      }
    }
    
    export const requestCheckPhone = (params)=> {
      return {
          type: "REQUEST_CHECK_PHONE_MANAGER",
          payload:{
            params
          }
      }
    }
    
    export const resetInputStatus = (name) => {
      return {
          type: "RESET_INPUT_STATUS_MANAGER",
          payload:{
            field:name
          }
      }
    }
  
    export const setStatusField = (status) => {
      return {
          type: "SET_STATUS_FIELD_MANAGER",
          payload:{
            status:status?'00':''
          }
      }
    }