import React, { lazy, Suspense } from 'react'
import { render } from 'react-dom'
import * as serviceWorker from 'serviceWorker'
import { ThemeProvider } from 'styled-components'
import ThemeDesklab from 'assets/Theme'
import GlobalStyles from 'assets/Theme/globalStyles'
import 'assets/Theme/index.css'
import 'assets/css/default.css'
import store from 'store'
import { Provider } from 'react-redux'
import LazyLoad from 'layout/DefaultLayout/LazyLoad'
const App = lazy(() => import('App'))
render(
    <Suspense fallback={<LazyLoad />}>
        <ThemeProvider theme={ThemeDesklab}>
            <Provider store={store}>
                <GlobalStyles />
                <App />
            </Provider>
        </ThemeProvider>
    </Suspense>,
    document.getElementById('root'),
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
