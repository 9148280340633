import React, { useState } from "react";
import {
    Segment,
    Text,
    Button,
    FormControl,
    OptionBox
} from '@elevenia/master-ui/components/Atom';
import useSWR from 'swr'
import { fetcher } from 'helper';
import { Services } from 'service';
import { useDispatch } from 'react-redux';
import { useAction } from 'hooks';
import { useParams } from 'react-router-dom'
import { requestOrderListById } from 'store/actions/orderList'
const PindahToko = ({ detailOrder }) => {
    const dispatch = useDispatch()
    const { hasFetch } = useAction();
    const { id } = useParams()
    const [storeCode, setStoreCode] = useState(null)
    const [loadingConfirm, setLoadingConfirm] = useState('idle')
    const optionsSwr = {
        revalidateOnFocus: false
    }
    const { data: dataStore, error: errorDataStore } = useSWR(`/store/api/back-office/stores/${detailOrder?.channelOrder}/available?clusterCode=${detailOrder?.clusterCode}`, fetcher, optionsSwr)
    
    const handleConfirmation = () => {
        if(storeCode){
            setLoadingConfirm('pending')
            Services().post(`/orderprocessor/api/back-office/orders/assign-store`,{
                orderId: detailOrder?.orderId,
                storeCode: storeCode
            })
            .then(()=>{
                setLoadingConfirm('resolved')
                dispatch({
                    type:'ALERT_TOAST_SUCCESS',
                    payload:{
                        message: "Berhasil"
                    }
                })
                hasFetch(requestOrderListById(id))
            })
            .catch(e=>{
                setLoadingConfirm('rejected')
                dispatch({
                    type:'ALERT_TOAST_ERROR',
                    payload:{
                        message: e.message
                    }
                })
                
            })
        }
    }
    return <>
        <Segment boxShadow borderRadius={4} bg={"white"} mt={24}>
            <Segment p={24}>
                <Segment display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Text fontWeight={600} mb={32}>Pindah Toko/Area Coverage</Text>
                </Segment>
                <Segment display="flex" className="xs-flex-wrap">
                    <Segment width={280} py={8}>
                        <Text>Ganti Toko</Text>
                    </Segment>
                    <Segment flex={1}>
                        <FormControl>
                            <OptionBox
                                options={dataStore?.data?.content?.length > 0 ? dataStore?.data?.content?.map(obj => { return { label: `${obj?.storeCode} - ${obj?.storeName}`, value: obj?.storeCode } }) : []}
                                placeholder={"Pilih Toko"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value) value = e.value

                                    setStoreCode(value);
                                    

                                }}
                                isDisabled={!dataStore && !errorDataStore}
                            />
                        </FormControl>
                    </Segment>
                </Segment>
            </Segment>
            <Segment justifyContent={"flex-end"} borderTop="1px solid #DCDEE3" p={24}>
                <Button
                    size="medium"
                    type={"button"}
                    disabled={!storeCode || loadingConfirm==='pending'}
                    onClick={handleConfirmation}
                >
                    Konfirmasi
                </Button>
            </Segment>
        </Segment>
    </>
}

export default PindahToko;