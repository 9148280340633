import React from 'react';
import { Segment, Text } from '@elevenia/master-ui/components/Atom';
import { capitalize, formatRupiahCommas } from 'helper';

const CustomBadge = ({ children, ...props }) => {
    return (
        <Segment
            {...props}
            display={'inline-block'}
            mr={8}
            color={'white'}
            borderRadius={12}
            py={6}
            px={8}
            style={{ fontSize: '12px' }}
        >
            {children}
        </Segment>
    );
};

const GeneralInfo = ({ detailData }) => {
    return (
        <>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Order ID</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.orderId}
                </Segment>
            </Segment>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>AWB</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.awbId}
                </Segment>
            </Segment>
            {['SAMEDAY', 'REGULAR-PAKET'].includes(detailData.serviceType) ? (
                ['PAKET-EXPRESS', 'PAKET', 'PAKET-JEMPUT', 'PJB'].includes(
                    detailData.orderType
                ) ? (
                    <Segment mb={24} display='flex' className='xs-flex-wrap'>
                        <Segment minWidth={280}>
                            <Text color={'#70727D'}>TrackNum (awbippid)</Text>
                        </Segment>
                        <Segment display='flex'>
                            <Text mr={8}>:</Text>
                            {detailData?.awbIppId ?? '-'}
                        </Segment>
                    </Segment>
                ) : (
                    ''
                )
            ) : (
                ''
            )}

            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Sales Order</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.salesOrderId}
                </Segment>
            </Segment>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Channel</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.channelOrder
                        ? capitalize(detailData?.channelOrder)
                        : '-'}
                </Segment>
            </Segment>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Tipe Order</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.orderType ?? '-'}
                </Segment>
            </Segment>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Diangkut Dengan</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    {detailData?.vehicleType
                        ? capitalize(detailData?.vehicleType)
                        : '-'}
                </Segment>
            </Segment>
            <Segment mb={24} display='flex' className='xs-flex-wrap'>
                <Segment minWidth={280}>
                    <Text color={'#70727D'}>Kategori/Service</Text>
                </Segment>
                <Segment display='flex'>
                    <Text mr={8}>:</Text>
                    <Segment>
                        <Segment>
                            <CustomBadge bg={'green20'}>
                                {detailData?.serviceType &&
                                    detailData?.serviceType?.toUpperCase()}
                            </CustomBadge>
                            {detailData?.cod && (
                                <CustomBadge bg={'#1178D4'}>COD</CustomBadge>
                            )}
                            {detailData?.bulky && (
                                <CustomBadge bg={'#FFA800'}>Bulky</CustomBadge>
                            )}
                            {detailData?.orderReturn && (
                                <CustomBadge bg={'#FD474A'}>Retur</CustomBadge>
                            )}
                        </Segment>
                        <Segment mt={16}>
                            {(detailData?.cod || detailData?.bulky) && (
                                <ul>
                                    {detailData?.bulky &&
                                        detailData?.item?.map((val, index) => (
                                            <li key={index}>
                                                - {val.quantity} {val.itemName}{' '}
                                            </li>
                                        ))}

                                    {detailData?.cod && (
                                        <li>
                                            - Uang Tunai{' '}
                                            {formatRupiahCommas(
                                                detailData?.codAmount,
                                                'Rp. '
                                            )}
                                        </li>
                                    )}
                                </ul>
                            )}
                        </Segment>
                    </Segment>
                </Segment>
            </Segment>
        </>
    );
};

export default GeneralInfo;
