import {
    Badge, Button, ButtonGroup, FormControl,
    Icon, OptionBox, Segment,
    Text, Textfield
} from "@elevenia/master-ui/components/Atom"
import Breadcrumb from 'component/Breadcrumb'
import DataTable from 'component/DataTable'
import ModalDelete from 'component/ModalCustom/modalSmall'
import { saveAs } from 'file-saver'
import { capitalize, fetcher, objectToQueryString, statusColor } from 'helper'
import DownloadExcel from 'helper/DownloadExcel'
import { useAction } from "hooks"
import moment from 'moment'
import Error404 from 'page/errorLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { Services } from 'service'
import { setStatusField } from 'store/actions/deliveryman'
import useSWR from 'swr'
const dataBreadcrumb = [
    { to: '', label: 'Delivery Man' }
]


const DeliveryMan = props => {
    document.title = props.title
    const { hasFetch } = useAction()
    const IconXls = require("assets/image/icon-xls.svg");
    const [isModal, setIsModal] = useState(false)
    const [statusDownload, setStatusDownload] = useState("idle")
    
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        keyword: "",
        sort: "name,asc",
        status:'active'
    });

    const optionsSwr = {
        revalidateOnFocus:false
    }
    const { data:dataDeliman, error } = useSWR((isTable && props.access?.read) &&  `/driver/api/back-office/list?${objectToQueryString(isTable)}`,fetcher,optionsSwr)

    useEffect(()=>{
        props.access?.read && hasFetch(setStatusField())
    },[hasFetch, props.access])
    useEffect(()=>{
        if(error){
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message } })
        }
    },[hasFetch, error])

    const payload = useSelector(state => {
        return {
            data: dataDeliman?.data.result.content,
            loading: !dataDeliman && !error,
            totalPages: dataDeliman?.data.result.totalPages,
            statName: state.setAlertsToast.notify.statName,
            statuses: dataDeliman?.data?.statusList?.map(val => ({ label: val === 'active' ? 'Aktif' : 'Non-Aktif', value: val })),
            clusters: dataDeliman?.data?.clusters?.map(val => ({ label: val, value: val })),
            vehicleList: dataDeliman?.data?.vehicleList?.map(val => ({ label: capitalize(val) , value: val })),
            companyList: dataDeliman?.data?.company?.map(val => ({ label: capitalize(val) , value: val }))
        }
    })
    
    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        
        onSearch$.next({
            ...isTable,
            page:0,
            keyword: value.trim()
        });
    }

    const listVerifikasi = [

        { value: "2", label: "Terverifikasi" },
        { value: "1", label: "Belum Verifikasi" },
    ];


    const handleDownloadExcel = async () => {
        
        setStatusDownload('pending')
        Services().get('/driver/api/back-office/list',{...isTable,unpaged:true})
        .then( async (data)=>{
            setStatusDownload('resolved')
            const contentRow = data.data.data.result.content?.map((value,key)=>{
                
                return {
                    ...value,
                    no: key + 1,
                    totalStore: value.totalStore??0,
                    totalCluster: value.totalCluster??0,
                    verified: value.verified ? 'Terverifikasi' : 'Belum Verifikasi',
                    status: value.status==='active' ? 'Aktif' : 'Non-Aktif',
                    vehicleType: value.vehicleType ? capitalize(value.vehicleType) : '-',
                    name: value.name ? value.name : "-",
                    email: value.email ? value.email : "-",
                    licensePlate: value.licensePlate ? value.licensePlate : "-",
                    listCluster: value.clusters.length > 0 ? value.clusters.map(val=>val.code).join(", ") : "-",
                    listStore: value.stores.length > 0 ? value.stores.map(val=>val.storeCode??"-").join(", ") : "-",
                    company: value.company ? value.company : "-",
                    storeBase: value.homeBaseStore ? `${value.homeBaseStore.storeCode} - ${value.homeBaseStore.storeName} (${value.homeBaseStore.channel})` : '-'
                }
            });
            const columns = [
                { header: "No", key: "no", width: 5 },
                { header: "NIK", key: "nik", width: 19 },
                { header: "Nama", key: "name", width: 19 },
                { header: "Email", key: "email", width: 19 },
                { header: "Nomor Handphone", key: "phone", width: 19 },
                { header: "Klaster", key: "listCluster", width: 19 },
                { header: "Toko", key: "listStore", width: 19 },
                { header: "Toko Homebase", key: "storeBase", width: 19 },
                { header: "Tipe Pegawai", key: "employmentType", width: 15 },
                { header: "Perusahaan", key: "company", width: 20 },
                { header: "Verifikasi", key: "verified", width: 15 },
                { header: "Kendaraan", key: "vehicleType", width: 20 },
                { header: "Plat Nomor Kendaraan", key: "licensePlate", width: 19 },
                { header: "Status", key: "status", width: 20 }
            ]
            const creator       = 'Ipp Application'
            const createdDate   = creator
            const modifiedDate  = new Date()
            const sheetName     = 'sheet 1'
            const fileName      = `Master Delivery Man ${moment(new Date()).format("YYYY-MM-DD")}`;
            const buffer        = await DownloadExcel({creator, createdDate, modifiedDate, sheetName, columns, contentRow});
            
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, fileName + fileExtension);
            
        })
        .catch(e=>{
            setStatusDownload('rejected')
        })
        
         
    }

    
    

    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />
    //else if (error) return <Error404 />
    return (
        <>
            <Segment justifyContent={"space-between"} className="xs-flex-wrap">
                <Segment mb={30}>
                    <Text H28 mb={"1rem"}>Delivery Man</Text>
                    <Breadcrumb data={dataBreadcrumb} />
                </Segment>
                <Segment>
                    <Button disabled={statusDownload==="pending" ? true : false} onClick={()=>handleDownloadExcel()} mr={16} variant="secondary">
                        <img src={IconXls} alt="xls" style={{marginRight: 8}}/>
                        Export XLS
                    </Button>
                    <Link to={"/delivery-man/create"}>
                        <Button>
                            <Icon name={"plus"} size={22} fillColor={"white"} mr={8} />
                            Buat Baru
                        </Button>
                    </Link>
                </Segment>
            </Segment>
            <Segment mb={32} pb={16}>
                <Segment className="group-filter" boxShadow={"0 1px 4px 0 #70727d33"} borderRadius={4} bg={"white"} p={20} borderBottom={"1px solid #DCDEE3"} mb={24} display="flex">
                    <Segment maxWidth={468} width={"100%"} className={"fw500"}>
                        <FormControl >
                            <Textfield
                                inputProps={{
                                    onChange: e => handleSearch(e),
                                    placeholder: 'Cari Nama atau NIK'
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl >
                            <OptionBox
                                defaultValue={""}
                                options={payload.vehicleList}
                                placeholder={"Jenis Kendaraan"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value) value = e.value
                                    setTable(prev => ({
                                        ...prev,
                                        ...isTable,
                                        page:0,
                                        vehicleType: value
                                    }))

                                }}
                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl >
                            <OptionBox
                                defaultValue={""}
                                options={payload.companyList}
                                placeholder={"Perusahaan"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value) value = e.value
                                    setTable(prev => ({
                                        ...prev,
                                        ...isTable,
                                        page:0,
                                        company: value
                                    }))

                                }}
                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        <FormControl >
                            <OptionBox
                                defaultValue={""}
                                options={listVerifikasi}
                                placeholder={"Verifikasi"}
                                isClearable
                                onChange={(e) => {
                                    let value = ''
                                    if (e && e.value === '1') value = false
                                    if (e && e.value === '2') value = true
                                    setTable(prev => ({
                                        ...prev,
                                        ...isTable,
                                        verified: value
                                    }))

                                }}
                                isDisabled={payload.loading}
                            />
                        </FormControl>
                    </Segment>
                    <Segment pl={20} maxWidth={214} width={"100%"} className={"fw500"}>
                        
                    {
                        !payload.loading && 
                        <FormControl>
                                <OptionBox
                                    defaultValue={payload.statuses?.filter(val=>val.value===isTable.status)[0]}

                                    options={payload.statuses}
                                    placeholder={"Status"}
                                    isClearable
                                    onChange={(e) => {
                                        let value = ''
                                        if (e && e.value) value = e.value
                                        setTable(prev => ({
                                            ...prev,
                                            ...isTable,
                                            status: value
                                        }))

                                    }}
                                    isDisabled={payload.loading}
                                />
                        </FormControl>
                    }
                            
                        
                    </Segment>
                </Segment>
                <DataTable
                    defaultSortActive={isTable.sort}
                    countingList={true}
                    defaultSize={isTable.size}
                    tableConsume={[{
                        field: 'NIK Delivery Man',
                        sortField: 'nik',
                        isCustomRow: (id, entity) => {
                            return (
                                <Link to={`/delivery-man/${entity.id}`}>{entity.nik}</Link>
                            )
                        }
                    }, {
                        field: 'Nama',
                        rowField: 'name',
                        sortField: 'name'
                    }, {
                        field: 'Klaster',
                        isCustomRow: (id, entity) => {
                            return entity.totalCluster ? entity.totalCluster : 0  
                        },
                    }, {
                        field: 'Toko',
                        isCustomRow: (id, entity) => {
                            return entity.totalStore ? entity.totalStore : 0  
                        },
                    },{
                        field: 'Toko Homebase',
                        isCustomRow: (id, entity) => {
                            return entity.homeBaseStore ? `${entity.homeBaseStore.storeCode}` : '-'  
                        },
                    }, {
                        field: 'Tipe Pegawai',
                        rowField: 'employmentType',
                        //sortField:'employmentType',
                    }, {
                        field: 'Perusahaan',
                        rowField:'company',
                        isCustomRow: (id, entity) => {
                            return entity.company ? capitalize(entity.company) : '-'
                        }
                    },{
                        field: 'Kendaraan',
                        rowField:'vehicleType',
                        isCustomRow: (id, entity) => {
                            return entity.vehicleType ? capitalize(entity.vehicleType) : '-'
                        }
                    }, {
                        field: 'Verifikasi',
                        sortField: 'verified',
                        isCustomRow: (id, entity) => {
                            return entity.verified ? 'Terverifikasi' : 'Belum Verifikasi'
                        }
                    },{
                        field: 'Status',
                        rowField: 'status',
                        sortField: 'status',
                        isCustomRow: (id, entity) => {
                            const pallet = statusColor(entity.status)
                            
                            return (
                                <Badge
                                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                    bg={pallet.bg}
                                    color={pallet.color}
                                >
                                    {pallet.text}
                                </Badge>
                            )
                        }
                    },{
                        field: 'Dihapus',
                        rowField: 'deleted',
                        sortField: 'deleted',
                        isCustomRow: (id, entity) => {
                            const pallet = entity.deleted ? statusColor("deleted") : null
                            
                            return (
                                pallet!==null ?  <Badge
                                    style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                    bg={pallet.bg}
                                    color={pallet.color}
                                >
                                    {pallet.text}
                                </Badge> : '-'
                            )
                        },
                        width: "25%"
                    }]}
                    dataConsume={payload.data}
                    isLoading={payload.loading}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    activePage={isTable.page}
                    totalPages={payload.totalPages}
                    callSorted={(name, direction) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            sort: `${name},${direction}`
                        }
                    })}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            ...isTable,
                            page: 0,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => {
                        const query = {
                            ...isTable,
                            ...page
                        }
                        
                        setTable(query)
                    }}
                />
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={"Hapus Delivery Many?"}
                content={<Segment py={8}>Semua informasi mengenai Delivery Man ini akan dihapus dari sistem</Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)}>
                                Tidak
                            </Button>
                            <Button minWidth={"100px"} variant={"secondary"}>
                                Ya
                            </Button>
                        </ButtonGroup>
                    )
                }
            />
        </>
    )
}

export default DeliveryMan