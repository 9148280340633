import React, { useState, useEffect, lazy } from 'react'
import {
    Segment,
    Text,
    Button,
    ButtonGroup,
    Spinner,
    Badge
} from '@elevenia/master-ui/components/Atom'
import { Tabs } from "@elevenia/master-ui/components/Molecules"
import ModalDelete from 'component/ModalCustom/modalSmall'
import { Link } from 'react-router-dom'
import Breadcrumb from 'component/Breadcrumb'
import { useAction } from 'hooks';
import { requestDeliveryManById, requestAddDeliveryMan, requestPhotoProfile, resetPhotoProfile } from 'store/actions/deliveryman'
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/id';
import Error404 from 'page/errorLayout'
import { capitalize, statusColor } from 'helper'
import TabKlaster from './_component/TabKlaster'
import TabToko from './_component/TabToko'
import { Services } from 'service'
import TabRiwayatNik from './_component/TabRiwayatNik'
const ModalInfo = lazy(() => import('component/ModalCustom/modalMedium'))

const DeliveryManDetail = props => {
    document.title = props.title
    const id = props.match.params.id;
    const { hasFetch } = useAction();
    const [isModal, setIsModal] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false);
    const [activeTab, setActiveTab] = useState("klaster");
    const history = props.history
    const title = id ? 'Detail Delivery Man' : 'Buat Baru'
    const dataBreadcrumb = [
        { to: '/delivery-man', label: 'Delivery Man' },
        { to: '', label: title }
    ]

    useEffect(() => {
        (id !== undefined && id !== 'preview') && props.access?.read && hasFetch(requestDeliveryManById(id))
    }, [hasFetch, id, props.access])

    const payload = useSelector(state => {
        return {
            detailData: state.deliveryman.detailData,
            previewData: props.location?.state,
            loadingGetImage: state.deliveryman.loadingGetImage,
            dataImage: state.deliveryman.dataImage,
            openModal: state.deliveryman.openModal
        };
    });

    const [loadingDelete, setLoadingDelete] = useState('idle')
    const handleActionYes = async () => {
        if (id === undefined) {
            const dataAdd = {
                email: payload.previewData.email,
                name: payload.previewData.name,
                nik: payload.previewData.nik,
                phone: payload.previewData.phone,
                employmentType: payload.previewData.employmentType,
                status: payload.previewData.status,
                licensePlate: (payload.previewData?.licensePlateSegment1 && payload.previewData?.licensePlateSegment2 && payload.previewData?.licensePlateSegment3) ? `${payload.previewData?.licensePlateSegment1}-${payload.previewData?.licensePlateSegment2}-${payload.previewData?.licensePlateSegment3}` : "",
                vehicleType: payload.previewData.vehicleType,
                company: payload.previewData.company,
                homeBaseStoreId: payload.previewData.storeBase.value
            }

            hasFetch(requestAddDeliveryMan(dataAdd, history))
        } else {
            setLoadingDelete('pending')
            try {
                await Services().delete(`/driver/api/back-office/delete/${id}`)
                setLoadingDelete('resolved')
                hasFetch(requestDeliveryManById(id))
            } catch (error) {
                const { message } = error
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
                setLoadingDelete('rejected')
            }

        }
        setIsModal(!isModal)
    }

    const handleGetImage = (url) => {
        hasFetch(requestPhotoProfile(url))
    }

    const handleCloseImage = () => {
        hasFetch(resetPhotoProfile())
    }

    const generateHBValue = (dataHb) => {
        let storeCode = dataHb?.storeCode;
        let storeName = dataHb?.storeName;
        let channel = dataHb?.channel;

        if (dataHb) {
            return `${storeCode} - ${storeName} (${channel}) `
        } else {
            return false
        }
    }
    const pallet = payload.detailData?.deleted ? statusColor('deleted') : null
    if (!props.access) return <></>
    else if (!props.access?.read) return <Error404 />

    return (
        <>
            <Segment mb={30}>
                <Text H28 mb={"1rem"}>{title}</Text>
                <Breadcrumb data={dataBreadcrumb} />
            </Segment>
            <Segment>
                <Segment boxShadow borderRadius={4} bg={"white"} p={24}>
                    <Segment mb={32}>
                        <Segment display="flex" justifyContent="space-between" alignItems="start">
                            <Text fontWeight={600} mb={32}>Informasi Delivery Man</Text>
                            {payload.detailData?.deleted && pallet !== null && <Badge
                                style={{ border: `1px solid ${pallet.border}`, borderRadius: 4 }}
                                bg={pallet.bg}
                                color={pallet.color}
                                fontSize={12}
                                p={8}
                            >
                                {pallet.text}
                            </Badge>}
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nama</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.name : payload.previewData?.name}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>NIK</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.nik : payload.previewData?.nik}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Email</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.email : payload.previewData?.email}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Nomor Handphone</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.phone : payload.previewData?.phone}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Tipe Pegawai</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.employmentType : payload.previewData?.employmentType}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Perusahaan</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.company ? capitalize(payload.detailData?.company) : '-' : capitalize(payload.previewData?.company)}
                            </Segment>
                        </Segment>
                        {
                            !id && <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Toko Homebase</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {generateHBValue(payload.previewData?.storeBase) || "-"}
                                </Segment>
                            </Segment>
                        }
                        {

                            id &&
                            <>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Klaster</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {id ? payload.detailData?.clusters.length > 0 ? payload.detailData?.clusters.length : 0 : payload.previewData?.employmentType}
                                    </Segment>
                                </Segment>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Toko</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {payload.detailData?.stores.length > 0 ? payload.detailData?.stores.length : 0}
                                    </Segment>
                                </Segment>
                                <Segment mb={24} display="flex" className="xs-flex-wrap">
                                    <Segment minWidth={280}>
                                        <Text color={'#70727D'}>Toko Homebase</Text>
                                    </Segment>
                                    <Segment display="flex">
                                        <Text mr={8}>:</Text>
                                        {generateHBValue(payload.detailData?.homeBaseStore) || "-"}
                                    </Segment>
                                </Segment>
                            </>
                        }

                        {
                            id &&
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Verifikasi</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.verified ? 'Terverifikasi' : 'Belum Verifikasi'}
                                </Segment>
                            </Segment>
                        }
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Status</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.status === 'active' ? 'Aktif' : 'Tidak Aktif'
                                    : payload.previewData?.status === 'active' ? 'Aktif' : 'Tidak Aktif'}
                            </Segment>
                        </Segment>
                        {
                            id && <Segment mb={24} display="flex">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Foto Delivery Man</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.imageProfile ?
                                        <Link
                                            to={'#'}
                                            onClick={() => handleGetImage(payload.detailData?.imageProfile)}
                                        >
                                            Lihat Gambar
                                        </Link> : '-'
                                    }

                                </Segment>
                            </Segment>
                        }

                    </Segment>

                    <Segment borderTop={"1px solid #DCDEE3"} pt={32}>
                        <Segment display="flex" justifyContent="space-between">
                            <Text fontWeight={600} mb={32}>Informasi Kendaraan</Text>
                        </Segment>
                        <Segment mb={24} display="flex" className="xs-flex-wrap">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Jenis Kendaraan</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.vehicleType ? capitalize(payload.detailData?.vehicleType) : '-' : capitalize(payload.previewData?.vehicleType)}
                            </Segment>
                        </Segment>
                        <Segment mb={24} display="flex">
                            <Segment minWidth={280}>
                                <Text color={'#70727D'}>Plat Nomor Kendaraan</Text>
                            </Segment>
                            <Segment display="flex">
                                <Text mr={8}>:</Text>
                                {id ? payload.detailData?.licensePlate ? payload.detailData?.licensePlate.split("-").join(" ") : '-' : (payload.previewData?.licensePlateSegment1 && payload.previewData?.licensePlateSegment2 && payload.previewData?.licensePlateSegment3) ? `${payload.previewData?.licensePlateSegment1} ${payload.previewData?.licensePlateSegment2} ${payload.previewData?.licensePlateSegment3}` : '-'}
                            </Segment>
                        </Segment>
                    </Segment>

                    {id &&
                        <Segment borderTop={"1px solid #DCDEE3"} pt={32}>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Dibuat Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.createdBy} | {payload.detailData?.createdDate ? moment(payload.detailData?.createdDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                            <Segment mb={24} display="flex" className="xs-flex-wrap">
                                <Segment minWidth={280}>
                                    <Text color={'#70727D'}>Diperbaharui Oleh</Text>
                                </Segment>
                                <Segment display="flex">
                                    <Text mr={8}>:</Text>
                                    {payload.detailData?.modifiedBy} | {payload.detailData?.modifiedDate ? moment(payload.detailData?.modifiedDate).locale('id').format('dddd, DD MMMM YYYY - HH:mm') : '-'}
                                </Segment>
                            </Segment>
                        </Segment>
                    }
                </Segment>
                <Segment mt={32} justifyContent="flex-end">
                    <ButtonGroup>
                        {id ?
                            !payload.detailData?.deleted && (
                                <Link to={`/delivery-man/${id}/edit`}>
                                    <Button>Edit</Button>
                                </Link>)
                            :
                            <>
                                <Link to={{ pathname: "/delivery-man/create", state: props.history?.location?.state }}>
                                    <Button variant={"secondary"}>Atur Ulang</Button>
                                </Link>
                                <Button variant={"primary"} onClick={() => setIsModal(!isModal)}>Buat</Button>
                            </>
                        }
                    </ButtonGroup>
                </Segment>
            </Segment>

            <Segment boxShadow borderRadius={4} bg={"white"} p={24} mt={30}>
                <Segment>
                    <Segment>
                        <Tabs className="group-tab" active={activeTab} onChange={active => setActiveTab(active)}>
                            <Text key="klaster">Daftar Klaster</Text>
                            <Text key="toko">Daftar Toko</Text>
                            <Text key="riwayat nik">Riwayat Perubahan NIK</Text>
                        </Tabs>
                        <Segment mt={32}>
                            {activeTab === "klaster" && <TabKlaster {...props} idDeliman={id} />}
                            {activeTab === "toko" && <TabToko {...props} idDeliman={id} />}
                            {activeTab === "riwayat nik" && <TabRiwayatNik {...props} idDeliman={id}/>}
                        </Segment>
                    </Segment>
                </Segment>
            </Segment>

            {/* modal delete */}
            <ModalDelete
                isOpen={isModal}
                onClose={() => setIsModal(!isModal)}
                title={id ? "Hapus Delivery Man?" : "Buat Delivery Man Baru?"}
                content={<Segment py={8}>
                    {id ?
                        "Informasi ini akan dihapus dari sistem."
                        :
                        "Semua informasi Delivery Man yang Anda masukkan akan tercatat dan tersimpan."
                    }
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModal(!isModal)} variant={id ? "primary" : "secondary"}>
                                Batal
                            </Button>
                            <Button onClick={handleActionYes} disabled={loadingDelete === "pending"} minWidth={"100px"} variant={id ? "secondary" : "primary"}>
                                {id ? loadingDelete === "pending" ? "Loading.." : "Hapus" : "Buat"}
                            </Button>
                        </ButtonGroup>
                    )
                }
            />

            <ModalInfo
                isOpen={payload?.openModal}
                onClose={() => setModalPhoto(!modalPhoto)}
                content={
                    <Segment py={8} className="font-nunito wrap-img-pendukung" alignItems={'center'} justifyContent={'center'} style={{ textAlign: 'center' }} mb={24}>
                        {
                            payload?.loadingGetImage ?
                                <Segment className="u-tx-center" width="100%" height={40}><Spinner /></Segment> :
                                <img src={payload?.dataImage} alt="file upload" />
                        }
                    </Segment>
                }
                ButtonFooter={
                    (
                        <Segment style={{ textAlign: "center" }} mx={'auto'}>
                            <Button onClick={() => handleCloseImage()}>
                                Tutup
                            </Button>
                        </Segment>
                    )
                }
            />
        </>
    )
}

export default DeliveryManDetail