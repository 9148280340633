import moment from 'moment'
export const serviceType = (data) => {
  if(!data.isCod && data.isBulky && data.isOrderReturn) return  'Bulky & Retur'
  if(data.isCod && data.isBulky && data.isOrderReturn) return  'COD, Bulky & Retur'
  if(data.isCod && data.isBulky && !data.isOrderReturn) return  'COD & Bulky'
  if(!data.isCod && data.isBulky && !data.isOrderReturn) return  'Bulky'
  if(data.isCod && !data.isBulky && !data.isOrderReturn) return  'COD'
  if(!data.isCod && !data.isBulky && !data.isOrderReturn)  return  '-'
}

export const timeSlot = (start,end) => {
  return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
}

export const formatCountDown = (duration,is1Hour=false) => {
  const positifValue = Math.abs(duration)
  
  const days = Math.floor(positifValue / (24 * 60 * 60 ))
  const hours = Math.floor(positifValue / (60 * 60 ) % 24)
  const minutes = Math.floor((positifValue / (60)) % 60)
  const seconds = Math.floor((positifValue % 60)).toLocaleString('en-US',{minimumIntegerDigits:2})
  
  const hourString =  hours > 0 ? `${hours}:` : ''
  const dayString = days > 0 ? `${days}:` : ''
  let result = ''
  if(duration < 0){
    result = `- ${dayString}${hourString}${minutes}:${seconds}`
    
  }else if(duration===0){
    result = '00:00'
  }else if(is1Hour && duration===3600){
    result = '01:00:00'
  }else{
    result = `${dayString}${hourString}${minutes}:${seconds}`
  }
  
  return result
}

export const getDuration = (start,end) => {
  const startDate = new moment(start);
  const endData = new moment(end);

  
  const duration = moment.duration(endData.diff(startDate)).as('seconds')
  
  return Math.ceil(duration)
  
}
