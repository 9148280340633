import React, { useState, useEffect } from "react"
import {
    Segment,
    FormControl,
    Icon,
    Textfield,
    Button,
    OptionBox,
    ButtonGroup,
    Text
} from "@elevenia/master-ui/components/Atom"
import DataTable from "component/DataTable"
import DatePicker from "@elevenia/master-ui/components/Atom/DatePicker"
import ModalNotes from 'component/ModalCustom/modalSmall'
import { Link } from "react-router-dom"
import useSWR from 'swr'
import { fetcher, objectToQueryString } from 'helper'
import moment from "moment"
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { useAction } from "hooks"
const TabSedangAktif = (props) => {
    const { hasFetch } = useAction()
    const [isModalNotes, setIsModalNotes] = useState(({show:false,data:null}))
    const [isTable, setTable] = useState({
        page: 0,
        size: 10,
        searchParam: "",
        sort: "date,asc",
        status: 'active',
        startDate: '',
        endDate: ''
    });

    const optionsSwr = {
        revalidateOnFocus: false,
        shouldRetryOnError: false
    }
    const { data: dataActive, error } = useSWR((isTable && props.access?.read) && `/user/api/back-office/rkb-spv/all?${objectToQueryString(isTable)}`, fetcher, optionsSwr)

    const payload = {
        data: dataActive?.data.content,
        loading: !dataActive && !error,
        totalPages: dataActive?.data.totalPages
    }

    const [filterDate, setFilterDate] = useState({
        startDate: null,
        endDate: null
    })
    const [onSearch$] = useState(() => new Subject());
    useEffect(() => {
        onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(value => setTable(value))
        ).subscribe();

        return () => {
            onSearch$.unsubscribe();
        }

    }, [onSearch$])

    const handleSearch = e => {
        let value = e.target.value;
        onSearch$.next({
            ...isTable,
            searchParam: value.trim()
        });
    }

    useEffect(() => {
        if (error) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ? error.message : 'Not Found' } })
        }
    }, [hasFetch, error])


    const btnActive = (value) => {
        const basicStyle = { minWidth: 44, height: 24, padding: "5px 8px" }

        switch (value) {
            case true:
                return {
                    based: { ...basicStyle },
                    bg: "#FFA800",
                }
            case false:
                return {
                    based: { ...basicStyle, cursor: "auto" },
                    bg: "#9C9DA6",
                }
            default:
                return {
                    bg: "#9C9DA6",
                }
        }
    }

    

    return (
        <>
            <Segment mb={32} pb={16}>
                <Segment
                    className="group-filter"
                    boxShadow={"0 1px 4px 0 #70727d33"}
                    borderRadius={4}
                    bg={"white"}
                    p={20}
                    borderBottom={"1px solid #DCDEE3"}
                    mb={24}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Segment maxWidth={280} width={"100%"} className={"fw500"}>
                        <FormControl>
                            <Textfield
                                inputProps={{
                                    onChange: (e) => handleSearch(e),
                                    placeholder: "Cari Supervisor Delivery",
                                }}
                                left={<Icon name={"search"} size={24} fillColor="#9c9da5" />}
                            />
                        </FormControl>
                    </Segment>
                    <Segment alignItems={"center"} justifyContent={"space-between"} className="group-filter">
                        <Segment pl={20} maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                                <OptionBox
                                    defaultValue={""}
                                    options={props.optionsLocation}
                                    placeholder={"Lokasi"}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                clockinStatus: e ? e.value : ''
                                            }
                                        })
                                    }}
                                    isClearable
                                />
                            </FormControl>
                        </Segment>
                        <Segment ml={20} maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                                <DatePicker
                                    placeholderText={"Tanggal Awal"}
                                    selected={filterDate.startDate}
                                    selectsStart
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    minDate={new Date()}
                                    maxDate={filterDate.endDate ? filterDate.endDate : null}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                startDate: e ? moment(e).format('DD/MM/YYYY') : ''
                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                startDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                        {/* separator */}
                        <Segment minWidth={8} height={2} bg={'#9C9DA6'} mx={10} className="lg-hidden" />
                        {/* separator */}
                        <Segment maxWidth={200} width={"100%"} className={"fw500"}>
                            <FormControl>
                                <DatePicker
                                    placeholderText={"Tanggal Akhir"}
                                    selected={filterDate.endDate}
                                    selectsEnd
                                    startDate={filterDate.startDate}
                                    endDate={filterDate.endDate}
                                    minDate={filterDate.startDate ?? new Date()}
                                    onChange={e => {
                                        setTable(prev => {
                                            return {
                                                ...prev,
                                                page: 0,
                                                endDate: e ? moment(e).format('DD/MM/YYYY') : ''
                                            }
                                        })
                                        setFilterDate(prev => {
                                            return {
                                                ...prev,
                                                endDate: e ? e : null
                                            }
                                        })
                                    }}
                                    right={<Icon name={"date"} fillColor="#70727D" size={24} />}
                                />
                            </FormControl>
                        </Segment>
                    </Segment>
                </Segment>
                <DataTable
                    countingList={true}
                    isLoading={payload.loading}
                    defaultSize={isTable.size}
                    totalPages={payload.totalPages}
                    tableConsume={[
                        {
                            field: "Tanggal",
                            rowField: "date",
                            isCustomRow: (id, { date }) => {
                                return moment(date).format('DD/MM/YY')
                            }
                        },
                        {
                            field: "Supervisor Delivery",
                            rowField: "supervisorNik",
                            isCustomRow: (id, { supervisorNik, supervisorName }) => {
                                return (supervisorName && supervisorNik) ? `${supervisorNik} - ${supervisorName}` : '-'
                            }
                        },
                        {
                            field: "Kode Toko",
                            rowField: "storeCode",
                            isCustomRow: (id, { storeCode, storeName }) => {
                                return (storeCode && storeName) ? `${storeCode} - ${storeName}` : '-'
                            }
                        },
                        {
                            field: "Lokasi",
                            isCustomRow: (_, { checkinStatusInfo }) => {
                                const getLabel = props.optionsLocation.find(val=>val.value===checkinStatusInfo)
                                return <span style={{ textTransform: "capitalize" }}> {getLabel ? getLabel.label : '-'}</span>
                            }
                        },
                        {
                            field: "Waktu Clock In",
                            isCustomRow: (_, { checkinTime }) => {
                                return checkinTime ? `${moment(checkinTime).format('HH:mm')} ${props.timezoneLabel}` : "-"
                            }
                        },
                        {
                            field: "Ubah Jadwal",
                            isCustomRow: (_, {checkinStatusInfo, id}) => {
                                const enableButton = checkinStatusInfo === 'kosong' ? false : true
                                const property = btnActive(!enableButton);

                                return <>
                                    {!enableButton
                                        ? <Link to={`/pengaturan-rkb-spv/${id}/form-ubah-jadwal`}>
                                            <Button style={{ backgroundColor: property.bg, ...property.based }}>
                                                Atur
                                            </Button>
                                        </Link>
                                        :
                                        <Button style={{ backgroundColor: property.bg, ...property.based }}>
                                            Atur
                                        </Button>
                                    }
                                </>
                            },
                        },
                        {
                            field: "Catatan",
                            isCustomRow: (_, entity) => {
                                const statusCheckIn = entity?.checkinStatusInfo === 'tidak-sesuai' ? true : false
                                const property = btnActive(statusCheckIn);
                                
                                return (
                                    <Button
                                        style={{ backgroundColor: property.bg, ...property.based }}
                                        onClick={(e) => !statusCheckIn ? e.preventDefault : setIsModalNotes({show: !isModalNotes.show, data:entity})}
                                    >
                                        Lihat
                                    </Button>
                                );
                            },
                        },
                    ]}
                    dataConsume={payload.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    createShowPerSize={(size) => setTable(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTable(prev => {

                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>

            {/* modal show notes */}
            {isModalNotes.show && <ModalNotes
                isOpen={isModalNotes.show}
                onClose={() => setIsModalNotes({show:!isModalNotes.show, data:null})}
                title={"Informasi Jadwal"}
                content={<Segment py={8} style={{ lineHeight: "21px" }}>
                    <Segment>
                        <Text color={'#151823'} fontWeight="500">
                            Alasan Lokasi Tidak Sesuai
                        </Text>
                        {isModalNotes.data?.checkinNote}
                    </Segment>
                </Segment>}
                ButtonFooter={
                    (
                        <ButtonGroup>
                            <Button minWidth={"100px"} onClick={() => setIsModalNotes({show:!isModalNotes,data:null})}>
                                Tutup
                            </Button>
                        </ButtonGroup>
                    )
                }
            />}
        </>
    );
};

export default TabSedangAktif;
