
const initialState = {
  data: "",
  totalPages:0,
  parameter:null,
  loading: false,
  detailData:null,
  statuses:[],
  clusters:[],
  statusCheck:{
      nik:'',
      email:'',
      phone:''
  },
  loadingCheck:{
    nik:false,
    email:false,
    phone:false
  },
  messageError:{
    nik:'',
    email:'',
    phone:''
  },
  loadingGetImage:false,
  dataImage:null,
  openModal:false,
}

export const deliveryman = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_DELIVERY_MAN':
          return {
              ...state,
              loading: true,
              detailData:null,
              statusCheck:{
                nik:'',
                email:'',
                phone:''
              },
              messageError:{
                nik:'',
                email:'',
                phone:''
              }
          }
      case 'REQUEST_DELIVERY_MAN_BY_ID':
          return {
              ...state,
              loading: true,
              detailData:null,
              statusCheck:{
                nik:'00',
                email:'00',
                phone:'00'
              }
          }
      case 'GET_DELIVERY_MAN':
          
          return {
              ...state,
              data:action.payload.data,
              parameter: action.payload.parameter,
              totalPages:action.payload.totalPages,
              loading: false,
              statuses:action.payload.filterStatus,
              clusters:action.payload.filterCluster,
          }
      case 'GET_DELIVERY_MAN_BY_ID':
    
        return {
            ...state,
            detailData:action.payload.data,
            loading: false
        }
      case 'GET_DELIVERY_MAN_FAILED':
        return {
            ...state,
            loading: false
        }
      case 'RESET_INPUT_STATUS':
          return {
              ...state,
              statusCheck:{
                ...state.statusCheck,
                [action.payload.field]:''
              },
              messageError:{
                ...state.messageError,
                [action.payload.field]:''
              }
          }
      case 'REQUEST_CHECK_NIK':
          return{
              ...state,
              loadingCheck:{
                ...state.loadingCheck,
                nik:true,
            },
            messageError:{
                ...state.messageError,
                nik:''
            }
          }
      case 'REQUEST_CHECK_EMAIL':
        return{
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                email:true,
            },
            messageError:{
                ...state.messageError,
                email:''
            }
            
        }
      case 'REQUEST_CHECK_PHONE':
        return{
            ...state,
            loadingCheck:{
                ...state.loadingCheck,
                phone:true,
            },
            messageError:{
                ...state.messageError,
                phone:''
            }
        }
        case 'GET_STATUS_FIELD':
            return {
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    [action.payload.field]:false,
                },
                statusCheck:{
                    ...state.statusCheck,
                    [action.payload.field]:action.payload.status
                },
                messageError:{
                    ...state.messageError,
                    [action.payload.field]:action.payload.message
                }
            }
        case 'ERROR_STATUS_FIELD':
            return {
                ...state,
                loadingCheck:{
                    ...state.loadingCheck,
                    [action.payload.field]:false,
                },
                messageError:{
                    ...state.messageError,
                    [action.payload.field]:action.payload.message
                },
                statusCheck:{
                    ...state.statusCheck,
                    [action.payload.field]:action.payload.status
                }
            }
        case 'SET_STATUS_FIELD_DELIVERY_MAN':
            return {
                ...state,
                detailData:null,
                statusCheck:{
                    nik:action.payload.status,
                    email:action.payload.status,
                    phone:action.payload.status
                },
                loadingCheck:{
                    nik:false,
                    email:false,
                    phone:false
                },
                messageError:{
                    nik:'',
                    email:'',
                    phone:''
                }
            }
        case 'REQUEST_PHOTO_PROFILE':
            return {
                ...state,
                loadingGetImage:true,
                dataImage:null,
                openModal:true,
            }
        case 'GET_PHOTO_PROFILE':
            return {
                ...state,
                loadingGetImage:false,
                dataImage:action.payload.data,
                openModal:true,
            }
        case 'RESET_FILE_UPLOAD' :
            return {
                ...state,
                loadingGetImage:false,
                dataImage:null,
                openModal:false,
            }
      default:
          return state
  }
}