import { combineEpics } from "redux-observable";
import { RequestAuthentication } from "./epicAuthentication";
import { RequestHomeSideMenu } from "./epicHomeSideMenu";
import { RequestMenuAccessRight } from "./epicMenuAccessRight";
import { RequestUsers } from "./epicUsers";
import { RequestCoordinatorDeliman } from "./epicCoordinatorDeliman";
import { RequestDeliveryman } from './epicDeliveryman'
import { RequestVerificationSetup } from "./epicVerificationSetup";
import { RequestCluster } from "./epicCluster";
import { RequestChangePassword } from "./epicChangePassword";
import { RequestMasteStore } from "./epicMasterStore";
import { RequestTrackOrder } from "./epicTrackOrder";
import { RequestOrderList } from "./epicOrderList";
import { RequestActivityLog } from "./epicMonitoringAplikasi";
import { RequestDashboard } from "./epicDashboard";
import { RequestDeliverymanCadangan } from './epicDeliverymanCadangan';
import { RequestReleaseLocking } from './epicReleaseLocking';
import { RequestPhotoReport } from './epicPhotoReport';
import { RequestReleaseValidation } from './epicReleaseValidation';
import { RequestSupervisorDelivery } from './epicSupervisorDelivery';
import { RequestConfigRKB } from './epicConfigRKB';
import { RequestConfigRKBSpv } from './epicConfigRKBSpv';
import { RequestManager } from "./epicManager";
import { RequestReleaseAccess } from "./epicReleaseAccess";

const setupEpic = combineEpics(
    // MODULES VARIABL
    RequestAuthentication,
    RequestHomeSideMenu,
    RequestMenuAccessRight,
    RequestUsers,
    RequestCoordinatorDeliman,
    RequestDeliveryman,
    RequestVerificationSetup,
    RequestCluster,
    RequestChangePassword,
    RequestMasteStore,
    RequestTrackOrder,
    RequestOrderList,
    RequestActivityLog,
    RequestDashboard,
    RequestDeliverymanCadangan,
    RequestReleaseLocking,
    RequestPhotoReport,
    RequestReleaseValidation,
    RequestSupervisorDelivery,
    RequestConfigRKB,
    RequestConfigRKBSpv,
    RequestManager,
    RequestReleaseAccess
)

export default setupEpic