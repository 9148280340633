import React from 'react'
import { Segment, Text } from '@elevenia/master-ui/components/Atom'
const ReturnInfo = ({
    data,
    textOrderStatus
}) => {
  return textOrderStatus === 'IN_RETURN'
  ?
  <>
      <Segment mb={24} display="flex" className="xs-flex-wrap">
          <Segment minWidth={280}>
              <Text color={'#70727D'}>Pin Bulky</Text>
          </Segment>
          <Segment display="flex">
              <Text mr={8}>:</Text>

              {data?.returnPin}
          </Segment>
      </Segment>
      <Segment mb={24} display="flex" className="xs-flex-wrap">
          <Segment minWidth={280}>
              <Text color={'#70727D'}>Kode Pembayaran</Text>
          </Segment>
          <Segment display="flex">
              <Text mr={8}>:</Text>

              {data?.codPin}
          </Segment>
      </Segment>
      {
          data?.cod
          &&
          <Segment mb={24} display="flex" className="xs-flex-wrap">
              <Segment minWidth={280}>
                  <Text color={'#70727D'}>Nama Biller</Text>
              </Segment>
              <Segment display="flex">
                  <Text mr={8}>:</Text>

                  {data?.codPaymentBiller ?? '-'}
              </Segment>
          </Segment>
      }
  </> : ''
}

export default ReturnInfo