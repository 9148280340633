import React, { useEffect } from "react"
import ModalSmall from 'component/ModalCustom/modalSmall'
import {
    Segment,
    Icon,
    ButtonLink,
    Text,
    TooltipTarget,
    Tooltip,
    TooltipContent,
    Spinner
} from "@elevenia/master-ui/components/Atom"
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import { requestOrderListByIdDeliman } from 'store/actions/release-locking'
const ModalOderInfo = ({isModal, setIsModal, data}) => {
    const { hasFetch } = useAction();
    const payload = useSelector(state => {
        return {
            orderListById: state.releaseLocking.orderListById,
            loadingGetOrder: state.releaseLocking.loadingGetOrder
        }
    })
    useEffect(()=>{
        
            isModal && hasFetch(requestOrderListByIdDeliman(data))
        
        return ()=>{
            hasFetch({ type: 'RELEASE_LOCKING_SUCCESS' })
        }
    },[isModal,hasFetch,data])
    
    return(
        <>
            <ModalSmall
            isOpen={isModal}
            onClose={() => setIsModal(!isModal)}
            title={<>
                <Segment justifyContent="space-between">
                    <Text>
                        Daftar Order ID
                    </Text>
                    <ButtonLink onClick={() => setIsModal(!isModal)}>
                        <Icon name={"cancel"} size={14} fillColor={"#70727D"} />
                    </ButtonLink>
                </Segment>
            </>}
            content={<Segment py={8} mt={24}>
                <Segment mb={24} display="flex" className="xs-flex-wrap">
                    <Segment minWidth={100}>
                        <Text color={'#70727D'}>Delivery Man</Text>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        {`${payload.orderListById?.deliverymanNik} - ${payload.orderListById?.deliverymanName}`}
                    </Segment>
                </Segment>
                <Segment display="flex" className="xs-flex-wrap">
                    <Segment minWidth={100} display="flex">
                        <Text color={'#70727D'}>Order ID</Text>
                        <Tooltip type="hover" placement="bottom" color="dark">
                            <TooltipTarget>
                                <Icon name="information" size={16} fillColor="#434755" ml={8}/>
                            </TooltipTarget>
                            <TooltipContent>
                                Order akan ter-update otomatis.
                            </TooltipContent>
                        </Tooltip>
                    </Segment>
                    <Segment display="flex">
                        <Text mr={8}>:</Text>
                        <Segment>
                        {payload.loadingGetOrder && <Segment className="u-tx-center" width={30} height={30}>
                                    <Spinner />
                                </Segment>}
                        {(payload.orderListById?.orderIdList && payload.orderListById?.orderIdList?.length ) 
                        ? payload.orderListById?.orderIdList?.map((val,index)=>(<Text key={index} mb={12}>
                            {val}
                        </Text>))
                        : '-'
                        }
                        </Segment>
                    </Segment>
                </Segment>
            </Segment>}
        />
        </>
    )
}

export default ModalOderInfo