const initialState = {
    data: [],
    loading: false,
    totalPages: 0,
    parameter: null,
    activities: [],
    detail: {
        loading: false,
        activities: [],
        activityDate: null,
        clusterCode: null,
        clusterName: null
    }
}
export const activityLog = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_ACTIVITY_LOG':
            return {
                ...state,
                loading: true
            }
        case 'GET_ACTIVITY_LOG':
            let rotate = []
            for (let i = 0; i < action.payload.activities.length; i++) {
                rotate[0] = action.payload.activities[action.payload.activities.length - 1];
                rotate[i] = action.payload.activities[0]
                rotate[i] = action.payload.activities[i - 1]
            }
            return {
                ...state,
                ...action.payload,
                activities: rotate.map((item, index) => {
                    return {
                        value: item,
                        label: item
                    }
                }),
                loading: false
            }
        case 'REQUEST_DETAIL_ACTIVITY_LOG':
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true
                }
            }
        case 'GET_DETAIL_ACTIVITY_LOG':
            return {
                ...state,
                detail: {
                    ...state.detail,
                    ...action.payload,
                    loading: false,
                }
            }
        case "RESET_ACTIVITY_LOG":
            return {
                ...initialState
            }
        case "ACTIVITY_LOG_FAILED":
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}