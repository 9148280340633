import React from "react";
import {
    Segment
} from "@elevenia/master-ui/components/Atom";
import DataTable from "component/DataTable";
import useDataUpload from "../_utils/DataContex";
import { parseValue } from "helper";
import moment from "moment";
import ShiftTooltip from "./ShiftTooltip";

const TabDataTerbaca = () => {
    const {dataRead, errorRead, setTableRead, isTableRead} = useDataUpload()
    return (
        <>
            <Segment>
                <DataTable
                    countingList={true}
                    isLoading={!dataRead.data && !errorRead}
                    defaultSize={isTableRead?.size}
                    tableConsume={[
                        {
                            field: "Delivery Man",
                            isCustomRow: (id, entity) => {
                                return `${entity.driverNik} - ${entity.driverName}`
                            }
                        },
                        {
                            field: "Tanggal",
                            entityFilters: 'absensiDate',
                            isCustomRow: (value) => parseValue(value, (val) => moment(val).format('DD/MM/yyyy'))
                        },
                        {
                            field: "Shift",
                            entityFilters: 'shift',
                            isAddOnHeader: () => <ShiftTooltip />,
                            isCustomRow: (value) => parseValue(value)
                        },
                        {
                            field: "Kode Toko Clock In",
                            isCustomRow: (id, entity) => {
                                return (entity.clockInStoreCode && entity.clockInStoreName) ? `${entity.clockInStoreCode} - ${entity.clockInStoreName}` : '-'
                            }
                        },
                        {
                            field: "Kode Toko Clock Out",
                            isCustomRow: (id, entity) => {
                                return (entity.clockOutStoreCode && entity.clockOutStoreName) ? `${entity.clockOutStoreCode} - ${entity.clockOutStoreName}` : '-'
                            }
                        }
                    ]}
                    dataConsume={dataRead.data}
                    showSize={true}
                    showPagination={true}
                    showSearch={false}
                    totalPages={dataRead?.totalPages}
                    createShowPerSize={(size) => setTableRead(prev => {
                        return {
                            ...prev,
                            size: size.value
                        }
                    })}
                    createPaginations={(page) => setTableRead(prev => {
                        return {
                            ...prev,
                            page: page.page
                        }
                    })}
                />
            </Segment>
        </>
    );
};

export default TabDataTerbaca;
